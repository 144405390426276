import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Button
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getProducts } from '../../redux/slices/products'
import { ProductStatus } from '../../enums/ProductStatus'
import LoadingState from '../LoadingState'
import ProductTable from './ProductTable'
import { getOrderItem } from '../../redux/slices/orderItems'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

function ProductList() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { products, isLoadingProducts } = useSelector(
    (state: RootState) => state.products
  )
  const { orderItem, loading } = useSelector(
    (state: RootState) => state.orderitems
  )

  const searchParams = new URLSearchParams(window.location.search)
  const orderId = searchParams.get('order') ?? undefined
  const orderItemId = searchParams.get('orderItem') ?? undefined

  useEffect(() => {
    if (orderItemId && orderId) {
      dispatch(
        getProducts({
          orderItemId: parseInt(orderItemId)
        })
      )
      dispatch(getOrderItem(parseInt(orderId), parseInt(orderItemId)))
    }
  }, [])

  /**
   * Products ready for testing and dispatching
   */
  const productsReadyForDispatch = products
    .filter((product) => product.status === ProductStatus.ASSEMBLED)
    .concat(
      products.filter((product) => product.status === ProductStatus.PREPARED),
      products.filter((product) => product.status === ProductStatus.TESTED)
    )

  /**
   * Is DeviceSensorDataTestForm or DispatchProductForm open
   */
  const [showForm, setShowForm] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Card mb={6}>
            {isLoadingProducts || loading ? (
              <LoadingState />
            ) : showForm == false ? (
              <React.Fragment>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon />
                  {t('Manufacturing.back')}
                </Button>
                <Divider my={6} />

                {/**ProductTable that contains pending products */}
                <ProductTable
                  orderItem={orderItem}
                  products={products.filter(
                    (product) => product.status === ProductStatus.PENDING
                  )}
                  readyForDispatch={false}
                  dispatchProductFormOpen={(showForm) => {
                    setShowForm(showForm)
                  }}
                  deviceSensorDataTestFormOpen={(showForm) => {
                    setShowForm(showForm)
                  }}
                />
              </React.Fragment>
            ) : (
              <></>
            )}
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Divider my={6} />
          <Card mb={6}>
            {isLoadingProducts || loading ? (
              <LoadingState />
            ) : (
              /**Product table that contains products ready for testing and dispatching */
              <ProductTable
                orderItem={orderItem}
                products={productsReadyForDispatch}
                readyForDispatch={true}
                dispatchProductFormOpen={(showForm) => {
                  setShowForm(showForm)
                }}
                deviceSensorDataTestFormOpen={(showForm) => {
                  setShowForm(showForm)
                }}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default ProductList
