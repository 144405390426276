import { useTranslation } from 'react-i18next'
import { RuuviTagCondition } from '../types'
import RuuviTagStats from './RuuviTagStats'
import { Stack } from '@mui/material'
import RuuviTagSignalIndicator from './RuuviTagSignalIndicator'

interface RuuviTagSignalCardProps {
  ruuviTagCondition: RuuviTagCondition | null
  loading?: boolean
}

export default function RuuviTagSignalCard({
  ruuviTagCondition,
  loading = false
}: RuuviTagSignalCardProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <RuuviTagStats
      title={
        <Stack direction="row" alignItems="center" gap={3}>
          {t('signal')}
          {ruuviTagCondition && (
            <RuuviTagSignalIndicator condition={ruuviTagCondition} />
          )}
        </Stack>
      }
      value={ruuviTagCondition?.rssi}
      unit="dBm"
      date={ruuviTagCondition?.time}
      loading={loading}
    />
  )
}
