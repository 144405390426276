import React, { useEffect } from 'react'
import ManufacturingHeader from '../../components/manufacturing/ManufacturingPageHeader'
import OrderForm from '../../components/manufacturing/OrderForm'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getOrder } from '../../redux/slices/orders'

function OrderEdit() {
  const { id } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      dispatch(getOrder(parseInt(id)))
    }
  }, [id])

  return (
    <React.Fragment>
      <ManufacturingHeader />
      <OrderForm />
    </React.Fragment>
  )
}

export default OrderEdit
