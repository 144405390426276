import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  TableHead,
  Divider as MuiDivider,
  CardHeader,
  TableContainer,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LoadingState from '../LoadingState'
import { deleteProductState, getProducts } from '../../redux/slices/products'
import { ProductStatus } from '../../enums/ProductStatus'
import { ProductType } from '../../enums/ProductType'
import { Order, Product } from '../../types'
import { Add } from '@mui/icons-material'
import { OrderStatus } from '../../enums/OrderStatus'
import { OrderType } from '../../enums/OrderType'
import { createOrderItem, getOrders } from '../../services/orderService'
import OrderItemInput from '../../types/OrderItemInput'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import { OrderErrors } from '../../enums/OrderErrors'
import { useAuth } from '../../hooks'
import translateProductOrOrderItemType from '../../utils/translateProductOrOrderItemType'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

export default function ProductStorageTable() {
  const { products, isLoadingProducts } = useSelector(
    (state: RootState) => state.products
  )
  const { currentUser } = useAuth()
  const [orders, setOrders] = useState<Order[]>([])
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [t] = useTranslation('common')

  useEffect(() => {
    dispatch(getProducts({ status: ProductStatus.STORED }))
    loadOrders()
  }, [])

  async function loadOrders() {
    try {
      setIsLoadingOrders(true)
      setOrders(
        await getOrders({
          status: [
            OrderStatus.PENDING,
            OrderStatus.IN_PROGRESS,
            OrderStatus.ON_HOLD
          ],
          type: OrderType.OUTBOUND
        })
      )
    } finally {
      setIsLoadingOrders(false)
    }
  }

  const [filter, setFilter] = useState<string>('')
  const [orderFilter, setOrderFilter] = useState<string>('')

  const filteredProducts = products.filter((product: Product) => {
    const regex = new RegExp(filter, 'i')
    return (
      filter.length === 0 ||
      Object.values(product).some((value) => {
        return regex.test(value as string)
      }) ||
      Object.values(product.device ?? {}).some((value) => {
        return regex.test(value as string)
      }) ||
      Object.values(product.catalogProduct ?? {}).some((value) => {
        return regex.test(value as string)
      })
    )
  })

  const filteredOrders = orders.filter((order: Order) => {
    const regex = new RegExp(orderFilter, 'i')
    return (
      orderFilter.length === 0 ||
      Object.values(order).some((value) => {
        return regex.test(value as string)
      })
    )
  })

  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function handleOpenOrderList(product: Product) {
    setSelectedProduct(product)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedProduct(undefined)
  }

  async function makeOrderItemInput(orderId: number) {
    if (selectedProduct) {
      return {
        type: selectedProduct.type as ProductType,
        nominalCurrent: selectedProduct.nominalCurrent,
        quantity: 1,
        withPowerLeds: selectedProduct.withPowerLeds,
        orderId: orderId,
        clampRatio: selectedProduct.clampRatio,
        catalogProduct: selectedProduct.catalogProduct
          ? selectedProduct.catalogProduct.id
          : null,
        status: selectedProduct.status,
        products: [selectedProduct.id]
      }
    }
  }

  async function handleAttachToOrder(id: number) {
    try {
      setIsSubmitting(true)
      // @ts-ignore
      const orderItemInput: OrderItemInput = await makeOrderItemInput(id)
      await createOrderItem(orderItemInput)

      //Remove product from storaged products list
      dispatch(deleteProductState(selectedProduct?.id))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('productAttachSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: OrderErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
      setOpen(false)
    }
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate('/manufacturing')}
        >
          <ArrowBackIcon />
          {t('back')}
        </Button>
        <Divider my={6} />
        <Card mb={6}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t('selectOrderForDevice')} {selectedProduct?.device.shortUUID}
            </DialogTitle>

            {isLoadingOrders || isSubmitting ? (
              <LoadingState />
            ) : (
              <React.Fragment>
                {open && orders ? (
                  <DialogContent>
                    <TextField
                      sx={{ mt: 2 }}
                      label={t('search')}
                      fullWidth
                      name="search"
                      size="small"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setOrderFilter(event.target.value)
                      }}
                    />
                    <Divider my={6} />
                    <Table>
                      {filteredOrders.map((order: Order) => (
                        <TableRow>
                          <TableCell>
                            {order.company?.name}, {order.name}
                          </TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => handleAttachToOrder(order.id)}
                            >
                              {t('attachProductToOrder')}
                              <Add />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </DialogContent>
                ) : (
                  <DialogContent>
                    <DialogContentText>
                      {t('noPendingOrders')}
                    </DialogContentText>
                  </DialogContent>
                )}
              </React.Fragment>
            )}
            <DialogActions>
              <Button onClick={handleClose} autoFocus variant="contained">
                {t('close')}
              </Button>
            </DialogActions>
          </Dialog>
          <CardHeader title={t('storedProducts')} />
          <Grid item xs={6} md={3} ml={3} my={3}>
            <TextField
              label={t('search')}
              fullWidth
              name="search"
              size="small"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
            />
          </Grid>
          <Divider my={6} />
          {isLoadingProducts ? (
            <LoadingState />
          ) : (
            <TableContainer component={Paper}>
              <TableWrapper>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('deviceCode')}</TableCell>
                      <TableCell>{t('manufacturerProductCode')}</TableCell>
                      <TableCell>{t('name')}</TableCell>
                      <TableCell>{t('manufacturer')}</TableCell>
                      <TableCell>{t('type')}</TableCell>
                      <TableCell>{t('clampRatio')}</TableCell>
                      <TableCell>{t('nominalCurrent')}</TableCell>
                      <TableCell>{t('ledbar')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts.map((product: Product) => (
                      <TableRow>
                        <TableCell>{product.device.shortUUID}</TableCell>
                        <TableCell>
                          {product.catalogProduct
                            ? product.catalogProduct.manufacturerProductCode
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {product.catalogProduct
                            ? product.catalogProduct.name
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {product.catalogProduct
                            ? product.catalogProduct.manufacturer
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {t(
                            translateProductOrOrderItemType(
                              product.type
                            ) as string
                          )}
                        </TableCell>
                        <TableCell>{product.clampRatio}</TableCell>
                        <TableCell>{product.nominalCurrent}</TableCell>
                        <TableCell>
                          {product.withPowerLeds ? t('yes') : t('no')}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => handleOpenOrderList(product)}
                          >
                            {t('add')} <Add />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableWrapper>
            </TableContainer>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}
