import { useTranslation } from 'react-i18next'
import { blue } from '@mui/material/colors'
import { ChartData, ChartOptions, ChartDataset } from 'chart.js'
import {
  getDatesByRange,
  getDifferenceOfDateRangeInHours,
  humanizeTimestamp
} from '../utils/date'
import { transformEnergyPricesToMap } from '../utils/energyPrice'
import {
  calculateAvgKwh,
  transformSensorAggregatesToMap
} from '../utils/sensorAggregate'
import BarChart from './BarChart'
import LineChart from './LineChart'
import EnergyPriceType from '../enums/EnergyPriceType'
import { CalculationMode, DateInterval } from '../enums'
import {
  EnergyCost,
  EnergyCostQuery,
  EnergyCostSettings,
  SensorAggregate,
  EnergyPrice
} from '../types'
import { mapEnergyCostsByDate } from '../utils/energyCost'

interface EnergyCostChartProps {
  energyCosts?: EnergyCost[]
  query: EnergyCostQuery
  settings: EnergyCostSettings
  initialEnergyCost?: number

  // @deprecated
  sensorAggregates?: SensorAggregate[]
  energyPrices?: EnergyPrice[]
}

export default function EnergyCostChart({
  energyCosts,
  query,
  settings,
  initialEnergyCost = 0,
  sensorAggregates = [],
  energyPrices = []
}: EnergyCostChartProps) {
  const [t] = useTranslation('common')

  const dates: Date[] = getDatesByRange(
    query.from,
    query.to,
    DateInterval.ONE_HOUR
  )

  const datesAsIsoStrings: string[] = dates.map((date) => date.toISOString())

  const chartData: ChartData = makeChartData()

  const chartOptions: ChartOptions = makeChartOptions()

  function makeChartOptions(): ChartOptions {
    return {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        tooltip: {
          callbacks: {
            // @ts-ignore
            title: (tooltipItems: TooltipItem[]): string => {
              if (tooltipItems.length) {
                return humanizeTimestamp(
                  // @ts-ignore
                  chartData?.labels[tooltipItems[0].dataIndex]
                )
              }
              return ''
            }
          }
        }
      },
      scales: {
        x: {
          type: 'timeseries',
          time: {
            // @ts-ignore
            unit:
              getDifferenceOfDateRangeInHours(
                // @ts-ignore
                new Date(chartData?.labels[0]),
                // @ts-ignore
                new Date(chartData?.labels[chartData?.labels.length - 1])
              ) > 24
                ? 'day'
                : 'hour',
            displayFormats: {
              hour: 'HH:00',
              day: 'd.M'
            }
          },
          grid: {
            display: false
          }
        },
        y: {
          min: 0
          // TODO: Use smarter max scaling.
          // suggestedMax:
          //   settings.calculationMode === CalculationMode.CUMULATIVE ? 240 : 10
        }
      }
    }
  }

  function makeChartData(): ChartData {
    return {
      labels: makeChartLabels(),
      datasets: makeChartDatasets()
    }
  }

  function makeChartLabels(): string[] {
    return datesAsIsoStrings
  }

  function makeChartDatasets(): ChartDataset[] {
    return [
      {
        label: '€',
        backgroundColor: blue['500'],
        borderColor: blue['500'],
        data:
          settings.calculationMode === CalculationMode.CUMULATIVE
            ? makeCumulativeEnergyCostData()
            : makeIncrementalEnergyCostData(),
        fill: settings.calculationMode === CalculationMode.CUMULATIVE
      }
    ]
  }

  function makeCumulativeEnergyCostData(): number[] {
    return energyCosts
      ? makeCumulativeEnergyCostDataUsingEnergyCosts()
      : makeCumulativeEnergyCostDataUsingSensorAggregates()
  }

  function makeCumulativeEnergyCostDataUsingEnergyCosts(): number[] {
    // @ts-ignore
    const energyCostsByDate = mapEnergyCostsByDate(energyCosts)

    let value = initialEnergyCost
    return datesAsIsoStrings.map((date) => {
      const energyCost = energyCostsByDate.get(date)
      value += energyCost ? energyCost.value : 0
      return value
    })
  }

  function makeCumulativeEnergyCostDataUsingSensorAggregates(): number[] {
    const sensorAggregatesByDate: Map<string, SensorAggregate> =
      transformSensorAggregatesToMap(sensorAggregates)

    const energyPricesByDate: Map<string, EnergyPrice> =
      transformEnergyPricesToMap(energyPrices)

    let energyCost = initialEnergyCost

    return datesAsIsoStrings.map((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)

      if (sensorAggregate) {
        // const kwh = sensorAggregate?.avg_kw
        const kwh = calculateAvgKwh(sensorAggregate)

        if (
          settings.energyPriceType === EnergyPriceType.SPOT &&
          energyPricesByDate.has(date)
        ) {
          const energyPrice = energyPricesByDate.get(date)
          // @ts-ignore
          const eurosPerKwh = energyPrice.cents_per_kwh / 100
          energyCost = energyCost + kwh * eurosPerKwh
        } else if (
          settings.energyPriceType === EnergyPriceType.FIXED &&
          settings.energyPrice !== null
        ) {
          const eurosPerKwh = settings.energyPrice / 100
          energyCost = energyCost + kwh * eurosPerKwh
        }
      }

      return energyCost
    })
  }

  function makeIncrementalEnergyCostData(): number[] {
    return energyCosts
      ? makeIncrementalEnergyCostDataUsingEnergyCosts()
      : makeIncrementalEnergyCostDataUsingSensorAggregates()
  }

  function makeIncrementalEnergyCostDataUsingSensorAggregates(): number[] {
    const sensorAggregatesByDate: Map<string, SensorAggregate> =
      transformSensorAggregatesToMap(sensorAggregates)

    const energyPricesByDate: Map<string, EnergyPrice> =
      transformEnergyPricesToMap(energyPrices)

    return datesAsIsoStrings.map((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)
      const energyPrice = energyPricesByDate.get(date)

      if (
        settings.energyPriceType === EnergyPriceType.SPOT &&
        sensorAggregate &&
        energyPrice
      ) {
        const eurosPerKwh = energyPrice.cents_per_kwh / 100
        return sensorAggregate.avg_kw * eurosPerKwh
      } else if (
        settings.energyPriceType === EnergyPriceType.FIXED &&
        sensorAggregate &&
        settings.energyPrice !== null
      ) {
        const eurosPerKwh = settings.energyPrice / 100
        return sensorAggregate.avg_kw * eurosPerKwh
      } else {
        return 0
      }
    })
  }

  function makeIncrementalEnergyCostDataUsingEnergyCosts(): number[] {
    // @ts-ignore
    const energyCostsByDate = mapEnergyCostsByDate(energyCosts)

    return datesAsIsoStrings.map((date) => {
      const energyCost = energyCostsByDate.get(date)
      return energyCost ? energyCost.value : 0
    })
  }

  return (
    <>
      {settings.calculationMode === CalculationMode.CUMULATIVE ? (
        <LineChart data={chartData} options={chartOptions} />
      ) : (
        <BarChart data={chartData} options={chartOptions} />
      )}
    </>
  )
}
