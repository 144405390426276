import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePowerSupplies } from '../hooks'
import { PowerSupply } from '../types'

type PowerSupplySelectProps = TextFieldProps & {
  powerSupplies?: PowerSupply[]
}

export default function PowerSupplySelect({
  powerSupplies,
  name = 'powerSupply',
  label,
  ...props
}: PowerSupplySelectProps) {
  const [t] = useTranslation('common')

  label = label ?? t('powerSupply')

  powerSupplies = powerSupplies ?? usePowerSupplies()

  return (
    <TextField select name={name} label={label} {...props}>
      {powerSupplies.map((powerSupply) => (
        <MenuItem key={powerSupply.id} value={powerSupply.id}>
          {powerSupply.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
