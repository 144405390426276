/**
 * Enum describing a controlled device reboot reason.
 */
export enum ControlledRebootReason {
  /**
   * 0 - "cold" start after power on/power failure.
   */
  COLD_START_AFTER_POWER_FAILURE = 0,

  /**
   * 1 - user-requested boot (action "reboot")
   */
  USER_REQUESTED_BOOT = 1,

  /**
   * 2 - booting after a successful firmware update
   */
  SUCCESSFUL_FIRMWARE_UPDATE = 2,

  /**
   * 3 - problems with the transmission of sensor data (acknowledgment from the modem that it is not possible).
   */
  SENSOR_DATA_TRANSMISSION_ISSUE = 3,

  /**
   * 4 - modem reboot failed. The restart is done when there are problems with sensor data transmission (before the entire device is booted)
   */
  MODEM_REBOOT_FAILED = 4,

  /**
   * 5 - transmission of sensor data takes too long (modem "stuck", no acknowledgment from the modem)
   */
  SENSOR_DATA_TRANSMISSION_TIMEOUT = 5,

  /**
   * 6 - booting after a failed firmware update
   */
  FAILED_FIRMWARE_UPDATE = 6,

  /**
   * 7 - the modem reboot failed after a short power outage (the whole device did not boot).
   */
  MODEM_REBOOT_FAILED_AFTER_POWER_OUTAGE = 7,

  /**
   * 8 - the time has not been received for 30 minutes.
   */
  TIME_NOT_RECEIVED_FOR_30_MINUTES = 8
}
