import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import {
  createNotification,
  createNotificationForManufacturingEnvironment
} from './notifications'
import { Upload, UploadWithLink } from '../../types/Upload'
import uploadService from '../../services/uploadService'
import { BlueprintInput } from '../../types/BlueprintInput'
import projectService from '../../services/projectService'

interface UploadState {
  uploads: Upload[]
  upload: Upload | undefined
  uploadWithLink: UploadWithLink | undefined
  listOfLevels: string[]
  show: boolean
  loading: boolean
}

export interface Filter {
  type: string
  name: string
}

const initialState: UploadState = {
  uploads: [],
  upload: undefined,
  uploadWithLink: undefined,
  listOfLevels: [],
  show: false,
  loading: false
}

const uploadSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    setUploads(state, action) {
      state.uploads = action.payload
    },
    setUpload(state, action) {
      state.uploadWithLink = action.payload
    },
    setListOfLevels(state, action) {
      state.listOfLevels = action.payload
    },
    createUpload(state, action) {
      state.uploads = [...state.uploads, action.payload]
    },
    deleteUpload(state, action) {
      state.uploads = state.uploads.filter(
        (upload) => upload.id !== action.payload
      )
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const { createUpload, setUpload } = uploadSlice.actions

//User can search for example instructions by Manufacturer or Model or Product number
//Response does not contain links to open files
export function getUploads(input: Filter) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(uploadSlice.actions.setLoading(true))
      const uploads: Upload[] = await uploadService.getUploads(input)
      dispatch(uploadSlice.actions.setUploads(uploads))
    } finally {
      dispatch(uploadSlice.actions.setLoading(false))
    }
  }
}

//GET /:id
//Returns link to the file
export function getUpload(id: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(uploadSlice.actions.setLoading(true))
      const link = await uploadService.getUpload(id)
      //Open response link automatically to new tab
      window.open(link.url, '_blank')
    } catch (error: any) {
      dispatch<any>(
        createNotificationForManufacturingEnvironment({
          show: true,
          type: 'warning',
          message: 'Something went wrong',
          timeout: 5000
        })
      )
    } finally {
      dispatch(uploadSlice.actions.setLoading(false))
    }
  }
}

export function getBlueprint(input: BlueprintInput) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(uploadSlice.actions.setLoading(true))
      const upload = await uploadService.getBlueprintService(input)
      dispatch(uploadSlice.actions.setUpload(upload))
    } catch (error: any) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Blueprint not found',
          timeout: 5000
        })
      )
    } finally {
      dispatch(uploadSlice.actions.setLoading(false))
    }
  }
}

//DELETE
export function deleteUpload(id: string) {
  return async (dispatch: Dispatch) => {
    try {
      await uploadService.deleteUpload(id)
      dispatch(uploadSlice.actions.deleteUpload(id))

      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'File deleted successfully!',
          timeout: 5000
        })
      )
    } catch (error: any) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong',
          timeout: 0
        })
      )
    }
  }
}

export default uploadSlice.reducer
