import { useState, ReactElement, MouseEvent } from 'react'
import {
  Button as MuiButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Menu,
  MenuProps,
  MenuItem,
  Stack,
  Typography,
  IconButton,
  Chip
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import { spacing } from '@mui/system'
import { MoreVertical } from 'react-feather'
import LoadingState from './LoadingState'
import StatsFigure from '../types/StatsFigure'
import { DateRange } from '../types'
import { humanizeDayRange } from '../utils/date'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/system'

/**
 * The stats menu.
 */
const StatsMenu = styled((props: MenuProps) => (
  <Menu
    elevation={8}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    PaperProps={{
      sx: {
        boxShadow: 12
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    padding: theme.spacing(3),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.6)'
        : 'rgba(255, 255, 255, 0.5)',
    '& .MuiMenu-list': {
      padding: 0
    }
  }
}))

/**
 * The button with spacings.
 */
const Button = styled(MuiButton)(spacing)

export interface DeviceStatsProps {
  title: string
  figures: StatsFigure[]
  dateRange?: DateRange | null
  menu?: ReactElement | JSX.Element
  chart?: ReactElement | JSX.Element
  loading?: boolean
}

export function DeviceStats({
  title,
  figures,
  dateRange,
  chart,
  menu,
  loading = false
}: DeviceStatsProps): ReactElement {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The anchor element for the menu.
   */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  /**
   * Indicates if the menu is open.
   */
  const open = Boolean(anchorEl)

  /**
   * The size of the figure column.
   */
  const figureColumnSize = 12 / figures.length ?? 12

  function openMenu(event: MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
  }

  function closeMenu(): void {
    setAnchorEl(null)
  }

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" mb={4}>
            {title}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={{ xs: 2 }}>
            {dateRange && (
              <Chip
                size="small"
                label={humanizeDayRange(dateRange.from, dateRange.to)}
                color="primary"
              />
            )}

            {menu && (
              <div>
                <IconButton
                  id="menu-button"
                  aria-controls={open ? 'menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={openMenu}
                >
                  <MoreVertical />
                </IconButton>
                <StatsMenu
                  id="menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={closeMenu}
                >
                  {menu}

                  {/*<Button variant="contained" mt={3} onClick={closeMenu}>
                    {t('closeSettings')}
                  </Button>*/}
                </StatsMenu>
              </div>
            )}
          </Stack>
        </Stack>

        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Grid container>
              {figures.map(({ value, unit }, index) => (
                <Grid item xs={figureColumnSize} key={index}>
                  <Typography variant="h3" fontWeight={400}>
                    {value}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    color={
                      theme.palette.mode === 'light'
                        ? 'rgba(0, 0, 0, 0.5)'
                        : 'rgba(255, 255, 255, 0.6)'
                    }
                  >
                    {unit}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            {chart && (
              <Grid container mt={6}>
                <Grid item xs={12}>
                  {chart}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default DeviceStats
