import React, { SyntheticEvent, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Link,
  Tab,
  Typography
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Add } from '@mui/icons-material'
import { getUsers, setShow, setUser } from '../../redux/slices/users'
import { RootState } from '../../redux/store'
import useAuth from '../../hooks/useAuth'
import LoadingState from '../../components/LoadingState'
import UserDataGrid from '../../components/UserDataGrid'

export default function Users(): JSX.Element {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The users.
   */
  const users = useSelector((state: RootState) => state.users.users)

  /**
   * Indicates if the users are loading.
   */
  const isLoadingUsers = useSelector((state: RootState) => state.users.loading)

  /**
   * Active users.
   */
  const activeUsers = users.filter((user) => user.deletedAt === null)

  /**
   * Deleted users.
   */
  const deletedUsers = users.filter((user) => user.deletedAt !== null)

  /**
   * The current tab.
   */
  const [currentTab, setCurrentTab] = useState<'users' | 'archive'>('users')

  useEffect(() => {
    if (currentUser) {
      dispatch(getUsers({ showDeleted: true }))
    }
  }, [currentUser])

  /**
   * Change the tab.
   */
  function changeTab(event: SyntheticEvent, tab: 'users' | 'archive'): void {
    setCurrentTab(tab)
  }

  /**
   * Open the create new user dialog.
   */
  function openCreateNewUserDialog(): void {
    dispatch(setUser(undefined))
    dispatch(setShow(true))
  }

  return (
    <React.Fragment>
      <Helmet title={t('users')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('users')}
      </Typography>

      <Divider sx={{ mt: 6 }} />

      {isLoadingUsers ? (
        <LoadingState />
      ) : (
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={changeTab}>
              <Tab label={t('activeUsers')} value="users" />
              <Tab label={t('archivedUsers')} value="archive" />
            </TabList>
          </Box>

          <Box sx={{ mt: 6 }}>
            <TabPanel value="users" sx={{ p: 0 }}>
              <Card>
                <CardHeader
                  title={t('users')}
                  action={
                    <Button
                      variant="outlined"
                      color="primary"
                      endIcon={<Add fontSize="inherit" />}
                      onClick={() => openCreateNewUserDialog()}
                    >
                      {t('newUser')}
                    </Button>
                  }
                />
                <CardContent>
                  <UserDataGrid users={activeUsers} />
                </CardContent>
              </Card>
            </TabPanel>

            <TabPanel value="archive" sx={{ p: 0 }}>
              <Card>
                <CardHeader title={t('users')} />
                <CardContent>
                  <UserDataGrid
                    users={deletedUsers}
                    hiddenFields={['phone', 'mfa']}
                  />
                </CardContent>
              </Card>
            </TabPanel>
          </Box>
        </TabContext>
      )}
    </React.Fragment>
  )
}
