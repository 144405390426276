import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Card as MuiCard,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
  Stack,
  Chip
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import useAuth from '../hooks/useAuth'
import {
  deleteUserRequest,
  getActionRequestsOfUser,
  showRequest
} from '../redux/slices/requests'
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread'
import { ActionRequest } from '../types'
import { RootState } from '../redux/store'
import LoadingState from './LoadingState'
import { Delete } from '@mui/icons-material'
import RequestAccessForm from './RequestAccessForm'
import { RequestStatus } from '../enums/RequestStatus'
import BuildIcon from '@mui/icons-material/Build'
import { deleteRequest } from '../services/requestService'
import { NotificationType } from '../enums/NotificationType'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { RequestAccessErrors } from '../enums/RequestAccessErrors'
import { createUserNotification } from '../utils/createUserNotification'
import { ActionRequestType } from '../enums/RequestAccessType'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'

const Card = styled(MuiCard)(spacing)
const Button = styled(MuiButton)(spacing)

export default function ProfileRequestManagementTable() {
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const { loading, userRequests, show } = useSelector(
    (state: RootState) => state.requests
  )

  const [selectedRequest, setSelectedRequest] = useState<
    ActionRequest | undefined
  >(undefined)
  const dispatch = useDispatch()
  const [openMessage, setOpenMessage] = useState(false)

  useEffect(() => {
    if (currentUser) {
      dispatch(getActionRequestsOfUser(parseInt(currentUser.id)))
    }
  }, [currentUser])

  //Delete request
  async function handleDelete(id: string) {
    try {
      await deleteRequest(id)
      dispatch(deleteUserRequest(id))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('requestDeleteSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }
  const handleClickOpenMessage = (request: ActionRequest) => {
    setOpenMessage(true)
    setSelectedRequest(request)
  }

  const handleCloseMessage = () => {
    setOpenMessage(false)
    setSelectedRequest(undefined)
  }

  function translateRequestType(type: string) {
    switch (type) {
      case ActionRequestType.COMPANY:
        return t('companyRoleChange')
      case ActionRequestType.PROJECT:
        return t('projectAccess')
    }
  }

  const columns: GridColumns<ActionRequest> = [
    {
      field: 'requestableName',
      headerName: t('requestableName'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        switch (params.row.requestableType) {
          case ActionRequestType.COMPANY:
            return params.row?.company?.name ?? '-'
          case ActionRequestType.PROJECT:
            return params.row?.project?.name ?? '-'
        }
      }
    },
    {
      field: 'message',
      headerName: t('message'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.message ? (
              <IconButton
                size="small"
                onClick={() => handleClickOpenMessage(params.row)}
              >
                <MarkEmailUnreadIcon />
              </IconButton>
            ) : (
              '-'
            )}
          </>
        )
      }
    },
    {
      field: 'createdAt',
      headerName: t('requestedAt'),
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params) => new Date(params.row?.createdAt).toLocaleString()
    },
    {
      field: 'requestableType',
      headerName: t('type'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params) => translateRequestType(params.row.requestableType)
    },
    {
      field: 'status',
      headerName: t('status'),
      type: 'string',
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      editable: false,
      valueGetter: (params) => params.row?.status,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.status}
            size="small"
            color={
              params.row.status === RequestStatus.APPROVED
                ? 'success'
                : params.row.status === RequestStatus.REQUESTED
                ? 'warning'
                : 'error'
            }
          />
        )
      }
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 250,
      renderCell: (params) => {
        const approvedAt = params.row.approvedAt
        const approvedString =
          approvedAt !== null ? new Date(approvedAt).toLocaleString() : '-'
        return (
          <Stack direction="row" spacing={3}>
            {params.row.status === RequestStatus.REQUESTED ||
            params.row.status === RequestStatus.REJECTED ? (
              <React.Fragment>
                <Button
                  variant="contained"
                  style={{ cursor: 'pointer' }}
                  onClick={() => dispatch(showRequest(params.row))}
                >
                  {t('editMessage')} <BuildIcon />
                </Button>

                <Button
                  color="error"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(params.row.id)}
                >
                  <Delete />
                </Button>
              </React.Fragment>
            ) : (
              `${t('approvedAt')}: ${approvedString}`
            )}
          </Stack>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <React.Fragment>
      <Dialog
        open={openMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('message')}:</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedRequest ? selectedRequest.message : '-'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleCloseMessage} autoFocus>
            {t('Request.close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Card mb={6}>
        {loading ? (
          <LoadingState />
        ) : (
          <Paper>
            {show ? (
              <RequestAccessForm />
            ) : (
              <React.Fragment>
                <DataGrid
                  rows={userRequests}
                  columns={columns}
                  autoHeight
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  disableSelectionOnClick
                  components={{
                    Toolbar: GridToolbar
                  }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 }
                    }
                  }}
                />
              </React.Fragment>
            )}
          </Paper>
        )}
      </Card>
    </React.Fragment>
  )
}
