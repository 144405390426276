import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import {
  EditedValue,
  TroubleshootEvent
} from '../../pages/pages/DeviceTroubleshoot'
import TroubleshootProps from './TroubleshootProps'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import { StatusOutput } from '../../types'
import { getStatus, invokeAction } from '../../services/deviceService'
import { useTranslation } from 'react-i18next'
import { delay } from '../../utils/delay'
import { updatePowerSupply } from '../../services/powerSupplyService'

const TEN_SECONDS_IN_MS = 10_000

interface Props extends TroubleshootProps {
  setUpdatedValues: Dispatch<SetStateAction<EditedValue[]>>
}

export default function CheckReadings({
  stateUpdate,
  device,
  setUpdatedValues
}: Props) {
  const [t] = useTranslation('common')
  /**
   * Used to stop running requests if the component is unmounted.
   */
  const isCancelled = useRef(false)
  /**
   * State that handles status gotten from device.
   */
  const [statusFromDevice, setStatusFromDevice] = useState<StatusOutput>()
  /**
   * State that handles if the info popup is open.
   */
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  const [inputCable, setInputCable] = useState(0)
  const [clampRatio, setClampRatio] = useState(0)
  const [ratedCurrent, setRatedCurrent] = useState(0)

  /**
   * State that is used to set the input fields to contain errors.
   */
  const [invalidValues, setInvalidValues] = useState({
    clampRatio: false,
    inputCable: false,
    ratedCurrent: false
  })

  const latestSensorData = device.sensorData?.latest
  const statusFromBackend = device?.powerSupply

  /**
   * Function which is run when inputCable or clampRatio is changed.
   * @param event the event send
   */
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    const id = event.target.id
    const newInvalidValues = {
      ...invalidValues
    }
    const isInvalidValue = Number.isNaN(newValue) || newValue < 0

    if (id === 'inputCable') {
      setInputCable(newValue)
      newInvalidValues.inputCable = isInvalidValue
    } else if (id === 'clampRatio') {
      setClampRatio(newValue)
      newInvalidValues.clampRatio = isInvalidValue
    } else if (id === 'ratedCurrent') {
      setRatedCurrent(newValue)
      newInvalidValues.ratedCurrent = isInvalidValue
    }
    setInvalidValues(newInvalidValues)
  }

  /**
   * Polls backend API every 10s until a status message is returned.
   */
  const getDeviceStatus = async () => {
    const maxRetries = 200
    let attempts = 0
    while (attempts < maxRetries && !isCancelled.current) {
      try {
        sendGetStatus()
        const status = await getStatus(device.name)
        setStatusFromDevice(status)
      } catch (error) {
        await delay(TEN_SECONDS_IN_MS)
        attempts += 1
      }
    }
    if (attempts === maxRetries) {
      throw new Error(`Failed to fetch status after ${maxRetries} attempts`)
    }
  }

  /**
   * Send get_status action to the device.
   */
  const sendGetStatus = async () => {
    await invokeAction(device.name, { get_status: 1 })
  }

  /**
   * Function that resents configuration to the device.
   */
  const resendConfiguration = () => {
    // Check if any of the values are invalid.
    if (
      invalidValues.clampRatio ||
      invalidValues.ratedCurrent ||
      invalidValues.inputCable
    ) {
      return
    }

    if (!device?.powerSupply) {
      stateUpdate(
        TroubleshootEvent.SendErrorTicket,
        'Power supply not found from device.'
      )
      return
    }

    const updatedValues: EditedValue[] = []
    const newPowerSupply = {
      ...device?.powerSupply,
      project: device.asset?.project?.id
    }

    if (inputCable !== 0) {
      updatedValues.push({
        name: 'Input Cables',
        oldValue: statusFromBackend?.inputCables,
        newValue: inputCable
      })
      newPowerSupply.inputCables = inputCable
    }

    if (clampRatio !== 0) {
      updatedValues.push({
        name: 'Clamp ratio',
        oldValue: statusFromBackend?.voltage_1_tp,
        newValue: clampRatio
      })
      newPowerSupply.voltage_1_tp = clampRatio
    }

    if (ratedCurrent !== 0) {
      updatedValues.push({
        name: 'Rated current',
        oldValue: statusFromBackend?.fuse,
        newValue: ratedCurrent
      })
      newPowerSupply.fuse = ratedCurrent
    }
    updatePowerSupply(newPowerSupply.id, newPowerSupply)
    setUpdatedValues(updatedValues)
    stateUpdate(TroubleshootEvent.ResendConfiguration, 'Resend configuration.')
  }

  useEffect(() => {
    getDeviceStatus()

    return () => {
      isCancelled.current = true
    }
  }, [])

  return (
    <>
      <Dialog
        open={isInfoOpen}
        onClose={() => setIsInfoOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('currentTransformerInfoTitle')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table>
              <TableRow>
                {t('currentTransformer100A') +
                  ': ' +
                  'SCT016 100 A : 0,1 A (100 mA), 1%'}
              </TableRow>
              <TableRow>
                {t('currentTransformer250A') +
                  ': ' +
                  'SCT024 250 A : 0,1 A (100 mA), 1%'}
              </TableRow>
              <TableRow>
                {t('other') + ': ' + t('currentTransformerOtherInfo')}
              </TableRow>
            </Table>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsInfoOpen(false)}
            autoFocus
          >
            {t('understood')}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {t('troubleshoot.everythingOK')}
      </Typography>

      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t('troubleshoot.crossCheckValues')}
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label="custom table">
          <TableHead>
            <TableRow>
              <TableCell>{t('troubleshoot.name')}</TableCell>
              <TableCell>{t('troubleshoot.valueFromDevice')}</TableCell>
              <TableCell>{t('troubleshoot.valueFromDatabase')}</TableCell>
              <TableCell>{t('troubleshoot.selectCorrect')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={'Clamp Ratio'}>
              <TableCell component="th" scope="row">
                {t('troubleshoot.clampRatio')}
              </TableCell>
              <TableCell>{statusFromDevice?.clamp_ratio}</TableCell>
              <TableCell>{statusFromBackend?.voltage_1_tp}</TableCell>
              <TableCell>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <TextField
                    id="clampRatio"
                    onInput={onChange}
                    type="number"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }}
                    error={invalidValues.clampRatio}
                    helperText={t('troubleshoot.clampRatio')}
                  />
                  <IconButton
                    size="small"
                    onClick={() => setIsInfoOpen(true)}
                    sx={{ mb: 5, ml: 2 }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow key={'Input Cables'}>
              <TableCell component="th" scope="row">
                {t('troubleshoot.inputCables')}
              </TableCell>
              <TableCell>{statusFromDevice?.input_cables}</TableCell>
              <TableCell>{statusFromBackend?.inputCables}</TableCell>
              <TableCell>
                <TextField
                  id="inputCable"
                  onInput={onChange}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                  error={invalidValues.inputCable}
                  helperText={t('troubleshoot.inputCables')}
                />
              </TableCell>
            </TableRow>
            <TableRow key={'Rated Current'}>
              <TableCell component="th" scope="row">
                {t('troubleshoot.ratedCurrent')}
              </TableCell>
              <TableCell>{latestSensorData?.rated_current}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                <TextField
                  id="ratedCurrent"
                  onInput={onChange}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                  error={invalidValues.ratedCurrent}
                  helperText={t('troubleshoot.ratedCurrent')}
                />
              </TableCell>
            </TableRow>
            <TableRow key={'Energy Counter'}>
              <TableCell component="th" scope="row">
                {t('troubleshoot.energyCounter')}
              </TableCell>
              <TableCell>{latestSensorData?.energy_counter}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        marginTop={5}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography align="right" sx={{ mr: 2, mt: 2 }}>
          <Button variant="contained" onClick={resendConfiguration}>
            {t('troubleshoot.resendConfiguration')}
          </Button>
        </Typography>
        <Typography align="right" sx={{ mr: 2, mt: 2 }}>
          <Button
            onClick={() => {
              stateUpdate(TroubleshootEvent.SendErrorTicket)
            }}
            variant="contained"
          >
            {t('troubleshoot.sendErrorTicket')}
          </Button>
        </Typography>
      </Grid>
    </>
  )
}
