import { useApi } from '../hooks'
import { Level, LevelInput } from '../types'

const api = useApi('/v2')

export async function getLevels(): Promise<Level[]> {
  const response = await api.get('/levels')

  return response.data
}

export async function getLevelsByProjectId(
  projectId: number
): Promise<Level[]> {
  const response = await api.get(`/projects/${projectId}/levels`)

  return response.data
}

export async function getLevel(id: number): Promise<Level> {
  const response = await api.get(`/levels/${id}`)

  return response.data
}

export async function createLevel(level: LevelInput): Promise<Level> {
  const response = await api.post('/levels', level)

  return response.data
}

export async function updateLevel(
  id: number,
  input: LevelInput
): Promise<Level> {
  const response = await api.put(`/levels/${id}`, input)

  return response.data
}

export async function deleteLevel(id: number): Promise<void> {
  await api.delete(`/levels/${id}`)
}

export default {
  getLevels,
  getLevelsByProjectId,
  getLevel,
  createLevel,
  updateLevel,
  deleteLevel
}
