import React from 'react'
import styled, { css } from 'styled-components/macro'
import { rgba } from 'polished'

import {
  Box,
  CircularProgress,
  Grid,
  Card as MuiCard,
  CardHeader,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography as MuiTypography,
  IconButton
} from '@mui/material'
import { palette, spacing } from '@mui/system'
import { green, red, blue } from '@mui/material/colors'
import { MoreVertical } from 'react-feather'
import { useState } from 'react'
import useTheme from '../../../hooks/useTheme'
import { THEMES } from '../../../constants'

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`

const Typography = styled(MuiTypography)(spacing)

// const CardHeader = styled(MuiCardHeader)

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`

const Chip = styled(MuiChip)`
  /*
  position: absolute;
  top: 16px;
  right: 16px;
  */
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  /* margin-bottom: ${(props) => props.theme.spacing(4)}; */

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`

const Percentage = styled(MuiTypography)<{
  percentagecolor: string
  illustration?: string
}>`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `}
`

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between('xs', 'lg')} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`

type StatsProps = {
  title: string
  amount: string
  unit?: string
  chip?: string
  percentagetext?: string
  percentageAltText?: string
  percentagecolor?: string
  illustration?: string
  chart?: JSX.Element
  menu?: JSX.Element
  loading?: boolean
}

const Stats: React.FC<StatsProps> = ({
  title,
  amount,
  unit,
  chip,
  percentagetext,
  percentageAltText,
  percentagecolor,
  illustration,
  chart,
  menu,
  loading = false
}) => {
  // const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { theme } = useTheme()

  const checkAmountType = (amount: string) => {
    if (
      amount === 'ONLINE' ||
      amount === 'Excellent' ||
      amount === 'Very Good' ||
      amount === 'Good' ||
      amount === 'Sub-Device'
    ) {
      return green[500]
    } else if (
      amount === 'OFFLINE' ||
      amount === 'Low' ||
      amount === 'Very Low'
    ) {
      return red[500]
    } else if (amount === 'Bluetooth') {
      return blue[500]
    } else {
      return theme === THEMES.DARK ? '#fffffff2' : '#000000F2'
    }
  }

  // const CardHeaderWithMenu() {}
  // const CardHeaderWithoutMenu() {}

  // /function openMenu(event: React.MouseEvent<HTMLButtonElement>): void {
  //   setAnchorEl(event.currentTarget)
  //   setMenuIsOpen(true)
  // }

  // function closeMenu(event: React.MouseEvent<HTMLButtonElement>): void {
  //   setAnchorEl(event.currentTarget)
  //   setMenuIsOpen(false)
  // }

  return (
    <Card illustration={illustration}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" mb={4}>
            {title}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={{ xs: 2 }}>
            {chip && !illustration && <Chip label={chip} />}

            {menu && (
              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MoreVertical />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClick={handleClose}
                  onClose={handleClose}
                  elevation={3}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  {menu}
                  {/*
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                  */}
                </Menu>
              </div>
            )}
            {/*<div>
              <IconButton onClick={openMenu}>
                <MoreVertical />
              </IconButton>
              <Menu open={menuIsOpen} onClose={closeMenu} anchorEl={anchorEl}>
                <MenuItem onClick={closeMenu}>Profile</MenuItem>
                <MenuItem onClick={closeMenu}>My account</MenuItem>
                <MenuItem onClick={closeMenu}>Logout</MenuItem>
              </Menu>
            </div>
            */}
          </Stack>
        </Stack>

        {loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '10rem'
            }}
          >
            <CircularProgress size="5vh" />
          </Box>
        ) : (
          <>
            <Typography variant="h3" mb={3}>
              <Box
                fontWeight="fontWeightRegular"
                style={{ color: checkAmountType(amount) }}
              >
                {amount}

                {unit && (
                  <Typography
                    fontSize={13}
                    fontWeight="bold"
                    color={'rgba(255, 255, 255, 0.5)'}
                    sx={{ marginTop: '0.125rem' }}
                  >
                    {unit}
                  </Typography>
                )}
              </Box>
            </Typography>

            {percentagetext && percentageAltText && percentagecolor && (
              <Percentage
                variant="subtitle2"
                color="textSecondary"
                percentagecolor={percentagecolor}
                illustration={illustration}
              >
                <span>{percentagetext}</span> {percentageAltText}
              </Percentage>
            )}

            {chart && (
              <Grid container spacing={3} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                  {chart}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </CardContent>

      {!!illustration && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
    </Card>
  )
}

export default Stats
