import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import Announcements from '../pages/pages/Announcements'
import { UserRole } from '../enums'

export default [
  {
    path: '/announcements',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <Announcements />
        </DashboardLayout>
      </AuthGuard>
    )
  }
]
