import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ZapOff } from 'react-feather'
import EmptyState from './EmptyState'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateQuery from '../types/SensorAggregateQuery'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import SensorAggregateField from '../enums/SensorAggregateField'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'
import { Device } from '../types'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { StringMap } from 'i18next'
import { SensorAggregateDataGridRow } from '../types'

// The value of "max-height" is the sum of table header and 6½ rows.
const TableWrapper = styled.div`
  overflow-x: auto;
  max-height: calc(54.78px + (6.5 * 51.58px));
  max-width: calc(100vw - (2 * 24px));
  @media (min-width: 960px) {
    max-width: calc(100vw - 258px - (2 * 48px));
  }
  margin-top: 2rem;
`
interface SensorAggregateTableProps {
  device: Device
  sensorAggregates: SensorAggregate[]
  query: SensorAggregateQuery
  options: SensorAggregateOptions
  loading?: boolean
}

type FieldLabel = {
  label: string
  key: string
}

export default function SensorAggregateTable({
  sensorAggregates,
  options,
  loading = false
}: SensorAggregateTableProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The selected statistics.
   */
  const selectedStatistics = options.statistics

  /**
   * The selected fields.
   */
  const selectedFields = options.fields

  /**
   * The timestamp label.
   */
  const timestampLabel = `${t(
    'SensorAggregate.time_bucket'
  )} (${getTimezoneString()})`

  /**
   * The field labels.
   */
  const fieldLabels = makeFieldLabels()

  /**
   * The field keys.
   */
  const fieldKeys = makeFieldKeys()

  /**
   * Make the field labels.
   */
  function makeFieldLabels(): FieldLabel[] {
    const labels: FieldLabel[] = []

    selectedFields.forEach((field) => {
      selectedStatistics.forEach((statistic) => {
        labels.push(makeFieldLabel(field, statistic))
      })
    })

    return labels
  }

  /**
   * Make the field label.
   */
  function makeFieldLabel(
    field: SensorAggregateField,
    statistic: SensorAggregateStatistic
  ): FieldLabel {
    const key = makeFieldKey(field, statistic)
    const humanizedField = t(('SensorAggregate.' + field) as string)

    return {
      label: `${humanizedField} (${t(
        ('SensorAggregate.' + statistic) as string
      )})`,
      key: key
    }
  }

  /**
   * Make the field keys.
   */
  function makeFieldKeys(): string[] {
    const keys: string[] = []

    selectedFields.forEach((field: SensorAggregateField) => {
      selectedStatistics.forEach((statistic: SensorAggregateStatistic) => {
        keys.push(makeFieldKey(field, statistic))
      })
    })
    return keys
  }

  /**
   * Make the field key.
   */
  function makeFieldKey(
    field: SensorAggregateField,
    statistic: SensorAggregateStatistic
  ): string {
    return `${statistic as string}_${field as string}`
  }

  /**
   * Get the timezone string.
   */
  function getTimezoneString(): string {
    const offset: number = new Date().getTimezoneOffset() / 60

    const symbol = offset > 0 ? '+' : '-'
    const hours =
      Math.abs(offset) < 10 ? '0' + Math.abs(offset) : Math.abs(offset)
    const minutes = offset % 1 !== 0 ? '30' : '00'

    return `UTC${symbol}${hours}:${minutes}`
  }

  /**
   * Humanize the timestamp.
   */
  function humanizeTimestamp(timestamp: string): string {
    return format(Date.parse(timestamp), 'd.M.yyyy HH:mm')
  }

  function humanizeValue(value: number): string {
    return value.toFixed(2)
  }

  const columns = [
    {
      field: 'timestamp',
      headerName: timestampLabel,
      width: 200,
      editable: false
    }
  ]

  fieldLabels.forEach((fieldLabels) =>
    columns.push({
      field: fieldLabels.key,
      headerName: fieldLabels.label,
      width: 200,
      editable: false
    })
  )

  const rows: SensorAggregateDataGridRow[] = []

  sensorAggregates.reverse().forEach((sensor) =>
    rows.push({
      id: sensor.time_bucket,
      timestamp: humanizeTimestamp(sensor.time_bucket),
      avg_current_1: humanizeValue(sensor.avg_current_1),
      avg_current_2: humanizeValue(sensor.avg_current_2),
      avg_current_3: humanizeValue(sensor.avg_current_3),
      avg_kw: humanizeValue(sensor.avg_kw),
      avg_rated_current: humanizeValue(sensor.avg_rated_current),
      avg_rh: humanizeValue(sensor.avg_rh),
      avg_rssi: humanizeValue(sensor.avg_rssi),
      avg_temp: humanizeValue(sensor.avg_temp),
      avg_voltage_1: humanizeValue(sensor.avg_voltage_1),
      avg_voltage_2: humanizeValue(sensor.avg_voltage_2),
      avg_voltage_3: humanizeValue(sensor.avg_voltage_3),
      max_current_1: humanizeValue(sensor.max_current_1),
      max_current_2: humanizeValue(sensor.max_current_2),
      max_current_3: humanizeValue(sensor.max_current_3),
      max_kw: humanizeValue(sensor.max_kw),
      max_rated_current: humanizeValue(sensor.max_rated_current),
      max_rh: humanizeValue(sensor.max_rh),
      max_rssi: humanizeValue(sensor.max_rssi),
      max_temp: humanizeValue(sensor.max_temp),
      max_voltage_1: humanizeValue(sensor.max_voltage_1),
      max_voltage_2: humanizeValue(sensor.max_voltage_2),
      max_voltage_3: humanizeValue(sensor.max_voltage_3),
      min_current_1: humanizeValue(sensor.min_current_1),
      min_current_2: humanizeValue(sensor.min_current_2),
      min_current_3: humanizeValue(sensor.min_current_3),
      min_kw: humanizeValue(sensor.min_kw),
      min_rated_current: humanizeValue(sensor.min_rated_current),
      min_rh: humanizeValue(sensor.min_rh),
      min_rssi: humanizeValue(sensor.min_rssi),
      min_temp: humanizeValue(sensor.min_temp),
      min_voltage_1: humanizeValue(sensor.min_voltage_1),
      min_voltage_2: humanizeValue(sensor.min_voltage_2),
      min_voltage_3: humanizeValue(sensor.min_voltage_3)
    })
  )

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '10rem'
        }}
      >
        <CircularProgress size="5vh" />
      </Box>
    )
  } else if (!sensorAggregates.length) {
    return (
      <EmptyState
        icon={<ZapOff size={24} />}
        title={t('SensorAggregate.empty')}
      />
    )
  } else {
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnSelector
        sx={{ height: 500 }}
        components={{
          Toolbar: GridToolbar
        }}
      />
    )
  }
}
