import { createSlice } from '@reduxjs/toolkit'
import device_groupService from '../../services/device_groupService'
import { Dispatch } from 'redux'

interface DeviceGroupState {
  device_groups: Device_group[]
  device_group: Device_group | undefined
  show: boolean
  status: 'idle' | 'loading' | 'failed'
}

// FIXME: Move to src/types/DeviceGroup
export interface Device_group {
  id: number
  name: string
}

const initialState: DeviceGroupState = {
  device_groups: [],
  device_group: undefined,
  show: false,
  status: 'idle'
}

const Device_GroupSlice = createSlice({
  name: 'device_groups',
  initialState,
  reducers: {
    setDeviceGroups(state, action) {
      state.device_groups = action.payload
    },
    setDeviceGroup(state, action) {
      state.device_group = action.payload
    },
    createDeviceGroup(state, action) {
      state.device_groups = [...state.device_groups, action.payload]
    },
    updateDeviceGroup(state, action) {
      state.device_groups = state.device_groups
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteDeviceGroup(state, action) {
      state.device_groups = state.device_groups.filter(
        (u) => u.id !== action.payload
      )
    },
    setShow(state) {
      state.show = !state.show
    }
  }
})

export const { setDeviceGroups } = Device_GroupSlice.actions

export function getDeviceGroups() {
  return async (dispatch: Dispatch) => {
    const response = await device_groupService.getDeviceGroups()
    dispatch(Device_GroupSlice.actions.setDeviceGroups(response.data))
  }
}

export function getDeviceGroup(device_group: Device_group | unknown) {
  return async (dispatch: Dispatch) => {
    dispatch(Device_GroupSlice.actions.setDeviceGroup(device_group))
  }
}

export function createDeviceGroup(device_group: Device_group) {
  return async (dispatch: Dispatch) => {
    const response = await device_groupService.createDeviceGroup(device_group)
    if (response.status === 200) {
      dispatch(Device_GroupSlice.actions.createDeviceGroup(response.data))
    }
  }
}

export function updateDeviceGroup(device_group: Device_group) {
  return async (dispatch: Dispatch) => {
    const response = await device_groupService.updateDeviceGroup(device_group)
    if (response.status === 200) {
      dispatch(Device_GroupSlice.actions.updateDeviceGroup(response.data))
    }
  }
}

export function deleteDeviceGroup(id: number) {
  return async (dispatch: Dispatch) => {
    const response = await device_groupService.deleteDeviceGroup(id)
    if (response.status === 200) {
      dispatch(Device_GroupSlice.actions.deleteDeviceGroup(id))
    }
  }
}

export function setShow() {
  return async (dispatch: Dispatch) => {
    dispatch(Device_GroupSlice.actions.setShow())
  }
}

export default Device_GroupSlice.reducer
