import { Grid } from '@mui/material'
import { RuuviTag } from '../types'
import RuuviTagGridItem from './RuuviTagGridItem'

interface RuuviTagGridProps {
  ruuviTags: RuuviTag[]
}

export default function RuuviTagGrid({ ruuviTags }: RuuviTagGridProps) {
  return (
    <Grid container spacing={6}>
      {ruuviTags.map((ruuviTag) => (
        <Grid
          item
          sx={{
            width: '100%',
            height: '343px',
            maxWidth: { sm: '100%', md: '343px' }
          }}
        >
          <RuuviTagGridItem ruuviTag={ruuviTag} />
        </Grid>
      ))}
    </Grid>
  )
}
