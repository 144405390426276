import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectNewOrOldType } from '../enums/ProjectNewOrOldType'

export default function ProjectNewOrRenovationSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField
      name="new"
      label={t('Project.new') + ' / ' + t('Project.renovation')}
      select
      {...props}
    >
      <MenuItem value={ProjectNewOrOldType.NEW}>{t('Projects.new')}</MenuItem>
      <MenuItem value={ProjectNewOrOldType.OLD}>
        {t('Projects.renovation')}
      </MenuItem>
    </TextField>
  )
}
