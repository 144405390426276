import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardActionArea,
  CardContent as MuiCardContent,
  Chip,
  Stack,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { green, amber, red, grey } from '@mui/material/colors'
import styled, { keyframes } from 'styled-components/macro'
import { Cpu as DeviceIcon, Zap as PowerSupplyIcon } from 'react-feather'
import { Device, PowerSupply, Sensor } from '../types'
import { getLastSensor, getSensorNow } from '../services/deviceService'
import { calculatePowerUsageAsPercentage } from '../utils/sensor'
import { trackOrSetValue } from '@testing-library/user-event/dist/types/document/trackValue'
import { alpha } from '@mui/material'
import useTheme from '../hooks/useTheme'
import { THEMES } from '../constants'
import { AlertRuleType, isDeviceAlerting } from '../utils/alert'
import { AlertGroup } from '../types/Alerts'

const Card = styled(MuiCard)(spacing)
const CardHeader = styled(MuiCardHeader)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `
}
const AnimatedComponent = styled.div`
  animation: ${blinkingEffect} 1s linear infinite;
`

export interface DeviceCardProps {
  device: Device
  powerSupply: PowerSupply
  alertRules: AlertGroup[]
}

export default function DeviceCard({
  device,
  powerSupply,
  alertRules
}: DeviceCardProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const [sensor, setSensor] = useState<Sensor | undefined>()
  const [isLoadingSensor, setIsLoadingSensor] = useState<boolean>(true)
  const [sensorError, setSensorError] = useState<Error>()
  const { theme } = useTheme()

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await loadSensor(controller.signal))()
    return () => controller.abort()
  }, [device])

  async function loadSensor(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoadingSensor(true)
      setSensor(await getSensorNow(device.name, { signal }))
    } catch (error: any) {
      if (error instanceof Error) {
        setSensorError(error)
        setSensor(undefined)
      }
    } finally {
      setIsLoadingSensor(false)
    }
  }

  useEffect(() => {
    //Fetch new data at the start of the minute
    const timer = setInterval(async () => {
      const sec = new Date().getSeconds()

      if (sec) return

      loadSensor()
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [sensor])

  const powerUsageAsPercentage: number = sensor
    ? calculatePowerUsageAsPercentage(sensor, powerSupply)
    : 0

  const powerUsageString = `${powerUsageAsPercentage} %`

  const powerUsageColor: string = getPowerUsageColor()

  function getPowerUsageColor(): string {
    if (powerUsageAsPercentage === 0) {
      return alpha(grey[500], 0)
    } else if (powerUsageAsPercentage < 60) {
      return alpha(green[500], 0.4)
    } else if (powerUsageAsPercentage < 85) {
      return alpha(amber[600], 0.4)
    } else {
      return alpha(red[500], 0.4)
    }
  }

  function handleClick() {
    navigate(`/devices/${device.name}`)
  }

  //TODO: Move this to utils
  function getAlertColor(alert: AlertRuleType) {
    switch (alert) {
      case AlertRuleType.SHORT_CIRCUIT:
        return alpha(red[500], 0.4)
      case AlertRuleType.HIGH_CURRENT:
        return alpha(amber[500], 0.4)
      case AlertRuleType.OVERLOADED_CURRENT:
        return alpha(red[500], 0.4)
      case AlertRuleType.HIGH_HUMIDITY:
        return alpha(amber[500], 0.4)
      case AlertRuleType.LOW_SIGNAL:
        return alpha(amber[500], 0.4)
      case AlertRuleType.HIGH_TEMPERATURE:
        return alpha(amber[500], 0.4)
      case AlertRuleType.HIGH_VOLTAGE:
        return alpha(red[500], 0.4)
      case AlertRuleType.LOW_VOLTAGE:
        return alpha(red[500], 0.4)
      default:
        return ''
    }
  }

  const alerting = sensor ? isDeviceAlerting(sensor, alertRules) : null
  const colorOfCard = alerting ? getAlertColor(alerting) : ''

  return (
    <>
      {powerUsageAsPercentage < 100 && alerting === null ? (
        <Card
          variant="outlined"
          sx={{
            borderColor: theme === THEMES.DARK ? 'white' : 'black',
            backgroundColor: powerUsageColor
          }}
        >
          <CardActionArea onClick={handleClick}>
            <CardContent padding={2}>
              <Typography
                mb={2}
                color={theme === THEMES.DARK ? 'white' : 'black'}
              >
                {powerUsageString}
              </Typography>
              <Chip
                sx={{
                  backgroundColor: device.status === true ? 'green' : 'red',
                  color: 'white'
                }}
                icon={<DeviceIcon size={12} color="white" />}
                label={device.shortUUID}
                size="small"
              />
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <AnimatedComponent>
          <Card
            variant="outlined"
            sx={{
              borderColor: theme === THEMES.DARK ? 'white' : 'black',
              backgroundColor: powerUsageColor ?? colorOfCard
            }}
          >
            <CardActionArea onClick={handleClick}>
              <CardContent padding={2}>
                <Typography
                  mb={2}
                  color={theme === THEMES.DARK ? 'white' : 'black'}
                >
                  {powerUsageString}
                </Typography>
                <Chip
                  sx={{
                    backgroundColor: device.status === true ? 'green' : 'red',
                    color: 'white'
                  }}
                  icon={<DeviceIcon size={12} color="white" />}
                  label={device.shortUUID}
                  size="small"
                />
              </CardContent>
            </CardActionArea>
          </Card>
        </AnimatedComponent>
      )}
    </>
  )
}
