import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  ToggleButton,
  Typography,
  useMediaQuery,
  Divider as MuiDivider
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import LoadingState from './LoadingState'
import { useTranslation } from 'react-i18next'
import { ActiveProject, CurrentUser, Project, PrometheusAlert } from '../types'
import { AlertGroup, UserAlertRule } from '../types/Alerts'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { useAuth } from '../hooks'
import { useTheme } from '@mui/material/styles'
import {
  getUser,
  getUserAlertRulesByProject,
  setShow,
  setShowUserAlertManagementPopUp
} from '../redux/slices/users'
import {
  toggleUserAlertRulesByProject,
  updateUserAlertRulesByProject
} from '../services/userService'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { AlertErrors } from '../enums/AlertErrors'
import { useNavigate } from 'react-router-dom'
import UserAlertManagementPopUp from './UserAlertManagementPopUp'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import { getProjectAlertRules } from '../services/projectService'
import DeviceAlertTable from './DeviceAlertTable'
import { DeviceAlertType } from '../enums/DeivceAlertType'
import { getAlerts } from '../services/alertService'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'

const Divider = styled(MuiDivider)(spacing)

export default function ProfileAlert() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()

  /**
   * Current user
   */
  const { currentUser } = useAuth()

  /**
   * User's project spesific alert rules state
   */
  const { loading, userAlertRules } = useSelector(
    (state: RootState) => state.users
  )

  /**
   * User state
   * Show user alert management popup state
   */
  const { user, showUserAlertManagementPopUp } = useSelector(
    (state: RootState) => state.users
  )

  /**
   * The theme
   */
  const theme = useTheme()

  /**
   * Is user using mobile
   */
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  /**
   * Selected project's alertrules
   */
  const [projectAlertRules, setProjectAlertRules] = useState<AlertGroup[]>([])

  /**
   * Is loading project alert rules
   */
  const [isLoadingProjectAlertRules, setisLoadingProjectAlertRules] =
    useState<boolean>(false)

  /**
   * Selected project id
   */
  const [selectedProject, setSelectedProject] = useState<number>()

  const navigate = useNavigate()

  /**
   * Initialize selected project
   */
  useEffect(() => {
    if (currentUser && currentUser?.activeProjectMembership) {
      setSelectedProject(currentUser?.activeProjectMembership.projectId)
      dispatch(getUser(parseInt(currentUser.id)))
    }
  }, [currentUser])

  /**
   * Get user's alertrules by project when selected project changes
   * Get project's alertrules by project when selected project changes
   */
  useEffect(() => {
    if (currentUser && selectedProject) {
      dispatch(
        getUserAlertRulesByProject(currentUser?.id, selectedProject as number)
      )
      loadProjectAlertRules()
    }
  }, [selectedProject])

  /**
   * Load project's alert rules
   */
  async function loadProjectAlertRules() {
    try {
      setisLoadingProjectAlertRules(true)
      setProjectAlertRules(
        await getProjectAlertRules(selectedProject as number)
      )
    } finally {
      setisLoadingProjectAlertRules(false)
    }
  }

  /**
   * Handle alert updating
   * @param data
   */
  async function handleUpdateAlerts(data: UserAlertRule[]) {
    try {
      await updateUserAlertRulesByProject(
        parseInt(currentUser?.id),
        selectedProject as number,
        data
      )
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('alertUpdateSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AlertErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  const columns = [
    {
      field: 'alertRuleName',
      headerName: t('name'),
      width: lessThanSmall ? 150 : 350,
      editable: false
    },
    {
      field: 'notificationEnabled',
      headerName: t('emailNotifications'),
      type: 'boolean',
      width: lessThanSmall ? 150 : 350,
      editable: true
    },
    {
      field: 'appNotificationEnabled',
      headerName: t('appNotifications'),
      type: 'boolean',
      width: lessThanSmall ? 150 : 350,
      editable: true
    }
  ]

  const rows: UserAlertRule[] = []
  const [customRows, setCustomRows] = useState<UserAlertRule[]>([])
  const [enableNotifications, setEnableNotifications] = useState<boolean>(false)

  /**
   * Initialize rows
   */
  useEffect(() => {
    if (userAlertRules) {
      createRows()
    }
  }, [userAlertRules])

  function createRows() {
    if (userAlertRules) {
      userAlertRules?.map((rule: UserAlertRule) => {
        rows.push({
          id: rule.id,
          userId: rule.id,
          projectId: rule.id,
          ruleGroupName: rule.ruleGroupName,
          alertRuleName: rule.alertRuleName,
          notificationEnabled: rule.notificationEnabled,
          appNotificationEnabled: rule.appNotificationEnabled
        })
      })
      setCustomRows(rows)
      setEnableNotifications(checkRules)
    }
  }

  function handleRowChange(row: any) {
    //Updated row
    const target = customRows.find((obj: UserAlertRule) => obj.id === row.id)
    Object.assign(target as UserAlertRule, {
      id: row.id,
      userId: target?.userId,
      projectId: target?.projectId,
      ruleGroupName: target?.ruleGroupName,
      alertRuleName: target?.alertRuleName,
      notificationEnabled: row.notificationEnabled,
      appNotificationEnabled: row.appNotificationEnabled
    })
    setRowIsNotModified(false)
  }

  function handleRowDataChange(params: any) {
    const alertId = Object.keys(params)
    const alertValues: any = Object.values(params)

    if (alertId[0] && alertValues[0]) {
      handleRowChange({
        id: parseInt(alertId[0]),
        notificationEnabled: alertValues[0].notificationEnabled.value,
        appNotificationEnabled: alertValues[0].appNotificationEnabled.value
      })
    }
  }
  const [rowIsNotModiefied, setRowIsNotModified] = useState(true)

  //Check if there is any rules enabled
  let enabled = false
  function checkRules() {
    for (let i = 0; i < userAlertRules.length; i++) {
      if (
        userAlertRules[i].notificationEnabled === true ||
        userAlertRules[i].appNotificationEnabled === true
      ) {
        enabled = true
        break
      }
    }
    return enabled
  }

  const [isToggling, setIsToggling] = useState<boolean>(false)

  async function handleToggleAllNotifications(enabled: boolean) {
    try {
      if (currentUser && selectedProject) {
        setIsToggling(true)
        await toggleUserAlertRulesByProject(
          parseInt(currentUser.id),
          selectedProject,
          {
            notificationEnabled: enabled,
            appNotificationEnabled: enabled
          }
        )

        setEnableNotifications(enabled)

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            enabled ? t('alertUnMuteSuccess') : t('alertMuteSuccess')
          )
        )

        await updateAllRows(enabled)
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AlertErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsToggling(false)
    }
  }

  const activeProjects = useSelector(
    (state: RootState) => state.projects.activeProjects
  )

  async function updateAllRows(enabled: boolean) {
    customRows.map(
      (rule) => (
        (rule.appNotificationEnabled = enabled),
        (rule.notificationEnabled = enabled)
      )
    )

    setCustomRows(customRows)
  }

  function handleNavigation(id: number) {
    dispatch(setShow(true))
    navigate(`/users/${id}#dashboard`)
  }

  /**Open user's alert management popup */
  function handleClickOpenUserAlertManagement() {
    dispatch(setShowUserAlertManagementPopUp(true))
  }

  /**Close user's alert management popup */
  function handleCloseUserAlertManagement() {
    dispatch(setShowUserAlertManagementPopUp(false))
  }

  /**
   * The device's active alerts
   */
  const [projectActiveAlerts, setProjectActiveAlerts] = useState<
    PrometheusAlert[]
  >([])

  const [isLoadingProjectActiveAlerts, setIsLoadingProjectActiveAlerts] =
    useState<boolean>(false)

  useEffect(() => {
    if (selectedProject) {
      loadAlerts()
    }
  }, [selectedProject])

  async function loadAlerts() {
    try {
      setIsLoadingProjectActiveAlerts(true)
      setProjectActiveAlerts(await getAlerts({ project: selectedProject }))
      //TODO: Load device specific alerts
    } finally {
      setIsLoadingProjectActiveAlerts(false)
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <FormControl sx={{ m: 3, minWidth: 120 }} size="small">
            <TextField
              fullWidth
              id="select-active-project"
              select
              value={selectedProject ?? ''}
              label={t('project')}
            >
              {activeProjects.map((project: ActiveProject) => (
                <MenuItem
                  value={project.id}
                  onClick={() => setSelectedProject(project.id)}
                >
                  {project.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right" sx={{ mt: 10, mb: 4, mr: 6 }}>
            <NotificationAddIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleClickOpenUserAlertManagement()}
            />
          </Typography>
        </Grid>
        <Dialog
          open={showUserAlertManagementPopUp}
          onClose={handleCloseUserAlertManagement}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogContent>
            {isLoadingProjectAlertRules ? (
              <LoadingState />
            ) : (
              <UserAlertManagementPopUp
                user={currentUser as CurrentUser}
                projectId={selectedProject as number}
                projectAlertRules={projectAlertRules}
                loading={loading}
                userAlertRules={userAlertRules}
                updatingOwnSettings={true}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCloseUserAlertManagement}
              autoFocus
            >
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <>
        {user?.notificationsByEmail === false && (
          <Alert severity="warning">
            {t('emailsDisabledWarning')}{' '}
            <Button
              variant="outlined"
              onClick={() => handleNavigation(user.id)}
            >
              {t('accountSettings')}
            </Button>
          </Alert>
        )}
      </>
      {isLoadingProjectActiveAlerts ? (
        <LoadingState />
      ) : (
        <DeviceAlertTable
          type={DeviceAlertType.ALERTING}
          activeAlerts={projectActiveAlerts}
        />
      )}

      <Divider my={6} />
      <Card>
        {loading || isToggling ? (
          <LoadingState />
        ) : (
          <React.Fragment>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Typography align="left">
                  <ToggleButton
                    sx={{ mt: 4, mb: 4, ml: 4 }}
                    value="mute"
                    selected={enableNotifications}
                    onChange={() => {
                      handleToggleAllNotifications(!enableNotifications)
                    }}
                  >
                    {enableNotifications ? (
                      <NotificationsIcon />
                    ) : (
                      <NotificationsOffIcon />
                    )}
                  </ToggleButton>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right">
                  <Button
                    disabled={rowIsNotModiefied}
                    sx={{ mt: 4, mb: 4, mr: 4 }}
                    variant="contained"
                    onClick={() => handleUpdateAlerts(customRows)}
                  >
                    {t('save')}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <DataGrid
              rows={customRows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              editMode="row"
              onEditRowsModelChange={(params: any) =>
                handleRowDataChange(params)
              }
              disableColumnMenu
              autoHeight
            />
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  )
}
