import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Button as MuiButton,
  Typography,
  TextField as MuiTextField,
  Alert,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getProduct,
  setProduct,
  updateProductState
} from '../../redux/slices/products'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { RootState } from '../../redux/store'
import { OrderItemType } from '../../enums/OrderItemType'
import { ProductStatus } from '../../enums/ProductStatus'
import { QrReader } from 'react-qr-reader'
import LinkDeviceWithProduct from './LinkDeviceWithProduct'
import UpdateLinkedDevice from './UpdateLinkedDevice'
import {
  createErrorOrSuccessNotification,
  createNotificationForManufacturingEnvironment
} from '../../redux/slices/notifications'
import { getDevice, setDevice } from '../../redux/slices/devices'
import { setData, setStep } from '../../redux/slices/steps'
import { QRData } from '../../enums/QRData'
import { getUpload, getUploads } from '../../redux/slices/uploads'
import UploadType from '../../enums/UploadType'
import UploadPictureForm from './UploadPictureForm'
import { Upload } from '../../types/Upload'
import Autocomplete from '@mui/material/Autocomplete'
import productService from '../../services/productService'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { ProductErrors } from '../../enums/ProductErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { OrderType } from '../../enums/OrderType'
import WarehouseSelect from '../WarehouseSelect'
import { getWarehouses, setWarehouses } from '../../redux/slices/warehouses'
import { ProductType } from '../../enums/ProductType'
import { Device, Product } from '../../types'
import LoadingState from '../LoadingState'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)

function ProductInstallInstructions() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { product } = useSelector((state: RootState) => state.products)
  const { step, data } = useSelector((state: RootState) => state.steps)
  const { device } = useSelector((state: RootState) => state.devices)
  const [shortUUID, setShortUUID] = useState('')
  const { uploads } = useSelector((state: RootState) => state.uploads)
  const { warehouses } = useSelector((state: RootState) => state.warehouses)
  const { currentUser } = useAuth()

  useEffect(() => {
    if (id && product === undefined) {
      dispatch(getProduct(parseInt(id)))
      dispatch(
        getUploads({
          type: UploadType.INSTRUCTION,
          name: UploadType.INSTRUCTION
        })
      )
    }
  }, [])

  useEffect(() => {
    if (data !== QRData.NOT_SCANNED) {
      dispatch(getDevice(data.split('/').pop() as string))
    }
  }, [data])

  function getProductStatus(product: Product) {
    switch (product?.orderItem?.order?.type as OrderType) {
      case OrderType.INBOUND:
        return ProductStatus.STORED
      case OrderType.OUTBOUND:
        return product.orderItem.type === OrderItemType.INSTALLATION_KIT
          ? ProductStatus.PREPARED
          : ProductStatus.ASSEMBLED
    }
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function handleSubmit() {
    try {
      setIsSubmitting(true)
      const updatedProduct: Product = await productService.updateProduct(
        parseInt(id as string),
        {
          status: getProductStatus(product as Product),
          warehouseId: selectedWarehouse as string
        }
      )
      dispatch(updateProductState(updatedProduct))

      //Clear warehouse state
      dispatch(setWarehouses([]))
      //Clear product
      dispatch(setProduct(undefined))
      //Clear Device
      dispatch(setDevice(undefined))
      //CLear QrData
      dispatch(setData(QRData.NOT_SCANNED))
      //CLear steps
      dispatch(setStep(0))
      navigate(`/manufacturing/orders/${product?.orderItem?.order?.id}`)
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('productUpdateSuccess')
        )
      )
    } catch (error: unknown) {
      dispatch(
        setError({
          type: ProductErrors.EDIT,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(ProductErrors.EDIT))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (device !== undefined) {
      dispatch<any>(
        createNotificationForManufacturingEnvironment({
          show: true,
          type: 'success',
          message: t('Manufacturing.deviceFound'),
          timeout: 5000
        })
      )
      dispatch(setStep(step + 1))
    }
  }, [device])

  const handleStepBack = () => {
    if (step === 0) {
      dispatch(setProduct(undefined))
      dispatch(setDevice(undefined))
      dispatch(setData(QRData.NOT_SCANNED))
      dispatch(setStep(0))
      navigate(-1)
    } else if (step === 1) {
      dispatch(setData(QRData.NOT_SCANNED))
      dispatch(setDevice(undefined))
      dispatch(setStep(step - 1))
    } else dispatch(setStep(step - 1))
  }

  const handleShortUUID = (shortUUID: string) => {
    dispatch(getDevice(shortUUID))
  }

  const handleStepForward = () => {
    if (step === 3) {
      handleSubmit()
    } else dispatch(setStep(step + 1))
  }

  const TranslateOrderItemType = (type: string) => {
    switch (type) {
      case ProductType.INSTALLATION_KIT:
        return t('Manufacturing.kit')
      case ProductType.PLUG_AND_PLAY:
        return t('Manufacturing.plug')
      case ProductType.SMART_ELECTRICAL_CABINET:
        return t('Manufacturing.cabinet')
    }
  }

  const handleOpenInstruction = (name: string | null) => {
    uploads.filter((upload: Upload) => {
      if (Object.values(upload).includes(name as string) === true) {
        dispatch(getUpload(upload.id))
      }
    })
  }

  const [defaultInstruction, setDefaultIinstruction] = useState<
    string | undefined
  >()

  function findDefaultInstructions() {
    let filteredInstruction

    uploads.map((upload: Upload) => {
      upload.name.split('/')[4] ===
      (product?.catalogProduct?.manufacturerProductCode as string)
        ? (filteredInstruction = upload.name)
        : (filteredInstruction = undefined)
    })
    setDefaultIinstruction(filteredInstruction)
  }

  useEffect(() => {
    findDefaultInstructions()
    {
      product?.orderItem?.order?.type === OrderType.INBOUND
    }
    {
      dispatch(getWarehouses())
    }
  }, [product])

  const [checked, setChecked] = useState(false)
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setChecked(false)
    } else if (event.target.checked === true) {
      setChecked(true)
    }
  }

  const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(
    null
  )

  useEffect(() => {
    if (warehouses.length > 0) {
      setSelectedWarehouse(warehouses[0].id)
    }
  }, [warehouses])

  function handleWarehouseChange(event: any) {
    setSelectedWarehouse(event.target.value)
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Card mb={6}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography align="left">
                  <Button
                    my={2}
                    size="small"
                    variant="contained"
                    onClick={() => handleStepBack()}
                  >
                    <ArrowBackIcon />
                    {t('Manufacturing.back')}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Divider my={6} />
            <Grid container spacing={6}>
              <Grid item xs={12}>
                {uploads && product && (
                  <>
                    {defaultInstruction && checked === false ? (
                      <>
                        <Grid container spacing={6}>
                          <Grid item xs={10}>
                            <Alert severity="success">
                              {t('instructionsByProductCodeFound')}
                            </Alert>
                          </Grid>
                          <Grid item xs={2} my={3}>
                            <FileOpenIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleOpenInstruction(defaultInstruction)
                              }
                            />
                          </Grid>
                        </Grid>
                        <FormControlLabel
                          control={<Checkbox onChange={handleCheckboxChange} />}
                          label={t('showAllInstructions') as string}
                        />
                      </>
                    ) : (
                      <>
                        <Autocomplete
                          options={uploads.map((option: Upload) => option.name)}
                          onChange={(event: any, newValue: string | null) => {
                            handleOpenInstruction(newValue)
                          }}
                          // defaultValue={findDefaultInstructions()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Manufacturing.instructions')}
                            />
                          )}
                        />
                        {defaultInstruction && checked === true && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={checked}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={t('showAllInstructions') as string}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Divider my={6} />
            <Grid justifyContent={'center'} alignItems="center">
              <Paper>
                {step === 0 && (
                  <>
                    <Typography align="center" variant="h6">
                      Scan the QR code.
                    </Typography>
                    <QrReader
                      scanDelay={1000}
                      onResult={(result) => {
                        if (result) {
                          dispatch(setData(result.toString()))
                        }
                      }}
                      constraints={{ facingMode: 'environment' }}
                    />
                    <Divider my={6} />
                    <Typography align="center" variant="h6">
                      {t('Manufacturing.enterShortCode')}
                    </Typography>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                      <Grid item xs={8} sm={8} md={8} lg={8} xl>
                        <TextField
                          fullWidth
                          size="small"
                          onChange={(e) => setShortUUID(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => handleShortUUID(shortUUID)}
                        >
                          {t('Manufacturing.find')}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

                {step === 1 && device && (
                  <LinkDeviceWithProduct device={device} step={step} />
                )}

                {step === 2 && (
                  <UpdateLinkedDevice
                    product={product as Product}
                    device={device as Device}
                    step={step}
                  />
                )}

                {step === 3 && (
                  <Grid item xs={12}>
                    {isSubmitting ? (
                      <LoadingState />
                    ) : (
                      <React.Fragment>
                        <UploadPictureForm device={device} />
                        <Divider my={6} />

                        <Typography align="center" variant="h6">
                          {product?.orderItem?.order?.type === OrderType.INBOUND
                            ? t('selectWarehouse') + ' '
                            : ''}
                          {t('markThis')}{' '}
                          {TranslateOrderItemType(
                            product?.orderItem?.type as string
                          )}{' '}
                          {product?.orderItem?.order?.type ===
                          OrderType.OUTBOUND
                            ? t('asReady')
                            : t('asStored')}
                        </Typography>
                        {product?.orderItem?.order?.type ===
                          OrderType.INBOUND && (
                          <Grid item xs={12} my={6}>
                            <WarehouseSelect
                              name="warehouse"
                              label={t('storage')}
                              value={selectedWarehouse}
                              fullWidth
                              variant="outlined"
                              onChange={handleWarehouseChange}
                            />
                          </Grid>
                        )}

                        <Divider my={6} />
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => handleStepForward()}
                        >
                          {t('Manufacturing.ready')}
                        </Button>
                      </React.Fragment>
                    )}
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default ProductInstallInstructions
