import EnergyPrice from '../types/EnergyPrice'

export function transformEnergyPricesToMap(
  energyPrices: EnergyPrice[]
): Map<string, EnergyPrice> {
  const energyPricesByDate: Map<string, EnergyPrice> = new Map()

  energyPrices.forEach((energyPrice) => {
    const dateAsIsoString: string = energyPrice.time
    energyPricesByDate.set(dateAsIsoString, energyPrice)
  })

  return energyPricesByDate
}

export function groupEnergyPricesByDate(
  energyPrices: EnergyPrice[]
): Map<string, EnergyPrice[]> {
  const energyPricesGroupedByDate: Map<string, EnergyPrice[]> = new Map()

  energyPrices.forEach((energyPrice) => {
    const dateAsIsoString: string = energyPrice.time

    // @ts-ignore
    const energyPricesOfDate: EnergyPrice[] = energyPricesGroupedByDate.has(
      dateAsIsoString
    )
      ? energyPricesGroupedByDate.get(dateAsIsoString)
      : []

    energyPricesGroupedByDate.set(dateAsIsoString, [
      ...energyPricesOfDate,
      energyPrice
    ])
  })

  return energyPricesGroupedByDate
}
