import {
  startOfDay,
  endOfDay,
  subHours,
  subDays,
  isToday,
  differenceInDays,
  differenceInHours
} from 'date-fns'
import DateRange from '../types/DateRange'
import { toNearestHoursInterval, toNearestMinutesInterval } from '../utils/date'
import { SensorAggregateInterval } from '../enums/SensorAggregateInterval'
import SensorAggregate from '../types/SensorAggregate'

export function resolveDateRange(
  date: Date,
  interval: SensorAggregateInterval
): DateRange {
  const to: Date = resolveToDate(date, interval)
  return {
    from: resolveFromDate(to, interval),
    to
  }
}

export function resolveFromDate(
  date: Date,
  interval: SensorAggregateInterval
): Date {
  switch (interval) {
    case SensorAggregateInterval.FIVE_MINUTES:
      return isToday(date) ? subHours(date, 24) : startOfDay(date)
    case SensorAggregateInterval.TEN_MINUTES:
      return startOfDay(subDays(date, 2))
    case SensorAggregateInterval.FIFTEEN_MINUTES:
      return startOfDay(subDays(date, 6))
    case SensorAggregateInterval.ONE_HOUR:
      return startOfDay(subDays(date, 29))
    default:
      return startOfDay(date)
  }
}

export function resolveToDate(
  date: Date,
  interval: SensorAggregateInterval
): Date {
  if (isToday(date)) {
    date = new Date()
    switch (interval) {
      case SensorAggregateInterval.FIVE_MINUTES:
        return toNearestMinutesInterval(date, 5)
      case SensorAggregateInterval.TEN_MINUTES:
        return toNearestMinutesInterval(date, 10)
      case SensorAggregateInterval.FIFTEEN_MINUTES:
        return toNearestMinutesInterval(date, 15)
      case SensorAggregateInterval.ONE_HOUR:
      default:
        return toNearestHoursInterval(date)
    }
  }

  return endOfDay(date)
}

export function toSensorAggregateMap(
  sensorAggregates: SensorAggregate[]
): Map<string, SensorAggregate> {
  const map: Map<string, SensorAggregate> = new Map()

  sensorAggregates.forEach((sensorAggregate) => {
    map.set(sensorAggregate.time_bucket, sensorAggregate)
  })

  return map
}

export function transformSensorAggregatesToMap(
  sensorAggregates: SensorAggregate[]
): Map<string, SensorAggregate> {
  return toSensorAggregateMap(sensorAggregates)
}

export function calculateMaxKwh(sensorAggregate: SensorAggregate): number {
  return (
    (sensorAggregate.max_current_1 * sensorAggregate.max_voltage_1 +
      sensorAggregate.max_current_2 * sensorAggregate.max_voltage_2 +
      sensorAggregate.max_current_3 * sensorAggregate.max_voltage_3) /
    1000
  )
}

export function calculateMinKwh(sensorAggregate: SensorAggregate): number {
  return (
    (sensorAggregate.min_current_1 * sensorAggregate.min_voltage_1 +
      sensorAggregate.min_current_2 * sensorAggregate.min_voltage_2 +
      sensorAggregate.min_current_3 * sensorAggregate.min_voltage_3) /
    1000
  )
}

export function calculateAvgKwh(sensorAggregate: SensorAggregate): number {
  return (
    (sensorAggregate.avg_current_1 * sensorAggregate.avg_voltage_1 +
      sensorAggregate.avg_current_2 * sensorAggregate.avg_voltage_2 +
      sensorAggregate.avg_current_3 * sensorAggregate.avg_voltage_3) /
    1000
  )
}

/**
 * Resolve sensor aggregate interval for the given date range.
 */
export function resolveSensorAggregateInterval({
  from,
  to
}: DateRange): SensorAggregateInterval {
  const hours = Math.abs(differenceInHours(from, to))

  if (hours <= 24) {
    return SensorAggregateInterval.FIVE_MINUTES
  } else if (hours <= 72) {
    return SensorAggregateInterval.FIFTEEN_MINUTES
  } else if (hours <= 744) {
    return SensorAggregateInterval.ONE_HOUR
  } else {
    return SensorAggregateInterval.ONE_DAY
  }
}
