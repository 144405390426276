import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NominalCurrent from '../enums/NominalCurrent'

export function NominalCurrentSelect100And250(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField
      name="nominalCurrent"
      label={t('Manufacturing.currentTransformers')}
      select
      {...props}
    >
      <MenuItem value={NominalCurrent.NOMINAL100}>
        0 - 100 A (100 A {t('Manufacturing.currentTransformers')})
      </MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL250}>
        100 A - 1000 A (250 A {t('Manufacturing.currentTransformers')})
      </MenuItem>
      <MenuItem value={NominalCurrent.OTHER}>
        {t('Manufacturing.other')}
      </MenuItem>
    </TextField>
  )
}

export function NominalCurrentSelectForSmartElectricalCabinet(
  props: TextFieldProps
) {
  const [t] = useTranslation('common')

  return (
    <TextField name="fuse" label={t('Manufacturing.nominal')} select {...props}>
      <MenuItem value={NominalCurrent.NOMINAL16}>16 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL32}>32 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL63}>63 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL125}>125 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL250}>250 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL400}>400 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL630}>630 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL800}>800 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL1000}>1000 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL1200}>1200 A</MenuItem>
      <MenuItem value={NominalCurrent.NOMINAL1250}>1250 A</MenuItem>
    </TextField>
  )
}
