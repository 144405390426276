import { useState } from 'react'
import { AssetGridViewMode, Device, Statistic } from '../types'
import LoadingState from './LoadingState'
import DeviceFilters from './DeviceFilters'
import { Box, Card, CardContent } from '@mui/material'
import DeviceDataGrid from './DeviceDataGrid'
import DeviceGrid from './DeviceGrid'

interface DeviceManagerProps {
  /**
   * The devices.
   */
  devices: Device[]

  /**
   * Indicates if the devices are loading.
   */
  loading?: boolean
}

export default function DeviceManager({
  devices,
  loading
}: DeviceManagerProps): JSX.Element {
  /**
   * The status.
   */
  const [status, setStatus] = useState<boolean | null>(null)

  /**
   * The view mode.
   */
  const [viewMode, setViewMode] = useState<AssetGridViewMode>('data')

  /**
   * The statistic.
   */
  const [statistic, setStatistic] = useState<Statistic>('avg')

  /**
   * The filtered devices.
   */
  const filteredDevices = devices.filter(
    (device) => status === null || device.status === status
  )

  /**
   * Handle view mode change.
   *
   * @param viewMode - The changed view mode.
   */
  function handleViewModeChange(viewMode: AssetGridViewMode): void {
    setViewMode(viewMode)
  }

  /**
   * Handle status change.
   *
   * @param status - The changed status.
   */
  function handleStatusChange(status: boolean | null): void {
    setStatus(status)
  }

  /**
   * Handle statistic change.
   *
   * @param statistic - The changed statistic.
   */
  function handleStatisticChange(statistic: Statistic): void {
    setStatistic(statistic)
  }

  return (
    <>
      <Box sx={{ mb: 6 }}>
        <DeviceFilters
          status={status}
          viewMode={viewMode}
          statistic={statistic}
          onStatusChange={handleStatusChange}
          onViewModeChange={handleViewModeChange}
          onStatisticChange={handleStatisticChange}
        />
      </Box>

      {viewMode === 'data' ? (
        <>
          {loading ? (
            <LoadingState />
          ) : (
            <Card>
              <CardContent>
                <DeviceDataGrid devices={filteredDevices} />
              </CardContent>
            </Card>
          )}
        </>
      ) : (
        <DeviceGrid
          devices={filteredDevices}
          size={viewMode}
          statistic={statistic}
          loading={loading}
        />
      )}
    </>
  )
}
