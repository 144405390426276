import QRLayout from '../layouts/QRLanding'
// FIXME: Move page to "pages/qr/InspectDevice.tsx".
import InspectDevice from '../pages/dashboards/QRLanding/QrLandingIndex'

// FIXME: Change path to "/qr/inspect-device" and move to "routes/qr.tsx".
export default [
  {
    path: '/inspect-device',
    element: (
      <QRLayout>
        <InspectDevice />
      </QRLayout>
    )
  }
]
