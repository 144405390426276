import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  Button,
  Grid,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getProjects,
  getProjectsByCompanyId
} from '../../redux/slices/projects'
import { getDevice } from '../../redux/slices/devices'
import DeviceForm from '../../components/DeviceForm'
import { useAuth, useDevice } from '../../hooks'
import PageHeader from '../../components/PageHeader'
import { RootState } from '../../redux/store'
import LoadingState from '../../components/LoadingState'
import { UserRole } from '../../enums/UserRole'
import device_groupService from '../../services/device_groupService'

const Card = styled(MuiCard)(spacing)
const CardHeader = styled(MuiCardHeader)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

export default function EditDevice() {
  const [t] = useTranslation('common')
  const { id } = useParams()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const device = useDevice()
  const navigate = useNavigate()

  const isLoadingDevice = useSelector(
    (state: RootState) => state.devices.loading
  )

  const breadcrumbs =
    currentUser?.role === UserRole.SUPERADMIN ||
    currentUser?.role === UserRole.ADMIN
      ? [
          {
            label: t('Device.frontpage'),
            link: '/'
          },
          {
            label: t('Device.devices'),
            link: '/devices'
          },
          {
            label: device ? device?.name : t('loading'),
            link: `/devices/${device?.name}` ?? undefined
          },
          {
            label: t('edit')
          }
        ]
      : []

  const heading = device?.powerSupply?.name
    ? `${device?.powerSupply.name} (${device?.name})` ?? ''
    : device?.name ?? ''

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getDevice(id))
    }
  }, [currentUser, id])

  if (isLoadingDevice || device === undefined) {
    return <LoadingState />
  }

  return (
    <>
      <PageHeader
        title={t('device')}
        heading={heading}
        breadcrumbs={breadcrumbs}
      />

      <Card>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <CardHeader title={t('editDevice')} />
          </Grid>
          {device.asset && device.asset.project && (
            <Grid item xs={6}>
              <Typography align="right" sx={{ mr: 2, mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/devices/${device.name}/location`)}
                >
                  {t('moveOrDetach')}
                </Button>
              </Typography>
            </Grid>
          )}
        </Grid>
        <CardContent>
          <DeviceForm />
        </CardContent>
      </Card>
    </>
  )
}
