import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartOptions } from 'chart.js'
import { Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import {
  RuuviTagCondition,
  RuuviTagConditionQuery,
  RuuviTagConditionStatistic,
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateQuery,
  RuuviTagConditionAggregateStatistic
} from '../types'
import RuuviTagConditionChart from './RuuviTagConditionChart'
import RuuviTagConditionAggregateChart from './RuuviTagConditionAggregateChart'
import RuuviTagConditionStatisticToggle from './RuuviTagConditionStatisticToggle'
import LoadingState from './LoadingState'
import { getDifferenceOfDateRangeInHours } from '../utils/date'

interface RuuviTagTemperatureChartProps {
  conditions: RuuviTagCondition[] | RuuviTagConditionAggregate[]
  query: RuuviTagConditionQuery | RuuviTagConditionAggregateQuery
  showStats?: boolean
  loading?: boolean
}

export default function RuuviTagTemperatureChart({
  conditions,
  query,
  showStats = true,
  loading = false
}: RuuviTagTemperatureChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The condition statistics of RuuviTag.
   */
  const [statistics, setStatistics] = useState<
    RuuviTagConditionStatistic[] | RuuviTagConditionAggregateStatistic[]
  >(['avg'])

  /**
   * The chart options.
   */
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        suggestedMax: 30,
        suggestedMin: -30,
        grid: {
          display: true
        }
      }
    }
  }

  /**
   * Determines whether condition aggregates are being used.
   */
  const isUsingConditionAggregates =
    getDifferenceOfDateRangeInHours(query.from, query.to) >= 24

  /**
   * The highest temperature.
   */
  const highestTemperature = Math.max(
    ...conditions.map((condition) => condition.temperatureMax)
  )

  /**
   * The lowest temperature.
   */
  const lowestTemperature = Math.min(
    ...conditions.map((condition) => condition.temperatureMin)
  )

  /**
   * The average temperature.
   */
  const averageTemperature =
    conditions
      .map((condition) => condition.temperatureAvg)
      .reduce((a, b) => a + b, 0) / conditions.length

  /**
   * Make the chip label.
   */
  function makeChipLabel(label: string, value: number): string {
    return `${label}: ${value.toFixed(2)}°C`
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h4">{t('temperature')}</Typography>
          </Grid>

          {showStats && (
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent={{ xs: 'flex-start', md: 'center' }}
              alignItems="center"
            >
              {conditions.length > 0 && (
                <Stack direction="row" gap={3}>
                  <Chip
                    label={makeChipLabel(t('min'), lowestTemperature)}
                    size="small"
                    variant="outlined"
                  />

                  <Chip
                    label={makeChipLabel(t('avg'), averageTemperature)}
                    size="small"
                    variant="outlined"
                  />

                  <Chip
                    label={makeChipLabel(t('max'), highestTemperature)}
                    size="small"
                    variant="outlined"
                  />
                </Stack>
              )}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            alignItems="center"
          >
            <RuuviTagConditionStatisticToggle
              value={statistics}
              onChange={setStatistics}
            />
          </Grid>
        </Grid>

        {loading ? (
          <LoadingState />
        ) : (
          <>
            {isUsingConditionAggregates ? (
              <RuuviTagConditionAggregateChart
                field="temperature"
                conditions={conditions as RuuviTagConditionAggregate[]}
                query={query as RuuviTagConditionAggregateQuery}
                statistics={statistics as RuuviTagConditionAggregateStatistic[]}
                options={options}
              />
            ) : (
              <RuuviTagConditionChart
                ruuviTagConditionField="temperature"
                ruuviTagConditions={conditions as RuuviTagCondition[]}
                ruuviTagConditionQuery={query as RuuviTagConditionQuery}
                ruuviTagConditionStatistics={
                  statistics as RuuviTagConditionStatistic[]
                }
                chartOptions={options}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}
