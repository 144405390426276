import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { Bolt, DeviceThermostat } from '@mui/icons-material'
import { useTheme } from '@mui/system'
import {
  Asset,
  Company,
  Device,
  RuuviTag,
  RuuviTagBattery,
  RuuviTagCondition,
  Sensor,
  Statistic
} from '../types'
import { AssetType } from '../enums/AssetType'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks'
import { UserRole } from '../enums/UserRole'
import { getLastSensor } from '../services/deviceService'
import {
  getRuuviTagBatteryNow,
  getRuuviTagConditionNow
} from '../services/ruuviTagService'
import DevicePowerBar from './DevicePowerBar'
import {
  calculateAverageCurrent,
  calculateAverageVoltage,
  calculateCurrent,
  calculatePower,
  calculateVoltage,
  isSensorNow
} from '../utils/sensor'
import { humanizeTimestamp } from '../utils/date'
import {
  calculateHumidity,
  calculatePressure,
  calculateTemperature,
  formatMacAddress,
  isConditionOutdated
} from '../utils/ruuviTag'
import RuuviTagBatteryIndicator from './RuuviTagBatteryIndicator'
import RuuviTagSignalIndicator from './RuuviTagSignalIndicator'
import AssetStatusIndicator from './AssetStatusIndicator'
import { DeviceSignal } from '../enums/DeviceSignal'
import DeviceSignalIndicator from './DeviceSignalIndicator'
import { AlertGroup } from '../types/Alerts'
import DeviceAlertIndicator from './DeviceAlertIndicator'
import RuuviTagStatsTooltip from './RuuviTagStatsTooltip'
import DeviceStatsTooltip from './DeviceStatsTooltip'

/**
 * The props of the asset data grid row stats box.
 */
interface AssetDataGridRowReadingsProps {
  /**
   * The asset.
   */
  asset: Asset

  /**
   * The alert groups.
   */
  alertGroups?: AlertGroup[]

  /**
   * The statistic.
   */
  statistic?: Statistic

  /**
   * Indicates if the asset is being loaded.
   */
  loading?: boolean
}

/**
 * A box of asset data grid row stats.
 */
function AssetDataGridRowReadings({
  asset,
  alertGroups = [],
  statistic = 'avg'
}: AssetDataGridRowReadingsProps): JSX.Element {
  return (
    <>
      {asset.type === AssetType.DEVICE && asset?.device ? (
        <DeviceDataGridRowReadings
          device={asset.device}
          alertGroups={alertGroups}
          statistic={statistic}
        />
      ) : asset.type === AssetType.RUUVI_TAG && asset?.ruuviTag ? (
        <RuuviTagDataGridReadingsBox
          ruuviTag={asset.ruuviTag}
          statistic={statistic}
        />
      ) : (
        <></>
      )}
    </>
  )
}

/**
 * The props of the device data grid row stats box.
 */
interface DeviceDataGridRowReadingsProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The alert groups.
   */
  alertGroups: AlertGroup[]

  /**
   * The statistic.
   */
  statistic?: Statistic
}

/**
 * The box of device data grid row stats.
 */
function DeviceDataGridRowReadings({
  device,
  alertGroups = [],
  statistic = 'avg'
}: DeviceDataGridRowReadingsProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The last sensor of the device.
   */
  const [sensor, setSensor] = useState<Sensor | null>(null)

  /**
   * Indicates if sensor is being loaded.
   */
  const [loading, setLoading] = useState<boolean>(true)

  /**
   * The error of loading sensor.
   */
  const [error, setError] = useState<unknown | null>(null)

  /** */
  useEffect(() => {
    const controller = new AbortController()
    loadSensor(controller.signal)
    return () => controller.abort()
  }, [])

  /**
   * Load the last sensor of the device.
   */
  async function loadSensor(signal: AbortSignal): Promise<void> {
    try {
      setLoading(true)
      setSensor(await getLastSensor(device.name))
    } catch (error: unknown) {
      console.error(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {sensor && !isSensorNow(sensor) ? (
        <DeviceStatsTooltip
          device={device}
          sensor={sensor}
          statistic={statistic}
        >
          <Alert variant="outlined" severity="warning" sx={{ border: 0, p: 0 }}>
            {!device.status ? t('deviceIsOffline') : t('sensorIsOutdated')}
          </Alert>
        </DeviceStatsTooltip>
      ) : (
        <Stack direction="row" gap={6} alignItems="center">
          <Tooltip title={t('power') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography fontWeight={600}>
                  {sensor ? Math.round(calculatePower(sensor, statistic)) : '-'}
                </Typography>
              )}
              <Typography fontSize={12} color={theme.palette.text.secondary}>
                {t('kW')}
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title={t('voltage') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography fontWeight={600}>
                  {sensor
                    ? Math.round(calculateVoltage(sensor, statistic))
                    : '-'}
                </Typography>
              )}
              <Typography fontSize={12} color={theme.palette.text.secondary}>
                {t('V')}
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title={t('current') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography fontWeight={600}>
                  {sensor
                    ? Math.round(calculateCurrent(sensor, statistic))
                    : '-'}
                  /{device?.powerSupply?.fuse ?? '-'}
                </Typography>
              )}
              <Typography fontSize={12} color={theme.palette.text.secondary}>
                {t('A')}
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title={t('powerUsage') as string}>
            <Box sx={{ width: 120 }}>
              {loading ? (
                <Skeleton variant="text" width={80} />
              ) : sensor ? (
                <DevicePowerBar device={device} sensor={sensor} size="small" />
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
          </Tooltip>

          <Box sx={{ width: 60 }}>
            <Stack direction="row" alignItems="center" gap={4}>
              {sensor && (
                <DeviceSignalIndicator
                  fontSize="small"
                  device={device}
                  sensor={sensor}
                />
              )}

              {sensor && (
                <DeviceAlertIndicator
                  device={device}
                  sensor={sensor}
                  alertGroups={alertGroups}
                  fontSize="small"
                />
              )}
            </Stack>
          </Box>

          <Tooltip title={t('measuredAt') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography>
                  {sensor ? humanizeTimestamp(sensor.time) : '-'}
                </Typography>
              )}
            </Box>
          </Tooltip>
        </Stack>
      )}
    </>
  )
}

/**
 * The props of the ruuvi tag data grid row stats box.
 */
interface RuuviTagDataGridReadingsBoxProps {
  /**
   * The ruuvi tag.
   */
  ruuviTag: RuuviTag

  /**
   * The statistic.
   */
  statistic?: Statistic
}

/**
 * The box of device data grid row stats.
 */
function RuuviTagDataGridReadingsBox({
  ruuviTag,
  statistic = 'avg'
}: RuuviTagDataGridReadingsBoxProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The latest condition of the ruuvi tag.
   */
  const [condition, setCondition] = useState<RuuviTagCondition | null>(null)

  /**
   * The latest battery of the ruuvi tag.
   */
  const [battery, setBattery] = useState<RuuviTagBattery | null>(null)

  /**
   * Indicates if the data is being loaded.
   */
  const [loading, setLoading] = useState<boolean>(true)

  /**
   * The error received when loading the data.
   */
  const [error, setError] = useState<unknown | null>(null)

  /**
   * Runs when the component is mounted.
   */
  useEffect(() => {
    const controller = new AbortController()
    loadData(controller.signal)
    return () => controller.abort()
  }, [])

  /**
   * Load the data.
   */
  async function loadData(signal: AbortSignal): Promise<void> {
    try {
      setLoading(true)
      await loadCondition(signal)
      await loadBattery(signal)
    } catch (error: unknown) {
      console.error(error)
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Load the latest condition of the ruuvi tag.
   */
  async function loadCondition(signal: AbortSignal): Promise<void> {
    setCondition(await getRuuviTagConditionNow(ruuviTag.id, { signal }))
  }

  /**
   * Load the latest battery of the ruuvi tag.
   */
  async function loadBattery(signal: AbortSignal): Promise<void> {
    setBattery(await getRuuviTagBatteryNow(ruuviTag.id, { signal }))
  }

  return (
    <>
      {condition && isConditionOutdated(condition) ? (
        <RuuviTagStatsTooltip
          ruuviTag={ruuviTag}
          condition={condition}
          battery={battery}
          statistic={statistic}
        >
          <Alert variant="outlined" severity="warning" sx={{ border: 0, p: 0 }}>
            {!ruuviTag.status ? t('deviceIsOffline') : t('conditionIsOutdated')}
          </Alert>
        </RuuviTagStatsTooltip>
      ) : (
        <Stack direction="row" alignItems="center" gap={6}>
          <Tooltip title={t('temperature') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography fontWeight={600}>
                  {condition ? calculateTemperature(condition, statistic) : '-'}
                </Typography>
              )}
              <Typography fontSize={12} color={theme.palette.text.secondary}>
                {t('°C')}
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title={t('humidity') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography fontWeight={600}>
                  {condition ? calculateHumidity(condition, statistic) : '-'}
                </Typography>
              )}
              <Typography fontSize={12} color={theme.palette.text.secondary}>
                {t('%')}
              </Typography>
            </Box>
          </Tooltip>

          <Tooltip title={t('pressure') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography fontWeight={600}>
                  {condition ? calculatePressure(condition, statistic) : '-'}
                </Typography>
              )}
              <Typography fontSize={12} color={theme.palette.text.secondary}>
                {t('hPA')}
              </Typography>
            </Box>
          </Tooltip>

          <Box sx={{ width: 120 }}>
            <Stack direction="row" gap={4} alignItems="center">
              {loading ? (
                <Skeleton variant="text" width={20} />
              ) : battery && condition ? (
                <Typography fontSize="2em" lineHeight={1}>
                  <RuuviTagBatteryIndicator
                    battery={battery}
                    condition={condition}
                    rotationAngle={90}
                  />
                </Typography>
              ) : (
                <></>
              )}

              {loading ? (
                <Skeleton variant="text" width={20} />
              ) : battery && condition ? (
                <Typography fontSize="1.5em" lineHeight={1}>
                  <RuuviTagSignalIndicator condition={condition} />
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Box>

          <Box sx={{ width: 60 }}></Box>

          <Tooltip title={t('measuredAt') as string}>
            <Box sx={{ width: 60 }}>
              {loading ? (
                <Skeleton variant="text" width={50} />
              ) : (
                <Typography>
                  {condition ? humanizeTimestamp(condition.time) : '-'}
                </Typography>
              )}
            </Box>
          </Tooltip>
        </Stack>
      )}
    </>
  )
}

/**
 * The props of the asset data grid.
 */
interface AssetDataGridProps {
  /**
   * The assets.
   */
  assets: Asset[]

  /**
   * The alert groups.
   */
  alertGroups: AlertGroup[]

  /**
   * The statistic.
   */
  statistic?: Statistic

  /**
   * Indicates assets are being loaded.
   */
  loading?: boolean

  /**
   * The hidden columns.
   */
  hiddenColumns?: string[]
}

/**
 * A data grid of assets.
 */
export default function AssetDataGrid({
  assets,
  alertGroups = [],
  statistic = 'avg',
  loading = false,
  hiddenColumns = ['company', 'project', 'lift', 'level']
}: AssetDataGridProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The page size.
   */
  const [pageSize, setPageSize] = useState<number>(24)

  /**
   * The data grid columns.
   */
  const columns = makeColumns()

  /**
   * Make the columns for the data grid.
   */
  function makeColumns(): GridColDef<Asset>[] {
    const columns: GridColDef<Asset>[] = []

    if (!isHiddenColumn('name')) {
      columns.push({
        field: 'name',
        headerName: t('name'),
        type: 'string',
        width: 300,
        editable: false,
        valueGetter: ({ row }) => row.name,
        renderCell: ({ row }) => {
          return (
            <>
              {loading ? (
                <Stack direction="row" gap={2} alignItems="center">
                  <Skeleton variant="circular" />
                  <Skeleton variant="text" width={200} />
                </Stack>
              ) : (
                <Stack direction="row" gap={2} alignItems="center">
                  {row.type === AssetType.DEVICE ? (
                    <Tooltip title={t('device') as string}>
                      <Bolt
                        fontSize="small"
                        color={theme.palette.text.secondary}
                      />
                    </Tooltip>
                  ) : row.type === AssetType.RUUVI_TAG ? (
                    <Tooltip title={t('conditionMonitoring') as string}>
                      <DeviceThermostat
                        fontSize="small"
                        color={theme.palette.text.secondary}
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  <AssetStatusIndicator asset={row} />
                  <Typography>{row.name}</Typography>
                </Stack>
              )}
            </>
          )
        }
      })
    }

    if (!isHiddenColumn('deviceCode')) {
      columns.push({
        field: 'deviceCode',
        headerName: t('deviceCode'),
        type: 'string',
        width: 200,
        editable: false,
        valueGetter: ({ row }) => {
          return row.type === AssetType.DEVICE
            ? row?.device?.name ?? '-'
            : row.type === AssetType.RUUVI_TAG
            ? row?.ruuviTag?.id ?? '-'
            : '-'
        },
        renderCell: ({ row }) => {
          return (
            <>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : row.type === AssetType.DEVICE ? (
                <Chip
                  variant="outlined"
                  size="small"
                  component={NavLink}
                  to={`/devices/${row.device?.name}`}
                  label={row?.device?.name ?? '-'}
                  clickable
                />
              ) : row.type === AssetType.RUUVI_TAG ? (
                <Chip
                  variant="outlined"
                  size="small"
                  component={NavLink}
                  to={`/ruuvi-tags/${row.ruuviTag?.id}`}
                  label={
                    row?.ruuviTag ? formatMacAddress(row.ruuviTag.id) : '-'
                  }
                  clickable
                />
              ) : (
                '-'
              )}
            </>
          )
        }
      })
    }

    if (!isHiddenColumn('company')) {
      columns.push({
        field: 'company',
        headerName: t('company'),
        type: 'string',
        width: 200,
        editable: false,
        valueGetter: ({ row }) => {
          return row?.company?.name ?? '-'
        },
        renderCell: ({ row }) => {
          return (
            <>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : row?.company ? (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  component={NavLink}
                  to={`/companies/${row.company.id}`}
                  label={row.company.name}
                  clickable
                />
              ) : (
                '-'
              )}
            </>
          )
        }
      })
    }

    if (!isHiddenColumn('project')) {
      columns.push({
        field: 'project',
        headerName: t('project'),
        type: 'string',
        width: 200,
        editable: false,
        valueGetter: ({ row }) => {
          return row?.project?.name ?? '-'
        },
        renderCell: ({ row }) => {
          return (
            <>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : row?.project ? (
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  component={NavLink}
                  to={`/projects/${row.project.id}`}
                  label={row.project.name}
                  clickable
                />
              ) : (
                '-'
              )}
            </>
          )
        }
      })
    }

    if (!isHiddenColumn('lift')) {
      columns.push({
        field: 'lift',
        headerName: t('lift'),
        type: 'string',
        width: 200,
        editable: false,
        valueGetter: ({ row }) => {
          return row?.lift?.name ?? '-'
        },
        renderCell: ({ value }) => {
          return (
            <>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <Typography>{value}</Typography>
              )}
            </>
          )
        }
      })
    }

    if (!isHiddenColumn('level')) {
      columns.push({
        field: 'level',
        headerName: t('level'),
        type: 'string',
        width: 200,
        editable: false,
        valueGetter: ({ row }) => {
          return row?.level?.name ?? '-'
        },
        renderCell: ({ value }) => {
          return (
            <>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <Typography>{value}</Typography>
              )}
            </>
          )
        }
      })
    }

    if (!isHiddenColumn('readings')) {
      columns.push({
        field: 'readings',
        headerName: '',
        type: 'string',
        editable: false,
        sortable: false,
        minWidth: 800,
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <AssetDataGridRowReadings
              asset={row}
              statistic={statistic}
              loading={loading}
            />
          )
        }
      })
    }

    return columns
  }

  /**
   * Check if the column is hidden.
   *
   * @param {string} column The column.
   *
   *
   * @returns {boolean}
   */
  function isHiddenColumn(column: string): boolean {
    return hiddenColumns.includes(column)
  }

  /**
   * Check if the current user can view company.
   */
  function canViewCompany(company: Company): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      currentUser?.role === UserRole.ADMIN ||
      currentUser?.company?.id === company.id
    )
  }

  return (
    <DataGrid
      columns={columns}
      rows={assets}
      autoHeight
      pageSize={pageSize}
      onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
      disableSelectionOnClick
      onRowClick={({ row }) => {
        if (row.type === AssetType.DEVICE) {
          navigate(`/devices/${row.device?.name}`)
        } else if (row.type === AssetType.RUUVI_TAG) {
          navigate(`/ruuvi-tags/${row.ruuviTag?.id}`)
        }
      }}
      components={{
        Toolbar: GridToolbar
      }}
      componentsProps={{
        toolbar: {
          csvOptions: { disableToolbarButton: true },
          printOptions: { disableToolbarButton: true },
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 250 }
        }
      }}
      sx={{
        border: 0,
        cursor: 'pointer'
      }}
    />
  )
}
