import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { Divider as MuiDivider, Typography, Skeleton } from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { RootState } from '../../redux/store'
import { getDevicesByCompanyId, fetchDevices } from '../../redux/slices/devices'
import useAuth from '../../hooks/useAuth'
import { UserRole } from '../../enums/UserRole'
import DeviceManager from '../../components/DeviceManager'

const Divider = styled(MuiDivider)(spacing)

export default function Devices() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The devices state.
   */
  const { devices, loading } = useSelector((state: RootState) => state.devices)

  /**
   * Loads devices when the current user changes.
   */
  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(fetchDevices())
      } else if (currentUser?.role === UserRole.ADMIN) {
        dispatch(getDevicesByCompanyId(currentUser.company.id))
      }
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <Helmet title={t('Devices.devices')} />

      {loading ? (
        <Skeleton variant="text" width={100} sx={{ fontSize: '1.5rem' }} />
      ) : (
        <Typography variant="h3">{t('Devices.devices')}</Typography>
      )}

      <Divider my={6} />

      <DeviceManager devices={devices} loading={loading} />
    </React.Fragment>
  )
}
