import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Stats from '../Default/Stats'
import { green, red, blue } from '@mui/material/colors'
import { useParams } from 'react-router-dom'
import {
  Grid,
  Divider as MuiDivider,
  Typography,
  TextField as MuiTextField,
  Card,
  CardContent,
  Button as MuiButton,
  MenuItem
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import PowerConsumptionBar from './PowerConsumptionBar'
import ConsumptionTable from './ConsumptionTable'
import NavbarLanguagesDropdown from '../../../components/navbar/NavbarLanguagesDropdown'
import { ReactComponent as WhiteLogo } from '../../../vendor/spine-white-logo-text.svg'
import { ReactComponent as BlueLogo } from '../../../vendor/spine-blue-logo-text.svg'
import VoltageChart from './VoltageChart'
import {
  getPublicDeviceByName,
  getPublicSensorNow
} from '../../../services/publicService'
import checkRssiSignal from '../../../utils/checkRssiSignal'
import useTheme from '../../../hooks/useTheme'
import { THEMES } from '../../../constants'
import { Device, Sensor } from '../../../types'

const Divider = styled(MuiDivider)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)
const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`
function InspectDevice() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const { shortUUID } = useParams()
  const [sensor, setSensor] = useState<Sensor>()
  const [device, setDevice] = useState<Device>()
  const [action, setAction] = useState('')
  const { theme } = useTheme()

  useEffect(() => {
    if (shortUUID) {
      loadDevice()
      loadSensor()
    }
  }, [shortUUID])

  async function loadDevice(): Promise<void> {
    if (shortUUID) {
      setDevice(await getPublicDeviceByName(shortUUID))
    }
  }
  async function loadSensor(): Promise<void> {
    if (shortUUID) {
      setSensor(await getPublicSensorNow(shortUUID))
    }
  }

  useEffect(() => {
    if (action === 'edit' && device) {
      navigate(`/devices/${device.name}/edit`)
    } else if (action === 'install' && device) {
      navigate('/installation')
    }
  }, [action])

  const checkRssiColor = (value: number) => {
    if (value > -80 || value == -255) {
      return green[500]
    } else if (value == -254) {
      return blue[500]
    } else return red[500]
  }

  return (
    <React.Fragment>
      <Helmet title="Inspect" />
      <Grid justifyContent="center" alignItems="center" container spacing={6}>
        {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}
        <Divider my={6} />
      </Grid>
      <Grid container spacing={6}>
        <Grid justifyContent="center" alignItems="center" container>
          <NavbarLanguagesDropdown />
        </Grid>
        {device && (
          <>
            <Grid justifyContent="center" alignItems="center" container>
              <Typography variant="h3">
                {device?.powerSupply?.name} ({device?.shortUUID})
              </Typography>
            </Grid>

            <Divider my={6} />
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <TextField
                    name="company"
                    label={t('Device.actions')}
                    fullWidth
                    select
                    value={action}
                    variant="outlined"
                    my={2}
                  >
                    <MenuItem value={'edit'} onClick={() => setAction('edit')}>
                      {t('Device.edit')}
                    </MenuItem>
                    <MenuItem
                      value={'edit'}
                      onClick={() => setAction('install')}
                    >
                      {t('Device.install')}
                    </MenuItem>
                  </TextField>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
      <Divider my={6} />
      {device && sensor ? (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <PowerConsumptionBar device={device} sensor={sensor} />
            </Grid>
            <Grid item xs={12}>
              <Stats
                title={t('Device.status')}
                amount={device.status ? 'ONLINE' : 'OFFLINE'}
                chip={t('Device.now')}
                percentagetext={new Date(sensor.time).toLocaleString()}
                percentageAltText={t('Device.latest')}
                percentagecolor={green[500]}
              />
            </Grid>
            <Grid item xs={12}>
              <ConsumptionTable device={device} sensor={sensor} />
            </Grid>

            <Grid item xs={12}>
              <VoltageChart sensor={sensor} />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl>
                <Stats
                  title={t('Device.signal')}
                  amount={
                    sensor.rssi !== undefined
                      ? (checkRssiSignal(Math.round(sensor.rssi)) as string)
                      : ''
                  }
                  chip={t('Device.now')}
                  percentagetext={
                    sensor.rssi !== undefined
                      ? Math.round(sensor.rssi).toString() + 'dB'
                      : ''
                  }
                  percentageAltText={t('Device.latest')}
                  percentagecolor={checkRssiColor(sensor.rssi)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid justifyContent="center" alignItems={'center'} container>
            <Typography variant="h1" color={'error'}>
              {t('Device.notOnline')}
            </Typography>
          </Grid>
          <Divider my={6} />
        </>
      )}
      {device && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => navigate(`/devices/${device.name}`)}
            >
              {t('Device.signIn')}
            </Button>
            <Divider my={6} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default InspectDevice
