import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik } from 'formik'

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Stack
} from '@mui/material'
import { spacing } from '@mui/system'

import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

// eslint-disable-next-line
function SignIn() {
  const { signIn } = useAuth()
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password)

          // eslint-disable-next-line
        } catch (error: any) {
          dispatch(
            setError({
              type: AuthErrors.SIGN_IN,
              error: error
            })
          )

          //Create message
          const errorMessage = createUserNotification({
            user: null,
            type: AuthErrors.SIGN_IN,
            error: error
          })

          //Dispatch error message
          dispatch<unknown>(
            createErrorOrSuccessNotification(
              NotificationType.WARNING,
              t(errorMessage.key) + t(errorMessage.message)
            )
          )
          //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
          dispatch(deleteError(AuthErrors.SIGN_IN))
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Stack direction="column" gap={4}>
            <Button
              data-cy="submit"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign in
            </Button>

            <Stack direction="column" gap={1}>
              <Button
                component={Link}
                to="/auth/reset-password"
                fullWidth
                color="primary"
              >
                Forgot password
              </Button>

              <Button
                component={Link}
                to="/auth/sign-up"
                fullWidth
                color="primary"
              >
                New user? Click here to create an account
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </Formik>
  )
}

export default SignIn
