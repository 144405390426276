import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { setShowInstructions } from '../redux/slices/notifications'
import { LibraryAddRounded } from '@mui/icons-material'

export function InstructionForNewUser() {
  const [t] = useTranslation('common')
  const showInstructions = useSelector(
    (state: RootState) => state.notifications.showInstructions
  )
  const dispatch = useDispatch()

  return (
    <Dialog
      open={showInstructions}
      onClose={() => dispatch(setShowInstructions(false))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('welcomeTitle')}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('followTheseInstructions')}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          <ol>
            <li>{t('instructionsForNewUser.step2')}</li>
            <li>{t('instructionsForNewUser.step3')}</li>
            <li>{t('instructionsForNewUser.step4')}</li>
            <li>{t('instructionsForNewUser.step5')}</li>
          </ol>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => dispatch(setShowInstructions(false))}
          autoFocus
        >
          {t('FrontPage.understood')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
