import {
  Typography,
  Button,
  Grid,
  TextField as MuiTextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@mui/material'
import TroubleshootProps from './TroubleshootProps'
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import InfoIcon from '@mui/icons-material/Info'
import {
  EditedValue,
  TroubleshootEvent
} from '../../pages/pages/DeviceTroubleshoot'
import {
  invokeAction,
  getStatus,
  getLatestReboot
} from '../../services/deviceService'
import { StatusOutput } from '../../types'
import { useTranslation } from 'react-i18next'
import { delay } from '../../utils/delay'
import { updatePowerSupply } from '../../services/powerSupplyService'
import { RebootMessage } from '../../types/StatusMessage'
import { isEnumVariant } from '../../utils/enum'
import { ControlledRebootReason } from '../../enums/ControlledRebootReason'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
interface ErrorCodeProps extends TroubleshootProps {
  errorCode: number
  setUpdatedValues: Dispatch<SetStateAction<EditedValue[]>>
}

const TEN_SECONDS_IN_MS = 10 * 1000

export default function ErrorCode({
  device,
  errorCode,
  stateUpdate,
  setUpdatedValues
}: ErrorCodeProps) {
  const [t] = useTranslation('common')
  const [isRebootInfoOpen, setIsRebootInfoOpen] = useState(false)
  /**
   * Used to stop running requests if the component is unmounted.
   */
  const isCancelled = useRef(false)

  const [clampRatio, setClampRatio] = useState(0)
  const [inputCable, setInputCable] = useState(0)
  const [retries, setRetries] = useState({
    reboot: 0,
    status: 0
  })
  /**
   * State that handles if any of the selectable values are invalid.
   */
  const [invalidValues, setInvalidValues] = useState({
    clampRatio: false,
    inputCable: false
  })
  /**
   * State for handling status from device.
   */
  const [deviceStatus, setDeviceStatus] = useState<StatusOutput>()
  const [deviceReboot, setDeviceReboot] = useState<RebootMessage>()

  const [currentErrorCode, setCurrentErrorCode] = useState(errorCode)
  const [missingInputCable, setIsMissingInputCable] = useState(errorCode >= 4)
  const [missingClampRatio, setIsMissingClampRatio] = useState(
    errorCode === 2 || errorCode === 3 || errorCode === 6 || errorCode === 7
  )
  const missingTimeStamp = errorCode % 2 == 1

  /**
   * Function which handles textField onChange events
   * @param event
   */
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    const id = event.target.id
    const newInvalidValues = {
      ...invalidValues
    }
    const isInvalidValue = Number.isNaN(newValue) || newValue < 0
    if (id === 'inputCable') {
      newInvalidValues.inputCable = isInvalidValue
      setInputCable(newValue)
    } else if (id === 'clampRatio') {
      newInvalidValues.clampRatio = isInvalidValue
      setClampRatio(newValue)
    }
    setInvalidValues(newInvalidValues)
  }

  /**
   * Send reboot action to the device.
   */
  const sendRebootDevice = async () => {
    await invokeAction(device.name, { reboot: 1 })
  }

  /**
   * Send get_status action to the device.
   */
  const sendGetStatus = async () => {
    await invokeAction(device.name, { get_status: 1 })
  }

  /**
   * Handles status update.
   *
   * @param newStatus - the status message received from device.
   */
  const handleDeviceStatus = (newStatus: StatusOutput) => {
    setDeviceStatus(newStatus)
    const isMissingInputCables = !newStatus.input_cables
    const isMissingClampRatio = !newStatus.clamp_ratio
    if (isMissingClampRatio && isMissingInputCables) {
      setCurrentErrorCode(6)
    } else if (missingClampRatio) {
      setCurrentErrorCode(2)
    } else if (isMissingInputCables) {
      setCurrentErrorCode(4)
    }

    setIsMissingInputCable(isMissingInputCables)
    setIsMissingClampRatio(isMissingClampRatio)
  }

  /**
   * Polls backend api until a device reboot message is returned.
   */
  const getDeviceReboot = async () => {
    const maxRetries = 200

    let attempts = 0
    while (attempts < maxRetries && !isCancelled.current) {
      try {
        const reboot = await getLatestReboot(device.name)
        setDeviceReboot(reboot)
        return
      } catch (error) {
        await delay(TEN_SECONDS_IN_MS)
        attempts += 1
        setRetries({
          reboot: retries.reboot + 1,
          status: retries.status
        })
      }
    }
    if (attempts === maxRetries) {
      throw new Error(
        `Failed to fetch reboot reason after ${maxRetries} attempts`
      )
    }
  }

  /**
   * Polls backend API every 10s until a status message is returned.
   */
  const getDeviceStatus = async () => {
    const maxRetries = 200

    let attempts = 0
    while (attempts < maxRetries && !isCancelled.current) {
      try {
        await sendGetStatus()
        const status = await getStatus(device.name)
        handleDeviceStatus(status)
        return
      } catch (error) {
        await delay(TEN_SECONDS_IN_MS)
        attempts += 1
        setRetries({
          reboot: retries.reboot,
          status: retries.status + 1
        })
      }
    }
    if (attempts === maxRetries) {
      throw new Error(`Failed to fetch status after ${maxRetries} attempts`)
    }
  }

  /**
   * Function to handle reboot -> getStatus command chain.
   */
  const handleRebootAndGetStatus = async () => {
    await sendRebootDevice()
    await Promise.all([getDeviceStatus(), getDeviceReboot()])
  }

  /**
   * Function which handles resend configuration.
   * Sends reboot and get_status if timestamp is missing from the device
   */
  const resendConfigurationOnClick = () => {
    if (!device?.powerSupply) {
      stateUpdate(
        TroubleshootEvent.ResendConfiguration,
        'Error resending configuration: No power supply found in device.'
      )
      return
    }
    setDeviceStatus(undefined)
    // Check if needed values are incorrect
    if (missingInputCable && invalidValues.inputCable) {
      return
    }
    if (missingClampRatio && invalidValues.clampRatio) {
      return
    }
    const newPowerSupply = {
      ...device.powerSupply,
      project: device.asset?.project?.id,
      parent: device.powerSupply?.parent?.id
    }
    const updated: EditedValue[] = []

    // Change values if they are needed.
    if (missingClampRatio) {
      updated.push({
        name: 'Clamp ratio',
        newValue: clampRatio
      })
      newPowerSupply.voltage_1_tp = clampRatio
    }
    if (missingInputCable) {
      updated.push({
        name: 'Input Cables',
        newValue: inputCable
      })
      newPowerSupply.inputCables = inputCable
    }

    setUpdatedValues(updated)
    updatePowerSupply(newPowerSupply.id, newPowerSupply)

    handleRebootAndGetStatus().catch((error) =>
      stateUpdate(
        TroubleshootEvent.ResendConfiguration,
        `Retried get_status ${retries} times. Failed to resend configuration: ${error}`
      )
    )
  }

  useEffect(() => {
    // On error code 1 we can just instantly send correct actions to the device
    if (currentErrorCode === 1) {
      handleRebootAndGetStatus().catch((error) => {
        stateUpdate(
          TroubleshootEvent.ResendConfiguration,
          `Failed to resend configuration: ${error}`
        )
      })
    }

    return () => {
      isCancelled.current = true
    }
  }, [currentErrorCode])

  // Only error codes from 1-7 can be fixed using this troubleshoot
  if (currentErrorCode > 7 || currentErrorCode < 1) {
    stateUpdate(
      TroubleshootEvent.InvalidErrorCode,
      `Encountered an invalid error code: ${currentErrorCode}.`
    )
    return (
      <>
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
          {`${t('troubleshoot.invalidErrorCode')}: ${currentErrorCode}`}
        </Typography>

        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t('troubleshoot.allowedErrorCodes')}
        </Typography>
      </>
    )
  }

  return (
    <>
      <Dialog open={isRebootInfoOpen}>
        <DialogTitle id="alert-dialog-title">
          {t('troubleshoot.rebootInfo.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('troubleshoot.rebootInfo.infoText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsRebootInfoOpen(false)}
            autoFocus
          >
            {t('FrontPage.understood')}
          </Button>
        </DialogActions>
      </Dialog>

      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {`${t('troubleshoot.errorCode')}: ${currentErrorCode} ${t(
          `troubleshoot.errorCodes.${currentErrorCode}.name`
        )}`}
      </Typography>

      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t(`troubleshoot.errorCodes.${currentErrorCode}.text`)}
      </Typography>

      {(missingInputCable || missingClampRatio) && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={4}
        >
          {missingInputCable && (
            <TextField
              id="inputCable"
              onInput={onChange}
              type="number"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              error={invalidValues.inputCable}
              helperText="Input cable amount"
            />
          )}
          {missingClampRatio && (
            <TextField
              id="clampRatio"
              type="number"
              onInput={onChange}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
              error={invalidValues.clampRatio}
              helperText="Clamp ratio"
            />
          )}
        </Grid>
      )}

      {missingTimeStamp && (
        <TableContainer component={Paper}>
          <Table aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell>{t('troubleshoot.rebootTime')}</TableCell>
                <TableCell>
                  {t('troubleshoot.rebootReason')}
                  <InfoIcon
                    onClick={() => setIsRebootInfoOpen(true)}
                    sx={{
                      verticalAlign: 'middle',
                      marginLeft: '5px'
                    }}
                  />
                </TableCell>
                <TableCell>{t('troubleshoot.rebootDescription')}</TableCell>
                <TableCell>{t('troubleshoot.relay')}</TableCell>
                <TableCell>{t('troubleshoot.clampRatio')}</TableCell>
                <TableCell>{t('troubleshoot.inputCables')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {deviceStatus && deviceReboot ? (
                  <>
                    <TableCell>{deviceReboot.time}</TableCell>
                    <TableCell
                      sx={{
                        color: !isEnumVariant(
                          deviceReboot.reason,
                          ControlledRebootReason
                        )
                          ? 'red'
                          : undefined
                      }}
                    >
                      {deviceReboot.reason}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: !isEnumVariant(
                          deviceReboot.reason,
                          ControlledRebootReason
                        )
                          ? 'red'
                          : undefined
                      }}
                    >
                      {isEnumVariant(
                        deviceReboot.reason,
                        ControlledRebootReason
                      )
                        ? t(`rebootCodes.${deviceReboot.reason}`)
                        : t('rebootCodes.internal')}
                    </TableCell>
                    <TableCell>{deviceStatus.relay}</TableCell>
                    <TableCell
                      sx={{
                        color: missingClampRatio ? 'red' : undefined
                      }}
                    >
                      {missingClampRatio ? 'missing' : deviceStatus.clamp_ratio}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: missingInputCable ? 'red' : undefined
                      }}
                    >
                      {missingInputCable
                        ? 'missing'
                        : deviceStatus.input_cables}
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                  </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {(missingClampRatio || missingInputCable) && (
          <Typography align="right" sx={{ mr: 2, mt: 2 }}>
            <Button variant="contained" onClick={resendConfigurationOnClick}>
              {'Resend configurations'}
            </Button>
          </Typography>
        )}
        <Typography align="right" sx={{ mr: 2, mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!deviceStatus || !deviceReboot) {
                stateUpdate(TroubleshootEvent.Skip, [
                  'User clicked skip. Device status or reboot was never gotten.',
                  `Reboot retries: ${retries.reboot}. Reboot message: ${deviceReboot}`,
                  `Status retries: ${retries.status}. Status message: ${deviceStatus}`
                ])
                return
              }
              stateUpdate(TroubleshootEvent.Skip, [
                `User clicked skip.`,
                `Device status was fetched after ${retries.status} retries and it was: ${deviceStatus}`,
                `Device reboot was fetched after ${retries.reboot} retries and it was: ${deviceReboot} `
              ])
            }}
          >
            {t('troubleshoot.skip')}
          </Button>
        </Typography>
        {!!deviceStatus && (
          <Typography align="right" sx={{ mr: 2, mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                stateUpdate(TroubleshootEvent.ResendConfiguration, [
                  `User clicked continue.`,
                  `Device status was fetched after ${retries.status} retries and it was: ${deviceStatus}`,
                  `Device reboot was fetched after ${retries.reboot} retries and it was: ${deviceReboot} `
                ])
              }}
            >
              {t('troubleshoot.continue')}
            </Button>
          </Typography>
        )}
      </Grid>
    </>
  )
}
