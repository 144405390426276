import { ActionRequest, UpdateRequestInput, RequestInput } from '../types'
import { useApi } from '../hooks'
import UpdateActionRequestStateInput from '../types/UpdateActionRequestState'

const api = useApi('v2')

export async function getRequests(): Promise<ActionRequest[]> {
  const response = await api.get('/action-requests')

  return response.data
}

export async function createRequest(
  input: RequestInput
): Promise<ActionRequest[]> {
  const response = await api.post('/action-requests', input)

  return response.data
}

export async function updateRequest(
  id: string,
  input: UpdateRequestInput
): Promise<ActionRequest> {
  const response = await api.put(`/action-requests/${id}`, input)

  return response.data
}

export async function updateRequestState(
  id: string,
  input: UpdateActionRequestStateInput
): Promise<ActionRequest> {
  const response = await api.post(`/action-requests/${id}/update-state`, input)

  return response.data
}

export async function deleteRequest(id: string): Promise<void> {
  await api.delete(`/action-requests/${id}`)
}

export default {
  createRequest,
  getRequests,
  updateRequest,
  deleteRequest
}
