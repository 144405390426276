import { Navigate, useParams } from 'react-router-dom'

interface RedirectProps {
  to: string
}

export default function Redirect({ to }: RedirectProps) {
  const params = useParams()

  // Replace the params in the path.
  const path = Object.keys(params).reduce((path, key) => {
    return params[key] ? path.replace(`:${key}`, params[key]) : path
  }, to)

  return <Navigate to={path} replace />
}
