import { AxiosPromise } from 'axios'
import { UserRole } from '../enums/UserRole'
import { AlertNotifications, UserAlertRule } from '../types/Alerts'
import {
  User,
  UserQuery,
  UserInput,
  ProjectMember,
  ActionRequest,
  OtpInput,
  SendEmailFromInstaller
} from '../types'
import useApi from '../hooks/useApi'
import useAxios from '../hooks/useAxios'

const api = useApi('/v2')

const axios = useAxios()

export async function getUsers(query?: UserQuery): Promise<User[]> {
  const response = await api.get(`/users`, {
    params: {
      showDeleted: query?.showDeleted ?? false
    }
  })

  return response.data
}

export async function getUser(id: number): Promise<User> {
  const response = await api.get(`/users/${id}`)

  return response.data
}

export async function getProjectMembers(id: number): Promise<ProjectMember[]> {
  const response = await api.get(`/projects/${id}/members`)

  return response.data
}

export async function createUser(input: UserInput): Promise<User> {
  const response = await api.post('/users', input)

  return response.data
}

export async function updateUser(id: number, input: UserInput): Promise<User> {
  const response = await api.put(`/users/${id}`, input)

  return response.data
}

export async function deleteUser(
  id: number,
  permanently = false
): Promise<void> {
  await api.delete(`/users/${id}`, {
    params: {
      permanently
    }
  })
}

export async function recoverUser(id: number): Promise<void> {
  await api.patch(`/users/${id}/recover`)
}

export interface ManufacturerInvite {
  email: string
  role: UserRole
}

export async function inviteManufacturer(
  email: ManufacturerInvite
): Promise<void> {
  await api.post('/users/invite-manufacturer', email)
}

export async function sendSendEmailFromInstaller(
  input: SendEmailFromInstaller
): Promise<SendEmailFromInstaller> {
  const response = await api.post('/users/email-from-installer', input)

  return response.data
}

export async function getUserAlertRules(id: number): Promise<UserAlertRule[]> {
  const response = await api.get(`/users/${id}/alertrules`)

  return response.data
}

export async function getUserAlertRulesByProject(
  id: number,
  projectId: number
): Promise<AxiosPromise> {
  const response = await api.get(`/users/${id}/alertrules/project/${projectId}`)
  return response.data
}

export async function updateUserAlertRuleNotifications(
  id: number,
  projectId: number
): Promise<UserAlertRule[]> {
  const response = await api.get(`/users/${id}/alertrules/project/${projectId}`)
  return response.data
}

export async function updateUserAlertRules(
  id: number,
  input: UserAlertRule[]
): Promise<UserAlertRule[]> {
  const response = await api.put(`/users/${id}/alertrules`, input)
  return response.data
}

// TODO: PATCH /users/:id/alertrules/?projectId=:projectId
export async function updateUserAlertRulesByProject(
  id: number,
  projectId: string | number,
  input: UserAlertRule[]
): Promise<UserAlertRule[]> {
  const response = await api.put(
    `/users/${id}/alertrules/project/${projectId}`,
    input
  )

  return response.data
}

// TODO: Use different endpoint instead of different method for batch update.
// Eg. PATCH /users/:id/alertrules/toggle?projectId=:projectId
export async function toggleUserAlertRulesByProject(
  id: number,
  projectId: number,
  input: AlertNotifications
): Promise<AxiosPromise> {
  const response = await api.patch(
    `/users/${id}/alertrules/project/${projectId}`,
    input
  )

  return response.data
}

export async function getUserAlertRule(
  id: number,
  ruleId: string
): Promise<UserAlertRule> {
  const response = await api.get(`/users/${id}/alertrules/${ruleId}`)

  return response.data
}

// FIXME: Add type "UserAlertRuleInput".
export async function updateUserAlertRule(
  id: number,
  input: any
): Promise<UserAlertRule> {
  const response = await api.put(`/users/${id}/alertrules/${input.id}`, input)

  return response.data
}

// TODO: DELETE /users/:id/alertrules
export async function deleteUserAlertRule(id: number): Promise<void> {
  await api.delete(`/users/alertrules/${id}`)
}

export async function getActionRequestsOfUser(
  id: number
): Promise<ActionRequest[]> {
  const response = await api.get(`users/${id}/action-requests`)

  return response.data
}

export async function sendOtpToEmail(input?: OtpInput): Promise<void> {
  //  If input is provided, user is setting up mfa. If not, user is trying to login.
  await axios.post(`users/otp-to-email`, input)
}

export async function disableMfa(id: string): Promise<void> {
  await axios.post(`users/${id}/disable-mfa`)
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  getProjectMembers,
  inviteManufacturer,
  sendSendEmailFromInstaller,
  getUserAlertRulesByProject,
  sendOtpToEmail,
  getActionRequestsOfUser
}
