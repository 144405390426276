import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import useAuth from '../../hooks/useAuth'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { getPowerSupplies } from '../../redux/slices/powerSupplies'
import { getProjects } from '../../redux/slices/projects'
import { RootState } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import { getCompanies } from '../../redux/slices/companies'
import { UserRole } from '../../enums/UserRole'
import LoadingState from '../../components/LoadingState'
import PowerSupplyTable from '../../components/PowerSupplyTable'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

/**
 * @deprecated
 */
export default function PowerSupplies() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const { powerSupplies, powerSupply, show, loading } = useSelector(
    (state: RootState) => state.powerSupplies
  )

  const [refresh, setRefresh] = useState<boolean>(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  useEffect(() => {
    if (currentUser) {
      if (
        (currentUser?.role === UserRole.SUPERADMIN && isFirstRender) ||
        (currentUser?.role === UserRole.SUPERADMIN && refresh === true)
      ) {
        dispatch(getPowerSupplies())
        dispatch(getProjects())
        dispatch(getCompanies())
        setIsFirstRender(false)
      }
    }
  }, [dispatch, currentUser, refresh])

  return (
    <React.Fragment>
      <Helmet title={t('PowerSupplies.powerSupplies')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('PowerSupplies.powerSupplies')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('PowerSupplies.frontpage')}
        </Link>
        <Typography>{t('PowerSupplies.powerSupplies')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {loading ? (
            <LoadingState />
          ) : (
            <PowerSupplyTable
              powerSupplies={powerSupplies}
              powerSupply={powerSupply}
              show={show}
              onUpdate={(refresh) => {
                setRefresh(refresh)
              }}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
