import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useDispatch } from 'react-redux'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import { sendEmailVerificationMail } from '../../services/authService'
import { AuthErrors } from '../../enums/AuthErrors'
import { useAuth } from '../../hooks'

// eslint-disable-next-line
export default function EmailVerificationCard() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { signOut } = useAuth()
  const navigate = useNavigate()

  function handleSignOut() {
    signOut()
    navigate('/auth/sign-in')
  }

  async function handleNewEmailVerificationLink(): Promise<void> {
    try {
      setIsSubmitting(true)
      await sendEmailVerificationMail()
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('emailSentSuccess')
        )
      )
      // eslint-disable-next-line
    } catch (error: any) {
      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: AuthErrors.SEND_VERIFICATION_EMAIL,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => handleNewEmailVerificationLink()}
        disabled={isSubmitting}
      >
        {t('sendNewLink')}
      </Button>
      <Button
        disabled={isSubmitting}
        onClick={() => handleSignOut()}
        fullWidth
        color="primary"
      >
        {t('returnToLogin')}
      </Button>
    </React.Fragment>
  )
}
