import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartOptions } from 'chart.js'
import { Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import {
  RuuviTagCondition,
  RuuviTagConditionQuery,
  RuuviTagConditionStatistic,
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateQuery,
  RuuviTagConditionAggregateStatistic
} from '../types'
import RuuviTagConditionChart from './RuuviTagConditionChart'
import RuuviTagConditionAggregateChart from './RuuviTagConditionAggregateChart'
import RuuviTagConditionStatisticToggle from './RuuviTagConditionStatisticToggle'
import LoadingState from './LoadingState'
import { getDifferenceOfDateRangeInHours } from '../utils/date'

interface RuuviTagPressureChartProps {
  conditions: RuuviTagCondition[] | RuuviTagConditionAggregate[]
  query: RuuviTagConditionQuery | RuuviTagConditionAggregateQuery
  loading?: boolean
}

export default function RuuviTagPressureChart({
  conditions,
  query,
  loading = false
}: RuuviTagPressureChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The condition statistics of RuuviTag.
   */
  const [statistics, setStatistics] = useState<
    RuuviTagConditionStatistic[] | RuuviTagConditionAggregateStatistic[]
  >(['avg'])

  /**
   * The chart options.
   */
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        suggestedMax: 1000,
        suggestedMin: 0,
        grid: {
          display: true
        }
      }
    }
  }

  /**
   * Indicates if condition aggregates are being used.
   */
  const isUsingConditionAggregates =
    getDifferenceOfDateRangeInHours(query.from, query.to) >= 24

  /**
   * The highest pressure.
   */
  const highestPressure = Math.max(
    ...conditions.map((ruuviTagCondition) => ruuviTagCondition.pressureMax)
  )

  /**
   * The lowest pressure.
   */
  const lowestPressure = Math.min(
    ...conditions.map((ruuviTagCondition) => ruuviTagCondition.pressureMin)
  )

  /**
   * The average pressure.
   */
  const averagePressure =
    conditions
      .map((ruuviTagCondition) => ruuviTagCondition.pressureAvg)
      .reduce((a, b) => a + b, 0) / conditions.length

  /**
   * Make the chip label.
   */
  function makeChipLabel(label: string, value: number): string {
    return `${label}: ${value.toFixed(2)} hPa`
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h4">{t('pressure')}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'center' }}
            alignItems="center"
          >
            {conditions.length > 0 && (
              <Stack direction="row" gap={3}>
                <Chip
                  label={makeChipLabel(t('min'), lowestPressure)}
                  size="small"
                  variant="outlined"
                />

                <Chip
                  label={makeChipLabel(t('avg'), averagePressure)}
                  size="small"
                  variant="outlined"
                />

                <Chip
                  label={makeChipLabel(t('max'), highestPressure)}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            alignItems="center"
          >
            <RuuviTagConditionStatisticToggle
              value={statistics}
              onChange={setStatistics}
            />
          </Grid>
        </Grid>

        {loading ? (
          <LoadingState />
        ) : (
          <>
            {isUsingConditionAggregates ? (
              <RuuviTagConditionAggregateChart
                field="pressure"
                conditions={conditions as RuuviTagConditionAggregate[]}
                query={query as RuuviTagConditionAggregateQuery}
                statistics={statistics as RuuviTagConditionAggregateStatistic[]}
                options={options}
              />
            ) : (
              <RuuviTagConditionChart
                ruuviTagConditionField="pressure"
                ruuviTagConditions={conditions as RuuviTagCondition[]}
                ruuviTagConditionQuery={query as RuuviTagConditionQuery}
                ruuviTagConditionStatistics={
                  statistics as RuuviTagConditionStatistic[]
                }
                chartOptions={options}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}
