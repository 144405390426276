import { Box, Card, CardContent, Grid, Skeleton, lighten } from '@mui/material'
import { useTheme } from '@mui/material/styles'

function AssetGridSkeletonItemBox() {
  return (
    <Box>
      <Skeleton
        variant="text"
        animation="wave"
        width="80px"
        sx={{ fontSize: '2rem' }}
      />
      <Skeleton
        variant="text"
        width="24px"
        animation="wave"
        sx={{ fontSize: '1rem' }}
      />
    </Box>
  )
}

interface AssetGridSkeletonProps {
  /**
   * The count of skeleton items.
   */
  count: number

  /**
   * The size.
   */
  size?: 'small' | 'large'
}

export default function AssetGridSkeleton({
  count,
  size = 'large'
}: AssetGridSkeletonProps) {
  const theme = useTheme()

  const items = []
  for (let key = 0; key < count; key++) {
    items.push(key)
  }

  return (
    <Grid container spacing={6}>
      {items.map((key) => (
        <Grid
          item
          key={key}
          sx={{
            width: '100%',
            height: size === 'large' ? '343px' : '100%',
            maxWidth: { sm: '100%', md: '343px' }
          }}
        >
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'flex-end',
              position: 'relative'
            }}
          >
            {size === 'large' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: lighten(theme.palette.background.paper, 0.05)
                }}
              ></Box>
            )}

            <CardContent>
              <Skeleton
                animation="wave"
                variant="text"
                width="120px"
                height="24px"
                sx={{ fontSize: '24px', mb: 6 }}
              />

              <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                  <AssetGridSkeletonItemBox />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <AssetGridSkeletonItemBox />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <AssetGridSkeletonItemBox />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
