import { useTranslation } from 'react-i18next'
import { RuuviTagMovement } from '../types'
import RuuviTagStats from './RuuviTagStats'

interface RuuviTagMovementCountStatsProps {
  ruuviTagMovement: RuuviTagMovement | null
  loading?: boolean
}

export default function RuuviTagMovementCountStats({
  ruuviTagMovement,
  loading = false
}: RuuviTagMovementCountStatsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <RuuviTagStats
      title={t('movement')}
      value={ruuviTagMovement?.counts}
      decimals={0}
      unit={
        ruuviTagMovement?.counts === 1
          ? t('move').toLowerCase()
          : t('moves').toLowerCase()
      }
      date={ruuviTagMovement?.time}
      isAging={false}
      loading={loading}
    />
  )
}
