import {
  Card as MuiCard,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  LinearProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableContainer,
  Stack
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { calculateProjectProgress } from '../utils/projectProgress'
import checkProjectStatus from '../utils/checkProjectStatus'
import { ProjectStateType } from '../enums/ProjectStateType'
import { useDispatch } from 'react-redux'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { THEMES } from '../constants'
import { scrollbarForDark, scrollbarForLight } from '../theme/scrollbar'
import useTheme from '../hooks/useTheme'
import projectService, { recoverProject } from '../services/projectService'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import LoadingState from './LoadingState'
import { useNavigate } from 'react-router-dom'
import { createUserNotification } from '../utils/createUserNotification'
import { ProjectErrors } from '../enums/ProjectErrors'
import { useAuth } from '../hooks'
import { Project } from '../types'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import translateProjectState from '../utils/translateProjectState'
import { deleteProject } from '../redux/slices/projects'

const Card = styled(MuiCard)(spacing)

export interface DeletedProjectTableProps {
  projects: Project[]
}

export default function ProjectArchiveTable({
  projects
}: DeletedProjectTableProps) {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { theme } = useTheme()

  const [open, setOpen] = useState(false)
  const [projectId, setProjectId] = useState<number | string>('')

  async function handleDelete(id: number) {
    try {
      await projectService.deleteProject(id, true)
      dispatch(deleteProject(id))
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  const handleClickOpen = (id: number) => {
    setProjectId(id)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setProjectId('')
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function handleProjectRecovery(id: number) {
    try {
      setIsSubmitting(true)
      await recoverProject(id)

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('projectRecoverySuccess')
        )
      )
      //handleNavigate(id)
      navigate(`/projects/${id}`)
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectErrors.RECOVER,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const columns = [
    {
      field: 'state',
      headerName: t('status'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Chip
            label={t(translateProjectState(params.row.state) as string)}
            size="small"
            color={checkProjectStatus(params.row.state as ProjectStateType)}
          />
        )
      }
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      minWidth: 150,
      editable: false
    },

    {
      field: 'company.name',
      headerName: t('company'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params: any) => params.row?.company?.name ?? '-'
    },
    {
      field: 'city',
      headerName: t('city'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'deletedAt',
      headerName: t('archivedAt'),
      flex: 1,
      minWidth: 200,
      type: 'date',
      editable: false,
      valueGetter: (params: any) =>
        new Date(params.row?.deletedAt).toLocaleString()
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params: any) => {
        return (
          <Stack direction="row" spacing={3}>
            <AutorenewIcon
              style={{ cursor: 'pointer' }}
              color="success"
              onClick={() => handleProjectRecovery(params.row.id)}
            />

            <Delete
              color="error"
              style={{ cursor: 'pointer' }}
              onClick={() => handleClickOpen(params.row.id)}
            />
          </Stack>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Projects.confirm')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Projects.warning')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleDelete(projectId as number)}>
            {t('Projects.yes')}
          </Button>
          <Button onClick={handleClose} autoFocus>
            {t('Projects.no')}
          </Button>
        </DialogActions>
      </Dialog>
      <Card mb={6}>
        {isSubmitting ? (
          <LoadingState />
        ) : (
          <Paper>
            <DataGrid
              rows={projects}
              columns={columns}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 }
                }
              }}
            />
          </Paper>
        )}
      </Card>
    </React.Fragment>
  )
}
