import { Grid } from '@mui/material'
import { Device, Statistic } from '../types'
import DeviceGridItem from './DeviceGridItem'
import EmptyState from './EmptyState'
import { useTranslation } from 'react-i18next'
import LoadingState from './LoadingState'
import { SensorAggregateStatistic } from '../enums'
import AssetGridSkeleton from './AssetGridSkeleton'

interface DeviceGridProps {
  /**
   * The devices.
   */
  devices: Device[]

  /**
   * Indicates if the devices are loading.
   */
  loading?: boolean

  /**
   * The size.
   */
  size?: 'small' | 'large'

  /**
   * The statistic.
   */
  statistic?: Statistic
}

export default function DeviceGrid({
  devices,
  loading,
  size = 'large',
  statistic = 'avg'
}: DeviceGridProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <>
      {loading ? (
        <AssetGridSkeleton count={12} size={size} />
      ) : devices.length === 0 ? (
        <EmptyState title={t('noDevices')} />
      ) : (
        <Grid container spacing={6}>
          {devices.map((device) => (
            <Grid
              item
              key={device.name}
              sx={{
                width: '100%',
                height: size === 'large' ? '343px' : 'auto',
                maxWidth: { sm: '100%', md: '343px' }
              }}
            >
              <DeviceGridItem
                device={device}
                size={size}
                statistic={statistic as SensorAggregateStatistic}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}
