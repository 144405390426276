import QRLayout from '../layouts/QRLanding'
// FIXME: Move page to "pages/qr/Scan.tsx".
import QRScan from '../pages/dashboards/QRLanding/QRScanning'

// FIXME: Change path to "/qr/scan" and move to "routes/qr.tsx".
export default [
  {
    path: '/scan',
    element: (
      <QRLayout>
        <QRScan />
      </QRLayout>
    )
  }
]
