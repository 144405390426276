import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Box,
  TableHead,
  Divider as MuiDivider,
  CardHeader,
  IconButton,
  TableContainer,
  Collapse,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { getOrders } from '../../redux/slices/orders'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { OrderStatus } from '../../enums/OrderStatus'
import { Order, OrderItem, Product, StatusHistory } from '../../types'
import LoadingState from '../LoadingState'
import { ProductStatus } from '../../enums/ProductStatus'
import translateProductOrOrderItemType from '../../utils/translateProductOrOrderItemType'
import { OrderType } from '../../enums/OrderType'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`
interface RowProps {
  order: Order
}

function Row({ order }: RowProps) {
  const [t] = useTranslation('common')

  const translateOrderStatus = (status: string) => {
    switch (status) {
      case OrderStatus.PENDING:
        return t('Manufacturing.pending')
      case OrderStatus.ON_HOLD:
        return t('Manufacturing.onHold')
      case OrderStatus.IN_PROGRESS:
        return t('Manufacturing.inProgress')
      case OrderStatus.SHIPPED:
        return t('Manufacturing.shipped')
    }
  }

  /**
   * Is order partially or fully shipped
   */
  let orderHasShippedProducts = false

  order.orderItems?.forEach((orderItem: OrderItem) => {
    if (orderItem && orderItem.products) {
      for (let i = 0; i < orderItem?.products.length; i++) {
        if (orderItem?.products[i].status === ProductStatus.SHIPPED) {
          orderHasShippedProducts = true
          break
        }
      }
    }
  })

  /**
   * Open / close row
   */
  const [open, setOpen] = useState(false)

  /**
   * Open / close shipped products dialog
   */
  const [openShippedProductsDialog, setOpenShippedProductsDialog] =
    useState(false)

  /**
   * Selected shipped products
   */
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  /**
   * Get products shipped timestamp
   * @param statusHistory
   * @returns
   */

  const getShippedTimeStamp = (statusHistory: StatusHistory[]) => {
    let shippedObject: StatusHistory | undefined
    {
      order.orderItems?.map((orderItem) =>
        orderItem.products?.map((product) => {
          product.status === ProductStatus.SHIPPED &&
            (shippedObject = statusHistory.find(
              (history: StatusHistory) =>
                history.status === ProductStatus.SHIPPED
            ))
        })
      )
    }

    return shippedObject
      ? new Date(shippedObject.timestamp).toLocaleString()
      : ''
  }

  /**
   * Set selected products and open dialog
   */
  const handleOpenShippedProductsDialog = () => {
    const shippedProductList: Product[] = []
    if (order.orderItems && order.orderItems) {
      order.orderItems.map((orderItem) => {
        if (orderItem.products) {
          orderItem.products.map((product) => {
            if (product.status === ProductStatus.SHIPPED) {
              shippedProductList.push(product)
            }
          })
        }
      })
    }
    setSelectedProducts(shippedProductList)
    setOpenShippedProductsDialog(true)
  }

  const handleCloseShippedProductsDialog = () => {
    setSelectedProducts([])
    setOpenShippedProductsDialog(false)
  }

  return (
    <React.Fragment>
      {order && orderHasShippedProducts && (
        <>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {order.name}, {order.company ? order.company.name : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="h6" gutterBottom component="div">
                        {t('Manufacturing.changes')}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        align="right"
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => handleOpenShippedProductsDialog()}
                        >
                          {t('showShippedProducts')}
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Manufacturing.status')}</TableCell>
                        <TableCell>{t('Manufacturing.timestamp')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.statusHistory.map((historyRow: any) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {translateOrderStatus(historyRow.status)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {new Date(historyRow.timestamp).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>

          {/**Popup for shipped products */}
          <Dialog
            open={openShippedProductsDialog}
            onClose={() => handleCloseShippedProductsDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <TableWrapper>
                <Table>
                  {selectedProducts.map((product: Product) => (
                    <>
                      <TableHead>
                        {t('device')}: {product.device?.shortUUID}
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {t(
                            translateProductOrOrderItemType(
                              product.type
                            ) as string
                          )}
                          ,{' '}
                          {product.catalogProduct
                            ? product.catalogProduct.manufacturerProductCode
                            : t('notFromCatalog')}
                          , {t('nominalCurrent')}: {product.nominalCurrent}
                        </TableRow>
                        <TableRow>
                          {t('shippedAt')}:{' '}
                          {getShippedTimeStamp(product.statusHistory)}
                        </TableRow>
                      </TableBody>
                      <Divider my={6} />
                    </>
                  ))}
                </Table>
              </TableWrapper>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() => handleCloseShippedProductsDialog()}
                autoFocus
              >
                {t('close')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </React.Fragment>
  )
}

export default function OrderShippedList() {
  const { orders, loading } = useSelector((state: RootState) => state.orders)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [t] = useTranslation('common')

  //TODO: Shold we add new status "partially_shipped" for order etc.
  useEffect(() => {
    dispatch(
      getOrders({
        status: [OrderStatus.SHIPPED, OrderStatus.IN_PROGRESS],
        type: OrderType.OUTBOUND
      })
    )
  }, [])

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl>
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate('/manufacturing')}
        >
          <ArrowBackIcon />
          {t('Manufacturing.back')}
        </Button>
        <Divider my={6} />
        <Card mb={6}>
          <CardHeader title={t('Manufacturing.ordersShipped')} align="center" />
          <Divider my={6} />
          {loading ? (
            <LoadingState />
          ) : (
            <TableContainer component={Paper}>
              <TableWrapper>
                <Table aria-label="collapsible table">
                  {orders ? (
                    <TableBody>
                      {orders.map((order: Order) => (
                        <Row key={order.id} order={order} />
                      ))}
                    </TableBody>
                  ) : (
                    <Typography>{t('noShippedOrders')}</Typography>
                  )}
                </Table>
              </TableWrapper>
            </TableContainer>
          )}
        </Card>
      </Grid>
    </Grid>
  )
}
