import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function PowerSupplyInputCablesSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField
      name="inputCables"
      label={t('PowerSupply.inputCables')}
      select
      {...props}
    >
      <MenuItem value={'0'}>0</MenuItem>
      <MenuItem value={'1'}>1</MenuItem>
      <MenuItem value={'2'}>2</MenuItem>
      <MenuItem value={'3'}>3</MenuItem>
      <MenuItem value={'4'}>4</MenuItem>
      <MenuItem value={'5'}>5</MenuItem>
    </TextField>
  )
}
