/**
 * Checks if the given value is a variant of the provided enum type.
 *
 * @template T - The enum type.
 * @param value - The value to check.
 * @param enumType - The enum type to check against.
 * @returns - True if the value is a variant of the enum type, false otherwise.
 */
export function isEnumVariant<T extends Record<string, string | number>>(
  value: string | number,
  enumType: T
): value is T[keyof T] {
  return Object.values(enumType).includes(value)
}
