import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { Badge, Grid, Avatar, Typography, Link } from '@mui/material'

import useAuth from '../../hooks/useAuth'

import { NavLink, useNavigate } from 'react-router-dom'
import { getUser } from '../../redux/slices/users'
import { useDispatch } from 'react-redux'
import { checkUserRole } from '../../utils/checkRole'

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
`

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSetUser = (id: number) => {
    dispatch(getUser(id))
    navigate('/profile/' + currentUser?.id)
  }

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            variant="dot"
          >
            {!!currentUser && (
              <Avatar alt={currentUser.firstName} src={currentUser.avatar} />
            )}

            {!currentUser && <Avatar />}
          </FooterBadge>
        </Grid>
        <Grid item>
          {currentUser && (
            <Link
              variant="body2"
              component={NavLink}
              onClick={() => handleSetUser(parseInt(currentUser.id))}
              to={`/users/${currentUser.id}`}
            >
              {currentUser.firstName} {currentUser.lastName}
            </Link>
          )}
          {/* Demo data */}
          {!currentUser && <FooterText variant="body2"></FooterText>}
          {currentUser && (
            <FooterSubText variant="caption">
              {checkUserRole(currentUser.role)}
            </FooterSubText>
          )}
        </Grid>
      </Grid>
    </Footer>
  )
}

export default SidebarFooter
