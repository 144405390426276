import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { ConfluencePages, UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import Projects from '../pages/pages/Projects'
import Project from '../pages/pages/Project'
import EditProject from '../pages/pages/EditProject'
import ProjectStats from '../pages/pages/ProjectStats'
import InviteUser from '../pages/pages/inviteUser'
import Redirect from '../pages/Redirect'

export default [
  {
    path: '/projects',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.PROJECTS}>
          <DashboardLayout>
            <Projects />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/projects/:id',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.PROJECTS}>
          <DashboardLayout>
            <Project />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/projects/:id/edit',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.PROJECTS}>
          <DashboardLayout>
            <EditProject />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/projects/:id/stats',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
        <UserManualProvider pageId={ConfluencePages.PROJECTS}>
          <DashboardLayout>
            <ProjectStats />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/projects/:id/invite-user',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.INSTALLER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.PROJECTS}>
          <DashboardLayout>
            <InviteUser />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/project',
    children: [
      {
        path: ':id',
        element: <Redirect to="/projects/:id" />
      },
      {
        path: ':id/edit',
        element: <Redirect to="/projects/:id/edit" />
      },
      {
        path: ':id/stats',
        element: <Redirect to="/projects/:id/stats" />
      },
      {
        path: ':id/invite-user',
        element: <Redirect to="/projects/:id/invite-user" />
      }
    ]
  }
]
