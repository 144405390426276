import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import counterReducer from './slices/counter'
import userReducer from './slices/users'
import companyReducer from './slices/companies'
import deviceReducer from './slices/devices'
import powerSupplyReducer from './slices/powerSupplies'
import levelReducer from './slices/levels'
import liftReducer from './slices/lifts'
import sectorReducer from './slices/sectors'
import projectReducer from './slices/projects'
import sensorReducer from './slices/sensors'
import device_groupReducer from './slices/device_groups'
import notificationReducer from './slices/notifications'
import requestReducer from './slices/requests'
import orderReducer from './slices/orders'
import productReducer from './slices/products'
import orderItemReducer from './slices/orderItems'
import stepReducer from './slices/steps'
import uploadReducer from './slices/uploads'
import errorReducer from './slices/errors'
import catalogProductReducer from './slices/catalog'
import warehouseReducer from './slices/warehouses'
import queryReducer from './slices/query'
import projectMembershipReducer from './slices/projectMemberships'
import projectPhaseReducer from './slices/phases'
import announcementReducer from './slices/announcements'

// import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
// import { persistReducer } from 'redux-persist'

const reducers = combineReducers({
  counter: counterReducer,
  users: userReducer,
  companies: companyReducer,
  devices: deviceReducer,
  powerSupplies: powerSupplyReducer,
  levels: levelReducer,
  lifts: liftReducer,
  sectors: sectorReducer,
  projects: projectReducer,
  sensors: sensorReducer,
  device_groups: device_groupReducer,
  notifications: notificationReducer,
  requests: requestReducer,
  orders: orderReducer,
  products: productReducer,
  orderitems: orderItemReducer,
  steps: stepReducer,
  uploads: uploadReducer,
  errors: errorReducer,
  catalogProducts: catalogProductReducer,
  warehouses: warehouseReducer,
  query: queryReducer,
  projectMemberships: projectMembershipReducer,
  phases: projectPhaseReducer,
  announcements: announcementReducer
})

// const persistConfig = {
//   key: 'root',
//   storage
// }

// const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
