import { AxiosResponse } from 'axios'
import { useAxios } from '../hooks'
import { AuthResponse, OtpInput } from '../types'

const axios = useAxios()

interface AuthKeyAndQrCodeParams {
  email: string
}

export async function getAuthKeyAndQrCode(
  params: AuthKeyAndQrCodeParams
): Promise<AuthResponse> {
  const response: AxiosResponse = await axios.get('mfa/key-and-qr', {
    params: params
  })
  return response.data
}

export async function updateUserOtpSecret(input: OtpInput): Promise<any> {
  const response: AxiosResponse = await axios.put('mfa/user-otp-secret', input)
  return response.data
}

export async function checkUserOtp(input: OtpInput): Promise<any> {
  const response: AxiosResponse = await axios.post('mfa/user-otp-check', input)
  return response.data
}

export default {
  getAuthKeyAndQrCode,
  updateUserOtpSecret,
  checkUserOtp
}
