import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Grid,
  Stack,
  TextField as MuiTextField
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { createLift, updateLift, hideLift } from '../redux/slices/lifts'
import { getProjects } from '../redux/slices/projects'
import { getSectorsByProjectId } from '../redux/slices/sectors'
import { useAuth, useLift } from '../hooks'
import LiftInput from '../types/LiftInput'
import ProjectSelect from './ProjectSelect'
import LoadingState from './LoadingState'
import SectorSelect from './SectorSelect'
import liftService from '../services/liftService'
import { deleteError, setError } from '../redux/slices/errors'
import { LiftErrors } from '../enums/LiftErrors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { Lift } from '../types'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export interface LiftFormProps {
  value?: LiftInput
  hidden?: Array<keyof LiftInput>
  disabled?: Array<keyof LiftInput>
}

export default function LiftForm({
  value = {},
  hidden = [],
  disabled = []
}: LiftFormProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const lift: Lift | undefined = useLift()

  const isLoadingProjects: boolean = useSelector(
    (state: RootState) => state.projects.loading
  )

  const isLoadingSectors: boolean = useSelector(
    (state: RootState) => state.sectors.loading
  )

  const initialValues: LiftInput =
    lift === undefined
      ? {
          name: '',
          number: 0,
          project: null,
          sector: null,
          ...value
        }
      : {
          name: lift.name,
          number: lift.number,
          project: lift.project?.id ?? null,
          sector: lift.sector?.id ?? null,
          ...value
        }

  const validationSchema = Yup.object().shape({
    // FIXME: Add unique condition:
    // - Name should be unique among lifts of the selected project
    name: Yup.string().required(
      t('isRequired', {
        field: t('Lift.name')
      })
    ),
    number: Yup.number().nullable(),
    project: Yup.number().required(
      t('isRequired', {
        field: t('Lift.project')
      })
    ),
    sector: Yup.number().nullable()
  })

  useEffect(() => {
    dispatch(getProjects())
  }, [])

  useEffect(() => {
    if (lift?.project?.id) {
      dispatch(getSectorsByProjectId(lift.project.id))
    }
  }, [lift])

  function isHidden(field: string): boolean {
    return hidden.includes(field as keyof LiftInput)
  }

  function isDisabled(field: string): boolean {
    return disabled.includes(field as keyof LiftInput)
  }

  const { currentUser } = useAuth()

  async function handleSubmit(
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ): Promise<void> {
    try {
      if (lift === undefined) {
        const createdLift: Lift = await liftService.createLift(values)
        dispatch(createLift(createdLift))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('liftCreateSuccess')
          )
        )
      } else {
        const updatedLift: Lift = await liftService.updateLift(lift.id, values)
        dispatch(updateLift(updatedLift))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('levelCreateSuccess')
          )
        )
      }
      resetForm()
      setStatus({ sent: true })
      dispatch(hideLift())
    } catch (error: unknown) {
      setStatus({ sent: false })
      // FIXME: Set validation errors.
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: lift ? LiftErrors.EDIT : LiftErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: lift ? LiftErrors.EDIT : LiftErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(lift ? LiftErrors.EDIT : LiftErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  function handleCancel(): void {
    dispatch(hideLift())
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        setValues,
        errors,
        isSubmitting,
        touched,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                {!isHidden('project') && (
                  <Grid item xs={12}>
                    <ProjectSelect
                      data-testid="project"
                      name="project"
                      label={t('Lift.project')}
                      value={values.project}
                      disabled={
                        isDisabled('project') ||
                        isLoadingProjects ||
                        isSubmitting
                      }
                      error={Boolean(touched.project && errors.project)}
                      helperText={touched.project && errors.project}
                      onBlur={handleBlur}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const projectId: number = parseInt(event.target.value)

                        let newValues = {
                          ...values,
                          project: projectId
                        }

                        if (lift?.project?.id !== projectId) {
                          dispatch(getSectorsByProjectId(projectId))
                          newValues = {
                            ...newValues,
                            sector: null
                          }
                        }

                        setValues(newValues)
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                )}

                {!isHidden('name') && (
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label={t('Lift.name')}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      disabled={isDisabled('name') || isSubmitting}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                )}

                {!isHidden('sector') && (
                  <Grid item xs={12}>
                    <SectorSelect
                      data-testid="sector"
                      name="sector"
                      label={t('Lift.sector')}
                      value={values.sector}
                      disabled={
                        isDisabled('sector') || isLoadingSectors || isSubmitting
                      }
                      error={Boolean(touched.sector && errors.sector)}
                      helperText={touched.sector && errors.sector}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack direction="row" spacing={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {t('save')}
                    </Button>

                    <Button
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => handleCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  )
}
