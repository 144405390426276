import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import { Grid, Paper, Typography } from '@mui/material'

import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import NavbarLanguagesDropdown from '../../components/navbar/NavbarLanguagesDropdown'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'
import MFACheckForm from '../../components/auth/MFACheckForm'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

export default function MFA() {
  const [t] = useTranslation('common')
  const { theme } = useTheme()

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}
      <Wrapper>
        <Helmet title="MFA" />
        <Grid container spacing={6}>
          <NavbarLanguagesDropdown />
        </Grid>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t('enterSixDigitCode')}
        </Typography>

        <MFACheckForm />
      </Wrapper>
    </React.Fragment>
  )
}
