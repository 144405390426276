import { Product, ProductInput, ProductQuery } from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getProducts(params?: ProductQuery): Promise<Product[]> {
  const response = await api.get(`/products`, {
    params: params
  })

  return response.data
}

export async function getProduct(id: number): Promise<Product> {
  const response = await api.get(`/products/${id}`)

  return response.data
}

export async function updateProduct(
  id: number,
  input: ProductInput
): Promise<Product> {
  const response = await api.put(`/products/${id}`, input)

  return response.data
}

export async function deleteProduct(id: number): Promise<void> {
  await api.delete(`/products/${id}`)
}

export default {
  getProducts,
  getProduct,
  updateProduct,
  deleteProduct
}
