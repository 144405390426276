import { useTranslation } from 'react-i18next'
import { ChartOptions, Chart } from 'chart.js'
import SensorAggregateChart from './SensorAggregateChart'
import DateRange from '../types/DateRange'
import Sensor from '../types/Sensor'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import { Device } from '../types'
import annotationPlugin from 'chartjs-plugin-annotation'
import { red } from '@mui/material/colors'
import { ForcedYAxis, ScalingYAxis } from '../types/YAxisOptions'
Chart.register(annotationPlugin)

interface DeviceCurrentChartProps {
  device: Device
  dateRange: DateRange
  sensors: Sensor[]
  sensorAggregates: SensorAggregate[]
  options: SensorAggregateOptions
  sensor?: Sensor
  showAnnotation?: boolean
  yAxisOption: ScalingYAxis | ForcedYAxis
}

export default function DeviceCurrentChart({
  device,
  dateRange,
  sensors,
  sensorAggregates,
  options,
  sensor,
  showAnnotation,
  yAxisOption
}: DeviceCurrentChartProps) {
  const [t] = useTranslation('common')

  const chartOptions: ChartOptions = {
    plugins: {
      annotation: {
        annotations: {
          line1: {
            display: showAnnotation,
            drawTime: 'afterDatasetsDraw',
            type: 'line',
            yMin: device?.powerSupply?.fuse ?? 0,
            yMax: device?.powerSupply?.fuse ?? 0,
            borderColor: red[500],
            borderWidth: 2
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: yAxisOption
    }
  }

  return (
    <SensorAggregateChart
      dateRange={dateRange}
      sensors={sensors}
      sensorAggregates={sensorAggregates}
      options={options}
      chartOptions={chartOptions}
      chartWrapperHeight={'250px'}
    />
  )
}
