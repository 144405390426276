import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSectors } from '../hooks'

export default function SectorSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  const sectors = useSectors()

  return (
    <TextField select name="sectors" label={t('sector')} {...props}>
      {sectors.map((sector) => (
        <MenuItem key={sector.id} value={sector.id}>
          {sector.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
