import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { getSectors, getSectorsByProjectId } from '../../redux/slices/sectors'
import { RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import SectorTable from '../../components/SectorTable'
import { UserRole } from '../../enums/UserRole'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

/**
 * @deprecated
 */
export default function Sectors() {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(getSectors())
      } else {
        dispatch(getSectorsByProjectId(currentUser?.activeProject?.id))
      }
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <Helmet title={t('sectors')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('sectors')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('frontpage')}
        </Link>
        <Typography>{t('sectors')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SectorTable />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
