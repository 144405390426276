import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import { Warehouse } from '../../types'
import warehouseService from '../../services/warehouseService'

interface WarehousesState {
  warehouses: Warehouse[]
  warehouse: Warehouse | undefined
  show: boolean
  loading: boolean
}

const initialState: WarehousesState = {
  warehouses: [],
  warehouse: undefined,
  show: false,
  loading: false
}

const warehouseSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    setWarehouses(state, action) {
      state.warehouses = action.payload
    },
    setWarehouse(state, action) {
      state.warehouse = action.payload
    },
    setShow(state, action) {
      state.show = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const { setWarehouses, setWarehouse, setShow } = warehouseSlice.actions

export function getWarehouses() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(warehouseSlice.actions.setLoading(true))
      const warehouses = await warehouseService.getWarehouses()
      dispatch(warehouseSlice.actions.setWarehouses(warehouses))
    } finally {
      dispatch(warehouseSlice.actions.setLoading(false))
    }
  }
}

export default warehouseSlice.reducer
