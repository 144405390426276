import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { Grid, Paper, Typography, Divider as MuiDivider } from '@mui/material'
import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import NavbarLanguagesDropdown from '../../components/navbar/NavbarLanguagesDropdown'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'
import EmailVerificationCard from '../../components/auth/EmailVerificationCard'
import { spacing } from '@mui/system'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`
const Divider = styled(MuiDivider)(spacing)

export default function EmailVerification() {
  const [t] = useTranslation('common')
  const { theme } = useTheme()

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}
      <Wrapper>
        <Helmet title="Email Verification" />
        <Grid container spacing={6}>
          <NavbarLanguagesDropdown />
        </Grid>
        <Divider my={6} />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t('emailVerificationNotice')}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t('emailVerificationInstructions')}
        </Typography>
        <Divider my={6} />

        <EmailVerificationCard />
      </Wrapper>
    </React.Fragment>
  )
}
