import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Card as MuiCard,
  CardActionArea,
  CardContent as MuiCardContent,
  Chip,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { green, red } from '@mui/material/colors'
import styled from 'styled-components/macro'
import { RuuviTag, RuuviTagBattery, RuuviTagCondition } from '../types'
import { alpha } from '@mui/material'
import useTheme from '../hooks/useTheme'
import { THEMES } from '../constants'
import {
  getRuuviTagBatteryNow,
  getRuuviTagConditionNow
} from '../services/ruuviTagService'
import { isRuuviTagOnline } from '../utils/ruuviTag'
import LoadingState from './LoadingState'
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar'
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar'
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar'
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar'
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff'
import BatteryFullIcon from '@mui/icons-material/BatteryFull'
import Battery20Icon from '@mui/icons-material/Battery20'
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

export interface RuuviTagDataCardProps {
  ruuviTag: RuuviTag
}

export default function RuuviTagDataCard({ ruuviTag }: RuuviTagDataCardProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const { theme } = useTheme()

  /**
   * The current condition of RuuviTag.
   */
  const [condition, setCondition] = useState<RuuviTagCondition | null>(null)

  /**
   * The current battery of RuuviTag.
   */
  const [battery, setBattery] = useState<RuuviTagBattery | null>(null)

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * Indicates if the ruuvi tag is online.
   */
  const isOnline = condition && isRuuviTagOnline(new Date(condition?.time))

  /**
   * The status color.
   */
  const statusColor = isOnline ? 'green' : 'red'

  /**
   * Boots the component.
   */
  async function boot(signal: AbortSignal): Promise<void> {
    await loadRuuviTagConditionAndBatteryNow(signal)
  }

  /**
   * Loads the current condition and battery of RuuviTag.
   */
  async function loadRuuviTagConditionAndBatteryNow(
    signal: AbortSignal
  ): Promise<void> {
    try {
      setIsLoading(true)
      setCondition(await getRuuviTagConditionNow(ruuviTag.id, { signal }))
      setBattery(await getRuuviTagBatteryNow(ruuviTag.id, { signal }))
    } finally {
      setIsLoading(false)
    }
  }

  function handleClick() {
    navigate(`/ruuvi-tags/${ruuviTag.id}`)
  }

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => await boot(controller.signal))()

    return () => controller.abort()
  }, [])

  function getSignalIcon() {
    if (condition && condition.rssi > -60) {
      return <SignalCellular4BarIcon color="success" />
    } else if (condition && condition.rssi > -80) {
      return <SignalCellular3BarIcon color="success" />
    } else if (condition && condition.rssi > -90) {
      return <SignalCellular2BarIcon color="warning" />
    } else if (condition && condition.rssi < -90) {
      return <SignalCellular1BarIcon color="error" />
    } else return <SignalCellularOffIcon color="error" />
  }

  function getBatteryIcon() {
    if (battery && condition && condition.temperatureAvg < -20) {
      return battery.voltage <= 2000 ? (
        <Battery20Icon color="error" />
      ) : (
        <BatteryFullIcon color="success" />
      )
    } else if (
      battery &&
      condition &&
      condition.temperatureAvg > -20 &&
      condition.temperatureAvg <= 0
    ) {
      return battery.voltage <= 2300 ? (
        <Battery20Icon color="error" />
      ) : (
        <BatteryFullIcon color="success" />
      )
    } else if (battery) {
      return battery.voltage <= 2500 ? (
        <Battery20Icon color="error" />
      ) : (
        <BatteryFullIcon color="success" />
      )
    } else return <BatteryUnknownIcon />
  }

  return (
    <Card
      variant="outlined"
      sx={{
        borderColor: theme === THEMES.DARK ? 'white' : 'black',
        backgroundColor: isOnline
          ? alpha(green[500], 0.4)
          : alpha(red[500], 0.4)
      }}
    >
      {isLoading ? (
        <CardActionArea style={{ width: '75px', height: '75px' }}>
          <CardContent padding={2}>
            <LoadingState size="xs" />
          </CardContent>
        </CardActionArea>
      ) : (
        <CardActionArea onClick={handleClick}>
          <CardContent padding={2}>
            {isOnline ? (
              <React.Fragment>
                <Typography
                  mb={2}
                  color={theme === THEMES.DARK ? 'white' : 'black'}
                >
                  {condition?.temperatureAvg + '°C'}{' '}
                  {'RH' + ': ' + condition.humidityAvg + '%'}
                </Typography>

                {getSignalIcon()}
                {getBatteryIcon()}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography width={'60px'} height={'50px'}>
                  <Chip
                    sx={{
                      mt: 3,
                      ml: 1,
                      backgroundColor: statusColor,
                      color: 'white'
                    }}
                    label={t('offline')}
                    size="small"
                  />
                </Typography>
              </React.Fragment>
            )}
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  )
}
