import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Dialog,
  Stack,
  TextField,
  Typography,
  DialogActions,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  DialogTitle,
  SelectChangeEvent,
  CircularProgress,
  Paper,
  Alert
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Delete, Edit, ExpandMore, NavigateNext } from '@mui/icons-material'
import { Asset, AssetInput, Company, Level, Lift, Project } from '../types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react'
import { useAuth } from '../hooks'
import {
  userBelongsToCompany,
  userIsAdmin,
  userIsAdminOfProject,
  userIsSuperadmin,
  userIsUserOfProject
} from '../utils/auth'
import { getLiftsByProjectId } from '../services/liftService'
import { getLevelsByProjectId } from '../services/levelService'
import { getProjects } from '../services/projectService'
import { getCompanies } from '../services/companyService'
import {
  deleteAssetPhoto,
  updateAsset,
  uploadAssetPhoto
} from '../services/assetService'
import { styled } from '@mui/system'

interface AssetDetailsProps {
  asset: Asset | null
  onUpdate(asset: Asset): void
}

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

export default function AssetDetails({
  asset,
  onUpdate,
  children
}: PropsWithChildren<AssetDetailsProps>) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if icon buttons should be used.
   */
  const useIconButtons = true

  /**
   * The asset input.
   */
  const [input, setInput] = useState<AssetInput>(getInitialAssetInput())

  /**
   * The uploaded file.
   */
  const [file, setFile] = useState<File | null>(null)

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * Indicates if the component has booted.
   */
  const [hasBooted, setHasBooted] = useState<boolean>(false)

  /**
   * Indicates if the component is saving.
   */
  const [isSaving, setIsSaving] = useState<boolean>(false)

  /**
   * Indicates if the component photo is being uploaded.
   */
  const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false)

  /**
   * Indicates if the photo is being deleted.
   */
  const [isDeletingPhoto, setIsDeletingPhoto] = useState<boolean>(false)

  /**
   * The companies.
   */
  const [companies, setCompanies] = useState<Company[]>([])

  /**
   * The projects.
   */
  const [projects, setProjects] = useState<Project[]>([])

  /**
   * The levels of project.
   */
  const [levels, setLevels] = useState<Level[]>([])

  /**
   * The lift of project.
   */
  const [lifts, setLifts] = useState<Lift[]>([])

  /**
   * Indicates if the name dialog is open.
   */
  const [isNameDialogOpen, setIsNameDialogOpen] = useState<boolean>(false)

  /**
   * Indicates if the company dialog is open.
   */
  const [isCompanyDialogOpen, setIsCompanyDialogOpen] = useState<boolean>(false)

  /**
   * Indicates if the project dialog is open.
   */
  const [isProjectDialogOpen, setIsProjectDialogOpen] = useState<boolean>(false)

  /**
   * Indicates if the level dialog is open.
   */
  const [isLevelDialogOpen, setIsLevelDialogOpen] = useState<boolean>(false)

  /**
   * Indicates if the lift dialog is open.
   */
  const [isLiftDialogOpen, setIsLiftDialogOpen] = useState<boolean>(false)

  /**
   * Indicates if the photo dialog is open.
   */
  const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState<boolean>(false)

  /**
   * The URL of the uploaded file.
   */
  const fileUrl: string | null =
    file !== null ? URL.createObjectURL(file) : null

  /**
   * Indicates if the accordion is open.
   */
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(false)

  /**
   * Toggle the accordion.
   */
  function toggleAccordion(): void {
    setIsAccordionExpanded(!isAccordionExpanded)
  }

  /**
   * Check if company can be viewed.
   */
  function canViewCompany(company: Company): boolean {
    return (
      userIsSuperadmin(currentUser) ||
      (userIsAdmin(currentUser) && userBelongsToCompany(currentUser, company))
    )
  }

  /**
   * Check if project can be viewed.
   */
  function canViewProject(project: Project): boolean {
    return (
      userIsSuperadmin(currentUser) ||
      userIsAdminOfProject(currentUser, project) ||
      userIsUserOfProject(currentUser, project)
    )
  }

  /**
   * Check if the asset can be edited.
   */
  function canEditAsset(asset: Asset): boolean {
    return (
      userIsSuperadmin(currentUser) ||
      (asset?.project !== undefined &&
        userIsAdminOfProject(currentUser, asset.project))
    )
  }

  function openNameDialog(): void {
    setIsNameDialogOpen(true)
  }

  function closeNameDialog(): void {
    setIsNameDialogOpen(false)
    resetInput()
  }

  function openCompanyDialog(): void {
    setIsCompanyDialogOpen(true)
  }

  function closeCompanyDialog(): void {
    setIsCompanyDialogOpen(false)
    resetInput()
  }

  function openProjectDialog(): void {
    setIsProjectDialogOpen(true)
  }

  function closeProjectDialog(): void {
    setIsProjectDialogOpen(false)
    resetInput()
  }

  function openLevelDialog(): void {
    setIsLevelDialogOpen(true)
  }

  function closeLevelDialog(): void {
    setIsLevelDialogOpen(false)
    resetInput()
  }

  function openLiftDialog(): void {
    setIsLiftDialogOpen(true)
  }

  function closeLiftDialog(): void {
    setIsLiftDialogOpen(false)
    resetInput()
  }

  function openPhotoDialog(): void {
    setIsPhotoDialogOpen(true)
  }

  function closePhotoDialog(): void {
    setIsPhotoDialogOpen(false)
    setFile(null)
  }

  function resetInput(): void {
    setInput(getInitialAssetInput())
  }

  function getInitialAssetInput(): AssetInput {
    return {
      name: asset?.name ?? '',
      company: asset?.companyId ?? null,
      project: asset?.projectId ?? null,
      level: asset?.levelId ?? null,
      lift: asset?.liftId ?? null
    }
  }

  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)
      setHasBooted(false)

      const promises: Promise<unknown>[] = [
        loadCompanies(signal),
        loadProjects(signal)
      ]

      if (asset?.projectId) {
        promises.push(loadLevels(asset.projectId, signal))
        promises.push(loadLifts(asset.projectId, signal))
      }

      await Promise.all(promises)
    } finally {
      setIsBooting(false)
      setHasBooted(true)
    }
  }

  async function loadCompanies(signal: AbortSignal): Promise<void> {
    try {
      const companies = await getCompanies()
      setCompanies(companies)
    } catch (error: any) {
      console.error(error)
    }
  }

  async function loadProjects(signal: AbortSignal): Promise<void> {
    try {
      const projects = await getProjects()
      setProjects(projects)
    } catch (error: any) {
      console.error(error)
    }
  }

  async function loadLevels(
    projectId: number,
    signal: AbortSignal
  ): Promise<void> {
    try {
      const levels = await getLevelsByProjectId(projectId)
      setLevels(levels)
    } catch (error: any) {
      console.error(error)
    }
  }

  async function loadLifts(
    projectId: number,
    signal: AbortSignal
  ): Promise<void> {
    try {
      const lifts = await getLiftsByProjectId(projectId)
      setLifts(lifts)
    } catch (error: any) {
      console.error(error)
    }
  }

  function handlePhotoUpload(event: ChangeEvent): void {
    const target = event.target as HTMLInputElement
    const newFile = target.files?.[0]

    if (newFile && newFile.type.startsWith('image/')) {
      setFile(newFile)
      openPhotoDialog()
    }
  }

  /**
   * Save the asset.
   */
  async function save(): Promise<void> {
    try {
      setIsSaving(true)

      if (asset !== null) {
        // Reset level and lift if project changes.
        if (asset.projectId !== input.project) {
          input.level = null
          input.lift = null
        }

        onUpdate(await updateAsset(asset.id, input))
      }

      setIsSaving(false)

      // Close all dialogs without updating input.
      setIsNameDialogOpen(false)
      setIsCompanyDialogOpen(false)
      setIsProjectDialogOpen(false)
      setIsLevelDialogOpen(false)
      setIsLiftDialogOpen(false)
      setIsPhotoDialogOpen(false)
    } catch (error: any) {
      // TODO: Handle error.
      console.error(error)
      setIsSaving(false)
    }
  }

  /**
   * Upload photo.
   */
  async function uploadPhoto(): Promise<void> {
    try {
      setIsUploadingPhoto(true)

      if (asset !== null && file !== null) {
        onUpdate(await uploadAssetPhoto(asset.id, file))
      }

      setIsUploadingPhoto(false)
      closePhotoDialog()
    } catch (error: any) {
      // TODO: Handle error.
      console.error(error)
      setIsUploadingPhoto(false)
    }
  }

  /**
   * Delete the asset photo.
   */
  async function deletePhoto(): Promise<void> {
    try {
      setIsDeletingPhoto(true)

      if (asset !== null) {
        onUpdate(await deleteAssetPhoto(asset.id))
      }

      setIsDeletingPhoto(false)
    } catch (error: any) {
      // TODO: Handle error.
      console.error(error)
      setIsDeletingPhoto(false)
    }
  }

  // Runs when the accordion status changes.
  useEffect(() => {
    const controller = new AbortController()

    if (!hasBooted && isAccordionExpanded) {
      boot(controller.signal)
    }

    return () => controller.abort()
  }, [isAccordionExpanded])

  // Runs when the project changes.
  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (asset?.projectId) {
        await Promise.all([
          loadLevels(asset.projectId, controller.signal),
          loadLifts(asset.projectId, controller.signal)
        ])
      } else {
        setLevels([])
        setLifts([])
      }
    })()

    return () => controller.abort()
  }, [asset?.project])

  return (
    <Accordion
      expanded={isAccordionExpanded}
      onChange={() => toggleAccordion()}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ pr: 6 }}>
        <Box px={3}>
          <Typography variant="h6">{t('assetInformation')}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {asset ? (
          <List>
            <ListItemButton>
              <ListItemText primary={asset.name} secondary={t('name')} />

              <ListItemSecondaryAction>
                {canEditAsset(asset) && (
                  <>
                    {useIconButtons ? (
                      <IconButton onClick={openNameDialog}>
                        <Edit fontSize="small" />
                      </IconButton>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ alignSelf: 'center' }}
                        onClick={openNameDialog}
                      >
                        {t('edit')}
                      </Button>
                    )}
                  </>
                )}
              </ListItemSecondaryAction>
            </ListItemButton>

            <Dialog
              open={isNameDialogOpen}
              onClose={closeNameDialog}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>{t('editName')}</DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  autoFocus
                  sx={{ mt: 2 }}
                  label={t('name')}
                  value={input.name}
                  disabled={isSaving}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setInput({
                      ...input,
                      name: event.target.value
                    })
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={isSaving} onClick={closeNameDialog}>
                  {t('cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={isSaving}
                  loading={isSaving}
                  onClick={save}
                >
                  <span>{t('save')}</span>
                </LoadingButton>
              </DialogActions>
            </Dialog>

            {asset?.company ? (
              <ListItemButton>
                <ListItemText
                  primary={asset.company.name}
                  secondary={t('company')}
                />
                <ListItemSecondaryAction>
                  <Stack direction="row" gap={2}>
                    {userIsSuperadmin(currentUser) && (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ alignSelf: 'center' }}
                        onClick={openCompanyDialog}
                      >
                        {t('edit')}
                      </Button>
                    )}

                    {canViewCompany(asset.company) && (
                      <IconButton
                        onClick={() =>
                          navigate(`/companies/${asset?.company?.id}`)
                        }
                      >
                        <NavigateNext />
                      </IconButton>
                    )}
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            ) : (
              <>
                {canEditAsset(asset) ? (
                  <ListItem sx={{ py: 4 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={openCompanyDialog}
                    >
                      {t('addCompany')}
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem>
                    <ListItemText primary="-" secondary={t('company')} />
                  </ListItem>
                )}
              </>
            )}

            <Dialog
              open={isCompanyDialogOpen}
              onClose={closeCompanyDialog}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>{t('selectCompany')}</DialogTitle>
              <DialogContent>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="company-select-label">
                    {t('company')}
                  </InputLabel>
                  <Select
                    id="company-select"
                    labelId="company-select-label"
                    value={input.company?.toString() ?? undefined}
                    label={t('company')}
                    autoFocus
                    disabled={isSaving}
                    onChange={(event: SelectChangeEvent) => {
                      setInput({
                        ...input,
                        company: parseInt(event.target.value)
                      })
                    }}
                  >
                    {companies.map((company) => (
                      <MenuItem value={company.id}>{company.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button disabled={isSaving} onClick={closeCompanyDialog}>
                  {t('cancel')}
                </Button>
                {input.company !== null && (
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      setInput({
                        ...input,
                        company: null
                      })
                    }}
                  >
                    {t('clear')}
                  </Button>
                )}
                <LoadingButton
                  variant="contained"
                  disabled={isSaving}
                  loading={isSaving}
                  onClick={save}
                >
                  <span>{t('save')}</span>
                </LoadingButton>
              </DialogActions>
            </Dialog>

            {asset?.project ? (
              <ListItemButton>
                <ListItemText
                  primary={asset.project.name}
                  secondary={t('project')}
                />

                <ListItemSecondaryAction>
                  <Stack direction="row" gap={2}>
                    {canEditAsset(asset) && (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ alignSelf: 'center' }}
                        onClick={openProjectDialog}
                      >
                        {t('edit')}
                      </Button>
                    )}

                    {canViewProject(asset.project) && (
                      <IconButton
                        onClick={() => navigate(`/projects/${asset.projectId}`)}
                      >
                        <NavigateNext />
                      </IconButton>
                    )}
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            ) : (
              <>
                {canEditAsset(asset) ? (
                  <ListItem sx={{ py: 4 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => setIsProjectDialogOpen(true)}
                    >
                      {t('addProject')}
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem>
                    <ListItemText primary="-" secondary={t('project')} />
                  </ListItem>
                )}
              </>
            )}

            <Dialog
              open={isProjectDialogOpen}
              onClose={closeProjectDialog}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>{t('selectProject')}</DialogTitle>
              <DialogContent>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="project-select-label">
                    {t('project')}
                  </InputLabel>
                  <Select
                    id="project-select"
                    labelId="project-select-label"
                    value={input.project?.toString() ?? undefined}
                    label={t('project')}
                    autoFocus
                    disabled={isSaving}
                    onChange={(event: SelectChangeEvent) => {
                      setInput({
                        ...input,
                        project: parseInt(event.target.value)
                      })
                    }}
                  >
                    {projects.map((project) => (
                      <MenuItem value={project.id}>{project.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button disabled={isSaving} onClick={closeProjectDialog}>
                  {t('cancel')}
                </Button>
                {input.project !== null && (
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      setInput({
                        ...input,
                        project: null
                      })
                    }}
                  >
                    {t('clear')}
                  </Button>
                )}
                <LoadingButton
                  variant="contained"
                  disabled={isSaving}
                  loading={isSaving}
                  onClick={save}
                >
                  <span>{t('save')}</span>
                </LoadingButton>
              </DialogActions>
            </Dialog>

            {asset?.project && (
              <>
                {asset?.level ? (
                  <ListItemButton>
                    <ListItemText
                      primary={asset.level.name}
                      secondary={t('level')}
                    />
                    <ListItemSecondaryAction>
                      {canEditAsset(asset) && (
                        <>
                          {useIconButtons ? (
                            <IconButton onClick={openLevelDialog}>
                              <Edit fontSize="small" />
                            </IconButton>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{ alignSelf: 'center' }}
                              onClick={openLevelDialog}
                            >
                              {t('edit')}
                            </Button>
                          )}
                        </>
                      )}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ) : (
                  <>
                    {canEditAsset(asset) ? (
                      <ListItem sx={{ py: 4 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={openLevelDialog}
                        >
                          {t('addLevel')}
                        </Button>
                      </ListItem>
                    ) : (
                      <ListItem>
                        <ListItemText primary="-" secondary={t('level')} />
                      </ListItem>
                    )}
                  </>
                )}

                <Dialog
                  open={isLevelDialogOpen}
                  onClose={closeLevelDialog}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>{t('selectLevel')}</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="level-select-label">
                        {t('level')}
                      </InputLabel>
                      <Select
                        id="level-select"
                        labelId="level-select-label"
                        value={input.level?.toString() ?? undefined}
                        label={t('level')}
                        sx={{ flexGrow: 1 }}
                        autoFocus
                        disabled={isSaving}
                        onChange={(event: SelectChangeEvent) => {
                          setInput({
                            ...input,
                            level: parseInt(event.target.value) ?? null
                          })
                        }}
                      >
                        {levels.map((level) => (
                          <MenuItem value={level.id}>{level.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button disabled={isSaving} onClick={closeLevelDialog}>
                      {t('cancel')}
                    </Button>
                    {input.level !== null && (
                      <Button
                        disabled={isSaving}
                        onClick={() => {
                          setInput({
                            ...input,
                            level: null
                          })
                        }}
                      >
                        {t('clear')}
                      </Button>
                    )}
                    <LoadingButton
                      variant="contained"
                      disabled={isSaving}
                      loading={isSaving}
                      onClick={save}
                    >
                      <span>{t('save')}</span>
                    </LoadingButton>
                  </DialogActions>
                </Dialog>

                {asset?.lift ? (
                  <ListItemButton>
                    <ListItemText
                      primary={asset.lift.name}
                      secondary={t('lift')}
                    />
                    <ListItemSecondaryAction>
                      {canEditAsset(asset) && (
                        <>
                          {useIconButtons ? (
                            <IconButton onClick={openLiftDialog}>
                              <Edit fontSize="small" />
                            </IconButton>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{ alignSelf: 'center' }}
                              onClick={openLiftDialog}
                            >
                              {t('edit')}
                            </Button>
                          )}
                        </>
                      )}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ) : (
                  <>
                    {canEditAsset(asset) ? (
                      <ListItem sx={{ py: 4 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setIsLiftDialogOpen(true)}
                        >
                          {t('addLift')}
                        </Button>
                      </ListItem>
                    ) : (
                      <ListItem>
                        <ListItemText primary="-" secondary={t('lift')} />
                      </ListItem>
                    )}
                  </>
                )}

                <Dialog
                  open={isLiftDialogOpen}
                  onClose={closeLiftDialog}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>{t('selectLift')}</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="lift-select-label">
                        {t('lift')}
                      </InputLabel>
                      <Select
                        id="lift-select"
                        labelId="lift-select-label"
                        value={input.lift?.toString() ?? undefined}
                        label={t('lift')}
                        autoFocus
                        onChange={(event: SelectChangeEvent) => {
                          setInput({
                            ...input,
                            lift: parseInt(event.target.value) ?? null
                          })
                        }}
                      >
                        {lifts.map((lift) => (
                          <MenuItem value={lift.id}>{lift.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button disabled={isSaving} onClick={closeLiftDialog}>
                      {t('cancel')}
                    </Button>
                    {input.lift !== null && (
                      <Button
                        disabled={isSaving}
                        onClick={() => {
                          setInput({
                            ...input,
                            lift: null
                          })
                        }}
                      >
                        {t('clear')}
                      </Button>
                    )}
                    <LoadingButton
                      variant="contained"
                      disabled={isSaving}
                      loading={isSaving}
                      onClick={save}
                    >
                      <span>{t('save')}</span>
                    </LoadingButton>
                  </DialogActions>
                </Dialog>

                {asset?.photo ? (
                  <ListItemButton>
                    {asset?.photoUrl ? (
                      <Paper elevation={6}>
                        <img
                          src={asset.photoUrl}
                          style={{
                            display: 'block',
                            objectFit: 'cover',
                            width: '64px',
                            height: '64px',
                            borderRadius: '4px'
                          }}
                        />
                      </Paper>
                    ) : (
                      <CircularProgress />
                    )}
                    <ListItemSecondaryAction>
                      <Stack direction="row" gap={3}>
                        <LoadingButton
                          color="error"
                          size="small"
                          endIcon={<Delete />}
                          disabled={isDeletingPhoto}
                          loading={isDeletingPhoto}
                          onClick={deletePhoto}
                        >
                          <span>{t('deletePhoto')}</span>
                        </LoadingButton>

                        <Button
                          component="label"
                          variant="contained"
                          size="small"
                          tabIndex={-1}
                        >
                          {t('uploadNewPhoto')}
                          <HiddenInput
                            type="file"
                            onChange={handlePhotoUpload}
                          />
                        </Button>
                      </Stack>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ) : (
                  <>
                    {canEditAsset(asset) && (
                      <ListItem sx={{ py: 4 }}>
                        <Button
                          component="label"
                          variant="contained"
                          size="small"
                          tabIndex={-1}
                        >
                          {t('addPhoto')}
                          <HiddenInput
                            type="file"
                            onChange={handlePhotoUpload}
                          />
                        </Button>
                      </ListItem>
                    )}
                  </>
                )}

                <Dialog
                  open={isPhotoDialogOpen}
                  onClose={closePhotoDialog}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle>{t('previewPhoto')}</DialogTitle>
                  <DialogContent>
                    {fileUrl !== null && (
                      <Paper elevation={6}>
                        <img
                          src={fileUrl}
                          style={{
                            display: 'block',
                            objectFit: 'cover',
                            width: '552px',
                            height: '552px',
                            borderRadius: '4px'
                          }}
                        />
                      </Paper>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={isUploadingPhoto}
                      onClick={closePhotoDialog}
                    >
                      {t('cancel')}
                    </Button>

                    <LoadingButton
                      variant="contained"
                      disabled={isUploadingPhoto}
                      loading={isUploadingPhoto}
                      onClick={uploadPhoto}
                    >
                      <span>{t('save')}</span>
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </List>
        ) : (
          <Alert variant="outlined" severity="error" sx={{ border: 0 }}>
            {t('assetNotFound')}
          </Alert>
        )}

        {children}
      </AccordionDetails>
    </Accordion>
  )
}
