import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material'
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  GridToolbar
} from '@mui/x-data-grid'
import { Autorenew, Delete, Edit, Visibility } from '@mui/icons-material'
import User from '../types/User'
import { MFAType } from '../enums'
import { NotificationType } from '../enums/NotificationType'
import { UserErrors } from '../enums/UserErrors'
import { UserRole } from '../enums/UserRole'
import { RootState } from '../redux/store'
import { setShow, setUser, updateUser } from '../redux/slices/users'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { useAuth } from '../hooks'
import { deleteUser, recoverUser } from '../services/userService'
import { humanizeTimestamp } from '../utils/date'
import { createUserNotification } from '../utils/createUserNotification'
import LoadingState from './LoadingState'
import UserForm from './UserForm'

type UserDataGridField =
  | 'name'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'mfa'
  | 'role'
  | 'company'
  | 'deletedAt'

interface UserDataGridProps {
  users: User[]
  hiddenFields?: UserDataGridField[]
  dataGridProps?: DataGridProps
  onDelete?: (user: User) => void
  onRecover?: (user: User) => void
}

export default function UserDataGrid({
  users,
  hiddenFields = ['deletedAt'],
  dataGridProps,
  onDelete,
  onRecover
}: UserDataGridProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The editable user.
   */
  const editableUser = useSelector((state: RootState) => state.users.user)

  /**
   * The selected user.
   */
  const [selectedUser, setSelectedUser] = useState<User>()

  /**
   * Indicates if the edit dialog is open.
   */
  const isEditUserDialogOpen = useSelector(
    (state: RootState) => state.users.show
  )

  /**
   * Indicates if the dialog is open.
   */
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false)

  /**
   * Indicates if the user recover dialog is open.
   */
  const [isRecoverUserDialogOpen, setIsRecoverUserDialogOpen] = useState(false)

  /**
   * Indicates if the user is being deleted.
   */
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false)

  /**
   * Indicates if the user is being recovered.
   */
  const [isRecoveringUser, setIsRecoveringUser] = useState<boolean>(false)

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  /**
   * The grid columns.
   */
  const columns = makeColumns()

  /**
   * Make the grid columns.
   */
  function makeColumns(): GridColumns<User> {
    const columns: GridColumns<User> = []

    if (!isFieldHidden('name')) {
      columns.push({
        field: 'name',
        headerName: t('name'),
        flex: 1,
        minWidth: 150,
        editable: false,
        valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`
      })
    }

    if (!isFieldHidden('firstName')) {
      columns.push({
        field: 'firstName',
        headerName: t('firstName'),
        flex: 1,
        minWidth: 150,
        editable: false,
        hide: true
      })
    }

    if (!isFieldHidden('lastName')) {
      columns.push({
        field: 'lastName',
        headerName: t('lastName'),
        flex: 1,
        minWidth: 150,
        editable: false,
        hide: true
      })
    }

    if (!isFieldHidden('email')) {
      columns.push({
        field: 'email',
        headerName: t('email'),
        type: 'string',
        flex: 1,
        minWidth: 200,
        editable: false
      })
    }

    if (!isFieldHidden('phone')) {
      columns.push({
        field: 'phone',
        headerName: t('phone'),
        type: 'string',
        flex: 1,
        minWidth: 200,
        editable: false
      })
    }

    if (!isFieldHidden('mfa')) {
      columns.push({
        field: 'mfa',
        headerName: t('mfa'),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        renderCell: ({ row }) => {
          return (
            <>
              {row.mfaType === MFAType.NONE ? (
                <Chip
                  label={t('no')}
                  variant="outlined"
                  color="error"
                  size="small"
                />
              ) : (
                <Chip
                  label={t('yes')}
                  variant="outlined"
                  color="success"
                  size="small"
                />
              )}
            </>
          )
        }
      })
    }

    if (!isFieldHidden('role')) {
      columns.push({
        field: 'role',
        headerName: t('role'),
        type: 'string',
        width: 150,
        editable: false,
        renderCell: ({ row }) => (
          <Chip variant="outlined" size="small" label={row.role} />
        )
      })
    }

    if (!isFieldHidden('company')) {
      columns.push({
        field: 'company.name',
        headerName: t('company'),
        flex: 1,
        minWidth: 150,
        editable: false,
        valueGetter: ({ row }) => row?.company?.name,
        renderCell: ({ row }) => {
          return (
            <>
              {row?.company && (
                <Chip
                  variant="outlined"
                  color="primary"
                  component={NavLink}
                  to={`/companies/${row.company.id ?? ''}`}
                  label={row.company.name ?? ''}
                  size="small"
                  clickable
                />
              )}
            </>
          )
        }
      })
    }

    if (!isFieldHidden('deletedAt')) {
      columns.push({
        field: 'deletedAt',
        headerName: t('archivedAt'),
        flex: 1,
        minWidth: 200,
        type: 'date',
        editable: false,
        valueGetter: ({ row }) => {
          return row.deletedAt !== null ? humanizeTimestamp(row.deletedAt) : ''
        }
      })
    }

    columns.push({
      field: t('buttons'),
      // disableClickEventBubbling: true,
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 120,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" gap={1}>
            {canShowUser(row) && (
              <Tooltip title={t('show') as string}>
                <IconButton
                  color="primary"
                  size="small"
                  component={NavLink}
                  to={`/users/${row.id}`}
                >
                  <Visibility fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}

            {canEditUser(row) && (
              <Tooltip title={t('edit') as string}>
                <IconButton
                  color="warning"
                  size="small"
                  onClick={() => openEditUserDialog(row)}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}

            {canRecoverUser(row) && (
              <Tooltip title={t('recover') as string}>
                <IconButton
                  color="success"
                  size="small"
                  onClick={() => openRecoverUserDialog(row)}
                >
                  <Autorenew fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}

            {canDeleteUser(row) && (
              <Tooltip title={t('delete') as string}>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => openDeleteUserDialog(row)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )
      }
    })

    return columns
  }

  /**
   * Check if a field is hidden.
   */
  function isFieldHidden(field: UserDataGridField): boolean {
    return hiddenFields.includes(field)
  }

  /**
   * Check if the user can be shown.
   */
  function canShowUser(user: User): boolean {
    return currentUser?.role === UserRole.SUPERADMIN && user.deletedAt === null
  }

  /**
   * Check if the user can be edited.
   */
  function canEditUser(user: User): boolean {
    return currentUser?.role === UserRole.SUPERADMIN && user.deletedAt === null
  }

  /**
   * Check if the user can be deleted.
   */
  function canDeleteUser(user: User): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      (currentUser?.role === UserRole.ADMIN &&
        currentUser.companyId !== user.companyId &&
        user?.role !== UserRole.SUPERADMIN)
    )
  }

  /**
   * Check if the user can be recovered.
   */
  function canRecoverUser(user: User): boolean {
    return currentUser?.role === UserRole.SUPERADMIN && user.deletedAt !== null
  }

  /**
   * Open the delete dialog.
   */
  function openDeleteUserDialog(user: User): void {
    setSelectedUser(user)
    setIsDeleteUserDialogOpen(true)
  }

  /**
   * Close the delete dialog.
   */
  function closeDeleteUserDialog(): void {
    setIsDeleteUserDialogOpen(false)
    setSelectedUser(undefined)
  }

  /**
   * Open the user recover dialog.
   */
  function openRecoverUserDialog(user: User): void {
    setSelectedUser(user)
    setIsRecoverUserDialogOpen(true)
  }

  /**
   * Close the user recover dialog.
   */
  function closeRecoverUserDialog(): void {
    setIsRecoverUserDialogOpen(false)
    setSelectedUser(undefined)
  }

  /**
   * Open the edit user dialog.
   */
  function openEditUserDialog(user: User): void {
    dispatch(setUser(user))
    dispatch(setShow(true))
  }

  /**
   * Close the edit user dialog.
   */
  function closeEditUserDialog(): void {
    dispatch(setShow(false))
    dispatch(setUser(undefined))
  }

  /**
   * Delete the user.
   */
  async function handleDeleteUser(user: User): Promise<void> {
    try {
      setIsDeletingUser(true)

      if (user.deletedAt === null) {
        await deleteUser(user.id)
      } else {
        await deleteUser(user.id, true)
      }

      const deletedUser = { ...user, deletedAt: new Date().toISOString() }
      dispatch(updateUser(deletedUser))

      if (onDelete !== undefined) {
        onDelete(deletedUser)
      }

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('userArchiveSuccess')
        )
      )
    } catch (error: any) {
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UserErrors.DELETE,
        error: error
      })

      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsDeletingUser(false)
      closeDeleteUserDialog()
    }
  }

  /**
   * Recover the user.
   */
  async function handleRecoverUser(user: User): Promise<void> {
    try {
      setIsRecoveringUser(true)

      await recoverUser(user.id)

      const recoveredUser = { ...user, deletedAt: null }
      dispatch(updateUser(recoveredUser))

      if (onRecover !== undefined) {
        onRecover(recoveredUser)
      }

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('userRecoverySuccess')
        )
      )
    } catch (error: any) {
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UserErrors.RECOVER,
        error: error
      })

      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsRecoveringUser(false)
    }
  }

  return (
    <>
      {isDeletingUser ? (
        <LoadingState />
      ) : (
        <DataGrid
          rows={users}
          columns={columns}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar
          }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 }
            }
          }}
          sx={{
            border: 0
          }}
          {...dataGridProps}
        />
      )}

      {selectedUser !== undefined && (
        <>
          <Dialog
            open={isDeleteUserDialogOpen && selectedUser.deletedAt === null}
            onClose={() => closeDeleteUserDialog}
            aria-labelledby="user-archive-dialog-title"
            aria-describedby="user-archive-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="user-archive-dialog-title">
              {t('archiveUserDialogTitle')}
            </DialogTitle>

            <DialogContent>
              {selectedUser !== undefined && (
                <DialogContentText id="user-archive-dialog-description">
                  {currentUser?.role === UserRole.ADMIN
                    ? t('archiveUserDialogDescriptionForAdmin')
                    : t('archiveUserDialogDescription')}
                </DialogContentText>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                disabled={isDeletingUser}
                onClick={closeDeleteUserDialog}
                autoFocus
              >
                {t('cancel')}
              </Button>

              <Button
                variant="contained"
                color="error"
                disabled={isDeletingUser}
                onClick={() => handleDeleteUser(selectedUser)}
              >
                {isDeletingUser ? <CircularProgress /> : t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={isDeleteUserDialogOpen && selectedUser.deletedAt !== null}
            onClose={() => closeDeleteUserDialog}
            aria-labelledby="user-delete-dialog-title"
            aria-describedby="user-delete-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="user-delete-dialog-title">
              {t('deleteUserDialogTitle')}
            </DialogTitle>

            <DialogContent>
              {selectedUser !== undefined && (
                <DialogContentText id="user-delete-dialog-description">
                  {t('deleteUserDialogDescription')}
                </DialogContentText>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                disabled={isDeletingUser}
                onClick={closeDeleteUserDialog}
                autoFocus
              >
                {t('cancel')}
              </Button>

              <Button
                variant="contained"
                color="error"
                disabled={isDeletingUser}
                onClick={() => handleDeleteUser(selectedUser)}
              >
                {isDeletingUser ? <CircularProgress /> : t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={isRecoverUserDialogOpen}
            onClose={() => closeRecoverUserDialog}
            aria-labelledby="user-recover-dialog-title"
            aria-describedby="user-recover-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="user-recover-dialog-title">
              {t('recoverUserDialogTitle')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="user-recover-dialog-description">
                {t('recoverUserDialogDescription')}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                disabled={isRecoveringUser}
                onClick={closeRecoverUserDialog}
                autoFocus
              >
                {t('cancel')}
              </Button>

              <Button
                variant="contained"
                color="success"
                disabled={isRecoveringUser}
                onClick={() => handleRecoverUser(selectedUser)}
              >
                {isRecoveringUser ? <CircularProgress /> : t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      <Dialog
        open={isEditUserDialogOpen}
        onClose={() => closeEditUserDialog()}
        aria-labelledby="user-edit-dialog-title"
        aria-describedby="user-edit-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="user-edit-dialog-title">
          {isEditUserDialogOpen && editableUser
            ? t('editUser')
            : t('createNewUser')}
        </DialogTitle>
        <DialogContent id="user-edit-dialog-description">
          <Box sx={{ pt: 3 }}>
            <UserForm />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
