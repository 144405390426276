import { AdminType, UserType } from '../enums/RoleType'
import { MembershipRole } from '../enums/MembershipRole'
import { UserRole } from '../enums/UserRole'

export function checkUserRole(role: string) {
  if (role === UserRole.ADMIN) {
    return AdminType.COMPANY_ADMIN
  } else if (role === UserRole.USER) {
    return UserType.COMPANY_USER
  } else return role
}

export function checkProjectRole(role: string) {
  if (role === MembershipRole.ADMIN) {
    return AdminType.PROJECT_ADMIN
  } else if (role === MembershipRole.USER) {
    return UserType.PROJECT_USER
  } else return role
}
