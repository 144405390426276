import { Dispatch, createSlice } from '@reduxjs/toolkit'
import { DateRange } from '../../types'
import { startOfMinute, subHours } from 'date-fns'
import TimePeriod from '../../enums/TimePeriod'

interface QueryState {
  dateRange: DateRange
  timePeriod: TimePeriod
}

const initialState: QueryState | undefined = getInitialState()

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    setDateRangeState(state, action) {
      state.dateRange = action.payload
    },
    setTimePeriodState(state, action) {
      state.timePeriod = action.payload
    }
  }
})

function getInitialState() {
  const searchParams = new URLSearchParams(window.location.search)
  const foundDateRange = searchParams.get('daterange') ?? undefined
  const foundTimePeriod = searchParams.get('timeperiod') ?? undefined
  if (foundDateRange) {
    const dateRangeFromQuery = JSON.parse(foundDateRange)

    return {
      dateRange: {
        from: startOfMinute(new Date(dateRangeFromQuery.from)),
        to: startOfMinute(new Date(dateRangeFromQuery.to))
      },
      timePeriod: (foundTimePeriod as TimePeriod) ?? TimePeriod.TWENTYFOUR_HOURS
    }
  }

  const now = startOfMinute(new Date())

  const dateRange: DateRange = {
    from: subHours(now, 24),
    to: now
  }
  const foundHash: string = location.hash.substr(1)
  const hash = foundHash ? '#' + foundHash : ''
  searchParams.set('daterange', JSON.stringify(dateRange))

  const newRelativePathQuery =
    window.location.pathname + hash + '?' + searchParams.toString()

  window.history.replaceState(null, '', newRelativePathQuery)

  return {
    dateRange,
    timePeriod: TimePeriod.TWENTYFOUR_HOURS
  }
}

export function getInitialTimePeriod() {
  return async (dispatch: Dispatch) => {
    const searchParams = new URLSearchParams(window.location.search)
    const foundTimePeriod = searchParams.get('timeperiod') ?? undefined
    if (foundTimePeriod) {
      dispatch(querySlice.actions.setTimePeriodState(foundTimePeriod))
    } else {
      searchParams.set('timeperiod', TimePeriod.TWENTYFOUR_HOURS)
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
    }
  }
}

export function setDateRange(dateRange: DateRange) {
  return async (dispatch: Dispatch) => {
    const searchParams = new URLSearchParams(window.location.search)
    dispatch(querySlice.actions.setDateRangeState(dateRange))
    searchParams.set(
      'daterange',
      JSON.stringify({
        from: dateRange.from,
        to: dateRange.to
      })
    )
    const newRelativePathQuery =
      window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState(null, '', newRelativePathQuery)
  }
}

export function setTimePeriod(timePeriod: TimePeriod) {
  return async (dispatch: Dispatch) => {
    const searchParams = new URLSearchParams(window.location.search)
    dispatch(querySlice.actions.setTimePeriodState(timePeriod))

    searchParams.set('timeperiod', timePeriod)
    const newRelativePathQuery =
      window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState(null, '', newRelativePathQuery)
  }
}

export default querySlice.reducer
