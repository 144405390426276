import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate } from 'react-router-dom'

import { spacing } from '@mui/system'

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
  Button,
  Stack,
  Grid,
  List,
  ListItemText
} from '@mui/material'

import { ReactComponent as Logo } from '../../vendor/spine-white-logo-text.svg'
import { SidebarItemsType } from '../../types/sidebar'
import Footer from './SidebarFooter'
import SidebarNav from './SidebarNav'
import { Scale } from '@mui/icons-material'
import useAuth from '../../hooks/useAuth'
import StoreIcon from '@mui/icons-material/Store'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import MailIcon from '@mui/icons-material/Mail'
import { useTranslation } from 'react-i18next'
import NavbarProjectsDropdown from '../navbar/NavbarProjectsDropdown'
import { useDispatch } from 'react-redux'
import {
  clearActiveProjectMembership,
  getMyProjects,
  setActiveProjectMembership,
  setShow
} from '../../redux/slices/projects'
import { UserRole } from '../../enums/UserRole'
import ProjectMembershipRole from '../../enums/ProjectMembershipRole'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import BoltIcon from '@mui/icons-material/Bolt'
import { ProjectReducerType } from '../../enums/ProjectReducerType'

const Box = styled(MuiBox)(spacing)

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode
  to?: string
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`
const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
  transform: scale(3.5);
`

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number
    }
  }
  variant?: 'permanent' | 'persistent' | 'temporary'
  open?: boolean
  onClose?: () => void
  items: {
    title: string
    pages: SidebarItemsType[]
  }[]
  showFooter?: boolean
}

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  ...rest
}) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [t] = useTranslation('common')
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser && currentUser.activeProjectMembership) {
      dispatch(
        setActiveProjectMembership(
          currentUser.activeProjectMembership.projectId
        )
      )
      dispatch(getMyProjects(ProjectReducerType.ACTIVE_PROJECTS))
    } else {
      dispatch(clearActiveProjectMembership())
      dispatch(getMyProjects(ProjectReducerType.ACTIVE_PROJECTS))
    }
  }, [currentUser])

  //TODO: Make new page for project creation if needed
  function handleOpenProjectForm() {
    dispatch(setShow(true))
    navigate('/projects')
  }
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        <BrandIcon />
      </Brand>

      <Box p={6} pb={0}>
        <NavbarProjectsDropdown />
      </Box>

      <SidebarNav items={items} />

      <Stack display="flex" flexDirection="column" spacing={3} p={6}>
        <Button
          variant="contained"
          size="small"
          endIcon={<MailIcon />}
          onClick={() => {
            navigate('/request-access')
          }}
        >
          {t('Request.requestAccess')}
        </Button>
        {currentUser && currentUser.activeProjectMembership === null && (
          <Button
            size="small"
            variant="contained"
            onClick={() => handleOpenProjectForm()}
          >
            {t('createProject')}
          </Button>
        )}

        {currentUser &&
          currentUser.activeProjectMembership &&
          currentUser.activeProjectMembership.role ===
            ProjectMembershipRole.ADMIN && (
            <Grid container>
              <Grid item xs={9}>
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<LocationCityIcon />}
                  onClick={() =>
                    navigate(
                      `/projects/${currentUser.activeProjectMembership.projectId}`
                    )
                  }
                >
                  {t('Sidebar.manageProject')}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  size="small"
                  onClick={() =>
                    navigate(
                      `/projects/${currentUser.activeProjectMembership.projectId}/invite-user`
                    )
                  }
                >
                  <PersonAddAlt1Icon />
                </Button>
              </Grid>
            </Grid>
          )}
        {currentUser && currentUser.activeProjectMembership && (
          <Button
            variant="outlined"
            size="small"
            endIcon={<BoltIcon />}
            onClick={() =>
              navigate(
                `/projects/${currentUser.activeProjectMembership.projectId}#monitoring`
              )
            }
          >
            {t('projectDevices')}
          </Button>
        )}

        {(currentUser && currentUser.role === UserRole.ADMIN) ||
        (currentUser && currentUser.role === UserRole.SUPERADMIN) ? (
          <Grid container>
            <Grid item xs={9}>
              <Button
                disabled={currentUser.company === null}
                variant="outlined"
                size="small"
                endIcon={<StoreIcon />}
                onClick={() => navigate(`/companies/${currentUser.company.id}`)}
              >
                {t('Sidebar.manage')}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                disabled={currentUser.company === null}
                size="small"
                onClick={() =>
                  navigate(`/companies/${currentUser.company.id}#users`)
                }
              >
                <PersonAddAlt1Icon />
              </Button>
            </Grid>
          </Grid>
        ) : null}

        {(currentUser &&
          currentUser.company &&
          currentUser.role === UserRole.USER) ||
        (currentUser &&
          currentUser.company &&
          currentUser.role === UserRole.INSTALLER) ? (
          <Button
            disabled={currentUser.company === null}
            variant="outlined"
            size="small"
            onClick={() => navigate(`/companies/${currentUser.company.id}`)}
          >
            <StoreIcon />
            {t('Sidebar.seeCompany')}
          </Button>
        ) : null}

        {(currentUser && currentUser.role === UserRole.SUPERADMIN) ||
        (currentUser && currentUser.role === UserRole.ADMIN) ? (
          <Button
            disabled={currentUser.company === null}
            size="small"
            variant="outlined"
            endIcon={<BoltIcon />}
            onClick={() =>
              navigate(`/companies/${currentUser.company.id}#devices`)
            }
          >
            {t('companyDevices')}
          </Button>
        ) : null}
      </Stack>

      <List sx={{ display: { xs: 'block', md: 'none' } }}>
        <ListItemButton component={NavLink} to="/user-manual">
          <ListItemText primary={t('userManual')} />
        </ListItemButton>
        <ListItemButton component={NavLink} to="/privacy-policy">
          <ListItemText primary={t('privacyPolicy')} />
        </ListItemButton>
        <ListItemButton component={NavLink} to="/terms-of-service">
          <ListItemText primary={t('termsOfService')} />
        </ListItemButton>
      </List>

      {!!showFooter && <Footer />}
    </Drawer>
  )
}

export default Sidebar
