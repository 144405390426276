import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectStateType } from '../enums/ProjectStateType'

export default function ProjectStateSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField name="state" label={t('status')} select {...props}>
      {props.value === ProjectStateType.ENDED && (
        <MenuItem value={ProjectStateType.ENDED}>{t('ended')}</MenuItem>
      )}
      <MenuItem value={ProjectStateType.PLANNED}>{t('planned')}</MenuItem>
      <MenuItem value={ProjectStateType.EXTENDED}>{t('extended')}</MenuItem>
      <MenuItem value={ProjectStateType.INACTIVE}>
        {t('Projects.inactive')}
      </MenuItem>
      <MenuItem value={ProjectStateType.ACTIVE}>
        {t('Projects.active')}
      </MenuItem>
    </TextField>
  )
}
