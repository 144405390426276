import React from 'react'
import styled from 'styled-components/macro'
import { Paper } from '@mui/material'
import ManufacturingHeader from '../../components/manufacturing/ManufacturingPageHeader'
import HomeNavigation from '../../components/manufacturing/HomeNavigation'
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(12)};
  }
`
function Home() {
  return (
    <React.Fragment>
      <ManufacturingHeader />
      <Wrapper>
        <HomeNavigation />
      </Wrapper>
    </React.Fragment>
  )
}

export default Home
