import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardHeader,
  Paper,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
  Alert
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate, useParams } from 'react-router-dom'
import { getOrder } from '../../redux/slices/orders'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { deleteOrderItem, getOrderItems } from '../../redux/slices/orderItems'
import { Delete } from '@mui/icons-material'
import { OrderStatus } from '../../enums/OrderStatus'
import { OrderItemType } from '../../enums/OrderItemType'
import { OrderItem, Product } from '../../types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'
import { ProductStatus } from '../../enums/ProductStatus'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { updateProduct } from '../../services/productService'
import { updateProductState } from '../../redux/slices/products'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import LoadingState from '../LoadingState'
import { createUserNotification } from '../../utils/createUserNotification'
import { ProductErrors } from '../../enums/ProductErrors'
import { useAuth } from '../../hooks'
import { OrderItemErrors } from '../../enums/OrderItemErrors'
import orderService from '../../services/orderService'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

function OrderInfo() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * The order id
   */
  const { id } = useParams()

  /**
   * The order
   */
  const { order } = useSelector((state: RootState) => state.orders)

  /**
   * Indicates if order are being loaded.
   */
  const isLoadingOrder = useSelector((state: RootState) => state.orders.loading)

  /**
   * The orderitems of the order
   */
  const { orderItems } = useSelector((state: RootState) => state.orderitems)

  /**
   * Indicates if orderItems are being loaded.
   */
  const isLoadingOrderItems = useSelector(
    (state: RootState) => state.orderitems.loading
  )

  /**
   * The theme
   */
  const { theme } = useTheme()

  useEffect(() => {
    if (id) {
      dispatch(getOrder(parseInt(id)))
      dispatch(getOrderItems(parseInt(id)))
    }
  }, [])

  /**
   * Handle delete orderItem
   * @param id
   */
  async function handleDelete(orderItemId: number) {
    try {
      await orderService.deleteOrderItem(order?.id as number, orderItemId)
      dispatch(deleteOrderItem(orderItemId))
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: OrderItemErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  /**
   * Open delete orderitem dialog
   */
  const [open, setOpen] = useState(false)

  /**
   * Open send multiple products dialog
   */
  const [openSendMultipleDialog, setOpenSendMultipleDialog] = useState(false)

  /**
   * The orderItem Id
   */
  const [orderItemId, setOrderItemId] = useState(0)

  /**
   * Handle open delete orderitem dialog
   */
  const handleClickOpen = (id: any) => {
    setOrderItemId(id)
    setOpen(true)
  }

  /**
   * Handle open delete orderitem dialog
   */
  const handleClickOpenSendMultipleDialog = () => {
    setOpenSendMultipleDialog(true)
  }

  /**
   * Handle close delete orderitem dialog
   */
  const handleClose = () => {
    setOpen(false)
    setOrderItemId(0)
  }

  /**
   * Handle close send multiple products dialog
   */
  const handleCloseSendMultipleDialog = () => {
    setOpenSendMultipleDialog(false)
  }

  /**
   * Check and translate Order status
   * @param status
   * @returns
   */
  const checkStatus = (status: string) => {
    switch (status) {
      case OrderStatus.PENDING:
        return t('Manufacturing.pending')
      case OrderStatus.ON_HOLD:
        return t('Manufacturing.onHold')
      case OrderStatus.IN_PROGRESS:
        return t('Manufacturing.inProgress')
      case OrderStatus.SHIPPED:
        return t('Manufacturing.shipped')
    }
  }

  /**
   * Check and translate Orderitem type
   * @param type
   * @returns
   */
  const checkType = (type: string) => {
    switch (type) {
      case OrderItemType.INSTALLATION_KIT:
        return t('Manufacturing.kit')
      case OrderItemType.PLUG_AND_PLAY:
        return t('Manufacturing.plug')
      case OrderItemType.SMART_ELECTRICAL_CABINET:
        return t('Manufacturing.cabinet')
      case OrderItemType.SPINE_MODULE:
        return t('spineModule')
    }
  }

  /**
   * @param products
   * @param status
   * @returns Lenght of the products by status
   */
  const getLengthOfProducts = (
    products: Product[] | undefined,
    status: ProductStatus
  ) => {
    return products
      ? products.filter((product) => product.status === status).length
      : 0
  }

  /**
   * @returns List of the products that are tested
   */
  function handleListOfTestedProducts() {
    const allTestedProducts: Product[] = []
    orderItems.map((orderItem) =>
      orderItem?.products?.map((product) => {
        if (product.status === ProductStatus.TESTED) {
          allTestedProducts.push(product)
        }
      })
    )
    return allTestedProducts
  }

  /**
   * Show alert
   */
  const [showAlert, setShowAlert] = useState<boolean>(false)

  /**
   * Is submitting
   */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  /**
   * Handle refreshing data after dispatching tested products
   */
  function handleRefreshData() {
    if (id) {
      dispatch(getOrder(parseInt(id)))
      dispatch(getOrderItems(parseInt(id)))
    }
  }

  /**
   * Handle dispatch all tested products
   */
  async function handleSendAllTestedProducts() {
    try {
      setIsSubmitting(true)
      const testedProducts = handleListOfTestedProducts()
      if (testedProducts.length > 0) {
        for (const product of testedProducts) {
          const updatedProduct = await updateProduct(product.id as number, {
            status: ProductStatus.SHIPPED
          })
          dispatch(updateProductState(updatedProduct))
        }

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('productDispatchSuccess')
          )
        )
        handleRefreshData()
      } else {
        setShowAlert(true)
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleCloseSendMultipleDialog()
      setIsSubmitting(false)
    }
  }

  function handleNavigate(orderItemId: number) {
    const searchParams = new URLSearchParams(window.location.search)
    if (id && orderItemId) {
      navigate(`/manufacturing/products`)
      searchParams.set('order', id)
      searchParams.set('orderItem', orderItemId.toString())
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
    }
  }

  return (
    <React.Fragment>
      {isSubmitting || isLoadingOrder || isLoadingOrderItems ? (
        <LoadingState />
      ) : (
        <React.Fragment>
          {order && orderItems && (
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl>
                <Box
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => navigate('/manufacturing/orders')}
                  >
                    <ArrowBackIcon />
                    {t('Manufacturing.back')}
                  </Button>
                  <Button
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={() =>
                      navigate(`/manufacturing/products/${order.id}/create`)
                    }
                  >
                    {t('Manufacturing.addProduct')}
                    <AddIcon />
                  </Button>
                </Box>
                <Divider my={6} />
                <Card mb={6}>
                  <CardHeader
                    title={
                      t('Manufacturing.orderReference') + ': ' + order.name
                    }
                    align="center"
                  />
                  <CardHeader
                    title={
                      t('Manufacturing.orderDispatchDate') +
                      ': ' +
                      order.dueDate.toLocaleString()
                    }
                    align="center"
                  />
                  <CardHeader
                    title={
                      t('Manufacturing.status') +
                      ': ' +
                      checkStatus(order.status)
                    }
                    align="center"
                  />
                  {showAlert ? (
                    <Alert
                      severity="warning"
                      color="warning"
                      onClose={() => setShowAlert(false)}
                    >
                      {t('noTestedProducts')}
                    </Alert>
                  ) : (
                    <>
                      <Typography align="center">
                        <Button
                          disabled={order.company === null}
                          size="small"
                          variant="contained"
                          onClick={() => handleClickOpenSendMultipleDialog()}
                        >
                          {t('sendAllTestedProducts')} <LocalShippingIcon />
                        </Button>
                      </Typography>
                      {/**Send multiple products dialog */}
                      <Dialog
                        open={openSendMultipleDialog}
                        onClose={handleCloseSendMultipleDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {t('confirmSendAllProducts')}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t('Manufacturing.orderReference')}: {order.name}
                          </DialogContentText>
                        </DialogContent>

                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t('Manufacturing.customer')}:{' '}
                            {order?.company?.name}
                          </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                          <Button onClick={() => handleSendAllTestedProducts()}>
                            {t('Manufacturing.yes')}
                          </Button>
                          <Button
                            onClick={handleCloseSendMultipleDialog}
                            autoFocus
                          >
                            {t('Manufacturing.no')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}

                  <Divider my={6} />

                  <Paper>
                    <TableWrapper>
                      <Table>
                        <TableBody>
                          {orderItems.map((orderItem: OrderItem) => (
                            <TableRow key={orderItem.id}>
                              {orderItem.isPrepared ? (
                                <React.Fragment>
                                  <TableCell style={{ color: 'grey' }}>
                                    {orderItem.quantity}{' '}
                                    {t('Manufacturing.pcs')},{' '}
                                    {orderItem.nominalCurrent === 0
                                      ? ''
                                      : orderItem.nominalCurrent + 'A,'}
                                    {checkType(orderItem.type)}{' '}
                                    {orderItem.nominalCurrent === 0
                                      ? t('takenFromTheStorage')
                                      : orderItem.withPowerLeds
                                      ? t('withLedbar')
                                      : t('withoutLedbar')}
                                  </TableCell>
                                  <TableCell align="left">
                                    {t('pending')}
                                    {': '}
                                    {
                                      /**Pending products */
                                      getLengthOfProducts(
                                        orderItem.products,
                                        ProductStatus.PENDING
                                      )
                                    }{' '}
                                    {t('done')}
                                    {': '}
                                    {
                                      /**Assembled products */
                                      getLengthOfProducts(
                                        orderItem.products,
                                        ProductStatus.ASSEMBLED
                                      ) +
                                        /**Prepared products */
                                        getLengthOfProducts(
                                          orderItem.products,
                                          ProductStatus.PREPARED
                                        ) +
                                        /**Stored products */
                                        getLengthOfProducts(
                                          orderItem.products,
                                          ProductStatus.STORED
                                        ) +
                                        /**Tested products */
                                        getLengthOfProducts(
                                          orderItem.products,
                                          ProductStatus.TESTED
                                        )
                                    }{' '}
                                    {t('shipped')}
                                    {': '}
                                    {
                                      /**Shipped products */
                                      getLengthOfProducts(
                                        orderItem.products,
                                        ProductStatus.SHIPPED
                                      )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {orderItem.products?.length === 0 ? (
                                      <>
                                        <Delete
                                          style={{ cursor: 'pointer' }}
                                          color="error"
                                          onClick={() =>
                                            handleClickOpen(orderItem.id)
                                          }
                                        >
                                          {t('Manufacturing.delete')}
                                        </Delete>

                                        <Dialog
                                          open={open}
                                          onClose={handleClose}
                                          aria-labelledby="alert-dialog-title"
                                          aria-describedby="alert-dialog-description"
                                        >
                                          <DialogTitle id="alert-dialog-title">
                                            {t('Manufacturing.confirmProducts')}
                                          </DialogTitle>

                                          <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                              {t('Manufacturing.warning')}
                                            </DialogContentText>
                                          </DialogContent>

                                          <DialogActions>
                                            <Button
                                              onClick={() =>
                                                handleDelete(orderItemId)
                                              }
                                            >
                                              {t('Manufacturing.yes')}
                                            </Button>
                                            <Button
                                              onClick={handleClose}
                                              autoFocus
                                            >
                                              {t('Manufacturing.no')}
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </>
                                    ) : (
                                      <CheckCircleIcon
                                        sx={{
                                          color:
                                            theme === THEMES.DARK
                                              ? 'lightGreen'
                                              : 'green',
                                          mr: 5
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        handleNavigate(orderItem.id)
                                      }
                                    >
                                      <ArrowForwardIosIcon />
                                    </Button>
                                  </TableCell>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <TableCell>
                                    {orderItem.quantity}{' '}
                                    {t('Manufacturing.pcs')},{' '}
                                    {orderItem.nominalCurrent} A,{' '}
                                    {checkType(orderItem.type)}{' '}
                                    {orderItem.withPowerLeds
                                      ? t('withLedbar')
                                      : t('withoutLedbar')}
                                  </TableCell>

                                  <TableCell align="left">
                                    {t('pending')}
                                    {': '}
                                    {
                                      /**Pending products */
                                      getLengthOfProducts(
                                        orderItem.products,
                                        ProductStatus.PENDING
                                      )
                                    }{' '}
                                    {t('done')}
                                    {': '}
                                    {
                                      /**Assembled products */
                                      getLengthOfProducts(
                                        orderItem.products,
                                        ProductStatus.ASSEMBLED
                                      ) +
                                        /**Prepared products */
                                        getLengthOfProducts(
                                          orderItem.products,
                                          ProductStatus.PREPARED
                                        ) +
                                        /**Stored products */
                                        getLengthOfProducts(
                                          orderItem.products,
                                          ProductStatus.STORED
                                        ) +
                                        /**Tested products */
                                        getLengthOfProducts(
                                          orderItem.products,
                                          ProductStatus.TESTED
                                        )
                                    }{' '}
                                    {t('shipped')}
                                    {': '}
                                    {
                                      /**Shipped products */
                                      getLengthOfProducts(
                                        orderItem.products,
                                        ProductStatus.SHIPPED
                                      )
                                    }
                                  </TableCell>

                                  <TableCell align="right">
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        handleNavigate(orderItem.id)
                                      }
                                    >
                                      <ArrowForwardIosIcon />
                                    </Button>
                                  </TableCell>
                                </React.Fragment>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableWrapper>
                  </Paper>
                </Card>
              </Grid>
            </Grid>
          )}
          <Typography align="right">
            <Button
              variant="contained"
              onClick={() => navigate('/manufacturing')}
            >
              {t('Manufacturing.done')}
            </Button>
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default OrderInfo
