import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function PowerSupplyFuseSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField name="fuse" label={t('PowerSupply.fuse')} select {...props}>
      {props.value === 0 && (
        <MenuItem value={0}>{t('selectFuseSize')}</MenuItem>
      )}
      <MenuItem value={'16'}>16A</MenuItem>
      <MenuItem value={'32'}>32A</MenuItem>
      <MenuItem value={'63'}>63A</MenuItem>
      <MenuItem value={'125'}>125A</MenuItem>
      <MenuItem value={'200'}>200A</MenuItem>
      <MenuItem value={'250'}>250A</MenuItem>
      <MenuItem value={'300'}>300A</MenuItem>
      <MenuItem value={'400'}>400A</MenuItem>
      <MenuItem value={'500'}>500A</MenuItem>
      <MenuItem value={'600'}>600A</MenuItem>
      <MenuItem value={'800'}>800A</MenuItem>
      <MenuItem value={'1000'}>1000A</MenuItem>
      <MenuItem value={'1200'}>1200A</MenuItem>
    </TextField>
  )
}
