import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Tab,
  Stack,
  IconButton,
  Tooltip
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import {
  getCompanies,
  getCompany,
  setShowCompany,
  setCompany,
  setLoading,
  updateCompany
} from '../../redux/slices/companies'
import { Add, Delete, Edit, Visibility } from '@mui/icons-material'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import CompanyForm from '../../components/CompanyForm'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CompaniesMap from '../../components/CompaniesMap'
import { forwardGeocoding } from '../../utils/forwardGeocoding'
import { UserRole } from '../../enums/UserRole'
import { Company, GeoCoordinate } from '../../types'
import CompanyArchiveTable from '../../components/CompanyArchiveTable'
import { deleteCompany } from '../../services/companyService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { createUserNotification } from '../../utils/createUserNotification'
import { NotificationType } from '../../enums/NotificationType'
import { UserErrors } from '../../enums/UserErrors'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import LoadingState from '../../components/LoadingState'

const Card = styled(MuiCard)(spacing)

const Divider = styled(MuiDivider)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

const Paper = styled(MuiPaper)(spacing)

const Button = styled(MuiButton)(spacing)

const TableWrapper = styled.div`
  overflow-x: auto;
`

// eslint-disable-next-line
function CompanyTable(props: any) {
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const { companies, company, show } = props
  const [t] = useTranslation('common')

  const handleShow = (payload: boolean) => {
    dispatch(setShowCompany(payload))

    if (company !== undefined) {
      dispatch(setCompany(undefined))
    }
  }

  const handleEdit = (id: number) => {
    dispatch(getCompany(id))
    dispatch(setShowCompany(true))
  }

  async function handleDelete(company: Company) {
    try {
      await deleteCompany(company.id)
      company = { ...company }
      company.deletedAt = new Date().toString()
      dispatch(updateCompany(company))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('companyArchiveSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UserErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  const [open, setOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    undefined
  )

  const handleClickOpen = (company: Company) => {
    setSelectedCompany(company)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedCompany(undefined)
  }
  const columns: GridColumns<Company> = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      minWidth: 200,
      flex: 1,
      editable: false
    },
    {
      field: 'address',
      headerName: t('address'),
      flex: 1,
      minWidth: 200,
      editable: false
    },
    {
      field: 'zip',
      headerName: t('zip'),
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'city',
      headerName: t('city'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'countryCode',
      headerName: t('countryCode'),
      type: 'string',
      flex: 1,
      minWidth: 100,
      editable: false,
      valueGetter: (params: any) => params.row.countryCode ?? '-'
    },
    {
      field: 'domain',
      headerName: t('domain'),
      type: 'string',
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: (params: any) => params.row.domain ?? '-'
    },
    {
      field: 'vatNumber',
      headerName: t('vatNumber'),
      type: 'string',
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: (params: any) => params.row.vatNumber ?? '-'
    },
    {
      field: t('actions'),
      headerName: '',
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Tooltip title={t('show') as string}>
                <IconButton
                  size="small"
                  component={NavLink}
                  to={`/companies/${row.id}`}
                >
                  <Visibility fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('edit') as string}>
                <IconButton size="small" onClick={() => handleEdit(row.id)}>
                  <Edit fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('delete') as string}>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => handleClickOpen(row)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>

              {selectedCompany !== undefined && (
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t('companyArchiveTitle')}
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t('companyArchiveInfo')}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={() => handleDelete(selectedCompany)}>
                      {t('yes')}
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                      {t('no')}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Stack>
          </React.Fragment>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <>
      <Card>
        <CardHeader
          title={t('companies')}
          action={
            <Button
              variant="outlined"
              color="primary"
              endIcon={<Add fontSize="inherit" />}
              onClick={() => handleShow(true)}
            >
              {t('newCompany')}
            </Button>
          }
        />
        <CardContent>
          <DataGrid
            rows={companies}
            columns={columns}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 }
              }
            }}
            sx={{
              border: 0
            }}
          />
        </CardContent>
      </Card>

      <Dialog
        open={show}
        onClose={() => handleShow(false)}
        aria-labelledby="company-edit-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="company-edit-dialog-title">
          {company === undefined ? t('newCompany') : t('editCompany')}
        </DialogTitle>

        <DialogContent>
          <CompanyForm />
        </DialogContent>
      </Dialog>
    </>
  )
}

type CompaniesTab = 'companies' | 'map' | 'archive'

export default function Companies(): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The "companies" state.
   */
  const { companies, company, show, loading } = useSelector(
    (state: RootState) => state.companies
  )

  /**
   * The active companies.
   */
  const activeCompanies = companies.filter(
    (company) => company.deletedAt === null
  )

  /**
   * The deleted companies.
   */
  const deletedCompanies = companies.filter(
    (company) => company.deletedAt !== null
  )

  /**
   * The current tab.
   */
  const [tab, setTab] = useState<CompaniesTab>('companies')

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(getCompanies({ showDeleted: true }))
      }
    }
  }, [currentUser])

  const handleChange = (
    event: React.SyntheticEvent,
    tab: CompaniesTab
  ): void => {
    setTab(tab)
  }

  const [longLatList, setLongLatList] = useState<GeoCoordinate[] | undefined>()

  useEffect(() => {
    loadLocations()
  }, [companies])

  async function loadLocations(): Promise<void> {
    try {
      setLoading(true)
      if (companies) {
        setLongLatList(await forwardGeocoding(companies))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Helmet title={t('companies')} />

      <Typography variant="h3">{t('companies')}</Typography>

      <Divider mt={6} />

      {loading ? (
        <LoadingState />
      ) : (
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label={t('companies')} value="companies" />
              <Tab label={t('map')} value="map" />
              <Tab label={t('archive')} value="archive" />
            </TabList>
          </Box>

          <Box mt={6}>
            <TabPanel value="companies" sx={{ p: 0 }}>
              {companies && (
                <CompanyTable
                  companies={activeCompanies}
                  show={show}
                  company={company}
                />
              )}
            </TabPanel>

            <TabPanel value="map">
              <CompaniesMap longLatList={longLatList as GeoCoordinate[]} />
            </TabPanel>

            <TabPanel value="archive">
              <CompanyArchiveTable companies={deletedCompanies} />
            </TabPanel>
          </Box>
        </TabContext>
      )}
    </React.Fragment>
  )
}
