import { SensorAggregateStatistic } from '../enums'
import { EnergyConsumption, SensorAggregate } from '../types'

export function mapEnergyConsumptionsByTime(
  energyConsumptions: EnergyConsumption[]
): Map<string, EnergyConsumption> {
  const energyConsumptionsByDate = new Map<string, EnergyConsumption>()

  for (const energyConsumption of energyConsumptions) {
    energyConsumptionsByDate.set(energyConsumption.time, energyConsumption)
  }

  return energyConsumptionsByDate
}

/**
 * Calculates the total energy consumption.
 */
export function calculateTotalEnergyConsumption(
  sensorAggregates: SensorAggregate[],
  statistic: SensorAggregateStatistic = SensorAggregateStatistic.AVG
): number {
  return sensorAggregates
    .map((sensorAggregate) => {
      switch (statistic) {
        case SensorAggregateStatistic.MAX:
          return sensorAggregate.max_kw as number
        case SensorAggregateStatistic.MIN:
          return sensorAggregate.min_kw as number
        case SensorAggregateStatistic.AVG:
        default:
          return sensorAggregate.avg_kw as number
      }
    })
    .reduce((sum: number, kw: number) => {
      return sum + kw
    }, 0)
}

/**
 * Calculates the highest consumption.
 */
export function calculateMaxEnergyConsumption(
  sensorAggregates: SensorAggregate[]
): number {
  return sensorAggregates
    .map((sensorAggregate) => {
      return sensorAggregate.avg_kw as number
    })
    .reduce((sum: number, kw: number) => {
      return sum + kw
    }, 0)
}
