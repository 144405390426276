import { useTranslation } from 'react-i18next'
import { ChartOptions } from 'chart.js'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { RuuviTagMovement, RuuviTagMovementQuery } from '../types'
import RuuviTagMovementChart from './RuuviTagMovementChart'

interface RuuviTagMovementCountChartProps {
  movements: RuuviTagMovement[]
  query: RuuviTagMovementQuery
  loading?: boolean
}

export default function RuuviTagMovementCountChart({
  movements,
  query,
  loading = false
}: RuuviTagMovementCountChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The chart options.
   */
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: true
        }
      }
    }
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" sx={{ mb: 6 }}>
          {t('movement')}
        </Typography>

        <RuuviTagMovementChart
          movements={movements}
          query={query}
          options={options}
          loading={loading}
        />
      </CardContent>
    </Card>
  )
}
