import { useState, MouseEvent } from 'react'
import {
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  Button as MuiButton,
  Divider as MuiDivider,
  IconButton,
  Switch,
  Menu,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  MenuList
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import LoadingState from './LoadingState'
import SensorAggregateStatisticToggle from './SensorAggregateStatisticToggle'
import DeviceCurrentChart from './DeviceCurrentChart'

import SensorAggregateField from '../enums/SensorAggregateField'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'

import DateRange from '../types/DateRange'
import Sensor from '../types/Sensor'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateOptions from '../types/SensorAggregateOptions'

import { Device } from '../types'
import { getDifferenceOfDateRangeInHours } from '../utils/date'
import DeviceCurrentBarTable from './DeviceCurrentBarTable'
import { styled } from '@mui/styles'
import { spacing } from '@mui/system'
import { MoreVertical } from 'react-feather'
import useAppSettings from '../hooks/useAppSettings'
import { red } from '@mui/material/colors'
import { ForcedYAxis, ScalingYAxis } from '../types/YAxisOptions'

import { Square, ZoomIn } from '@mui/icons-material'
const Divider = styled(MuiDivider)(spacing)

interface DeviceCurrentStatsProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The date range.
   */
  dateRange: DateRange

  /**
   * The latest sensor.
   */
  sensor?: Sensor

  /**
   * The sensors.
   */
  sensors: Sensor[]

  /**
   * The sensor aggregates.
   */
  sensorAggregates: SensorAggregate[]

  /**
   * Indicates if the component is loading.
   */
  loading?: boolean
}

export default function DeviceCurrentStatsProps({
  device,
  dateRange,
  sensor,
  sensors,
  sensorAggregates,
  loading = false
}: DeviceCurrentStatsProps) {
  /**
   * The translation function.
   */
  const [t] = useTranslation('common')

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  const [sensorAggregateOptions, setSensorAggregateOptions] =
    useState<SensorAggregateOptions>({
      statistics: getInitialStatistics(),
      fields: [
        SensorAggregateField.CURRENT_1,
        SensorAggregateField.CURRENT_2,
        SensorAggregateField.CURRENT_3
      ]
    })

  /**
   * The anchor element for the menu.
   */
  const [menuAnchor, setAnchorEl] = useState<null | HTMLElement>(null)

  /**
   * Indicates if the menu is open.
   */
  const open = Boolean(menuAnchor)

  function getInitialStatistics(): SensorAggregateStatistic[] {
    const searchParams = new URLSearchParams(window.location.search)
    const foundStatics = searchParams.get('current-statistics') ?? undefined
    if (foundStatics) {
      return JSON.parse(foundStatics)
    } else {
      searchParams.set(
        'current-statistics',
        JSON.stringify(
          appSettings.sensorSettings?.currentChartStatistics
            ? appSettings.sensorSettings.currentChartStatistics
            : [SensorAggregateStatistic.AVG]
        ) as string
      )
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
      return appSettings.sensorSettings?.currentChartStatistics
        ? appSettings.sensorSettings.currentChartStatistics
        : [SensorAggregateStatistic.AVG]
    }
  }

  function changeStatistics(statistics: SensorAggregateStatistic[]) {
    const appSettings = useAppSettings()
    const sensorSettings = appSettings.sensorSettings

    setSensorAggregateOptions({
      ...sensorAggregateOptions,
      statistics
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          currentChartStatistics: statistics
        }
      })
    )

    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('current-statistics', JSON.stringify(statistics) as string)

    const newRelativePathQuery =
      window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState(null, '', newRelativePathQuery)
  }

  /**
   * Indicates if the rated current annotation should be shown.
   */
  const [showRaterdCurrentAnnotation, setShowRatedCurrentAnnotation] =
    useState<boolean>(
      appSettings?.sensorSettings?.showRatedCurrentAnnotation ?? false
    )

  const [yAxisOption, setYAxisOption] = useState<ScalingYAxis | ForcedYAxis>({
    //@ts-ignore
    max: device?.powerSupply?.fuse,
    min: 0,
    defaultChecked: true
  })

  /**
   * Handle the change of the show rated current annotation switch.
   *
   * @param event The event
   */
  function handleShowRatedCurrentAnnotationChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    setShowRatedCurrentAnnotation(event.target.checked)

    // FIXME: Use a service for setting these
    // eg. setAppSettings('sensorSettings.showRatedCurrentAnnotation', event.target.checked)
    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...appSettings.sensorSettings,
          showRatedCurrentAnnotation: event.target.checked
        }
      })
    )
  }

  const handleYAxisOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setYAxisOption({
      //@ts-ignore
      suggestedMax: device?.powerSupply?.fuse,
      suggestedMin: 0,
      defaultChecked: event.target.checked
    })
  }

  /**
   * Open the menu.
   */
  function openMenu(event: MouseEvent<HTMLElement>): void {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu.
   */
  function closeMenu(): void {
    setAnchorEl(null)
  }

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        pt={4}
        pb={2}
      >
        <Typography variant="h6">{t('Device.current')}</Typography>

        <Stack direction="row" spacing={3}>
          <SensorAggregateStatisticToggle
            value={sensorAggregateOptions.statistics}
            onChange={changeStatistics}
          />
        </Stack>

        <IconButton
          aria-controls={open ? 'menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={openMenu}
        >
          <MoreVertical />
        </IconButton>

        <Menu
          anchorEl={menuAnchor}
          open={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            sx: {
              width: '320px'
            }
          }}
        >
          <MenuList>
            <ListItem>
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center" gap={2}>
                    <Square fontSize="small" sx={{ color: red[500] }} />
                    {t('showRatedCurrent')}
                  </Stack>
                }
              />
              <Switch
                size="small"
                checked={showRaterdCurrentAnnotation}
                onChange={handleShowRatedCurrentAnnotationChange}
              />
            </ListItem>

            <ListItem>
              <ListItemText primary={t('zoomIn')} />
              <Switch
                size="small"
                checked={yAxisOption.defaultChecked}
                onChange={handleYAxisOptionChange}
              />
            </ListItem>
          </MenuList>
        </Menu>
      </Box>
      <CardContent>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <DeviceCurrentBarTable device={device} sensor={sensor} />
            <Divider my={6} />
            <DeviceCurrentChart
              device={device}
              dateRange={dateRange}
              sensors={sensors}
              sensorAggregates={sensorAggregates}
              options={sensorAggregateOptions}
              sensor={sensor}
              showAnnotation={showRaterdCurrentAnnotation}
              yAxisOption={yAxisOption}
            />
          </>
        )}
      </CardContent>
    </Card>
  )
}
