import { useEffect, useState } from 'react'
import { useApi } from '../hooks'
import { Divider, Grid, Typography } from '@mui/material'
import ConfluencePageArticle from '../components/ConfluencePageArticle'
import { ConfluencePage } from '../types'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import LoadingState from '../components/LoadingState'
import { ConfluencePages } from '../enums'

export default function TermsOfService(): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Indicates if the page is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The page.
   */
  const [page, setPage] = useState<ConfluencePage>()

  /**
   * The API client.
   */
  const api = useApi('v2')

  /**
   * Loads the page.
   */
  async function loadPage(): Promise<void> {
    try {
      setIsLoading(true)

      const response = await api.get(
        `/confluence/pages/${ConfluencePages.TERMS_OF_SERVICE}`,
        {
          params: {
            'body-format': 'view'
          }
        }
      )

      setPage(response.data as ConfluencePage)
    } catch (err: any) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Runs when the component mounts.
   */
  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      await loadPage()
    })()

    return () => controller.abort()
  }, [])

  return (
    <>
      <Helmet title={t('termsOfService')} />

      <Typography variant="h3">{t('termsOfService')}</Typography>

      <Divider sx={{ my: 6 }} />

      <Grid container spacing={6}>
        <Grid item xl={12}>
          {isLoading || page === undefined ? (
            <LoadingState />
          ) : (
            <ConfluencePageArticle page={page} />
          )}
        </Grid>
      </Grid>
    </>
  )
}
