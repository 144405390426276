import React from 'react'
import styled from 'styled-components/macro'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'

import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider as MuiDivider,
  TableCell,
  Table,
  TableRow,
  Chip,
  useMediaQuery
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateProductState } from '../../redux/slices/products'
import productService from '../../services/productService'
import { setStep } from '../../redux/slices/steps'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { ProductErrors } from '../../enums/ProductErrors'
import { deleteError, setError } from '../../redux/slices/errors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { Device, Product } from '../../types'
import { useTheme } from '@mui/system'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const Divider = styled(MuiDivider)(spacing)

const initialValues = {
  id: '',
  deviceName: ''
}

interface LinkDeviceWithProductProps {
  device: Device
  step: number
}

// eslint-disable-next-line
export default function LinkDeviceWithProduct({
  device,
  step
}: LinkDeviceWithProductProps) {
  const [t] = useTranslation('common')
  const { id } = useParams()
  const dispatch = useDispatch()
  initialValues.id = id as string
  const { currentUser } = useAuth()

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const editedProduct: Product = await productService.updateProduct(
        values.id,
        values
      )
      dispatch(updateProductState(editedProduct))
      dispatch(setStep(step + 1))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('deviceLinkedToProductSuccess')
        )
      )
      resetForm()
      setStatus({ sent: true })
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: ProductErrors.EDIT,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(ProductErrors.EDIT))
    } finally {
      setSubmitting(false)
    }
  }

  const isDeviceMissingInfo = handleCheckDeviceInformation()

  //TODO: Check device's fw and hw version after device entity change has been deployed
  //Add fw and hw also to the table
  function handleCheckDeviceInformation() {
    if (device?.powerSupply && device.asset) {
      return false
    } else return true
  }

  /**
   * The theme.
   */
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography align="center" variant="h4">
              {t('Manufacturing.linkInstructions')}
            </Typography>
            <Divider my={6} />

            {isMobile ? (
              <Table sx={{ mb: 4 }}>
                <TableRow>
                  <TableCell>{t('powerSupply')}: </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color={device?.powerSupply ? 'success' : 'error'}
                      label={device?.powerSupply ? t('found') : t('notFound')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('Asset')}: </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color={device.asset ? 'success' : 'error'}
                      label={device.asset ? t('found') : t('notFound')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('simId')}:</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color={device.simId ? 'success' : 'error'}
                      label={device.simId ? t('found') : t('notFound')}
                    />
                  </TableCell>
                </TableRow>
              </Table>
            ) : (
              <Table sx={{ mb: 4 }}>
                <TableRow>
                  <TableCell>
                    {t('powerSupply')}:{' '}
                    <Chip
                      size="small"
                      color={device?.powerSupply ? 'success' : 'error'}
                      label={device?.powerSupply ? t('found') : t('notFound')}
                    />
                  </TableCell>

                  <TableCell>
                    {t('Asset')}:{' '}
                    <Chip
                      size="small"
                      color={device.asset ? 'success' : 'error'}
                      label={device.asset ? t('found') : t('notFound')}
                    />
                  </TableCell>
                  <TableCell>
                    {t('simId')}:{' '}
                    <Chip
                      size="small"
                      color={device.simId ? 'success' : 'error'}
                      label={device.simId ? t('found') : t('notFound')}
                    />
                  </TableCell>
                </TableRow>
              </Table>
            )}

            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={8} lg={8} xl>
                  <TextField
                    key={(values.deviceName = device.name as string)}
                    size="small"
                    name="name"
                    label={t('Devices.name')}
                    value={device.name}
                    error={Boolean(touched.deviceName && errors.deviceName)}
                    fullWidth
                    disabled={true}
                    helperText={touched.deviceName && errors.deviceName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    disabled={isDeviceMissingInfo}
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    {t('Devices.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
