import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import useAxios from '../hooks/useAxios'
import { Device, Sensor } from '../types'

const axios: AxiosInstance = useAxios()

export async function getPublicDeviceByName(name: string): Promise<Device> {
  const response: AxiosResponse = await axios.get(`/public/device/${name}`)
  return response.data
}

export async function getPublicSensorNow(
  deviceCode: string,
  config: AxiosRequestConfig = {}
): Promise<Sensor> {
  const response: AxiosResponse = await axios.get(
    `/public/device/${deviceCode}/sensors/now`,
    config
  )
  return response.data
}
