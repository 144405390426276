import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { getLifts, getLiftsByProjectId } from '../../redux/slices/lifts'
import useAuth from '../../hooks/useAuth'
import { UserRole } from '../../enums/UserRole'
import LiftTable from '../../components/LiftTable'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

/**
 * @deprecated
 */
export default function Lifts() {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(getLifts())
      } else if (currentUser?.role === UserRole.ADMIN) {
        dispatch(getLiftsByProjectId(currentUser?.activeProject.id))
      }
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <Helmet title={t('lifts')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('lifts')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('frontpage')}
        </Link>
        <Typography>{t('lifts')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LiftTable />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
