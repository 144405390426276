import { AxiosRequestConfig } from 'axios'
import { useApi } from '../hooks'
import {
  Asset,
  AssetInput,
  AssetQuery,
  PaginationOutput,
  PaginationQuery
} from '../types'

const api = useApi('/v2')

export async function getAssets(
  query: AssetQuery = {},
  config: AxiosRequestConfig = {}
): Promise<Asset[]> {
  const response = await api.get('/assets', {
    ...config,
    params: query
  })

  return response.data
}

export async function getAsset(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<Asset> {
  const response = await api.get(`/assets/${id}`, {
    ...config
  })

  return response.data
}

export async function updateAsset(
  id: string,
  input: AssetInput,
  config: AxiosRequestConfig = {}
): Promise<Asset> {
  const response = await api.patch(`/assets/${id}`, input, {
    ...config
  })

  return response.data
}

export async function uploadAssetPhoto(
  id: string,
  photo: File,
  config: AxiosRequestConfig = {}
): Promise<Asset> {
  const formData = new FormData()

  // We need to set the content type to multipart/form-data
  // so that the server can parse the request body correctly.
  formData.set('Content-Type', 'multipart/form-data')

  formData.append('file', photo)

  const response = await api.patch(`/assets/${id}/photo`, formData, {
    ...config
  })

  return response.data
}

export async function deleteAssetPhoto(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<Asset> {
  const response = await api.delete(`/assets/${id}/photo`, {
    ...config
  })

  return response.data
}

export async function getAssetsUsingPagination(
  query: AssetQuery = {},
  pagination: PaginationQuery,
  config: AxiosRequestConfig = {}
): Promise<PaginationOutput<Asset>> {
  const response = await api.get('/assets', {
    ...config,
    params: {
      ...query,
      ...pagination
    }
  })

  const page = parseInt(response.headers['x-page'])
  const pageSize = parseInt(response.headers['x-page-size'])
  const pageCount = parseInt(response.headers['x-page-count'])
  const totalCount = parseInt(response.headers['x-total-count'])

  return {
    page,
    pageSize,
    pageCount,
    totalCount,
    data: response.data
  }
}
