import { EnergyCost } from '../types'
import EnergyPrice from '../types/EnergyPrice'
import SensorAggregate from '../types/SensorAggregate'
import { transformEnergyPricesToMap } from './energyPrice'
import { transformSensorAggregatesToMap } from './sensorAggregate'

/**
 * Calculate the energy costs.
 */
export function calculateEnergyCostsForSpotEnergyPrices(
  sensorAggregates: SensorAggregate[],
  energyPrices: EnergyPrice[]
): number {
  const sensorAggregatesByDate: Map<string, SensorAggregate> =
    transformSensorAggregatesToMap(sensorAggregates)

  const energyPricesByDate: Map<string, EnergyPrice> =
    transformEnergyPricesToMap(energyPrices)

  const datesAsIsoStrings = Array.from(sensorAggregatesByDate.keys())

  let energyCost = 0

  datesAsIsoStrings.forEach((date) => {
    const sensorAggregate = sensorAggregatesByDate.get(date)
    const energyPrice = energyPricesByDate.get(date)

    if (sensorAggregate && energyPrice) {
      const kwh = sensorAggregate.avg_kw ?? 0
      const eurosPerKwh = energyPrice.cents_per_kwh / 100
      // @ts-ignore
      energyCost = energyCost + kwh * eurosPerKwh
    }
  })

  return energyCost
}

/**
 * Calculate the energy costs for the fixed energy price.
 */
export function calculateEnergyCostsForFixedEnergyPrice(
  sensorAggregates: SensorAggregate[],
  energyPrice: number
): number {
  const sensorAggregatesByDate: Map<string, SensorAggregate> =
    transformSensorAggregatesToMap(sensorAggregates)

  const datesAsIsoStrings = Array.from(sensorAggregatesByDate.keys())

  let energyCost = 0

  datesAsIsoStrings.forEach((date) => {
    // @ts-ignore
    const kwh = sensorAggregatesByDate.get(date)?.avg_kw ?? 0

    // @ts-ignore
    const eurosPerKwh = energyPrice / 100

    energyCost = energyCost + kwh * eurosPerKwh
  })

  return energyCost
}

export function mapEnergyCostsByDate(
  energyCosts: EnergyCost[]
): Map<string, EnergyCost> {
  const energyCostsByDate = new Map<string, EnergyCost>()

  for (const energyCost of energyCosts) {
    energyCostsByDate.set(energyCost.time, energyCost)
  }

  return energyCostsByDate
}
