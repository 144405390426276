import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { ConfluencePages, UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import Home from '../pages/manufacturing/Home'
import OrderIndex from '../pages/manufacturing/OrderIndex'
import OrderShipped from '../pages/manufacturing/OrderShowShipped'
import OrderCreate from '../pages/manufacturing/OrderCreate'
import OrderShow from '../pages/manufacturing/OrderShow'
import OrderInstall from '../pages/manufacturing/OrderInstall'
import OrderEdit from '../pages/manufacturing/OrderEdit'
import ProductCreate from '../pages/manufacturing/ProductCreate'
import ProductIndex from '../pages/manufacturing/ProductIndex'
import ManufacturerInvite from '../pages/manufacturing/ManufacturerInvite'
import UploadInstructionsIndex from '../pages/manufacturing/UploadIndex'
import CatalogManagement from '../pages/manufacturing/CatalogManagement'
import ProductStorage from '../pages/manufacturing/ProductStorage'

export default [
  {
    path: '/manufacturing',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN, UserRole.MANUFACTURER]}>
        <UserManualProvider pageId={ConfluencePages.MANUFACTURING}>
          <DashboardLayout />
        </UserManualProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'orders',
        element: <OrderIndex />
      },
      {
        path: 'orders/shipped',
        element: <OrderShipped />
      },
      {
        path: 'orders/create',
        element: <OrderCreate />
      },
      {
        path: 'orders/:id',
        element: <OrderShow />
      },
      {
        path: 'orders/:id/install',
        element: <OrderInstall />
      },
      {
        path: 'orders/:id/edit',
        element: <OrderEdit />
      },

      // TODO: OrderShowProductIndex
      {
        path: 'products',
        element: <ProductIndex />
      },
      // TODO: orders/:id/products/create
      {
        path: 'products/:id/create',
        element: <ProductCreate />
      },
      {
        path: 'upload',
        element: <UploadInstructionsIndex />
      },
      {
        path: 'catalog',
        element: <CatalogManagement />
      },
      {
        path: 'storage',
        element: <ProductStorage />
      }
    ]
  },
  {
    path: '/manufacturing',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.MANUFACTURING}>
          <DashboardLayout />
        </UserManualProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: 'invite',
        element: <ManufacturerInvite />
      }
    ]
  }
]
