enum Hours {
  ONE_DAY = 24,
  THREE_DAYS = 72,
  FIVE_DAYS = 120,
  SEVEN_DAYS = 168,
  THIRTY_DAYS = 720,
  THIRTY_ONE_DAYS = 744
}

export default Hours
