import React, { useEffect, useState } from 'react'
import { getSensorNow } from '../services/deviceService'
import DeviceConsumptionStats from './DeviceConsumptionStats'
import Sensor from '../types/Sensor'
import { Device, PowerSupply } from '../types'
import LoadingState from './LoadingState'

export interface PowerSupplyConsumptionStatsProps {
  isLoadingPowerSupplies: boolean
  powerSupplies: PowerSupply[]
}

export default function PowerSupplyConsumptionStats({
  isLoadingPowerSupplies,
  powerSupplies
}: PowerSupplyConsumptionStatsProps) {
  const [sensor, setSensor] = useState<Sensor[]>([])

  const [isLoadingSensor, setIsLoadingSensor] = useState<boolean>(true)

  const [selectedPowerSupply, setSelectedPowerSupply] = useState<
    PowerSupply | undefined
  >(undefined)

  async function loadSensor(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoadingSensor(true)

      {
        if (selectedPowerSupply) {
          const response = await getSensorNow(
            selectedPowerSupply?.device?.name as string,
            {
              signal
            }
          )

          const sensorList = [response]
          setSensor(sensorList)
        } else {
          powerSupplies.map(async (powerSupply) => {
            const response = await getSensorNow(
              powerSupply?.device?.name as string,
              {
                signal
              }
            )

            if (
              sensor.some((s) => s.device_code === response.device_code) ===
              false
            ) {
              setSensor([...sensor, response])
            }
          })
        }
      }
    } finally {
      setIsLoadingSensor(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => await loadSensor(controller.signal))()
    return () => controller.abort()
  }, [powerSupplies, selectedPowerSupply])

  return (
    <>
      <DeviceConsumptionStats
        loading={isLoadingSensor || isLoadingPowerSupplies}
        powerSupplies={powerSupplies}
        sensors={sensor as Sensor[]}
        selectPowerSupply={(selectedPowerSupply: PowerSupply) => {
          /**If undefined use all powersupplies
           * Clear sensors and load new measurements if powersupply changes
           */
          setSelectedPowerSupply(selectedPowerSupply)
        }}
      />
    </>
  )
}
