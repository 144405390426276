import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  CardContent as MuiCardContent,
  Card as MuiCard,
  CircularProgress
} from '@mui/material'
import { Box, spacing } from '@mui/system'
import styled from 'styled-components/macro'
import useAuth from '../hooks/useAuth'
import Project from '../types/Project'
import { format } from 'date-fns'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import {
  createProjectPhase,
  updateProjectPhase
} from '../services/projectPhaseService'
import {
  createPhaseState,
  hidePhase,
  updatePhaseState
} from '../redux/slices/phases'
import { RootState } from '../redux/store'
import { createUserNotification } from '../utils/createUserNotification'
import { ProjectPhaseErrors } from '../enums/ProjectPhaseErrors'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Card = styled(MuiCard)(spacing)

interface ProjectPhaseFormProps {
  project: Project
}

export default function ProjectPhaseForm({ project }: ProjectPhaseFormProps) {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { phase } = useSelector((state: RootState) => state.phases)
  const { currentUser } = useAuth()

  const initialValues = phase
    ? {
        id: phase.id,
        name: phase.name,
        projectId: project.id,
        startDate: format(new Date(phase.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(phase.endDate), 'yyyy-MM-dd')
      }
    : {
        id: '',
        name: '',
        projectId: project.id,
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd')
      }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required')
  })

  async function handleSubmit(
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ): Promise<void> {
    try {
      if (phase === undefined) {
        const createdProjectPhase = await createProjectPhase(values)
        dispatch(createPhaseState(createdProjectPhase))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectPhaseCreateSuccess')
          )
        )
      } else {
        const updatedProjectPhase = await updateProjectPhase(values.id, values)
        dispatch(updatePhaseState(updatedProjectPhase))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectPhaseUpdateSuccess')
          )
        )
      }
      resetForm()
      dispatch(hidePhase())
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: phase ? ProjectPhaseErrors.EDIT : ProjectPhaseErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      data-testid="name"
                      name="name"
                      label={t('Projects.name')}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl>
                    <TextField
                      name="startDate"
                      type="date"
                      label={t('startDate')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={values.startDate}
                      error={Boolean(touched.startDate && errors.startDate)}
                      fullWidth
                      helperText={touched.startDate && errors.startDate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl>
                    <TextField
                      name="endDate"
                      type="date"
                      label={t('endDate')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={values.endDate}
                      error={Boolean(touched.endDate && errors.endDate)}
                      fullWidth
                      helperText={touched.endDate && errors.endDate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  {t('Projects.save')}
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
