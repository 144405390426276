import { useTranslation } from 'react-i18next'
import { RuuviTagCondition } from '../types'
import RuuviTagStats from './RuuviTagStats'

interface RuuviTagHumidityCardProps {
  ruuviTagCondition: RuuviTagCondition | null
  loading?: boolean
}

export default function RuuviTagHumidityCard({
  ruuviTagCondition,
  loading = false
}: RuuviTagHumidityCardProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <RuuviTagStats
      title={t('humidity')}
      value={ruuviTagCondition?.humidityAvg}
      unit="%"
      date={ruuviTagCondition?.time}
      loading={loading}
    />
  )
}
