import {
  FormControl,
  FormControlProps,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
  ClipboardEvent
} from 'react'
import { useTranslation } from 'react-i18next'

enum KeyboardKey {
  BACKSPACE = 'Backspace',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ENTER = 'Enter'
}

interface DeviceCodeInputProps extends Omit<FormControlProps, 'onChange'> {
  /**
   * The handler for the change event.
   */
  onChange?: (value: string) => void
}

export default function DeviceCodeInput({ onChange }: DeviceCodeInputProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The digits.
   */
  const digits = Array.from(Array(6).keys())

  /**
   * The input refs.
   */
  const refs = useRef<HTMLInputElement[]>([])

  /**
   * The input value as an array of digits.
   */
  const [value, setValue] = useState<string[]>(digits.map(() => ''))

  /**
   * The consecutive backspace hit counter per input.
   *
   * This is needed so that we can change focus when the backspace is pressed
   * to an input with an empty value as this does not trigger a change event.
   * Moreover, since change event is fired before the keyup event, we would not
   * be able to determine in the keyup handler if the input was already empty or not.
   */
  const [backspaceHits, setBackspaceHits] = useState<number[]>(
    digits.map(() => 0)
  )

  /**
   * Handle the change event.
   */
  const handleChange = (index: number, character: string) => {
    const newValue = [...value]
    newValue[index] = character
    setValue(newValue)

    // If a digit was entered, then focus the next input.
    if (character !== '' && refs.current[index + 1]) {
      refs.current[index + 1].focus()
    }
  }

  /**
   * Handle the key up event.
   */
  const handleKeyUp = (
    index: number,
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newBackspaceHits = [...backspaceHits]

    if (event.key === KeyboardKey.BACKSPACE) {
      newBackspaceHits[index]++

      if (newBackspaceHits[index] > 1 && refs.current[index - 1]) {
        refs.current[index - 1].focus()
      }
    } else {
      if (index === backspaceHits.length - 1) {
        newBackspaceHits[index] = 0
      } else {
        newBackspaceHits[index - 1] = 0
      }
    }

    setBackspaceHits(newBackspaceHits)

    switch (event.key) {
      case KeyboardKey.ARROW_LEFT:
        if (refs.current[index - 1]) {
          refs.current[index - 1].focus()
        }
        break
      case KeyboardKey.ARROW_RIGHT:
      case KeyboardKey.ENTER:
        if (refs.current[index + 1]) {
          refs.current[index + 1].focus()
        }
        break
    }
  }

  /**
   * Handle the paste event.
   */
  const handlePaste = (
    index: number,
    event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const characters = event.clipboardData.getData('text').split('')

    // Keep only digits.
    const digits = characters.filter((character) => {
      return /^\d+$/.test(character)
    })

    const newValue = [...value]
    let indexOfPastedDigit = index

    digits.forEach((digit, i) => {
      indexOfPastedDigit = index + i
      const inputForIndexExists = indexOfPastedDigit < refs.current.length
      if (inputForIndexExists) {
        newValue[indexOfPastedDigit] = digit
      }
    })

    setValue(newValue)
  }

  /**
   * Build refs when the component mounts.
   */
  useEffect(() => {
    refs.current = refs.current.slice(0, digits.length)
  }, [])

  /**
   * Emit the change event when the value changes.
   */
  useEffect(() => {
    if (onChange) {
      onChange(value.join(''))
    }
  }, [value])

  return (
    <FormControl sx={{ mt: 6 }}>
      <Typography fontWeight={500} sx={{ mb: 2 }}>
        {t('enterSixDigitDeviceCode')}
      </Typography>
      <Stack direction="row" spacing={2}>
        {digits.map((digit) => (
          <TextField
            key={digit}
            value={value[digit]}
            inputMode="numeric"
            inputProps={{
              ref: (el: HTMLInputElement) => (refs.current[digit] = el),
              inputmode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 1,
              min: 0,
              max: 9,
              sx: {
                maxWidth: '24px',
                fontSize: '24px !important',
                textAlign: 'center !important'
              },
              onKeyUp: (event) => {
                handleKeyUp(digit, event)
              },
              onPaste: (event) => {
                handlePaste(digit, event)
              }
            }}
            fullWidth
            onChange={(event) => {
              handleChange(digit, event.target.value)
            }}
          />
        ))}
      </Stack>
    </FormControl>
  )
}
