import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { MoreVertical } from 'react-feather'
import {
  Alert,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  IconButton,
  Paper,
  Box,
  CircularProgress
} from '@mui/material'
import { spacing } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../redux/store'
import { getProject } from '../../redux/slices/projects'
import { getPowerSupply } from '../../redux/slices/powerSupplies'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { getDevice } from '../../redux/slices/devices'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const TableWrapper = styled.div`
  overflow-x: auto;
`

/**
 * @deprecated
 */
function PowerSupply() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const { id } = useParams()
  const { powerSupply, loading } = useSelector(
    (state: RootState) => state.powerSupplies
  )

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getPowerSupply(parseInt(id)))
    }
  }, [currentUser, id])

  const handleSetProject = (id: number) => {
    dispatch(getProject(id))
  }

  const translateType = (type: any) => {
    switch (type) {
      case 'Kerrostalo':
        return t('Project.block')
      case 'Omakotitalo':
        return t('Project.detached')
      case 'Rivitalo':
        return t('Project.terraced')
      case 'Liiketila':
        return t('Project.business')
    }
  }

  const translateNew = (n: any) => {
    switch (n) {
      case 'Uudiskohde':
        return t('Project.new')
      case 'Saneerauskohde':
        return t('Project.renovation')
    }
  }

  const translateWarming = (n: any) => {
    switch (n) {
      case 'Puu':
        return t('Project.wood')
      case 'Öljy':
        return t('Project.oil')
      case 'Kaasu':
        return t('Project.gas')
      case 'Kaukolämpö':
        return t('Project.district')
    }
  }

  return (
    <React.Fragment>
      <Helmet title="PowerSupply" />

      <Typography variant="h3" gutterBottom display="inline">
        {powerSupply?.name}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('PowerSupply.frontpage')}
        </Link>
        <Link component={NavLink} to="/powersupplies">
          {t('PowerSupply.powerSupplies')}
        </Link>
        <Typography>{powerSupply?.name}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '60vh'
          }}
        >
          <CircularProgress size="5vh" />
        </Box>
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card mb={6}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={t('PowerSupply.powerSupplyInfo')}
              />

              <Paper>
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('PowerSupply.name')}</TableCell>
                        <TableCell>{t('PowerSupply.description')}</TableCell>
                        <TableCell>{t('PowerSupply.fuse')} (A)</TableCell>
                        <TableCell>{t('PowerSupply.inputCables')}</TableCell>
                        <TableCell>{t('PowerSupply.voltage_1_tp')}</TableCell>
                        <TableCell>{t('PowerSupply.voltage_1_ts')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {powerSupply && (
                        <TableRow key={powerSupply.id}>
                          <TableCell component="th" scope="row">
                            {powerSupply.name}
                          </TableCell>
                          <TableCell>{powerSupply.description}</TableCell>
                          <TableCell>{powerSupply.fuse}</TableCell>
                          <TableCell>{powerSupply.inputCables}</TableCell>
                          <TableCell>{powerSupply.voltage_1_tp}</TableCell>
                          <TableCell>{powerSupply.voltage_1_ts}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableWrapper>
              </Paper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card mb={6}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={t('PowerSupply.projectInfo')}
              />
              <Paper>
                {powerSupply && powerSupply?.device?.asset?.project ? (
                  <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell> {t('PowerSupply.name')}</TableCell>
                          <TableCell align="right">
                            {' '}
                            {t('PowerSupply.address')}
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {t('PowerSupply.city')}
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {t('PowerSupply.zip')}
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {t('PowerSupply.area')}
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {t('PowerSupply.type')}
                          </TableCell>
                          <TableCell align="right">
                            {
                              /* prettier-ignore */
                              `${t('PowerSupply.new')} / ${t('PowerSupply.renovation')}`
                            }
                          </TableCell>
                          <TableCell align="right">
                            {' '}
                            {t('PowerSupply.warming')}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={powerSupply.id}>
                          <TableCell component="th" scope="row">
                            {powerSupply.device.asset.project !== null && (
                              <Link
                                onClick={() => {
                                  if (powerSupply?.device?.asset?.projectId) {
                                    handleSetProject(
                                      powerSupply.device.asset.projectId
                                    )
                                  }
                                }}
                                component={NavLink}
                                to={`/projects/${powerSupply.device.asset.projectId}`}
                              >
                                {powerSupply.device.asset.project.name}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {powerSupply.device.asset.project.address}
                          </TableCell>
                          <TableCell align="right">
                            {powerSupply.device.asset.project.city}
                          </TableCell>
                          <TableCell align="right">
                            {powerSupply.device.asset.project.zip}
                          </TableCell>
                          <TableCell align="right">
                            {powerSupply.device.asset.project.area}
                          </TableCell>
                          <TableCell align="right">
                            {translateType(
                              powerSupply.device.asset.project.type
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {translateNew(powerSupply.device.asset.project.new)}
                          </TableCell>
                          <TableCell align="right">
                            {translateWarming(
                              powerSupply.device.asset.project.warmingMethod
                            )}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableWrapper>
                ) : (
                  <Grid style={{ padding: '1rem' }}>
                    <Alert severity="warning">
                      {t('PowerSupply.noProject')}
                    </Alert>
                  </Grid>
                )}
              </Paper>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card mb={6}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={t('PowerSupply.level')}
              />

              <Paper>
                {powerSupply && powerSupply?.device?.asset?.level && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> {t('PowerSupply.name')}</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {powerSupply.device.asset.level.name}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </Paper>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card mb={6}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={t('PowerSupply.lift')}
              />

              <Paper>
                {powerSupply && powerSupply?.device?.asset?.lift && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> {t('PowerSupply.name')}</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {powerSupply.device.asset.lift.name}
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </Paper>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card mb={6}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={t('PowerSupply.sector')}
              />

              <Paper>
                {powerSupply &&
                  powerSupply?.device?.asset?.lift &&
                  powerSupply?.device?.asset?.lift?.sector && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell> {t('PowerSupply.name')}</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {powerSupply.device.asset.lift.sector.name}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
              </Paper>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card mb={6}>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title="Laitteet"
              />
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> {t('PowerSupply.name')}</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {powerSupply?.device && (
                      <TableRow key={powerSupply.id}>
                        <TableCell component="th" scope="row">
                          <Link
                            // onClick={() => handleSetDevice(row.id)}
                            component={NavLink}
                            onClick={() =>
                              dispatch(
                                getDevice(powerSupply?.device?.name as string)
                              )
                            }
                            to={`/devices/${powerSupply.device.name}`}
                          >
                            {powerSupply.device.name}
                          </Link>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Card>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default PowerSupply
