export function calculateProgressByDate(startDate: any, endDate: any): number {
  const start = new Date(startDate)
  const end = new Date(endDate)

  const today = new Date()
  const percentage = Math.round(
    ((today.valueOf() - start.valueOf()) / (end.valueOf() - start.valueOf())) *
      100
  )
  if (percentage > 100) {
    return 100
  } else if (percentage < 0) {
    return 0
  } else return percentage
}
