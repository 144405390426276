import React from 'react'
import CreateProductForm from '../../components/manufacturing/CreateProductForm'
import ManufacturingHeader from '../../components/manufacturing/ManufacturingPageHeader'

function ProductCreate() {
  return (
    <React.Fragment>
      <ManufacturingHeader />
      <CreateProductForm />
    </React.Fragment>
  )
}

export default ProductCreate
