import React, { useEffect } from 'react'
import styled, { css, withTheme, ThemeProps } from 'styled-components/macro'
import { MoreVertical } from 'react-feather'
import { rgba } from 'polished'
import { alpha } from '@mui/material/styles'

import {
  Box,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Chip as MuiChip,
  Grid,
  IconButton,
  Typography as MuiTypography,
  Theme,
  Tabs,
  Tab,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Link
} from '@mui/material'
import { spacing } from '@mui/system'
import { NavLink } from 'react-router-dom'

import { green, grey, blue, blueGrey, red } from '@mui/material/colors'
import Chart from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './../../redux/store'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import Stats from '../dashboards/Default/Stats'
import { Helmet } from 'react-helmet-async'

const Card = styled(MuiCard)(spacing)

const ChartWrapper = styled.div`
  height: 100px;
  position: relative;
`
const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

const LineChartWrapper = styled.div`
  height: 336px;
`
const Percentage = styled(MuiTypography)<{
  percentagecolor: string
  illustration?: string
}>`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `}
`

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginBottom: '24px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

/**
 * @deprecated
 */
function Condition({ theme }: ThemeProps<Theme>) {
  const [t] = useTranslation('common')
  const [tabValue, setTabValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // if (newValue === 1) {
    //   return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    // } else if (newValue === 2) {
    //   return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    // }
    setTabValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const lineData_0 = (canvas: any) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 300)
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.875)) // 0.0875
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')

    return {
      labels: [
        '00:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],

      datasets: [
        {
          label: 'Temperature °C',
          fill: true,
          backgroundColor: gradient,
          borderColor: blueGrey[500],
          tension: 0.4,
          data: [
            10, 15, 20, 25, 30, 35, 20, 15, 20, 25, 30, 35, 20, 15, 20, 25, 30,
            35, 50, 15, 20, 25, 30, 35
          ]
          //data: device?.sensors?.day.map((s: any) => s.current_1)
        }
      ]
    }
  }

  const lineData_1 = (canvas: any) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 300)
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.875)) // 0.0875
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')

    return {
      labels: [
        '00:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      datasets: [
        {
          label: 'Temperature °C',
          fill: true,
          backgroundColor: gradient,
          borderColor: blueGrey[500],
          tension: 0.4,
          //data: device?.sensors?.month.map((s: any) => s.current_1)
          data: [10, 20, 30, 40, 50, 60]
        }
      ]
    }
  }

  const lineData_2 = (canvas: any) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 300)
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.875)) // 0.0875
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)')

    return {
      labels: [
        '00:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      datasets: [
        {
          label: 'Temperature °C',
          fill: true,
          backgroundColor: gradient,
          borderColor: blueGrey[500],
          tension: 0.4,
          //data: device?.sensors?.month.map((s: any) => s.current_1)
          data: [10, 20, 30, 40, 50, 60]
        }
      ]
    }
  }

  const lineOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)'
        }
      },
      y: {
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff'
        }
      }
    }
  }

  return (
    <Grid>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        {'Condition'}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {'Frontpage'}
        </Link>
        <Link component={NavLink} to="/conditions">
          Conditions
        </Link>
        <Typography>Device name</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Temperature'}
            amount={'10' + ' °C'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Pressure'}
            amount={'10 hPa'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Humidity'}
            amount={'10' + '%'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Movement counter'}
            amount={'144 movements'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Battery voltage'}
            amount={'2,791 V'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Acceleration X'}
            amount={'0,828g'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Acceleration Y'}
            amount={'0,640g'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Acceleration Z'}
            amount={'0,068g'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Signal strenght (RSSI)'}
            amount={'-86' + ' dbm'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title={'Measurement sequence'}
            amount={'38 074'}
            chip={t('FrontPage.now')}
            percentagetext={'10 %'}
            percentageAltText={'From the last measurement'}
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Tabs value={tabValue} onChange={handleChange} aria-label="virta">
            <Tab label={t('Device.day')} {...a11yProps(0)} />
            <Tab label={t('Device.week')} {...a11yProps(1)} />
            <Tab label={t('Device.month')} {...a11yProps(2)} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <Card>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={'Last measurement: 23.5.2023 11.59.00'}
              />
              <CardContent>
                <LineChartWrapper>
                  <Chart type="line" data={lineData_0} options={lineOptions} />
                </LineChartWrapper>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Card>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={'Last measurement: 23.5.2023 11.59.00'}
              />
              <CardContent>
                <LineChartWrapper>
                  <Chart type="line" data={lineData_1} options={lineOptions} />
                </LineChartWrapper>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Card>
              <CardHeader
                action={
                  <IconButton aria-label="settings" size="large">
                    <MoreVertical />
                  </IconButton>
                }
                title={'Last measurement: 23.5.2023 11.59.00'}
              />
              <CardContent>
                <LineChartWrapper>
                  <Chart type="line" data={lineData_2} options={lineOptions} />
                </LineChartWrapper>
              </CardContent>
            </Card>
          </TabPanel>
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * @deprecated
 */
export default withTheme(Condition)
