import * as Yup from 'yup'
import styled from 'styled-components/macro'
import ProjectSelect from './ProjectSelect'
import PowerSupplyFuseSelect from './PowerSupplyFuseSelect'
import PowerSupplyInputCablesSelect from './PowerSupplyInputCablesSelect'
import {
  Button as MuiButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField as MuiTextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow,
  InputAdornment
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { spacing } from '@mui/system'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAuth, useDevice, usePowerSupplies } from '../hooks'
import {
  getPowerSuppliesByProjectId,
  updatePowerSupply
} from '../redux/slices/powerSupplies'
import { useNavigate } from 'react-router-dom'
import LevelSelect from './LevelSelect'
import LiftSelect from './LiftSelect'
import PowerSupplySelect from './PowerSupplySelect'
import { ChangeEvent, useEffect, useState } from 'react'
import { getLevelsByProjectId } from '../redux/slices/levels'
import { getLiftsByProjectId } from '../redux/slices/lifts'
import { RootState } from '../redux/store'
import { getProjects, getProjectsByCompanyId } from '../redux/slices/projects'
import { UserRole } from '../enums/UserRole'
import powerSupplyService from '../services/powerSupplyService'
import { deleteError, setError } from '../redux/slices/errors'
import { PowerSupplyErrors } from '../enums/PowerSupplyError'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import NominalCurrent from '../enums/NominalCurrent'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import React from 'react'
import { NominalCurrentSelect100And250 } from './NominalCurrentSelect'
import MenuIcon from '@mui/icons-material/Menu'
import TextFieldsIcon from '@mui/icons-material/TextFields'

const TextField = styled(MuiTextField)(spacing)
const Button = styled(MuiButton)(spacing)

export default function DeviceForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [t] = useTranslation('common')
  const device = useDevice()

  const isLoadingProjects = useSelector(
    (state: RootState) => state.projects.loading
  )

  const isLoadingPowerSupplies = useSelector(
    (state: RootState) => state.powerSupplies.loading
  )

  const isLoadingLevels = useSelector(
    (state: RootState) => state.levels.loading
  )

  const isLoadingLifts = useSelector((state: RootState) => state.lifts.loading)

  const parentPowerSupplies = usePowerSupplies().filter(
    (powerSupply) => powerSupply.id !== device?.powerSupply?.id
  )

  useEffect(() => {
    if (currentUser?.role === UserRole.SUPERADMIN) {
      dispatch(getProjects())
    } else if (currentUser?.role === UserRole.ADMIN) {
      dispatch(getProjectsByCompanyId(currentUser.company.id))
    }

    if (device?.asset?.project) {
      dispatch(getPowerSuppliesByProjectId(device.asset.project.id))
      dispatch(getLevelsByProjectId(device.asset.project.id))
      dispatch(getLiftsByProjectId(device.asset.project.id))
    }
  }, [currentUser])

  const initialValues = {
    name: device?.powerSupply?.name ?? '',
    fuse: device?.powerSupply?.fuse ?? '',
    inputCables: device?.powerSupply?.inputCables ?? 0,
    voltage_1_tp: device?.powerSupply?.voltage_1_tp ?? 0,
    voltage_1_ts: device?.powerSupply?.voltage_1_ts ?? 0,
    isMainDistributionAssembly:
      device?.powerSupply?.isMainDistributionAssembly ?? false,
    project: device?.asset?.project?.id ?? null,
    parent: device?.powerSupply?.parent?.id ?? null,
    level: device?.asset?.level?.id ?? null,
    lift: device?.asset?.lift?.id ?? null,
    transformCurrent: device?.powerSupply?.transformCurrent ?? false,
    customerReference: device?.powerSupply?.customerReference ?? null,
    current_1_offset: device?.powerSupply?.current_1_offset ?? 0,
    current_2_offset: device?.powerSupply?.current_2_offset ?? 0,
    current_3_offset: device?.powerSupply?.current_3_offset ?? 0,
    voltage_1_offset: device?.powerSupply?.voltage_1_offset ?? 0,
    voltage_2_offset: device?.powerSupply?.voltage_2_offset ?? 0,
    voltage_3_offset: device?.powerSupply?.voltage_3_offset ?? 0
  }

  const [nominalCurrent, setNominalCurrent] = useState(
    device?.powerSupply?.voltage_1_tp === 1000
      ? (NominalCurrent.NOMINAL100 as string)
      : device?.powerSupply?.voltage_1_tp === 2500
      ? (NominalCurrent.NOMINAL250 as string)
      : (NominalCurrent.OTHER as string)
  )

  const [openCurrentTransformerInfo, setOpenCurrentTransformerInfo] =
    useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().nullable(),
    fuse: Yup.number().positive('Select fuse size').required('Required'),
    inputCables: Yup.number().required('Required'),
    voltage_1_tp: Yup.number().required('Required'),
    voltage_1_ts: Yup.number().required('Required'),
    isMainDistributionAssembly: Yup.boolean(),
    parent: Yup.number().nullable(),
    level: Yup.number().nullable(),
    lift: Yup.number().nullable(),
    transformCurrent: Yup.boolean(),
    current_1_offset: Yup.number().typeError(t('offsetError')),
    current_2_offset: Yup.number().typeError(t('offsetError')),
    current_3_offset: Yup.number().typeError(t('offsetError')),
    voltage_1_offset: Yup.number().typeError(t('offsetError')),
    voltage_2_offset: Yup.number().typeError(t('offsetError')),
    voltage_3_offset: Yup.number().typeError(t('offsetError'))
  })

  async function handleSubmit(
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ): Promise<void> {
    try {
      setSubmitting(true)
      const updatedPowerSupply = await powerSupplyService.updatePowerSupply(
        device?.powerSupply?.id as number,
        values
      )
      dispatch(updatePowerSupply(updatedPowerSupply))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('powerSupplyUpdateSuccess')
        )
      )
      setSubmitting(false)
      resetForm()
      setStatus({ sent: true })
      navigate(`/devices/${device?.name}`)
    } catch (error: unknown) {
      dispatch(
        setError({
          type: PowerSupplyErrors.EDIT,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: PowerSupplyErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage?.key) + t(errorMessage?.message)
        )
      )
      dispatch(deleteError(PowerSupplyErrors.EDIT))
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      setSubmitting(false)
    }
  }

  const [showFuseSelect, setShowFuseSelect] = useState(false)

  const handleClickShowFuseSelect = () => {
    setShowFuseSelect((showFuseSelect) => !showFuseSelect)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
        setValues
      }) => (
        <React.Fragment>
          <Dialog
            open={openCurrentTransformerInfo}
            onClose={() => setOpenCurrentTransformerInfo(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t('currentTransformerInfoTitle')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Table>
                  <TableRow>
                    {t('currentTransformer100A') +
                      ': ' +
                      'SCT016 100 A : 0,1 A (100 mA), 1%'}
                  </TableRow>
                  <TableRow>
                    {t('currentTransformer250A') +
                      ': ' +
                      'SCT024 250 A : 0,1 A (100 mA), 1%'}
                  </TableRow>
                  <TableRow>
                    {t('other') + ': ' + t('currentTransformerOtherInfo')}
                  </TableRow>
                </Table>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setOpenCurrentTransformerInfo(false)}
                autoFocus
              >
                {t('understood')}
              </Button>
            </DialogActions>
          </Dialog>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={t('Device.name')}
                  value={device?.name}
                  disabled
                  fullWidth
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  label={t('Device.shortUUID')}
                  value={device?.shortUUID}
                  disabled
                  fullWidth
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              {/* 
              TODO: This can be removed later if we don't need them anymore
              <Grid item xs={12} lg={6}>
                <ProjectSelect
                  name="project"
                  label={t('PowerSupply.project')}
                  value={values.project}
                  disabled={isLoadingProjects}
                  error={Boolean(touched.project && errors.project)}
                  helperText={touched.project && errors.project}
                  onBlur={handleBlur}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const projectId: number = parseInt(event.target.value)

                    dispatch(getPowerSuppliesByProjectId(projectId))
                    dispatch(getLevelsByProjectId(projectId))
                    dispatch(getLiftsByProjectId(projectId))

                    setValues({
                      ...values,
                      project: projectId,
                      parent: null,
                      level: null,
                      lift: null
                    })
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid> */}
              <Grid item xs={12} lg={6}>
                <TextField
                  name="customerReference"
                  label={t('customerReference')}
                  value={values.customerReference}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name="name"
                  label={t('PowerSupply.name')}
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <PowerSupplyFuseSelect
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              sx={{ p: '10px' }}
                              aria-label="menu"
                              onClick={handleClickShowFuseSelect}
                            >
                              {showFuseSelect ? (
                                <TextFieldsIcon />
                              ) : (
                                <MenuIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment:
                          showFuseSelect === false ? (
                            <InputAdornment
                              position="end"
                              style={{ color: 'white' }}
                            >
                              A
                            </InputAdornment>
                          ) : (
                            ''
                          )
                      }}
                      select={showFuseSelect}
                      name="fuse"
                      label={t('PowerSupply.fuse')}
                      value={values.fuse}
                      error={Boolean(touched.fuse && errors.fuse)}
                      helperText={touched.fuse && errors.fuse}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <PowerSupplyInputCablesSelect
                      name="inputCables"
                      label={t('PowerSupply.inputCables')}
                      value={values.inputCables}
                      error={Boolean(touched.inputCables && errors.inputCables)}
                      helperText={touched.inputCables && errors.inputCables}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  {values.project !== null && (
                    <Grid item xs={12} md={4}>
                      {/**
                       * We should not be able to select higher-order power supply
                       * if the power supply is main distribution assembly.
                       */}
                      <PowerSupplySelect
                        name="parent"
                        label={t('PowerSupply.parent')}
                        value={values.parent}
                        powerSupplies={parentPowerSupplies}
                        disabled={
                          values.isMainDistributionAssembly ||
                          isLoadingPowerSupplies
                        }
                        error={Boolean(touched.parent && errors.parent)}
                        helperText={touched.parent && errors.parent}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  {currentUser?.role === UserRole.SUPERADMIN && (
                    <FormControlLabel
                      /* @ts-ignore */
                      label={t('PowerSupply.transformCurrent')}
                      control={
                        <Checkbox
                          name="transformCurrent"
                          checked={values.transformCurrent}
                          onChange={(event: ChangeEvent) => {
                            setValues({
                              ...values,
                              transformCurrent:
                                // @ts-ignore
                                event.target.checked,
                              parent: null
                            })
                          }}
                        />
                      }
                    />
                  )}

                  <FormControlLabel
                    /* @ts-ignore */
                    label={t('PowerSupply.isMainDistributionAssembly')}
                    control={
                      <Checkbox
                        name="isMainDistributionAssembly"
                        checked={values.isMainDistributionAssembly}
                        onChange={(event: ChangeEvent) => {
                          // If the power supply is marked as main distribution assembly,
                          // then we need to clear the higher-order parent.
                          setValues({
                            ...values,
                            isMainDistributionAssembly:
                              // @ts-ignore
                              event.target.checked,
                            parent: null
                          })
                        }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <IconButton
                      size="small"
                      onClick={() => setOpenCurrentTransformerInfo(true)}
                      sx={{ mt: 2 }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    md={nominalCurrent === NominalCurrent.OTHER ? 3 : 11}
                  >
                    <NominalCurrentSelect100And250
                      name="nominalCurrent"
                      value={nominalCurrent}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={(e) => setNominalCurrent(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      name="voltage_1_tp"
                      sx={{
                        display:
                          nominalCurrent === NominalCurrent.OTHER
                            ? 'default'
                            : 'none'
                      }}
                      label={t('PowerSupply.voltage_1_tp')}
                      value={
                        nominalCurrent === NominalCurrent.NOMINAL100
                          ? (values.voltage_1_tp = 1000)
                          : nominalCurrent === NominalCurrent.NOMINAL250
                          ? (values.voltage_1_tp = 2500)
                          : values.voltage_1_tp
                      }
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      name="voltage_1_ts"
                      sx={{
                        display:
                          nominalCurrent === NominalCurrent.OTHER
                            ? 'default'
                            : 'none'
                      }}
                      label={t('PowerSupply.voltage_1_ts')}
                      value={
                        nominalCurrent === NominalCurrent.NOMINAL100
                          ? (values.voltage_1_ts = 1)
                          : nominalCurrent === NominalCurrent.NOMINAL250
                          ? (values.voltage_1_ts = 1)
                          : values.voltage_1_ts
                      }
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {currentUser?.role === UserRole.SUPERADMIN && (
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        name="current_1_offset"
                        label={t('current1Calibration')}
                        value={values.current_1_offset}
                        error={Boolean(
                          touched.current_1_offset && errors.current_1_offset
                        )}
                        helperText={
                          touched.current_1_offset && errors.current_1_offset
                        }
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        name="current_2_offset"
                        label={t('current2Calibration')}
                        value={values.current_2_offset}
                        error={Boolean(
                          touched.current_2_offset && errors.current_2_offset
                        )}
                        helperText={
                          touched.current_2_offset && errors.current_2_offset
                        }
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        name="current_3_offset"
                        label={t('current3Calibration')}
                        value={values.current_3_offset}
                        error={Boolean(
                          touched.current_3_offset && errors.current_3_offset
                        )}
                        helperText={
                          touched.current_3_offset && errors.current_3_offset
                        }
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        name="voltage_1_offset"
                        label={t('voltage1Calibration')}
                        value={values.voltage_1_offset}
                        error={Boolean(
                          touched.voltage_1_offset && errors.voltage_1_offset
                        )}
                        helperText={
                          touched.voltage_1_offset && errors.voltage_1_offset
                        }
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        name="voltage_2_offset"
                        label={t('voltage2Calibration')}
                        value={values.voltage_2_offset}
                        error={Boolean(
                          touched.voltage_2_offset && errors.voltage_2_offset
                        )}
                        helperText={
                          touched.voltage_2_offset && errors.voltage_2_offset
                        }
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6} lg={4}>
                      <TextField
                        name="voltage_3_offset"
                        label={t('voltage3Calibration')}
                        value={values.voltage_3_offset}
                        error={Boolean(
                          touched.voltage_3_offset && errors.voltage_3_offset
                        )}
                        helperText={
                          touched.voltage_3_offset && errors.voltage_3_offset
                        }
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <Stack direction="row" spacing={4}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                  >
                    {isSubmitting ? t('saving') : t('save')}
                  </LoadingButton>

                  <Button
                    type="button"
                    variant="text"
                    color="secondary"
                    onClick={() => navigate(`/devices/${device?.name}`)}
                  >
                    {t('cancel')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </React.Fragment>
      )}
    </Formik>
  )
}
