import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { blue } from '@mui/material/colors'
import { ChartData, ChartDataset, ChartOptions } from 'chart.js'
import {
  EnergyConsumptionSettings,
  EnergyConsumptionQuery,
  SensorAggregate,
  EnergyConsumption
} from '../types'
import { DateInterval, CalculationMode } from '../enums'
import {
  getDatesByRange,
  getDifferenceOfDateRangeInHours,
  humanizeTimestamp
} from '../utils/date'
import { transformSensorAggregatesToMap } from '../utils/sensorAggregate'
import LineChart from './LineChart'
import BarChart from './BarChart'
import { mapEnergyConsumptionsByTime } from '../utils/energyConsumption'
interface EnergyConsumptionChartProps {
  energyConsumptions?: EnergyConsumption[]
  query: EnergyConsumptionQuery
  settings: EnergyConsumptionSettings
  initialValue?: number

  // @deprecated
  sensorAggregates?: SensorAggregate[]
}

export default function EnergyConsumptionChart({
  energyConsumptions,
  query,
  settings,
  initialValue = 0,
  sensorAggregates = []
}: EnergyConsumptionChartProps): ReactElement {
  const [t] = useTranslation('common')

  const dates = getDatesByRange(query.from, query.to, DateInterval.ONE_HOUR)

  const datesAsIsoStrings = dates.map((date) => date.toISOString())

  const isUsingSensorAggregates = energyConsumptions === undefined

  const chartData = makeChartData()

  const chartOptions = makeChartOptions()

  function makeChartData(): ChartData {
    return {
      labels: makeChartLabels(),
      datasets: makeChartDatasets()
    }
  }

  function makeChartOptions(): ChartOptions {
    return {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        tooltip: {
          callbacks: {
            // @ts-ignore
            title: (tooltipItems: TooltipItem[]): string => {
              if (tooltipItems.length) {
                return humanizeTimestamp(
                  // @ts-ignore
                  chartData?.labels[tooltipItems[0].dataIndex]
                )
              }
              return ''
            }
          }
        }
      },
      scales: {
        x: {
          type: 'timeseries',
          // @ts-ignore
          // min: query.from,
          // @ts-ignore
          // max: query.to,
          time: {
            unit:
              getDifferenceOfDateRangeInHours(query.from, query.to) > 24
                ? 'day'
                : 'hour',
            displayFormats: {
              hour: 'HH:00',
              day: 'd.M'
            }
          },
          grid: {
            display: false
          }
        },
        y: {
          min: 0
          // TODO: Use smarter max scaling.
          // suggestedMax: settings.calculationMode === CalculationMode.CUMULATIVE
          //   ? 2400
          //   : 100
        }
      }
    }
  }

  function makeChartLabels(): string[] {
    return datesAsIsoStrings
  }

  function makeChartDatasets(): ChartDataset[] {
    return [
      {
        label: t('kWh'),
        backgroundColor: blue['500'],
        borderColor: blue['500'],
        data:
          settings.calculationMode === CalculationMode.CUMULATIVE
            ? useCumulativeData()
            : useIncrementalData(),
        fill: settings.calculationMode === CalculationMode.CUMULATIVE
      }
    ]
  }

  function useCumulativeData(): number[] {
    return isUsingSensorAggregates
      ? makeCumulativeDataUsingSensorAggregates()
      : makeCumulativeDataUsingEnergyConsumptions()
  }

  function useIncrementalData(): number[] {
    return isUsingSensorAggregates
      ? makeIncrementalDataUsingSensorAggregates()
      : makeIncrementalDataUsingEnergyConsumptions()
  }

  function makeCumulativeDataUsingSensorAggregates(): number[] {
    const sensorAggregatesByDate =
      transformSensorAggregatesToMap(sensorAggregates)

    let value = initialValue
    return datesAsIsoStrings.map((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)
      value += sensorAggregate ? sensorAggregate.avg_kw : 0
      return value
    })
  }

  function makeCumulativeDataUsingEnergyConsumptions(): number[] {
    if (!energyConsumptions) {
      return []
    }

    const energyConsumptionsByDate =
      mapEnergyConsumptionsByTime(energyConsumptions)

    let value = initialValue
    return datesAsIsoStrings.map((date) => {
      const energyConsumption = energyConsumptionsByDate.get(date)
      value += energyConsumption ? energyConsumption.value : 0
      return value
    })
  }

  function makeIncrementalDataUsingEnergyConsumptions(): number[] {
    if (!energyConsumptions) {
      return []
    }

    const energyConsumptionsByDate =
      mapEnergyConsumptionsByTime(energyConsumptions)

    return datesAsIsoStrings.map((date) => {
      const energyConsumption = energyConsumptionsByDate.get(date)
      return energyConsumption ? energyConsumption.value : 0
    })
  }

  function makeIncrementalDataUsingSensorAggregates(): number[] {
    const sensorAggregatesByDate =
      transformSensorAggregatesToMap(sensorAggregates)

    return datesAsIsoStrings.map((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)
      return sensorAggregate ? sensorAggregate.avg_kw : 0
    })
  }

  return (
    <>
      {settings.calculationMode === CalculationMode.CUMULATIVE ? (
        <LineChart data={chartData} options={chartOptions} />
      ) : (
        <BarChart data={chartData} options={chartOptions} />
      )}
    </>
  )
}
