import { Button as MuiButton, Typography, Stack } from '@mui/material'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { setOrder, setOrders } from '../../redux/slices/orders'
import { useDispatch } from 'react-redux'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import useAuth from '../../hooks/useAuth'
import { UserRole } from '../../enums/UserRole'
import { setDevice } from '../../redux/slices/devices'
import { setData, setStep } from '../../redux/slices/steps'
import { QRData } from '../../enums/QRData'
import { setProduct } from '../../redux/slices/products'

const Button = styled(MuiButton)(spacing)

function HomeNavigation() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setOrder(undefined))
    dispatch(setOrders(undefined))
    dispatch(setDevice(undefined))
    dispatch(setData(QRData.NOT_SCANNED))
    dispatch(setProduct(undefined))
    dispatch(setStep(0))
  }, [])

  const { currentUser } = useAuth()
  return (
    <React.Fragment>
      <Stack spacing={6}>
        {currentUser && currentUser.role === UserRole.SUPERADMIN ? (
          <Typography align="right">
            <Button
              variant="contained"
              onClick={() => navigate('/manufacturing/invite')}
            >
              {t('Manufacturing.invite')}
              <MailOutlineIcon />
            </Button>
          </Typography>
        ) : null}

        <Typography variant="h3" align="center">
          {t('Manufacturing.selectAction')}
        </Typography>
        <Button
          style={{
            minHeight: '100px',
            fontSize: '25px'
          }}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/manufacturing/orders/create')}
        >
          {t('Manufacturing.newOrder')}
        </Button>
        <Button
          style={{
            minHeight: '100px',
            fontSize: '25px'
          }}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/manufacturing/orders')}
        >
          {t('Manufacturing.orders')}
        </Button>

        <Button
          style={{
            minHeight: '100px',
            fontSize: '25px'
          }}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/manufacturing/orders/shipped')}
        >
          {t('Manufacturing.inspectShipped')}
        </Button>
        <Button
          style={{
            minHeight: '100px',
            fontSize: '25px'
          }}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/manufacturing/upload')}
        >
          {t('Manufacturing.instructionsAndPictures')}
        </Button>
        <Button
          style={{
            minHeight: '100px',
            fontSize: '25px'
          }}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/manufacturing/catalog')}
        >
          {t('manageCatalog')}
        </Button>
        <Button
          style={{
            minHeight: '100px',
            fontSize: '25px'
          }}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/manufacturing/storage')}
        >
          {t('storage')}
        </Button>
      </Stack>
    </React.Fragment>
  )
}
export default HomeNavigation
