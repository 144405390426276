import {
  grey,
  blue,
  orange,
  purple,
  yellow,
  deepPurple,
  lime,
  teal,
  cyan,
  amber,
  blueGrey,
  indigo
} from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import { differenceInHours } from 'date-fns'
import fi from 'date-fns/locale/fi'
import {
  ChartData,
  ChartDataset,
  ChartOptions,
  TooltipItem,
  Chart
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import DateRange from '../types/DateRange'
import Sensor from '../types/Sensor'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import SensorAggregateField from '../enums/SensorAggregateField'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'
import {
  getDatesByRange,
  getDefaultDateIntervalByDateRange,
  getDifferenceOfDateRangeInHours,
  humanizeDate,
  humanizeDateRange,
  humanizeTimestamp
} from '../utils/date'
import LoadingState from './LoadingState'
import _ from 'lodash'
import LineChart from './LineChart'
import { useTheme } from '@mui/system'
import { useRef } from 'react'
import { DateInterval, SensorAggregateInterval } from '../enums'
import { toSensorAggregateMap } from '../utils/sensorAggregate'
import zoomPlugin from 'chartjs-plugin-zoom'
import { useDispatch } from 'react-redux'
import { setDateRange, setTimePeriod } from '../redux/slices/query'
import TimePeriod from '../enums/TimePeriod'
import { AnyMessageParams } from 'yup/lib/types'
Chart.register(zoomPlugin)

export interface SensorAggregateChartProps {
  dateRange: DateRange
  sensors?: Sensor[]
  sensorAggregates?: SensorAggregate[]
  options?: SensorAggregateOptions
  chartOptions?: ChartOptions
  chartWrapperHeight?: string
  loading?: boolean
}

export default function SensorAggregateChart({
  dateRange,
  sensors = [],
  sensorAggregates = [],
  options = {
    fields: [],
    statistics: []
  },
  chartOptions,
  chartWrapperHeight = '40vh',
  loading = false
}: SensorAggregateChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')
  const dispatch = useDispatch()

  function updateSensorDateRange({ chart }: any) {
    const { min, max } = chart.scales.x
    dispatch(setTimePeriod(TimePeriod.CUSTOM))
    dispatch(
      setDateRange({
        from: new Date(parseInt(min)),
        to: new Date(parseInt(max))
      })
    )
  }

  /**
   * The difference of date range as hours.
   */
  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  const theme = useTheme()

  /**
   * Indicates if sensor aggregates should be used.
   */
  const useSensorAggregates = differenceOfDateRangeInHours > 3

  const dates = makeDates()

  const datesAsIsoStrings = dates.map((date) => date.toISOString())

  const chartData: ChartData = {
    labels: useSensorAggregates ? datesAsIsoStrings : [],
    datasets: makeChartDatasets()
  }

  const defaultChartOptions: ChartOptions = {
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x',
          onZoomComplete: updateSensorDateRange
        }
      },
      title: {
        display: true,
        position: 'bottom',
        padding: {
          top: 24
        },
        text: `${humanizeDateRange(dateRange.from, dateRange.to)}`,
        font: {
          weight: '600',
          size: 14
        },
        color:
          theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 1.0)'
            : 'rgba(255, 255, 255, 1.0)'
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 24,
          boxHeight: 1,
          padding: 24,
          font: {
            weight: '600',
            size: 12
          }
        }
      },
      tooltip: {
        callbacks: {
          // @ts-ignore
          title: (tooltipItems: TooltipItem[]): string => {
            if (tooltipItems.length) {
              if (differenceOfDateRangeInHours <= 3) {
                return humanizeTimestamp(tooltipItems[0].raw.x, true)
              } else if (differenceOfDateRangeInHours <= 744) {
                return humanizeTimestamp(dates[tooltipItems[0].dataIndex])
              } else {
                return humanizeDate(dates[tooltipItems[0].dataIndex])
              }
            }
            return ''
          }
        }
      },
      decimation: {
        enabled: false,
        algorithm: 'min-max'
      }
    },
    elements: {
      point: {
        pointStyle: 'point',
        radius: 0
      },
      line: {
        borderWidth: 1
      }
    },
    scales: {
      x: {
        display: true,
        // @ts-ignore
        min: dateRange.from,
        // @ts-ignore
        max: dateRange.to,
        type: 'timeseries',
        // ticks: { autoSkip: true, maxTicksLimit: 12 },
        time: {
          unit:
            differenceOfDateRangeInHours <= 3
              ? 'minute'
              : differenceOfDateRangeInHours <= 24
              ? 'hour'
              : 'day',
          displayFormats: {
            // TODO: If date range overlaps to two dates then d.m HH:mm
            minute: 'HH:mm',
            // TODO: If date range overlaps to two dates then d.m HH:mm
            hour: 'HH:mm',
            day: 'd.M'
          }
        },
        adapters: {
          date: {
            locale: fi
          }
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          source: 'auto'
        },
        grid: {
          display: true,
          // @ts-ignore
          color: 'rgba(255, 255, 255, 0.0375)',
          // @ts-ignore
          fontColor: '#fff',
          // @ts-ignore
          drawBorder: false
        },
        color: '#fff'
      },
      y: {
        display: true,
        grid: {
          display: true,
          // @ts-ignore
          color: 'rgba(255, 255, 255, 0.0375)',
          // @ts-ignore
          fontColor: '#fff',
          // @ts-ignore
          drawBorder: false
        }
      }
    }
  }

  chartOptions = _.merge(defaultChartOptions, chartOptions)

  function makeDates(): Date[] {
    const interval = getDefaultDateIntervalByDateRange(dateRange)

    return getDatesByRange(dateRange.from, dateRange.to, interval)
  }

  function makeChartDatasets(): ChartDataset[] {
    const datasets: ChartDataset[] = []

    options.fields.forEach((field: SensorAggregateField) => {
      options.statistics.forEach((statistic: SensorAggregateStatistic) => {
        if (useSensorAggregates) {
          datasets.push(makeChartDatasetOfSensorAggregates(field, statistic))
        } else {
          datasets.push(makeChartDatasetOfSensors(field, statistic))
        }
      })
    })

    return datasets
  }

  function makeChartDatasetOfSensorAggregates(
    field: SensorAggregateField,
    statistic: SensorAggregateStatistic
  ): ChartDataset {
    const key = `${statistic}_${field}` as keyof SensorAggregate
    const label = makeDatasetLabel(field, statistic)
    const color = makeColor(field, statistic)

    const sensorAggregatesByDate = toSensorAggregateMap(sensorAggregates)

    const data = datesAsIsoStrings.map((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)
      return sensorAggregate ? (sensorAggregate[key] as number).toFixed(1) : 0
    })

    // @ts-ignore
    return {
      fill: options.statistics.length === 1 && options.fields.length === 1,
      label,
      // backgroundColor: color,
      backgroundColor: (context: any) => {
        // Return if the chart has not mounted.
        if (!context.chart.chartArea) {
          return
        }

        const {
          ctx,
          chartArea: { top, bottom }
        } = context.chart

        const index = options.statistics.indexOf(statistic)
        const lineChart = context.chart._sortedMetasets[index]

        // Collect the points.
        const points: any[] = lineChart.data.filter(
          (point: any) => point.y !== undefined
        )

        const yCoordinates: number[] = points.map(
          (point: any) => point.y as number
        )

        if (!yCoordinates.length) {
          // TODO: We should wait until points have been rendered.
          yCoordinates.push(top)
        }

        // Start the pattern from the highest point (lowest Y-coordinate).
        const patternTop = Math.min(...yCoordinates)

        // End the pattern to the lowest point (highest Y-coordinate).
        const patternBottom = Math.max(...yCoordinates)

        const backgroundColor = getColor(field)

        // Create the fill pattern.
        const pattern = ctx.createLinearGradient(
          0,
          patternTop,
          0,
          patternBottom
        )

        pattern.addColorStop(0, backgroundColor['A700'])
        pattern.addColorStop(0.15, 'rgba(35, 48, 68, 0)')

        return pattern
      },
      borderColor: color,
      pointBackgroundColor: color,
      spanGaps: false,
      tension: 0.4,
      data: data
    } as ChartDataset
  }

  function makeChartDatasetOfSensors(
    field: SensorAggregateField,
    statistic: SensorAggregateStatistic
  ): ChartDataset {
    const key = `${statistic}_${field}` as keyof Sensor
    const label = makeDatasetLabel(field, statistic)
    const color: string = makeColor(field, statistic)

    return {
      label,
      backgroundColor: color,
      borderColor: color,
      tension: 0.4,
      spanGaps: false,
      // @ts-ignore
      data: [...sensors].reverse().map((sensor) => {
        return {
          x: sensor.time,
          y: isLegacySensor(sensor)
            ? (sensor[field] as number).toFixed(1)
            : (sensor[key] as number).toFixed(1)
        }
      })
    }
  }

  function makeDatasetLabel(
    field: SensorAggregateField,
    statistic?: SensorAggregateStatistic
  ): string {
    const fieldString = t(`SensorAggregate.${field as string}`)
    if (statistic) {
      const statisticString = t(`SensorAggregate.${statistic as string}`)
      return `${fieldString} (${statisticString})`
    }

    return fieldString
  }

  function makeColor(
    field: SensorAggregateField,
    statistic?: SensorAggregateStatistic
  ): any {
    let weight: string
    switch (statistic) {
      case SensorAggregateStatistic.MIN:
        weight = 'A100'
        break
      case SensorAggregateStatistic.MAX:
        weight = 'A700'
        break
      case SensorAggregateStatistic.AVG:
      default:
        weight = '500'
        break
    }

    const color = getColor(field)

    return color[weight]
  }

  function getColor(field: SensorAggregateField): any {
    // @see https://mui.com/material-ui/customization/color/#color-palette
    switch (field) {
      case SensorAggregateField.CURRENT_1:
        return blue as any
      case SensorAggregateField.CURRENT_2:
        return grey as any
      case SensorAggregateField.CURRENT_3:
        return orange as any
      case SensorAggregateField.VOLTAGE_1:
        return deepPurple as any
      case SensorAggregateField.VOLTAGE_2:
        return blueGrey as any
      case SensorAggregateField.VOLTAGE_3:
        return amber as any
      case SensorAggregateField.KW:
        return purple as any
      case SensorAggregateField.KW_L1:
        return indigo as any
      case SensorAggregateField.KW_L2:
        return cyan as any
      case SensorAggregateField.KW_L3:
        return blueGrey as any
      case SensorAggregateField.RATED_CURRENT:
        return yellow as any
      case SensorAggregateField.TEMP:
        return cyan as any
      case SensorAggregateField.RH:
        return teal as any
      case SensorAggregateField.RSSI:
        return lime as any
      default:
        return blue as any
    }
  }

  function isLegacySensor(sensor: Sensor): boolean {
    return sensor.min_current_1 === null
  }

  return (
    <>
      {loading ? (
        <LoadingState />
      ) : (
        <>
          <LineChart
            data={chartData}
            options={chartOptions}
            wrapperHeight={chartWrapperHeight}
          />
        </>
      )}
    </>
  )
}
