import { SyntheticEvent, useEffect, useState } from 'react'
import { differenceInHours } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import {
  RuuviTag,
  RuuviTagAcceleration,
  RuuviTagAccelerationAggregate,
  RuuviTagAccelerationAggregateQuery,
  RuuviTagAccelerationQuery,
  RuuviTagBattery,
  RuuviTagBatteryAggregate,
  RuuviTagBatteryAggregateQuery,
  RuuviTagBatteryQuery,
  RuuviTagCondition,
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateQuery,
  RuuviTagConditionQuery,
  RuuviTagMovement,
  RuuviTagMovementQuery
} from '../../types'
import {
  Hours,
  RuuviTagAccelerationAggregateInterval,
  RuuviTagBatteryAggregateInterval,
  RuuviTagConditionAggregateInterval
} from '../../enums'
import { Helmet } from 'react-helmet-async'
import {
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Typography,
  Stack,
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Tab,
  CardHeader
} from '@mui/material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid'
import DateRangeBar from '../../components/DateRangeBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import {
  getRuuviTag,
  getRuuviTagAccelerationAggregates,
  getRuuviTagAccelerations,
  getRuuviTagBatteries,
  getRuuviTagBatteryAggregates,
  getRuuviTagConditionAggregates,
  getRuuviTagConditions,
  getRuuviTagMovements
} from '../../services/ruuviTagService'
import { formatMacAddress } from '../../utils/ruuviTag'
import RuuviTagTemperatureChart from '../../components/RuuviTagTemperatureChart'
import RuuviTagHumidityChart from '../../components/RuuviTagHumidityChart'
import RuuviTagPressureChart from '../../components/RuuviTagPressureChart'
import { humanizeTimestamp } from '../../utils/date'
import LoadingState from '../../components/LoadingState'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import RuuviTagAccelerationAxisChart from '../../components/RuuviTagAccelerationAxisChart'
import RuuviTagBatteryVoltageChart from '../../components/RuuviTagBatteryVoltageChart'
import RuuviTagMovementCountChart from '../../components/RuuviTagMovementCountChart'
import RuuviTagStatusIndicator from '../../components/RuuviTagStatusIndicator'

type RuuviTagStatsTab = 'conditions' | 'accelerations' | 'battery' | 'movements'

type RuuviTagStatsDataGridRow = {
  id: number
  time: string
  energyConsumption: string
  energyCost: string
  energyPrice: string
  co2Emissions: string
  co2EmissionFactor: string
}

function RuuviTagStatsDataGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

export default function RuuviTagStats() {
  /**
   * The route params.
   */
  const { id } = useParams()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current date range.
   */
  const dateRange = useSelector((state: RootState) => state.query.dateRange)

  /**
   * The RuuviTag sensor.
   */
  const [ruuviTag, setRuuviTag] = useState<RuuviTag | null>(null)

  /**
   * The accelerations of the RuuviTag.
   */
  const [accelerations, setAccelerations] = useState<RuuviTagAcceleration[]>([])

  /**
   * The batteries of the RuuviTag.
   */
  const [batteries, setBatteries] = useState<RuuviTagBattery[]>([])

  /**
   * The conditions of the RuuviTag.
   */
  const [conditions, setConditions] = useState<RuuviTagCondition[]>([])

  /**
   * The movements of the RuuviTag.
   */
  const [movements, setMovements] = useState<RuuviTagMovement[]>([])

  /**
   * The accceleration aggregates of the RuuviTag.
   */
  const [accelerationAggregates, setAccelerationAggregates] = useState<
    RuuviTagAccelerationAggregate[]
  >([])

  /**
   * The battery aggregates of the RuuviTag.
   */
  const [batteryAggregates, setBatteryAggregates] = useState<
    RuuviTagBatteryAggregate[]
  >([])

  /**
   * The condition aggregates of the RuuviTag sensor.
   */
  const [conditionAggregates, setConditionAggregates] = useState<
    RuuviTagConditionAggregate[]
  >([])

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * Indicates if the component is in error state.
   */
  const [isError, setIsError] = useState<boolean>(false)

  /**
   * The selected tab.
   */
  const [selectedTab, setSelectedTab] = useState<RuuviTagStatsTab>('conditions')

  /**
   * The differencec of date range in hours.
   */
  const differenceOfDateRangeInHours = Math.abs(
    differenceInHours(dateRange.from, dateRange.to)
  )

  /**
   * Indicates if the aggregates are being used.
   */
  const isUsingAggregates = differenceOfDateRangeInHours >= 24

  /**
   * The query for the accelerations of the RuuviTag.
   */
  const accelerationQuery: RuuviTagAccelerationQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query for the accelerations of the RuuviTag.
   */
  const accelerationAggregateQuery: RuuviTagAccelerationAggregateQuery = {
    from: dateRange.from,
    to: dateRange.to,
    interval: getRuuviTagAccelerationAggregateInterval()
  }

  /**
   * The query for the batteries of the RuuviTag.
   */
  const batteryQuery: RuuviTagBatteryQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query for the battery aggregates of the RuuviTag.
   */
  const batteryAggregateQuery: RuuviTagBatteryAggregateQuery = {
    from: dateRange.from,
    to: dateRange.to,
    interval: getRuuviTagBatteryAggregateInterval()
  }

  /**
   * The query for the conditions of the RuuviTag.
   */
  const conditionQuery: RuuviTagConditionQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query for the conditions of the RuuviTag.
   */
  const conditionAggregateQuery: RuuviTagConditionAggregateQuery = {
    from: dateRange.from,
    to: dateRange.to,
    interval: getRuuviTagConditionAggregateInterval()
  }

  /**
   * The query for the movements of the RuuviTag.
   */
  const movementQuery: RuuviTagMovementQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * Boots the component.
   */
  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      setIsError(false)

      if (ruuviTag === null) {
        await loadRuuviTag(signal)
      }

      switch (selectedTab) {
        case 'accelerations':
          if (isUsingAggregates) {
            await loadAccelerationAggregates(signal)
            setAccelerations([])
          } else {
            await loadAccelerations(signal)
            setAccelerationAggregates([])
          }
          break
        case 'battery':
          if (isUsingAggregates) {
            await loadBatteryAggregates(signal)
            setBatteries([])
          } else {
            await loadBatteries(signal)
            setBatteryAggregates([])
          }
          break
        case 'conditions':
          if (isUsingAggregates) {
            await loadConditionAggregates(signal)
            setConditions([])
          } else {
            await loadConditions(signal)
            setConditionAggregates([])
          }
          break
        case 'movements':
          await loadMovements(signal)
          break
      }
    } catch (error: any) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Loads the RuuviTag.
   */
  async function loadRuuviTag(signal?: AbortSignal): Promise<void> {
    if (id) {
      setRuuviTag(await getRuuviTag(id, { signal }))
    }
  }

  /**
   * Loads the accelerations of the RuuviTag.
   */
  async function loadAccelerations(signal?: AbortSignal): Promise<void> {
    if (id) {
      setAccelerations(
        await getRuuviTagAccelerations(id, accelerationQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the batteries of the RuuviTag.
   */
  async function loadBatteries(signal?: AbortSignal): Promise<void> {
    if (id) {
      setBatteries(
        await getRuuviTagBatteries(id, batteryQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the conditions of the RuuviTag.
   */
  async function loadConditions(signal?: AbortSignal): Promise<void> {
    if (id) {
      setConditions(await getRuuviTagConditions(id, conditionQuery, { signal }))
    }
  }

  /**
   * Loads the movements of the RuuviTag.
   */
  async function loadMovements(signal?: AbortSignal): Promise<void> {
    if (id) {
      setMovements(
        await getRuuviTagMovements(id, movementQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the acceleration agggregates of the RuuviTag.
   */
  async function loadAccelerationAggregates(
    signal?: AbortSignal
  ): Promise<void> {
    if (id) {
      setAccelerationAggregates(
        await getRuuviTagAccelerationAggregates(
          id,
          accelerationAggregateQuery,
          { signal }
        )
      )
    }
  }

  /**
   * Loads the battery agggregates of the RuuviTag.
   */
  async function loadBatteryAggregates(signal?: AbortSignal): Promise<void> {
    if (id) {
      setBatteryAggregates(
        await getRuuviTagBatteryAggregates(id, batteryAggregateQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the condition aggregates of the RuuviTag.
   */
  async function loadConditionAggregates(signal?: AbortSignal): Promise<void> {
    if (id) {
      setConditionAggregates(
        await getRuuviTagConditionAggregates(id, conditionAggregateQuery, {
          signal
        })
      )
    }
  }

  /**
   * Get the aggregate interval for the accelerations of the RuuviTag.
   */
  function getRuuviTagAccelerationAggregateInterval(): RuuviTagAccelerationAggregateInterval {
    if (differenceOfDateRangeInHours < Hours.THREE_DAYS) {
      return RuuviTagAccelerationAggregateInterval.FIVE_MINUTES
    } else if (differenceOfDateRangeInHours < Hours.SEVEN_DAYS) {
      // 168h = 7 days
      return RuuviTagAccelerationAggregateInterval.FIFTEEN_MINUTES
    } else if (differenceOfDateRangeInHours <= Hours.THIRTY_ONE_DAYS) {
      return RuuviTagAccelerationAggregateInterval.ONE_HOUR
    } else {
      return RuuviTagAccelerationAggregateInterval.ONE_DAY
    }
  }

  /**
   * Get the aggregate interval for the batteries of the RuuviTag.
   */
  function getRuuviTagBatteryAggregateInterval(): RuuviTagBatteryAggregateInterval {
    if (differenceOfDateRangeInHours < Hours.THREE_DAYS) {
      return RuuviTagBatteryAggregateInterval.FIVE_MINUTES
    } else if (differenceOfDateRangeInHours < Hours.SEVEN_DAYS) {
      return RuuviTagBatteryAggregateInterval.FIFTEEN_MINUTES
    } else if (differenceOfDateRangeInHours <= Hours.THIRTY_ONE_DAYS) {
      return RuuviTagBatteryAggregateInterval.ONE_HOUR
    } else {
      return RuuviTagBatteryAggregateInterval.ONE_DAY
    }
  }

  /**
   * Get the aggregate interval for the conditions of the RuuviTag.
   */
  function getRuuviTagConditionAggregateInterval(): RuuviTagConditionAggregateInterval {
    if (differenceOfDateRangeInHours < Hours.THREE_DAYS) {
      return RuuviTagConditionAggregateInterval.FIVE_MINUTES
    } else if (differenceOfDateRangeInHours < Hours.SEVEN_DAYS) {
      return RuuviTagConditionAggregateInterval.FIFTEEN_MINUTES
    } else if (differenceOfDateRangeInHours <= Hours.THIRTY_ONE_DAYS) {
      return RuuviTagConditionAggregateInterval.ONE_HOUR
    } else {
      return RuuviTagConditionAggregateInterval.ONE_DAY
    }
  }

  const dataGridColumns = makeDataGridColumns()

  const dataGridRows = makeDataGridRows()

  function getTimezoneString(): string {
    const offset: number = new Date().getTimezoneOffset() / 60

    const symbol = offset > 0 ? '+' : '-'
    const hours =
      Math.abs(offset) < 10 ? '0' + Math.abs(offset) : Math.abs(offset)
    const minutes = offset % 1 !== 0 ? '30' : '00'

    return `UTC${symbol}${hours}:${minutes}`
  }

  function makeDataGridColumns(): any[] {
    switch (selectedTab) {
      case 'conditions':
        return makeConditionDataGridColumns()
      case 'accelerations':
        return makeAccelerationDataGridColumns()
      case 'battery':
        return makeBatteryDataGridColumns()
      case 'movements':
        return makeMovementDataGridColumns()
    }
  }

  function makeConditionDataGridColumns(): any[] {
    let columns = [
      {
        field: 'time',
        headerName: `${t('date')} (${getTimezoneString()})`,
        flex: 1
      },
      {
        field: 'temperature_min',
        headerName: `${t('temperature')} ${t('min')} (°C)`,
        flex: 1
      },
      {
        field: 'temperature_avg',
        headerName: `${t('temperature')} ${t('avg')} (°C)`,
        flex: 1
      },
      {
        field: 'temperature_max',
        headerName: `${t('temperature')} ${t('max')} (°C)`,
        flex: 1
      },
      {
        field: 'humidity_min',
        headerName: `${t('humidity')} ${t('min')} (%)`,
        flex: 1
      },
      {
        field: 'humidity_avg',
        headerName: `${t('humidity')} ${t('avg')} (%)`,
        flex: 1
      },
      {
        field: 'humidity_max',
        headerName: `${t('humidity')} ${t('max')} (%)`,
        flex: 1
      },
      {
        field: 'pressure_min',
        headerName: `${t('pressure')} ${t('min')} (hPA)`,
        flex: 1
      },
      {
        field: 'pressure_avg',
        headerName: `${t('pressure')} ${t('avg')} (hPA)`,
        flex: 1
      },
      {
        field: 'pressure_max',
        headerName: `${t('pressure')} ${t('max')} (hPA)`,
        flex: 1
      }
    ]

    if (isUsingAggregates) {
      columns = columns.concat([
        {
          field: 'rssi_min',
          headerName: `${t('rssi')} ${t('min')} (dBM)`,
          flex: 1
        },
        {
          field: 'rssi_avg',
          headerName: `${t('rssi')} ${t('avg')} (dBM)`,
          flex: 1
        },
        {
          field: 'rssi_max',
          headerName: `${t('rssi')} ${t('max')} (dBM)`,
          flex: 1
        }
      ])
    } else {
      columns.push({
        field: 'rssi',
        headerName: `${t('rssi')} (dBM)`,
        flex: 1
      })
    }

    return columns
  }

  function makeAccelerationDataGridColumns(): any[] {
    return [
      {
        field: 'time',
        headerName: `${t('date')} (${getTimezoneString()})`,
        flex: 1
      },
      {
        field: 'x_avg',
        headerName: `${t('xAxis')} ${t('avg')}`,
        flex: 1
      },
      {
        field: 'x_max',
        headerName: `${t('xAxis')} ${t('max')}`,
        flex: 1
      },
      {
        field: 'y_avg',
        headerName: `${t('yAxis')} ${t('avg')}`,
        flex: 1
      },
      {
        field: 'y_max',
        headerName: `${t('yAxis')} ${t('max')}`,
        flex: 1
      },
      {
        field: 'z_avg',
        headerName: `${t('zAxis')} ${t('avg')}`,
        flex: 1
      },
      {
        field: 'z_max',
        headerName: `${t('zAxis')} ${t('max')}`,
        flex: 1
      }
    ]
  }

  function makeBatteryDataGridColumns(): any[] {
    let columns = [
      {
        field: 'time',
        headerName: `${t('date')} (${getTimezoneString()})`,
        flex: 1
      }
    ]

    if (isUsingAggregates) {
      columns = columns.concat([
        {
          field: 'volt_min',
          headerName: `${t('voltage')} ${t('min')} (mV)`,
          flex: 1
        },
        {
          field: 'volt_avg',
          headerName: `${t('voltage')} ${t('avg')} (mV)`,
          flex: 1
        },
        {
          field: 'volt_max',
          headerName: `${t('voltage')} ${t('max')} (mV)`,
          flex: 1
        }
      ])
    } else {
      columns.push({
        field: 'voltage',
        headerName: `${t('voltage')} (V)`,
        flex: 1
      })
    }

    return columns
  }

  function makeMovementDataGridColumns(): any[] {
    return [
      {
        field: 'time',
        headerName: `${t('date')} (${getTimezoneString()})`,
        flex: 1
      },
      {
        field: 'movementCount',
        headerName: `${t('movementCount')}`,
        flex: 1
      }
    ]
  }

  function makeDataGridRows(): any[] {
    switch (selectedTab) {
      case 'conditions':
        return makeConditionDataGridRows()
      case 'accelerations':
        return makeAccelerationDataGridRows()
      case 'battery':
        return makeBatteryDataGridRows()
      case 'movements':
        return makeMovementDataGridRows()
    }
  }

  function makeConditionDataGridRows(): any[] {
    return isUsingAggregates
      ? conditionAggregates.map((conditionAggregate) => ({
          id: new Date(conditionAggregate.timeBucket).toTimeString(),
          time: humanizeTimestamp(conditionAggregate.timeBucket),
          temperature_min: humanizeValue(conditionAggregate.temperatureMin),
          temperature_avg: humanizeValue(conditionAggregate.temperatureAvg),
          temperature_max: humanizeValue(conditionAggregate.temperatureMax),
          humidity_min: humanizeValue(conditionAggregate.humidityMin),
          humidity_avg: humanizeValue(conditionAggregate.humidityAvg),
          humidity_max: humanizeValue(conditionAggregate.humidityMax),
          pressure_min: humanizeValue(conditionAggregate.pressureMin),
          pressure_avg: humanizeValue(conditionAggregate.pressureAvg),
          pressure_max: humanizeValue(conditionAggregate.pressureMax),
          rssi_min: humanizeValue(conditionAggregate.rssiMin),
          rssi_avg: humanizeValue(conditionAggregate.rssiAvg),
          rssi_max: humanizeValue(conditionAggregate.rssiMax)
        }))
      : conditions.map((condition) => ({
          id: new Date(condition.time).toTimeString(),
          time: humanizeTimestamp(condition.time),
          temperature_min: humanizeValue(condition.temperatureMin),
          temperature_avg: humanizeValue(condition.temperatureAvg),
          temperature_max: humanizeValue(condition.temperatureMax),
          humidity_min: humanizeValue(condition.humidityMin),
          humidity_avg: humanizeValue(condition.humidityAvg),
          humidity_max: humanizeValue(condition.humidityMax),
          pressure_min: humanizeValue(condition.pressureMin),
          pressure_avg: humanizeValue(condition.pressureAvg),
          pressure_max: humanizeValue(condition.pressureMax),
          rssi: humanizeValue(condition.rssi)
        }))
  }

  function makeAccelerationDataGridRows(): any[] {
    return isUsingAggregates
      ? accelerationAggregates.map((accelerationAggregate) => ({
          id: new Date(accelerationAggregate.timeBucket).toTimeString(),
          time: humanizeTimestamp(accelerationAggregate.timeBucket),
          x_avg: humanizeValue(accelerationAggregate.xAvg),
          x_max: humanizeValue(accelerationAggregate.xMax),
          y_avg: humanizeValue(accelerationAggregate.yAvg),
          y_max: humanizeValue(accelerationAggregate.yMax),
          z_avg: humanizeValue(accelerationAggregate.zAvg),
          z_max: humanizeValue(accelerationAggregate.zMax)
        }))
      : accelerations.map((acceleration) => ({
          id: new Date(acceleration.time).toTimeString(),
          time: humanizeTimestamp(acceleration.time),
          x_avg: humanizeValue(acceleration.xAvg),
          x_max: humanizeValue(acceleration.xMax),
          y_avg: humanizeValue(acceleration.yAvg),
          y_max: humanizeValue(acceleration.yMax),
          z_avg: humanizeValue(acceleration.zAvg),
          z_max: humanizeValue(acceleration.zMax)
        }))
  }

  function makeBatteryDataGridRows(): any[] {
    return isUsingAggregates
      ? batteryAggregates.map((batteryAggregate) => ({
          id: new Date(batteryAggregate.timeBucket).toTimeString(),
          time: humanizeTimestamp(batteryAggregate.timeBucket),
          volt_min: humanizeValue(batteryAggregate.voltageMin),
          volt_avg: humanizeValue(batteryAggregate.voltageAvg),
          volt_max: humanizeValue(batteryAggregate.voltageMax)
        }))
      : batteries.map((battery) => ({
          id: new Date(battery.time).toTimeString(),
          time: humanizeTimestamp(battery.time),
          voltage: humanizeValue(battery.voltage)
        }))
  }

  function makeMovementDataGridRows(): any[] {
    return movements.map((movement) => ({
      id: new Date(movement.time).toTimeString(),
      time: humanizeTimestamp(movement.time),
      movementCount: movement.counts
    }))
  }

  function humanizeValue(value: number | null): string {
    return value !== null ? value.toFixed(2) : '-'
  }

  function changeTab(event: SyntheticEvent, tab: RuuviTagStatsTab): void {
    setSelectedTab(tab)
  }

  useEffect(() => {
    const controller = new AbortController()
    boot(controller.signal)
    return () => controller.abort()
  }, [selectedTab, dateRange])

  return (
    <>
      <Helmet title={`${ruuviTag?.asset?.name} - ${t('statistics')}`} />

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" gap={2}>
          {!isError && (
            <>
              {ruuviTag && (
                <Stack direction="row" gap={2} alignItems="center">
                  <RuuviTagStatusIndicator
                    fontSize="medium"
                    ruuviTag={ruuviTag}
                  />
                  <Typography variant="h3" display="inline">
                    {ruuviTag?.asset?.name ?? formatMacAddress(ruuviTag?.id)}
                  </Typography>
                </Stack>
              )}

              <Breadcrumbs>
                {ruuviTag && ruuviTag?.asset?.project && (
                  <Link
                    component={NavLink}
                    to={`/projects/${ruuviTag.asset.project.id}`}
                  >
                    {ruuviTag.asset.project.name}
                  </Link>
                )}

                {ruuviTag && ruuviTag?.asset?.project ? (
                  <Link
                    component={NavLink}
                    to={`/projects/${ruuviTag.asset.project.id}#monitoring`}
                  >
                    {t('deviceMonitoring')}
                  </Link>
                ) : (
                  <Link component={NavLink} to="/ruuvi-tags">
                    {t('conditionMonitoring')}
                  </Link>
                )}

                {ruuviTag && (
                  <Link component={NavLink} to={`/ruuvi-tags/${ruuviTag.id}`}>
                    {formatMacAddress(ruuviTag?.id)}
                  </Link>
                )}

                <Typography>{t('statistics')}</Typography>
              </Breadcrumbs>
            </>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DateRangeBar showAutoRefreshToggle={true} />
        </Stack>
      </Stack>

      <Divider sx={{ mt: 6, mb: 3 }} />

      <TabContext value={selectedTab}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={changeTab}
          sx={{ mb: 6 }}
        >
          <Tab label={t('conditions')} value="conditions" />
          <Tab label={t('accelerations')} value="accelerations" />
          <Tab label={t('battery')} value="battery" />
          <Tab label={t('movements')} value="movements" />
        </TabList>

        {isError && (
          <Alert severity="error">
            <AlertTitle>{t('pageError')}</AlertTitle>
            <Typography>{t('pleaseTryAgain')}</Typography>
            <Button
              variant="contained"
              color="error"
              onClick={() => boot()}
              sx={{ mt: 3 }}
            >
              {t('reload')}
            </Button>
          </Alert>
        )}

        {ruuviTag && !isError && (
          <>
            <TabPanel value="conditions">
              <Grid container spacing={6}>
                <Grid item xs={12} xl={4}>
                  <RuuviTagTemperatureChart
                    conditions={
                      isUsingAggregates ? conditionAggregates : conditions
                    }
                    query={
                      isUsingAggregates
                        ? conditionAggregateQuery
                        : conditionQuery
                    }
                    loading={isLoading}
                  />
                </Grid>

                <Grid item xs={12} xl={4}>
                  <RuuviTagHumidityChart
                    conditions={
                      isUsingAggregates ? conditionAggregates : conditions
                    }
                    query={
                      isUsingAggregates
                        ? conditionAggregateQuery
                        : conditionQuery
                    }
                    loading={isLoading}
                  />
                </Grid>

                <Grid item xs={12} xl={4}>
                  <RuuviTagPressureChart
                    conditions={
                      isUsingAggregates ? conditionAggregates : conditions
                    }
                    query={
                      isUsingAggregates
                        ? conditionAggregateQuery
                        : conditionQuery
                    }
                    loading={isLoading}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value="accelerations">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <RuuviTagAccelerationAxisChart
                    accelerations={
                      isUsingAggregates ? accelerationAggregates : accelerations
                    }
                    query={
                      isUsingAggregates
                        ? accelerationAggregateQuery
                        : accelerationQuery
                    }
                    loading={isLoading}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value="battery">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <RuuviTagBatteryVoltageChart
                    batteries={
                      isUsingAggregates ? batteryAggregates : batteries
                    }
                    query={
                      isUsingAggregates ? batteryAggregateQuery : batteryQuery
                    }
                    loading={isLoading}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value="movements">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <RuuviTagMovementCountChart
                    movements={movements}
                    query={movementQuery}
                    loading={isLoading}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <Divider sx={{ my: 6 }} />

            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={t('statistics')} />
                  <CardContent>
                    {isLoading ? (
                      <LoadingState />
                    ) : (
                      <DataGrid
                        autoHeight
                        columns={dataGridColumns}
                        rows={dataGridRows}
                        components={{ Toolbar: RuuviTagStatsDataGridToolbar }}
                        sx={{
                          border: 0
                        }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </TabContext>
    </>
  )
}
