// Hooks.
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// Types.
import { ChartOptions } from 'chart.js'
import {
  RuuviTagAcceleration,
  RuuviTagAccelerationQuery,
  RuuviTagAccelerationField,
  RuuviTagAccelerationStatistic,
  RuuviTagAccelerationAggregate,
  RuuviTagAccelerationAggregateQuery,
  RuuviTagAccelerationAggregateField,
  RuuviTagBatteryAggregateStatistic
} from '../types'

// Components.
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import RuuviTagAccelerationChart from './RuuviTagAccelerationChart'
import RuuviTagAccelerationStatisticToggle from './RuuviTagAccelerationStatisticToggle'
import RuuviTagAccelerationAggregateChart from './RuuviTagAccelerationAggregateChart'
import RuuviTagAccelerationAggregateStatisticToggle from './RuuviTagAccelerationAggregateStatisticToggle'

interface RuuviTagAccelerationAxisChartProps {
  accelerations: RuuviTagAcceleration[] | RuuviTagAccelerationAggregate[]
  query: RuuviTagAccelerationQuery | RuuviTagAccelerationAggregateQuery
  loading?: boolean
}

export default function RuuviTagAccelerationAxisChart({
  accelerations,
  query
}: RuuviTagAccelerationAxisChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The acceleration fields of RuuviTag.
   */
  const fields: RuuviTagAccelerationField[] = ['x', 'y', 'z']

  /**
   * The acceleration aggregate fields of RuuviTag.
   */
  const aggregateFields: RuuviTagAccelerationAggregateField[] = ['x', 'y', 'z']

  /**
   * The acceleration statistics of RuuviTag.
   */
  const [statistics, setStatistics] = useState<RuuviTagAccelerationStatistic[]>(
    ['avg']
  )

  const [aggregateStatistics, setAggregateStatistics] = useState<
    RuuviTagBatteryAggregateStatistic[]
  >(['avg'])

  /**
   * The chart options.
   */
  const chartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: true
        }
      }
    }
  }

  /**
   * Indicates if acceleration aggregates are being used.
   */
  const isUsingAccelerationAggregates =
    accelerations.length > 0 && 'timeBucket' in accelerations[0]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h4">{t('acceleration')}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            alignItems="center"
          >
            {isUsingAccelerationAggregates ? (
              <RuuviTagAccelerationAggregateStatisticToggle
                value={aggregateStatistics}
                onChange={setAggregateStatistics}
              />
            ) : (
              <RuuviTagAccelerationStatisticToggle
                value={statistics}
                onChange={setStatistics}
              />
            )}
          </Grid>
        </Grid>

        {isUsingAccelerationAggregates ? (
          <RuuviTagAccelerationAggregateChart
            accelerations={accelerations as RuuviTagAccelerationAggregate[]}
            query={query as RuuviTagAccelerationAggregateQuery}
            fields={aggregateFields}
            statistics={aggregateStatistics}
            options={chartOptions}
          />
        ) : (
          <RuuviTagAccelerationChart
            ruuviTagAccelerations={accelerations as RuuviTagAcceleration[]}
            ruuviTagAccelerationQuery={query as RuuviTagAccelerationQuery}
            ruuviTagAccelerationFields={fields}
            ruuviTagAccelerationStatistics={statistics}
            chartOptions={chartOptions}
          />
        )}
      </CardContent>
    </Card>
  )
}
