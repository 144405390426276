import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { ConfluencePages, UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import Devices from '../pages/pages/Devices'
import Device from '../pages/pages/Device'
import DeviceAlerts from '../pages/pages/DeviceAlerts'
import DeviceEdit from '../pages/pages/EditDevice'
import DeviceLocation from '../pages/pages/DeviceLocation'
import DeviceSensorErrors from '../pages/pages/DeviceSensorErrors'
import DeviceStats from '../pages/pages/DeviceStats'
import DeviceTroubleshoot from '../pages/pages/DeviceTroubleshoot'
import Redirect from '../pages/Redirect'
import DeviceReplace from '../pages/pages/DeviceReplace'

export default [
  {
    path: '/devices',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <Devices />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/replace',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.INSTALLER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceReplace />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:id',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <Device />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:id/edit',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceEdit />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:id/location',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceLocation />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:id/stats',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceStats />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:name/alerts',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.INSTALLER,
          UserRole.USER,
          UserRole.MANUFACTURER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceAlerts />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:id/troubleshoot',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceTroubleshoot />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:name/sensor-errors',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.DEVICES}>
          <DashboardLayout>
            <DeviceSensorErrors />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/devices/:name/alert-management',
    element: <Redirect to="/devices/:name/alerts" />
  },
  {
    path: '/devices/:id/sensor-aggregates',
    element: <Redirect to="/devices/:id/stats" />
  },
  {
    path: '/devices/:id/edit/location',
    element: <Redirect to="/devices/:id/location" />
  }
]
