import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardHeader,
  IconButton,
  Stack,
  Chip,
  Alert
} from '@mui/material'
import { spacing } from '@mui/system'
import { Edit, Delete, VerifiedUser, Person } from '@mui/icons-material'
import {
  deleteMemberState,
  getProjectMembers,
  getUserAlertRulesByProject,
  setShow,
  setShowUserAlertManagementPopUp,
  setUser,
  setUserAlertRules,
  updateMemberState
} from '../../redux/slices/users'
import { RootState } from '../../redux/store'
import useAuth from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { MembershipRole } from '../../enums/MembershipRole'
import { checkProjectRole } from '../../utils/checkRole'
import { deleteError, setError } from '../../redux/slices/errors'
import { MembershipErrors } from '../../enums/MembershipErrors'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import UserAlertManagementPopUp from '../../components/UserAlertManagementPopUp'
import { User } from '../../types'
import { getProjectAlertRules } from '../../redux/slices/projects'
import {
  deleteProjectMembership,
  updateProjectMembership
} from '../../services/projectMembershipService'
import {
  hideMembership,
  showMembership,
  updateMembershipState
} from '../../redux/slices/projectMemberships'
import LoadingState from '../../components/LoadingState'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)

interface ProjectMemberFormProps {
  user: User
}

function Form({ user }: ProjectMemberFormProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  const { updateMembership } = useSelector(
    (state: RootState) => state.projectMemberships
  )

  /**
   * The initial values.
   */
  const initialValues =
    updateMembership === undefined
      ? {
          id: '',
          projectId: 0,
          memberId: 0,
          role: 'User'
        }
      : {
          id: updateMembership.id,
          projectId: updateMembership.projectId,
          memberId: updateMembership.memberId,
          role: updateMembership.role
        }

  /**
   * The validation schema.
   */
  const validationSchema = Yup.object().shape({
    role: Yup.string().required('Required')
  })

  useEffect(() => {
    return function cleanup() {
      dispatch(setShow(false))
    }
  }, [dispatch])

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const updatedMembership = await updateProjectMembership(values)
      dispatch(updateMembershipState(updatedMembership))

      const updatedUser = {
        ...user,
        membership: updatedMembership
      }

      dispatch(updateMemberState(updatedUser))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('memberUpdateSuccess')
        )
      )
      dispatch(hideMembership())
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
      dispatch(setShow(false))
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: MembershipErrors.EDIT,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: MembershipErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(MembershipErrors.EDIT))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    label={t('user')}
                    value={`${user.firstName} ${user.lastName}`}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    select
                    name="role"
                    label={t('role')}
                    value={values.role}
                    error={Boolean(touched.role && errors.role)}
                    helperText={touched.role && errors.role}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value={MembershipRole.ADMIN}>
                      Project admin
                    </MenuItem>
                    <MenuItem value={MembershipRole.USER}>
                      Project user
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    gap={3}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button onClick={() => dispatch(hideMembership())}>
                      {t('Users.cancel')}
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      {t('Users.save')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  )
}

function ProjectMembersDataGrid() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The ID parameter.
   */
  const { id } = useParams()

  /**
   * THe project ID.
   */
  const projectId = parseInt(id as string)

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  const { members, showUserAlertManagementPopUp, userAlertRules, user } =
    useSelector((state: RootState) => state.users)

  const { loading, projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  const { show } = useSelector((state: RootState) => state.projectMemberships)

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  const [open, setOpen] = useState(false)

  /**
   * The grid columns.
   */
  const columns: GridColumns<User> = [
    {
      field: 'firstName',
      headerName: t('firstName'),
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'lastName',
      headerName: t('lastName'),
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      minWidth: 250,
      editable: false
    },
    {
      field: 'membership.role',
      headerName: t('role'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params) => {
        if (params?.row?.membership) {
          return checkProjectRole(params.row.membership.role as string)
        }
      }
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" gap={2} alignItems="center">
            <IconButton size="small" onClick={() => handleEdit(params.row)}>
              <Edit fontSize="inherit" />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => openDeletePopUp(params.row)}
            >
              <Delete fontSize="inherit" />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => openUserAlertManagement(params.row)}
            >
              <EditNotificationsIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        )
      }
    }
  ]

  /**
   * The selected page size.
   */
  const [pageSize, setPageSize] = useState<number>(25)

  /**
   * Handle the edit action.
   *
   * @param user The user to edit.
   */
  function handleEdit(user: User) {
    setSelectedUser(user)
    dispatch(showMembership(user.membership))
  }

  /**
   * Handle the delete action.
   */
  async function handleDelete() {
    try {
      if (selectedUser?.membership) {
        await deleteProjectMembership(selectedUser.membership.id)
        dispatch(deleteMemberState(selectedUser.id))
        closeDeletePopUp()

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('memberDeleteSuccess')
          )
        )
      }
    } catch (error) {
      const errorMessage = createUserNotification({
        user: currentUser,
        type: MembershipErrors.DELETE,
        error: error
      })

      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  /**
   * Open the delete pop-up.
   *
   * @param user The user.
   */
  function openDeletePopUp(user: User) {
    setSelectedUser(user)
    setOpen(true)
  }

  /**
   * Close the delete pop-up.
   */
  function closeDeletePopUp() {
    setOpen(false)

    // Wait for the close animation to finish.
    setTimeout(() => setSelectedUser(undefined), 300)
  }

  /**
   * Open user alert management.
   *
   * @param user The user.
   */
  function openUserAlertManagement(user: User) {
    dispatch(getUserAlertRulesByProject(user.id, projectId))
    dispatch(setUser(user))
    dispatch(setShowUserAlertManagementPopUp(true))
  }

  /**
   * Close user alert management.
   */
  function closeUserAlertManagement() {
    dispatch(setShowUserAlertManagementPopUp(false))
    dispatch(setUserAlertRules([]))
    setUser(undefined)
  }

  return (
    <Card mb={6}>
      <CardHeader title={t('Users.users')} />
      <CardContent>
        {show ? (
          <Form user={selectedUser} />
        ) : (
          <>
            <DataGrid
              rows={members}
              columns={columns}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 }
                }
              }}
              sx={{
                border: 0
              }}
            />

            <Dialog
              open={open && selectedUser !== undefined}
              onClose={closeDeletePopUp}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('Users.confirmAlertMembership')}
              </DialogTitle>

              <DialogContent>
                {selectedUser && (
                  <Chip
                    variant="outlined"
                    label={
                      <Stack direction="row" gap={1} alignItems="center">
                        <Person fontSize="inherit" />
                        {`${selectedUser.firstName} ${selectedUser.lastName}`}
                      </Stack>
                    }
                    sx={{ mb: 3 }}
                  />
                )}

                <Alert
                  variant="outlined"
                  severity="warning"
                  id="alert-dialog-description"
                >
                  {t('Users.warning')}
                </Alert>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => handleDelete()}>{t('Users.yes')}</Button>
                <Button onClick={closeDeletePopUp} autoFocus>
                  {t('Users.no')}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={showUserAlertManagementPopUp}
              onClose={closeUserAlertManagement}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
            >
              <DialogContent>
                <UserAlertManagementPopUp
                  user={user}
                  projectId={id as string}
                  projectAlertRules={projectAlertRules}
                  loading={loading}
                  userAlertRules={userAlertRules}
                  updatingOwnSettings={false}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={closeUserAlertManagement}
                  autoFocus
                >
                  {t('close')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </CardContent>
    </Card>
  )
}

// eslint-disable-next-line
function ProjectUsers() {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The auth state.
   */
  const { currentUser } = useAuth()

  /**
   * The parameters.
   */
  const { id } = useParams()

  /**
   * The users state.
   */
  const { loading } = useSelector((state: RootState) => state.users)

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getProjectMembers(parseInt(id)))
      dispatch(getProjectAlertRules(parseInt(id)))
    }
  }, [currentUser, id])

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {loading ? <LoadingState /> : <ProjectMembersDataGrid />}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ProjectUsers
