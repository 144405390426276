import { createSlice } from '@reduxjs/toolkit'
import orderService from '../../services/orderService'
import { Dispatch } from 'redux'
import { Order, OrderQuery } from '../../types'

interface OrdersState {
  orders: Order[]
  order: Order | undefined
  show: boolean
  loading: boolean
}

const initialState: OrdersState = {
  orders: [],
  order: undefined,
  show: false,
  loading: false
}

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload
    },
    setOrder(state, action) {
      state.order = action.payload
    },
    createOrder(state, action) {
      state.orders = [...state.orders, action.payload]
    },
    updateOrder(state, action) {
      state.orders = state.orders
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteOrder(state, action) {
      state.orders = state.orders.filter((u) => u.id !== action.payload)
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setShow(state) {
      state.show = !state.show
    }
  }
})

export const { setOrders, setOrder, createOrder, updateOrder, deleteOrder } =
  orderSlice.actions

export function getOrders(query?: OrderQuery) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(orderSlice.actions.setLoading(true))
      const orders = await orderService.getOrders(query)
      dispatch(orderSlice.actions.setOrders(orders))
    } finally {
      dispatch(orderSlice.actions.setLoading(false))
    }
  }
}

export function getOrder(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(orderSlice.actions.setLoading(true))
      const order = await orderService.getOrder(id)
      dispatch(orderSlice.actions.setOrder(order))
    } finally {
      dispatch(orderSlice.actions.setLoading(false))
    }
  }
}

export default orderSlice.reducer
