import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TroubleshootEvent } from '../../pages/pages/DeviceTroubleshoot'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import TroubleshootProps from './TroubleshootProps'
import {
  getSimStatus,
  getDevice,
  getLastSensor,
  getLatestSensorError
} from '../../services/deviceService'
import { Sensor, SensorError } from '../../types'

interface OnomondoProps extends TroubleshootProps {
  setErrorCode: Dispatch<SetStateAction<number>>
}

export default function Onomondo({
  stateUpdate,
  device,
  setErrorCode
}: OnomondoProps) {
  const [t] = useTranslation('common')
  const [isSimOnline, setIsSimOnline] = useState(device.status)
  const [isFetched, setIsFetched] = useState(false)

  /**
   * Get the latest sensor message.
   * @returns Latest sensor message if latest message found, null otherwise.
   */
  const getLatestSensorMessage = async (): Promise<Sensor | null> => {
    try {
      return await getLastSensor(device.name)
    } catch (error) {
      return null
    }
  }

  /**
   * Function that returns the latest sensorError.
   *
   * @returns the latest sensor error.
   */
  const getLastSensorError = async (): Promise<SensorError | null> => {
    try {
      return await getLatestSensorError(device.name)
    } catch (error) {
      return null
    }
  }

  /**
   * Checks sim status by calling backend API.
   */
  const checkSimStatus = async () => {
    try {
      const newDevice = await getDevice(device.name)
      if (newDevice.status) {
        setIsSimOnline(true)
        return
      }
      const status = await getSimStatus(device.name)
      setIsSimOnline(status.online)
    } catch (error) {
      setIsSimOnline(false)
    }
    setIsFetched(true)
  }

  /**
   * Function that handles if device has error messages.
   * @returns void
   */
  const handleErrorMessages = async () => {
    const sensorError = await getLastSensorError()
    const latestSensor = await getLatestSensorMessage()

    if (sensorError === null) {
      stateUpdate(
        TroubleshootEvent.NoErrorMessages,
        'Device online and no error messages found.'
      )
      return
    }

    if (latestSensor === null) {
      setErrorCode(sensorError.error_code)
      stateUpdate(
        TroubleshootEvent.SomeErrorMessage,
        `Device online, no sensor messages found and error messages found. Latest error code: ${sensorError.error_code}`
      )
      return
    }

    const latestErrorTimestamp = new Date(sensorError.timestamp)
    const latestSensorTimestamp = new Date(latestSensor.time)
    const errorMessageIsNewer = latestErrorTimestamp > latestSensorTimestamp

    if (errorMessageIsNewer) {
      setErrorCode(sensorError.error_code)
      stateUpdate(
        TroubleshootEvent.SomeErrorMessage,
        `Device online, sensor messages found and error messages found. The latest sensor message is older than the latest error message. Latest error code: ${sensorError.error_code}`
      )
      return
    }

    stateUpdate(
      TroubleshootEvent.NoErrorMessages,
      'Device online, sensor messages found, error messages found. The latest sensor message is newer than the latest error message.'
    )
  }

  useEffect(() => {
    checkSimStatus()
  }, [])

  useEffect(() => {
    if (!isSimOnline) {
      return
    }

    handleErrorMessages().catch((error) => {
      stateUpdate(
        TroubleshootEvent.NoErrorMessages,
        `No error messages found since an error happened while fetching error messages: ${error}`
      )
    })
  }, [isSimOnline])

  return (
    <>
      {isSimOnline || !isFetched ? (
        // Nothing to display if the sim is online
        // or if the status hasn't yet been fetched.
        <></>
      ) : (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            {t('troubleshoot.deviceOfflineHeader')}
          </Typography>

          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t('troubleshoot.deviceOfflineBody')}
          </Typography>

          <Typography align="left" sx={{ mr: 2, mt: 2 }}>
            <Button onClick={checkSimStatus} variant="contained">
              {t('troubleshoot.tryAgain')}
            </Button>
          </Typography>
        </>
      )}
    </>
  )
}
