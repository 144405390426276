import { ProjectType } from '../enums/ProjectType'

export default function translateType(type: string) {
  switch (type) {
    case ProjectType.KERROSTALO:
      return 'Project.block'
    case ProjectType.OMAKOTITALO:
      return 'Project.detached'
    case ProjectType.RIVITALO:
      return 'Project.terraced'
    case ProjectType.LIIKETILA:
      return 'Project.business'
    case ProjectType.INFRA:
      return 'Project.infra'
    case ProjectType.SAIRAALA:
      return 'Project.hospital'
    case ProjectType.HALLI:
      return 'Project.warehouse'
    case ProjectType.MUU:
      return 'Project.other'
  }
}
