import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
  Chip,
  Checkbox
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { deleteProductState } from '../../redux/slices/products'
import { Delete } from '@mui/icons-material'
import { ProductStatus } from '../../enums/ProductStatus'

import { OrderItem, Product } from '../../types'
import translateProductOrOrderItemType from '../../utils/translateProductOrOrderItemType'
import DispatchProduct from './DispatchProduct'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import BuildIcon from '@mui/icons-material/Build'
import DeviceSensorDataTestForm from './DeviceSensorDataTestForm'
import productService from '../../services/productService'
import { createUserNotification } from '../../utils/createUserNotification'
import { useAuth } from '../../hooks'
import { ProductErrors } from '../../enums/ProductErrors'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'

const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

interface ProductTabelProps {
  orderItem?: OrderItem
  products: Product[]
  readyForDispatch: boolean
  dispatchProductFormOpen(showDispatchForm: boolean): void
  deviceSensorDataTestFormOpen(showTestDeviceSensorDataForm: boolean): void
}

export default function ProductTable({
  orderItem,
  products,
  readyForDispatch,
  dispatchProductFormOpen,
  deviceSensorDataTestFormOpen
}: ProductTabelProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()

  /**
   * Handle delete product
   * @param id
   */
  async function handleDelete(id: number) {
    try {
      await productService.deleteProduct(id)
      dispatch(deleteProductState(id))
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  /**
   * Open delete dialog
   */
  const [open, setOpen] = useState(false)

  /**
   * Product id
   */
  const [productId, setProductId] = useState<number | undefined>(undefined)

  /**
   * Handle open delete dialog
   * @param id
   */
  const handleClickOpen = (id: number) => {
    setProductId(id)
    setOpen(true)
  }

  /**
   * Handle close delete dialog
   */
  const handleClose = () => {
    setOpen(false)
    setProductId(undefined)
  }

  /**
   * Function for translating product's status
   * @param status
   */
  const translateProductStatus = (status: string) => {
    switch (status) {
      case ProductStatus.PENDING:
        return t('Manufacturing.pending')
      case ProductStatus.ASSEMBLED:
        return t('Manufacturing.assembled')
      case ProductStatus.PREPARED:
        return t('Manufacturing.prepared')
      case ProductStatus.TESTED:
        return t('tested')
    }
  }

  /**
   * Show DispatchForm
   */
  const [showDispatchForm, setShowDispatchForm] = useState<boolean>(false)

  /**
   * dispatch multiple products
   */
  const [dispatchMultipleProducts, setDispatchMultipleProducts] =
    useState<boolean>(false)

  /**
   * Selected products
   */
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  /**
   * Selected product
   */
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(
    undefined
  )

  /**
   * Show TestDeviceSensorDataForm.
   */
  const [showTestDeviceSensorDataForm, setShowTestDeviceSensorDataForm] =
    useState<boolean>(false)

  /**
   * Function for Checkbox handling
   * @param event
   * @param product
   */
  const handleSelectProductForDispatch = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: Product
  ) => {
    if (event.target.checked) {
      setSelectedProducts([...selectedProducts, product])
    } else {
      const newProduct = selectedProducts.filter(
        (existingProduct: Product) => existingProduct !== product
      )
      setSelectedProducts(newProduct)
    }
  }

  /**
   *Function for opening DispatchProductForm
   * @param product
   */
  const handleOpenDispatchProductsForm = (product?: Product) => {
    if (product) {
      setSelectedProducts([product])
    }
    setShowDispatchForm(true)
    dispatchProductFormOpen(true)
  }

  /**
   *Function for closing DispatchProductForm
   */
  const handleCloseProductDispatch = () => {
    setSelectedProducts([])
    setShowDispatchForm(false)
    setDispatchMultipleProducts(false)
    dispatchProductFormOpen(false)
  }

  /**
   *Function for opening DeviceSensorDataTestForm
   * @param product
   */
  const handleOpenDeviceSensorDataTestForm = (product: Product) => {
    setSelectedProduct(product)
    setShowTestDeviceSensorDataForm(true)
    deviceSensorDataTestFormOpen(true)
  }

  /**
   *Function for closing DeviceSensorDataTestForm
   */
  const handleCloseDeviceSensorDataTest = () => {
    setSelectedProduct(undefined)
    setShowTestDeviceSensorDataForm(false)
    deviceSensorDataTestFormOpen(false)
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Paper>
            {showDispatchForm ? (
              <React.Fragment>
                {/*Show Form for dispatching products */}
                <Typography align="right">
                  <Button
                    variant="contained"
                    onClick={() => handleCloseProductDispatch()}
                  >
                    {t('cancel')}
                  </Button>
                </Typography>

                <DispatchProduct
                  products={selectedProducts}
                  orderItem={orderItem}
                  showForm={() => {
                    handleCloseProductDispatch()
                  }}
                />
              </React.Fragment>
            ) : showTestDeviceSensorDataForm ? (
              <React.Fragment>
                {/*Show Device SensorData test form*/}
                <Typography align="right">
                  <Button
                    variant="contained"
                    onClick={() => handleCloseDeviceSensorDataTest()}
                  >
                    {t('cancel')}
                  </Button>
                </Typography>

                <DeviceSensorDataTestForm
                  product={selectedProduct as Product}
                  showForm={() => {
                    handleCloseDeviceSensorDataTest()
                  }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/*Show "Dispatch multiple products" button
                 if products are ready to be tested or dispatched*/}
                {readyForDispatch ? (
                  <React.Fragment>
                    <Grid container spacing={6}>
                      <Grid item xs={6}>
                        <Typography fontSize={'1.5em'}>
                          {t('doneProducts')}
                        </Typography>
                      </Grid>
                      {dispatchMultipleProducts ? (
                        <Grid item xs={6}>
                          <Typography align="right">
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleCloseProductDispatch()}
                            >
                              {t('cancel')}
                            </Button>
                            <Button
                              color="success"
                              disabled={selectedProducts.length === 0 ?? false}
                              sx={{ ml: 2 }}
                              variant="outlined"
                              onClick={() => handleOpenDispatchProductsForm()}
                            >
                              {t('Send')}
                            </Button>
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={6}>
                          <Typography align="right">
                            <Button
                              variant="outlined"
                              onClick={() => setDispatchMultipleProducts(true)}
                            >
                              {t('sendMultiple')} <LocalShippingIcon />
                            </Button>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </React.Fragment>
                ) : (
                  /*Typography for pending products*/
                  <Typography fontSize={'1.5em'}>
                    {t('pendingProducts')}
                  </Typography>
                )}
                <Divider my={6} />

                {/*Product table*/}
                <TableWrapper>
                  <Table>
                    {products && products.length > 0 ? (
                      <TableBody>
                        {products.map((product: Product) => (
                          <TableRow>
                            {product && product.orderItem && (
                              <React.Fragment>
                                <TableCell>
                                  {t(
                                    translateProductOrOrderItemType(
                                      product.orderItem.type
                                    ) as string
                                  )}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    size="small"
                                    color={
                                      product.status === ProductStatus.TESTED
                                        ? 'success'
                                        : 'warning'
                                    }
                                    label={translateProductStatus(
                                      product.status
                                    )}
                                  />
                                </TableCell>
                              </React.Fragment>
                            )}

                            {/*Products ready for dispatch should have device relation*/}
                            {readyForDispatch && (
                              <TableCell>
                                {t('deviceCode')}:{' '}
                                {product?.device?.shortUUID ?? '-'}
                              </TableCell>
                            )}

                            {readyForDispatch === false && (
                              <TableCell align="right">
                                {/*Delete product button that open delete dialog*/}
                                <Button>
                                  <Delete
                                    color="error"
                                    onClick={() => handleClickOpen(product.id)}
                                  >
                                    {t('Manufacturing.delete')}
                                  </Delete>
                                </Button>
                              </TableCell>
                            )}
                            <TableCell align="right">
                              <React.Fragment>
                                {readyForDispatch ? (
                                  <React.Fragment>
                                    {product.status === ProductStatus.TESTED ? (
                                      <React.Fragment>
                                        {/*Show Checkboxes so user can select and dispatch multiple products*/}
                                        {dispatchMultipleProducts ? (
                                          <Checkbox
                                            onChange={(event) =>
                                              handleSelectProductForDispatch(
                                                event,
                                                product
                                              )
                                            }
                                          />
                                        ) : (
                                          /*Dispatch one product*/
                                          <Button
                                            onClick={() =>
                                              handleOpenDispatchProductsForm(
                                                product
                                              )
                                            }
                                          >
                                            <LocalShippingIcon />
                                          </Button>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      /*Open test device sensorData form*/
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() =>
                                          handleOpenDeviceSensorDataTestForm(
                                            product
                                          )
                                        }
                                      >
                                        {t('test')}
                                      </Button>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  /*Navigate to installation page*/
                                  <Button
                                    onClick={() =>
                                      navigate(
                                        `/manufacturing/orders/${product.id}/install`
                                      )
                                    }
                                  >
                                    <BuildIcon />
                                  </Button>
                                )}
                              </React.Fragment>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      /*Products not found*/
                      <React.Fragment>
                        {readyForDispatch ? (
                          <Typography align="center">
                            {t('noProductsReadyForDispatch')}
                          </Typography>
                        ) : (
                          <Typography align="center" sx={{ mt: 4 }}>
                            {t('noPendingProducts')}
                          </Typography>
                        )}
                      </React.Fragment>
                    )}
                  </Table>
                </TableWrapper>
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/*Dialog for deleting product*/}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Manufacturing.confirmProduct')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Manufacturing.warning')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleDelete(productId as number)}>
            {t('Manufacturing.yes')}
          </Button>
          <Button onClick={handleClose} autoFocus>
            {t('Manufacturing.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
