import { useDispatch } from 'react-redux'
import { PowerSupply } from '../types'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../hooks'
import {
  deletePowerSupply,
  getPowerSupply,
  setPowerSupply,
  setShow
} from '../redux/slices/powerSupplies'
import { updateProduct } from '../services/productService'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { ProductErrors } from '../enums/ProductErrors'
import { useState } from 'react'
import React from 'react'
import {
  Link,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  CardHeader,
  IconButton,
  Tooltip
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import PowerSupplyForm from './PowerSupplyForm'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { Add, Delete, Edit } from '@mui/icons-material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { ProductStatus } from '../enums/ProductStatus'
import powerSupplyService from '../services/powerSupplyService'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)

export default function PowerSupplyTable({
  powerSupplies,
  powerSupply,
  show,
  onUpdate
}: {
  powerSupplies: PowerSupply[]
  powerSupply: PowerSupply | undefined
  show: boolean
  onUpdate(refresh: boolean): void
}) {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()

  const handleShow = (payload: boolean) => {
    dispatch(setShow(payload))

    if (powerSupply !== undefined) {
      dispatch(setPowerSupply(undefined))
    }
  }

  const handleEdit = (id: number) => {
    dispatch(getPowerSupply(id))
    dispatch(setShow(true))
  }

  const handleDelete = async (id: number) => {
    await powerSupplyService.deletePowerSupply(id)
    dispatch(deletePowerSupply(id))
  }

  async function handleDetachFromProduct(powerSupply: PowerSupply) {
    try {
      await updateProduct(powerSupply.device?.product?.id as number, {
        deviceName: null,
        status: ProductStatus.END_OF_LIFE
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('powerSupplyDetachSuccess')
        )
      )
      onUpdate(true)
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage?.key) + t(errorMessage?.message)
        )
      )
    } finally {
      handleClose()
      onUpdate(false)
    }
  }

  const [open, setOpen] = useState(false)
  const [powerSupplyForDetaching, setPowerSupplyForDetaching] = useState<
    PowerSupply | undefined
  >(undefined)

  const handleClickOpen = (powerSupply: PowerSupply) => {
    setPowerSupplyForDetaching(powerSupply)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setPowerSupplyForDetaching(undefined)
  }

  const columns: GridColDef<PowerSupply>[] = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      width: 200,
      editable: false
    },
    {
      field: 'device.name',
      headerName: t('deviceCode'),
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params) => params.row.device?.name ?? '-',
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.device ? (
              <Link
                component={NavLink}
                to={`/devices/${params.row.device?.name}`}
              >
                {params.row.device?.name}
              </Link>
            ) : (
              '-'
            )}
          </React.Fragment>
        )
      }
    },
    {
      field: 'fuse',
      headerName: t('fuse') + '(A)',
      flex: 1,
      minWidth: 200,
      editable: false
    },
    {
      field: 'inputCables',
      headerName: t('inputCables'),
      flex: 1,
      minWidth: 200,
      editable: false
    },
    {
      field: 'device.asset.company.name',
      headerName: t('owner'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params) => params.row.device?.asset?.company?.name ?? '-'
    },
    {
      field: 'device.asset.project.name',
      headerName: t('project'),
      type: 'string',
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: (params) => params.row.device?.asset?.project?.name ?? '-',
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.device?.asset?.project ? (
              <Link
                component={NavLink}
                to={`/projects/${params.row.device.asset.project.id}`}
              >
                {params.row.device.asset.project.name}
              </Link>
            ) : (
              '-'
            )}
          </React.Fragment>
        )
      }
    },
    {
      field: 'device.installer',
      headerName: t('installer'),
      type: 'string',
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: (params) =>
        params.row.device?.installer
          ? `${params.row.device.installer.firstName} ${params.row.device.installer.lastName}`
          : '-'
    },
    {
      field: t('product'),
      headerName: '',
      type: 'string',
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.device?.product ? (
              <Button
                variant="contained"
                color="error"
                onClick={() => handleClickOpen(params.row)}
              >
                {t('detachFromOrder')}
              </Button>
            ) : (
              ''
            )}
          </>
        )
      }
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={3}>
              <Tooltip title={t('editPowerSupply') as string}>
                <IconButton
                  size="small"
                  onClick={() => handleEdit(params.row.id)}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('deletePowerSupply') as string}>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(params.row.id)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          </React.Fragment>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  const title =
    show && powerSupply
      ? t('editPowerSupply')
      : show
      ? t('createNewPowerSupply')
      : t('powerSupplies')

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          !show && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<Add />}
              onClick={() => handleShow(true)}
            >
              {t('newPowerSupply')}
            </Button>
          )
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('confirmDetachingTitle')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('confirmDetachingInfo')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() =>
              handleDetachFromProduct(powerSupplyForDetaching as PowerSupply)
            }
          >
            {t('yes')}
          </Button>
          <Button onClick={handleClose} autoFocus>
            {t('no')}
          </Button>
        </DialogActions>
      </Dialog>
      <CardContent>
        {show ? (
          <PowerSupplyForm />
        ) : (
          <DataGrid
            rows={powerSupplies}
            columns={columns}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 }
              }
            }}
            sx={{
              border: 0
            }}
          />
        )}
      </CardContent>
    </Card>
  )
}
