import { AxiosRequestConfig } from 'axios'
import { useApi } from '../hooks'
import {
  RuuviTag,
  RuuviTagCondition,
  RuuviTagConditionQuery,
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateQuery,
  RuuviTagAccelerationQuery,
  RuuviTagAcceleration,
  RuuviTagAccelerationAggregateQuery,
  RuuviTagAccelerationAggregate,
  RuuviTagBatteryQuery,
  RuuviTagBattery,
  RuuviTagBatteryAggregateQuery,
  RuuviTagBatteryAggregate,
  RuuviTagMovement,
  RuuviTagMovementQuery,
  RuuviTagInput,
  RuuviTagQuery,
  PaginationQuery
} from '../types'
import PaginationOutput from '../types/PaginationOutput'

const api = useApi('v2')

export async function getRuuviTags(
  query: RuuviTagQuery = {},
  config: AxiosRequestConfig = {}
): Promise<RuuviTag[]> {
  const response = await api.get('/ruuvi-tags', {
    ...config,
    params: {
      ...query
    }
  })

  return response.data
}

export async function getRuuviTagsUsingPagination(
  query: RuuviTagQuery = {},
  pagination: PaginationQuery,
  config: AxiosRequestConfig = {}
): Promise<PaginationOutput<RuuviTag>> {
  const response = await api.get('/ruuvi-tags', {
    ...config,
    params: {
      ...query,
      ...pagination
    }
  })

  const page = parseInt(response.headers['x-page'])
  const pageSize = parseInt(response.headers['x-page-size'])
  const pageCount = parseInt(response.headers['x-page-count'])
  const totalCount = parseInt(response.headers['x-total-count'])

  return {
    page,
    pageSize,
    pageCount,
    totalCount,
    data: response.data
  }
}

export async function getRuuviTag(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<RuuviTag> {
  const response = await api.get(`/ruuvi-tags/${id}`, {
    ...config
  })

  return response.data
}

export async function createOrUpdateRuuviTag(
  input: RuuviTagInput,
  config: AxiosRequestConfig = {}
): Promise<RuuviTag> {
  const response = await api.post('/ruuvi-tags', input, config)

  return response.data
}

export async function updateRuuviTag(
  id: string,
  input: RuuviTagInput,
  config: AxiosRequestConfig = {}
): Promise<RuuviTag> {
  const response = await api.patch(`/ruuvi-tags/${id}`, input, config)

  return response.data
}

export async function getRuuviTagAccelerations(
  id: string,
  query: RuuviTagAccelerationQuery,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagAcceleration[]> {
  const response = await api.get(`/ruuvi-tags/${id}/accelerations`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagAccelerationNow(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagAcceleration> {
  const response = await api.get(`/ruuvi-tags/${id}/accelerations/now`, {
    ...config
  })

  return response.data
}

export async function getRuuviTagAccelerationAggregates(
  id: string,
  query: RuuviTagAccelerationAggregateQuery,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagAccelerationAggregate[]> {
  const response = await api.get(`/ruuvi-tags/${id}/acceleration-aggregates`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagBatteries(
  id: string,
  query: RuuviTagBatteryQuery,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagBattery[]> {
  const response = await api.get(`/ruuvi-tags/${id}/batteries`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagBatteryNow(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagBattery> {
  const response = await api.get(`/ruuvi-tags/${id}/batteries/now`, {
    ...config
  })

  return response.data
}

export async function getRuuviTagBatteryAggregates(
  id: string,
  query: RuuviTagBatteryAggregateQuery,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagBatteryAggregate[]> {
  const response = await api.get(`/ruuvi-tags/${id}/battery-aggregates`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagConditions(
  id: string,
  query: RuuviTagConditionQuery,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagCondition[]> {
  const response = await api.get(`/ruuvi-tags/${id}/conditions`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagConditionNow(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagCondition> {
  const response = await api.get(`/ruuvi-tags/${id}/conditions/now`, {
    ...config
  })

  return response.data
}

export async function getRuuviTagConditionAggregates(
  id: string,
  query: RuuviTagConditionAggregateQuery,
  config: AxiosRequestConfig
): Promise<RuuviTagConditionAggregate[]> {
  const response = await api.get(`/ruuvi-tags/${id}/condition-aggregates`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagMovements(
  id: string,
  query: RuuviTagMovementQuery,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagMovement[]> {
  const response = await api.get(`/ruuvi-tags/${id}/movements`, {
    params: query,
    ...config
  })

  return response.data
}

export async function getRuuviTagMovementNow(
  id: string,
  config: AxiosRequestConfig = {}
): Promise<RuuviTagMovement> {
  const response = await api.get(`/ruuvi-tags/${id}/movements/now`, {
    ...config
  })

  return response.data
}
