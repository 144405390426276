import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import { createNotification } from './notifications'
import { Level } from '../../types'
import levelService from '../../services/levelService'

interface LevelsState {
  levels: Level[]
  level: Level | undefined
  show: boolean
  loading: boolean
}

const initialState: LevelsState = {
  levels: [],
  level: undefined,
  show: false,
  loading: false
}

const levelSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {
    setLevels(state, action) {
      state.levels = action.payload
    },
    setLevel(state, action) {
      state.level = action.payload
    },
    createLevel(state, action) {
      state.levels = [...state.levels, action.payload].sort(
        (a, b) => b.number - a.number
      )
    },
    updateLevel(state, action) {
      state.levels = state.levels
        .map((level) =>
          level.id === action.payload.id ? action.payload : level
        )
        .sort((a, b) => b.number - a.number)
    },
    deleteLevel(state, action) {
      state.levels = state.levels.filter((level) => level.id !== action.payload)
    },
    showLevel(state, action) {
      state.level = action.payload
      state.show = true
    },
    hideLevel(state) {
      state.show = false
      state.level = undefined
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const { createLevel, updateLevel } = levelSlice.actions

export function getLevels() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(levelSlice.actions.setLoading(true))
      const levels = await levelService.getLevels()
      dispatch(levelSlice.actions.setLevels(levels))
    } finally {
      dispatch(levelSlice.actions.setLoading(false))
    }
  }
}

export function getLevelsByProjectId(projectId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(levelSlice.actions.setLoading(true))
      const levels = await levelService.getLevelsByProjectId(projectId)
      dispatch(levelSlice.actions.setLevels(levels))
    } finally {
      dispatch(levelSlice.actions.setLoading(false))
    }
  }
}

export function getLevel(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(levelSlice.actions.setLoading(true))
      const level = await levelService.getLevel(id)
      dispatch(levelSlice.actions.setLevel(level))
    } finally {
      dispatch(levelSlice.actions.setLoading(false))
    }
  }
}

export function deleteLevel(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      await levelService.deleteLevel(id)
      dispatch(levelSlice.actions.deleteLevel(id))
      // FIXME: Translate notification message.
      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Level deleted succesfully',
          timeout: 5000
        })
      )
    } catch (error: any) {
      // FIXME: Translate notification message.
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong',
          timeout: 0
        })
      )
    }
  }
}

export function showLevel(level?: Level) {
  return async (dispatch: Dispatch) => {
    dispatch(levelSlice.actions.showLevel(level))
  }
}

export function hideLevel() {
  return async (dispatch: Dispatch) => {
    dispatch(levelSlice.actions.hideLevel())
  }
}

export default levelSlice.reducer
