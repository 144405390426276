import { useEffect, useState, MouseEvent } from 'react'
import {
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  Button as MuiButton,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

export interface SensorAggregateStatisticToggleProps {
  value: SensorAggregateStatistic[]
  onChange(value: SensorAggregateStatistic[]): void
}

export default function SensorAggregateStatisticToggle({
  value,
  onChange
}: SensorAggregateStatisticToggleProps) {
  const [t] = useTranslation('common')

  function handleChange(
    event: MouseEvent<HTMLElement>,
    newValue: SensorAggregateStatistic[]
  ): void {
    onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      aria-label={t('SensorAggregate.statistics')}
      onChange={handleChange}
    >
      <ToggleButton value={SensorAggregateStatistic.MIN}>
        {t('SensorAggregate.min')}
      </ToggleButton>
      <ToggleButton value={SensorAggregateStatistic.AVG}>
        {t('SensorAggregate.avg')}
      </ToggleButton>
      <ToggleButton value={SensorAggregateStatistic.MAX}>
        {t('SensorAggregate.max')}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
