import { useState } from 'react'

import React from 'react'
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper as MuiPaper,
  Card as MuiCard,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Stack
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { deleteUpload, getUpload, getUploads } from '../../redux/slices/uploads'
import UploadType from '../../enums/UploadType'
import { Upload } from '../../types/Upload'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import DeleteIcon from '@mui/icons-material/Delete'
import useUploads from '../../hooks/useUploads'

const Card = styled(MuiCard)(spacing)
const Paper = styled(MuiPaper)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 200px;
`

export interface UploadTableProps {
  uploadType: UploadType
}

export default function UploadTable({ uploadType }: UploadTableProps) {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')
  const [clearFilter, setClearFilter] = useState(false)
  const [open, setOpen] = useState(false)
  const [uploadId, setUploadId] = useState('')
  const [t] = useTranslation('common')
  const uploads: Upload[] | undefined = useUploads()
  const handleFiltering = (input: string) => {
    dispatch(getUploads({ type: uploadType, name: input }))
    setClearFilter(true)
  }

  const handleClearFiltering = () => {
    dispatch(getUploads({ type: uploadType, name: uploadType }))
    setClearFilter(false)
  }

  const handleDelete = (id: string) => {
    dispatch(deleteUpload(id))
    handleClose()
  }

  const handleClickOpen = (id: string) => {
    setUploadId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setUploadId('')
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <Grid container spacing={6}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <TextField
                  name="insturctions"
                  label={t('Manufacturing.search')}
                  fullWidth
                  variant="outlined"
                  size="small"
                  my={2}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <IconButton
                  size="small"
                  sx={{ mt: 2, ml: -4 }}
                  onClick={() => handleFiltering(filter)}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
              {clearFilter === true && (
                <Grid item xs={1} sm={1} md={1} lg={1}>
                  <IconButton
                    size="small"
                    sx={{ mt: 2, ml: -4 }}
                    onClick={() => handleClearFiltering()}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Paper>
              {uploads && (
                <TableWrapper>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Manufacturing.name')}</TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploads.map((row: Upload) => (
                        <TableRow key={row?.id}>
                          <TableCell>
                            {row.name.split(`${uploadType}/`)[1]}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              justifyContent="flex-end"
                              flexGrow={0}
                              spacing={3}
                            >
                              <Button
                                size="small"
                                onClick={() => dispatch(getUpload(row.id))}
                              >
                                <FileOpenIcon />
                              </Button>
                              <Button
                                size="small"
                                color="error"
                                onClick={() => handleClickOpen(row.id)}
                              >
                                <DeleteIcon />
                              </Button>
                            </Stack>
                          </TableCell>

                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {t('Manufacturing.confirmDeleteUpload')}
                            </DialogTitle>

                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {t('Manufacturing.warning')}
                              </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                              <Button onClick={() => handleDelete(uploadId)}>
                                {t('Manufacturing.yes')}
                              </Button>
                              <Button onClick={handleClose} autoFocus>
                                {t('Manufacturing.no')}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableWrapper>
              )}
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
