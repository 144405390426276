import React, { MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { getMyProjects } from '../../redux/slices/projects'
import useAuth from '../../hooks/useAuth'

import {
  Card as MuiCard,
  CardHeader,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Button as MuiButton,
  Typography,
  Box,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Stack,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import {
  getProject,
  getProjects,
  setShow,
  setProject
} from '../../redux/slices/projects'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { UserRole } from '../../enums/UserRole'
import 'mapbox-gl/dist/mapbox-gl.css'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import ProjectForm from '../../components/ProjectForm'
import ProjectsMap from '../../components/ProjectsMap'
import InfoIcon from '@mui/icons-material/Info'
import LoadingState from '../../components/LoadingState'
import { Project } from '../../types'
import ProjectArchiveTable from '../../components/ProjectArchiveTable'
import { getProjectsByCompanyId } from '../../services/projectService'
import { ProjectReducerType } from '../../enums/ProjectReducerType'
import ProjectDataGrid from '../../components/ProjectDataGrid'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)

type ProjectsTab = 'projects' | 'map' | 'archive'

export default function Projects(): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  const { projects, project, show, loading } = useSelector(
    (state: RootState) => state.projects
  )

  /**
   * The option to view data per square meters.
   */
  const [perSquareMeters, setPerSquareMeters] = useState<boolean>(false)

  /**
   * The selected tab.
   */
  const [tab, setTab] = useState<ProjectsTab>(
    (window.location.hash.split('#')[1] as ProjectsTab) ?? 'projects'
  )

  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(getProjects({ showDeleted: true }))
      } else if (currentUser.role === UserRole.ADMIN) {
        dispatch(getMyProjects(ProjectReducerType.PROJECTS))
        loadCompanyProjects()
      } else {
        dispatch(getMyProjects(ProjectReducerType.PROJECTS))
      }
    }
  }, [currentUser])

  const [companyProjects, setCompanyProjects] = useState<Project[]>([])
  async function loadCompanyProjects(): Promise<void> {
    try {
      setIsLoadingProjects(true)
      setCompanyProjects(
        await getProjectsByCompanyId(currentUser?.company.id, true)
      )
    } finally {
      setIsLoadingProjects(false)
    }
  }

  const handleChange = (
    event: React.SyntheticEvent,
    tab: ProjectsTab
  ): void => {
    setTab(tab)
    window.history.replaceState({}, '', `#{tab}`)
  }

  const activeProjects = projects.filter((item) => item.deletedAt === null)
  const deletedProjects =
    currentUser?.role === UserRole.SUPERADMIN
      ? projects.filter((item) => item.deletedAt !== null)
      : companyProjects?.filter((item) => item.deletedAt !== null)

  const handleShow = (payload: boolean) => {
    dispatch(setShow(payload))

    if (project !== undefined) {
      dispatch(setProject(undefined))
    }
  }

  const handleClickOpenInfo = () => {
    setOpenInfo(true)
  }

  const handleCloseInfo = () => {
    setOpenInfo(false)
  }

  const [openInfo, setOpenInfo] = useState(false)

  /**
   * Hadle the change to the per square meters option
   *
   * @param event - The mouse event.
   * @param value - The changed value.
   */
  function handleChangePerSquareMeters(
    event: MouseEvent<HTMLElement>,
    value: boolean
  ): void {
    setPerSquareMeters(value)
  }

  function handleSetProject(id: number): void {
    dispatch(getProject(id))
  }

  return (
    <React.Fragment>
      <Helmet title={t('Projects.projects')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('Projects.projects')}
      </Typography>

      <Divider mt={6} />

      {loading || isLoadingProjects ? (
        <LoadingState />
      ) : (
        <Box sx={{ width: '100%' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab label={t('projects')} value="projects" />

                <Tab label={t('map')} value="map" />

                {(currentUser?.role === UserRole.SUPERADMIN ||
                  currentUser?.role === UserRole.ADMIN) && (
                  <Tab label={t('deletedProjects')} value="archive" />
                )}
              </TabList>
            </Box>

            <Box mt={6}>
              <TabPanel value="projects">
                <Card>
                  <CardHeader
                    title={t('Projects.projects') as string}
                    action={
                      <Box>
                        {currentUser?.role === UserRole.GUEST ||
                        currentUser?.company === null ? (
                          <Stack direction="row" alignItems="center" gap={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled
                              onClick={() =>
                                show ? handleShow(false) : handleShow(true)
                              }
                            >
                              {show ? t('Projects.cancel') : t('Projects.add')}
                              {!show && <Add />}
                            </Button>
                            <IconButton
                              size="small"
                              onClick={() => handleClickOpenInfo()}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Stack>
                        ) : (
                          <Stack direction="row" gap={3}>
                            <ToggleButtonGroup
                              color="primary"
                              size="small"
                              exclusive
                              value={perSquareMeters}
                              onChange={handleChangePerSquareMeters}
                            >
                              <ToggleButton value={false}>
                                {t('total')}
                              </ToggleButton>
                              <ToggleButton value={true}>
                                {t('perSquareMeters')}
                              </ToggleButton>
                            </ToggleButtonGroup>

                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              endIcon={<Add />}
                              onClick={() => handleShow(true)}
                            >
                              {t('newProject')}
                            </Button>
                          </Stack>
                        )}
                      </Box>
                    }
                  />

                  <Dialog
                    open={show}
                    onClose={() => handleShow(false)}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogTitle>
                      {project ? t('editProject') : t('createNewProject')}
                    </DialogTitle>
                    <DialogContent>
                      <ProjectForm />
                    </DialogContent>
                  </Dialog>

                  <Dialog
                    open={openInfo}
                    onClose={handleCloseInfo}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {t('Projects.createProjectTitle')}
                    </DialogTitle>

                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t('Projects.createProjectInfo')}
                      </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                      <Button
                        variant="contained"
                        onClick={handleCloseInfo}
                        autoFocus
                      >
                        {t('Projects.understood')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <CardContent
                    pb={1}
                    sx={{ mb: 2 }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <ProjectDataGrid
                      projects={activeProjects}
                      perSquareMeters={perSquareMeters}
                    />
                  </CardContent>
                </Card>
              </TabPanel>

              <TabPanel value="map">
                <ProjectsMap />
              </TabPanel>

              <TabPanel value="archive">
                <ProjectArchiveTable projects={deletedProjects} />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      )}
    </React.Fragment>
  )
}
