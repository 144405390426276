import Draggable from 'react-draggable'
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
import {
  Button,
  Grid,
  Typography,
  Divider as MuiDivider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow,
  Slider,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import { updateCoordinates } from '../services/uploadService'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Box, spacing } from '@mui/system'
import { deleteError, setError } from '../redux/slices/errors'
import { UploadErrors } from '../enums/UploadErrors'
import { createUserNotification } from '../utils/createUserNotification'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { useAuth } from '../hooks'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { UploadWithLink } from '../types/Upload'
import BlueprintAssetContainer from './BlueprintAssetContainer'
import { RootState } from '../redux/store'
import { UserRole } from '../enums/UserRole'
import ProjectMembershipRole from '../enums/ProjectMembershipRole'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import { PowerSupply, RuuviTag } from '../types'
import LoadingState from './LoadingState'

interface CanvasProps {
  upload: UploadWithLink
  powerSupplies: PowerSupply[] | undefined
  ruuviTags?: RuuviTag[]
}

type Meta = {
  powerSupply?: number
  ruuviTag?: string
  x: number
  y: number
}

export enum BlueprintAssetType {
  POWERSUPPLY = 'powerSupply',
  RUUVITAG = 'RuuviTag'
}
const listOfCoordinates: Meta[] = []

const Divider = styled(MuiDivider)(spacing)

export function BlueprintCanvas({
  upload,
  powerSupplies,
  ruuviTags
}: CanvasProps) {
  const { currentUser } = useAuth()
  const [t] = useTranslation('common')
  const [isDragging, setIsDragging] = useState(false)
  const [draggedAsset, setDraggedAsset] = useState<number | string>()
  const ref = useRef(null)
  const [scale, setScale] = useState<number>(1)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [isLoaded, setIsloaded] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.only('md'))
  const isLarge = useMediaQuery(theme.breakpoints.only('lg'))
  const [isEditing, setIsEditing] = useState(false)
  const { membership } = useSelector(
    (state: RootState) => state.projectMemberships
  )
  const [openBlueprintInfo, setOpenBlueprintInfo] = useState(false)
  const [uploadState, setUploadState] = useState<UploadWithLink>(upload)

  function handleLoad() {
    // @ts-ignore
    setWidth(ref.current.width)
    // @ts-ignore
    setHeight(ref.current.height)
    setIsloaded(true)
  }

  function handleDragStart(id: number | string) {
    setDraggedAsset(id)
    setIsDragging(true)
  }
  //TODO: enum
  const handleDragStop = (e: any, data: any, type: BlueprintAssetType) => {
    const target = listOfCoordinates.find((obj: Meta) =>
      type === BlueprintAssetType.POWERSUPPLY
        ? obj.powerSupply === draggedAsset
        : obj.ruuviTag === draggedAsset
    )

    const scaledCoordinates = scaleCoordinates(data.x, data.y)
    if (type === BlueprintAssetType.POWERSUPPLY) {
      target
        ? Object.assign(target, {
            powerSupply: draggedAsset,
            x: scaledCoordinates.x,
            y: scaledCoordinates.y
          })
        : listOfCoordinates.push({
            powerSupply: draggedAsset as number,
            x: scaledCoordinates.x,
            y: scaledCoordinates.y
          })
    } else if (type === BlueprintAssetType.RUUVITAG) {
      target
        ? Object.assign(target, {
            ruuviTag: draggedAsset,
            x: scaledCoordinates.x,
            y: scaledCoordinates.y
          })
        : listOfCoordinates.push({
            ruuviTag: draggedAsset as string,
            x: scaledCoordinates.x,
            y: scaledCoordinates.y
          })
    }

    setIsDragging(false)
  }

  const scaleCoordinates = (x: number, y: number) => {
    const newX = x / width
    const newY = y / height
    return { x: newX, y: newY }
  }
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  async function handleSubmit() {
    try {
      setIsSubmitting(true)
      const updatedUpload = await updateCoordinates(uploadState.blueprint.id, {
        listOfCoordinates,
        sizeSetting
      })

      setUploadState(updatedUpload)

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('uploadLocationUpdateSuccess')
        )
      )
    } catch (error) {
      dispatch(
        setError({
          type: UploadErrors.UPDATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UploadErrors.UPDATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(UploadErrors.UPDATE))
    } finally {
      setIsSubmitting(false)
      setIsEditing(false)
    }
  }

  function handleScale(ref: ReactZoomPanPinchRef) {
    setScale(ref.state.scale)
  }

  //Check if coordinates exists for powerSupply and scale them by image size
  function checkCoordinates(id: number | string, type: BlueprintAssetType) {
    if (upload !== undefined) {
      const target = uploadState.blueprint?.uploadMeta.find((obj: Meta) =>
        type === BlueprintAssetType.POWERSUPPLY
          ? obj.powerSupply === id
          : obj.ruuviTag === id
      )
      let newCoordinates
      target
        ? (newCoordinates = { x: target.x * width, y: target.y * height })
        : (newCoordinates = { x: width / 2, y: 0 })

      return newCoordinates
    }
  }

  //Reload page if window is resized. Coordinates are calculated by the new image size
  function ReloadPage() {
    window.location.reload()
  }
  window.addEventListener('resize', ReloadPage)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleResize = (event: any) => {
    setSizeSetting(event.target.value)
  }

  const userCanManageUpload: boolean =
    currentUser?.role === UserRole.SUPERADMIN ||
    membership?.role === ProjectMembershipRole.ADMIN ||
    membership?.role === ProjectMembershipRole.OWNER

  const [sizeSetting, setSizeSetting] = useState(
    uploadState?.blueprint?.sizeSetting ?? 40
  )
  const [containerWidth, setContainerWidth] = useState('')
  const [containerHeight, setContainerHeight] = useState('')
  const [containerFontSize, setContainerFontSize] = useState('')
  const containerBorderRadius = lessThanSmall
    ? '0.8px'
    : isMedium
    ? '1.4px'
    : isLarge
    ? '1.8px'
    : '2px'

  //Size and scaling of the powerSupply container
  //TODO: If there is large differences between container positions on different devices (mobile, tablet etc.),
  //we could add more breakpoints
  useEffect(() => {
    switch (sizeSetting) {
      case 10:
        //Container width
        lessThanSmall
          ? setContainerWidth('6px')
          : isMedium
          ? setContainerWidth('10.5px')
          : isLarge
          ? setContainerWidth('13,5px')
          : setContainerWidth('15px')

        //Container height
        lessThanSmall
          ? setContainerHeight('4px')
          : isMedium
          ? setContainerHeight('7px')
          : isLarge
          ? setContainerHeight('9px')
          : setContainerHeight('10px')

        //Container fontSize
        lessThanSmall
          ? setContainerFontSize('0.08em')
          : isMedium
          ? setContainerFontSize('0.14em')
          : isLarge
          ? setContainerFontSize('0.18em')
          : setContainerFontSize('0.2em')

        break
      case 20:
        //Container width
        lessThanSmall
          ? setContainerWidth('8px')
          : isMedium
          ? setContainerWidth('14px')
          : isLarge
          ? setContainerWidth('18px')
          : setContainerWidth('20px')

        //Container height
        lessThanSmall
          ? setContainerHeight('6px')
          : isMedium
          ? setContainerHeight('10.5px')
          : isLarge
          ? setContainerHeight('13.5px')
          : setContainerHeight('15px')

        //Container fontSize
        lessThanSmall
          ? setContainerFontSize('0.12em')
          : isMedium
          ? setContainerFontSize('0.21em')
          : isLarge
          ? setContainerFontSize('0.27em')
          : setContainerFontSize('0.3em')
        break
      case 30:
        //Container width
        lessThanSmall
          ? setContainerWidth('10px')
          : isMedium
          ? setContainerWidth('17.5px')
          : isLarge
          ? setContainerWidth('22.5px')
          : setContainerWidth('25px')

        //Container height
        lessThanSmall
          ? setContainerHeight('8px')
          : isMedium
          ? setContainerHeight('14px')
          : isLarge
          ? setContainerHeight('18px')
          : setContainerHeight('20px')

        //Container fontSize
        lessThanSmall
          ? setContainerFontSize('0.16em')
          : isMedium
          ? setContainerFontSize('0.28em')
          : isLarge
          ? setContainerFontSize('0.36em')
          : setContainerFontSize('0.4em')
        break
      case 40:
        //Container width
        lessThanSmall
          ? setContainerWidth('12px')
          : isMedium
          ? setContainerWidth('21px')
          : isLarge
          ? setContainerWidth('27px')
          : setContainerWidth('30px')

        //Container height
        lessThanSmall
          ? setContainerHeight('10px')
          : isMedium
          ? setContainerHeight('17.5px')
          : isLarge
          ? setContainerHeight('22.5px')
          : setContainerHeight('25px')

        //Container fontSize
        lessThanSmall
          ? setContainerFontSize('0.2em')
          : isMedium
          ? setContainerFontSize('0.35em')
          : isLarge
          ? setContainerFontSize('0.45em')
          : setContainerFontSize('0.5em')
        break
      case 50:
        //Container width
        lessThanSmall
          ? setContainerWidth('14px')
          : isMedium
          ? setContainerWidth('24.5px')
          : isLarge
          ? setContainerWidth('31.5px')
          : setContainerWidth('35px')

        //Container height
        lessThanSmall
          ? setContainerHeight('12px')
          : isMedium
          ? setContainerHeight('21px')
          : isLarge
          ? setContainerHeight('27px')
          : setContainerHeight('30px')

        //Container fontSize
        lessThanSmall
          ? setContainerFontSize('0.24em')
          : isMedium
          ? setContainerFontSize('0.42em')
          : isLarge
          ? setContainerFontSize('0.54em')
          : setContainerFontSize('0.6em')
        break
    }
  }, [sizeSetting, lessThanSmall, isMedium, isLarge])

  const [assetFilter, setAssetFilter] = useState<string>('all')

  const handleChangeAssetFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: string
  ) => {
    setAssetFilter(filter)
  }

  return (
    <React.Fragment>
      {isSubmitting ? (
        <LoadingState />
      ) : (
        <>
          <Dialog
            open={openBlueprintInfo}
            onClose={() => setOpenBlueprintInfo(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t('uploadInfoTitle')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Table>
                  <TableRow>{'1. ' + t('uploadInfo.step1')}</TableRow>
                  <TableRow>{'2. ' + t('uploadInfo.step2')}</TableRow>
                  <TableRow>{'3. ' + t('uploadInfo.step3')}</TableRow>
                </Table>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setOpenBlueprintInfo(false)}
                autoFocus
              >
                {t('understood')}
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <Typography align="left">
                <Button variant="contained" onClick={() => navigate(-1)}>
                  {t('back')}
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="right" sx={{ md: 3, mr: 3, mb: 4 }}>
                <ToggleButtonGroup
                  color="primary"
                  value={assetFilter}
                  exclusive
                  onChange={handleChangeAssetFilter}
                  aria-label="Platform"
                >
                  <ToggleButton value={'ruuviTags'}>
                    {t('ruuviTags')}
                  </ToggleButton>
                  <ToggleButton value={'powerSupplies'}>
                    {t('powerSupplies')}
                  </ToggleButton>
                  <ToggleButton value={'all'}>{t('all')}</ToggleButton>
                </ToggleButtonGroup>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {userCanManageUpload ? (
                <Typography align="right">
                  {isEditing === false ? (
                    <Button
                      variant="contained"
                      onClick={handleEdit}
                      color="success"
                    >
                      {t('edit')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => setIsEditing(false)}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        color="success"
                      >
                        {t('save')}
                      </Button>
                    </>
                  )}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Divider my={6} />
          <Typography align="right">
            <IconButton
              size="small"
              onClick={() => setOpenBlueprintInfo(true)}
              sx={{ mt: 4, ml: -4 }}
            >
              <InfoIcon />
            </IconButton>
          </Typography>
          {isEditing && (
            <>
              <Typography align="center">
                {t('changeSizeOfPowerSupplies')}
              </Typography>
              <Slider
                size="small"
                aria-label="Small"
                onChange={(e) => handleResize(e)}
                defaultValue={sizeSetting}
                step={10}
                marks
                min={10}
                max={50}
              />
            </>
          )}

          {scale > 1 ? null : (
            <Typography align="center">{t('unlocatedDevices')}</Typography>
          )}
          <TransformWrapper
            initialScale={1}
            minScale={1}
            maxScale={lessThanSmall ? 15 : 10}
            onZoom={handleScale}
            centerZoomedOut={true}
            disablePadding={true}
            limitToBounds={true}
            panning={{ disabled: isDragging }}
          >
            <TransformComponent
              wrapperStyle={{
                width: isLoaded ? width : '100%',
                height: isLoaded ? height : '90%'
              }}
            >
              {upload && isLoaded && (
                <React.Fragment>
                  {assetFilter === 'all' || assetFilter === 'powerSupplies' ? (
                    <React.Fragment>
                      {powerSupplies?.map((powerSupply: PowerSupply) => (
                        <Draggable
                          key={powerSupply.id}
                          defaultPosition={checkCoordinates(
                            powerSupply.id,
                            BlueprintAssetType.POWERSUPPLY
                          )}
                          bounds="parent"
                          scale={scale}
                          onStart={() => handleDragStart(powerSupply.id)}
                          onStop={(e, data) =>
                            handleDragStop(
                              e,
                              data,
                              BlueprintAssetType.POWERSUPPLY
                            )
                          }
                          grid={[5, 5]}
                          disabled={isEditing === true ? false : true}
                        >
                          <Box>
                            <Typography
                              style={{
                                backgroundColor: '#D3D3D3',
                                color: 'black',
                                fontSize: containerFontSize,
                                borderRadius: containerBorderRadius
                              }}
                            >
                              {powerSupply.name.length > 8
                                ? powerSupply.name.substring(0, 6) + '...'
                                : powerSupply.name}
                            </Typography>
                            <BlueprintAssetContainer
                              powerSupply={powerSupply}
                              containerWidth={containerWidth}
                              containerHeight={containerHeight}
                              containerFontSize={containerFontSize}
                              containerBorderRadius={containerBorderRadius}
                              sizeSetting={sizeSetting}
                            />
                          </Box>
                        </Draggable>
                      ))}
                    </React.Fragment>
                  ) : (
                    <div
                      style={{ width: containerWidth, height: containerHeight }}
                    >
                      {/**Nothing should go here */}
                    </div>
                  )}

                  {assetFilter === 'all' || assetFilter === 'ruuviTags' ? (
                    <React.Fragment>
                      {ruuviTags?.map((ruuviTag: RuuviTag) => (
                        <Draggable
                          key={ruuviTag.id}
                          defaultPosition={checkCoordinates(
                            ruuviTag.id,
                            BlueprintAssetType.RUUVITAG
                          )}
                          bounds="parent"
                          scale={scale}
                          onStart={() => handleDragStart(ruuviTag.id)}
                          onStop={(e, data) =>
                            handleDragStop(e, data, BlueprintAssetType.RUUVITAG)
                          }
                          grid={[5, 5]}
                          disabled={isEditing === true ? false : true}
                        >
                          <Box>
                            <Typography
                              style={{
                                backgroundColor: '#D3D3D3',
                                color: 'black',
                                fontSize: containerFontSize,
                                borderRadius: containerBorderRadius
                              }}
                            >
                              {
                                //@ts-ignore
                                ruuviTag?.asset?.name.length > 8
                                  ? ruuviTag?.asset?.name.substring(0, 6) +
                                    '...'
                                  : ruuviTag?.asset?.name
                              }
                            </Typography>
                            <BlueprintAssetContainer
                              ruuviTag={ruuviTag}
                              containerWidth={containerWidth}
                              containerHeight={containerHeight}
                              containerFontSize={containerFontSize}
                              containerBorderRadius={'100%'}
                              sizeSetting={sizeSetting}
                            />
                          </Box>
                        </Draggable>
                      ))}
                    </React.Fragment>
                  ) : (
                    <div
                      style={{ width: containerWidth, height: containerHeight }}
                    >
                      {/**Nothing should go here */}
                    </div>
                  )}
                </React.Fragment>
              )}
              <img
                className="img"
                onLoad={handleLoad}
                ref={ref}
                src={uploadState.url}
                style={{ width: '100%', height: '100%' }}
              />
            </TransformComponent>
          </TransformWrapper>
        </>
      )}
    </React.Fragment>
  )
}
