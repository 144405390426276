import { createSlice } from '@reduxjs/toolkit'

interface ErrorState {
  data: ErrorData | undefined
}

const initialState: ErrorState = {
  data: undefined
}

export interface ErrorData {
  type: string
  error: any
}

export const errorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setError(state, action) {
      state.data = action.payload
    },
    deleteError(state, action) {
      state.data = undefined
    }
  }
})

export const { setError, deleteError } = errorSlice.actions

export default errorSlice.reducer
