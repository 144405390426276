import { Typography, Button, Grid } from '@mui/material'
import TroubleshootProps from './TroubleshootProps'
import { useState } from 'react'
import { TroubleshootEvent } from '../../pages/pages/DeviceTroubleshoot'
import { useTranslation } from 'react-i18next'

interface Props extends TroubleshootProps {
  endTroubleshoot: () => void
}

export default function RebootManually({
  stateUpdate,
  endTroubleshoot
}: Props) {
  /**
   * State which describes if the ending text is shown.
   */
  const [isText, setIsText] = useState(false)
  const [t] = useTranslation('common')
  return (
    <>
      {!isText ? (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            {t('troubleshoot.dangIt')}
          </Typography>

          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t('troubleshoot.rebootManuallyBody')}
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button variant="contained" onClick={() => setIsText(true)}>
                {t('troubleshoot.itWorked')}
              </Button>
            </Typography>
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  stateUpdate(
                    TroubleshootEvent.NothingWorked,
                    "Rebooting device didn't work."
                  )
                }}
              >
                {t('troubleshoot.nothingWorked')}
              </Button>
            </Typography>
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  stateUpdate(
                    TroubleshootEvent.Skip,
                    'Skipped rebooting the device.'
                  )
                }}
              >
                {t('troubleshoot.skip')}
              </Button>
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            {t('troubleshoot.nicelyDone')}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button variant="contained" onClick={endTroubleshoot}>
                {t('troubleshoot.endTroubleshoot')}
              </Button>
            </Typography>
          </Grid>
        </>
      )}
    </>
  )
}
