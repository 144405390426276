import { createSlice } from '@reduxjs/toolkit'
import { QRData } from '../../enums/QRData'

interface StepState {
  step: number
  data: string
}

const initialState: StepState = {
  step: 0,
  data: QRData.NOT_SCANNED
}

const stepSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload
    },
    setData(state, action) {
      state.data = action.payload
    }
  }
})

export const { setStep, setData } = stepSlice.actions

export default stepSlice.reducer
