import axios, { AxiosResponse } from 'axios'
import { CurrentUser, MFAResetLinkInput } from '../types'
import { setSession } from '../utils/jwt'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL

// const login = async (email: string, password: string): Promise<CurrentUser> => {
//   try {
//     const response = await axios.post(baseUrl + 'login', { email, password })
//     return response.data
//   } catch (e) {
//     throw new Error('Wrong username or password')
//   }
// }

// const sendEmail = (email: string): Promise<AxiosResponse> => {
//   try {
//     const response = axios.post(baseUrl + 'email', { email })
//     return response
//   } catch (e) {
//     return e as never
//   }
// }

// const resetPassword = (
//   password: string,
//   token: string
// ): Promise<AxiosResponse> => {
//   try {
//     const response = axios.post(`${baseUrl}email/password_reset/${token}`, {
//       password
//     })
//     return response
//   } catch (e) {
//     return e as never
//   }
// }

// const changePassword = (
//   id: number,
//   oldPassword: string,

interface PassworInput {
  newPassword: string
  oldPassword?: string
}

interface EmailInput {
  email: string
}

//TODO: LoginInput type comes with mfa branch
export async function login(input: any): Promise<CurrentUser> {
  const response: AxiosResponse = await axios.post(baseURL + 'login', input)
  return response.data
}

export async function sendPasswordResetEmail(
  input: EmailInput
): Promise<AxiosResponse> {
  const response: AxiosResponse = await axios.post(baseURL + 'email', input)
  return response.data
}

export async function resetPassword(
  input: PassworInput,
  token: string
): Promise<AxiosResponse> {
  const response: AxiosResponse = await axios.post(
    baseURL + `email/password_reset/${token}`,
    input
  )
  return response.data
}

//move this to users service?
export async function changePassword(
  id: number,
  input: PassworInput
): Promise<AxiosResponse> {
  const response: AxiosResponse = await axios.put(
    baseURL + `users/change_password/${id}`,
    input
  )
  return response.data
}

export async function handleRefreshToken() {
  const refreshToken = localStorage.getItem('refreshToken')

  try {
    const response = await axios.post(baseURL + 'login/refresh-token', {
      refreshToken: refreshToken
    })

    const newTokens = response.data

    if (!newTokens) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    }

    return setSession(newTokens.accessToken, newTokens.refreshToken)
  } catch (error) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export async function sendEmailVerificationMail(): Promise<void> {
  const response: AxiosResponse = await axios.post(
    `${baseURL}email/verify/user`
  )

  return response.data
}

export async function verifyUserEmailService(token: string): Promise<void> {
  const response: AxiosResponse = await axios.post(
    `${baseURL}email/verify/${token}`
  )

  return response.data
}

export async function sendMfaResetLink(
  input: MFAResetLinkInput
): Promise<void> {
  const response: AxiosResponse = await axios.post(
    `${baseURL}email/send-mfa-reset-link`,
    input
  )

  return response.data
}

export async function resetUserMfa(token: string): Promise<void> {
  const response: AxiosResponse = await axios.post(
    `${baseURL}email/reset-mfa/${token}`
  )

  return response.data
}

export default {
  login,
  // sendEmail,
  resetPassword,
  changePassword,
  sendEmailVerificationMail,
  sendMfaResetLink,
  verifyUserEmailService,
  resetUserMfa,
  sendPasswordResetEmail
}
