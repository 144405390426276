import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import { Grid, Paper, Typography } from '@mui/material'

import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import SignUpComponent from '../../components/auth/SignUp'
import NavbarLanguagesDropdown from '../../components/navbar/NavbarLanguagesDropdown'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function SignUp() {
  const { theme } = useTheme()
  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}

      <Helmet title="Sign Up" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Welcome to user creation
      </Typography>
      {/* <Typography component="h2" variant="body1" align="center">
          Start creating the best possible user experience for you customers
        </Typography> */}

      <Grid item xs={12}>
        <NavbarLanguagesDropdown />
      </Grid>
      <SignUpComponent />
    </React.Fragment>
  )
}

export default SignUp
