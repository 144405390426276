import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  Checkbox,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
  Alert,
  Link,
  Table,
  TableRow,
  TableCell,
  Divider as MuiDivider,
  Typography
} from '@mui/material'
import * as Yup from 'yup'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { createOrderItem } from '../../redux/slices/orderItems'
import { OrderItemType } from '../../enums/OrderItemType'
import { NominalCurrentSelect100And250 } from '../NominalCurrentSelect'
import OrderItemTypeSelect from '../OrderItemTypeSelect'
import InfoIcon from '@mui/icons-material/Info'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { OrderErrors } from '../../enums/OrderErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { getCatalogProducts } from '../../redux/slices/catalog'
import { RootState } from '../../redux/store'
import { CatalogProduct, Product } from '../../types'
import LoadingState from '../LoadingState'
import { ProductStatus } from '../../enums/ProductStatus'
import { getOrder } from '../../redux/slices/orders'
import { OrderType } from '../../enums/OrderType'
import OrderItemInput from '../../types/OrderItemInput'
import { OrderItem } from '../../types'
import { getProducts } from '../../redux/slices/products'
import translateProductOrOrderItemType from '../../utils/translateProductOrOrderItemType'
import { CatalogProductType } from '../../enums/CatalogProductType'
import orderService from '../../services/orderService'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const Button = styled(MuiButton)(spacing)

const Card = styled(MuiCard)(spacing)

const Grid = styled(MuiGrid)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

const Divider = styled(MuiDivider)(spacing)

const validationSchema = Yup.object().shape({
  nominalCurrent: Yup.string().required('Required'),
  clampRatio: Yup.string().required('Required')
})

export default function OrderForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [t] = useTranslation('common')
  const { id } = useParams()
  const [checked, setChecked] = useState<boolean>(false)
  const [clampInput, setClampInput] = useState<boolean>(false)
  const [nominalInput, setNominalInput] = useState<boolean>(false)
  const [openInstructions, setOpenInstructions] = useState<boolean>(false)
  const [orderItemType, setOrderItemType] = useState<OrderItemType>(
    OrderItemType.INSTALLATION_KIT
  )
  const { order, isLoadingOrder } = useSelector((state: RootState) => ({
    order: state.orders.order,
    isLoadingOrder: state.orders.loading
  }))

  const { products, isLoadingProducts } = useSelector((state: RootState) => ({
    products: state.products.products,
    isLoadingProducts: state.products.isLoadingProducts
  }))
  const { catalogProducts, loading } = useSelector(
    (state: RootState) => state.catalogProducts
  )

  const [selectedProducts, setSelectedProducts] = useState<number[]>([])

  useEffect(() => {
    if (id) {
      dispatch(getOrder(parseInt(id)))
      dispatch(getCatalogProducts())
    }
  }, [id])

  useEffect(() => {
    dispatch(getProducts({ status: ProductStatus.STORED, type: orderItemType }))
    setFilter('')
  }, [orderItemType])

  const initialValues = {
    type: orderItemType,
    nominalCurrent: '',
    quantity: 1,
    withPowerLeds: false,
    orderId: order?.id as number,
    clampRatio: '',
    catalogProduct: null,
    status: ProductStatus.STORED,
    useStock: false,
    products: undefined
  }

  const [filter, setFilter] = useState<string>('')

  const filteredProducts = products.filter((product: Product) => {
    const regex = new RegExp(filter, 'i')
    return (
      filter.length === 0 ||
      Object.values(product).some((value) => {
        return regex.test(value as string)
      }) ||
      Object.values(product.device ?? {}).some((value) => {
        return regex.test(value as string)
      }) ||
      Object.values(product.catalogProduct ?? {}).some((value) => {
        return regex.test(value as string)
      })
    )
  })

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleClampInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClampInput(event.target.checked)
  }

  const handleNominalInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNominalInput(event.target.checked)
  }

  const handleClickOpenInstructions = () => {
    setOpenInstructions(true)
  }

  const handleCloseInstructions = () => {
    setOpenInstructions(false)
  }

  const { currentUser } = useAuth()

  async function handleStoragedProducts(values: OrderItemInput) {
    if (selectedProducts.length > 0) {
      values.products = selectedProducts
      values.nominalCurrent = 0
      values.clampRatio = 0
      values.quantity = selectedProducts.length
      handleSubmit(values)
    } else handleSubmit(values)
  }

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any
  ) => {
    try {
      values.status = ProductStatus.PENDING
      const createdOrder: OrderItem = await orderService.createOrderItem(values)
      dispatch(createOrderItem(createdOrder))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('orderItemCreateSuccess')
        )
      )
      navigate(`/manufacturing/orders/${id}`)
    } catch (error) {
      dispatch(
        setError({
          type: OrderErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: OrderErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )

      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(OrderErrors.CREATE))
    }
  }

  const [fuseValue, setFuseValue] = useState<string | number>('')
  const [catalogId, setCatalogId] = useState<string | null>(null)
  const [productCode, setProductCode] = useState('')
  const [catalogProduct, setCatalogProduct] = useState<
    CatalogProduct | undefined
  >(undefined)

  const handleSetCatalogValues = (name: string | null) => {
    if (name === null) {
      setFuseValue('')
      setCatalogId(null)
      setProductCode('')
      setCatalogProduct(undefined)
      setFilter('')
    } else {
      catalogProducts.filter((data: CatalogProduct) => {
        if (Object.values(data).includes(name as string) === true) {
          setFuseValue(data.customData.fuse)
          setCatalogId(data.id)
          setProductCode(data.manufacturerProductCode)
          setCatalogProduct(data)
          setFilter(data.manufacturerProductCode)
        }
      })
    }
  }

  function handleCatalogPlugAndPlay(catalogProductCode: string) {
    setOrderItemType(OrderItemType.SMART_ELECTRICAL_CABINET)
    handleSetCatalogValues(catalogProductCode)
  }

  function handleOrderItemTypeChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (
      event.target.value === OrderItemType.INSTALLATION_KIT ||
      event.target.value === OrderItemType.PLUG_AND_PLAY
    ) {
      setCatalogProduct(undefined)
      setCatalogId(null)
      setProductCode('')
    }
    setOrderItemType(event.target.value as OrderItemType)
  }

  const handleSelectProductFromStorage = (
    event: React.ChangeEvent<HTMLInputElement>,
    productId: number
  ) => {
    if (event.target.checked) {
      setSelectedProducts([...selectedProducts, productId])
    } else {
      const newProduct = selectedProducts.filter(
        (existingProduct: number) => existingProduct !== productId
      )
      setSelectedProducts(newProduct)
    }
  }

  return (
    <React.Fragment>
      {loading || isLoadingOrder ? (
        <LoadingState />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <Card mb={6}>
              <Dialog
                open={openInstructions}
                onClose={handleCloseInstructions}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t('Manufacturing.clampRatio')}
                </DialogTitle>

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t('Manufacturing.setClampratioManually')}
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleCloseInstructions}
                    autoFocus
                  >
                    {t('Manufacturing.close')}
                  </Button>
                </DialogActions>
              </Dialog>
              {values.type === OrderItemType.PLUG_AND_PLAY && (
                <Alert variant="filled" severity="info">
                  {t('plugAndPlayInfo.partOne')} 16 A{' '}
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleCatalogPlugAndPlay('501310005')}
                    color={'darkblue'}
                  >
                    (501310005)
                  </Link>
                  , 32 A{' '}
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleCatalogPlugAndPlay('501310004')}
                    color={'darkblue'}
                  >
                    (501310004)
                  </Link>
                  , {t('plugAndPlayInfo.or')} 63 A{' '}
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleCatalogPlugAndPlay('501310003')}
                    color={'darkblue'}
                  >
                    (501310003)
                  </Link>{' '}
                  {t('plugAndPlayInfo.partTwo')}
                </Alert>
              )}
              <CardContent>
                {isSubmitting ? (
                  <LoadingState />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <CardHeader
                      title={t('Manufacturing.addProduct')}
                      align="center"
                      titleTypographyProps={{ variant: 'h4' }}
                    />

                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl>
                        <OrderItemTypeSelect
                          disabled={selectedProducts.length > 0}
                          name="type"
                          value={values.type}
                          error={Boolean(touched.type && errors.type)}
                          fullWidth
                          helperText={touched.type && errors.type}
                          onBlur={handleBlur}
                          onChange={handleOrderItemTypeChange}
                          variant="outlined"
                        />
                      </Grid>
                      {values.type === OrderItemType.INSTALLATION_KIT ||
                      values.type === OrderItemType.PLUG_AND_PLAY ? (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl my={2}>
                          <NominalCurrentSelect100And250
                            name="nominalCurrent"
                            value={values.nominalCurrent}
                            error={Boolean(
                              touched.nominalCurrent && errors.nominalCurrent
                            )}
                            fullWidth
                            helperText={
                              touched.nominalCurrent && errors.nominalCurrent
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                      ) : null}

                      {(values.type ===
                        OrderItemType.SMART_ELECTRICAL_CABINET &&
                        nominalInput === false) ||
                      (values.type === OrderItemType.SPINE_MODULE &&
                        nominalInput === false) ? (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl my={2}>
                          <Autocomplete
                            key={
                              ((values.nominalCurrent = fuseValue as string),
                              // @ts-ignore
                              (values.catalogProduct = catalogId))
                            }
                            options={catalogProducts
                              .filter((product: CatalogProduct) =>
                                values.type ===
                                OrderItemType.SMART_ELECTRICAL_CABINET
                                  ? product.type ===
                                    CatalogProductType.ELECTRICAL_CABINET
                                  : product.type ===
                                    CatalogProductType.SPINE_MODULE
                              )
                              .map(
                                (option: any) => option.manufacturerProductCode
                              )}
                            onChange={(event: any, newValue: string | null) => {
                              handleSetCatalogValues(newValue)
                            }}
                            value={productCode}
                            renderInput={(params) => (
                              <TextField
                                error={Boolean(
                                  touched.nominalCurrent &&
                                    errors.nominalCurrent
                                )}
                                helperText={
                                  touched.nominalCurrent &&
                                  errors.nominalCurrent
                                }
                                {...params}
                                label={
                                  catalogProduct
                                    ? catalogProduct.name +
                                      ' / ' +
                                      catalogProduct.manufacturer
                                    : t('Catalog')
                                }
                              />
                            )}
                          />

                          <Grid item xs={12} sm={12} md={6} lg={6} xl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="other"
                                  onChange={handleNominalInput}
                                />
                              }
                              label={t('Manufacturing.modifyNominal') as string}
                            />
                          </Grid>
                        </Grid>
                      ) : null}

                      {values.type === OrderItemType.SMART_ELECTRICAL_CABINET &&
                        nominalInput === true && (
                          <Grid item xs={12} sm={12} md={6} lg={6} xl my={2}>
                            <TextField
                              name="nominalCurrent"
                              label={t('Manufacturing.nominal')}
                              value={values.nominalCurrent}
                              error={Boolean(
                                touched.nominalCurrent && errors.nominalCurrent
                              )}
                              fullWidth
                              helperText={
                                touched.nominalCurrent && errors.nominalCurrent
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                            <Grid item xs={12} sm={12} md={6} lg={6} xl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked
                                    name="other"
                                    onChange={handleNominalInput}
                                  />
                                }
                                label={
                                  t('Manufacturing.modifyNominal') as string
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={6}>
                      {parseInt(values.nominalCurrent) <= 100 &&
                        parseInt(values.nominalCurrent) > 0 &&
                        clampInput === false &&
                        nominalInput === false && (
                          <>
                            <Grid item xs={11} sm={11} md={5} lg={5} xl>
                              <TextField
                                name="clampRatio"
                                label={t('Manufacturing.clampRatio')}
                                value={(values.clampRatio = '1000')}
                                error={Boolean(
                                  touched.clampRatio && errors.clampRatio
                                )}
                                fullWidth
                                disabled
                                helperText={
                                  touched.clampRatio && errors.clampRatio
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                my={2}
                              />
                              <Grid item xs={12} sm={12} md={6} lg={6} xl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="modifyClampratio"
                                      onChange={handleClampInput}
                                    />
                                  }
                                  label={
                                    t(
                                      'Manufacturing.modifyClampratio'
                                    ) as string
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                sx={{ mt: 4, ml: -5 }}
                                size="small"
                                onClick={() => handleClickOpenInstructions()}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Grid>
                          </>
                        )}

                      {parseInt(values.nominalCurrent) > 100 &&
                        clampInput === false &&
                        nominalInput === false && (
                          <>
                            <Grid item xs={11} sm={11} md={5} lg={5} xl>
                              <TextField
                                name="clampRatio"
                                label={t('Manufacturing.clampRatio')}
                                value={(values.clampRatio = '2500')}
                                error={Boolean(
                                  touched.clampRatio && errors.clampRatio
                                )}
                                fullWidth
                                disabled
                                helperText={
                                  touched.clampRatio && errors.clampRatio
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                my={2}
                              />
                              <Grid item xs={12} sm={12} md={6} lg={6} xl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="modifyClampratio"
                                      onChange={handleClampInput}
                                    />
                                  }
                                  label={
                                    t(
                                      'Manufacturing.modifyClampratio'
                                    ) as string
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                sx={{ mt: 4, ml: -5 }}
                                size="small"
                                onClick={() => handleClickOpenInstructions()}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Grid>
                          </>
                        )}

                      {clampInput === true ||
                      parseInt(values.nominalCurrent) == 0 ||
                      nominalInput === true ? (
                        <>
                          <Grid item xs={11} sm={11} md={5} lg={5} xl>
                            <TextField
                              name="clampRatio"
                              label={t('Manufacturing.clampRatio')}
                              value={values.clampRatio}
                              error={Boolean(
                                touched.clampRatio && errors.clampRatio
                              )}
                              fullWidth
                              helperText={
                                touched.clampRatio && errors.clampRatio
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                            <Grid item xs={12} sm={12} md={6} lg={6} xl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={clampInput}
                                    name="modifyClampratio"
                                    onChange={handleClampInput}
                                  />
                                }
                                label={
                                  t('Manufacturing.modifyClampratio') as string
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              sx={{ mt: 4, ml: -5 }}
                              size="small"
                              onClick={() => handleClickOpenInstructions()}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Grid>
                        </>
                      ) : null}
                      <Grid item xs={12} sm={12} md={6} lg={6} xl>
                        <TextField
                          name="quantity"
                          disabled={selectedProducts.length > 0}
                          label={t('Manufacturing.quantity')}
                          value={
                            selectedProducts.length > 0
                              ? selectedProducts.length
                              : values.quantity
                          }
                          error={Boolean(touched.quantity && errors.quantity)}
                          fullWidth
                          helperText={touched.quantity && errors.quantity}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="withPowerLeds"
                              value={(values.withPowerLeds = checked)}
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={t('Manufacturing.addLedbar') as string}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      m={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => {
                          navigate(`/manufacturing/orders/${id}`)
                        }}
                        variant="contained"
                        color="primary"
                        mt={3}
                      >
                        {t('Manufacturing.cancel')}
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        mt={3}
                        onClick={() => handleStoragedProducts(values)}
                      >
                        {t('Manufacturing.save')}
                      </Button>
                    </Box>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      )}
      {order?.company !== null && (
        <Card>
          <CardHeader
            title={t('storage')}
            titleTypographyProps={{ variant: 'h4' }}
          />
          <Divider my={6} />
          <Grid xs={4} ml={4}>
            <TextField
              label={t('search')}
              fullWidth
              name="search"
              size="small"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value)
              }}
            />
          </Grid>
          <CardContent>
            {isLoadingProducts ? (
              <LoadingState />
            ) : (
              <React.Fragment>
                {products ? (
                  <Table>
                    <TableRow>
                      <TableCell>{t('deviceName')}</TableCell>
                      <TableCell>{t('manufacturerProductCode')}</TableCell>
                      <TableCell>{t('type')}</TableCell>
                      <TableCell>{t('clampRatio')}</TableCell>
                      <TableCell>{t('nominalCurrent')}</TableCell>
                      <TableCell>{t('ledbar')}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    {filteredProducts.map((product: Product) => (
                      <TableRow>
                        <TableCell>
                          {product.device ? product.device.shortUUID : '-'}
                        </TableCell>
                        <TableCell>
                          {product.catalogProduct
                            ? product.catalogProduct.manufacturerProductCode
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {t(
                            translateProductOrOrderItemType(
                              product.type
                            ) as string
                          )}
                        </TableCell>
                        <TableCell>{product.clampRatio}</TableCell>
                        <TableCell>{product.nominalCurrent}</TableCell>
                        <TableCell>
                          {product.withPowerLeds ? t('yes') : t('no')}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            onChange={(event) =>
                              handleSelectProductFromStorage(event, product.id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                ) : (
                  <Typography align="center">{t('storageIsEmpty')}</Typography>
                )}
              </React.Fragment>
            )}
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  )
}
