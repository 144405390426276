import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Button,
  Typography,
  TextField,
  Alert
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { QrReader } from 'react-qr-reader'
import { RootState } from '../../../redux/store'
import { QRData } from '../../../enums/QRData'
import { setData } from '../../../redux/slices/steps'
import NavbarLanguagesDropdown from '../../../components/navbar/NavbarLanguagesDropdown'
import { getPublicDeviceByName } from '../../../services/publicService'
import { Device } from '../../../types'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)

function QRScan() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [device, setDevice] = useState<Device>()
  const { data } = useSelector((state: RootState) => state.steps)
  const [shortUUID, setShortUUID] = useState('')
  const [errorNotification, setErrorNotification] = useState(false)

  useEffect(() => {
    if (data !== QRData.NOT_SCANNED) {
      loadDevice(data.split('/').pop() as string)
    }
  }, [data])

  useEffect(() => {
    if (device !== undefined) {
      navigate(`/inspect-device/${device.shortUUID}`)
      dispatch(setData(QRData.NOT_SCANNED))
    }
  }, [device])

  const handleShortUUID = (shortUUID: string) => {
    loadDevice(shortUUID)
  }

  async function loadDevice(shortUUID: string): Promise<void> {
    if (shortUUID) {
      try {
        setDevice(await getPublicDeviceByName(shortUUID))
      } catch (error) {
        setErrorNotification(true)
      }
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Card mb={6}>
            <Grid justifyContent={'center'} alignItems="center">
              <Paper>
                <>
                  <Grid container spacing={6}>
                    <Grid xs={6} mt={6} ml={6}>
                      <Typography>
                        <NavbarLanguagesDropdown />
                      </Typography>
                    </Grid>
                    <Grid xs={5} mt={8}>
                      <Typography align="right">
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => navigate('/auth/sign-in')}
                        >
                          {t('signIn')}
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    justifyContent="center"
                    alignItems="center"
                    container
                    mt={6}
                  >
                    <Typography align="center" variant="h6">
                      {t('Manufacturing.scanQR')}
                    </Typography>
                  </Grid>

                  <QrReader
                    scanDelay={500}
                    onResult={(result) => {
                      if (result) {
                        dispatch(setData(result.toString()))
                      }
                    }}
                    constraints={{ facingMode: 'environment' }}
                  />
                  {errorNotification === true ? (
                    <Grid style={{ padding: '1rem' }}>
                      <Alert severity={'warning'}>
                        {t('somethingWentWrong')}
                      </Alert>
                    </Grid>
                  ) : null}
                  <Divider my={6} />
                  <Typography align="center" variant="h6">
                    {t('Manufacturing.enterShortCode')}
                  </Typography>
                  <Divider my={6} />
                  <Grid container spacing={6}>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl>
                      <TextField
                        fullWidth
                        size="small"
                        onChange={(e) => setShortUUID(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleShortUUID(shortUUID)}
                      >
                        {t('Manufacturing.find')}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              </Paper>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default QRScan
