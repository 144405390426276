export enum ProductStatus {
  PENDING = 'pending',
  PREPARED = 'prepared',
  ASSEMBLED = 'assembled',
  SHIPPED = 'shipped',
  INSTALLED = 'installed',
  STORED = 'stored',
  TESTED = 'tested',
  END_OF_LIFE = 'end_of_life'
}
