export function calculateProjectProgress(startDate: any, endDate: any): number {
  const today = new Date()
  const start = new Date(startDate)
  const end = new Date(endDate)

  const percentage = Math.round(
    ((today.valueOf() - start.valueOf()) / (end.valueOf() - start.valueOf())) *
      100
  )
  return percentage > 100 ? 100 : percentage
}
