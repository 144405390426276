import { Tooltip } from '@mui/material'
import { Battery20, BatteryFull, BatteryUnknown } from '@mui/icons-material'
import { RuuviTagBattery, RuuviTagCondition } from '../types'
import { useTranslation } from 'react-i18next'
import BlinkingComponent from './BlinkingComponent'

/**
 * The props of the ruuvi tag battery indicator.
 */
interface RuuviTagBatteryIndicatorProps {
  /**
   * The battery.
   */
  battery: RuuviTagBattery
  /**
   * The condition.
   */
  condition: RuuviTagCondition

  /**
   * The font size.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'

  /**
   * The rotation angle as degrees.
   */
  rotationAngle?: number
}

/**
 * A battery indicator for RuuviTag.
 */
export default function RuuviTagBatteryIndicator({
  battery,
  condition,
  fontSize = 'inherit',
  rotationAngle = 0
}: RuuviTagBatteryIndicatorProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Indicates if the battery is low
   */
  const isBatteryLow =
    (condition.temperatureAvg < -20 && battery.voltage <= 2000) ||
    (condition.temperatureAvg >= -20 &&
      condition.temperatureAvg <= 0 &&
      battery.voltage <= 2300) ||
    battery.voltage <= 2500

  /**
   * Indicates if the battery is full.
   */
  const isBatteryFull =
    (condition.temperatureAvg < -20 && battery.voltage > 2000) ||
    (condition.temperatureAvg >= -20 &&
      condition.temperatureAvg <= 0 &&
      battery.voltage > 2300) ||
    battery.voltage > 2500

  /**
   * The message to be displayed in the tooltip.
   */
  const message = isBatteryFull
    ? t('fullBattery')
    : isBatteryLow
    ? t('lowBattery')
    : t('unknownBattery')

  return (
    <Tooltip title={message}>
      {isBatteryFull ? (
        <BatteryFull
          color="success"
          fontSize={fontSize}
          sx={{ transform: `rotate(${rotationAngle}deg)` }}
        />
      ) : isBatteryLow ? (
        <BlinkingComponent>
          <Battery20
            color="error"
            fontSize={fontSize}
            sx={{ transform: `rotate(${rotationAngle}deg)` }}
          />
        </BlinkingComponent>
      ) : (
        <BatteryUnknown
          fontSize={fontSize}
          sx={{ transform: `rotate(${rotationAngle}deg)` }}
        />
      )}
    </Tooltip>
  )
}
