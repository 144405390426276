import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import Installation from '../pages/pages/Installation'

export default [
  {
    path: '/installation',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.INSTALLER,
          UserRole.ADMIN,
          UserRole.USER
        ]}
      >
        <UserManualProvider pageId="43843606">
          <DashboardLayout>
            <Installation />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/installation/project/:id',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.INSTALLER,
          UserRole.ADMIN,
          UserRole.USER
        ]}
      >
        <UserManualProvider pageId="43843606">
          <DashboardLayout>
            <Installation />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  }
]
