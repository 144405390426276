import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography
} from '@mui/material'
import { StartDateMode } from '../enums'

interface StatsStarDateToggleProps {
  value: StartDateMode
  options?: StartDateMode[]
  onChange(event: MouseEvent<HTMLElement>, value: StartDateMode): void
}

export default function StatsStarDateToggle({
  value,
  options = [
    StartDateMode.START_OF_PROJECT,
    StartDateMode.START_OF_MONTH,
    StartDateMode.SELECTED_DATE
  ],
  onChange
}: StatsStarDateToggleProps) {
  const [t] = useTranslation('common')

  function handleChange(event: MouseEvent<HTMLElement>, value: StartDateMode) {
    if (value && value !== null) {
      onChange(event, value)
    }
  }

  function hasOption(option: StartDateMode): boolean {
    return options.includes(option)
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography fontSize={12}>{t('startDateMode')}</Typography>

      <ToggleButtonGroup
        color="primary"
        size="small"
        exclusive
        value={value}
        onChange={handleChange}
      >
        {hasOption(StartDateMode.START_OF_PROJECT) && (
          <ToggleButton value={StartDateMode.START_OF_PROJECT}>
            {t('StartDateMode.START_OF_PROJECT')}
          </ToggleButton>
        )}

        {hasOption(StartDateMode.START_OF_MONTH) && (
          <ToggleButton value={StartDateMode.START_OF_MONTH}>
            {t('StartDateMode.START_OF_MONTH')}
          </ToggleButton>
        )}

        {hasOption(StartDateMode.SELECTED_DATE) && (
          <ToggleButton value={StartDateMode.SELECTED_DATE}>
            {t('StartDateMode.SELECTED_DATE')}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Stack>
  )
}
