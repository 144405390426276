import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { Sensor, Device } from '../types'
import { isSensorNow } from '../utils/sensor'
import DeviceCurrentBar from './DeviceCurrentBar'

interface CurrentBarBoxProps {
  /**
   * The label.
   */
  label: string

  /**
   * The current.
   */
  current: number

  /**
   * The fuse size.
   */
  fuse: number
}

function CurrentBarBox({ label, current, fuse }: CurrentBarBoxProps) {
  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {`${label}: ${Math.round(current)}A / ${fuse}A`}
      </Typography>
      <DeviceCurrentBar phase={current} fuse={fuse} />
    </Box>
  )
}

interface DeviceCurrentBarBoxProps {
  device: Device
  sensor?: Sensor
}

export default function DeviceCurrentBarBoxTable({
  device,
  sensor
}: DeviceCurrentBarBoxProps) {
  const [t] = useTranslation('common')

  // TODO: Load the sensor when not provided.

  return (
    <>
      {sensor && isSensorNow(sensor) ? (
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <CurrentBarBox
              label={t('Device.phase1')}
              current={sensor.current_1}
              fuse={device?.powerSupply?.fuse as number}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CurrentBarBox
              label={t('Device.phase2')}
              current={sensor.current_2}
              fuse={device?.powerSupply?.fuse as number}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CurrentBarBox
              label={t('Device.phase3')}
              current={sensor.current_3}
              fuse={device?.powerSupply?.fuse as number}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}
