import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { createNotification } from './notifications'
import requestService from '../../services/requestService'
import projectService from '../../services/projectService'
import companyService from '../../services/companyService'
import { ActionRequest, UpdateRequestInput } from '../../types'
import userService from '../../services/userService'
import ProjectRequestParams from '../../types/ProjectRequestParams'
import { RequesterType } from '../../enums/RequesterType'
import { ActionRequestType } from '../../enums/RequestAccessType'

interface RequestState {
  projectRequests: ActionRequest[]
  companyRequests: ActionRequest[]
  systemRequests: ActionRequest[]
  userRequests: ActionRequest[]
  deviceLocationChangeRequests: ActionRequest[]
  request: ActionRequest | undefined
  show: boolean
  loading: boolean
  status: 'idle' | 'loading' | 'failed'
}

const initialState: RequestState = {
  projectRequests: [],
  companyRequests: [],
  systemRequests: [],
  userRequests: [],
  deviceLocationChangeRequests: [],
  request: undefined,
  show: false,
  status: 'idle',
  loading: false
}

export interface createRequestAccess {
  userId: number
  type: string
  project: number
  role: string
}

const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    setProjectRequests(state, action) {
      state.projectRequests = action.payload
    },
    setCompanyRequests(state, action) {
      state.companyRequests = action.payload
    },
    setSystemRequests(state, action) {
      state.systemRequests = action.payload
    },
    setUserRequests(state, action) {
      state.userRequests = action.payload
    },
    setDeviceLocationChangeRequests(state, action) {
      state.deviceLocationChangeRequests = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    deleteProjectRequest(state, action) {
      state.projectRequests = state.projectRequests.filter(
        (u) => u.id !== action.payload
      )
    },
    deleteCompanyRequest(state, action) {
      state.companyRequests = state.companyRequests.filter(
        (u) => u.id !== action.payload
      )
    },
    deleteUserRequest(state, action) {
      state.userRequests = state.userRequests.filter(
        (u) => u.id !== action.payload
      )
    },
    deleteSystemRequest(state, action) {
      state.systemRequests = state.systemRequests.filter(
        (actionRequest) => actionRequest.id !== action.payload
      )
    },
    showRequest(state, action) {
      state.request = action.payload
      state.show = true
    },
    hideRequest(state) {
      state.show = false
      state.request = undefined
    },
    updateUserRequest(state, action) {
      state.userRequests = state.userRequests.map((request) =>
        request.id === action.payload.id ? action.payload : request
      )
    },
    deleteDeviceLocationChangeRequest(state, action) {
      state.deviceLocationChangeRequests =
        state.deviceLocationChangeRequests.filter(
          (u) => u.id !== action.payload
        )
    }
  }
})

export const {
  setProjectRequests,
  setCompanyRequests,
  deleteCompanyRequest,
  deleteProjectRequest,
  deleteSystemRequest,
  showRequest,
  hideRequest,
  updateUserRequest,
  deleteUserRequest,
  deleteDeviceLocationChangeRequest
} = requestSlice.actions

export function getActionRequestsByProject(
  id: string | number,
  params: ProjectRequestParams
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestSlice.actions.setLoading(true))
      const requests = await projectService.getActionRequestsByProject(
        id,
        params
      )

      switch (params.requesterType) {
        case RequesterType.DEVICE:
          dispatch(
            requestSlice.actions.setDeviceLocationChangeRequests(requests)
          )
          break
        case RequesterType.USER:
          dispatch(requestSlice.actions.setProjectRequests(requests))
          break
        case RequesterType.SYSTEM:
          dispatch(requestSlice.actions.setSystemRequests(requests))
          break
      }
    } finally {
      dispatch(requestSlice.actions.setLoading(false))
    }
  }
}

export function getActionRequestsByCompany(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestSlice.actions.setLoading(true))
      const requests = await companyService.getActionRequestsByCompany(id)
      dispatch(requestSlice.actions.setCompanyRequests(requests))
    } finally {
      dispatch(requestSlice.actions.setLoading(false))
    }
  }
}

export function getActionRequestsOfUser(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestSlice.actions.setLoading(true))
      const requests = await userService.getActionRequestsOfUser(id)
      dispatch(requestSlice.actions.setUserRequests(requests))
    } finally {
      dispatch(requestSlice.actions.setLoading(false))
    }
  }
}

export function updateRequest(
  id: string,
  input: UpdateRequestInput,
  type: ActionRequestType
) {
  return async (dispatch: Dispatch) => {
    try {
      await requestService.updateRequest(id, input)
      switch (type) {
        case ActionRequestType.COMPANY:
          dispatch(requestSlice.actions.deleteCompanyRequest(id))
          break
        case ActionRequestType.PROJECT:
          dispatch(requestSlice.actions.deleteProjectRequest(id))
          break
      }

      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Request updated!',
          timeout: 5000
        })
      )
    } catch (error) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong!',
          timeout: 5000
        })
      )
    }
  }
}

export default requestSlice.reducer
