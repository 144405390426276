import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography
} from '@mui/material'
import { EnergyPriceDateRangeMode } from '../enums'
import { getHours } from 'date-fns'

interface EnergyPriceDateRangeModeToggleProps {
  value: EnergyPriceDateRangeMode
  onChange(value: EnergyPriceDateRangeMode): void
}

export default function EnergyPriceDateRangeModeToggle({
  value,
  onChange
}: EnergyPriceDateRangeModeToggleProps) {
  const [t] = useTranslation('common')

  /**
   * TODO: We could check if the energy prices of tomorrow are actually available.
   */
  const energyPricesOfTomorrowHaveBeenPublished = getHours(new Date()) >= 14

  function handleChange(
    event: MouseEvent<HTMLElement>,
    value: EnergyPriceDateRangeMode
  ) {
    if (value && value !== null) {
      onChange(value)
    }
  }

  return (
    <Stack direction="column" spacing={1} width="100%">
      <Typography fontSize={12} mb={1}>
        {t('showEnergyPricesOf')}
      </Typography>

      <ToggleButtonGroup
        color="primary"
        size="small"
        exclusive
        value={value}
        aria-label={t('showEnergyPricesOf')}
        onChange={handleChange}
      >
        <ToggleButton value={EnergyPriceDateRangeMode.TODAY}>
          {t('today')}
        </ToggleButton>

        <ToggleButton
          value={EnergyPriceDateRangeMode.TOMORROW}
          disabled={!energyPricesOfTomorrowHaveBeenPublished}
        >
          {t('tomorrow')}
        </ToggleButton>

        <ToggleButton value={EnergyPriceDateRangeMode.SELECTED_DATE_RANGE}>
          {t('selectedDateRange')}
        </ToggleButton>
      </ToggleButtonGroup>

      {!energyPricesOfTomorrowHaveBeenPublished && (
        <Stack>
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ mt: 2, maxWidth: '320px' }}
          >
            <Typography fontSize="0.75rem">
              {t('energyPricesOfTomorrowHelper')}
            </Typography>
          </Alert>
        </Stack>
      )}
    </Stack>
  )
}
