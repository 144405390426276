import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'
import { CssBaseline, Paper as MuiPaper } from '@mui/material'
import GlobalStyle from '../components/GlobalStyle'
import { spacing } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const Paper = styled(MuiPaper)(spacing)
const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const QRLayout: React.FC = ({ children }) => {
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  )
}

export default QRLayout
