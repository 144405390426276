import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardContent, Grid } from '@mui/material'
import { RootState } from '../redux/store'
import { getProjectAlertRules } from '../redux/slices/projects'
import { AssetType } from '../enums/AssetType'
import { SensorAggregateStatistic } from '../enums'
import {
  Asset,
  AssetGridViewMode,
  AssetQuery,
  Device,
  RuuviTag,
  Statistic
} from '../types'
import RuuviTagGridItem from './RuuviTagGridItem'
import DeviceGridItem from './DeviceGridItem'
import AssetDataGrid from './AssetDataGrid'

interface AssetGridProps {
  /**
   * The assets.
   */
  assets: Asset[]

  /**
   * The asset query.
   */
  query?: AssetQuery

  /**
   * The view mode.
   */
  viewMode?: AssetGridViewMode

  /**
   * The statistic.
   */
  statistic?: Statistic
}

export default function AssetGrid({
  assets,
  query,
  viewMode = 'large',
  statistic = 'avg'
}: AssetGridProps) {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The alert rules of the project.
   */
  const { projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  /**
   * Load alert rules of the project.
   */
  useEffect(() => {
    if (query?.projectId) {
      dispatch(getProjectAlertRules(query?.projectId))
    }
  }, [])

  return (
    <>
      {viewMode === 'large' || viewMode === 'small' ? (
        <Grid container spacing={6}>
          {assets.map((asset: Asset) => (
            <Grid
              key={asset.id}
              item
              sx={{
                width: '100%',
                height: viewMode === 'large' ? '343px' : 'auto',
                maxWidth: { sm: '100%', md: '343px' }
              }}
            >
              {asset.type === AssetType.DEVICE ? (
                <DeviceGridItem
                  device={{ ...asset.device, asset } as Device}
                  size={viewMode}
                  statistic={statistic as SensorAggregateStatistic}
                  alertRules={projectAlertRules}
                />
              ) : (
                <RuuviTagGridItem
                  ruuviTag={{ ...asset.ruuviTag, asset } as RuuviTag}
                  statistic={statistic}
                  size={viewMode}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        viewMode === 'data' && (
          <Card>
            <CardContent>
              <AssetDataGrid
                assets={assets}
                statistic={statistic}
                alertGroups={projectAlertRules}
              />
            </CardContent>
          </Card>
        )
      )}
    </>
  )
}
