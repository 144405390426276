import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardContent as MuiCardContent,
  Button as MuiButton,
  Stack,
  Typography,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CardHeader,
  IconButton,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { spacing } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../redux/store'

import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useTranslation } from 'react-i18next'
import useAuth from '../../hooks/useAuth'
import { getProject, updateProject } from '../../redux/slices/projects'
import {
  Delete,
  Edit,
  BarChart as BarChartIcon,
  AssignmentTurnedIn
} from '@mui/icons-material'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import ProjectUsers from './ProjectUsers'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { UserRole } from '../../enums/UserRole'
import { ProjectMembershipRole } from '../../enums/ProjectMembershipRole'
import { getLevelsByProjectId } from '../../redux/slices/levels'
import LevelTable from '../../components/LevelTable'
import LiftTable from '../../components/LiftTable'
import SectorTable from '../../components/SectorTable'
import PowerSupplyHierarchy from '../../components/PowerSupplyHierarchy'
import { getLiftsByProjectId } from '../../redux/slices/lifts'
import { getSectorsByProjectId } from '../../redux/slices/sectors'
import { getPowerSuppliesByProjectId } from '../../redux/slices/powerSupplies'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { MembershipRole } from '../../enums/MembershipRole'
import translateProjectType from '../../utils/translateProjectType'
import translateProjectNewOrOldType from '../../utils/translateProjectNewOrOldType'
import translateProjectWarmingMethod from '../../utils/translateProjectWarmingMethod'
import checkProjectStatus from '../../utils/checkProjectStatus'
import { ProjectStateType } from '../../enums/ProjectStateType'
import ProjectEnergyPriceInfo from '../../components/ProjectEnergyPriceInfo'
import ProjectEmissionFactorInfo from '../../components/ProjectEmissionFactorInfo'
import ProjectEnergyConsumptionStats from '../../components/ProjectEnergyConsumptionStats'
import ProjectEnergyCostStats from '../../components/ProjectEnergyCostStats'
import ProjectEmissionStats from '../../components/ProjectEmissionStats'
import ProjectAlertManagementTable from '../../components/ProjectAlertManagementTable'
import LoadingState from '../../components/LoadingState'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import projectService from '../../services/projectService'
import { createUserNotification } from '../../utils/createUserNotification'
import { ProjectErrors } from '../../enums/ProjectErrors'
import UserAlertManagementTable from '../../components/UserAlertManagementTable'
import { Project, GeoCoordinate, RuuviTag } from '../../types'
import { getProjectMembership } from '../../redux/slices/projectMemberships'
import ProjectGanttChart from '../../components/ProjectGanttChart'
import { calculateProgressByDate } from '../../utils/calculateProgressByDate'
import ProjectInput from '../../types/ProjectInput'
import translateProjectState from '../../utils/translateProjectState'
import AssetScroller from '../../components/AssetScroller'
import AssetHierarchyWrapper from '../../components/AssetHierarchyWrapper'
import { getRuuviTags } from '../../services/ruuviTagService'

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Paper = styled(MuiPaper)(spacing)
const Button = styled(MuiButton)(spacing)

export enum ProjectTab {
  DASHBOARD = 'dashboard',
  USERS = 'users',
  DEVICES = 'devices',
  RUUVI_TAGS = 'ruuvitags',
  ASSETS = 'assets',
  RUUVI_LOCATIONS = 'ruuvilocations',
  POWER_SUPPLIES = 'powersupplies',
  LEVELS = 'levels',
  LIFTS = 'lifts',
  SECTORS = 'sectors',
  ALERT_MANAGEMENT = 'alertmanagement',
  TIMELINE = 'timeline',
  MONITORING = 'monitoring'
}

// eslint-disable-next-line
function ProjectPage() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [t] = useTranslation('common')

  const { id } = useParams()

  const { currentUser } = useAuth()

  const { project, loading, projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  const { membership } = useSelector(
    (state: RootState) => state.projectMemberships
  )

  const memberId = parseInt(currentUser?.id)

  const [tab, setTab] = useState<ProjectTab>(getInitialTab())

  /**
   * The ruuvi tags of project.
   */
  const [ruuviTags, setRuuviTags] = useState<RuuviTag[]>([])

  /**
   * Indicates if ruuvi tags are loading.
   */
  const [isLoadingRuuviTags, setIsLoadingRuuviTags] = useState<boolean>(true)

  function getInitialTab(): ProjectTab {
    const hash = location.hash.substring(1).split('?')[0]
    switch (hash) {
      case ProjectTab.POWER_SUPPLIES:
        return ProjectTab.POWER_SUPPLIES
      case ProjectTab.LEVELS:
        return ProjectTab.LEVELS
      case ProjectTab.LIFTS:
        return ProjectTab.LIFTS
      case ProjectTab.SECTORS:
        return ProjectTab.SECTORS
      case ProjectTab.USERS:
        return ProjectTab.USERS
      case ProjectTab.DEVICES:
        return ProjectTab.DEVICES
      case ProjectTab.ALERT_MANAGEMENT:
        return ProjectTab.ALERT_MANAGEMENT
      case ProjectTab.TIMELINE:
        return ProjectTab.TIMELINE
      case ProjectTab.ASSETS:
        return ProjectTab.ASSETS
      case ProjectTab.MONITORING:
        return ProjectTab.MONITORING
      default:
        return ProjectTab.DASHBOARD
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    if (currentUser && id) {
      dispatch(getProject(parseInt(id)))
      dispatch(getLevelsByProjectId(parseInt(id)))
      dispatch(getLiftsByProjectId(parseInt(id)))
      dispatch(getSectorsByProjectId(parseInt(id)))
      dispatch(getPowerSuppliesByProjectId(parseInt(id)))
      dispatch(
        getProjectMembership({ memberId: memberId, projectId: parseInt(id) })
      )
      ;(async () => {
        await loadRuuviTagsOfProject(parseInt(id), controller.signal)
      })()
    }

    return () => controller.abort()
  }, [currentUser, id])

  async function loadRuuviTagsOfProject(
    projectId: number,
    signal: AbortSignal
  ): Promise<void> {
    try {
      setIsLoadingRuuviTags(true)
      setRuuviTags(await getRuuviTags({ projectId }, { signal }))
    } finally {
      setIsLoadingRuuviTags(false)
    }
  }

  const handleChange = (event: React.SyntheticEvent, tab: string): void => {
    setTab(tab as ProjectTab)
  }

  const userCanManageProject: boolean =
    currentUser?.role === UserRole.SUPERADMIN ||
    membership?.role === ProjectMembershipRole.ADMIN ||
    membership?.role === ProjectMembershipRole.OWNER

  const canProjectBeEnded: boolean =
    project?.state === ProjectStateType.PLANNED ||
    project?.state === ProjectStateType.INACTIVE ||
    project?.state === ProjectStateType.EXTENDED ||
    project?.state === ProjectStateType.ACTIVE

  const handleDelete = async (id: number) => {
    try {
      await projectService.deleteProject(id)

      handleClose()

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('projectArchiveSuccess')
        )
      )

      navigate('/projects')
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  const [open, setOpen] = useState(false)
  const [projectId, setProjectId] = useState(0)
  const [moveMarker, setMoveMarker] = useState(false)
  const [latlong, setLatlong] = useState<GeoCoordinate>({
    long: 0,
    lat: 0
  })

  useEffect(() => {
    if (project) {
      setLatlong({ long: project.long, lat: project.lat })
    }
  }, [project])

  const handleClickOpen = (id: any) => {
    setProjectId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setProjectId(0)
  }

  const [isUpdatingLocation, setIsUpdatingLocation] = useState<boolean>(false)

  async function handleLocationUpdate() {
    try {
      setIsUpdatingLocation(true)
      if (project) {
        const updatedProject = await projectService.updateProject(project.id, {
          ...project,
          company: project?.company?.id,
          lat: latlong.lat,
          long: latlong.long
        })

        dispatch(updateProject(updatedProject))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectUpdateSuccess')
          )
        )
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProjectErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setMoveMarker(false)
      setIsUpdatingLocation(false)
    }
  }

  const handleLatLong = (e: any) => {
    setLatlong({ long: e.lng, lat: e.lat })
  }

  async function handleChangeProjectStateProject(state: ProjectStateType) {
    try {
      if (project) {
        setIsSubmitting(true)
        //@ts-ignore
        const projectInput: ProjectInput = { ...project }
        projectInput.state = state
        projectInput.company = project?.company?.id

        const updatedProject = await projectService.updateProject(
          project.id,
          projectInput
        )

        dispatch(updateProject(updatedProject))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('projectUpdateSuccess')
          )
        )
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: project ? ProjectErrors.EDIT : ProjectErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setOpenProjectArchiveDialog(false)
      setIsSubmitting(false)
    }
  }
  const [openProjectArchiveDialog, setOpenProjectArchiveDialog] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY
  return (
    <React.Fragment>
      <Helmet title={t('Project.projects')} />

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        gap={6}
      >
        <Stack direction="column" gap={2}>
          <Typography variant="h3" display="inline">
            {project?.name}
          </Typography>

          <Breadcrumbs>
            <Link component={NavLink} to="/projects">
              {t('Project.projects')}
            </Link>

            <Typography>{project?.name}</Typography>
          </Breadcrumbs>
        </Stack>

        <Stack direction="row" gap={3}>
          {project &&
            (currentUser?.role === UserRole.SUPERADMIN ||
              membership?.role === ProjectMembershipRole.ADMIN) && (
              <>
                <Button
                  disabled={project?.state === ProjectStateType.ARCHIVED}
                  variant="contained"
                  endIcon={<PersonAddAlt1Icon />}
                  onClick={() =>
                    navigate(`/projects/${project?.id}/invite-user`)
                  }
                >
                  {t('Project.inviteUsersBtn')}
                </Button>

                <Button
                  variant="contained"
                  endIcon={<BarChartIcon />}
                  onClick={() => navigate(`/projects/${project?.id}/stats`)}
                >
                  {t('stats')}
                </Button>

                {canProjectBeEnded && (
                  <Button
                    variant="contained"
                    color="error"
                    endIcon={<AssignmentTurnedIn />}
                    onClick={() =>
                      handleChangeProjectStateProject(ProjectStateType.ENDED)
                    }
                  >
                    {t('endProject')}
                  </Button>
                )}

                {project.state === ProjectStateType.ENDED && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenProjectArchiveDialog(true)}
                  >
                    {t('archiveProject')}
                  </Button>
                )}

                {project.state === ProjectStateType.ARCHIVED && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleChangeProjectStateProject(ProjectStateType.ACTIVE)
                    }
                  >
                    {t('recoverProject')}
                  </Button>
                )}
              </>
            )}
        </Stack>
      </Stack>

      <Dialog
        open={openProjectArchiveDialog}
        onClose={() => setOpenProjectArchiveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isSubmitting ? (
          <DialogContentText width={'500px'} height={'200px'}>
            <LoadingState />
          </DialogContentText>
        ) : (
          <React.Fragment>
            <DialogTitle id="alert-dialog-title">
              {t('archiveProjectTitle')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('archiveProjectInfo')}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() =>
                  handleChangeProjectStateProject(ProjectStateType.ARCHIVED)
                }
                color="error"
              >
                {t('archiveProject')}
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpenProjectArchiveDialog(false)}
              >
                {t('cancel')}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>

      <Divider mt={6} />

      <Box sx={{ width: '100%' }}>
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={handleChange}
            >
              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#dashboard')
                }
                label={t('Project.information')}
                value={ProjectTab.DASHBOARD as string}
              />

              <Tab
                onClick={() => window.history.replaceState({}, '', '#timeline')}
                label={t('timeline')}
                value={ProjectTab.TIMELINE as string}
              />

              <Tab
                onClick={() => window.history.replaceState({}, '', '#assets')}
                label={t('deviceView')}
                value={ProjectTab.ASSETS as string}
              />

              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#monitoring')
                }
                label={t('deviceMonitoring')}
                value={ProjectTab.MONITORING as string}
              />

              {userCanManageProject && (
                <Tab
                  onClick={() => window.history.replaceState({}, '', '#levels')}
                  label={t('Project.levels')}
                  value={ProjectTab.LEVELS as string}
                />
              )}

              {userCanManageProject && (
                <Tab
                  onClick={() => window.history.replaceState({}, '', '#lifts')}
                  label={t('Project.lifts')}
                  value={ProjectTab.LIFTS as string}
                />
              )}

              {userCanManageProject && (
                <Tab
                  onClick={() =>
                    window.history.replaceState({}, '', '#sectors')
                  }
                  label={t('Project.sectors')}
                  value={ProjectTab.SECTORS as string}
                />
              )}

              {userCanManageProject && (
                <Tab
                  onClick={() => window.history.replaceState({}, '', '#users')}
                  label={t('Project.users')}
                  value={ProjectTab.USERS as string}
                />
              )}

              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#alertmanagement')
                }
                label={t('alertManagement')}
                value={ProjectTab.ALERT_MANAGEMENT as string}
              />
            </TabList>
          </Box>

          <Box mt={6}>
            <TabPanel value={ProjectTab.DASHBOARD as string}>
              <Box sx={{ width: '100%' }}>
                <Grid container spacing={6}>
                  <Grid item xs={10} md={11}>
                    <Stepper activeStep={2}>
                      <Step>
                        <StepLabel
                          StepIconComponent={ArrowCircleRightOutlinedIcon}
                        >
                          {t('Project.startDate')}
                          {': '}
                          {new Date(
                            project?.startDate as string
                          ).toLocaleDateString()}
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel
                          StepIconComponent={RoomOutlinedIcon}
                          optional={
                            <Typography variant="caption">
                              {t('Project.today')}:{' '}
                              {new Date().toLocaleDateString()}
                            </Typography>
                          }
                        >
                          {t('Project.progress')}:{' '}
                          {calculateProgressByDate(
                            project?.startDate,
                            project?.endDate
                          )}
                          %
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={CheckCircleOutlineIcon}>
                          {t('Project.endDate')}
                          {': '}
                          {new Date(
                            project?.endDate as string
                          ).toLocaleDateString()}
                        </StepLabel>
                      </Step>
                    </Stepper>
                  </Grid>
                  {userCanManageProject && (
                    <Grid item xs={2} md={1} mt={2}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          navigate(
                            `/projects/${project?.id}/edit?timeline=true`
                          )
                        }
                      >
                        {t('edit')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>

              {project && (
                <>
                  <Grid container spacing={6} my={6}>
                    <Grid item xs={12} md={4}>
                      <ProjectEnergyConsumptionStats project={project} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEnergyCostStats project={project} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEmissionStats project={project} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={6} mb={6}>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEnergyPriceInfo project={project} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ProjectEmissionFactorInfo project={project} />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl>
                  <Card mb={6}>
                    <CardHeader
                      title={t('Project.builderInfo')}
                      action={
                        <>
                          {currentUser?.role === UserRole.SUPERADMIN ? (
                            <Edit
                              data-cy="edit"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                navigate(`/projects/${project?.id}/edit`)
                              }
                            />
                          ) : null}
                        </>
                      }
                    />

                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('Project.name')}</TableCell>
                            <TableCell align="right">
                              {t('Project.address')}
                            </TableCell>
                            <TableCell align="right">
                              {t('Project.city')}
                            </TableCell>
                            <TableCell align="right">
                              {t('Project.zip')}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {project && project.company && (
                            <TableRow key={project.id}>
                              <TableCell component="th" scope="row">
                                {project.company.name}
                              </TableCell>
                              <TableCell align="right">
                                {project.company.address}
                              </TableCell>
                              <TableCell align="right">
                                {project.company.city}
                              </TableCell>
                              <TableCell align="right">
                                {project.company.zip}
                              </TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Card>
                </Grid>
              </Grid>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t('projectArchiveTitle')}
                </DialogTitle>

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t('projectArchiveInfo')}
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => handleDelete(projectId)}>
                    {t('Projects.yes')}
                  </Button>
                  <Button onClick={handleClose} autoFocus>
                    {t('Projects.no')}
                  </Button>
                </DialogActions>
              </Dialog>

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card mb={6}>
                    {currentUser?.role == UserRole.SUPERADMIN ||
                    membership?.role == ProjectMembershipRole.ADMIN ||
                    membership?.role == ProjectMembershipRole.OWNER ? (
                      <CardHeader
                        action={
                          <>
                            <IconButton
                              disabled={
                                project?.state === ProjectStateType.ARCHIVED
                              }
                            >
                              <Edit
                                data-testid="edit"
                                data-cy="edit"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(`/projects/${project?.id}/edit`)
                                }
                              />
                            </IconButton>{' '}
                            <IconButton>
                              <Delete
                                data-testid="delete"
                                data-cy="delete"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleClickOpen(project?.id)}
                              />
                            </IconButton>
                          </>
                        }
                        title={t('Project.projectInfo')}
                      />
                    ) : (
                      <CardHeader title={t('Project.projectInfo')} />
                    )}

                    <Paper>
                      {project && (
                        <Table>
                          <TableRow>
                            <TableCell variant="head">{t('status')}</TableCell>
                            <TableCell>
                              <Chip
                                label={t(
                                  translateProjectState(project.state) as string
                                )}
                                color={checkProjectStatus(
                                  project.state as ProjectStateType
                                )}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.name')}
                            </TableCell>
                            <TableCell>{project.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.address')}
                            </TableCell>
                            <TableCell>{project.address}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.city')}
                            </TableCell>
                            <TableCell>{project.city}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.zip')}
                            </TableCell>
                            <TableCell>{project.zip}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('countryCode')}
                            </TableCell>
                            <TableCell>{project.countryCode ?? '-'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.area')}
                            </TableCell>
                            <TableCell>{project.area}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.type')}
                            </TableCell>
                            <TableCell>
                              {t(translateProjectType(project.type) as string)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.new')} / {t('Project.renovation')}
                            </TableCell>
                            <TableCell>
                              {t(
                                translateProjectNewOrOldType(
                                  project.new
                                ) as string
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell variant="head">
                              {t('Project.warming')}
                            </TableCell>
                            <TableCell>
                              {t(
                                translateProjectWarmingMethod(
                                  project.warmingMethod
                                ) as string
                              )}
                            </TableCell>
                          </TableRow>
                        </Table>
                      )}
                    </Paper>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8}>
                  {loading || isSubmitting ? (
                    <LoadingState />
                  ) : (
                    <Card mb={6}>
                      <CardHeader
                        action={
                          <Grid container spacing={6} mt={1}>
                            {((currentUser?.role === UserRole.SUPERADMIN &&
                              moveMarker === false) ||
                              (membership?.role ===
                                ProjectMembershipRole.ADMIN &&
                                moveMarker === false)) && (
                              <Button
                                size="small"
                                disabled={
                                  project?.state === ProjectStateType.ARCHIVED
                                }
                                variant="contained"
                                onClick={() => setMoveMarker(true)}
                              >
                                Edit
                              </Button>
                            )}

                            {moveMarker === true && (
                              <Typography align="right">
                                <Button
                                  disabled={isUpdatingLocation}
                                  size="small"
                                  variant="contained"
                                  onClick={() => handleLocationUpdate()}
                                >
                                  Save
                                </Button>
                              </Typography>
                            )}
                          </Grid>
                        }
                        title={t('Project.projectLocation')}
                      />

                      {project && isUpdatingLocation === false ? (
                        <Map
                          mapboxAccessToken={apiKey}
                          initialViewState={{
                            longitude: project?.long,
                            latitude: project?.lat,
                            zoom: 13
                          }}
                          mapStyle="mapbox://styles/mapbox/streets-v9"
                          style={{
                            width: '100%',
                            minHeight: 590,
                            height: '100%'
                          }}
                        >
                          <Marker
                            draggable={moveMarker}
                            longitude={latlong.long}
                            latitude={latlong.lat}
                            onDragEnd={(e) => handleLatLong(e.lngLat)}
                          ></Marker>
                        </Map>
                      ) : (
                        <LoadingState />
                      )}
                    </Card>
                  )}
                </Grid>
              </Grid>
            </TabPanel>

            {project && (
              <TabPanel value={ProjectTab.TIMELINE as string}>
                <ProjectGanttChart
                  project={project}
                  userCanManageProject={userCanManageProject}
                />
              </TabPanel>
            )}
            <TabPanel value={ProjectTab.ASSETS as string}>
              <AssetHierarchyWrapper
                project={project as Project}
                ruuviTags={ruuviTags}
                userCanManageProject={userCanManageProject}
              />
            </TabPanel>

            <TabPanel value={ProjectTab.MONITORING as string}>
              {project && (
                <AssetScroller initialQuery={{ projectId: project.id }} />
              )}
            </TabPanel>

            {userCanManageProject && (
              <TabPanel value={ProjectTab.LEVELS as string}>
                <LevelTable project={project} />
              </TabPanel>
            )}

            {userCanManageProject && (
              <TabPanel value={ProjectTab.LIFTS as string}>
                <LiftTable project={project} />
              </TabPanel>
            )}

            {userCanManageProject && (
              <TabPanel value={ProjectTab.SECTORS as string}>
                <SectorTable project={project} />
              </TabPanel>
            )}

            {userCanManageProject && (
              <TabPanel value={ProjectTab.USERS as string}>
                <ProjectUsers />
              </TabPanel>
            )}

            <TabPanel value={ProjectTab.ALERT_MANAGEMENT as string}>
              <ProjectAlertManagementTable
                project={project}
                userCanManageProject={userCanManageProject}
              />
              <Divider mt={6} />
              <UserAlertManagementTable project={project} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </React.Fragment>
  )
}

export default ProjectPage
