import { ChangeEvent, FormEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Button,
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import LoadingButton from '@mui/lab/LoadingButton'
import { EmissionFactor, EnergyPrice, Project } from '../types'
import { updateProject } from '../redux/slices/projects'
import { useAuth } from '../hooks'
import { useState } from 'react'
import projectService from '../services/projectService'
import LoadingState from './LoadingState'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import {
  userIsAdminOfProject,
  userIsOwnerOfProject,
  userIsSuperadmin
} from '../utils/auth'
import { getEmissionFactorNow } from '../services/emissionFactorService'

interface ProjectEmissionFactorInfoProps {
  project: Project
}

export default function ProjectEmissionFactorInfo({
  project
}: ProjectEmissionFactorInfoProps) {
  const [t] = useTranslation('common')

  const dispatch = useDispatch()

  const theme = useTheme()

  const { currentUser } = useAuth()

  const [fixedEmissionFactor, setFixedEmissionFactor] = useState<number | null>(
    project.fixedEmissionFactor
  )

  const [emissionFactor, setEmissionFactor] = useState<EmissionFactor>()

  const [isBooting, setIsBooting] = useState<boolean>(true)

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const isUsingRealtimeEmissionFactor = fixedEmissionFactor === null

  const emissionFactorOfProject: number = isUsingRealtimeEmissionFactor
    ? emissionFactor?.gco2_per_kwh ?? 0
    : project.fixedEmissionFactor ?? 0

  const currentUserCanEditProject =
    userIsSuperadmin(currentUser) ||
    userIsOwnerOfProject(currentUser, project) ||
    userIsAdminOfProject(currentUser, project)

  const unitColor =
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.5)'
      : 'rgba(255, 255, 255, 0.6)'

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => {
      await boot(controller.signal)
    })()
    return () => controller.abort()
  }, [])

  async function boot(signal: AbortSignal) {
    try {
      setIsBooting(true)
      await loadEmissionFactor(signal)
    } finally {
      setIsBooting(false)
    }
  }

  async function loadEmissionFactor(signal: AbortSignal): Promise<void> {
    setEmissionFactor(
      await getEmissionFactorNow(
        {
          lat: project.lat,
          long: project.long
        },
        {
          signal
        }
      )
    )
  }

  async function saveProject(): Promise<void> {
    try {
      setIsSaving(true)

      const updatedProject = await projectService.updateProject(project.id, {
        name: project.name,
        address: project.address,
        zip: project.zip,
        city: project.city,
        countryCode: project.countryCode,
        area: project.area,
        type: project.type,
        new: project.new,
        warmingMethod: project.warmingMethod,
        hideCosts: project.hideCosts,
        fixedEnergyPrice: project.fixedEnergyPrice,
        fixedEmissionFactor,
        company: project?.company?.id,
        lat: project.lat,
        long: project.long,
        state: project.state,
        startDate: project.startDate,
        endDate: project.endDate,
        customerReference: project.customerReference
      })

      dispatch(updateProject(updatedProject))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('projectUpdateSuccess')
        )
      )
    } finally {
      setIsSaving(false)
    }
  }

  function changeFixedEmissionFactor(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const value =
      event.currentTarget.value !== ''
        ? parseFloat(event.currentTarget.value)
        : null

    setFixedEmissionFactor(value)
  }

  function changeIsUsingRealtimeEmissionFactor(): void {
    if (isUsingRealtimeEmissionFactor) {
      const value = emissionFactor?.gco2_per_kwh ?? 0
      setFixedEmissionFactor(parseFloat(value.toFixed(2)))
    } else {
      setFixedEmissionFactor(null)
    }
  }

  function handleCancel(): void {
    setIsEditing(false)
    setFixedEmissionFactor(project.fixedEmissionFactor)
  }

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()
    await saveProject()
    setIsEditing(false)
  }

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" mb={4}>
            {t('emissionFactor')}
          </Typography>

          <Stack direction="row" spacing={3}>
            {!isBooting && !isEditing && currentUserCanEditProject && (
              <IconButton
                title={t('edit')}
                size="small"
                onClick={(event) => {
                  setIsEditing(true)
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>

        {isBooting ? (
          <LoadingState />
        ) : isEditing ? (
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ mt: 3 }}>
              <FormGroup sx={{ px: 3, pb: 3 }}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isUsingRealtimeEmissionFactor}
                      disabled={isSaving}
                      onChange={changeIsUsingRealtimeEmissionFactor}
                    />
                  }
                  label={t('useRealtimeEmissionFactor') as string}
                  labelPlacement="end"
                />
              </FormGroup>

              {!isUsingRealtimeEmissionFactor ? (
                <TextField
                  label="gCO2/h"
                  type="number"
                  value={fixedEmissionFactor}
                  disabled={isSaving}
                  onChange={changeFixedEmissionFactor}
                  fullWidth
                />
              ) : (
                <Alert variant="outlined" severity="info">
                  {t('emissionFactorHelper')}
                </Alert>
              )}

              <Stack direction="row" spacing={3}>
                <Button
                  type="button"
                  disabled={isSaving}
                  onClick={handleCancel}
                >
                  {t('cancel')}
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSaving}
                >
                  {t('save')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        ) : (
          <>
            <Typography variant="h3" fontWeight={400}>
              {emissionFactorOfProject.toFixed(2)}
            </Typography>
            <Typography fontWeight={600} color={unitColor}>
              gCO2/h
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}
