import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  Divider as MuiDivider,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { getProjectsByLocation } from '../../services/projectService'
import RequestAccessForm from '../../components/RequestAccessForm'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { createNotification } from '../../redux/slices/notifications'
import { RequestAccessProject } from '../../types/RequestAccessProject'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const Grid = styled(MuiGrid)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)

const initialValues = {
  address: '',
  city: ''
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
}

const validationSchema = Yup.object().shape({
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required')
})

export default function RequestAccessIndex() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const [projects, setProjects] = useState<RequestAccessProject[]>()
  if (projects && projects.length === 0) {
    dispatch<any>(
      createNotification({
        show: true,
        type: 'warning',
        message: t('Project.notFound'),
        timeout: 5000
      })
    )
  }

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error, options)
    } else {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: t('Manufacturing.notSupported'),
          timeout: 5000
        })
      )
    }
  }

  async function success(position: any) {
    const latitude = position.coords.latitude
    const longitude = position.coords.longitude
    setProjects(
      await getProjectsByLocation({
        lat: latitude,
        long: longitude
      })
    )
  }

  function error() {
    dispatch<any>(
      createNotification({
        show: true,
        type: 'warning',
        message: t('Project.notFound'),
        timeout: 5000
      })
    )
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      handleLocationClick()
    }
  }

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setProjects(
        await getProjectsByLocation({
          city: values.city,
          address: values.address
        })
      )
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
          errors
        }) => (
          <Card mb={6}>
            <CardContent>
              <Button
                onClick={() => {
                  navigate('/')
                }}
                variant="contained"
                mt={3}
              >
                {t('Project.cancel')}
              </Button>

              <Divider my={6} />
              {projects === undefined || (projects && projects.length === 0) ? (
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title={t('Project.findProject')}
                    align="center"
                    titleTypographyProps={{ variant: 'h6' }}
                  />
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl>
                      <TextField
                        name="address"
                        value={values.address}
                        label={t('Project.address')}
                        error={Boolean(touched.address && errors.address)}
                        fullWidth
                        helperText={touched.address && errors.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl>
                      <TextField
                        name="city"
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        label={t('Project.city')}
                        fullWidth
                        helperText={touched.city && errors.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        mt={4}
                      >
                        {t('Project.find')}
                        <SearchIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <RequestAccessForm projects={projects} />
              )}
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxChange} />}
                label={t('Manufacturing.wantsNearbyProjects') as string}
              />
            </CardContent>
          </Card>
        )}
      </Formik>
    </React.Fragment>
  )
}
