import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isBefore } from 'date-fns'
import { getEnergyPrices } from '../services/energyPriceService'
import { getSensorAggregates } from '../services/sensorAggregateService'
import useAppSettings from '../hooks/useAppSettings'
import { CalculationMode, StartDateMode } from '../enums'
import SensorAggregateInterval from '../enums/SensorAggregateInterval'
import EnergyPriceType from '../enums/EnergyPriceType'
import DateInterval from '../enums/DateInterval'
import {
  DateRange,
  Device,
  EnergyPrice,
  EnergyCostSettings,
  EnergyCostQuery,
  Project,
  SensorAggregate,
  GeoCoordinate,
  EnergyCost,
  ActiveProject
} from '../types'
import { getDeviceGeoCoordinate } from '../utils/device'
import { getStartDateByMode, humanizeDayRange } from '../utils/date'
import EnergyCostStats from './EnergyCostStats'
import { getEnergyCosts } from '../services/deviceService'
interface DeviceEnergyCostStatsProps {
  device: Device
  project?: Project | ActiveProject
  dateRange: DateRange
  loading?: boolean
}

export default function DeviceEnergyCostStats({
  device,
  project,
  dateRange,
  loading = false
}: DeviceEnergyCostStatsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  /**
   * The energy cost settings.
   */
  const [settings, setSettings] = useState<EnergyCostSettings>({
    calculationMode: getInitialCalculationMode(),
    startDateMode: getInitialStartDateMode(),
    energyPriceType: getInitialEnergyPriceType(),
    energyPrice: getInitialEnergyPrice()
  })

  /**
   * The energy costs of date range.
   */
  const [energyCosts, setEnergyCosts] = useState<EnergyCost[]>([])

  /**
   * The energy costs before the date range.
   */
  const [energyCostsBeforeDateRange, setEnergyCostsBeforeDateRange] = useState<
    EnergyCost[]
  >([])

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * The energy cost query.
   */
  const query: EnergyCostQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The energy cost query since before the time period.
   */
  const queryBeforeDateRange: EnergyCostQuery = {
    from: getStartDateByMode(dateRange.from, settings.startDateMode, project),
    to: dateRange.from
  }

  /**
   * Indicates if the selected date range
   */
  const isUsingSelectedDateRange =
    settings.startDateMode === StartDateMode.SELECTED_DATE

  /**
   * The project area as square meters.
   */
  const area: number | undefined = project?.area

  const startDateModeOptions = project
    ? [
        StartDateMode.START_OF_PROJECT,
        StartDateMode.START_OF_MONTH,
        StartDateMode.SELECTED_DATE
      ]
    : [StartDateMode.START_OF_MONTH, StartDateMode.SELECTED_DATE]

  /**
   * Boot the component.
   */
  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (isUsingSelectedDateRange) {
        await loadEnergyCosts(signal)
        setEnergyCostsBeforeDateRange([])
      } else {
        await Promise.all([
          loadEnergyCosts(signal),
          loadEnergyCostsBeforeDateRange(signal)
        ])
      }
    } finally {
      setIsBooting(false)
    }
  }

  /**
   * Load energy costs.
   */
  async function loadEnergyCosts(signal?: AbortSignal): Promise<void> {
    setEnergyCosts(
      await getEnergyCosts(
        device.name,
        {
          from: query.from,
          to: query.to
        },
        {
          signal
        }
      )
    )
  }

  /**
   * Load energy costs before the date range.
   */
  async function loadEnergyCostsBeforeDateRange(
    signal?: AbortSignal
  ): Promise<void> {
    setEnergyCostsBeforeDateRange(
      await getEnergyCosts(
        device.name,
        {
          from: queryBeforeDateRange.from,
          to: queryBeforeDateRange.to
        },
        {
          signal
        }
      )
    )
  }

  /**
   * Change the calculation mode.
   */
  function changeCalculationMode(calculationMode: CalculationMode): void {
    const appSettings = useAppSettings()
    const deviceStatsSettings = appSettings.deviceStatsSettings

    setSettings({
      ...settings,
      calculationMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        deviceStatsSettings: {
          ...deviceStatsSettings,
          costOfElectricityCalculationMode: calculationMode
        }
      })
    )
  }

  /**
   * Change the start date mode.
   */
  function changeStartDateMode(startDateMode: StartDateMode): void {
    const appSettings = useAppSettings()
    const deviceStatsSettings = appSettings.deviceStatsSettings

    setSettings({
      ...settings,
      startDateMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        deviceStatsSettings: {
          ...deviceStatsSettings,
          costOfElectricityStartDateMode: startDateMode
        }
      })
    )
  }

  /**
   * Change the energy price mode.
   */
  function changeEnergyPriceType(energyPriceType: EnergyPriceType): void {
    setSettings({
      ...settings,
      energyPriceType
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        energyPriceType
      })
    )
  }

  /**
   * Change the energy price.
   */
  function changeEnergyPrice(energyPrice: number | null): void {
    // If the value is null, then use the fixed energy price of the project.
    if (energyPrice === null) {
      energyPrice = project?.fixedEnergyPrice ?? null
    }

    setSettings({ ...settings, energyPrice })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        energyPrice: energyPrice
      })
    )
  }

  /**
   * Get the initial energy price type.
   */
  function getInitialEnergyPriceType(): EnergyPriceType {
    return project?.fixedEnergyPrice
      ? EnergyPriceType.FIXED
      : EnergyPriceType.SPOT
  }

  /**
   * Get the initial energy price.
   */
  function getInitialEnergyPrice(): number | null {
    return project?.fixedEnergyPrice ?? null
  }

  function getInitialCalculationMode(): number {
    return (
      appSettings.deviceStatsSettings?.costOfElectricityCalculationMode ??
      CalculationMode.CUMULATIVE
    )
  }

  function getInitialStartDateMode(): StartDateMode {
    if (
      appSettings.deviceStatsSettings?.costOfElectricityStartDateMode &&
      appSettings.deviceStatsSettings?.costOfElectricityStartDateMode ===
        StartDateMode.START_OF_PROJECT &&
      project === undefined
    ) {
      return StartDateMode.SELECTED_DATE
    } else
      return (
        appSettings.deviceStatsSettings?.costOfElectricityStartDateMode ??
        StartDateMode.SELECTED_DATE
      )
  }

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [device, query.from, query.to, settings.startDateMode])

  return (
    <EnergyCostStats
      energyCosts={energyCosts}
      energyCostsBeforeDateRange={energyCostsBeforeDateRange}
      query={query}
      settings={settings}
      dateRange={dateRange}
      area={area}
      loading={loading || isBooting}
      startDateModeOptions={startDateModeOptions}
      onChangeCalculationMode={changeCalculationMode}
      onChangeStartDateMode={changeStartDateMode}
      onChangeEnergyPrice={changeEnergyPrice}
      onChangeEnergyPriceType={changeEnergyPriceType}
    />
  )
}
