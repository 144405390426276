import { useState, useEffect } from 'react'
import { CalculationMode, StartDateMode } from '../enums'
import {
  DateRange,
  EnergyConsumptionSettings,
  EnergyConsumptionQuery,
  Project,
  EnergyConsumption,
  ActiveProject
} from '../types'
import { getStartDateByMode } from '../utils/date'
import EnergyConsumptionStats from './EnergyConsumptionStats'
import { getEnergyConsumptionsOfProject } from '../services/projectService'
import { SettingsEthernetSharp } from '@mui/icons-material'
import useAppSettings from '../hooks/useAppSettings'
import { isFuture } from 'date-fns'

interface ProjectEnergyConsumptionStatsProps {
  initialSettings?: EnergyConsumptionSettings
  project: Project | ActiveProject
  dateRange?: DateRange
  loading?: boolean
}

function getInitialCalculationMode(): number {
  const appSettings = useAppSettings()
  return (
    appSettings.projectStatsSettings?.emissionCalculationMode ??
    CalculationMode.CUMULATIVE
  )
}

function getInitialStartDateMode(): StartDateMode {
  const appSettings = useAppSettings()

  return (
    appSettings.projectStatsSettings?.emissionStartDateMode ??
    StartDateMode.SELECTED_DATE
  )
}

export default function ProjectEnergyConsumptionStats({
  project,
  dateRange,
  initialSettings = {
    calculationMode: getInitialCalculationMode(),
    startDateMode: getInitialStartDateMode()
  },
  loading = false
}: ProjectEnergyConsumptionStatsProps) {
  /**
   * The settings.
   */
  const [settings, setSettings] =
    useState<EnergyConsumptionSettings>(initialSettings)

  /**
   * The energy consumptions.
   */
  const [energyConsumptions, setEnergyConsumptions] = useState<
    EnergyConsumption[]
  >([])

  /**
   * The energy consumptions before the date range.
   */
  const [
    energyConsumptionsBeforeDateRange,
    setEnergyConsumptionsBeforeDateRange
  ] = useState<EnergyConsumption[]>([])

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * The start date of the project.
   */
  const startDate = new Date(project.startDate)

  /**
   * The end date of the project.
   */
  const endDate = new Date(project.endDate)

  /**
   * The date range of the project.
   */
  const dateRangeOfProject = {
    from: startDate,
    to: isFuture(endDate) ? new Date() : endDate
  }

  /**
   * The used date range depending if a date range was provided or not.
   */
  const usedDateRange = dateRange ? dateRange : dateRangeOfProject

  /**
   * The energy consumption query.
   */
  const query: EnergyConsumptionQuery = {
    from: usedDateRange.from,
    to: usedDateRange.to
  }

  /**
   * The energy consumption query before the date range.
   */
  const queryBeforeDateRange: EnergyConsumptionQuery = {
    from: getStartDateByMode(
      usedDateRange.from,
      settings.startDateMode,
      project
    ),
    to: usedDateRange.from
  }

  /**
   * The project area as square meters.
   */
  const area: number | undefined = project?.area

  /**
   * Indicates if the selected date range is used.
   */
  const isUsingSelectedDateRange =
    settings.startDateMode === StartDateMode.SELECTED_DATE

  /**
   * Indicates if the start date mode options should be displayed.
   */
  const showStartDateModeOptions = dateRange !== undefined

  /**
   * Boot the component.
   */
  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (isUsingSelectedDateRange) {
        await loadEnergyConsumptions(signal)
        setEnergyConsumptionsBeforeDateRange([])
      } else {
        await Promise.all([
          loadEnergyConsumptions(signal),
          loadEnergyConsumptionsBeforeDateRange(signal)
        ])
      }
    } finally {
      setIsBooting(false)
    }
  }

  /**
   * Load the energy consumptions of the project.
   */
  async function loadEnergyConsumptions(signal?: AbortSignal): Promise<void> {
    setEnergyConsumptions(
      await getEnergyConsumptionsOfProject(project.id, query, {
        signal
      })
    )
  }

  /**
   * Load the energy consumptions of the project.
   */
  async function loadEnergyConsumptionsBeforeDateRange(
    signal?: AbortSignal
  ): Promise<void> {
    setEnergyConsumptionsBeforeDateRange(
      await getEnergyConsumptionsOfProject(project.id, queryBeforeDateRange, {
        signal
      })
    )
  }

  /**
   * Change the calculation mode.
   */
  function changeCalculationMode(calculationMode: CalculationMode): void {
    const appSettings = useAppSettings()
    const projectStatsSettings = appSettings.projectStatsSettings
    setSettings({
      ...settings,
      calculationMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        projectStatsSettings: {
          ...projectStatsSettings,
          consumptionOfElectricityCalculationMode: calculationMode
        }
      })
    )
  }

  /**
   * Change the start date mode.
   */
  function changeStartDateMode(startDateMode: StartDateMode): void {
    const appSettings = useAppSettings()
    const projectStatsSettings = appSettings.projectStatsSettings
    setSettings({
      ...settings,
      startDateMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        projectStatsSettings: {
          ...projectStatsSettings,
          consumptionOfElectricityStartDateMode: startDateMode
        }
      })
    )
  }

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [project, dateRange?.from, dateRange?.to, settings.startDateMode])

  return (
    <EnergyConsumptionStats
      energyConsumptions={energyConsumptions}
      energyConsumptionsBeforeDateRange={energyConsumptionsBeforeDateRange}
      query={query}
      settings={settings}
      dateRange={dateRange}
      area={area}
      loading={loading || isBooting}
      showStartDateModeOptions={showStartDateModeOptions}
      onChangeCalculationMode={changeCalculationMode}
      onChangeStartDateMode={changeStartDateMode}
    />
  )
}
