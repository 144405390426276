import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import useAxios from '../hooks/useAxios'
import EmissionFactor from '../types/EmissionFactor'
import EmissionFactorQuery from '../types/EmissionFactorQuery'
import GeoCoordinate from '../types/GeoCoordinate'

const axios: AxiosInstance = useAxios()

export async function getEmissionFactors(
  { lat, long, from, to }: EmissionFactorQuery,
  config: AxiosRequestConfig = {}
): Promise<EmissionFactor[]> {
  const response: AxiosResponse = await axios.get('/emission-factors', {
    params: {
      lat,
      long,
      from: from.toISOString(),
      to: to.toISOString()
    },
    ...config
  })

  return response.data
}

export async function getEmissionFactorNow(
  { lat, long }: GeoCoordinate,
  config: AxiosRequestConfig = {}
): Promise<EmissionFactor> {
  const response: AxiosResponse = await axios.get('/emission-factors/now', {
    params: {
      lat,
      long
    },
    ...config
  })

  return response.data
}
