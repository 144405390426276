import React, { useEffect } from 'react'
import styled, { css, withTheme, ThemeProps } from 'styled-components/macro'
import { rgba } from 'polished'
import { alpha } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography as MuiTypography,
  Theme,
  Tabs,
  Tab,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Chip as MuiChip,
  Link
} from '@mui/material'
import { spacing } from '@mui/system'
import { NavLink } from 'react-router-dom'

import { green, grey, blue, blueGrey, red } from '@mui/material/colors'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './../../redux/store'

import { useTranslation } from 'react-i18next'

import { Helmet } from 'react-helmet-async'

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      background: ${(props) => rgba(props.theme.palette.primary.main, 0.125)};
      color: ${(props) => props.theme.palette.primary.main};
    `}
`
const Typography = styled(MuiTypography)(spacing)

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`
const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`

const Percentage = styled(MuiTypography)<{
  percentagecolor: string
  illustration?: string
}>`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${(props) =>
    props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    css`
      color: ${(props) => rgba(props.theme.palette.primary.main, 0.85)};
    `}
`

// function ConditionCards({ theme }: ThemeProps<Theme>) {
function ConditionCards(props: any) {
  const { conditions } = props
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  return (
    <Grid container spacing={6}>
      {conditions.map((row: any) => (
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" mb={4}>
                {row.name} ({row.location})
              </Typography>
              <Typography variant="h3" mb={3}>
                <Box fontWeight="fontWeightRegular">
                  {row.temperature} °C <br /> {row.humidity} % <br />{' '}
                  {row.movement} movements
                </Box>
              </Typography>
              <Chip
                onClick={() => navigate(`/conditions/${row.id}`)}
                label={'=>'}
              />
              <Percentage
                variant="subtitle2"
                color="textSecondary"
                percentagecolor={green[500]}
                //illustration={illustration}
              >
                <span>{row.timestamp}</span> {'Last measurement'}
              </Percentage>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

/**
 * @deprecated
 */
export default function Conditions() {
  //   const dispatch = useDispatch()
  //   const { devices, device, show, loading } = useSelector(
  //     (state: RootState) => state.devices
  //   )
  const [t] = useTranslation('common')
  //   const { currentUser } = useAuth()

  //   useEffect(() => {
  //     if (currentUser?.role === 'Superadmin') {
  //       dispatch(fetchDevices())
  //     } else if (currentUser?.role === 'Admin') {
  //       dispatch(getDevicesByCompanyId(currentUser?.company.id))
  //     } else {
  //       dispatch(getDevicesByUser(currentUser?.activeProject.id))
  //     }
  //   }, [dispatch])

  const conditions = [
    {
      id: 1,
      name: 'Device 1',
      location: 'Sector 1',
      temperature: 10,
      humidity: 10,
      movement: 111,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 2,
      name: 'Device 2',
      location: 'Sector 2',
      temperature: 16,
      humidity: 20,
      movement: 129,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 3,
      name: ' Device 3',
      location: 'Sector 3',
      temperature: 23,
      humidity: 5,
      movement: 19,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 4,
      name: 'Device 4',
      location: 'Sector 4',
      temperature: 18,
      humidity: 18,
      movement: 369,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 5,
      name: 'Device 5',
      location: 'Sector 2',
      temperature: 15,
      humidity: 78,
      movement: 853,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 6,
      name: ' Device 6',
      location: 'Sector 3',
      temperature: 15,
      humidity: 64,
      movement: 842,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 7,
      name: 'Device 7',
      location: 'Sector 7',
      temperature: 9,
      humidity: 40,
      movement: 201,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 8,
      name: 'Device 8',
      location: 'Sector 8',
      temperature: 19,
      humidity: 76,
      movement: 582,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 9,
      name: ' Device 9',
      location: 'Sector 9',
      temperature: 10,
      humidity: 10,
      movement: 111,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 10,
      name: 'Device 10',
      location: 'Sector 10',
      temperature: 10,
      humidity: 10,
      movement: 111,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 11,
      name: 'Device 11',
      location: 'Sector 11',
      temperature: 10,
      humidity: 10,
      movement: 111,
      timestamp: '23.5.2023 11.59.00'
    },
    {
      id: 12,
      name: ' Device 12',
      location: 'Sector 12',
      temperature: 10,
      humidity: 10,
      movement: 111,
      timestamp: '23.5.2023 11.59.00'
    }
  ]

  // useEffect(() => {
  //   dispatch(fetchDevices())
  // }, [dispatch])

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        {'Conditions'}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {'Frontpage'}
        </Link>
        <Typography>{'Conditions'}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ConditionCards conditions={conditions} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
