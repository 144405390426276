import { AxiosResponse } from 'axios'
import { useAxios } from '../hooks'
const axios = useAxios()
const baseUrl =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL

const getSensor = (id: number): Promise<AxiosResponse> => {
  try {
    const response = axios.get(baseUrl + `sensors/${id}`)
    return response
  } catch (e) {
    return e as never
  }
}

export default { getSensor }
