import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectWarmingMethodType } from '../enums/ProjectWarmingMethodType'

export default function ProjectWarmingMethodSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField
      name="warmingMethod"
      label={t('Projects.warming')}
      select
      {...props}
    >
      <MenuItem value={ProjectWarmingMethodType.WATER}>
        {t('Projects.district')}
      </MenuItem>
      <MenuItem value={ProjectWarmingMethodType.OIL}>
        {t('Projects.oil')}
      </MenuItem>
      <MenuItem value={ProjectWarmingMethodType.WOOD} data-cy="wood">
        {t('Projects.wood')}
      </MenuItem>
      <MenuItem value={ProjectWarmingMethodType.GAS}>
        {t('Projects.gas')}
      </MenuItem>
      <MenuItem value={ProjectWarmingMethodType.ELECTRIC}>
        {t('Projects.electric')}
      </MenuItem>
      <MenuItem value={ProjectWarmingMethodType.OTHER}>
        {t('Projects.other')}
      </MenuItem>
    </TextField>
  )
}
