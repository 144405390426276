import React, { useEffect, useState } from 'react'
import { Grid, Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getBlueprintService } from '../../services/uploadService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { EmailErrors } from '../../enums/EmailErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { deleteError, setError } from '../../redux/slices/errors'
import { UploadErrors } from '../../enums/UploadErrors'
import { NotificationType } from '../../enums/NotificationType'
import { useAuth } from '../../hooks'
import { BlueprintCanvas } from '../../components/BlueprintCanvas'
import { PowerSupply, RuuviTag } from '../../types'
import { getProjectMembership } from '../../redux/slices/projectMemberships'
import { getRuuviTags } from '../../services/ruuviTagService'
import LoadingState from '../../components/LoadingState'
import { getPowerSuppliesByProjectId } from '../../services/projectService'

function Blueprint() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { levelId, projectId } = useParams()
  const { currentUser } = useAuth()
  const [isLoadingBlueprint, setIsLoadingBlueprint] = useState<boolean>(true)
  const [blueprintData, setBlueprint] = useState<any>()
  const [powerSuppliesOfLevel, setPowerSupplies] = useState<PowerSupply[]>()
  const [ruuviTagsOfLevel, setRuuvitagsOfLevel] = useState<RuuviTag[]>()
  const [isLoadingRuuviTags, setIsLoadingRuuviTags] = useState<boolean>(true)
  const memberId = parseInt(currentUser?.id)

  useEffect(() => {
    loadBlueprint()
    dispatch(
      getProjectMembership({
        memberId: memberId,
        projectId: parseInt(projectId as string)
      })
    )
  }, [currentUser])

  async function loadBlueprint(): Promise<void> {
    try {
      setIsLoadingBlueprint(true)
      setPowerSupplies(
        await getPowerSuppliesByProjectId(parseInt(projectId as string))
      )
      loadRuuviTagsOfProject(parseInt(projectId as string))
      levelId !== 'area'
        ? setBlueprint(
            await getBlueprintService({
              project: parseInt(projectId as string),
              level: parseInt(levelId as string)
            })
          )
        : setBlueprint(
            await getBlueprintService({
              project: parseInt(projectId as string)
            })
          )
    } catch (error: any) {
      dispatch(
        setError({
          type: UploadErrors.GET,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UploadErrors.GET,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(EmailErrors.SEND))
    } finally {
      setIsLoadingBlueprint(false)
    }
  }
  function getPowerSuppliesOfLevel(levelId: string): PowerSupply[] {
    if (powerSuppliesOfLevel && levelId !== 'area') {
      //return powerSupplies of the level
      return powerSuppliesOfLevel?.filter(
        (powerSupply) => powerSupply?.level?.id === parseInt(levelId)
      )
    } else if (powerSuppliesOfLevel && levelId === 'area') {
      //return powerSupplies that does not have and level
      return powerSuppliesOfLevel?.filter(
        (powerSupply) => powerSupply?.level === null
      )
    } else return []
  }

  async function loadRuuviTagsOfProject(projectId: number): Promise<void> {
    try {
      setIsLoadingRuuviTags(true)
      setRuuvitagsOfLevel(await getRuuviTags({ projectId }))
    } finally {
      setIsLoadingRuuviTags(false)
    }
  }

  function getRuuviTagsOfLevel(levelId: string): RuuviTag[] {
    if (ruuviTagsOfLevel && levelId !== 'area') {
      //return powerSupplies of the level
      return ruuviTagsOfLevel?.filter(
        (ruuviTag) => ruuviTag.asset?.levelId === parseInt(levelId)
      )
    } else if (ruuviTagsOfLevel && levelId === 'area') {
      //return powerSupplies that does not have and level
      return ruuviTagsOfLevel?.filter(
        (ruuviTag) => ruuviTag.asset?.levelId === null
      )
    } else return []
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoadingBlueprint || isLoadingRuuviTags ? (
            <LoadingState />
          ) : (
            <BlueprintCanvas
              upload={blueprintData}
              powerSupplies={getPowerSuppliesOfLevel(levelId as string)}
              ruuviTags={getRuuviTagsOfLevel(levelId as string)}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Blueprint
