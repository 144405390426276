import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Checkbox,
  Card as MuiCard,
  CardContent as MuiCardContent,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField as MuiTextField,
  MenuItem,
  IconButton,
  InputAdornment,
  useTheme,
  Stack
} from '@mui/material'
import { spacing } from '@mui/system'
import MenuIcon from '@mui/icons-material/Menu'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import { RootState } from '../redux/store'
import {
  createPowerSupply,
  updatePowerSupply,
  setShow
} from '../redux/slices/powerSupplies'
import { getProjects } from '../redux/slices/projects'
import { getCompanies } from '../redux/slices/companies'
import { getLevelsByProjectId } from '../redux/slices/levels'
import { getLiftsByProjectId } from '../redux/slices/lifts'
import { deleteError, setError } from '../redux/slices/errors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { createUserNotification } from '../utils/createUserNotification'
import powerSupplyService from '../services/powerSupplyService'
import { getPowerSuppliesByProjectId } from '../services/projectService'
import { useAuth } from '../hooks'
import { Lift, Project } from '../types'
import { PowerSupply, PowerSupplyInput } from '../types'
import { UserRole } from '../enums/UserRole'
import { PowerSupplyErrors } from '../enums/PowerSupplyError'
import { NotificationType } from '../enums/NotificationType'
import LevelSelect from './LevelSelect'
import LiftSelect from './LiftSelect'
import ProjectSelect from './ProjectSelect'
import PowerSupplyFuseSelect from './PowerSupplyFuseSelect'
import PowerSupplyInputCablesSelect from './PowerSupplyInputCablesSelect'
import CompanySelect from './CompanySelect'
import LoadingState from './LoadingState'

const Card = styled(MuiCard)(spacing)
const TextField = styled(MuiTextField)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)

export default function PowerSupplyForm() {
  const [t] = useTranslation('common')

  const dispatch = useDispatch()

  const theme = useTheme()

  const { currentUser } = useAuth()

  const powerSupply = useSelector(
    (state: RootState) => state.powerSupplies.powerSupply
  )

  const isLoadingProjects = useSelector(
    (state: RootState) => state.projects.loading
  )

  const isLoadingLevels = useSelector(
    (state: RootState) => state.levels.loading
  )

  const isLoadingLifts = useSelector((state: RootState) => state.lifts.loading)

  const [powerSupplies, setPowerSupplies] = useState<PowerSupply[]>([])

  const [isLoadingPowerSupplies, setIsLoadingPowerSupplies] =
    useState<boolean>(true)

  const [showFuseSelect, setShowFuseSelect] = useState(false)

  const powerSuppliesExceptSelf = powerSupplies.filter(
    (p) => p.id !== powerSupply?.id
  )

  const initialValues: PowerSupplyInput =
    powerSupply === undefined
      ? {
          name: '',
          description: '',
          fuse: 63,
          voltage_1_tp: 0,
          voltage_1_ts: 0,
          inputCables: 1,
          isMainDistributionAssembly: false,
          project: null,
          parent: null,
          level: null,
          lift: null,
          owner: null,
          customerReference: null,
          current_1_offset: 0,
          current_2_offset: 0,
          current_3_offset: 0,
          voltage_1_offset: 0,
          voltage_2_offset: 0,
          voltage_3_offset: 0
        }
      : {
          name: powerSupply.name,
          description: powerSupply.description,
          fuse: powerSupply.fuse,
          voltage_1_tp: powerSupply.voltage_1_tp,
          voltage_1_ts: powerSupply.voltage_1_ts,
          inputCables: powerSupply.inputCables,
          isMainDistributionAssembly:
            powerSupply.isMainDistributionAssembly ?? false,
          project: powerSupply.device?.asset?.project?.id ?? null,
          parent: powerSupply?.parent?.id ?? null,
          level: powerSupply.device?.asset?.level?.id ?? null,
          lift: powerSupply.device?.asset?.lift?.id ?? null,
          owner: powerSupply.device?.asset?.company?.id ?? null,
          customerReference: powerSupply.customerReference ?? null,
          current_1_offset: powerSupply?.current_1_offset ?? 0,
          current_2_offset: powerSupply?.current_2_offset ?? 0,
          current_3_offset: powerSupply?.current_3_offset ?? 0,
          voltage_1_offset: powerSupply?.voltage_1_offset ?? 0,
          voltage_2_offset: powerSupply?.voltage_2_offset ?? 0,
          voltage_3_offset: powerSupply?.voltage_3_offset ?? 0
        }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().nullable(),
    fuse: Yup.number().positive('Select fuse size').required('Required'),
    inputCables: Yup.number().required('Required'),
    voltage_1_tp: Yup.number().required('Required'),
    voltage_1_ts: Yup.number().required('Required'),
    isMainDistributionAssembly: Yup.boolean(),
    project: Yup.number().nullable(),
    level: Yup.number().nullable(),
    lift: Yup.number().nullable(),
    owner: Yup.number().nullable()
  })

  const loadPowerSupplies = async (projectId: number) => {
    try {
      setIsLoadingPowerSupplies(true)
      setPowerSupplies(await getPowerSuppliesByProjectId(projectId))
    } catch (error: unknown) {
      // TODO: Handle the error.
      console.error(error)
    } finally {
      setIsLoadingPowerSupplies(false)
    }
  }

  const handleClickShowFuseSelect = () => {
    setShowFuseSelect((showFuseSelect) => !showFuseSelect)
  }

  const handleSubmit = async (
    values: any,
    { resetForm, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true)

      if (powerSupply === undefined) {
        const createdPowerSupply = await powerSupplyService.createPowerSupply(
          values
        )

        dispatch(createPowerSupply(createdPowerSupply))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('powerSupplyCreateSuccess')
          )
        )
      } else {
        const updatedPowerSupply = await powerSupplyService.updatePowerSupply(
          powerSupply.id,
          values
        )

        dispatch(updatePowerSupply(updatedPowerSupply))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('powerSupplyUpdateSuccess')
          )
        )
      }

      resetForm()
      setStatus({ sent: true })
      dispatch(setShow(false))
    } catch (error: unknown) {
      dispatch(
        setError({
          type: powerSupply ? PowerSupplyErrors.EDIT : PowerSupplyErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: powerSupply ? PowerSupplyErrors.EDIT : PowerSupplyErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(
        deleteError(
          powerSupply ? PowerSupplyErrors.EDIT : PowerSupplyErrors.CREATE
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  const handleCancel = () => {
    dispatch(setShow(false))
  }

  useEffect(() => {
    const controller = new AbortController()

    dispatch(getProjects())
    dispatch(getCompanies())
    if (powerSupply && powerSupply?.device?.asset?.projectId) {
      dispatch(getLevelsByProjectId(powerSupply?.device?.asset?.projectId))
      dispatch(getLiftsByProjectId(powerSupply?.device?.asset?.projectId))
      loadPowerSupplies(powerSupply?.device?.asset?.projectId)
    }

    return () => {
      dispatch(setShow(false))
      controller.abort()
    }
  }, [dispatch])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setValues,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                  <ProjectSelect
                    name="project"
                    value={values.project}
                    disabled={isLoadingProjects}
                    error={Boolean(touched.project && errors.project)}
                    helperText={touched.project && errors.project}
                    onBlur={handleBlur}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const projectId: number = parseInt(event.target.value)

                      // We need to reload and reset relations that
                      // should be scoped for project when project changes.
                      loadPowerSupplies(projectId)
                      dispatch(getLevelsByProjectId(projectId))
                      dispatch(getLiftsByProjectId(projectId))

                      setValues({
                        ...values,
                        parent: null,
                        level: null,
                        lift: null
                      })

                      handleChange(event)
                    }}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField
                    name="customerReference"
                    label={t('customerReference')}
                    value={values.customerReference}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <PowerSupplyFuseSelect
                        name="fuse"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                sx={{ p: '10px' }}
                                aria-label="menu"
                                onClick={handleClickShowFuseSelect}
                              >
                                {showFuseSelect ? (
                                  <TextFieldsIcon />
                                ) : (
                                  <MenuIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: showFuseSelect === false && (
                            <InputAdornment
                              position="end"
                              style={{ color: theme.palette.text.secondary }}
                            >
                              A
                            </InputAdornment>
                          )
                        }}
                        select={showFuseSelect}
                        value={values.fuse}
                        error={Boolean(touched.fuse && errors.fuse)}
                        helperText={touched.fuse && errors.fuse}
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        name="name"
                        label={t('PowerSupply.name')}
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      /* @ts-ignore */
                      label={t('PowerSupply.isMainDistributionAssembly')}
                      control={
                        <Checkbox
                          name="isMainDistributionAssembly"
                          checked={values.isMainDistributionAssembly}
                          onChange={(event: ChangeEvent) => {
                            setValues({
                              ...values,
                              isMainDistributionAssembly:
                                // @ts-ignore
                                event.target.checked,
                              parent: null
                            })
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                      <PowerSupplyInputCablesSelect
                        value={values.inputCables}
                        error={Boolean(
                          touched.inputCables && errors.inputCables
                        )}
                        helperText={touched.inputCables && errors.inputCables}
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        name="voltage_1_tp"
                        label={t('PowerSupply.voltage_1_tp')}
                        value={values.voltage_1_tp}
                        error={Boolean(
                          touched.voltage_1_tp && errors.voltage_1_tp
                        )}
                        helperText={touched.voltage_1_tp && errors.voltage_1_tp}
                        fullWidth
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        name="voltage_1_ts"
                        label={t('PowerSupply.voltage_1_ts')}
                        value={values.voltage_1_ts}
                        error={Boolean(
                          touched.voltage_1_ts && errors.voltage_1_ts
                        )}
                        helperText={touched.voltage_1_ts && errors.voltage_1_ts}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {values.project !== null && (
                  <>
                    {!values.isMainDistributionAssembly && (
                      <Grid item xs={12}>
                        <TextField
                          name="parent"
                          label={t('PowerSupply.parent')}
                          select
                          value={values.parent}
                          disabled={isLoadingPowerSupplies}
                          error={Boolean(touched.parent && errors.parent)}
                          helperText={touched.parent && errors.parent}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {powerSuppliesExceptSelf.map((powerSupply) => (
                            <MenuItem
                              key={powerSupply.id}
                              value={powerSupply.id}
                            >
                              {powerSupply.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <LevelSelect
                            value={values.level}
                            disabled={isLoadingLevels}
                            error={Boolean(touched.level && errors.level)}
                            helperText={touched.level && errors.level}
                            variant="outlined"
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <LiftSelect
                            value={values.lift}
                            disabled={isLoadingLifts}
                            error={Boolean(touched.lift && errors.lift)}
                            helperText={touched.lift && errors.lift}
                            fullWidth
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label={t('PowerSupply.description')}
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    fullWidth
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <CompanySelect
                        name="owner"
                        label={t('owner')}
                        value={values.owner}
                        error={Boolean(touched.owner && errors.owner)}
                        helperText={touched.owner && errors.owner}
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {currentUser?.role === UserRole.SUPERADMIN && (
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={6} lg={4}>
                        <TextField
                          name="current_1_offset"
                          label={t('current1Calibration')}
                          value={values.current_1_offset}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} lg={4}>
                        <TextField
                          name="current_2_offset"
                          label={t('current2Calibration')}
                          value={values.current_2_offset}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} lg={4}>
                        <TextField
                          name="current_3_offset"
                          label={t('current3Calibration')}
                          value={values.current_3_offset}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} lg={4}>
                        <TextField
                          name="voltage_1_offset"
                          label={t('voltage1Calibration')}
                          value={values.voltage_1_offset}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} lg={4}>
                        <TextField
                          name="voltage_2_offset"
                          label={t('voltage2Calibration')}
                          value={values.voltage_2_offset}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} lg={4}>
                        <TextField
                          name="voltage_3_offset"
                          label={t('voltage3Calibration')}
                          value={values.voltage_3_offset}
                          fullWidth
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      /* @ts-ignore */
                      label={t('PowerSupply.clearPowerSupply')}
                      control={
                        <Checkbox
                          onChange={() => {
                            setValues({
                              ...values,

                              // @ts-ignore
                              owner: null,
                              project: null,
                              level: null,
                              lift: null
                            })
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                  >
                    <Button onClick={handleCancel}>{t('cancel')}</Button>

                    <Button type="submit" variant="contained" color="primary">
                      {t('PowerSupplies.save')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  )
}
