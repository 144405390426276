import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import RuuviTagScroller from '../../components/RuuviTagScroller'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

export default function RuuviTagsPage() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <>
      <Helmet title={t('conditionMonitoring')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('conditionMonitoring')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('frontpage')}
        </Link>
        <Typography>{t('conditionMonitoring')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6} sx={{ height: 'calc(100% - 32px)' }}>
        <Grid item xs={12} sx={{ height: '100%' }}>
          <RuuviTagScroller />
        </Grid>
      </Grid>
    </>
  )
}
