import { useDispatch } from 'react-redux'
import {
  Button as MuiButton,
  Grid,
  Stack,
  Typography,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import useAuth from '../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import React from 'react'
import { MFAErrors } from '../enums/MFAErrrors'
import { useNavigate } from 'react-router-dom'
import { disableMfa } from '../services/userService'

const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)

export default function MFADisableForm() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  async function handleSubmit() {
    try {
      await disableMfa(currentUser?.id)
      navigate(`/users/${currentUser?.id}`)
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('mfaResetLinkSuccess')
        )
      )
      navigate(`/users/${currentUser?.id}`)
    } catch (error: any) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: MFAErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <Typography align="center" variant="h4">
            {t('youHaveMfaEnabled')}
          </Typography>
          <Divider my={6} />
          <Typography align="center" variant="h6">
            {t('howToChangeMfa')}
          </Typography>
          <Divider my={6} />
          <React.Fragment>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                  >
                    {t('turnOffMfa')}
                  </Button>

                  <Button
                    type="button"
                    onClick={() => navigate(`/users/${currentUser?.id}`)}
                  >
                    {t('cancel')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </React.Fragment>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}
