import React, { useState } from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import authService from '../../services/authService'
import { Link } from 'react-router-dom'

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField
} from '@mui/material'
import { spacing } from '@mui/system'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'

const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

// eslint-disable-next-line
function ResetPassword() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await authService.sendPasswordResetEmail(values)
          dispatch(
            createErrorOrSuccessNotification(
              NotificationType.SUCCESS,
              t('resetLinkSuccess')
            )
          )

          // eslint-disable-next-line
        } catch (error: any) {
          dispatch(
            setError({
              type: AuthErrors.SEND_LINK,
              error: error
            })
          )

          //Create message
          const errorMessage = createUserNotification({
            user: null,
            type: AuthErrors.SEND_LINK,
            error: error
          })

          //Dispatch error message
          dispatch<unknown>(
            createErrorOrSuccessNotification(
              NotificationType.WARNING,
              t(errorMessage.key) + t(errorMessage.message)
            )
          )
          //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
          dispatch(deleteError(AuthErrors.SEND_LINK))
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Reset password
          </Button>

          <Button component={Link} to="/auth/sign-in" fullWidth color="primary">
            Return to login page
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default ResetPassword
