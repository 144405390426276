import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Formik } from 'formik'

import {
  Box,
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Button as MuiButton,
  CardHeader
} from '@mui/material'
import { spacing } from '@mui/system'
import useAuth from '../../hooks/useAuth'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUser, updateUser } from '../../redux/slices/users'
import userService from '../../services/userService'
import { useUser } from '../../hooks'
import { UserInput } from '../../types'
import { UserRole } from '../../enums'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)

function ConfirmRoleChanges() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [t] = useTranslation('common')
  const user = useUser()
  const { signOut } = useAuth()
  const { currentUser } = useAuth()

  const url = window.location.pathname
  const token = url.substring(url.lastIndexOf('/') + 1)
  const decoded = JSON.parse(
    Buffer.from(token.split('.')[1], 'base64').toString()
  )

  const initialValues: UserInput = {
    role: UserRole.GUEST,
    firstName: '',
    lastName: '',
    email: '',
    phone: null,
    language: 'fi',
    notificationsByEmail: false,
    notificationsBySms: false
  }

  useEffect(() => {
    if (currentUser) {
      dispatch(getUser(parseInt(decoded.userId)))
    }
  }, [currentUser])

  useEffect(() => {
    if (user) {
      initialValues.role = decoded.role
      initialValues.firstName = user.firstName
      initialValues.lastName = user.lastName
      initialValues.email = user.email
    }
  }, [user])

  const handleSignOut = async () => {
    await signOut()
    navigate('/auth/sign-in')
  }

  const handleSubmit = async (
    values: UserInput,
    {
      resetForm,
      setErrors,
      setStatus,
      setSubmitting
    }: {
      resetForm: () => void
      setErrors: (data: { submit: string }) => void
      setStatus: (data: { sent: boolean }) => void
      setSubmitting: (value: boolean) => void
    }
  ) => {
    try {
      if (user) {
        const updatedUser = await userService.updateUser(user.id, values)
        dispatch(updateUser(updatedUser))

        resetForm()
        setTimeout(async () => await handleSignOut(), 3000)
        setStatus({ sent: true })
        setSubmitting(false)
      }
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      {user && (
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Grid
                justifyContent="center"
                alignItems={'center'}
                container
                spacing={6}
                sx={{ minHeight: '75vh' }}
              >
                <Box sx={{ width: '75%' }}>
                  <Card mb={6}>
                    <CardHeader
                      titleTypographyProps={{ variant: 'h3' }}
                      align="center"
                      title="Your role has been changed from User to Admin. Do you accept this change?"
                    />
                    <CardHeader
                      align="center"
                      title="As a admin you are able to see all the company's projects and manage company's users."
                    />
                    <CardHeader
                      align="center"
                      title="If you accept promotion you will be automatically signed out. Next time you login your role is updated"
                    />
                    <CardContent>
                      <form noValidate onSubmit={handleSubmit}>
                        <Box
                          m={1}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            mt={3}
                          >
                            {t('Users.accept')}
                          </Button>

                          <Button
                            onClick={() => {
                              navigate('/')
                            }}
                            variant="contained"
                            color="primary"
                            mt={3}
                          >
                            {t('Users.cancel')}
                          </Button>
                        </Box>
                      </form>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            )}
          </Formik>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default ConfirmRoleChanges
