import { ViewOptionsRefined } from '@fullcalendar/react'
import {
  Box,
  Chip,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  OutlinedInput
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SensorAggregateField from '../enums/SensorAggregateField'

interface SensorAggregateFieldSelectProps extends SelectProps {
  value: SensorAggregateField[]
  onValueChange: {
    (value: SensorAggregateField[]): void
  }
}

export default function SensorAggregateFieldSelect({
  value,
  onValueChange
}: SensorAggregateFieldSelectProps) {
  const [t] = useTranslation('common')

  const fields: SensorAggregateField[] = [
    SensorAggregateField.CURRENT_1,
    SensorAggregateField.CURRENT_2,
    SensorAggregateField.CURRENT_3,
    SensorAggregateField.VOLTAGE_1,
    SensorAggregateField.VOLTAGE_2,
    SensorAggregateField.VOLTAGE_3,
    SensorAggregateField.KW,
    SensorAggregateField.RATED_CURRENT,
    SensorAggregateField.TEMP,
    SensorAggregateField.RH,
    SensorAggregateField.RSSI
  ]

  function isSelected(field: SensorAggregateField): boolean {
    return value.includes(field)
  }

  function translateField(field: SensorAggregateField): string {
    return t(`SensorAggregate.${field}`)
  }

  function handleChange(event: SelectChangeEvent<any>): void {
    // Keep the original order for selected options.
    const newValue: SensorAggregateField[] = []
    ;(event.target.value as SensorAggregateField[]).forEach(
      (field: SensorAggregateField) => {
        newValue[fields.indexOf(field)] = field
      }
    )
    onValueChange(newValue)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="sensor-aggregate-field-select-label">
        {t('SensorAggregate.fields')}
      </InputLabel>
      <Select
        labelId="sensor-aggregate-field-select-label"
        id="sensor-aggregate-field-select"
        multiple={true}
        value={value as string[]}
        label={t('SensorAggreate.fields')}
        onChange={handleChange}
        input={
          <OutlinedInput label={t('SensorAggregate.fields')} size="small" />
        }
        renderValue={(selected: string[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
            {selected.map((field: string) => (
              <Chip key={field} label={t(`SensorAggregate.${field}`)} />
            ))}
          </Box>
        )}
      >
        {fields.map((field: SensorAggregateField) => (
          <MenuItem value={field} key={field}>
            <Checkbox checked={isSelected(field)} />
            <ListItemText primary={translateField(field)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
