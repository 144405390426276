import { useApi } from '../hooks'
import { Announcement, AnnouncementInput, AnnouncementQuery } from '../types'

const api = useApi('/v2')

export async function getAnnouncements(
  query: AnnouncementQuery = {}
): Promise<Announcement[]> {
  const response = await api.get('/announcements', {
    params: query
  })

  return response.data
}

async function getAnnouncement(id: string): Promise<Announcement> {
  const response = await api.get(`/announcements/${id}`)

  return response.data
}

async function createAnnouncement(input: Announcement): Promise<Announcement> {
  const response = await api.post(`/announcements`, input)

  return response.data
}

async function updateAnnouncement(
  id: string,
  input: AnnouncementInput
): Promise<Announcement> {
  const response = await api.put(`/announcements/${id}`, input)

  return response.data
}

async function deleteAnnouncement(id: string): Promise<Announcement> {
  const response = await api.delete(`/announcements/${id}`)

  return response.data
}

export default {
  getAnnouncement,
  getAnnouncements,
  createAnnouncement,
  updateAnnouncement,
  deleteAnnouncement
}
