export enum SidebarTitle {
  FRONTPAGE = 'frontpage',
  PROJECTS = 'projects',
  POWERSUPPLIES = 'powersupplies',
  DEVICES = 'devices',
  COMPANIES = 'companies',
  MANUFACTURING = 'manufacturing',
  USERS = 'users',
  ANNOUNCEMENT_MANAGEMENT = 'announcement_management',
  ADD_NEW_DEVICE = 'add_new_device',
  REPLACE_DEVICE = 'replace_device',
  ADD_NEW_RUUVI_TAG = 'add_new_ruuvi_tag',
  CONDITION_MONITORING = 'condition_monitoring'
}
