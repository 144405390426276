import { ChangeEvent, FormEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import LoadingButton from '@mui/lab/LoadingButton'
import { EnergyPrice, Project } from '../types'
import { updateProject } from '../redux/slices/projects'
import { useAuth } from '../hooks'
import { UserRole } from '../enums/UserRole'
import ProjectMembershipRole from '../enums/ProjectMembershipRole'
import { useState } from 'react'
import { getEnergyPriceNow } from '../services/energyPriceService'
import projectService from '../services/projectService'
import LoadingState from '../components/LoadingState'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import {
  userIsAdminOfProject,
  userIsOwnerOfProject,
  userIsSuperadmin
} from '../utils/auth'

interface ProjectEnergyPriceInfoProps {
  project: Project
}

export default function ProjectEnergyPriceInfo({
  project
}: ProjectEnergyPriceInfoProps) {
  const [t] = useTranslation('common')

  const dispatch = useDispatch()

  const theme = useTheme()

  const { currentUser } = useAuth()

  const [fixedEnergyPrice, setFixedEnergyPrice] = useState<number | null>(
    project.fixedEnergyPrice
  )

  const [hideCosts, setHideCosts] = useState<boolean>(project.hideCosts)

  const [energyPrice, setEnergyPrice] = useState<EnergyPrice>()

  const [isBooting, setIsBooting] = useState<boolean>(true)

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  /**
   * Indicates if the project is using spot price.
   */
  const isUsingSpotPrice = fixedEnergyPrice === null

  /**
   * The current energy price of the project.
   */
  const energyPriceOfProject: number = isUsingSpotPrice
    ? energyPrice?.cents_per_kwh ?? 0
    : project.fixedEnergyPrice ?? 0

  const currentUserCanEditProject =
    userIsSuperadmin(currentUser) ||
    userIsOwnerOfProject(currentUser, project) ||
    userIsAdminOfProject(currentUser, project)

  const unitColor =
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.5)'
      : 'rgba(255, 255, 255, 0.6)'

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => {
      await boot(controller.signal)
    })()
    return () => controller.abort()
  }, [])

  async function boot(signal: AbortSignal) {
    try {
      setIsBooting(true)
      await loadEnergyPrice(signal)
    } finally {
      setIsBooting(false)
    }
  }

  async function loadEnergyPrice(signal: AbortSignal): Promise<void> {
    setEnergyPrice(
      await getEnergyPriceNow(
        {
          lat: project.lat,
          long: project.long
        },
        {
          signal
        }
      )
    )
  }

  async function saveProject(): Promise<void> {
    try {
      setIsSaving(true)

      const updatedProject = await projectService.updateProject(project.id, {
        name: project.name,
        address: project.address,
        zip: project.zip,
        city: project.city,
        countryCode: project.countryCode,
        area: project.area,
        type: project.type,
        new: project.new,
        warmingMethod: project.warmingMethod,
        hideCosts,
        fixedEnergyPrice,
        fixedEmissionFactor: project?.fixedEmissionFactor,
        company: project?.company?.id,
        lat: project.lat,
        long: project.long,
        state: project.state,
        startDate: project.startDate,
        endDate: project.endDate,
        customerReference: project.customerReference
      })

      dispatch(updateProject(updatedProject))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('projectUpdateSuccess')
        )
      )
    } finally {
      setIsSaving(false)
    }
  }

  function changeHideCosts(): void {
    setHideCosts(!hideCosts)
  }

  function changeFixedEnergyPrice(event: ChangeEvent<HTMLInputElement>): void {
    const value =
      event.currentTarget.value !== ''
        ? parseFloat(event.currentTarget.value)
        : null

    setFixedEnergyPrice(value)
  }

  function changeIsUsingSpotPrice(): void {
    if (isUsingSpotPrice) {
      const value = energyPrice?.cents_per_kwh ?? 0
      setFixedEnergyPrice(parseFloat(value.toFixed(2)))
    } else {
      setFixedEnergyPrice(null)
    }
  }

  function handleCancel(): void {
    setIsEditing(false)
    setFixedEnergyPrice(project.fixedEnergyPrice)
    setHideCosts(project.hideCosts)
  }

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()
    await saveProject()
    setIsEditing(false)
  }

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" mb={4}>
            {t('energyPrice')}
          </Typography>

          <Stack direction="row" spacing={3}>
            {!isBooting && !isEditing && currentUserCanEditProject && (
              <IconButton
                title={t('edit')}
                size="small"
                onClick={(event) => {
                  setIsEditing(true)
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>

        {isBooting ? (
          <LoadingState />
        ) : isEditing ? (
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ mt: 3 }}>
              <FormGroup sx={{ px: 3, pb: 3 }}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={hideCosts}
                      disabled={isSaving}
                      onChange={changeHideCosts}
                    />
                  }
                  label={t('hideCostsFromUsers') as string}
                  labelPlacement="end"
                />
              </FormGroup>

              <FormGroup sx={{ px: 3, pb: 3 }}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isUsingSpotPrice}
                      disabled={isSaving}
                      onChange={changeIsUsingSpotPrice}
                    />
                  }
                  label={t('useSpotPrice') as string}
                  labelPlacement="end"
                />
              </FormGroup>

              {!isUsingSpotPrice && (
                <TextField
                  label="c/kWh"
                  type="number"
                  value={fixedEnergyPrice}
                  disabled={isSaving}
                  onChange={changeFixedEnergyPrice}
                  fullWidth
                />
              )}

              <Stack direction="row" spacing={3}>
                <Button
                  type="button"
                  disabled={isSaving}
                  onClick={handleCancel}
                >
                  {t('cancel')}
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSaving}
                >
                  {t('save')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        ) : (
          <>
            <Typography variant="h3" fontWeight={400}>
              {energyPriceOfProject.toFixed(2)}
            </Typography>
            <Typography fontWeight={600} color={unitColor}>
              c/kWh
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}
