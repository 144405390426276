import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import troubleshootProps from './TroubleshootProps'
import { TroubleshootEvent } from '../../pages/pages/DeviceTroubleshoot'

export default function Initial({ stateUpdate }: troubleshootProps) {
  const [t] = useTranslation('common')
  return (
    <>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {t('troubleshoot.initialHeader')}
      </Typography>

      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t('troubleshoot.initialBody')}
      </Typography>

      <Typography align="left" sx={{ mr: 2, mt: 2 }}>
        <Button
          onClick={() => stateUpdate(TroubleshootEvent.Continue)}
          variant="contained"
        >
          {t('troubleshoot.continue')}
        </Button>
      </Typography>
    </>
  )
}
