import { useApi, useAxios } from '../hooks'
import { PowerSupply, PowerSupplyInput } from '../types'

const api = useApi('v2')

export async function getPowerSupplies(): Promise<PowerSupply[]> {
  const response = await api.get('/powersupplies')

  return response.data
}

export async function getPowerSupply(id: number): Promise<PowerSupply> {
  const response = await api.get(`/powersupplies/${id}`)

  return response.data
}

export async function updatePowerSupply(
  id: number,
  input: PowerSupplyInput
): Promise<PowerSupply> {
  const response = await api.put(`/powersupplies/${id}`, input)

  return response.data
}

/**
 * @deprecated
 */
export async function getPowerSuppliesByProjectId(
  projectId: any
): Promise<PowerSupply[]> {
  const axios = useAxios()

  const response = await axios.get(`/projects/${projectId}/powersupplies`)

  return response.data
}

/**
 * @deprecated
 */
export async function createPowerSupply(
  input: PowerSupplyInput
): Promise<PowerSupply> {
  const axios = useAxios()

  const response = await axios.post('/powersupplies', input)

  return response.data
}

/**
 * @deprecated
 */
export async function deletePowerSupply(id: number): Promise<void> {
  const axios = useAxios()

  await axios.delete(`/powersupplies/${id}`)
}

export default {
  getPowerSupplies,
  getPowerSupply,
  createPowerSupply,
  updatePowerSupply,
  deletePowerSupply
}
