import { Lift, LiftInput } from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getLifts(): Promise<Lift[]> {
  const response = await api.get('/lifts')

  return response.data
}

export async function getLiftsByProjectId(projectId: number): Promise<Lift[]> {
  const response = await api.get(`/projects/${projectId}/lifts`)

  return response.data
}

export async function getLift(id: number): Promise<Lift> {
  const response = await api.get(`/lifts/${id}`)

  return response.data
}

export async function createLift(input: LiftInput): Promise<Lift> {
  const response = await api.post('/lifts', input)

  return response.data
}

export async function updateLift(id: number, input: LiftInput): Promise<Lift> {
  const response = await api.put(`/lifts/${id}`, input)

  return response.data
}

export async function deleteLift(id: number): Promise<void> {
  await api.delete(`/lifts/${id}`)
}

export default {
  getLifts,
  getLiftsByProjectId,
  getLift,
  createLift,
  updateLift,
  deleteLift
}
