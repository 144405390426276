import 'react-app-polyfill/stable'

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import reportWebVitals from './utils/reportWebVitals'
import App from './App'

import { ThemeProvider } from './contexts/ThemeContext'

render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
