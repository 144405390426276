import React, { useState } from 'react'
import { DeviceAlertType } from '../enums/DeivceAlertType'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { PrometheusAlert } from '../types'
import { AlertGroup } from '../types/Alerts'
import { checkAlertValueUnit } from '../utils/alert'

interface DeviceAlertTableProps {
  type: DeviceAlertType
  activeAlerts?: PrometheusAlert[]
  projectAlertRules?: AlertGroup[]
}

export default function DeviceAlertTable({
  type,
  activeAlerts,
  projectAlertRules
}: DeviceAlertTableProps) {
  /**
   * The translation function
   */
  const [t] = useTranslation('common')

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  /**
   * The columns
   */
  const columns = [
    {
      field: 'activeAt',
      headerName: t('Device.timestamp'),
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params: any) =>
        new Date(params.row.activeAt).toLocaleString()
    },

    {
      field: 'labels.alertname',
      headerName: t('name'),
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params: any) => params.row.labels.alertname
    },
    {
      field: 'labels.name',
      headerName: t('Device.phase'),
      type: 'string',
      width: 100,
      editable: false,
      valueGetter: (params: any) => params.row.labels.name
    },
    {
      field: 'value',
      headerName: t('value'),
      type: 'string',
      width: 100,
      editable: false,
      valueGetter: (params: any) => {
        const alertValueUnit = projectAlertRules
          ? checkAlertValueUnit(params.row.labels.alertname, projectAlertRules)
          : ''

        if (params.value === null) {
          return ''
        } else if (params.value && alertValueUnit && alertValueUnit === '%') {
          return `${(params.value * 100).toFixed(0)} ${alertValueUnit}`
        }

        return `${params.value.toFixed(1)} ${alertValueUnit}`
      }
    },
    {
      field: 'annotations.summary',
      headerName: t('summary'),
      type: 'string',
      width: 500,
      editable: false,
      valueGetter: (params: any) => params.row.annotations.summary
    },
    {
      field: 'labels.severity',
      headerName: t('severity'),
      type: 'string',
      width: 200,
      editable: false,
      valueGetter: (params: any) => params.row.labels.severity
    }
  ]

  return (
    <React.Fragment>
      <Typography mb={2} variant="h4">
        {t('activeAlerts')}
      </Typography>
      <DataGrid
        rows={activeAlerts ?? []}
        columns={columns}
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar
        }}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 250 }
          }
        }}
        getRowId={(row: any) =>
          /**Custom unique id
           * Alert name + device name + phase
           * Same alert for same device for same phase should not be firing multiple times
           */
          row.labels.alertName + row.labels.device + row.labels.name
        }
      />
    </React.Fragment>
  )
}
