import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Divider as MuiDivider,
  InputAdornment,
  IconButton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow
} from '@mui/material'
import * as Yup from 'yup'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { RootState } from '../../redux/store'
import { getLevelsByProjectId } from '../../redux/slices/levels'
import { getLiftsByProjectId } from '../../redux/slices/lifts'
import {
  getPowerSuppliesByProjectId,
  setPowerSupplyName,
  updatePowerSupply
} from '../../redux/slices/powerSupplies'
import AddIcon from '@mui/icons-material/Add'
import { setData, setStep } from '../../redux/slices/steps'
import PowerSupplyInputCablesSelect from '../PowerSupplyInputCablesSelect'
import { setDevice } from '../../redux/slices/devices'
import { QRData } from '../../enums/QRData'
import powerSupplyService from '../../services/powerSupplyService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { PowerSupplyErrors } from '../../enums/PowerSupplyErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import {
  ActionRequest,
  Device,
  Level,
  Lift,
  PowerSupply,
  Project
} from '../../types'
import PowerSupplyFuseSelect from '../PowerSupplyFuseSelect'
import { ProductType } from '../../enums/ProductType'
import { deleteDeviceLocationChangeRequest } from '../../redux/slices/requests'
import { updateRequestState } from '../../services/requestService'
import { RequestStatus } from '../../enums/RequestStatus'
import { getActionRequestsByProject } from '../../services/projectService'
import LoadingState from '../LoadingState'
import { ActionRequestType } from '../../enums/RequestAccessType'
import { RequesterType } from '../../enums/RequesterType'
import productService from '../../services/productService'
import MenuIcon from '@mui/icons-material/Menu'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import translateProductOrOrderItemType from '../../utils/translateProductOrOrderItemType'
import NominalCurrent from '../../enums/NominalCurrent'
import { NominalCurrentSelect100And250 } from '../NominalCurrentSelect'
import InfoIcon from '@mui/icons-material/Info'
import { PowerSupplyUpdateAction } from '../../enums'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const Grid = styled(MuiGrid)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)

interface PowerSupplyFormProps {
  device: Device
  project: Project
}

export default function PowerSupplyForm({
  device,
  project
}: PowerSupplyFormProps) {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')

  /**
   * The steps of installation process
   */
  const { step } = useSelector((state: RootState) => state.steps)
  /**
   * The levels of project
   */
  const { levels } = useSelector((state: RootState) => state.levels)
  /**
   * The lifts of project
   */
  const { lifts } = useSelector((state: RootState) => state.lifts)
  /**
   * The powerSupplies
   */
  const { powerSupplies, powerSupplyName } = useSelector(
    (state: RootState) => state.powerSupplies
  )

  /**
   * Is device type allowed to change fuse size
   */
  const [useFuseValidation, setUseFuseValidation] = useState<boolean>(false)

  /**
   * The validation schema
   */
  const validationSchema = useFuseValidation
    ? Yup.object().shape({
        name: Yup.string().required('Required'),
        level: Yup.string().required('Required'),
        inputCables: Yup.string().required('Required'),
        fuse: Yup.number().positive('Select fuse size').required('Required')
      })
    : Yup.object().shape({
        name: Yup.string().required('Required'),
        level: Yup.string().required('Required'),
        inputCables: Yup.string().required('Required')
      })

  /**
   * The device's requestts
   */
  const [deviceRequests, setDeviceRequests] = useState<ActionRequest[]>()

  /**
   * Is loading requests
   */
  const [isLoadingRequests, setIsLoadingRequests] = useState<boolean>(false)

  /**
   * Initial values
   */
  const initialValues = {
    id: device?.powerSupply?.id ?? '',
    name: device?.powerSupply?.name ?? '',
    level: device.asset?.level ? device.asset.level.id : 'Area',
    lift: device?.asset?.lift?.id ?? null,
    parent: device?.powerSupply?.parent ?? null,
    isMainDistributionAssembly:
      device?.powerSupply?.isMainDistributionAssembly ?? false,
    fuse: device?.powerSupply?.fuse,
    inputCables: device?.powerSupply?.inputCables ?? 1,
    //@ts-ignore
    project: device?.asset?.project ? device?.asset?.project.id : project.id,
    voltage_1_tp: device?.powerSupply?.voltage_1_tp ?? 0,
    voltage_1_ts: device?.powerSupply?.voltage_1_ts ?? 0,
    action: PowerSupplyUpdateAction.INSTALL
  }

  useEffect(() => {
    dispatch(getLevelsByProjectId(project.id))
    dispatch(getLiftsByProjectId(project.id))
    dispatch(getPowerSuppliesByProjectId(project.id))
    if (device?.powerSupply) {
      dispatch(setPowerSupplyName(device?.powerSupply?.name))
    }
    if (device.asset?.project) {
      loadRequests()
    }

    if (
      (device.product && device.product.type === ProductType.PLUG_AND_PLAY) ||
      (device.product &&
        device.product.type === ProductType.INSTALLATION_KIT) ||
      (device.product && device.product.type === ProductType.SPINE_MODULE)
    ) {
      setUseFuseValidation(true)
    }
  }, [])

  async function loadRequests() {
    try {
      setIsLoadingRequests(true)
      setDeviceRequests(
        //@ts-ignore
        await getActionRequestsByProject(device.asset.project.id, {
          requestableType: ActionRequestType.PROJECT,
          requesterType: RequesterType.DEVICE,
          requesterId: device?.name
        })
      )
    } finally {
      setIsLoadingRequests(false)
    }
  }

  /**
   * Main powersupplies
   */
  const mainPowerSupplies = powerSupplies.filter(
    (powerSupply: PowerSupply) =>
      powerSupply.isMainDistributionAssembly &&
      powerSupply.id !== device?.powerSupply?.id
  )

  /**
   * Show list of fuses
   */
  const [showFuseSelect, setShowFuseSelect] = useState(false)

  const handleClickShowFuseSelect = () => {
    setShowFuseSelect((showFuseSelect) => !showFuseSelect)
  }
  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * The nominal current
   */
  const [nominalCurrent, setNominalCurrent] = useState(
    device?.powerSupply?.voltage_1_tp === 1000
      ? (NominalCurrent.NOMINAL100 as string)
      : device?.powerSupply?.voltage_1_tp === 2500
      ? (NominalCurrent.NOMINAL250 as string)
      : (NominalCurrent.OTHER as string)
  )

  /**
   * Open currentTransformer info popup
   */
  const [openCurrentTransformerInfo, setOpenCurrentTransformerInfo] =
    useState(false)

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      /*Make parent id null if user marks current powersupply as main distribution assembly.
      This check is made to prevent error situation where user accidentally selects parent for powersupply before checking
      this powersupply as main distribution assembly.*/
      if (
        values.isMainDistributionAssembly === true &&
        values.parent !== null
      ) {
        values.level === 'Area'
          ? ((values.level = null), (values.lift = null))
          : values.level
        values.parent = null

        const updatedPowerSupply: PowerSupply =
          await powerSupplyService.updatePowerSupply(values.id, values)
        dispatch(updatePowerSupply(updatedPowerSupply))
        dispatch(setPowerSupplyName(''))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('powerSupplyUpdateSuccess')
          )
        )
      } else {
        values.level === 'Area'
          ? ((values.level = null), (values.lift = null))
          : values.level
        const updatedPowerSupply: PowerSupply =
          await powerSupplyService.updatePowerSupply(values.id, values)
        dispatch(updatePowerSupply(updatedPowerSupply))
        dispatch(setPowerSupplyName(''))

        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('powerSupplyUpdateSuccess')
          )
        )
      }
      if (device.product?.type === ProductType.INSTALLATION_KIT) {
        //Update product type from installation kit to smart electrical cabinet
        await productService.updateProduct(device.product.id, {
          type: ProductType.SMART_ELECTRICAL_CABINET
        })
      }

      if (deviceRequests && deviceRequests.length > 0) {
        await handleRequestsStatusUpdates(RequestStatus.APPROVED)
      }
      dispatch(setStep(step + 3))
      dispatch(setDevice(undefined))
      dispatch(setData(QRData.NOT_SCANNED))
      resetForm()
    } catch (error: unknown) {
      dispatch(
        setError({
          type: PowerSupplyErrors.EDIT,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: PowerSupplyErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(PowerSupplyErrors.EDIT))
    } finally {
      setSubmitting(false)
    }
  }

  async function handleRequestsStatusUpdates(status: RequestStatus) {
    //@ts-ignore
    return deviceRequests.map(async (request: RequestAccess) => {
      const updatedRequest = await updateRequestState(request.id, {
        status: status
      })
      dispatch(deleteDeviceLocationChangeRequest(updatedRequest.id))
    })
  }

  return (
    <React.Fragment>
      {/**The currentTransformer info popup */}
      <Dialog
        open={openCurrentTransformerInfo}
        onClose={() => setOpenCurrentTransformerInfo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('currentTransformerInfoTitle')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table>
              <TableRow>
                {t('currentTransformer100A') +
                  ': ' +
                  'SCT016 100 A : 0,1 A (100 mA), 1%'}
              </TableRow>
              <TableRow>
                {t('currentTransformer250A') +
                  ': ' +
                  'SCT024 250 A : 0,1 A (100 mA), 1%'}
              </TableRow>
              <TableRow>
                {t('other') + ': ' + t('currentTransformerOtherInfo')}
              </TableRow>
            </Table>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenCurrentTransformerInfo(false)}
            autoFocus
          >
            {t('understood')}
          </Button>
        </DialogActions>
      </Dialog>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setValues
        }) => (
          <Card mb={6}>
            {isLoadingRequests ? (
              <LoadingState />
            ) : (
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title={t('Manufacturing.instructionsForPowerSupply')}
                    align="center"
                    titleTypographyProps={{ variant: 'h6' }}
                  />
                  {device?.product?.type === ProductType.INSTALLATION_KIT ||
                  device?.product?.type === ProductType.PLUG_AND_PLAY ? (
                    <Alert color="warning" severity="info" variant="filled">
                      {t('installationTypeNotification')}{' '}
                      {t(
                        translateProductOrOrderItemType(
                          device.product?.type
                        ) as string
                      )}
                      !
                    </Alert>
                  ) : null}

                  <Divider my={6} />
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl>
                      <TextField
                        name="name"
                        label={t('Manufacturing.name')}
                        value={(values.name = powerSupplyName)}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          dispatch(setPowerSupplyName(e.target.value))
                        }
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl>
                      <TextField
                        name="level"
                        label={t('Manufacturing.level')}
                        value={values.level}
                        error={Boolean(touched.level && errors.level)}
                        fullWidth
                        select
                        helperText={touched.level && errors.level}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      >
                        {
                          // eslint-disable-next-line
                          levels.map((row: Level) => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))
                        }
                        <MenuItem value={'Area'}>{t('Area')}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl>
                      <Button
                        onClick={() => dispatch(setStep(step + 1))}
                        mt={4}
                        fullWidth
                        variant="contained"
                        color="success"
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>

                  {values.level !== 'Area' && (
                    <Grid container spacing={6}>
                      <Grid item xs={8} sm={8} md={8} lg={8} xl>
                        <TextField
                          name="lift"
                          label={t('Manufacturing.lift')}
                          select
                          value={values.lift}
                          error={Boolean(touched.lift && errors.lift)}
                          fullWidth
                          helperText={touched.lift && errors.lift}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        >
                          {
                            // eslint-disable-next-line
                            lifts.map((row: Lift) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.name}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl>
                        <Button
                          onClick={() => dispatch(setStep(step + 2))}
                          mt={4}
                          fullWidth
                          variant="contained"
                          color="success"
                        >
                          <AddIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {device.product?.type !== ProductType.SPINE_MODULE && (
                    <Grid item xs={12} mb={2} mt={2}>
                      <PowerSupplyInputCablesSelect
                        value={values.inputCables}
                        error={Boolean(
                          touched.inputCables && errors.inputCables
                        )}
                        helperText={touched.inputCables && errors.inputCables}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  )}

                  {useFuseValidation && (
                    <Grid item xs={12} mb={2} mt={4}>
                      <PowerSupplyFuseSelect
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                sx={{ p: '10px' }}
                                aria-label="menu"
                                onClick={handleClickShowFuseSelect}
                              >
                                {showFuseSelect ? (
                                  <TextFieldsIcon />
                                ) : (
                                  <MenuIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment:
                            showFuseSelect === false ? (
                              <InputAdornment
                                position="end"
                                style={{ color: 'white' }}
                              >
                                A
                              </InputAdornment>
                            ) : (
                              ''
                            )
                        }}
                        select={showFuseSelect}
                        value={values.fuse}
                        error={Boolean(touched.fuse && errors.fuse)}
                        helperText={touched.fuse && errors.fuse}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  )}

                  {device?.product?.type === ProductType.INSTALLATION_KIT && (
                    /**
                     * Nominalcurrent select for installationKit
                     */
                    <Grid item xs={12}>
                      <Grid container spacing={4}>
                        <Grid item xs={1} sm={1} md={1} lg={1}>
                          <IconButton
                            size="small"
                            onClick={() => setOpenCurrentTransformerInfo(true)}
                            sx={{ mt: 4 }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          md={nominalCurrent === NominalCurrent.OTHER ? 3 : 11}
                        >
                          <NominalCurrentSelect100And250
                            sx={{ mt: 2 }}
                            name="nominalCurrent"
                            value={nominalCurrent}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={(e) => setNominalCurrent(e.target.value)}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            name="voltage_1_tp"
                            sx={{
                              mt: 2,
                              display:
                                nominalCurrent === NominalCurrent.OTHER
                                  ? 'default'
                                  : 'none'
                            }}
                            label={t('PowerSupply.voltage_1_tp')}
                            value={
                              nominalCurrent === NominalCurrent.NOMINAL100
                                ? (values.voltage_1_tp = 1000)
                                : nominalCurrent === NominalCurrent.NOMINAL250
                                ? (values.voltage_1_tp = 2500)
                                : values.voltage_1_tp
                            }
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            name="voltage_1_ts"
                            sx={{
                              mt: 2,
                              display:
                                nominalCurrent === NominalCurrent.OTHER
                                  ? 'default'
                                  : 'none'
                            }}
                            label={t('PowerSupply.voltage_1_ts')}
                            value={
                              nominalCurrent === NominalCurrent.NOMINAL100
                                ? (values.voltage_1_ts = 1)
                                : nominalCurrent === NominalCurrent.NOMINAL250
                                ? (values.voltage_1_ts = 1)
                                : values.voltage_1_ts
                            }
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {values.isMainDistributionAssembly === false && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl>
                      <TextField
                        name="parent"
                        label={t('Manufacturing.parentName')}
                        select
                        value={values.parent}
                        error={Boolean(touched.parent && errors.parent)}
                        fullWidth
                        helperText={touched.parent && errors.parent}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      >
                        {
                          // eslint-disable-next-line
                          mainPowerSupplies.map((row: PowerSupply) => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>
                  )}

                  <FormControlLabel
                    /* @ts-ignore */
                    label={t('PowerSupply.isMainDistributionAssembly')}
                    control={
                      <Checkbox
                        name="isMainDistributionAssembly"
                        checked={values.isMainDistributionAssembly}
                        onChange={(event: ChangeEvent) => {
                          setValues({
                            ...values,
                            isMainDistributionAssembly:
                              // @ts-ignore
                              event.target.checked,
                            parent: null
                          })
                        }}
                      />
                    }
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    fullWidth
                  >
                    {t('Manufacturing.save')}
                  </Button>
                </form>
              </CardContent>
            )}
          </Card>
        )}
      </Formik>
    </React.Fragment>
  )
}
