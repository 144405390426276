import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'

import {
  Button as MuiButton,
  TextField as MuiTextField,
  Card as MuiCard,
  Grid,
  CardContent as MuiCardContent,
  CardHeader,
  Divider as MuiDivider
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { getProject, sendProjectInvite } from '../../services/projectService'
import { useNavigate, useParams } from 'react-router-dom'
import MembershipSelect from '../../components/MembershipRoleSelect'
import { MembershipRole } from '../../enums/MembershipRole'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useDispatch } from 'react-redux'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { InviteErrors } from '../../enums/InviteErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { Project } from '../../types'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)

const initialValues = {
  email: '',
  role: MembershipRole.USER
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required')
})
// eslint-disable-next-line
function InviteUser() {
  const [t] = useTranslation('common')
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [project, setProject] = useState<Project>()

  useEffect(() => {
    if (id) {
      loadProject()
    }
  }, [id])

  async function loadProject(): Promise<void> {
    if (id) {
      setProject(await getProject(parseInt(id)))
    }
  }

  const { currentUser } = useAuth()

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      await sendProjectInvite(parseInt(id as string), values)
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('Project.invitationSent')
        )
      )
      navigate('/')
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: InviteErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: InviteErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(InviteErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            <Button variant="contained" onClick={() => navigate('/')}>
              {t('Project.cancel')}
            </Button>
            <Divider my={6} />
            <form noValidate onSubmit={handleSubmit}>
              {project && (
                <CardHeader
                  title={t('Project.projectName') + ': ' + project.name}
                  align="center"
                  titleTypographyProps={{ variant: 'h4' }}
                />
              )}

              <CardHeader
                title={t('FrontPage.invite')}
                align="center"
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider my={6} />
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl>
                  <TextField
                    type="email"
                    name="email"
                    label={t('Users.email')}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl>
                  <MembershipSelect
                    name="role"
                    label={t('Users.role')}
                    value={values.role}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    mt={5}
                    color="primary"
                  >
                    {t('Project.inviteBtn')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

export default InviteUser
