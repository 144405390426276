enum IssueType {
  EPIC = '10000',
  IMPROVEMENT = '10008',
  TASK = '10009',
  SUB_TASK = '10010',
  NEW_FEATURE = '10011',
  BUG = '10012'
}

export default IssueType
