import { letterSpacing } from '@mui/system'
import EmissionFactorMode from '../enums/EmissionFactorMode'
import EnergyPriceType from '../enums/EnergyPriceType'
import {
  ProjectSettings,
  SensorSettings,
  ProjectAndDeviceStatsSettings,
  HierarchySettings
} from '../types'

interface AppSettings {
  energyPriceType?: EnergyPriceType
  energyPrice?: number
  emissionFactorMode?: EmissionFactorMode
  emissionFactorValue?: number
  projects?: Map<number, ProjectSettings>
  sensorSettings?: SensorSettings
  projectStatsSettings?: ProjectAndDeviceStatsSettings
  deviceStatsSettings?: ProjectAndDeviceStatsSettings
  hierarchySettings?: HierarchySettings
}

export default function useAppSettings(): AppSettings {
  let appSettings = JSON.parse(
    window.localStorage.getItem('appSettings') ?? '{}'
  )

  if (appSettings?.projects) {
    appSettings = {
      ...appSettings,
      projects: new Map(
        Object.keys(appSettings.projects).map((key) => {
          return [parseInt(key), appSettings.projects[key]]
        })
      )
    }
  }

  return appSettings
}
