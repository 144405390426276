import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link, IconButton } from '@mui/material'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import { Edit } from '@mui/icons-material'
import { getDevice } from '../redux/slices/devices'
import { Device } from '../types'
import DeviceStatusIndicator from './DeviceStatusIndicator'
import EmptyState from './EmptyState'

export interface DeviceDataGridProps {
  /**
   * The devices.
   */
  devices: Device[]

  /**
   * The hidden columns.
   */
  hiddenColumns?: string[]
}

export default function DeviceDataGrid({
  devices,
  hiddenColumns = ['company']
}: DeviceDataGridProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  /**
   * The grid columns.
   */
  const columns: GridColumns<Device> = makeColumns()

  /**
   * Make the grid columns.
   *
   * @returns The grid columns.
   */
  function makeColumns(): GridColumns<Device> {
    const columns: GridColumns<Device> = []

    if (!isColumnHidden('status')) {
      columns.push({
        field: t('status'),
        headerName: '',
        type: 'boolean',
        width: 50,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.status,
        renderCell: (params) => <DeviceStatusIndicator device={params.row} />
      })
    }

    if (!isColumnHidden('name')) {
      columns.push({
        field: 'name',
        headerName: t('name'),
        minWidth: 300,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          params.row?.asset?.name ?? params.row?.powerSupply?.name
      })
    }

    if (!isColumnHidden('deviceCode')) {
      columns.push({
        field: 'deviceCode',
        headerName: t('deviceCode'),
        minWidth: 200,
        editable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <Link
            component={NavLink}
            onClick={() => dispatch(getDevice(params.row.name))}
            to={`/devices/${params.row.name}`}
          >
            {params.row.name}
          </Link>
        ),
        valueGetter: (params) => params.row.name
      })
    }

    if (!isColumnHidden('shortUuid')) {
      columns.push({
        field: 'shortUuid',
        headerName: t('shortUUID'),
        editable: false,
        minWidth: 120,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.shortUUID
      })
    }

    if (!isColumnHidden('powerSupply.fuse')) {
      columns.push({
        field: 'powerSupply.fuse',
        headerName: `${t('fuse')} (A)`,
        type: 'number',
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row?.powerSupply?.fuse,
        renderCell: (params) => params.value ?? '-'
      })
    }

    if (!isColumnHidden('powerSupply.inputCables')) {
      columns.push({
        field: 'powerSupply.inputCables',
        headerName: t('inputCables'),
        type: 'number',
        minWidth: 190,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row?.powerSupply?.inputCables,
        renderCell: (params) => params.value ?? '-'
      })
    }

    if (!isColumnHidden('powerSupply.customerReference')) {
      columns.push({
        field: 'powerSupply.customerReference',
        headerName: t('customerReference'),
        minWidth: 200,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row?.powerSupply?.customerReference
      })
    }

    if (!isColumnHidden('company')) {
      columns.push({
        field: 'company',
        headerName: t('company'),
        minWidth: 200,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          params.row?.asset?.company?.name ??
          params.row?.powerSupply?.owner?.name,
        renderCell: (params) => (
          <Link
            component={NavLink}
            to={`/companies/${
              params.row?.asset?.companyId ?? params.row?.powerSupply?.ownerId
            }`}
          >
            {params.value}
          </Link>
        )
      })
    }

    if (!isColumnHidden('project')) {
      columns.push({
        field: 'project',
        headerName: t('project'),
        minWidth: 200,
        editable: false,
        disableColumnMenu: true,
        valueGetter: (params) =>
          params.row?.asset?.project?.name ??
          params.row?.powerSupply?.project?.name,
        renderCell: (params) => (
          <Link
            component={NavLink}
            to={`/projects/${
              params.row?.asset?.projectId ?? params.row?.powerSupply?.projectId
            }`}
          >
            {params.value}
          </Link>
        )
      })
    }

    if (!isColumnHidden('buttons')) {
      columns.push({
        field: t('buttons'),
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        align: 'right',
        renderCell: (params) => (
          <IconButton
            size="small"
            component={NavLink}
            to={`/devices/${params.row.name}/edit`}
            title={t('editDevice')}
          >
            <Edit fontSize="inherit" />
          </IconButton>
        )
      })
    }

    return columns
  }

  /**
   * Checks if the column is hidden.
   *
   * @param column The column.
   *
   * @returns True if the column is hidden, false otherwise.
   */
  function isColumnHidden(column: string): boolean {
    return hiddenColumns.includes(column)
  }

  return (
    <>
      {devices ? (
        <DataGrid
          rows={devices}
          columns={columns}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar
          }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 }
            }
          }}
          sx={{ border: 0 }}
        />
      ) : (
        <EmptyState title={t('noDevices')} />
      )}
    </>
  )
}
