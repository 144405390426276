import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider as MuiDivider,
  Stack,
  Typography,
  Switch as MuiSwitch,
  FormControlLabel,
  Grid,
  Switch,
  IconButton,
  Menu,
  MenuList,
  ListItem,
  ListItemText
} from '@mui/material'
import { styled, withStyles } from '@mui/styles'
import { spacing } from '@mui/system'
import DeviceVoltageBar from './DeviceVoltageBar'
import DeviceVoltageChart from './DeviceVoltageChart'
import LoadingState from './LoadingState'
import SensorAggregateStatisticToggle from './SensorAggregateStatisticToggle'
import Sensor from '../types/Sensor'
import DateRange from '../types/DateRange'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateQuery from '../types/SensorAggregateQuery'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'
import SensorAggregateField from '../enums/SensorAggregateField'
import { ForcedYAxis, ScalingYAxis } from '../types/YAxisOptions'
import { THEMES } from '../constants'
import useTheme from '../hooks/useTheme'
import { getDifferenceOfDateRangeInHours } from '../utils/date'
import { Device } from '../types'
import { MoreVertical } from 'react-feather'
import useAppSettings from '../hooks/useAppSettings'
import { isSensorNow } from '../utils/sensor'

const Divider = styled(MuiDivider)(spacing)
const CustomSwitch = withStyles({
  switchBase: {
    color: '#D3D3D3'
  },
  track: {},
  disabled: {}
})(MuiSwitch)

export interface DeviceVoltageStatsProps {
  device: Device
  dateRange: DateRange
  sensor?: Sensor
  sensors: Sensor[]
  sensorAggregates: SensorAggregate[]
  loading?: boolean
}

export default function DeviceVoltageStats({
  device,
  dateRange,
  sensor,
  sensors,
  sensorAggregates,
  loading = false
}: DeviceVoltageStatsProps) {
  const [t] = useTranslation('common')
  const { theme } = useTheme()

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  const [yAxisOption, setYaxisOption] = useState<ScalingYAxis | ForcedYAxis>({
    max: 250,
    min: 210,
    defaultChecked: true
  })

  const [sensorAggregateOptions, setSensorAggregateOptions] =
    useState<SensorAggregateOptions>({
      statistics: getInitialStatistics(),
      fields: [
        SensorAggregateField.VOLTAGE_1,
        SensorAggregateField.VOLTAGE_2,
        SensorAggregateField.VOLTAGE_3
      ]
    })

  function getInitialStatistics(): SensorAggregateStatistic[] {
    const searchParams = new URLSearchParams(window.location.search)
    const foundStatics = searchParams.get('voltage-statistics') ?? undefined
    if (foundStatics) {
      return JSON.parse(foundStatics)
    } else {
      searchParams.set(
        'voltage-statistics',
        JSON.stringify(
          appSettings.sensorSettings?.voltageChartStatistics
            ? appSettings.sensorSettings.voltageChartStatistics
            : [SensorAggregateStatistic.AVG]
        )
      )
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
      return appSettings.sensorSettings?.voltageChartStatistics
        ? appSettings.sensorSettings.voltageChartStatistics
        : [SensorAggregateStatistic.AVG]
    }
  }

  function changeStatistics(statistics: SensorAggregateStatistic[]): void {
    const appSettings = useAppSettings()
    const sensorSettings = appSettings.sensorSettings

    setSensorAggregateOptions({
      ...sensorAggregateOptions,
      statistics
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          voltageChartStatistics: statistics
        }
      })
    )

    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('voltage-statistics', JSON.stringify(statistics))

    const newRelativePathQuery =
      window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState(null, '', newRelativePathQuery)
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setYaxisOption({
        suggestedMax: 250,
        suggestedMin: 210,
        defaultChecked: false
      })
    } else if (event.target.checked === true) {
      setYaxisOption({ max: 250, min: 210, defaultChecked: true })
    }
  }

  /**
   * The difference of date range in hours.
   */
  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  /**
   * Indicates if sensor aggregates are being used.
   */
  const isUsingSensorAggregates = differenceOfDateRangeInHours > 3

  /**
   * The anchor element for the menu.
   */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  /**
   * Indicates if the menu is open.
   */
  const open = Boolean(anchorEl)

  /**
   * The size of the figure column.
   */

  function openMenu(event: MouseEvent<HTMLElement>): void {
    setAnchorEl(event.currentTarget)
  }

  function closeMenu(): void {
    setAnchorEl(null)
  }

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        pt={4}
        pb={2}
      >
        <Typography variant="h6">{t('Device.voltage')}</Typography>

        <Stack direction="row" spacing={3}>
          <SensorAggregateStatisticToggle
            value={sensorAggregateOptions.statistics}
            onChange={changeStatistics}
          />
        </Stack>
        <IconButton
          aria-controls={open ? 'menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={openMenu}
        >
          <MoreVertical />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            sx: {
              width: 320
            }
          }}
        >
          <MenuList>
            <ListItem>
              <ListItemText primary={t('zoomIn')} />
              <Switch
                size="small"
                defaultChecked={yAxisOption.defaultChecked}
                onChange={handleSwitchChange}
              />
            </ListItem>
          </MenuList>
        </Menu>
      </Box>

      <CardContent>
        <Stack direction="column">
          {sensor && isSensorNow(sensor) && (
            <>
              <DeviceVoltageBar device={device} sensor={sensor} />
              <Divider my={6} />
            </>
          )}

          {loading ? (
            <LoadingState />
          ) : (
            <DeviceVoltageChart
              device={device}
              dateRange={dateRange}
              sensors={sensors}
              sensorAggregates={sensorAggregates}
              sensorAggregateOptions={sensorAggregateOptions}
              yAxisOption={yAxisOption}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
