import { Search } from '@mui/icons-material'
import { Box, Paper, Typography } from '@mui/material'

interface EmptyStateProps {
  icon?: JSX.Element
  title: string
  children?: JSX.Element
}

export default function EmptyState({ icon, title, children }: EmptyStateProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: '2rem'
      }}
    >
      {icon ? icon : <Search fontSize="large" />}
      <Typography variant="h6" sx={{ mt: '1rem' }}>
        {title}
      </Typography>
      {children}
    </Paper>
  )
}
