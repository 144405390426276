import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/system'
import {
  humanizeTimestamp,
  lessThanMinutesAgo,
  moreThanMinutesAgo
} from '../utils/date'
import { differenceInMinutes } from 'date-fns'

interface RuuviTagStatsProps {
  title: string | ReactNode
  value?: number
  decimals?: number
  unit?: string
  date?: string
  isAging?: boolean
  loading?: boolean
}

export default function RuuviTagStats({
  title,
  value,
  decimals = 2,
  unit,
  date,
  isAging = false,
  loading = false
}: RuuviTagStatsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * Check if the date is outdated.
   */
  const isOutdated = isAging && date && moreThanMinutesAgo(new Date(date), 60)

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title={title}
        action={
          loading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100px"
              height="24px"
            />
          ) : (
            date &&
            !isOutdated && (
              <Typography color={theme.palette.text.secondary}>
                {humanizeTimestamp(date)}
              </Typography>
            )
          )
        }
      />
      <CardContent sx={{ pt: 0 }}>
        {loading ? (
          <Stack direction="column" gap={0}>
            <Skeleton
              animation="wave"
              variant="text"
              width="64px"
              sx={{ display: 'block', fontSize: '1.5rem' }}
            />

            <Skeleton
              animation="wave"
              variant="text"
              width="24px"
              sx={{ display: 'block', fontSize: '1rem' }}
            />
          </Stack>
        ) : value === undefined ? (
          <Alert variant="outlined" severity="warning" sx={{ p: 0, border: 0 }}>
            {t('readingIsMissing')}
          </Alert>
        ) : date && isOutdated ? (
          <Tooltip
            title={
              <Stack direction="column" gap={1}>
                <Box>{humanizeTimestamp(date)}</Box>
                <Box>{`${value.toFixed(decimals)} ${unit}`}</Box>
              </Stack>
            }
          >
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ p: 0, border: 0 }}
            >
              {t('readingIsOutdated')}
            </Alert>
          </Tooltip>
        ) : (
          <Stack direction="column">
            <Typography variant="h3">
              {value ? value.toFixed(decimals) : '-'}
            </Typography>

            <Typography variant="h6" color={theme.palette.text.secondary}>
              {unit}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}
