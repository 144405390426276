import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Button, Grid, Stack, TextField } from '@mui/material'
import { RootState } from '../redux/store'
import { createSector, updateSector, hideSector } from '../redux/slices/sectors'
import { getProjects } from '../redux/slices/projects'
import { useSector, useAuth } from '../hooks'
import SectorInput from '../types/SectorInput'
import ProjectSelect from './ProjectSelect'
import LoadingState from './LoadingState'
import sectorService from '../services/sectorService'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { deleteError, setError } from '../redux/slices/errors'
import { SectorErrors } from '../enums/SectorErrors'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'

export interface SectorFormProps {
  value?: SectorInput
  hidden?: Array<keyof SectorInput>
  disabled?: Array<keyof SectorInput>
}

export default function SectorForm({
  value,
  hidden = [],
  disabled = []
}: SectorFormProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const sector = useSector()

  const isLoadingProjects: boolean = useSelector(
    (state: RootState) => state.projects.loading
  )

  const initialValues: SectorInput = {
    name: sector?.name ?? '',
    number: sector?.number ?? 0,
    project: sector?.project?.id ?? null,
    ...value
  }

  const validationSchema = Yup.object().shape({
    project: Yup.number().required(
      t('isRequired', {
        field: t('Sector.project')
      })
    ),
    // FIXME: Add unique condition:
    // - Number should be unique among sectors of the selected project
    name: Yup.string().required(
      t('isRequired', {
        field: t('Sector.name')
      })
    ),
    number: Yup.number()
  })

  useEffect(() => {
    dispatch(getProjects())
  }, [])

  function isHidden(field: string): boolean {
    return hidden.includes(field as keyof SectorInput)
  }

  function isDisabled(field: string): boolean {
    return disabled.includes(field as keyof SectorInput)
  }

  const { currentUser } = useAuth()

  async function handleSubmit(
    values: SectorInput,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ): Promise<void> {
    try {
      if (sector === undefined) {
        const createdSector = await sectorService.createSector(values)

        dispatch(createSector(createdSector))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('sectorCreateSuccess')
          )
        )
      } else {
        const updatedSector = await sectorService.updateSector(
          sector.id,
          values
        )

        dispatch(updateSector(updatedSector))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('sectorUpdateSuccess')
          )
        )
      }
      resetForm()
      setStatus({ sent: true })
      dispatch(hideSector())
    } catch (error: any) {
      setStatus({ sent: false })
      // FIXME: Set validation errors.
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: sector ? SectorErrors.EDIT : SectorErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: sector ? SectorErrors.EDIT : SectorErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(sector ? SectorErrors.EDIT : SectorErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  function handleCancel(): void {
    dispatch(hideSector())
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                {!isHidden('project') && (
                  <Grid item xs={12}>
                    <ProjectSelect
                      name="project"
                      label={t('Sector.project')}
                      value={values.project}
                      error={Boolean(touched.project && errors.project)}
                      helperText={touched.project && errors.project}
                      disabled={
                        isLoadingProjects ||
                        isSubmitting ||
                        isDisabled('project')
                      }
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label={t('Sector.name')}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    disabled={isSubmitting || isDisabled('name')}
                    fullWidth
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={3}>
                    <Button
                      type="submit"
                      disabled={isSubmitting || isLoadingProjects}
                      variant="contained"
                      color="primary"
                    >
                      {t('save')}
                    </Button>
                    <Button
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => handleCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  )
}
