import { useTranslation } from 'react-i18next'
import { RuuviTagCondition } from '../types'
import RuuviTagStats from './RuuviTagStats'

interface RuuviTagPressureCardProps {
  ruuviTagCondition: RuuviTagCondition | null
  loading?: boolean
}

export default function RuuviTagPressureCard({
  ruuviTagCondition,
  loading = false
}: RuuviTagPressureCardProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <RuuviTagStats
      title={t('pressure')}
      value={ruuviTagCondition?.pressureAvg}
      unit="hPa"
      date={ruuviTagCondition?.time}
      loading={loading}
    />
  )
}
