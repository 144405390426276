import * as React from 'react'
import styled from 'styled-components/macro'
import { User } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton
} from '@mui/material'
import { getUser } from '../../redux/slices/users'
import { useDispatch } from 'react-redux'

import useAuth from '../../hooks/useAuth'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

// eslint-disable-next-line
function NavbarProfile() {
  // eslint-disable-next-line
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null)
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const dispatch = useDispatch()

  const handleSetUser = (id: number) => {
    dispatch(getUser(id))
    navigate('/profile/' + currentUser?.id)
  }

  return (
    <React.Fragment>
      {currentUser && (
        <Tooltip title="Profile">
          <IconButton
            aria-owns={anchorMenu ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={() => handleSetUser(parseInt(currentUser.id))}
            color="inherit"
            size="large"
          >
            <User data-cy="user" />
          </IconButton>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

export default NavbarProfile
