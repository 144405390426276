import { useState, ChangeEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Stack,
  TextField as MuiTextField,
  Typography
} from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { styled } from '@mui/styles'
import { spacing } from '@mui/system'
import useAppSettings from '../hooks/useAppSettings'
import { CalculationMode, StartDateMode } from '../enums'
import EmissionFactorMode from '../enums/EmissionFactorMode'
import {
  DateRange,
  EmissionFactorAggregate,
  EmissionQuery,
  EmissionSettings,
  StatsFigure,
  SensorAggregate,
  Emission
} from '../types'
import { calculateEmission } from '../utils/emission'
import DeviceStats from './DeviceStats'
import EmissionChart from './EmissionChart'
import CalculationModeToggle from './CalculationModeToggle'
import { transformEmissionFactorAggregatesToMap } from '../utils/emissionAggregate'
import { transformSensorAggregatesToMap } from '../utils/sensorAggregate'
import StartDateModeToggle from './StartDateModeToggle'
import EmissionFactorModeToggle from './EmissionFactorModeToggle'

const TextField = styled(MuiTextField)(spacing)

interface EmissionStatsProps {
  emissions?: Emission[]
  emissionsBeforeDateRange?: Emission[]
  query: EmissionQuery
  queryBeforeDateRange: EmissionQuery
  settings: EmissionSettings
  area: number | undefined
  loading?: boolean
  showCalculationModeOptions?: boolean
  calculationModeOptions?: CalculationMode[]
  showStartDateModeOptions?: boolean
  startDateModeOptions?: StartDateMode[]
  onChangeCalculationMode(calculationMode: CalculationMode): void
  onChangeStartDateMode(startDateMode: StartDateMode): void
  onChangeEmissionFactorMode(emissionFactorMode: EmissionFactorMode): void
  onChangeEmissionFactorValue(emissionFactorValue: number): void

  // @deprecated
  sensorAggregates?: SensorAggregate[]
  sensorAggregatesBeforeDateRange?: SensorAggregate[]
  emissionFactorAggregates?: EmissionFactorAggregate[]
  emissionFactorAggregatesBeforeDateRange?: EmissionFactorAggregate[]
}

export default function EmissionStats({
  emissions,
  emissionsBeforeDateRange,
  query,
  queryBeforeDateRange,
  settings,
  area,
  loading = false,
  showCalculationModeOptions = true,
  showStartDateModeOptions = true,
  calculationModeOptions,
  startDateModeOptions,
  onChangeCalculationMode,
  onChangeStartDateMode,
  onChangeEmissionFactorMode,
  onChangeEmissionFactorValue,
  sensorAggregates = [],
  sensorAggregatesBeforeDateRange = [],
  emissionFactorAggregates = [],
  emissionFactorAggregatesBeforeDateRange = []
}: EmissionStatsProps) {
  const [t] = useTranslation('common')

  const appSettings = useAppSettings()

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * Indicates if fixed emission factor is used.
   */
  const isUsingFixedEmissionFactor: boolean =
    settings.emissionFactorMode === EmissionFactorMode.FIXED

  /**
   * Indicates if realtime emisison factor is used.
   */
  const isUsingRealtimeEmissionFactor: boolean =
    settings.emissionFactorMode === EmissionFactorMode.REALTIME

  /**
   * The total emissions since before the date range (gCO2).
   */
  const totalEmissionsSinceBeforeDateRange: number =
    settings.startDateMode !== StartDateMode.SELECTED_DATE
      ? emissionsBeforeDateRange
        ? calculateTotalEmissionUsingEmissions(emissionsBeforeDateRange)
        : calculateEmissions(
            sensorAggregatesBeforeDateRange,
            emissionFactorAggregatesBeforeDateRange
          )
      : 0

  /**
   * The total emissions of the date range (gCO2).
   */
  const totalEmissionsOfDateRange: number = emissions
    ? calculateTotalEmissionUsingEmissions(emissions)
    : calculateEmissions(sensorAggregates, emissionFactorAggregates)

  /**
   * The total emissions (kgCO2).
   */
  const totalEmissions: number =
    (totalEmissionsSinceBeforeDateRange + totalEmissionsOfDateRange) / 1000

  /**
   * The total emissions per square meters (kgCO2/m2).
   */
  const emissionsPerSquareMeter: number | undefined = area
    ? totalEmissions / area
    : undefined

  /**
   * The stats figures.
   */
  const statsFigures = makeStatsFigures()

  /**
   * The date range of stats.
   */
  const statsDateRange: DateRange | undefined =
    settings.startDateMode !== StartDateMode.SELECTED_DATE
      ? {
          from: queryBeforeDateRange.from,
          to: query.to
        }
      : undefined

  function makeStatsFigures(): StatsFigure[] {
    const statsFigures: StatsFigure[] = []

    statsFigures.push({
      value: totalEmissions.toFixed(2),
      unit: 'kgCO2'
    })

    if (emissionsPerSquareMeter) {
      statsFigures.push({
        value: emissionsPerSquareMeter.toFixed(2),
        unit: 'kgCO2/m2'
      })
    }

    return statsFigures
  }

  function changeCalculationMode(
    event: MouseEvent<HTMLElement>,
    calculationMode: CalculationMode
  ): void {
    onChangeCalculationMode(calculationMode)
  }

  function changeStartDateMode(
    event: MouseEvent<HTMLElement>,
    startDateMode: StartDateMode
  ): void {
    onChangeStartDateMode(startDateMode)
  }

  function changeEmissionFactorMode(
    event: MouseEvent<HTMLElement>,
    emissionFactorMode: EmissionFactorMode
  ): void {
    onChangeEmissionFactorMode(emissionFactorMode)
  }

  function changeEmissionFactorValue(event: ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(event.target.value)
    onChangeEmissionFactorValue(value)
  }

  function calculateEmissions(
    sensorAggregates: SensorAggregate[],
    emissionFactorAggregates: EmissionFactorAggregate[]
  ): number {
    let emissions = 0

    const sensorAggregatesByDate: Map<string, SensorAggregate> =
      transformSensorAggregatesToMap(sensorAggregates)

    const emissionFactorAggregatesByDate: Map<string, EmissionFactorAggregate> =
      transformEmissionFactorAggregatesToMap(emissionFactorAggregates)

    sensorAggregatesByDate.forEach((sensorAggregate, date) => {
      const emissionFactorAggregate = emissionFactorAggregatesByDate.get(date)

      if (
        settings.emissionFactorMode === EmissionFactorMode.FIXED &&
        settings.emissionFactorValue
      ) {
        emissions += calculateEmission(
          sensorAggregate.avg_kw as number,
          settings.emissionFactorValue
        )
      } else if (
        settings.emissionFactorMode === EmissionFactorMode.REALTIME &&
        emissionFactorAggregate
      ) {
        emissions += calculateEmission(
          sensorAggregate.avg_kw as number,
          emissionFactorAggregate.avg_gco2_per_kwh
        )
      }
    })

    return emissions
  }

  function calculateTotalEmissionUsingEmissions(emissions: Emission[]): number {
    return emissions
      .map((emission) => {
        return emission.value
      })
      .reduce((sum, value) => {
        return sum + value
      }, 0)
  }

  return (
    <DeviceStats
      title={t('co2Emissions')}
      figures={statsFigures}
      dateRange={statsDateRange}
      loading={loading}
      chart={
        <EmissionChart
          emissions={emissions}
          sensorAggregates={sensorAggregates}
          emissionFactorAggregates={emissionFactorAggregates}
          query={query}
          settings={settings}
          initialEmission={totalEmissionsSinceBeforeDateRange}
        />
      }
      menu={
        <Stack direction="column" spacing={3}>
          {showCalculationModeOptions && (
            <CalculationModeToggle
              value={settings.calculationMode}
              onChange={changeCalculationMode}
            />
          )}

          {showStartDateModeOptions && (
            <StartDateModeToggle
              value={settings.startDateMode}
              options={startDateModeOptions}
              onChange={changeStartDateMode}
            />
          )}

          {/*(<Typography fontStyle="italic" fontSize="0.75rem" mt={3}>
            {settings.emissionFactorMode === EmissionFactorMode.REALTIME
              ? t('usingRealtimeEmissionFactors')
              : t('usingFixedEmissionFactorsOf', {
                  emissionFactor: settings.emissionFactorValue
                })}
          </Typography>)*/}

          {/*<Typography fontSize="0.75rem" mt={3}>
            {t('emissionFactor')}
          </Typography>
          <TextField
            type="number"
            value={settings.emissionFactorValue}
            disabled
            size="small"
            label={t('Device.gCo2PerH')}
            onClick={(event) => event.stopPropagation()}
            onChange={changeEmissionFactorValue}
            mt={3}
          />*/}

          {/*<EmissionFactorModeToggle
            value={settings.emissionFactorMode}
            onChange={changeEmissionFactorMode}
          />*/}

          {/*isUsingFixedEmissionFactor && (
            <TextField
              type="number"
              value={settings.emissionFactorValue}
              label={t('Device.gCo2PerH')}
              onClick={(event) => event.stopPropagation()}
              onChange={changeEmissionFactorValue}
            />
          )*/}

          {/*isUsingRealtimeEmissionFactor && (
            <Typography fontStyle="italic" fontSize="0.75rem" mt={3}>
              {t('emissionFactorHelper')}
            </Typography>
          )*/}
        </Stack>
      }
    />
  )
}
