import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { NotificationType } from '../../enums/NotificationType'

interface NotificationState {
  notifications: Notification[]
  notification: Notification | undefined
  manufacturingNotification: Notification | undefined
  authNotification: Notification | undefined
  show: boolean
  showInstructions: boolean
  status: 'idle' | 'loading' | 'failed'
}

export interface Notification {
  show: boolean
  type: string
  message: string
  timeout: number
}

const initialState: NotificationState = {
  notifications: [],
  notification: undefined,
  authNotification: undefined,
  manufacturingNotification: undefined,
  show: false,
  showInstructions: false,
  status: 'idle'
}

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification(state, action) {
      state.notification = action.payload
    },
    setAuthNotification(state, action) {
      state.authNotification = action.payload
    },
    setNotificationForManufacturingEnvironment(state, action) {
      state.manufacturingNotification = action.payload
    },
    setShowInstructions(state, action) {
      state.showInstructions = action.payload
    }
  }
})

export const { setNotification, setShowInstructions } =
  notificationSlice.actions

export function createNotification(notification: Notification) {
  return async (dispatch: Dispatch) => {
    dispatch(notificationSlice.actions.setNotification(notification))
  }
}

export function createErrorOrSuccessNotification(
  type: string,
  message: string
) {
  return async (dispatch: Dispatch) => {
    const notification = {
      show: true,
      type: type,
      message: message,
      timeout: 5000
    }
    dispatch(notificationSlice.actions.setNotification(notification))
  }
}

export function createWarningNotification(message: string) {
  return async (dispatch: Dispatch) => {
    const notification = {
      type: NotificationType.WARNING,
      message: message,
      show: true,
      timeout: 5000
    }

    dispatch(notificationSlice.actions.setNotification(notification))
  }
}

export function createNotificationForManufacturingEnvironment(
  manufacturingNotification: Notification
) {
  return async (dispatch: Dispatch) => {
    dispatch(
      notificationSlice.actions.setNotificationForManufacturingEnvironment(
        manufacturingNotification
      )
    )
  }
}

export default notificationSlice.reducer
