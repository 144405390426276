import { ReactElement, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import {
  DateRange,
  EnergyConsumptionSettings,
  EnergyConsumptionQuery,
  SensorAggregate,
  StatsFigure,
  EnergyConsumption
} from '../types'
import { CalculationMode, StartDateMode } from '../enums'
import DeviceStats from './DeviceStats'
import EnergyConsumptionChart from './EnergyConsumptionChart'
import CalculationModeToggle from './CalculationModeToggle'
import StartDateModeToggle from './StartDateModeToggle'

interface EnergyConsumptionStatsProps {
  energyConsumptions?: EnergyConsumption[]
  energyConsumptionsBeforeDateRange?: EnergyConsumption[]
  sensorAggregates?: SensorAggregate[]
  sensorAggregatesBeforeDateRange?: SensorAggregate[]
  query: EnergyConsumptionQuery
  settings: EnergyConsumptionSettings
  dateRange?: DateRange | null
  area?: number | null
  loading?: boolean
  showCalculationModeOptions?: boolean
  calculationModeOptions?: CalculationMode[]
  showStartDateModeOptions?: boolean
  startDateModeOptions?: StartDateMode[]
  onChangeCalculationMode(calculationMode: CalculationMode): void
  onChangeStartDateMode(startDateMode: StartDateMode): void
}

export default function EnergyConsumptionStats({
  energyConsumptions,
  energyConsumptionsBeforeDateRange,
  sensorAggregates,
  sensorAggregatesBeforeDateRange,
  query,
  settings,
  dateRange = null,
  area = null,
  loading = false,
  showCalculationModeOptions = true,
  showStartDateModeOptions = true,
  calculationModeOptions,
  startDateModeOptions,
  onChangeCalculationMode,
  onChangeStartDateMode
}: EnergyConsumptionStatsProps): ReactElement {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Indicates if the selected date range is used.
   */
  const isUsingSelectedDateRange: boolean =
    settings.startDateMode !== StartDateMode.SELECTED_DATE

  /**
   * The total energy consumption of date range.
   */
  const totalEnergyConsumptionOfDateRange: number =
    calculateTotalEnergyConsumptionOfDateRange()

  /**
   * The total energy consumption before the date range.
   */
  const totalEnergyConsumptionBeforeDateRange: number = isUsingSelectedDateRange
    ? calculateTotalEnergyConsumptionBeforeDateRange()
    : 0

  /**
   * The total energy consumption.
   */
  const totalEnergyConsumption: number =
    totalEnergyConsumptionBeforeDateRange + totalEnergyConsumptionOfDateRange

  /**
   * The energy consumption per square meters.
   */
  const energyConsumptionPerSquareMeter: number | undefined = area
    ? totalEnergyConsumption / area
    : undefined

  /**
   * The stats figures.
   */
  const statsFigures = makeStatsFigures()

  /**
   * Change the calculation mode.
   */
  function changeCalculationMode(
    event: MouseEvent<HTMLElement>,
    calculationMode: CalculationMode
  ): void {
    onChangeCalculationMode(calculationMode)
  }

  /**
   * Change the start date mode.
   */
  function changeStartDateMode(
    event: MouseEvent<HTMLElement>,
    startDateMode: StartDateMode
  ): void {
    onChangeStartDateMode(startDateMode)
  }

  function makeStatsFigures(): StatsFigure[] {
    const figures: StatsFigure[] = []

    figures.push({
      value: totalEnergyConsumption.toFixed(2),
      unit: 'kWh'
    })

    if (energyConsumptionPerSquareMeter) {
      figures.push({
        value: energyConsumptionPerSquareMeter.toFixed(2),
        unit: 'kWh/m2'
      })
    }

    return figures
  }

  // prettier-ignore
  function calculateTotalEnergyConsumptionOfDateRange(): number {
    if (energyConsumptions !== undefined) {
      return calculateTotalEnergyConsumptionUsingEnergyConsumptions(energyConsumptions)
    }

    if (sensorAggregates !== undefined) {
      return calculateTotalEnergyConsumptionUsingSensorAggregates(sensorAggregates)
    }

    return 0
  }

  // prettier-ignore
  function calculateTotalEnergyConsumptionBeforeDateRange(): number {
    if (energyConsumptionsBeforeDateRange !== undefined) {
      return calculateTotalEnergyConsumptionUsingEnergyConsumptions(energyConsumptionsBeforeDateRange)
    }

    if (sensorAggregatesBeforeDateRange !== undefined) {
      return calculateTotalEnergyConsumptionUsingSensorAggregates(sensorAggregatesBeforeDateRange)
    }

    return 0
  }

  function calculateTotalEnergyConsumptionUsingSensorAggregates(
    sensorAggregates: SensorAggregate[]
  ): number {
    return sensorAggregates
      .map((sensorAggregate) => sensorAggregate.avg_kw)
      .reduce((sum: number, value: number) => sum + value, 0)
  }

  function calculateTotalEnergyConsumptionUsingEnergyConsumptions(
    energyConsumptions: EnergyConsumption[]
  ): number {
    return energyConsumptions
      .map((energyConsumption) => energyConsumption.value)
      .reduce((sum: number, value: number) => sum + value, 0)
  }

  // <StatsCard
  //   title: t('energyConsumption')
  //   total: totalEnergyConsumption
  //   perSquareMeter?: energyConsumptionPerSquareMeter
  //   unit: 'kWh'
  //   loading?
  //   chip?: dateRangeAsString
  //   actions?
  //   menu?
  //   chart?
  // />
  return (
    <DeviceStats
      title={t('energyConsumption')}
      figures={statsFigures}
      dateRange={
        settings.startDateMode !== StartDateMode.SELECTED_DATE
          ? dateRange
          : null
      }
      loading={loading}
      menu={
        /* <EnergyConsumptionMenu /> */
        <Stack direction="column" spacing={3}>
          {showCalculationModeOptions && (
            <CalculationModeToggle
              value={settings.calculationMode}
              options={calculationModeOptions}
              onChange={changeCalculationMode}
            />
          )}

          {showStartDateModeOptions && (
            <StartDateModeToggle
              value={settings.startDateMode}
              options={startDateModeOptions}
              onChange={changeStartDateMode}
            />
          )}
        </Stack>
      }
      chart={
        <EnergyConsumptionChart
          energyConsumptions={energyConsumptions}
          sensorAggregates={sensorAggregates}
          query={query}
          settings={settings}
          initialValue={totalEnergyConsumptionBeforeDateRange}
        />
      }
    />
  )
}
