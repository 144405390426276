import {
  AlertGroup,
  AlertRule,
  AlertTemplate,
  UserAlertRule
} from '../types/Alerts'
import { AlertDataGridRow } from '../components/ProjectAlertManagementTable'
import { UserAlertDataGridRow } from '../components/UserAlertManagementPopUp'
import { Sensor } from '../types'

export enum AlertRuleType {
  HIGH_CURRENT = 'HighCurrent',
  HIGH_HUMIDITY = 'HighHumidity',
  LOW_SIGNAL = 'LowSignal',
  HIGH_TEMPERATURE = 'HighTemperature',
  HIGH_VOLTAGE = 'HighVoltage',
  LOW_VOLTAGE = 'LowVoltage',
  OVERLOADED_CURRENT = 'OverloadedCurrent',
  SHORT_CIRCUIT = 'ShortCircuit'
}

export function buildAlertStructure(
  data: AlertDataGridRow[],
  alertTemplates: AlertTemplate[]
): AlertGroup[] {
  const parsed: AlertGroup[] = alertTemplates.map((template): AlertGroup => {
    return {
      name: template.name,
      alerts: Object.entries(template.defaults).map(
        ([alertName, alert]): AlertRule => {
          return {
            name: alertName,
            enabled: alert.enabled,
            variables: alert.variables
          }
        }
      )
    }
  })

  data.forEach((row: AlertDataGridRow) => {
    const parsedValue = parsed.find((template) => template.name === row.group)!

    const alertIndex = parsedValue.alerts.findIndex(
      (alert) => alert.name === row.name
    )

    const rowAsAlert: AlertRule = {
      name: row.name,
      enabled: row.enabled,
      variables: {
        param1: {
          value: row.value,
          unit: row.unit,
          readonly: row.readonly
        }
      }
    }

    if (alertIndex === -1) {
      parsedValue.alerts.push(rowAsAlert)
    } else {
      rowAsAlert.variables.param1.type =
        parsedValue.alerts[alertIndex].variables.param1.type
      parsedValue.alerts[alertIndex] = rowAsAlert
    }
  })

  return parsed
}

export function checkAlertValueUnit(
  dataGridRowId: string,
  projectAlertRules: AlertGroup[]
) {
  let unit
  projectAlertRules.map((group: AlertGroup) => {
    group.alerts.map((alert: AlertRule) => {
      if (alert.name === dataGridRowId) {
        unit = alert.variables.param1.unit
      }
    })
  })
  return unit
}

export function buildAlertStructureForUser(data: UserAlertDataGridRow[]) {
  const alertRules: UserAlertRule[] = []
  data.map((rule: UserAlertDataGridRow) => {
    if (
      rule.appNotificationEnabled === true ||
      rule.notificationEnabled === true
    )
      alertRules.push({
        ruleGroupName: rule.ruleGroupName,
        alertRuleName: rule.alertRuleName,
        notificationEnabled: rule.notificationEnabled,
        appNotificationEnabled: rule.appNotificationEnabled
      })
  })

  return alertRules
}

export function isDeviceAlerting(
  sensor: Sensor | null,
  alertRules: AlertGroup[]
) {
  const isAlerting: any[] = []
  let firingAlertType: AlertRuleType | null = null
  alertRules?.forEach((group: AlertGroup) => {
    group.alerts.forEach((alert: AlertRule) => {
      if (alert.enabled === true && sensor) {
        isAlerting.push({
          alerting: compareValues(sensor, alert),
          name: alert.name
        })
      }
    })
  })

  for (let i = 0; i < isAlerting.length; i++) {
    if (isAlerting[i].alerting === true) {
      firingAlertType = isAlerting[i].name
      break
    }
  }

  return firingAlertType
}

function compareValues(sensor: Sensor, alert: AlertRule): boolean {
  switch (alert.name) {
    case AlertRuleType.SHORT_CIRCUIT:
      return (
        (sensor.current_1 / sensor.rated_current) * 100 >
          alert.variables.param1.value ||
        (sensor.current_2 / sensor.rated_current) * 100 >
          alert.variables.param1.value ||
        (sensor.current_3 / sensor.rated_current) * 100 >
          alert.variables.param1.value
      )
    case AlertRuleType.HIGH_CURRENT:
      return (
        (sensor.current_1 / sensor.rated_current) * 100 >
          alert.variables.param1.value ||
        (sensor.current_2 / sensor.rated_current) * 100 >
          alert.variables.param1.value ||
        (sensor.current_3 / sensor.rated_current) * 100 >
          alert.variables.param1.value
      )
    case AlertRuleType.OVERLOADED_CURRENT:
      return (
        (sensor.current_1 / sensor.rated_current) * 100 >
          alert.variables.param1.value ||
        (sensor.current_2 / sensor.rated_current) * 100 >
          alert.variables.param1.value ||
        (sensor.current_3 / sensor.rated_current) * 100 >
          alert.variables.param1.value
      )
    case AlertRuleType.HIGH_HUMIDITY:
      return sensor.rh > alert.variables.param1.value
    case AlertRuleType.LOW_SIGNAL:
      return sensor.rssi < alert.variables.param1.value
    case AlertRuleType.HIGH_TEMPERATURE:
      return sensor.temp > alert.variables.param1.value
    case AlertRuleType.HIGH_VOLTAGE:
      return (
        sensor.voltage_1 > alert.variables.param1.value ||
        sensor.voltage_2 > alert.variables.param1.value ||
        sensor.voltage_3 > alert.variables.param1.value
      )
    case AlertRuleType.LOW_VOLTAGE:
      return (
        sensor.voltage_1 < alert.variables.param1.value ||
        sensor.voltage_2 < alert.variables.param1.value ||
        sensor.voltage_3 < alert.variables.param1.value
      )
    default:
      return false
  }
}
