import { RuuviTag } from '../types'
import StatusIndicator from './StatusIndicator'

/**
 * The props of the ruuvi tag status indicator.
 */
interface RuuviTagStatusIndicatorProps {
  /**
   * The ruuvi tag.
   */
  ruuviTag: RuuviTag

  /**
   * The font size.
   */
  fontSize?: 'small' | 'medium' | 'large' | 'inherit'
}

/**
 * An indicator for the ruuvi tag status.
 */
export default function RuuviTagStatusIndicator({
  ruuviTag,
  fontSize = 'inherit'
}: RuuviTagStatusIndicatorProps): JSX.Element {
  return <StatusIndicator status={ruuviTag.status} fontSize={fontSize} />
}
