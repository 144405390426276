import {
  addHours,
  addDays,
  addMinutes,
  differenceInHours,
  format,
  getHours,
  getMinutes,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfHour,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMinutes,
  differenceInMinutes,
  isSameYear,
  isSameMonth,
  isSameDay
} from 'date-fns'
import { StartDateMode } from '../enums'
import DateInterval from '../enums/DateInterval'
import { DateRange, Project, ActiveProject } from '../types'

export function toNearestMinutesInterval(date: Date, interval = 1): Date {
  const minutes: number = Math.floor(getMinutes(date) / interval) * interval
  return setMilliseconds(setSeconds(setMinutes(date, minutes), 0), 0)
}

export function toNearestHoursInterval(date: Date, interval = 1): Date {
  const hours: number = Math.floor(getHours(date) / interval) * interval
  return setMilliseconds(setSeconds(setMinutes(setHours(date, hours), 0), 0), 0)
}

export function humanizeDate(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return format(date, 'd.M.yyyy')
}

export function humanizeTimestamp(
  timestamp: Date | string,
  includeSeconds = false
): string {
  if (typeof timestamp === 'string') {
    timestamp = new Date(timestamp)
  }

  return includeSeconds
    ? format(timestamp, 'd.M.yyyy HH:mm:ss')
    : format(timestamp, 'd.M.yyyy HH:mm')
}

export function humanizeDateRange(
  from: Date | string,
  to: Date | string
): string {
  return `${humanizeTimestamp(from)}–⁠${humanizeTimestamp(to)}`
}

export function humanizeDayRange(
  from: Date | string,
  to: Date | string
): string {
  if (typeof from === 'string') {
    from = new Date(from)
  }

  if (typeof to === 'string') {
    to = new Date(to)
  }

  if (isSameYear(from, to)) {
    if (isSameMonth(from, to)) {
      return isSameDay(from, to)
        ? `${format(from, 'd.M.yyyy')}`
        : `${format(from, 'd')}.–${format(to, 'd.M.yyyy')}`
    } else {
      return `${format(from, 'd.M')}.–${format(to, 'd.M.yyyy')}`
    }
  } else {
    return `${humanizeDate(from)}–${humanizeDate(to)}`
  }
}

export function getDatesByRange(
  from: Date,
  to: Date,
  interval: DateInterval
): Date[] {
  const dates: Date[] = []
  let date: Date = startOfInterval(from, interval)

  while (date <= to) {
    dates.push(date)
    date = addInterval(date, interval)
  }

  return dates
}

export function startOfInterval(date: Date, interval: DateInterval): Date {
  switch (interval) {
    case DateInterval.FIVE_MINUTES:
      return toNearestMinutesInterval(date, 5)
    case DateInterval.TEN_MINUTES:
      return toNearestMinutesInterval(date, 10)
    case DateInterval.FIFTEEN_MINUTES:
      return toNearestMinutesInterval(date, 15)
    case DateInterval.ONE_DAY:
      return subMinutes(startOfDay(date), date.getTimezoneOffset())
    case DateInterval.ONE_HOUR:
    default:
      return startOfHour(date)
  }
}

export function addInterval(date: Date, interval: DateInterval): Date {
  switch (interval) {
    case DateInterval.FIVE_MINUTES:
      return addMinutes(date, 5)
    case DateInterval.TEN_MINUTES:
      return addMinutes(date, 10)
    case DateInterval.FIFTEEN_MINUTES:
      return addMinutes(date, 15)
    case DateInterval.ONE_DAY:
      return addDays(date, 1)
    case DateInterval.ONE_HOUR:
    default:
      return addHours(date, 1)
  }
}

export function getStartDateByMode(
  date: Date,
  mode: StartDateMode,
  project: Project | ActiveProject | undefined
): Date {
  switch (mode) {
    case StartDateMode.START_OF_DAY:
      return startOfDay(date)
    case StartDateMode.START_OF_WEEK:
      return startOfWeek(date)
    case StartDateMode.START_OF_MONTH:
      return startOfMonth(date)
    case StartDateMode.START_OF_YEAR:
      return startOfYear(date)
    case StartDateMode.START_OF_PROJECT:
      return startOfDay(project?.startDate ? new Date(project.startDate) : date)
    case StartDateMode.SELECTED_DATE:
    default:
      return date
  }
}

export function getDifferenceOfDateRangeInHours(
  fromOrDateRange: Date | DateRange,
  to?: Date
): number {
  if ('from' in fromOrDateRange && 'to' in fromOrDateRange) {
    const dateRange = fromOrDateRange
    return Math.abs(differenceInHours(dateRange.from, dateRange.to))
  }

  const from = fromOrDateRange
  return Math.abs(differenceInHours(from as Date, to as Date))
}

/**
 * Get the default date interval for the given date range.
 */
export function getDefaultDateIntervalByDateRange(
  dateRange: DateRange
): DateInterval {
  const hours = getDifferenceOfDateRangeInHours(dateRange)

  if (hours <= 24) {
    return DateInterval.FIVE_MINUTES
  } else if (hours <= 72) {
    return DateInterval.FIFTEEN_MINUTES
  } else if (hours <= 744) {
    return DateInterval.ONE_HOUR
  } else {
    return DateInterval.ONE_DAY
  }
}

/**
 * Check if the given date is more than the given minutes ago.
 *
 * @param date - The date
 * @param minutes - The minutes
 *
 * @returns True if the date is more than the given minutes ago, false otherwise
 */
export function moreThanMinutesAgo(date: Date, minutes: number): boolean {
  return differenceInMinutes(new Date(), date) > minutes
}

/**
 * Check if the given date is less than the given minutes ago.
 *
 * @param date - The date
 * @param minutes - The minutes
 *
 * @returns True if the date is less than the given minutes ago, false otherwise
 */
export function lessThanMinutesAgo(date: Date, minutes: number): boolean {
  return differenceInMinutes(new Date(), date) < minutes
}

/**
 * Check if the given date is more than the given hours ago.
 *
 * @param date - The date
 * @param hours - The hours
 *
 * @returns True if the date is more than the given hours ago, false otherwise
 */
export function moreThanHoursAgo(date: Date, hours: number): boolean {
  return moreThanMinutesAgo(date, hours * 60)
}

/**
 * Check if the given date is more than given days ago.
 *
 * @param date - The date
 * @param days - The days
 *
 * @returns True if the date is more than the given days ago, false otherwise
 */
export function moreThanDaysAgo(date: Date, days: number): boolean {
  return moreThanHoursAgo(date, days * 24)
}
