import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { AssetGridViewMode, AssetQuery, Statistic } from '../types'
import { AssetType } from '../enums/AssetType'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Circle } from '@mui/icons-material'

interface AssetFiltersProps {
  /**
   * The asset query.
   */
  query?: AssetQuery

  /**
   * The selected view mode.
   */
  viewMode?: AssetGridViewMode

  /**
   * The selected statistic.
   */
  statistic?: Statistic

  /**
   * The handler on query change.
   *
   * @param query - The new asset query.
   */
  onQueryChange?: (query: AssetQuery) => void

  /**
   * The handler on view mode change.
   *
   * @param viewMode - The new view mode.
   */
  onViewModeChange?: (viewMode: AssetGridViewMode) => void

  /**
   * The handler on statistic change.
   *
   * @param statistic - The new statistic.
   */
  onStatisticChange?: (statistic: Statistic) => void
}

export default function AssetFilters({
  query = {},
  viewMode = 'large',
  statistic = 'avg',
  onQueryChange,
  onViewModeChange,
  onStatisticChange
}: AssetFiltersProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Handle query type change.
   *
   * @param event - The change event.
   */
  function handleQueryTypeChange(
    event: SelectChangeEvent<AssetType | undefined>
  ): void {
    const type =
      event.target.value !== undefined
        ? (event.target.value as AssetType)
        : undefined

    if (onQueryChange) {
      onQueryChange({ ...query, type })
    }
  }

  /**
   * Clear the query type.
   */
  function clearQueryType(): void {
    if (onQueryChange) {
      onQueryChange({ ...query, type: undefined })
    }
  }

  /**
   * Handle query status change.
   *
   * @param event - The change event.
   */
  function handleQueryStatusChange(
    event: MouseEvent<HTMLElement>,
    status: boolean | undefined
  ): void {
    if (onQueryChange) {
      onQueryChange({ ...query, status })
    }
  }

  /**
   * Change the view mode.
   *
   * @param event - The change event.
   */
  function handleViewModeChange(
    event: SelectChangeEvent<AssetGridViewMode>
  ): void {
    if (onViewModeChange) {
      onViewModeChange(event.target.value as AssetGridViewMode)
    }
  }

  /**
   * Change the selected statistic.
   *
   * @param event - The change event.
   */
  function handleStatisticChange(event: SelectChangeEvent<Statistic>): void {
    if (onStatisticChange) {
      onStatisticChange(event.target.value as Statistic)
    }
  }

  return (
    <Stack direction="row" gap={6}>
      <FormControl sx={{ width: 200 }} size="small">
        <InputLabel>{t('type')}</InputLabel>
        <Select
          value={query?.type}
          onChange={handleQueryTypeChange}
          label={t('type')}
        >
          <MenuItem key="all" onClick={() => clearQueryType()}>
            {t('all')}
          </MenuItem>
          <MenuItem key={AssetType.DEVICE} value={AssetType.DEVICE}>
            {t('devices')}
          </MenuItem>
          <MenuItem key={AssetType.RUUVI_TAG} value={AssetType.RUUVI_TAG}>
            {t('conditionMonitoring')}
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ width: 200 }} size="small">
        <InputLabel>{t('viewMode')}</InputLabel>
        <Select
          value={viewMode}
          label={t('viewMode')}
          onChange={handleViewModeChange}
        >
          <MenuItem value="large">{t('largeGrid')}</MenuItem>
          <MenuItem value="small">{t('smallGrid')}</MenuItem>
          <MenuItem value="data">{t('table')}</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ width: 120 }} size="small">
        <InputLabel>{t('statistic')}</InputLabel>
        <Select
          value={statistic}
          label={t('statistic')}
          onChange={handleStatisticChange}
        >
          <MenuItem key="avg" value="avg">
            {t('average')}
          </MenuItem>
          <MenuItem key="min" value="min">
            {t('lowest')}
          </MenuItem>
          <MenuItem key="max" value="max">
            {t('highest')}
          </MenuItem>
        </Select>
      </FormControl>

      <ToggleButtonGroup
        size="small"
        value={query?.status}
        exclusive
        onChange={handleQueryStatusChange}
      >
        <ToggleButton size="small" value={true}>
          <Circle color="success" fontSize="inherit" sx={{ mr: 1 }} />
          {t('online')}
        </ToggleButton>
        <ToggleButton size="small" value={false}>
          <Circle color="error" fontSize="inherit" sx={{ mr: 1 }} />
          {t('offline')}
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
