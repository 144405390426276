import { Grid, Card as MuiCard } from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { GeoCoordinate } from '../types'

const Card = styled(MuiCard)(spacing)

export interface CompaniesMapProps {
  longLatList: GeoCoordinate[]
}

export default function CompaniesMap({ longLatList }: CompaniesMapProps) {
  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl>
      <Card mb={6}>
        {longLatList && longLatList.length > 0 && (
          <Map
            mapboxAccessToken={apiKey}
            initialViewState={{
              latitude: longLatList[0].lat,
              longitude: longLatList[0].long,
              zoom: 10
            }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            style={{ width: '100%', height: 560 }}
          >
            {longLatList.map((longlat: GeoCoordinate) => (
              <Marker longitude={longlat.long} latitude={longlat.lat} />
            ))}
          </Map>
        )}
      </Card>
    </Grid>
  )
}
