import { AxiosRequestConfig } from 'axios'
import {
  ActionInput,
  Emission,
  EmissionQuery,
  EnergyConsumption,
  EnergyConsumptionQuery,
  EnergyCost,
  EnergyCostQuery,
  Sensor,
  SensorQuery,
  StatusOutput,
  SensorError,
  RuuviTag
} from '../types'
import { Device } from '../types'
import { useApi } from '../hooks'
import { OnomondoSim } from '../types/Onomondo'
import { RebootMessage } from '../types/StatusMessage'

const api = useApi('v2')

export async function getDevices(
  config?: AxiosRequestConfig
): Promise<Device[]> {
  const response = await api.get('/devices', config)

  return response.data
}

// TODO: GET /projects/{projectId}/devices or GET /devices?projectId={projectId}
export async function getProjectDevices(
  id: number,
  config?: AxiosRequestConfig
): Promise<Device[]> {
  const response = await api.get(`/devices/project/${id}`, config)

  return response.data
}

export async function getDevice(
  idOrName: string | number,
  config?: AxiosRequestConfig
): Promise<Device> {
  const response = await api.get(`/devices/${idOrName}`, config)

  return response.data
}

export async function replaceDevice(
  replaceable: string,
  replacement: string
): Promise<void> {
  await api.patch(`/devices/${replaceable}/replace/${replacement}`)
}

export async function getSensors(
  deviceCode: string,
  { from, to }: SensorQuery,
  config: AxiosRequestConfig = {}
): Promise<Sensor[]> {
  const response = await api.get(`/devices/${deviceCode}/sensors`, {
    ...config,
    params: {
      from: from.toISOString(),
      to: to.toISOString()
    }
  })

  return response.data
}

/**
 * Get sensors of the last hour.
 *
 * @param deviceCode - The device code
 * @param config - The axios request configuration
 *
 * @returns The sensors of the last hour
 */
export async function getSensorsOfTheLastHour(
  deviceCode: string,
  config?: AxiosRequestConfig
): Promise<Sensor[]> {
  const response = await api.get(
    `/devices/${deviceCode}/sensors/last-hour`,
    config
  )

  return response.data
}

export async function getSensorNow(
  deviceCode: string,
  config?: AxiosRequestConfig
): Promise<Sensor> {
  const response = await api.get(`/devices/${deviceCode}/sensor/now`, config)

  return response.data
}

export async function getLastSensor(
  deviceCode: string,
  config?: AxiosRequestConfig
): Promise<Sensor> {
  const response = await api.get(`/devices/${deviceCode}/sensor/last`, config)

  return response.data
}

/**
 * Get the energy consumptions of the device.
 */
export async function getEnergyConsumptions(
  deviceCode: string,
  query: EnergyConsumptionQuery,
  config: AxiosRequestConfig = {}
): Promise<EnergyConsumption[]> {
  const response = await api.get(`/devices/${deviceCode}/energy-consumptions`, {
    params: query,
    ...config
  })

  return response.data
}

/**
 * Get the energy costs of the device.
 */
export async function getEnergyCosts(
  deviceCode: string,
  query: EnergyCostQuery,
  config: AxiosRequestConfig = {}
): Promise<EnergyCost[]> {
  const response = await api.get(`/devices/${deviceCode}/energy-costs`, {
    params: query,
    ...config
  })

  return response.data
}

/**
 * Get the energy emissions of the device.
 */
export async function getEnergyEmissions(
  deviceCode: string,
  query: EmissionQuery,
  config: AxiosRequestConfig = {}
): Promise<Emission[]> {
  const response = await api.get(`/devices/${deviceCode}/energy-emissions`, {
    params: query,
    ...config
  })

  return response.data
}

/**
 * Invoke an action on a device.
 */
export async function invokeAction(
  name: string,
  input: ActionInput
): Promise<any> {
  const response = await api.post(`/devices/${name}/action`, input)

  return response.data
}

/**
 * Get the status of a device.
 */
export async function getStatus(
  name: string,
  config?: AxiosRequestConfig
): Promise<StatusOutput> {
  const response = await api.get(`/devices/${name}/status`, config)

  return response.data
}

/**
 * Get the sensor errors of a device.
 */
export async function getSensorErrors(
  name: string,
  config?: AxiosRequestConfig
): Promise<SensorError[]> {
  const response = await api.get(`/devices/${name}/sensor-errors`, config)

  return response.data
}

/**
 * Get the latest sensor error of a device.
 * @param name - name of the device.
 * @returns the latest sensor error in a promise.
 * @throws if the request fails.
 */
export async function getLatestSensorError(name: string): Promise<SensorError> {
  const api = useApi('v2')

  const response = await api.get(`/devices/${name}/latest-sensor-error`)
  return response.data
}

/**
 * Get the sim status of a device.
 */
export async function getSimStatus(
  name: string,
  config?: AxiosRequestConfig
): Promise<OnomondoSim> {
  const response = await api.get(`/devices/${name}/sim-status`, config)

  return response.data
}

/**
 * Fet the latest sensor reboot message.
 *
 * @param name - the name of the device.
 * @returns the latest sensor reboot message if found.
 *  @throws if the request fails.
 */
export async function getLatestReboot(name: string): Promise<RebootMessage> {
  const response = await api.get(`/devices/${name}/latest-reboot`)

  return response.data
}

/**
 * Get the paired ruuvi tag.
 */
export async function getPairedRuuviTag(
  name: string,
  config?: AxiosRequestConfig
): Promise<RuuviTag> {
  const response = await api.get(`/devices/${name}/paired-ruuvi-tag`, config)

  return response.data
}

export default {
  getDevices,
  getDevice,
  getSensorNow,
  getLastSensor,
  getProjectDevices
}
