import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Link,
  TableContainer,
  Tab,
  Breadcrumbs,
  Skeleton,
  Stack
} from '@mui/material'
import { spacing, SpacingProps } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import { deleteUser, getUser, setShow } from '../../redux/slices/users'
import useAuth from '../../hooks/useAuth'
import { Delete, Edit, Lock, Shield } from '@mui/icons-material'
import UserForm from '../../components/UserForm'
import ProjectTable from '../../components/ProjectTable'
import { THEMES } from '../../constants'
import { scrollbarForDark, scrollbarForLight } from '../../theme/scrollbar'
import useTheme from '../../hooks/useTheme'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { ProfileTab } from '../../enums/ProfileTabs'
import ProfileAlertManagementTable from '../../components/ProfileAlertManagementTable'
import { Company, Project, User } from '../../types'
import { UserErrors } from '../../enums/UserErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import ProfileRequestManagementTable from '../../components/ProfileRequestManagementTable'
import { NavLink } from 'react-router-dom'
import { MFAType } from '../../enums'
import { UserRole } from '../../enums/UserRole'

const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Grid = styled(MuiGrid)(spacing)

interface TypographyProps extends SpacingProps {
  component?: string
}

const Typography = styled(MuiTypography)<TypographyProps>(spacing)

interface CompanyCardProps {
  company: Company
}

function CompanyCard({ company }: CompanyCardProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Card mb={6}>
      <CardHeader title={t('company')} />
      <CardContent>
        {company && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('address')}</TableCell>
                <TableCell>{t('city')}</TableCell>
                <TableCell>{t('zip')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {company.name}
                </TableCell>
                <TableCell>{company.address}</TableCell>
                <TableCell>{company.city}</TableCell>
                <TableCell>{company.zip}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}

interface ProjectsCardProps {
  projects: Project[]
}

function ProjectsCard({ projects }: ProjectsCardProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Card mb={6}>
      <CardHeader title={t('projects')} />
      <CardContent>
        <ProjectTable projects={projects} />
      </CardContent>
    </Card>
  )
}

interface UserCardProps {
  user: User
}

function UserCard({ user }: UserCardProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if the user is shown.
   */
  const show = useSelector((state: RootState) => state.users.show)

  /**
   * The theme.
   */
  const { theme } = useTheme()

  const [open, setOpen] = useState(false)

  const handleEdit = (id: number) => {
    dispatch(getUser(id))
    dispatch(setShow(true))
  }

  const handleDelete = (id: number) => {
    try {
      dispatch(deleteUser(id))
      navigate('/auth/sign-in')
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UserErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        title={t('userAccount')}
        action={
          <Stack direction="row" gap={2}>
            {show ? (
              <Button
                variant="outlined"
                onClick={() => dispatch(setShow(false))}
              >
                {t('cancel')}
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="warning"
                  size="small"
                  endIcon={<Edit fontSize="inherit" />}
                  onClick={() => handleEdit(user.id)}
                >
                  {t('editUserAccount')}
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<Lock fontSize="inherit" />}
                  onClick={() => navigate('/auth/change-password')}
                >
                  {t('Profile.change')}
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<Shield fontSize="inherit" />}
                  onClick={() => navigate('/mfa')}
                >
                  {t('mfa')}
                </Button>
              </>
            )}

            {show !== true && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                endIcon={<Delete fontSize="inherit" />}
                onClick={() => setOpen(true)}
              >
                {t('deleteUserAccount')}
              </Button>
            )}
          </Stack>
        }
      />
      <CardContent>
        {show ? (
          <UserForm />
        ) : (
          <TableContainer
            sx={theme === THEMES.DARK ? scrollbarForDark : scrollbarForLight}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Users.name')}</TableCell>
                  <TableCell>{t('Users.email')}</TableCell>
                  <TableCell>{t('Users.phone')}</TableCell>
                  <TableCell>{t('mfa')}</TableCell>
                  <TableCell>{t('Users.role')}</TableCell>
                  <TableCell>{t('Users.company')}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={user.id}>
                  <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>
                    {user.mfaType === MFAType.NONE ? (
                      <Chip
                        variant="outlined"
                        color="error"
                        size="small"
                        label={t('no')}
                      />
                    ) : (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={t('yes')}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip variant="outlined" size="small" label={user.role} />
                  </TableCell>
                  {user.company ? (
                    <TableCell>
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        component={NavLink}
                        to={`/companies/${user.company.id}`}
                        label={user.company.name}
                        clickable
                      />
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                  {currentUser?.email === user.email ? (
                    <TableCell align="right">
                      <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {t('Profile.confirm')}
                        </DialogTitle>

                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {t('Profile.warning')}
                          </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                          <Button onClick={() => handleDelete(user.id)}>
                            {t('Profile.yes')}
                          </Button>
                          <Button onClick={() => setOpen(false)} autoFocus>
                            {t('Profile.no')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  )
}

export default function Profile(): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The route params.
   */
  const { id } = useParams()

  /**
   * The user state.
   */
  const { user } = useSelector((state: RootState) => state.users)

  /**
   * The projects of user.
   */
  const projects = (user?.projects ?? [])
    .map(({ project }) => project)
    .filter((project) => !!project)

  const handleChange = (event: React.SyntheticEvent, tab: string): void => {
    setTab(tab as ProfileTab)
  }

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getUser(parseInt(id)))
    }
  }, [currentUser, id])

  const [tab, setTab] = useState<ProfileTab>(getInitialTab())

  function getInitialTab(): ProfileTab {
    const hash: string = location.hash.substring(1).split('?')[0]

    switch (hash) {
      case ProfileTab.ALERT_MANAGEMENT:
        return ProfileTab.ALERT_MANAGEMENT
      case ProfileTab.REQUEST_MANAGEMENT:
        return ProfileTab.REQUEST_MANAGEMENT
      default:
        return ProfileTab.DASHBOARD
    }
  }

  function canViewCompany(): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      currentUser?.email === user?.email
    )
  }

  function canViewProjects(): boolean {
    return (
      currentUser?.role === UserRole.SUPERADMIN ||
      currentUser?.email === user?.email
    )
  }

  return (
    <React.Fragment>
      <Helmet title={user ? `${user.firstName} ${user.lastName}` : t('user')} />

      <Typography variant="h3">
        {user ? (
          `${user.firstName} ${user.lastName}`
        ) : (
          <Skeleton variant="text" width="120px" />
        )}
      </Typography>

      <Breadcrumbs sx={{ mt: 3 }}>
        <Link component={NavLink} to="/">
          {t('frontpage')}
        </Link>

        <Link component={NavLink} to="/users">
          {t('users')}
        </Link>

        <Typography>
          {user ? `${user.firstName} ${user.lastName}` : ''}
        </Typography>
      </Breadcrumbs>

      <Divider mt={6} />

      <Box sx={{ width: '100%' }}>
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#dashboard')
                }
                label={t('accountInformation')}
                value={ProfileTab.DASHBOARD as string}
              />

              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#alertmanagement')
                }
                label={t('alertManagement')}
                value={ProfileTab.ALERT_MANAGEMENT as string}
              />
              <Tab
                onClick={() =>
                  window.history.replaceState({}, '', '#requestmanagement')
                }
                label={t('requests')}
                value={ProfileTab.REQUEST_MANAGEMENT as string}
              />
            </TabList>
          </Box>

          <Box mt={6}>
            <TabPanel value={ProfileTab.DASHBOARD as string} sx={{ p: 0 }}>
              {user && (
                <Grid container spacing={6}>
                  <Grid item xs={12} lg={12} xl={12}>
                    <UserCard user={user} />
                    {user?.company && canViewCompany() && (
                      <CompanyCard company={user.company} />
                    )}
                    {canViewProjects() && <ProjectsCard projects={projects} />}
                  </Grid>
                </Grid>
              )}
            </TabPanel>

            <TabPanel value={ProfileTab.ALERT_MANAGEMENT as string}>
              <ProfileAlertManagementTable />
            </TabPanel>

            <TabPanel value={ProfileTab.REQUEST_MANAGEMENT as string}>
              <ProfileRequestManagementTable />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </React.Fragment>
  )
}
