import styled, { Keyframes, keyframes } from 'styled-components'

function blinkingEffect(): Keyframes {
  return keyframes`
      50% {
        opacity: 0;
      }
    `
}

export default styled.div`
  display: flex;
  animation: ${blinkingEffect} 1s linear infinite;
`
