import { grey, blue, purple } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import fi from 'date-fns/locale/fi'
import { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js'
import 'chartjs-adapter-date-fns'
import {
  getDifferenceOfDateRangeInHours,
  humanizeDate,
  humanizeDateRange,
  humanizeTimestamp
} from '../utils/date'
import LoadingState from './LoadingState'
import _ from 'lodash'
import LineChart from './LineChart'
import { useTheme } from '@mui/system'
import {
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateField,
  RuuviTagConditionAggregateQuery,
  RuuviTagConditionAggregateStatistic
} from '../types'
import { useDispatch } from 'react-redux'
import { Chart } from 'chart.js'
import { setDateRange, setTimePeriod } from '../redux/slices/query'
import TimePeriod from '../enums/TimePeriod'
import zoom from 'chartjs-plugin-zoom'

Chart.register(zoom)

export interface RuuviTagConditionAggregateChart {
  conditions: RuuviTagConditionAggregate[]
  query: RuuviTagConditionAggregateQuery
  field: RuuviTagConditionAggregateField
  statistics: RuuviTagConditionAggregateStatistic[]
  options?: ChartOptions
  height?: string
  loading?: boolean
}

export default function RuuviTagConditionAggregateChart({
  conditions,
  query,
  field,
  statistics,
  options,
  height = '320px',
  loading = false
}: RuuviTagConditionAggregateChart) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The difference of date range as hours.
   */
  const differenceOfDateRangeInHours = getDifferenceOfDateRangeInHours(
    query.from,
    query.to
  )

  /**
   * Update the date range.
   */
  function updateDateRange({ chart }: { chart: Chart }): void {
    const { min, max } = chart.scales.x

    dispatch(setTimePeriod(TimePeriod.CUSTOM))
    dispatch(
      setDateRange({
        from: new Date(parseInt(min.toString())),
        to: new Date(parseInt(max.toString()))
      })
    )
  }

  const chartData: ChartData = {
    datasets: makeChartDatasets()
  }

  const defaultChartOptions: ChartOptions = {
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x',
          onZoomComplete: updateDateRange
        }
      },
      title: {
        display: true,
        position: 'bottom',
        padding: {
          top: 24
        },
        text: `${humanizeDateRange(query.from, query.to)}`,
        font: {
          weight: '600',
          size: 14
        },
        color:
          theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 1.0)'
            : 'rgba(255, 255, 255, 1.0)'
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 24,
          boxHeight: 1,
          padding: 24,
          font: {
            weight: '600',
            size: 12
          }
        }
      },
      tooltip: {
        callbacks: {
          // @ts-ignore
          title: (tooltipItems: TooltipItem[]): string => {
            if (tooltipItems.length) {
              return differenceOfDateRangeInHours > 744
                ? humanizeDate(tooltipItems[0].raw.x)
                : humanizeTimestamp(tooltipItems[0].raw.x)
            }

            return ''
          }
        }
      },
      decimation: {
        enabled: false,
        algorithm: 'min-max'
      }
    },
    elements: {
      point: {
        pointStyle: 'point',
        radius: 0
      },
      line: {
        borderWidth: 1
      }
    },
    scales: {
      x: {
        display: true,
        // @ts-ignore
        min: query.from,
        // @ts-ignore
        max: query.to,
        type: 'timeseries',
        time: {
          unit:
            differenceOfDateRangeInHours <= 3
              ? 'minute'
              : differenceOfDateRangeInHours <= 24
              ? 'hour'
              : 'day',
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'd.M'
          }
        },
        adapters: {
          date: {
            locale: fi
          }
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          source: 'auto'
        },
        grid: {
          display: true,
          // @ts-ignore
          color: 'rgba(255, 255, 255, 0.0375)',
          // @ts-ignore
          fontColor: '#fff',
          // @ts-ignore
          drawBorder: false
        },
        color: '#fff'
      },
      y: {
        display: true,
        grid: {
          display: true,
          // @ts-ignore
          color: 'rgba(255, 255, 255, 0.0375)',
          // @ts-ignore
          fontColor: '#fff',
          // @ts-ignore
          drawBorder: false
        }
      }
    }
  }

  options = _.merge(defaultChartOptions, options)

  function makeChartDatasets(): ChartDataset[] {
    const datasets: ChartDataset[] = []

    for (const statistic of statistics) {
      datasets.push(makeChartDataset(field, statistic))
    }

    return datasets
  }

  /**
   * Make the dataset.
   */
  function makeChartDataset(
    field: RuuviTagConditionAggregateField,
    statistic: RuuviTagConditionAggregateStatistic
  ) {
    const label = makeDatasetLabel(field, statistic)
    const color = makeDatasetColor(field, statistic)
    const data = makeDatasetData(field, statistic)

    return {
      label,
      backgroundColor: color,
      borderColor: color,
      tension: 0.4,
      spanGaps: false,
      // @ts-ignore
      data: data
    }
  }

  /**
   * Make the key.
   */
  function makeKey(
    field: RuuviTagConditionAggregateField,
    statistic: RuuviTagConditionAggregateStatistic
  ): keyof RuuviTagConditionAggregate {
    return _.camelCase(
      `${field}_${statistic}`
    ) as keyof RuuviTagConditionAggregate
  }

  /**
   * Make the label for the dataset.
   */
  function makeDatasetLabel(
    field: RuuviTagConditionAggregateField,
    statistic: RuuviTagConditionAggregateStatistic
  ): string {
    return `${t(field)} (${t(statistic)})`
  }

  /**
   * Make the color for the dataset.
   */
  function makeDatasetColor(
    field: RuuviTagConditionAggregateField,
    statistic: RuuviTagConditionAggregateStatistic
  ): any {
    let weight: string
    switch (statistic) {
      case 'min':
        weight = 'A100'
        break
      case 'max':
        weight = 'A700'
        break
      case 'avg':
      default:
        weight = '500'
        break
    }

    // @see https://mui.com/material-ui/customization/color/#color-palette
    switch (field) {
      case 'humidity':
        return (grey as any)[weight]
      case 'pressure':
      case 'rssi':
        return (purple as any)[weight]
      case 'temperature':
      default:
        return (blue as any)[weight]
    }
  }

  /**
   * Make the data for the dataset.
   */
  function makeDatasetData(
    field: RuuviTagConditionAggregateField,
    statistic: RuuviTagConditionAggregateStatistic
  ): any {
    const key = makeKey(field, statistic)

    return conditions.map((condition) => {
      return {
        x: condition.timeBucket,
        y: condition[key]
      }
    })
  }

  return (
    <>
      {loading ? (
        <LoadingState />
      ) : (
        <>
          <LineChart
            data={chartData}
            options={options}
            wrapperHeight={height}
          />
        </>
      )}
    </>
  )
}
