import { useTranslation } from 'react-i18next'
import { RuuviTag, RuuviTagBattery, RuuviTagCondition } from '../types'
import RuuviTagStats from './RuuviTagStats'
import { Stack } from '@mui/material'
import RuuviTagBatteryIndicator from './RuuviTagBatteryIndicator'

interface RuuviTagBatteryVoltageStatsProps {
  ruuviTagBattery: RuuviTagBattery | null
  ruuviTagCondition: RuuviTagCondition | null
  loading?: boolean
}

export default function RuuviTagBatteryVoltageStats({
  ruuviTagBattery,
  ruuviTagCondition,
  loading = false
}: RuuviTagBatteryVoltageStatsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <RuuviTagStats
      title={
        <Stack direction="row" alignItems="center" gap={3}>
          {t('battery')}
          {ruuviTagBattery && ruuviTagCondition && (
            <RuuviTagBatteryIndicator
              battery={ruuviTagBattery}
              condition={ruuviTagCondition}
              rotationAngle={90}
            />
          )}
        </Stack>
      }
      value={ruuviTagBattery?.voltage}
      unit="mV"
      date={ruuviTagBattery?.time}
      isAging={false}
      loading={loading}
    />
  )
}
