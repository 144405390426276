import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Link,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CardHeader,
  Tooltip
} from '@mui/material'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { deleteLift, showLift } from '../redux/slices/lifts'
import { useLifts, useLift } from '../hooks'
import LiftForm, { LiftFormProps } from './LiftForm'
import LiftInput from '../types/LiftInput'
import LoadingState from './LoadingState'
import EmptyState from './EmptyState'
import { Lift, Project } from '../types'
import { ProjectStateType } from '../enums/ProjectStateType'
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridToolbar
} from '@mui/x-data-grid'
import { useState } from 'react'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
export interface LiftTableProps {
  project?: Project
}

export default function LiftTable({ project }: LiftTableProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const lifts: Lift[] = useLifts()

  const lift: Lift | undefined = useLift()

  const isLoading: boolean = useSelector(
    (state: RootState) => state.lifts.loading
  )

  const isShowing: boolean = useSelector((state: RootState) => state.lifts.show)

  const isEmpty: boolean = lifts.length === 0

  const isUsingProjectScope = project !== undefined

  const formProps: LiftFormProps = isUsingProjectScope
    ? {
        value: { project: project?.id },
        hidden: ['project' as keyof LiftInput]
      }
    : {}

  const title = !isShowing
    ? t('lifts')
    : lift === undefined
    ? t('newLift')
    : t('editLift')

  function handleAdd() {
    dispatch(showLift())
  }

  function handleEdit(lift: Lift) {
    dispatch(showLift(lift))
  }

  function handleDelete(lift: Lift) {
    // FIXME: Use MUI dialog.
    // TODO: Warn user if lift has power supplies.
    if (window.confirm(t('confirmDeleteLift'))) {
      dispatch(deleteLift(lift.id))
    }
  }

  /**
   * The grid columns.
   */
  const columns = makeGridColumns()

  /**
   * Make the grid columns.
   */
  function makeGridColumns(): GridColumns<Lift> {
    const columns: GridColumns<Lift> = []

    columns.push({
      field: 'name',
      headerName: t('name'),
      minWidth: 200,
      editable: false
    })

    if (!isUsingProjectScope) {
      columns.push({
        field: 'project.name',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        minWidth: 200,
        renderCell: (params: any) => {
          return (
            <>
              {!isUsingProjectScope && (
                <>
                  {params.row.project ? (
                    <Link
                      component={NavLink}
                      to={`/projects/${params.row.project.id}`}
                    >
                      {params.row.project.name}
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )
        }
      })
    }

    columns.push({
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      align: 'right',
      renderCell: (params) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexGrow={0}
          spacing={2}
        >
          <Tooltip title={t('editLift') as string}>
            <IconButton
              size="small"
              data-testid="edit"
              disabled={project?.state === ProjectStateType.ARCHIVED}
              onClick={() => handleEdit(params.row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('deleteLift') as string}>
            <IconButton
              size="small"
              color="error"
              data-testid="delete"
              onClick={() => handleDelete(params.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    })

    return columns
  }

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          !isShowing && (
            <Button
              variant="outlined"
              color="primary"
              disabled={project?.state === ProjectStateType.ARCHIVED}
              onClick={() => handleAdd()}
              endIcon={<AddIcon />}
            >
              {t('newLift')}
            </Button>
          )
        }
      />
      <CardContent>
        {isLoading ? (
          <LoadingState />
        ) : isShowing ? (
          <LiftForm {...formProps} />
        ) : isEmpty ? (
          <EmptyState title={t('noLifts')} />
        ) : (
          <DataGrid
            rows={lifts}
            columns={columns}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 }
              }
            }}
            sx={{ border: 0 }}
          />
        )}
      </CardContent>
    </Card>
  )
}
