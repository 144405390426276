import { UserRole } from '../enums/UserRole'
import { ProjectMembershipRole } from '../enums/ProjectMembershipRole'
import {
  Company,
  CurrentUser,
  Project,
  ProjectMembership,
  User
} from '../types'

export function userIsSuperadmin(user: User): boolean {
  return user?.role == UserRole.SUPERADMIN
}

export function userIsAdmin(user: User): boolean {
  return user?.role == UserRole.ADMIN
}

export function userIsUser(user: User): boolean {
  return user?.role == UserRole.USER
}

export function userIsManufacturer(user: User): boolean {
  return user?.role == UserRole.MANUFACTURER
}

export function userIsInstaller(user: User): boolean {
  return user?.role == UserRole.INSTALLER
}

export function userIsGuest(user: User): boolean {
  return user?.role == UserRole.GUEST
}

export function userBelongsToCompany(user: User, company: Company): boolean {
  return user?.company?.id === company.id
}

// @ts-ignore
export function userIsOwnerOfProject(user: User, project: Project): boolean {
  return (
    user.projects?.some((membership) => {
      return (
        membership.projectId === project.id &&
        membership.role === ProjectMembershipRole.OWNER
      )
    }) ?? false
  )
}

// @ts-ignore
export function userIsAdminOfProject(user: User, project: Project): boolean {
  return (
    user.projects?.some((membership) => {
      return (
        membership.projectId === project.id &&
        membership.role === ProjectMembershipRole.ADMIN
      )
    }) ?? false
  )
}

// @ts-ignore
export function userIsUserOfProject(user: User, project: Project): boolean {
  return (
    user.projects?.some((membership) => {
      return (
        membership.projectId === project.id &&
        membership.role === ProjectMembershipRole.USER
      )
    }) ?? false
  )
}

export function userIsMemberOfProject(user: User, project: Project): boolean {
  return (
    user.projects?.some((membership: ProjectMembership) => {
      return membership.projectId === project.id
    }) ?? false
  )
}
