import { UserRole } from '../../enums/UserRole'
import { SidebarItemsType } from '../../types/sidebar'
import {
  Add,
  DeviceThermostat,
  PublishedWithChanges
} from '@mui/icons-material'
import {
  Briefcase,
  Layout,
  Users,
  Home,
  Cpu,
  Zap,
  Settings
} from 'react-feather'
import CampaignIcon from '@mui/icons-material/Campaign'
import { SidebarTitle } from '../../enums/SidebarTitle'

const pagesSection = [
  {
    roles: [
      UserRole.SUPERADMIN,
      UserRole.INSTALLER,
      UserRole.ADMIN,
      UserRole.USER,
      UserRole.MANUFACTURER,
      UserRole.GUEST
    ],
    href: '/',
    icon: Home,
    title: SidebarTitle.FRONTPAGE
  },

  {
    roles: [
      UserRole.SUPERADMIN,
      UserRole.INSTALLER,
      UserRole.ADMIN,
      UserRole.USER,
      UserRole.MANUFACTURER,
      UserRole.GUEST
    ],
    href: '/projects',
    icon: Layout,
    title: SidebarTitle.PROJECTS
  },

  {
    roles: [UserRole.SUPERADMIN],
    href: '/powersupplies',
    icon: Zap,
    title: SidebarTitle.POWERSUPPLIES
  },

  {
    roles: [UserRole.SUPERADMIN],
    href: '/ruuvi-tags',
    icon: DeviceThermostat,
    title: SidebarTitle.CONDITION_MONITORING
  },

  {
    roles: [UserRole.SUPERADMIN],
    href: '/devices',
    icon: Cpu,
    title: SidebarTitle.DEVICES
  },
  {
    roles: [UserRole.SUPERADMIN],
    href: '/users',
    icon: Users,
    title: SidebarTitle.USERS
  },
  {
    roles: [UserRole.SUPERADMIN],
    href: '/companies',
    icon: Briefcase,
    title: SidebarTitle.COMPANIES
  },
  {
    roles: [UserRole.SUPERADMIN, UserRole.MANUFACTURER],
    href: '/manufacturing',
    icon: Settings,
    title: SidebarTitle.MANUFACTURING
  },
  {
    roles: [
      UserRole.SUPERADMIN,
      UserRole.INSTALLER,
      UserRole.ADMIN,
      UserRole.USER
    ],
    href: '/installation',
    icon: Add,
    title: SidebarTitle.ADD_NEW_DEVICE
  },
  {
    roles: [
      UserRole.SUPERADMIN,
      UserRole.ADMIN,
      UserRole.USER,
      UserRole.INSTALLER
    ],
    href: '/devices/replace',
    icon: PublishedWithChanges,
    title: SidebarTitle.REPLACE_DEVICE
  },
  {
    roles: [
      UserRole.SUPERADMIN,
      UserRole.ADMIN,
      UserRole.USER,
      UserRole.INSTALLER
    ],
    href: '/ruuvi-tags/install',
    icon: Add,
    title: SidebarTitle.ADD_NEW_RUUVI_TAG
  },
  {
    roles: [UserRole.SUPERADMIN],
    href: '/announcements',
    icon: CampaignIcon,
    title: SidebarTitle.ANNOUNCEMENT_MANAGEMENT
  }
] as SidebarItemsType[]

const navItems = [
  {
    title: 'Pages',
    pages: pagesSection
  }
]

export default navItems
