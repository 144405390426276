import React from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import authService from '../../services/authService'
import { Link, useNavigate } from 'react-router-dom'

import { Button, TextField as MuiTextField } from '@mui/material'
import { spacing } from '@mui/system'
import useAuth from '../../hooks/useAuth'
import { useDispatch } from 'react-redux'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useTranslation } from 'react-i18next'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { createUserNotification } from '../../utils/createUserNotification'
import { AuthErrors } from '../../enums/AuthErrors'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

// eslint-disable-next-line
function ChangePassword() {
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        oldPassword: Yup.string()
          .min(8, 'Must be at least 8 characters')
          .max(255)
          .required('Required'),
        newPassword: Yup.string()
          .min(8, 'Must be at least 8 characters')
          .max(255)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            t('strongPassword')
          )
          .required('Required'),
        confirmNewPassword: Yup.string().when('newPassword', {
          // eslint-disable-next-line
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('newPassword')],
            'Both password need to be the same'
          )
        })
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await authService.changePassword(currentUser?.id, {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
          })

          dispatch(
            createErrorOrSuccessNotification(
              NotificationType.SUCCESS,
              t('passwordChangeSuccess')
            )
          )
          // eslint-disable-next-line
          navigate('/')
        } catch (error: any) {
          dispatch(
            setError({
              type: AuthErrors.CHANGE_PASSWORD,
              error: error
            })
          )

          //Create message
          const errorMessage = createUserNotification({
            user: currentUser,
            type: AuthErrors.CHANGE_PASSWORD,
            error: error
          })

          //Dispatch error message
          dispatch<unknown>(
            createErrorOrSuccessNotification(
              NotificationType.WARNING,
              t(errorMessage.key) + t(errorMessage.message)
            )
          )
          //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
          dispatch(deleteError(AuthErrors.CHANGE_PASSWORD))

          setStatus({ success: false })
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="password"
            name="oldPassword"
            label={t('changePassword.old')}
            value={values.oldPassword}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            fullWidth
            helperText={touched.oldPassword && errors.oldPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="newPassword"
            label={t('changePassword.new')}
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="password"
            name="confirmNewPassword"
            label={t('changePassword.confirm')}
            value={values.confirmNewPassword}
            error={Boolean(
              touched.confirmNewPassword && errors.confirmNewPassword
            )}
            fullWidth
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('changePassword.change')}
          </Button>

          <Button
            component={Link}
            to={`/users/${currentUser?.id}`}
            fullWidth
            color="primary"
          >
            {t('changePassword.return')}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default ChangePassword
