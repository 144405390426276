import sectorService from '../../services/sectorService'
import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import { createNotification } from './notifications'
import { Sector } from '../../types'

interface SectorsState {
  sectors: Sector[]
  sector: Sector | undefined
  show: boolean
  loading: boolean
}

const initialState: SectorsState = {
  sectors: [],
  sector: undefined,
  show: false,
  loading: false
}

const sectorSlice = createSlice({
  name: 'sectors',
  initialState,
  reducers: {
    setSectors(state, action) {
      state.sectors = action.payload
    },
    setSector(state, action) {
      state.sector = action.payload
    },
    createSector(state, action) {
      state.sectors = [...state.sectors, action.payload].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },
    updateSector(state, action) {
      state.sectors = state.sectors
        .map((sector) =>
          sector.id === action.payload.id ? action.payload : sector
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    deleteSector(state, action) {
      state.sectors = state.sectors.filter(
        (sector) => sector.id !== action.payload
      )
    },
    showSector(state, action) {
      state.sector = action.payload
      state.show = true
    },
    hideSector(state) {
      state.show = false
      state.sector = undefined
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const { createSector, updateSector, showSector, hideSector } =
  sectorSlice.actions

export function getSectors() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(sectorSlice.actions.setLoading(true))
      const sectors = await sectorService.getSectors()
      dispatch(sectorSlice.actions.setSectors(sectors))
    } finally {
      dispatch(sectorSlice.actions.setLoading(false))
    }
  }
}

export function getSectorsByProjectId(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(sectorSlice.actions.setLoading(true))
      const sectors = await sectorService.getSectorsByProjectId(id)
      dispatch(sectorSlice.actions.setSectors(sectors))
    } finally {
      dispatch(sectorSlice.actions.setLoading(false))
    }
  }
}

export function getSector(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(sectorSlice.actions.setLoading(true))
      const sector = await sectorService.getSector(id)
      dispatch(sectorSlice.actions.setSector(sector))
    } finally {
      dispatch(sectorSlice.actions.setLoading(false))
    }
  }
}

export function deleteSector(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      await sectorService.deleteSector(id)
      dispatch(sectorSlice.actions.deleteSector(id))

      // FIXME: Translate notification message.
      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Sector deleted successfully',
          timeout: 5000
        })
      )
    } catch (error: any) {
      // FIXME: Translate notification message.
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong',
          timeout: 0
        })
      )
    }
  }
}

export default sectorSlice.reducer
