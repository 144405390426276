import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { RuuviTagConditionStatistic } from '../types'

export interface RuuviTagConditionStatisticToggleProps {
  value: RuuviTagConditionStatistic[]
  onChange(value: RuuviTagConditionStatistic[]): void
}

export default function RuuviTagConditionStatisticToggle({
  value,
  onChange
}: RuuviTagConditionStatisticToggleProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  function handleChange(
    event: MouseEvent<HTMLElement>,
    newValue: RuuviTagConditionStatistic[]
  ): void {
    onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value="min">{t('lowest')}</ToggleButton>
      <ToggleButton value="avg">{t('average')}</ToggleButton>
      <ToggleButton value="max">{t('highest')}</ToggleButton>
    </ToggleButtonGroup>
  )
}
