import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@mui/material'
import { NotificationsActive, NotificationsNone } from '@mui/icons-material'
import { isDeviceAlerting } from '../utils/alert'
import { Device, Sensor } from '../types'
import { AlertGroup } from '../types/Alerts'

interface DeviceAlertIndicatorProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The sensor.
   */
  sensor: Sensor

  /**
   * The alert groups.
   */
  alertGroups: AlertGroup[]

  /**
   * The font size of the icon.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
}

export default function DeviceAlertIndicator({
  device,
  sensor,
  alertGroups,
  fontSize = 'inherit'
}: DeviceAlertIndicatorProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The alert rule type.
   */
  const alertRuleType = isDeviceAlerting(sensor, alertGroups)

  return (
    <>
      {alertRuleType ? (
        <Tooltip title={t(alertRuleType) as string}>
          <IconButton
            component={NavLink}
            to={`/devices/${device.name}/alert-management`}
          >
            <NotificationsActive fontSize={fontSize} color="error" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('noAlerts') as string}>
          <NotificationsNone fontSize={fontSize} color="disabled" />
        </Tooltip>
      )}
    </>
  )
}
