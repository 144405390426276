import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
  Link,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button as MuiButton,
  CircularProgress,
  Select as MuiSelect,
  MenuItem
} from '@mui/material'
import { spacing } from '@mui/system'
import { Add } from '@mui/icons-material'
import { getUser, getUsers, setShow, setUser } from '../../redux/slices/users'
import { RootState } from '../../redux/store'
import useAuth from '../../hooks/useAuth'
import {
  getProjects,
  getProjectsByCompanyId
} from '../../redux/slices/projects'
import { useTranslation } from 'react-i18next'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import { Project } from '../../types'
import { createProjectMembership } from '../../services/projectMembershipService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { MembershipErrors } from '../../enums/MembershipErrors'
import { createUserNotification } from '../../utils/createUserNotification'

interface User {
  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  role: string
  company: Company
  language: string
  activeProject: Record<string, never>
  profile: Profile
}

interface Company {
  id: string | number
  name: string
  city: string
}

interface Profile {
  id: string | number
  notificationsByEmail: boolean
  notificationsBySms: boolean
}
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const Card = styled(MuiCard)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

const Paper = styled(MuiPaper)(spacing)

const Button = styled(MuiButton)(spacing)

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 500px;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`

const initialValues = {
  projectId: '',
  memberId: '',
  role: 'User'
}

// eslint-disable-next-line
function Form() {
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const { user, users } = useSelector((state: RootState) => state.users)
  const projectSelect = useSelector(
    (state: RootState) => state.projects.projects
  )
  const [t] = useTranslation('common')

  useEffect(() => {
    if (currentUser) {
      dispatch(getProjects())
    }
  }, [currentUser])

  useEffect(() => {
    return function cleanup() {
      dispatch(setShow(false))
    }
  }, [dispatch])

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      await createProjectMembership(values)
      dispatch(
        createErrorOrSuccessNotification(NotificationType.SUCCESS, t('Success'))
      )
      //Todo. notifications
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
      dispatch(setShow(false))
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: MembershipErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={
        user === undefined
          ? initialValues
          : {
              projectId: '',
              memberId: user.id,
              role: ''
            }
      }
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl>
                    <TextField
                      name="projectId"
                      label={t('Users.project')}
                      select
                      value={values.projectId}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    >
                      {
                        // eslint-disable-next-line
                        projectSelect.map((project: Project) => (
                          // currentUser?.company.projects.map((s: Project) => (
                          <MenuItem key={project.id} value={project.id}>
                            {project.name}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl>
                    <TextField
                      name="memberId"
                      label={t('Users.users')}
                      select
                      value={values.memberId}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    >
                      {
                        // eslint-disable-next-line
                        users.map((s: any) => (
                          // currentUser?.company.projects.map((s: Project) => (
                          <MenuItem key={s.id} value={s.id}>
                            {s.firstName} {s.lastName}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl>
                    <TextField
                      name="role"
                      label={t('Users.role')}
                      select
                      value={values.role}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    >
                      <MenuItem value={'Admin'}>Admin</MenuItem>
                      <MenuItem value={'Owner'}>Owner</MenuItem>
                      <MenuItem value={'User'}>User</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  {t('Users.save')}
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

// eslint-disable-next-line
function SimpleTableDemo(props: any) {
  const dispatch = useDispatch()
  const { users, user, show } = props
  const [t] = useTranslation('common')

  const handleShow = (payload: boolean) => {
    dispatch(setShow(payload))

    if (user !== undefined) {
      dispatch(setUser(undefined))
    }
  }

  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6" gutterBottom>
          {t('Users.users')}
        </Typography>
        <Button
          mr={2}
          variant="contained"
          color="primary"
          onClick={() => (show ? handleShow(false) : handleShow(true))}
        >
          {show ? t('Users.cancel') : t('Users.add')}
          {!show && <Add />}
        </Button>
      </CardContent>
      <Paper>
        {show ? (
          <Form />
        ) : (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Users.name')}</TableCell>
                  <TableCell align="right">{t('Users.email')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  // eslint-disable-next-line
                  users.map((row: User) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.firstName + ' ' + row.lastName}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>

                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableWrapper>
        )}
      </Paper>
    </Card>
  )
}

/**
 * @deprecated
 */
export default function Memberships() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const { id } = useParams()
  const { users, user, loading, show } = useSelector(
    (state: RootState) => state.users
  )

  useEffect(() => {
    if (currentUser) {
      dispatch(getUsers())
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh'
              }}
            >
              <CircularProgress size="5vh" />
            </Box>
          ) : (
            <SimpleTableDemo users={users} show={show} user={user} />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
