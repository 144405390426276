import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'

import authService from '../../services/authService'

import { Button, TextField as MuiTextField } from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useDispatch } from 'react-redux'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

// eslint-disable-next-line
function NewPassword() {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const url = window.location.pathname
  const token = url.substring(url.lastIndexOf('/') + 1)
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        submit: false
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, 'Must be at least 8 characters')
          .max(255)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            t('strongPassword')
          )
          .required('Required'),
        confirmPassword: Yup.string().when('password', {
          // eslint-disable-next-line
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            'Both password need to be the same'
          )
        })
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await authService.resetPassword(
            { newPassword: values.password },
            token
          )
          dispatch(
            createErrorOrSuccessNotification(
              NotificationType.SUCCESS,
              t('newPasswordSuccess')
            )
          )

          setTimeout(() => navigate('/auth/sign-in'), 5000)

          // eslint-disable-next-line
        } catch (error: any) {
          dispatch(
            setError({
              type: AuthErrors.NEW_PASSWORD,
              error: error
            })
          )

          //Create message
          const errorMessage = createUserNotification({
            user: null,
            type: AuthErrors.NEW_PASSWORD,
            error: error
          })

          //Dispatch error message
          dispatch<unknown>(
            createErrorOrSuccessNotification(
              NotificationType.WARNING,
              t(errorMessage.key) + t(errorMessage.message)
            )
          )
          //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
          dispatch(deleteError(AuthErrors.NEW_PASSWORD))
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            name="password"
            label={t('newPassword.new')}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            variant="outlined"
            my={2}
          />

          <TextField
            name="confirmPassword"
            label={t('newPassword.confirm')}
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            variant="outlined"
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t('newPassword.change')}
          </Button>
          <Button component={Link} to="/auth/sign-in" fullWidth color="primary">
            {t('newPassword.return')}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default NewPassword
