import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/styles'
import { spacing } from '@mui/system'
import useAppSettings from '../hooks/useAppSettings'
import {
  SensorAggregateInterval,
  CalculationMode,
  StartDateMode
} from '../enums'
import DateInterval from '../enums/DateInterval'
import EmissionFactorMode from '../enums/EmissionFactorMode'
import EmissionFactorAggregateInterval from '../enums/EmissionFactorAggregateInterval'
import {
  ActiveProject,
  DateRange,
  Device,
  Emission,
  EmissionFactorAggregate,
  EmissionQuery,
  EmissionSettings,
  GeoCoordinate,
  Project,
  SensorAggregate
} from '../types'
import { getEmissionFactorAggregates } from '../services/emissionFactorAggregateService'
import { calculateEmission } from '../utils/emission'
import { getDeviceGeoCoordinate } from '../utils/device'
import { getStartDateByMode } from '../utils/date'
import { getSensorAggregates } from '../services/sensorAggregateService'
import { transformSensorAggregatesToMap } from '../utils/sensorAggregate'
import EmissionStats from './EmissionStats'
import { transformEmissionFactorAggregatesToMap } from '../utils/emissionAggregate'
import { getEnergyEmissions } from '../services/deviceService'

const TextField = styled(MuiTextField)(spacing)

interface DeviceEmissionStatsProps {
  device: Device
  project?: Project | ActiveProject
  dateRange: DateRange
  initialSettings?: EmissionSettings
  loading?: boolean
}

export default function DeviceEmissionStats({
  device,
  project,
  dateRange,
  initialSettings,
  loading = false
}: DeviceEmissionStatsProps) {
  const [t] = useTranslation('common')

  const appSettings = useAppSettings()

  const [settings, setSettings] = useState<EmissionSettings>({
    calculationMode: getInitialCalculationMode(),
    startDateMode: getInitialStartDateMode(),
    emissionFactorMode: getInitialEmissionFactorMode(),
    emissionFactorValue: getInitialEmissionFactorValue(),
    ...initialSettings
  })

  /**
   * The emissions of the date range.
   */
  const [emissions, setEmissions] = useState<Emission[]>([])

  /**
   * The emissions before the date range.
   */
  const [emissionsBeforeDateRange, setEmissionsBeforeDateRange] = useState<
    Emission[]
  >([])

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  const query: EmissionQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query since the before the date range.
   */
  const queryBeforeDateRange: EmissionQuery = {
    from: getStartDateByMode(dateRange.from, settings.startDateMode, project),
    to: dateRange.from
  }

  /**
   * The project area as square meters.
   */
  const area: number | undefined = project?.area

  /**
   * Indicates if fixed emission factor is used.
   */
  const isUsingFixedEmissionFactor: boolean =
    settings.emissionFactorMode === EmissionFactorMode.FIXED

  /**
   * Indicates if realtime emisison factor is used.
   */
  const isUsingRealtimeEmissionFactor: boolean =
    settings.emissionFactorMode === EmissionFactorMode.REALTIME

  /**
   * Indicates if the selected date range is used.
   */
  const isUsingSelectedDateRange =
    settings.startDateMode === StartDateMode.SELECTED_DATE

  const startDateModeOptions = project
    ? [
        StartDateMode.START_OF_PROJECT,
        StartDateMode.START_OF_MONTH,
        StartDateMode.SELECTED_DATE
      ]
    : [StartDateMode.START_OF_MONTH, StartDateMode.SELECTED_DATE]

  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (isUsingSelectedDateRange) {
        await loadEmissions(signal)
        setEmissionsBeforeDateRange([])
      } else {
        await Promise.all([
          loadEmissions(signal),
          loadEmissionsBeforeDateRange(signal)
        ])
      }
    } finally {
      setIsBooting(false)
    }
  }

  async function loadEmissions(signal?: AbortSignal): Promise<void> {
    setEmissions(
      await getEnergyEmissions(
        device.name,
        {
          from: query.from,
          to: query.to
        },
        {
          signal
        }
      )
    )
  }

  async function loadEmissionsBeforeDateRange(
    signal?: AbortSignal
  ): Promise<void> {
    setEmissionsBeforeDateRange(
      await getEnergyEmissions(
        device.name,
        {
          from: queryBeforeDateRange.from,
          to: queryBeforeDateRange.to
        },
        {
          signal
        }
      )
    )
  }

  function getInitialEmissionFactorMode(): EmissionFactorMode {
    return project?.fixedEmissionFactor
      ? EmissionFactorMode.FIXED
      : EmissionFactorMode.REALTIME
  }

  function getInitialEmissionFactorValue(): number | null {
    return project?.fixedEmissionFactor ?? null
  }

  function getInitialCalculationMode(): number {
    return (
      appSettings.deviceStatsSettings?.emissionCalculationMode ??
      CalculationMode.CUMULATIVE
    )
  }

  function getInitialStartDateMode(): StartDateMode {
    if (
      appSettings.deviceStatsSettings?.emissionStartDateMode &&
      appSettings.deviceStatsSettings?.emissionStartDateMode ===
        StartDateMode.START_OF_PROJECT &&
      project === undefined
    ) {
      return StartDateMode.SELECTED_DATE
    } else
      return (
        appSettings.deviceStatsSettings?.emissionStartDateMode ??
        StartDateMode.SELECTED_DATE
      )
  }

  function changeCalculationMode(calculationMode: CalculationMode): void {
    const appSettings = useAppSettings()
    const deviceStatsSettings = appSettings.deviceStatsSettings
    setSettings({
      ...settings,
      calculationMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        deviceStatsSettings: {
          ...deviceStatsSettings,
          emissionCalculationMode: calculationMode
        }
      })
    )
  }

  function changeStartDateMode(startDateMode: StartDateMode): void {
    const appSettings = useAppSettings()
    const deviceStatsSettings = appSettings.deviceStatsSettings
    setSettings({
      ...settings,
      startDateMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        deviceStatsSettings: {
          ...deviceStatsSettings,
          emissionStartDateMode: startDateMode
        }
      })
    )
  }

  function changeEmissionFactorMode(
    emissionFactorMode: EmissionFactorMode
  ): void {
    setSettings({
      ...settings,
      emissionFactorMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        emissionFactorMode: emissionFactorMode as string
      })
    )
  }

  function changeEmissionFactorValue(value: number) {
    setSettings({
      ...settings,
      emissionFactorValue: value
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        emissionFactorValue: value
      })
    )
  }

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [device, query.from, query.to, settings.startDateMode])

  return (
    <EmissionStats
      emissions={emissions}
      emissionsBeforeDateRange={emissionsBeforeDateRange}
      query={query}
      queryBeforeDateRange={queryBeforeDateRange}
      settings={settings}
      area={area}
      loading={loading || isBooting}
      startDateModeOptions={startDateModeOptions}
      onChangeCalculationMode={changeCalculationMode}
      onChangeStartDateMode={changeStartDateMode}
      onChangeEmissionFactorMode={changeEmissionFactorMode}
      onChangeEmissionFactorValue={changeEmissionFactorValue}
    />
  )
}
