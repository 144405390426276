import { useTranslation } from 'react-i18next'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/system'
import styled, { keyframes } from 'styled-components/macro'
import { Device, Sensor } from '../types'

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `
}
const BlinkingComponent = styled.div`
  animation: ${blinkingEffect} 1s linear infinite;
`

interface VoltageBoxProps {
  /**
   * The label
   */
  label: string

  /**
   * The voltage.
   */
  voltage: number
}

function VoltageBox({ label, voltage }: VoltageBoxProps): JSX.Element {
  /**
   * The theme.
   */
  const theme = useTheme()

  return (
    <Box>
      <Stack direction="row" gap={1}>
        <Typography variant="h4">{label}:</Typography>

        {voltage === 0 ? (
          <Typography variant="h4" color={theme.palette.text.secondary}>
            0V
          </Typography>
        ) : voltage < 220 || voltage > 240 ? (
          <BlinkingComponent>
            <Typography variant="h4" color="error">
              {Math.round(voltage)}V
            </Typography>
          </BlinkingComponent>
        ) : (
          <Typography variant="h4" color="success">
            {Math.round(voltage)}V
          </Typography>
        )}
      </Stack>
    </Box>
  )
}

export interface DeviceVoltageBarProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The sensor.
   */
  sensor?: Sensor
}

export default function DeviceVoltageBar({
  device,
  sensor
}: DeviceVoltageBarProps): JSX.Element {
  const [t] = useTranslation('common')

  // TODO: Load the sensor when not provided.

  return (
    <Box>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <VoltageBox
            label={t('Device.phase1')}
            voltage={sensor?.voltage_1 ?? 0}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <VoltageBox
            label={t('Device.phase2')}
            voltage={sensor?.voltage_2 ?? 0}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <VoltageBox
            label={t('Device.phase3')}
            voltage={sensor?.voltage_3 ?? 0}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
