import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { isTemporaryToken, isValidToken } from '../../utils/jwt'

interface AuthGuardType {
  children: React.ReactNode
  roles: string[]
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, roles }: AuthGuardType) {
  const { isAuthenticated, isInitialized, currentUser } = useAuth()
  const location = useLocation()
  const refreshToken = window.localStorage.getItem('refreshToken')
  const accessToken = window.localStorage.getItem('accessToken')

  let validToken
  let temporaryToken

  if (refreshToken) {
    validToken = isValidToken(refreshToken)
  }
  if (accessToken) {
    temporaryToken = isTemporaryToken(accessToken)
  }

  if ((isInitialized && !isAuthenticated) || !validToken || temporaryToken) {
    const hash = window.location.hash
    const redirect = encodeURI(location.pathname + hash)
    return <Navigate to={`/auth/sign-in?redirect=${redirect}`} />
  }

  if (currentUser && roles && roles.indexOf(currentUser?.role) === -1) {
    return <Navigate to="/auth/404" />
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default AuthGuard
