import { Alert, Grid, Card as MuiCard } from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import Map, { Layer, LayerProps, Marker, Popup, Source } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Device, Project } from '../types'
import CellTowerIcon from '@mui/icons-material/CellTower'
import { calculateDistanceBetweenCoordinates } from '../utils/calculateDistanceBetweenCoordinates'
import { useState } from 'react'
import type { FeatureCollection } from 'geojson'
import { useTranslation } from 'react-i18next'

const Card = styled(MuiCard)(spacing)

export interface CompaniesMapProps {
  device: Device
  project?: Project | null
}

export default function DeviceMap({ device, project }: CompaniesMapProps) {
  const [t] = useTranslation('common')
  const accuracy =
    device.lastLocation && device.lastLocation.accuracy
      ? device.lastLocation.accuracy
      : 1000

  const distance =
    calculateDistanceBetweenCoordinates(
      parseFloat(device.lastLocation?.lat as string) ?? 0,
      parseFloat(device.lastLocation?.lng as string) ?? 0,
      (project?.lat as number) ?? 0,
      (project?.long as number) ?? 0
    ) * 1000

  const metersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
    meters / 0.075 / Math.cos((latitude * Math.PI) / 180)

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: null,
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(device.lastLocation?.lng as string) ?? 0,
            parseFloat(device.lastLocation?.lat as string) ?? 0
          ]
        }
      }
    ]
  }

  const layerStyle: LayerProps = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [
            20,
            metersToPixelsAtMaxZoom(
              device.lastLocation?.accuracy ?? 1000,
              (device.lastLocation?.lat as number) ?? 0
            )
          ]
        ],
        // @ts-ignore
        base: 2
      },
      'circle-color': distance > accuracy ? 'red' : 'green',
      'circle-opacity': 0.2
    }
  }
  const [showPopup, setShowPopup] = useState<boolean>(true)
  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY
  return (
    <Card>
      {project && distance > accuracy && (
        <Alert severity="warning">{t('deviceOutOfRange')}</Alert>
      )}

      {project &&
      device.lastLocation &&
      device.lastLocation.lat &&
      device.lastLocation.lng ? (
        <Map
          mapboxAccessToken={apiKey}
          initialViewState={{
            latitude: device.lastLocation?.lat as number,
            longitude: device.lastLocation?.lng as number,
            zoom: 11
          }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          style={{ width: '100%', height: 560 }}
        >
          <Source id="my-data" type="geojson" data={geojson}>
            <Layer {...layerStyle} />
          </Source>
          <Marker
            longitude={device.lastLocation.lng as number}
            latitude={device.lastLocation.lat as number}
          >
            <CellTowerIcon sx={{ color: 'black' }} />
          </Marker>
          <Marker
            onClick={() => setShowPopup(true)}
            longitude={project.long as number}
            latitude={project.lat as number}
          ></Marker>
          {showPopup && (
            <Popup
              closeButton={true}
              focusAfterOpen={false}
              style={{ color: 'black' }}
              longitude={project.long as number}
              latitude={project.lat as number}
              anchor="bottom"
              closeOnClick={false}
              onClose={() => setShowPopup(false)}
            >
              {project.name} <br />
              {project.address}
            </Popup>
          )}
        </Map>
      ) : (
        <Alert severity="warning">{t('deviceMapError')}</Alert>
      )}
    </Card>
  )
}
