// @ts-nocheck
import { useEffect } from 'react'

const initializeHotjar = (id, sv, debug = false) => {
  ;(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(...args)
      }

    h._hjSettings = { hjid: id, hjsv: sv, hjDebug: debug }
    h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.async = 1
      r.src = h._scriptPath
      a.appendChild(r)
    }
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

class Hotjar {
  public initialized(): boolean {
    return typeof window !== 'undefined' && typeof window?.hj === 'function'
  }

  public identify(userId: any, properties: any): void {
    this.hj('identify', userId, properties)
  }

  public event(event: any): void {
    this.hj('event', event)
  }

  public stateChange(relativePath: any): void {
    this.hj('stateChange', relativePath)
  }

  private hj(): void {
    if (!window.hj) {
      throw new Error('Hotjar is not initialized')
    }

    window.hj(...params)
  }
}

export default function useHotjar(): Hotjar {
  const hotjar = new Hotjar()

  useEffect(() => {
    if (!hotjar.initialized()) {
      initializeHotjar(
        process.env.REACT_APP_HOTJAR_ID,
        process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
      )
    }
  }, [])

  return hotjar
}
