enum ConfluencePages {
  COMPANIES = '43712525',
  DASHBOARD = '43319347',
  DEVICES = '43614211',
  MANUFACTURING = '43581444',
  PROJECTS = '43286580',
  PRIVACY_POLICY = '47939613',
  RUUVI_TAGS = '43122744',
  USERS = '43614229',
  USER_MANUAL = '294913',
  TERMS_OF_SERVICE = '45711364'
}

export default ConfluencePages
