import { Warehouse } from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getWarehouses(): Promise<Warehouse[]> {
  const response = await api.get('/warehouses')

  return response.data
}

export default {
  getWarehouses
}
