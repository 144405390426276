import JiraProject from '../enums/JiraProject'
import { useApi } from '../hooks'
import { BugInput } from '../types/BugInput'
import IssueInput from '../types/IssueInput'

const axios = useApi('v2')

export async function sendBug(input: BugInput, project: JiraProject) {
  const response = await axios.post(`/jira/${project}/bugs`, input)
  return response.data
}

export async function sendIssue(input: IssueInput, project: JiraProject) {
  const response = await axios.post(`/jira/${project}/issue`, input)
  return response.data
}
