import { Emission } from '../types'

export function calculateEmission(kwh: number, gco2PerKwh: number): number {
  return kwh * gco2PerKwh
}

export function mapEmissionsByDate(
  emissions: Emission[]
): Map<string, Emission> {
  const emissionsByDate = new Map<string, Emission>()

  for (const emission of emissions) {
    emissionsByDate.set(emission.time, emission)
  }

  return emissionsByDate
}
