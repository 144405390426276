import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import fi from 'date-fns/locale/fi'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Paper,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import {
  DateRange as DateRangeIcon,
  FastRewind as FastRewindIcon,
  FastForward as FastForwardIcon
} from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TimePeriod from '../enums/TimePeriod'
import { useEffect } from 'react'
import {
  isBefore,
  subDays,
  subHours,
  startOfDay,
  subMinutes,
  addMinutes,
  differenceInMinutes,
  isAfter,
  startOfMinute,
  startOfWeek,
  endOfWeek,
  endOfDay,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
  addDays,
  addWeeks,
  addMonths,
  endOfMinute
} from 'date-fns'
import DateRange from '../types/DateRange'
import { toNearestMinutesInterval, toNearestHoursInterval } from '../utils/date'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import {
  getInitialTimePeriod,
  setDateRange,
  setTimePeriod
} from '../redux/slices/query'
import { useTheme } from '@mui/system'
import { is } from 'date-fns/locale'

/**
 * A customized toggle button that allows itself to wrapped inside a tooltip.
 *
 * @see https://github.com/mui/material-ui/issues/18091
 */
const ToggleButtonInsideTooltip = forwardRef((props: ToggleButtonProps) => {
  return (
    <>
      {/* @ts-ignore */}
      <ToggleButton ref={props?.innerRef} {...props} />
    </>
  )
})

interface DateRangeSelectProps {
  value: DateRange
  includeOneHourOption?: boolean
  includeThreeDaysOption?: boolean
  marginTop?: number | string
  size?: 'small' | 'medium' | 'large' | undefined
  onChange?: {
    (value: DateRange): void
  }
}

export default function DateRangeSelect({
  value,
  includeOneHourOption = false,
  includeThreeDaysOption = false,
  marginTop = '0',
  size,
  onChange
}: DateRangeSelectProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The time period.
   */
  const { timePeriod } = useSelector((state: RootState) => ({
    timePeriod: state.query.timePeriod
  }))

  /**
   * The custom date range.
   */
  const [customDateRange, setCustomDateRange] = useState<DateRange>({
    from: value.from,
    to: value.to
  })

  /**
   * The difference of date range in minutes.
   */
  const differenceOfDateRangeInMinutes = Math.abs(
    differenceInMinutes(value.from, value.to)
  )

  /**
   * The achor element for the menu.
   */
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  /**
   * Indicates if the menu is open.
   */
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  /**
   * Indicates if we are on mobile.
   */
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  /**
   * Indicates if the time period is absolute.
   */
  const isAbsoluteTimePeriod =
    timePeriod === TimePeriod.DAY ||
    timePeriod === TimePeriod.WEEK ||
    timePeriod === TimePeriod.MONTH

  // Runs when the component mounts.
  useEffect(() => {
    dispatch(getInitialTimePeriod())
  }, [])

  // Runs when the value changes.
  useEffect(() => {
    setCustomDateRange({
      from: value.from,
      to: value.to
    })
  }, [value])

  /**
   * Open the menu.
   */
  function openMenu(): void {
    setMenuIsOpen(true)
  }

  /**>
   * Close the menu.
   */
  function closeMenu(): void {
    setMenuIsOpen(false)
  }

  /**
   * Handle the change event for selected time period.
   */
  function handleSelectedTimePeriodChange(
    event: React.MouseEvent<HTMLElement>,
    selectedTimePeriod: TimePeriod
  ): void {
    const now = startOfMinute(new Date())

    if (selectedTimePeriod !== TimePeriod.CUSTOM || !isAbsoluteTimePeriod) {
      dispatch(setTimePeriod(selectedTimePeriod))
    }

    switch (selectedTimePeriod) {
      case TimePeriod.ONE_HOUR:
        dispatch(
          setDateRange({
            from: subHours(now, 1),
            to: now
          })
        )

        break

      case TimePeriod.TWENTYFOUR_HOURS:
        dispatch(
          setDateRange({
            from: subHours(now, 24),
            to: now
          })
        )

        break

      case TimePeriod.THREE_DAYS:
        dispatch(
          setDateRange({
            from: subDays(now, 3),
            to: now
          })
        )

        break

      case TimePeriod.SEVEN_DAYS:
        dispatch(
          setDateRange({
            from: subDays(now, 7),
            to: now
          })
        )

        break

      case TimePeriod.THIRTY_DAYS:
        dispatch(
          setDateRange({
            from: subDays(now, 30),
            to: now
          })
        )

        break

      case TimePeriod.CUSTOM:
        openMenu()
        break
    }
  }

  /**
   * Handle the change event for absolute time period.
   */
  function handleAbsoluteTimePeriodChange(
    event: React.MouseEvent<HTMLElement>,
    selectedTimePeriod: TimePeriod
  ): void {
    const now = new Date()

    dispatch(setTimePeriod(selectedTimePeriod))

    switch (selectedTimePeriod) {
      case TimePeriod.DAY:
        dispatch(
          setDateRange({
            from: startOfDay(now),
            to: endOfDay(now)
          })
        )
        // setCustomDateRange({
        //   from: startOfDay(now),
        //   to: endOfDay(now)
        // })
        break

      case TimePeriod.WEEK:
        dispatch(
          setDateRange({
            from: startOfWeek(now, { locale: fi }),
            to: endOfWeek(now, { locale: fi })
          })
        )
        // setCustomDateRange({
        //   from: startOfWeek(now, { locale: fi }),
        //   to: endOfWeek(now, { locale: fi })
        // })
        break

      case TimePeriod.MONTH:
        dispatch(
          setDateRange({
            from: startOfMonth(now),
            to: endOfMonth(now)
          })
        )
        // setCustomDateRange({
        //   from: startOfMonth(now),
        //   to: endOfMonth(now)
        // })
        break
    }

    // Finally we can close the menu.
    closeMenu()
  }

  /**
   * Change the start date of the custom date range.
   */
  function handleCustomDateRangeFromChange(from: Date | null): void {
    if (from !== null && isBefore(from, customDateRange.to)) {
      setTimePeriod(TimePeriod.CUSTOM)
      setCustomDateRange({
        ...customDateRange,
        from
      })
    }
  }

  /**
   * Change the end date of the custom date range.
   */
  function handleCustomDateRangeToChange(to: Date | null): void {
    if (to !== null && isAfter(to, customDateRange.from)) {
      setTimePeriod(TimePeriod.CUSTOM)
      setCustomDateRange({
        ...customDateRange,
        to
      })
    }
  }

  /**
   * Handle the open menu action.
   */
  function handleOpenMenu(event: React.MouseEvent<HTMLButtonElement>): void {
    setMenuAnchor(event.currentTarget)

    if (!isAbsoluteTimePeriod) {
      dispatch(setTimePeriod(TimePeriod.CUSTOM))
    }

    openMenu()
  }

  /**
   * Handle the cancel action.
   */
  function handleCancel(): void {
    closeMenu()
  }

  /**
   * Handle the confirm action.
   */
  function handleConfirm(): void {
    if (onChange !== undefined) {
      onChange(customDateRange)
    }

    if (!isAbsoluteTimePeriod) {
      dispatch(setTimePeriod(TimePeriod.CUSTOM))
    }

    dispatch(setDateRange(customDateRange))

    closeMenu()
  }

  /**
   * Go backwards in time.
   */
  function goBackwards(): void {
    switch (timePeriod) {
      case TimePeriod.DAY:
        dispatch(
          setDateRange({
            from: startOfDay(subDays(value.from, 1)),
            to: endOfDay(subDays(value.to, 1))
          })
        )
        break

      case TimePeriod.WEEK:
        dispatch(
          setDateRange({
            from: startOfWeek(subWeeks(value.from, 1), { locale: fi }),
            to: endOfWeek(subWeeks(value.to, 1), { locale: fi })
          })
        )
        break

      case TimePeriod.MONTH:
        dispatch(
          setDateRange({
            from: startOfMonth(subMonths(value.from, 1)),
            to: endOfMonth(subMonths(value.to, 1))
          })
        )
        break

      default:
        dispatch(setTimePeriod(TimePeriod.CUSTOM))
        dispatch(
          setDateRange({
            from: subMinutes(value.from, differenceOfDateRangeInMinutes),
            to: subMinutes(value.to, differenceOfDateRangeInMinutes)
          })
        )
    }
  }

  /**
   * Go forwards in time.
   */
  function goForwards(): void {
    switch (timePeriod) {
      case TimePeriod.DAY:
        dispatch(
          setDateRange({
            from: startOfDay(addDays(value.from, 1)),
            to: endOfDay(addDays(value.to, 1))
          })
        )
        break

      case TimePeriod.WEEK:
        dispatch(
          setDateRange({
            from: startOfWeek(addWeeks(value.from, 1), { locale: fi }),
            to: endOfWeek(addWeeks(value.to, 1), { locale: fi })
          })
        )
        break

      case TimePeriod.MONTH:
        dispatch(
          setDateRange({
            from: startOfMonth(addMonths(value.from, 1)),
            to: endOfMonth(addMonths(value.to, 1))
          })
        )
        break

      default:
        dispatch(setTimePeriod(TimePeriod.CUSTOM))
        dispatch(
          setDateRange({
            from: addMinutes(value.from, differenceOfDateRangeInMinutes),
            to: addMinutes(value.to, differenceOfDateRangeInMinutes)
          })
        )
    }
  }

  return (
    <Stack direction="row" gap={2}>
      <Tooltip title={t('goBackwardsInTime') as string}>
        <IconButton
          size={size}
          sx={{ alignSelf: 'center' }}
          onClick={goBackwards}
        >
          <FastRewindIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <ToggleButtonGroup
        color="primary"
        value={timePeriod}
        exclusive
        onChange={handleSelectedTimePeriodChange}
        aria-label={t('Device.timePeriod')}
        size={size}
        sx={{
          marginTop,
          flexShrink: 0
        }}
      >
        {includeOneHourOption && (
          <Tooltip title={t('showDataForTheLastHour') as string}>
            <ToggleButton
              value={TimePeriod.ONE_HOUR}
              selected={timePeriod === TimePeriod.ONE_HOUR}
            >
              <Typography>{isMobile ? '1h' : t('TimePeriod.1h')}</Typography>
            </ToggleButton>
          </Tooltip>
        )}

        <Tooltip title={t('showDataForTheLastTwentyFourHours') as string}>
          <ToggleButton
            value={TimePeriod.TWENTYFOUR_HOURS}
            selected={timePeriod === TimePeriod.TWENTYFOUR_HOURS}
          >
            {isMobile ? '24h' : t('TimePeriod.24h')}
          </ToggleButton>
        </Tooltip>

        {includeThreeDaysOption && (
          <Tooltip title={t('showDataForTheLastThreeDays') as string}>
            <ToggleButton
              value={TimePeriod.THREE_DAYS}
              selected={timePeriod === TimePeriod.THREE_DAYS}
            >
              {isMobile ? '3d' : t('TimePeriod.3d')}
            </ToggleButton>
          </Tooltip>
        )}

        <Tooltip title={t('showDataForTheLastSevenDays') as string}>
          <ToggleButton
            value={TimePeriod.SEVEN_DAYS}
            selected={timePeriod === TimePeriod.SEVEN_DAYS}
          >
            {isMobile ? '7d' : t('TimePeriod.7d')}
          </ToggleButton>
        </Tooltip>

        <Tooltip title={t('showDataForTheLastThirtyDays') as string}>
          <ToggleButton
            value={TimePeriod.THIRTY_DAYS}
            selected={timePeriod === TimePeriod.THIRTY_DAYS}
          >
            {isMobile ? '30d' : t('TimePeriod.30d')}
          </ToggleButton>
        </Tooltip>

        <Tooltip title={t('selectCustomDateRange') as string}>
          <ToggleButton
            value={TimePeriod.CUSTOM}
            onClick={handleOpenMenu}
            selected={
              timePeriod === TimePeriod.CUSTOM ||
              timePeriod === TimePeriod.DAY ||
              timePeriod === TimePeriod.WEEK ||
              timePeriod === TimePeriod.MONTH
            }
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <DateRangeIcon fontSize="inherit" />
              <Typography sx={{ display: { xs: 'none', sm: 'inline' } }}>
                {t('TimePeriod.custom')}
              </Typography>
            </Stack>
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      <Menu
        open={menuIsOpen}
        anchorEl={menuAnchor}
        onClose={() => closeMenu()}
        elevation={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          backgroundColor: 'transparent',
          marginBottom: '0.5rem',
          border: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        {/* @ts-ignore */}
        <Box onClick={(event) => event.stopPropagation()}>
          <Box px={6} py={3}>
            <Typography variant="h6" mb={6}>
              {t('selectDateRange')}
            </Typography>

            <LocalizationProvider
              // @ts-ignore
              dateAdapter={AdapterDateFns}
              adapterLocale={fi}
            >
              <Stack direction="column" spacing={6} alignItems="center" mb={6}>
                <Stack direction="row" spacing={3} alignItems="center">
                  <DatePicker
                    label={t('fromDate')}
                    value={customDateRange.from}
                    onChange={handleCustomDateRangeFromChange}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '130px' }} />
                    )}
                  />

                  <TimePicker
                    value={customDateRange.from}
                    onChange={handleCustomDateRangeFromChange}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '100px' }} />
                    )}
                  />
                </Stack>

                <Stack direction="row" spacing={3} alignItems="center">
                  <DatePicker
                    label={t('toDate')}
                    value={customDateRange.to}
                    onChange={handleCustomDateRangeToChange}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '130px' }} />
                    )}
                  />

                  <TimePicker
                    value={customDateRange.to}
                    onChange={handleCustomDateRangeToChange}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '100px' }} />
                    )}
                  />
                </Stack>
              </Stack>
            </LocalizationProvider>

            <Box sx={{ mb: 6 }}>
              <Typography fontWeight={600} sx={{ mb: 3 }}>
                {t('absoluteDateRanges')}
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={timePeriod}
                size={size}
                onChange={handleAbsoluteTimePeriodChange}
                exclusive
                fullWidth
              >
                <Tooltip title={t('showDataForTheDay') as string}>
                  <ToggleButton
                    value={TimePeriod.DAY}
                    selected={timePeriod === TimePeriod.DAY}
                  >
                    {t('day')}
                  </ToggleButton>
                </Tooltip>

                <Tooltip title={t('showDataForTheWeek') as string}>
                  <ToggleButton
                    value={TimePeriod.WEEK}
                    selected={timePeriod === TimePeriod.WEEK}
                  >
                    {t('week')}
                  </ToggleButton>
                </Tooltip>

                <Tooltip title={t('showDataForTheMonth') as string}>
                  <ToggleButton
                    value={TimePeriod.MONTH}
                    selected={timePeriod === TimePeriod.MONTH}
                  >
                    {t('month')}
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Box>

            <Stack direction="row" spacing={3} justifyContent="flex-end" mt={3}>
              <Button
                type="button"
                variant="text"
                color="secondary"
                onClick={handleCancel}
              >
                {t('cancel')}
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                {t('confirm')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Menu>

      <Tooltip title={t('goForwardsInTime') as string}>
        <IconButton
          size={size}
          sx={{ alignSelf: 'center' }}
          onClick={goForwards}
        >
          <FastForwardIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
