import { Box, CircularProgress } from '@mui/material'

interface LoadingStateProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  align?: 'flex-start' | 'flex-end' | 'center'
}

export default function LoadingState({
  size = 'lg',
  align = 'center'
}: LoadingStateProps) {
  const boxHeight = computeBoxHeight()
  const circularProgressSize = computeCircularProgressSize()

  function computeBoxHeight(): string {
    switch (size) {
      case 'xs':
        return '1rem'
      default:
        return '10rem'
    }
  }

  function computeCircularProgressSize(): string {
    switch (size) {
      case 'xs':
        return '1rem'
      default:
        return '5vh'
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: align,
        justifyContent: 'center',
        height: boxHeight
      }}
    >
      <CircularProgress size={circularProgressSize} />
    </Box>
  )
}
