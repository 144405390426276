import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { MoreVertical } from 'react-feather'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  IconButton,
  Paper,
  CircularProgress,
  Box,
  Chip,
  Button
} from '@mui/material'
import { spacing } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'

import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../redux/store'
import { ActiveProject, Project } from '../../../types'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import checkProjectStatus from '../../../utils/checkProjectStatus'
import { ProjectStateType } from '../../../enums/ProjectStateType'
import translateProjectState from '../../../utils/translateProjectState'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

interface ProjectInfoProps {
  project: Project | ActiveProject
  userCanManageProject: boolean
}

// eslint-disable-next-line
function ProjectInfo({ project, userCanManageProject }: ProjectInfoProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        {/* <Grid item xs={12} lg={4}>
                <BarChart />
              </Grid> */}
        <Grid item xs={12} md={6}>
          <Card mb={6}>
            <CardHeader
              action={
                <React.Fragment>
                  {userCanManageProject && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => navigate(`/projects/${project.id}/edit`)}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </React.Fragment>
              }
              title={t('Project.projectInfo')}
            />

            <Paper>
              {project && (
                <Table>
                  <TableRow>
                    <TableCell variant="head">{t('status')}</TableCell>
                    <TableCell>
                      <Chip
                        label={t(
                          translateProjectState(project.state) as string
                        )}
                        color={checkProjectStatus(
                          project.state as ProjectStateType
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t('Project.name')}</TableCell>
                    <TableCell>{project.name}</TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                  <TableRow>
                    <TableCell variant="head">{t('Project.address')}</TableCell>
                    <TableCell>{project.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t('Project.city')}</TableCell>
                    <TableCell>{project.city}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t('Project.zip')}</TableCell>
                    <TableCell>{project.zip}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">{t('Project.area')}</TableCell>
                    <TableCell>{project.area}</TableCell>
                  </TableRow>
                </Table>
              )}
            </Paper>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card mb={6}>
            <CardHeader
              action={
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  <DoubleArrowIcon />
                </Button>
              }
              title={t('Project.projectLocation')}
            />

            {project && (
              <Map
                mapboxAccessToken={apiKey}
                longitude={project?.long}
                latitude={project?.lat}
                initialViewState={{
                  zoom: 13
                }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                style={{ width: '100%', height: 340 }}
              >
                <Marker
                  longitude={project?.long}
                  latitude={project?.lat}
                ></Marker>
              </Map>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default ProjectInfo
