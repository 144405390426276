import React, { useState } from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Button, Grid, TextField as MuiTextField } from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useDispatch } from 'react-redux'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import { MFAErrors } from '../../enums/MFAErrrors'
import LoadingState from '../LoadingState'
import { sendMfaResetLink } from '../../services/authService'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

// eslint-disable-next-line
export default function MFAResetForm() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    password: Yup.string().max(255).required('Password is required')
  })

  const initialValues = {
    email: '',
    password: ''
  }

  async function handleSubmit(
    values: any,
    { resetForm, setSubmitting }: any
  ): Promise<void> {
    try {
      setSubmitting(true)
      await sendMfaResetLink({
        email: values.email,
        password: values.password
      })
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('mfaResetLinkSuccess')
        )
      )

      // eslint-disable-next-line
    } catch (error: any) {
      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: MFAErrors.CONFIRM_OTP,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      resetForm()
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      {isSubmitting ? (
        <LoadingState />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6} my={2}>
                <Grid xs={12} my={2}>
                  <TextField
                    name="email"
                    label={t('email')}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    type="email"
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="password"
                    label={t('password')}
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    type="password"
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} my={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t('sendLink')}
                  </Button>
                </Grid>

                <Grid xs={12}>
                  <Button
                    component={Link}
                    to="/auth/sign-in"
                    fullWidth
                    color="primary"
                  >
                    {t('returnToLogin')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}
