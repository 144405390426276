import {
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularOff
} from '@mui/icons-material'
import { Device, Sensor } from '../types'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BlinkingComponent from './BlinkingComponent'

/**
 * The props of the signal indicator.
 */
interface SignalIndicatorProps {
  /**
   * The RSSI.
   */
  rssi: number

  /**
   * The font size of the icon.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
}

/**
 * A signal indicator.
 */
export default function SignalIndicator({
  rssi,
  fontSize = 'inherit'
}: SignalIndicatorProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Indicates if the signal is excellent.
   */
  const isExcellentSignal = rssi > -60

  /**
   * Indicates if the signal is good.
   */
  const isGoodSignal = rssi > -80

  /**
   * Indicates if the signal is fair.
   */
  const isFairSignal = rssi > -90

  /**
   * Indicates if the signal is poor.
   */
  const isPoorSignal = rssi < -90 && rssi > -120

  /**
   * Get the message to be displayed in the tooltip.
   */
  const message = isExcellentSignal
    ? t('excellentSignal')
    : isGoodSignal
    ? t('goodSignal')
    : isFairSignal
    ? t('fairSignal')
    : isPoorSignal
    ? t('poorSignal')
    : t('noSignal')

  return (
    <Tooltip title={message} arrow>
      {isExcellentSignal ? (
        <SignalCellular4Bar fontSize={fontSize} color="success" />
      ) : isGoodSignal ? (
        <SignalCellular3Bar fontSize={fontSize} color="success" />
      ) : isFairSignal ? (
        <SignalCellular2Bar fontSize={fontSize} color="warning" />
      ) : isPoorSignal ? (
        <BlinkingComponent>
          <SignalCellular1Bar fontSize={fontSize} color="error" />
        </BlinkingComponent>
      ) : (
        <BlinkingComponent>
          <SignalCellularOff fontSize={fontSize} color="error" />
        </BlinkingComponent>
      )}
    </Tooltip>
  )
}
