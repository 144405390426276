import { createSlice } from '@reduxjs/toolkit'
import userService, { ManufacturerInvite } from '../../services/userService'
import { Dispatch } from 'redux'
import { createNotification } from './notifications'
import { UserAlertRule } from '../../types/Alerts'
import { ProjectMembership, User, UserQuery } from '../../types'

interface UsersState {
  users: User[]
  members: User[]
  user: User | undefined
  show: boolean
  error: boolean
  loading: boolean
  showUserAlertManagementPopUp: boolean
  userAlertRules: UserAlertRule[]
}

const initialState: UsersState = {
  users: [],
  members: [],
  user: undefined,
  show: false,
  error: false,
  loading: false,
  showUserAlertManagementPopUp: false,
  userAlertRules: []
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    createUser(state, action) {
      state.users = [...state.users, action.payload]
    },
    updateUser(state, action) {
      state.users = state.users
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteUser(state, action) {
      state.users = state.users.filter((u) => u.id !== action.payload)
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    updateUserProject(state, action) {
      state.users = state.users
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    setMembers(state, action) {
      state.members = action.payload
    },
    updateMemberState(state, action) {
      state.members = state.members
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteMemberState(state, action) {
      state.members = state.members.filter(
        (member) => member.id !== action.payload
      )
    },
    setShow(state, action) {
      state.show = action.payload
    },
    setShowUserAlertManagementPopUp(state, action) {
      state.showUserAlertManagementPopUp = action.payload
    },
    setUserAlertRules(state, action) {
      state.userAlertRules = action.payload
    },
    deleteUserAlertRule(state, action) {
      state.userAlertRules = state.userAlertRules.filter(
        (u) => u.id !== action.payload
      )
    }
  }
})

export const {
  setUsers,
  setUser,
  createUser,
  updateUser,
  setShow,
  setShowUserAlertManagementPopUp,
  setUserAlertRules,
  deleteUserAlertRule,
  updateMemberState,
  deleteMemberState
} = userSlice.actions

export function getUsers(query?: UserQuery) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.setLoading(true))
      const response = await userService.getUsers(query)
      dispatch(userSlice.actions.setUsers(response))
    } finally {
      dispatch(userSlice.actions.setLoading(false))
    }
  }
}

export function getUser(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.setLoading(true))
      const response = await userService.getUser(id)
      dispatch(userSlice.actions.setUser(response))
    } finally {
      dispatch(userSlice.actions.setLoading(false))
    }
  }
}

export function deleteUser(id: number, permanently = false) {
  return async (dispatch: Dispatch) => {
    try {
      await userService.deleteUser(id, permanently)

      dispatch(userSlice.actions.deleteUser(id))
      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Succesfully deleted user!',
          timeout: 5000
        })
      )
    } catch (error) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong!',
          timeout: 5000
        })
      )
    }
  }
}

export function getProjectMembers(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.setLoading(true))
      const response = await userService.getProjectMembers(id)
      dispatch(userSlice.actions.setMembers(response))
    } finally {
      dispatch(userSlice.actions.setLoading(false))
    }
  }
}

export function inviteManufacturer(email: ManufacturerInvite) {
  return async (dispatch: Dispatch) => {
    try {
      await userService.inviteManufacturer(email)

      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Email sent succesfully!',
          timeout: 5000
        })
      )
    } catch (error) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong!',
          timeout: 5000
        })
      )
    }
  }
}

export function getUserAlertRulesByProject(userId: number, projectId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(userSlice.actions.setLoading(true))
      const response = await userService.getUserAlertRulesByProject(
        userId,
        projectId
      )
      dispatch(userSlice.actions.setUserAlertRules(response))
    } finally {
      dispatch(userSlice.actions.setLoading(false))
    }
  }
}

export default userSlice.reducer
