import React, { useState } from 'react'
import { Button, Card, Grid, Typography, useMediaQuery } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import LoadingState from './LoadingState'
import { useTranslation } from 'react-i18next'
import { CurrentUser, User } from '../types'
import { AlertGroup, AlertRule, UserAlertRule } from '../types/Alerts'
import { useDispatch } from 'react-redux'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { useAuth } from '../hooks'
import { AlertErrors } from '../enums/AlertErrors'
import { useTheme } from '@mui/material/styles'
import { updateUserAlertRulesByProject } from '../services/userService'
import { buildAlertStructureForUser } from '../utils/alert'
import {
  setShowUserAlertManagementPopUp,
  setUserAlertRules
} from '../redux/slices/users'
import { AuthUser } from '../types/auth'

interface UserAlertManagementTableProps {
  user?: User | CurrentUser | AuthUser
  projectId: string | number
  projectAlertRules: AlertGroup[]
  loading: boolean
  userAlertRules: UserAlertRule[]
  updatingOwnSettings: boolean
}

export type UserAlertDataGridRow = {
  id: string | number
  ruleGroupName: string
  alertRuleName: string
  notificationEnabled: boolean
  appNotificationEnabled: boolean
}

export default function UserAlertManagementPopUp({
  user,
  projectId,
  projectAlertRules,
  loading,
  userAlertRules,
  updatingOwnSettings
}: UserAlertManagementTableProps) {
  /**
   * The translate function
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function
   */
  const dispatch = useDispatch()

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * The theme
   */
  const theme = useTheme()

  /**
   * The mobile breakpoint
   */
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  async function handleUpdateAlerts(data: UserAlertDataGridRow[]) {
    try {
      const alertRules = buildAlertStructureForUser(data)

      //Combine existing rules with new rules. Otherwise backend clears existing rules after save
      const combinedAlerts = userAlertRules.concat(alertRules)
      const updatedUserAlerts = await updateUserAlertRulesByProject(
        user?.id as number,
        projectId,
        combinedAlerts
      )
      dispatch(setUserAlertRules(updatedUserAlerts))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('userAlertUpdateSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AlertErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      dispatch(setShowUserAlertManagementPopUp(false))
    }
  }

  let columns
  updatingOwnSettings
    ? (columns = [
        {
          field: 'alertRuleName',
          headerName: t('name'),
          width: lessThanSmall ? 200 : 250,
          editable: false
        },
        {
          field: 'enabled',
          headerName: t('enabled'),
          type: 'boolean',
          width: lessThanSmall ? 200 : 250,
          editable: true
        }
      ])
    : (columns = [
        {
          field: 'alertRuleName',
          headerName: t('name'),
          width: 150,
          editable: false
        },
        {
          field: 'notificationEnabled',
          headerName: t('emailNotifications'),
          type: 'boolean',
          width: 200,
          editable: false
        },
        {
          field: 'appNotificationEnabled',
          headerName: t('appNotifications'),
          type: 'boolean',
          width: 200,
          editable: false
        }
      ])

  const rows: UserAlertDataGridRow[] = []

  updatingOwnSettings
    ? projectAlertRules?.map((group: AlertGroup) => {
        group.alerts.map((alert: AlertRule) => {
          if (alert.enabled === true) {
            checkIfUserAlreadyHasAlertRule(group, alert)
          }
        })
      })
    : userAlertRules?.map((rule: UserAlertRule) => {
        rows.push({
          id: rule.id as number,
          ruleGroupName: rule.ruleGroupName,
          alertRuleName: rule.alertRuleName,
          notificationEnabled: rule.notificationEnabled,
          appNotificationEnabled: rule.appNotificationEnabled
        })
      })

  function checkIfUserAlreadyHasAlertRule(group: AlertGroup, alert: AlertRule) {
    const target = userAlertRules.find(
      (obj: UserAlertRule) => obj.alertRuleName === alert.name
    )
    if (target === undefined) {
      rows.push({
        id: alert.name,
        ruleGroupName: group.name,
        alertRuleName: alert.name,
        notificationEnabled: false,
        appNotificationEnabled: false
      })
    }
  }

  function handleRowChange(row: any) {
    //Updated row
    const target = rows.find((obj: UserAlertDataGridRow) => obj.id === row.id)
    Object.assign(target as UserAlertDataGridRow, {
      id: row.name,
      ruleGroupName: target?.ruleGroupName,
      alertRuleName: row.name,
      notificationEnabled: row.enabled,
      appNotificationEnabled: row.enabled
    })

    setRowIsNotModified(false)
  }

  function handleRowDataChange(params: any) {
    const alertName = Object.keys(params)
    const alertValues: any = Object.values(params)

    if (alertName[0] && alertValues[0]) {
      handleRowChange({
        id: alertName[0],
        name: alertName[0],
        enabled: alertValues[0].enabled.value
      })
    }
  }

  /**
   * Indicates is row modified
   */
  const [rowIsNotModiefied, setRowIsNotModified] = useState(true)

  return (
    <React.Fragment>
      <Card>
        <>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              {updatingOwnSettings ? (
                <Typography align="left" sx={{ mb: 4 }} variant="h6">
                  {t('addNewAlerts')}
                </Typography>
              ) : (
                <Typography align="left" sx={{ mb: 4 }}>
                  {user ? user.firstName : '-'} {user ? user.lastName : '-'}{' '}
                  {t('hasTheseAlerts')}
                </Typography>
              )}
            </Grid>
            {updatingOwnSettings && (
              <Grid item xs={6}>
                <Typography align="right">
                  <Button
                    disabled={rowIsNotModiefied}
                    sx={{ mb: 4 }}
                    variant="contained"
                    onClick={() => handleUpdateAlerts(rows)}
                  >
                    {t('save')}
                  </Button>
                </Typography>
              </Grid>
            )}
          </Grid>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            editMode="row"
            onEditRowsModelChange={(params: any) => handleRowDataChange(params)}
            disableColumnMenu
            autoHeight
          />
        </>
      </Card>
    </React.Fragment>
  )
}
