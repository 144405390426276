import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  MenuItem
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { CatalogProductType } from '../../enums/CatalogProductType'
import { CatalogProductInput } from '../../types'
import {
  createCatalogProduct,
  hideCatalogProduct,
  updateCatalogProduct
} from '../../redux/slices/catalog'
import catalogProductService from '../../services/catalogProductService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import { useAuth } from '../../hooks'
import useCatalogProduct from '../../hooks/useCatalogProduct'
import { CatalogProductErrors } from '../../enums'
import { NominalCurrentSelectForSmartElectricalCabinet } from '../NominalCurrentSelect'
import PowerSupplyInputCablesSelect from '../PowerSupplyInputCablesSelect'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function CatalogForm() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const catalogProduct = useCatalogProduct()

  const initialValues: CatalogProductInput =
    catalogProduct === undefined
      ? {
          type: CatalogProductType.ELECTRICAL_CABINET,
          name: '',
          description: '',
          manufacturer: '',
          manufacturerProductCode: '',
          customData: {
            fuse: '',
            isMainDistributionAssembly: false,
            inputCables: 0
          }
        }
      : {
          type: catalogProduct.type,
          name: catalogProduct.name,
          description: catalogProduct.description,
          manufacturer: catalogProduct.manufacturer,
          manufacturerProductCode: catalogProduct.manufacturerProductCode,
          customData: {
            fuse: catalogProduct.customData?.fuse ?? '',
            isMainDistributionAssembly:
              catalogProduct.customData?.isMainDistributionAssembly ?? '',
            inputCables: catalogProduct.customData?.inputCables ?? 0
          }
        }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    manufacturer: Yup.string().required('Required'),
    manufacturerProductCode: Yup.string().required('Required'),
    customData: Yup.object().shape({
      fuse: Yup.string().required('Required')
    })
  })

  useEffect(() => {
    return function cleanup() {
      dispatch(hideCatalogProduct())
    }
  }, [dispatch])

  async function handleSubmit(
    values: any,
    { setSubmitting }: any
  ): Promise<void> {
    try {
      if (catalogProduct === undefined) {
        const createdCatalogProduct =
          await catalogProductService.createCatalogProduct(values)

        dispatch(createCatalogProduct(createdCatalogProduct))

        dispatch<unknown>(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('catalogProductCreateSuccess')
          )
        )
      } else {
        const updatedCatalogProduct =
          await catalogProductService.updateCatalogProduct(
            catalogProduct.id,
            values
          )

        dispatch(updateCatalogProduct(updatedCatalogProduct))

        dispatch<unknown>(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('catalogProductUpdateSuccess')
          )
        )
      }

      dispatch(hideCatalogProduct())
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: catalogProduct
          ? CatalogProductErrors.EDIT
          : CatalogProductErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="name"
                    label={t('name')}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    name="type"
                    label={t('type')}
                    value={values.type}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value={CatalogProductType.ELECTRICAL_CABINET}>
                      {t('electricalCabinet')}
                    </MenuItem>
                    <MenuItem value={CatalogProductType.SPINE_MODULE}>
                      {t('spineModule')}
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="manufacturer"
                    label={t('manufacturer')}
                    value={values.manufacturer}
                    error={Boolean(touched.manufacturer && errors.manufacturer)}
                    helperText={touched.manufacturer && errors.manufacturer}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    name="manufacturerProductCode"
                    label={t('manufacturerProductCode')}
                    value={values.manufacturerProductCode}
                    error={Boolean(
                      touched.manufacturerProductCode &&
                        errors.manufacturerProductCode
                    )}
                    helperText={
                      touched.manufacturerProductCode &&
                      errors.manufacturerProductCode
                    }
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={3}>
                  <NominalCurrentSelectForSmartElectricalCabinet
                    name="fuse"
                    value={values.customData.fuse}
                    error={Boolean(
                      touched?.customData?.fuse && errors?.customData?.fuse
                    )}
                    helperText={
                      touched?.customData?.fuse && errors?.customData?.fuse
                    }
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <PowerSupplyInputCablesSelect
                    name="inputCables"
                    label={t('PowerSupply.inputCables')}
                    value={values.customData.inputCables}
                    error={Boolean(
                      touched?.customData?.inputCables &&
                        errors?.customData?.inputCables
                    )}
                    helperText={
                      touched?.customData?.inputCables &&
                      errors?.customData?.inputCables
                    }
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    name="isMainDistributionAssembly"
                    label={t('isMainDistributionAssembly')}
                    value={values.customData.isMainDistributionAssembly}
                    error={Boolean(
                      touched?.customData?.isMainDistributionAssembly &&
                        errors?.customData?.isMainDistributionAssembly
                    )}
                    helperText={
                      touched?.customData?.isMainDistributionAssembly &&
                      errors?.customData?.isMainDistributionAssembly
                    }
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value={false as any}>{t('no')}</MenuItem>
                    <MenuItem value={true as any}>{t('yes')}</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    name="description"
                    label={t('description')}
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
