import { useRoutes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { create } from 'jss'
import { ThemeProvider } from 'styled-components/macro'

import { StyledEngineProvider } from '@mui/styled-engine-sc'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StylesProvider from '@mui/styles/StylesProvider'
import jssPreset from '@mui/styles/jssPreset'

import './i18n'
import createTheme from './theme'
import routes from './routes/index'

import useTheme from './hooks/useTheme'
import { store } from './redux/store'

import { AuthProvider } from './contexts/JWTContext'
import { useHotjar } from './hooks'
// import { PersistGate } from 'redux-persist/integration/react'
// import { persistStore } from 'redux-persist'

// const persistor = persistStore(store)

const jss = create({
  ...jssPreset(),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  insertionPoint: document.getElementById('jss-insertion-point')!
})

function App() {
  const content = useRoutes(routes)
  const { theme } = useTheme()

  if (process.env?.NODE_ENV === 'production') {
    useHotjar()
  }

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Spine" defaultTitle="Spine - Dashboard" />
      <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persistor}>*/}
        <StylesProvider jss={jss}>
          {/* @ts-ignore */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <AuthProvider>{content}</AuthProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
        {/*</PersistGate>*/}
      </Provider>
    </HelmetProvider>
  )
}

export default App
