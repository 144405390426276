import React, { useState } from 'react'
import ProjectBaseForm from './ProjectBaseForm'
import EmailForm from './EmailToTheForemanForm'

interface DeviceInstallProjectCreationProps {
  projectCreatedAndEmailSent(projectId: number): void
  handleCancel(): void
}

export default function DeviceInstallProjectCreation({
  projectCreatedAndEmailSent,
  handleCancel
}: DeviceInstallProjectCreationProps) {
  /**
   * Created project id
   */
  const [createdProject, setCreatedProject] = useState<number | undefined>(
    undefined
  )

  return (
    <React.Fragment>
      {createdProject ? (
        /**
         * Form for sending email to the foreman of the project
         */
        <EmailForm
          projectId={createdProject}
          emailSent={() => {
            projectCreatedAndEmailSent(createdProject)
          }}
        />
      ) : (
        /**
         * Project base creation form
         */
        <ProjectBaseForm
          projectCreated={(projectId) => {
            setCreatedProject(projectId)
          }}
          handleCancel={() => {
            handleCancel()
          }}
        />
      )}
    </React.Fragment>
  )
}
