import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { getProject } from '../../redux/slices/projects'
import { useTranslation } from 'react-i18next'
import ProjectForm from '../../components/ProjectForm'
import { createNotification } from '../../redux/slices/notifications'
import { useAuth } from '../../hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { getCompanies, getCompany } from '../../redux/slices/companies'
import { UserRole } from '../../enums/UserRole'
import LoadingState from '../../components/LoadingState'
import { Helmet } from 'react-helmet-async'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

export default function EditProject() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const { project, loading } = useSelector((state: RootState) => state.projects)
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      dispatch(getProject(parseInt(id)))
    }
  }, [id])

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.role === UserRole.SUPERADMIN) {
        dispatch(getCompanies())
      } else if (
        (currentUser?.role === UserRole.ADMIN && currentUser?.company?.id) ||
        (currentUser?.role === UserRole.USER && currentUser?.company?.id)
      ) {
        dispatch(getCompany(currentUser.company.id as number))
      }
    }

    //Navigate user without company to frontpage.
    //Dispatch error message that tells user to create and company before he can update project
    if (currentUser && currentUser.company === null) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: t('companyMissing'),
          timeout: 5000
        })
      )
      navigate('/')
    }
  }, [currentUser])

  return (
    <>
      <Helmet title={t('editProject')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('editProject')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('Project.frontpage')}
        </Link>

        <Link component={NavLink} to="/projects">
          {t('Project.projects')}
        </Link>

        <Link component={NavLink} to={`/projects/${project?.id}`}>
          {project?.name}
        </Link>

        <Typography>{t('edit')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {loading ? <LoadingState /> : <ProjectForm />}
    </>
  )
}
