import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createUser } from '../../redux/slices/users'
import { UserRole } from '../../enums/UserRole'
import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { THEMES } from '../../constants'
import useTheme from '../../hooks/useTheme'
import { InstructionForNewUser } from '../InstructionsForNewUser'
import {
  createErrorOrSuccessNotification,
  setShowInstructions
} from '../../redux/slices/notifications'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { deleteError, setError } from '../../redux/slices/errors'
import { AuthErrors } from '../../enums/AuthErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { NotificationType } from '../../enums/NotificationType'
import userService from '../../services/userService'

const Card = styled(MuiCard)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  role: '',
  language: 'en',
  notificationsByEmail: true,
  notificationsBySms: true
}

// eslint-disable-next-line
function SignUpGuest() {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [isInstaller, setIsInstaller] = useState(UserRole.GUEST)
  const [t] = useTranslation('common')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const { theme } = useTheme()
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === true) {
      setIsInstaller(UserRole.INSTALLER)
    } else if (event.target.checked === false) {
      setIsInstaller(UserRole.GUEST)
    }
  }

  useEffect(() => {
    dispatch(setShowInstructions(true))
  }, [])

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .max(255)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        t('strongPassword')
      )
      .required('Required'),
    confirmPassword: Yup.string().when('password', {
      // eslint-disable-next-line
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same'
      )
    }),
    role: Yup.string().required('Required'),
    notificationsByEmail: Yup.boolean().required('Required'),
    notificationsBySms: Yup.boolean().required('Required')
  })

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (
        values.phone !== undefined &&
        values.phone !== '+' &&
        values.phone !== '' &&
        values.phone !== null
      ) {
        //Add '+' to phone number since PhoneInput does not provide it
        values.phone = '+' + values.phone
      } else if (values.phone === '+' || values.phone === '') {
        //if user wants to clear phonenumber on update we need to do this otherwise '+' is saved to database.
        values.phone = null
      }

      const newUser = await userService.createUser(values)
      dispatch(createUser(newUser))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('signUpSuccess')
        )
      )
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      navigate('/auth/sign-in')

      // eslint-disable-next-line
    } catch (error: unknown) {
      dispatch(
        setError({
          type: AuthErrors.SIGN_UP_GUEST,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: AuthErrors.SIGN_UP,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(AuthErrors.SIGN_UP))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <InstructionForNewUser />
          <Grid mt={2} ml={2}>
            <IconButton
              size="small"
              onClick={() => dispatch(setShowInstructions(true))}
            >
              <QuestionMarkIcon />
            </IconButton>
          </Grid>
          <CardContent>
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="text"
                    name="firstName"
                    label={t('Users.firstName')}
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="text"
                    name="lastName"
                    label={t('Users.lastName')}
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="email"
                    name="email"
                    label={t('Users.email')}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl my={3}>
                  <PhoneInput
                    enableSearch={true}
                    specialLabel=""
                    placeholder={t('Users.phone')}
                    value={(values.phone = phoneNumber)}
                    onChange={(newValue) => setPhoneNumber(newValue)}
                    containerStyle={{
                      width: '100%'
                    }}
                    inputStyle={{
                      width: '100%',
                      background:
                        theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                      color: theme === THEMES.DARK ? 'white' : 'black',
                      fontSize: 13,
                      borderColor: '#777777'
                    }}
                    dropdownStyle={{
                      background:
                        theme === THEMES.DARK ? '#233044' : '#FFFFFFF2',
                      color: 'gray'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="password"
                    name="password"
                    label={t('Users.password')}
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label={t('Users.confirm')}
                    value={values.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={3}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    name="notificationsByEmail"
                    label={t('Users.emailNotification')}
                    select
                    value={values.notificationsByEmail}
                    error={Boolean(
                      touched.notificationsByEmail &&
                        errors.notificationsByEmail
                    )}
                    fullWidth
                    helperText={
                      touched.notificationsByEmail &&
                      errors.notificationsByEmail
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  >
                    <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                    <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl>
                  <TextField
                    name="notificationsBySms"
                    label={t('Users.smsNotification')}
                    select
                    value={values.notificationsBySms}
                    error={Boolean(
                      touched.notificationsBySms && errors.notificationsBySms
                    )}
                    fullWidth
                    helperText={
                      touched.notificationsBySms && errors.notificationsBySms
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  >
                    <MenuItem value={true as any}>{t('Users.yes')}</MenuItem>
                    <MenuItem value={false as any}>{t('Users.no')}</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl>
                  <TextField
                    name="language"
                    label={t('Users.language')}
                    select
                    value={values.language}
                    error={Boolean(touched.language && errors.language)}
                    fullWidth
                    helperText={touched.language && errors.language}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  >
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem value={'fi'}>Finnish</MenuItem>
                    <MenuItem value={'swe'}>Swedish</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxChange} />}
                label={t('Users.iAmInstaller') as string}
                name="role"
                value={(values.role = isInstaller)}
              />

              <Box
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  onClick={() => {
                    navigate('/auth/sign-in')
                  }}
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  {t('Users.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  {t('Users.save')}
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

export default SignUpGuest
