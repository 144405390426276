import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import { Paper, Typography } from '@mui/material'

import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import ResetPasswordComponent from '../../components/auth/ResetPassword'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function ResetPassword() {
  const { theme } = useTheme()
  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}
      <Wrapper>
        <Helmet title="Reset Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  )
}

export default ResetPassword
