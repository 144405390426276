import {
  Order,
  OrderQuery,
  OrderInput,
  OrderItem,
  OrderItemInput
} from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getOrders(query?: OrderQuery): Promise<Order[]> {
  const response = await api.get('/orders', {
    params: query
  })

  return response.data
}

export async function getOrder(id: number): Promise<Order> {
  const response = await api.get(`/orders/${id}`)

  return response.data
}

export async function createOrder(input: OrderInput): Promise<Order> {
  const response = await api.post('/orders', input)

  return response.data
}

export async function updateOrder(
  id: number,
  input: OrderInput
): Promise<Order> {
  const response = await api.put(`/orders/${id}`, input)

  return response.data
}

export async function deleteOrder(id: number): Promise<void> {
  await api.delete(`/orders/${id}`)
}

export async function getOrderItems(orderId: number): Promise<OrderItem[]> {
  const response = await api.get(`orders/${orderId}/order-items`, {
    params: {
      orderId: orderId
    }
  })

  return response.data
}

export async function createOrderItem(
  input: OrderItemInput
): Promise<OrderItem> {
  const response = await api.post(`/orders/${input.orderId}/order-items`, input)

  return response.data
}

export async function getOrderItem(
  orderId: number,
  orderItemId: number
): Promise<OrderItem> {
  const response = await api.get(
    `/orders/${orderId}/order-items/${orderItemId}`
  )

  return response.data
}

export async function deleteOrderItem(
  orderId: number,
  orderItemId: number
): Promise<void> {
  await api.delete(`/orders/${orderId}/order-items/${orderItemId}`)
}

export default {
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  deleteOrder,
  getOrderItems,
  getOrderItem,
  createOrderItem,
  deleteOrderItem
}
