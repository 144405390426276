import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common_en from './translations/en/common.json'
import common_fi from './translations/fi/common.json'
import common_swe from './translations/swe/common.json'

const userlng = window.localStorage.getItem('language') as string

const resources = {
  fi: {
    common: common_fi
  },
  en: {
    common: common_en
  },
  swe: {
    common: common_swe
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: userlng || 'en',
  interpolation: {
    escapeValue: false
  }
})
