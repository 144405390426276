export enum Manufacturers {
  TSR_ELSITE = 'TSR-Elsite',
  SATEMA = 'Satema',
  GARO = 'Garo',
  EL_BJÖRN = 'El-Björn',
  VOHEK = 'Vohek',
  APUWATTI = 'Apuwatti',
  SRS_FENNO = 'SRS Fenno',
  GENERIC = 'Generic',
  KK_SÄHKÖTUKKU = 'Kk-sähkötukku',
  SPINE = 'Spine'
}
