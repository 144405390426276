import { useTranslation } from 'react-i18next'
import { ChartOptions } from 'chart.js'
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import {
  RuuviTagBattery,
  RuuviTagBatteryQuery,
  RuuviTagBatteryAggregate,
  RuuviTagBatteryAggregateQuery,
  RuuviTagBatteryAggregateStatistic
} from '../types'
import RuuviTagBatteryChart from './RuuviTagBatteryChart'
import RuuviTagBatteryAggregateChart from './RuuviTagBatteryAggregateChart'
import { useState } from 'react'
import RuuviTagBatteryStatisticToggle from './RuuviTagBatteryStatisticToggle'
import LoadingState from './LoadingState'

interface RuuviTagBatteryVoltageChartProps {
  batteries: RuuviTagBattery[] | RuuviTagBatteryAggregate[]
  query: RuuviTagBatteryQuery | RuuviTagBatteryAggregateQuery
  loading?: boolean
}

export default function RuuviTagBatteryVoltageChart({
  batteries,
  query,
  loading = false
}: RuuviTagBatteryVoltageChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The statistic aggregates.
   */
  const [statisticAggregates, setStatisticAggregates] = useState<
    RuuviTagBatteryAggregateStatistic[]
  >(['avg'])

  /**
   * The chart options.
   */
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: true
        }
      }
    }
  }

  /**
   * Determines if the battery aggregates are used.
   */
  const isUsingBatteryAggregates =
    batteries.length > 0 && 'timeBucket' in batteries[0]

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h4">{t('battery')}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            alignItems="center"
          >
            {isUsingBatteryAggregates && (
              <RuuviTagBatteryStatisticToggle
                value={statisticAggregates}
                onChange={setStatisticAggregates}
              />
            )}
          </Grid>
        </Grid>

        {loading ? (
          <LoadingState />
        ) : (
          <>
            {isUsingBatteryAggregates ? (
              <RuuviTagBatteryAggregateChart
                batteries={batteries as RuuviTagBatteryAggregate[]}
                query={query as RuuviTagBatteryAggregateQuery}
                statistics={statisticAggregates}
                options={options}
              />
            ) : (
              <RuuviTagBatteryChart
                ruuviTagBatteries={batteries as RuuviTagBattery[]}
                ruuviTagBatteryQuery={query as RuuviTagBatteryQuery}
                chartOptions={options}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}
