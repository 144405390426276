import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography
} from '@mui/material'
import { MessageSquare } from 'react-feather'
import { UserRole } from '../../enums/UserRole'
import {
  deleteDeviceLocationChangeRequest,
  getActionRequestsByCompany,
  setCompanyRequests
} from '../../redux/slices/requests'
import useAuth from '../../hooks/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import { Announcement, ActionRequest } from '../../types'
import { MFAType } from '../../enums'
import NavbarMessageType from '../../enums/NavbarMessageType'
import { deleteRequest } from '../../services/requestService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { RequestAccessErrors } from '../../enums/RequestAccessErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { NotificationType } from '../../enums/NotificationType'
import LoadingState from '../LoadingState'

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 350px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`
interface NavbarMessagesDropDownProps {
  announcements: Announcement[]
  lengthOfUnreadAnnouncements: number
}
// eslint-disable-next-line
function NavbarMessagesDropdown({
  announcements,
  lengthOfUnreadAnnouncements
}: NavbarMessagesDropDownProps) {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const [t] = useTranslation('common')
  const { companyRequests, deviceLocationChangeRequests } = useSelector(
    (state: RootState) => state.requests
  )
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (
      (currentUser && currentUser.role === UserRole.ADMIN) ||
      (currentUser && currentUser.role === UserRole.SUPERADMIN)
    ) {
      currentUser.company
        ? dispatch(getActionRequestsByCompany(currentUser.company.id))
        : dispatch(setCompanyRequests([]))
    } else {
      dispatch(setCompanyRequests([]))
    }
  }, [currentUser])

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  async function handleDeleteRequest(id: string) {
    try {
      setIsDeleting(true)
      await deleteRequest(id)
      dispatch(deleteDeviceLocationChangeRequest(id))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('messageDeleteSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage?.key) + t(errorMessage?.message)
        )
      )
    } finally {
      handleClose()
      setIsDeleting(false)
    }
  }

  function Message({
    title,
    description,
    navigationId,
    requestId,
    type
  }: {
    title: string
    description: string
    navigationId?: number | string
    requestId?: string
    type: string
  }) {
    return (
      <React.Fragment>
        {isDeleting ? (
          <LoadingState />
        ) : (
          <React.Fragment>
            {type === NavbarMessageType.COMPANY && (
              <ListItem
                divider
                component={Link}
                to={`/companies/${navigationId}`}
                onClick={handleClose}
              >
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                  secondary={description}
                />
              </ListItem>
            )}

            {type === NavbarMessageType.MFA_NOTIFICATION && (
              <ListItem
                divider
                component={Link}
                to={`/mfa`}
                onClick={handleClose}
              >
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                  secondary={description}
                />
              </ListItem>
            )}

            {type === NavbarMessageType.DEVICE_LOCATION && (
              <React.Fragment>
                <ListItem
                  divider
                  component={Link}
                  to={`/devices/${navigationId}/location`}
                  onClick={handleClose}
                >
                  <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                      color: 'textPrimary'
                    }}
                    secondary={description}
                  />
                </ListItem>

                <Button
                  color="error"
                  fullWidth
                  variant="contained"
                  onClick={() => handleDeleteRequest(requestId as string)}
                >
                  {t('deleteMessage')}
                </Button>
              </React.Fragment>
            )}

            {type === NavbarMessageType.COMPANY_VAT && (
              <ListItem
                divider
                component={Link}
                to={`/companies/${navigationId}`}
                onClick={handleClose}
              >
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                  secondary={description}
                />
              </ListItem>
            )}

            {type === NavbarMessageType.ANNOUNCEMENT && (
              <ListItem divider onClick={handleClose}>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                  secondary={description}
                />
              </ListItem>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  const addVATNotification =
    currentUser?.role === UserRole.ADMIN &&
    currentUser?.company.vatNumber === null
      ? 1
      : 0

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Badge
            badgeContent={
              addVATNotification +
              companyRequests.length +
              deviceLocationChangeRequests.length +
              lengthOfUnreadAnnouncements
            }
            color="error"
          >
            <MessageSquare />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MessageHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {currentUser?.mfaType === MFAType.NONE
              ? addVATNotification +
                companyRequests.length +
                deviceLocationChangeRequests.length +
                1
              : addVATNotification +
                companyRequests.length +
                deviceLocationChangeRequests.length}{' '}
            {t('Navbar.newNotifications')}
          </Typography>
        </MessageHeader>
        {currentUser?.mfaType === MFAType.NONE && (
          <List disablePadding>
            <Message
              title={t('enableMfaNotification')}
              description={t('enableMfaNotificationInstructions')}
              navigationId={currentUser.id}
              type={NavbarMessageType.MFA_NOTIFICATION}
            />
          </List>
        )}

        {currentUser?.role === UserRole.ADMIN &&
          currentUser?.company.vatNumber === null && (
            <List disablePadding>
              <Message
                title={t('addVatNumberTitle')}
                description={t('addVatNumberNotificationInstructions')}
                navigationId={currentUser.company.id}
                type={NavbarMessageType.COMPANY_VAT}
              />
            </List>
          )}

        {companyRequests.length > 0 && (
          <React.Fragment>
            {companyRequests.map((row: ActionRequest) => (
              <List disablePadding>
                <Message
                  title={
                    t('Navbar.requestFrom') +
                    ' ' +
                    row?.user?.firstName +
                    ' ' +
                    row?.user?.lastName
                  }
                  description={t('Navbar.clickThis')}
                  navigationId={currentUser?.company.id}
                  type={NavbarMessageType.COMPANY}
                />
              </List>
            ))}
          </React.Fragment>
        )}

        {deviceLocationChangeRequests.length > 0 && (
          <React.Fragment>
            {deviceLocationChangeRequests.map((request) => (
              <List disablePadding>
                <Message
                  title={
                    t('deviceLocationChangeTitle') +
                    request.device?.powerSupply?.name
                  }
                  description={t('deviceLocationChangeMessage')}
                  navigationId={request.requesterId}
                  requestId={request.id}
                  type={NavbarMessageType.DEVICE_LOCATION}
                />
              </List>
            ))}
          </React.Fragment>
        )}

        {announcements.length > 0 && (
          <React.Fragment>
            {announcements.map((announcement: Announcement) => (
              <List disablePadding>
                <Message
                  title={announcement.name}
                  description={announcement.message}
                  type={NavbarMessageType.ANNOUNCEMENT}
                />
              </List>
            ))}
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  )
}

export default NavbarMessagesDropdown
