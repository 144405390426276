import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import useAuth from '../../hooks/useAuth'
import { MFAType } from '../../enums'
import MFASetupForm from '../../components/MFASetUpForm'
import MFADisableForm from '../../components/MFADisableForm'
import LoadingState from '../../components/LoadingState'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

export default function MFASetUp() {
  const [t] = useTranslation('common')

  const { currentUser, refreshUser } = useAuth()
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false)

  useEffect(() => {
    loadRefreshedUser()
  }, [])

  async function loadRefreshedUser() {
    try {
      setIsLoadingUser(true)
      await refreshUser()
    } finally {
      setIsLoadingUser(false)
    }
  }

  return (
    <React.Fragment>
      <Helmet title={t('mfa')} />

      <Typography variant="h3" gutterBottom display="inline">
        {t('multiFactorAuthentication')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to={`/users/${currentUser?.id}`}>
          {t('profile')}
        </Link>
        <Typography>{t('mfa')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {isLoadingUser ? (
        <LoadingState />
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {currentUser?.mfaType === MFAType.NONE ? (
              <MFASetupForm />
            ) : (
              <MFADisableForm />
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}
