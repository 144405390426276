import { useTranslation } from 'react-i18next'
import { RuuviTagCondition } from '../types'
import RuuviTagStats from './RuuviTagStats'

interface RuuviTagTemperatureCardProps {
  ruuviTagCondition: RuuviTagCondition | null
  loading?: boolean
}

export default function RuuviTagTemperatureCard({
  ruuviTagCondition,
  loading = false
}: RuuviTagTemperatureCardProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <RuuviTagStats
      title={t('temperature')}
      value={ruuviTagCondition?.temperatureAvg}
      unit="°C"
      date={ruuviTagCondition?.time}
      loading={loading}
    />
  )
}
