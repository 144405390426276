import {
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps
} from '@mui/material'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { UserRole } from '../enums/UserRole'
import useAuth from '../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function UserRoleSelect(props: TextFieldProps) {
  const { currentUser } = useAuth()
  const [t] = useTranslation('common')
  const roles = []

  if (currentUser?.role === UserRole.SUPERADMIN) {
    roles.push(
      UserRole.USER,
      UserRole.MANUFACTURER,
      UserRole.SUPERADMIN,
      UserRole.GUEST,
      UserRole.ADMIN,
      UserRole.INSTALLER
    )
  }

  return (
    <TextField select name="role" label={t('Users.role')} {...props} my={2}>
      {roles.map((role: UserRole) => (
        <MenuItem key={role} value={role}>
          {role}
        </MenuItem>
      ))}
    </TextField>
  )
}
