import { ErrorData } from '../redux/slices/errors'

export function createSuperAdminErrorMessage(errorData: ErrorData) {
  //TODO: Make error messages for different error situations
  let message
  //TODO: FIX BACKEND LOGIN ERROR HANDLING SO WE CAN TRANSLATE THE MESSAGE
  if (errorData.error.response === undefined) {
    return (message = { key: 'default', message: errorData.error.message })
  }

  switch (errorData.error.response.status) {
    case 422:
      errorData.error.response.data
        ? Object.entries(errorData.error.response.data.errors).forEach(
            ([k, v]: any) => {
              v.map((row: string) => {
                message = { key: k, message: row }
              })
            }
          )
        : (message = { key: 'default', message: 'unprocessableEntity' })

      return message

    default:
      return (message = { key: 'default', message: 'somethingWentWrong' })
  }
}

export function createSimpleErrorMessage(errorData: ErrorData) {
  //TODO: Make error messages for different error situations
  let message: any
  //TODO: FIX BACKEND LOGIN ERROR HANDLING SO WE CAN TRANSLATE THE MESSAGE
  if (errorData.error.response === undefined) {
    return (message = { key: 'default', message: errorData.error.message })
  }

  switch (errorData.error.response.status) {
    case 422:
      errorData.error.response.data
        ? Object.entries(errorData.error.response.data.errors).forEach(
            ([k, v]: any) => {
              v.map((row: string) => {
                message = { key: k, message: row }
              })
            }
          )
        : (message = { key: 'default', message: 'unprocessableEntity' })
      return message

    default:
      return (message = { key: 'default', message: 'somethingWentWrong' })
  }
}
