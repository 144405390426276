import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useAppSettings from '../hooks/useAppSettings'
import { CalculationMode, StartDateMode } from '../enums'
import EnergyPriceType from '../enums/EnergyPriceType'

import {
  Project,
  EnergyCost,
  EnergyCostSettings,
  EnergyCostQuery,
  DateRange,
  ActiveProject
} from '../types'
import { getStartDateByMode } from '../utils/date'
import EnergyCostStats from './EnergyCostStats'
import { getEnergyCostsOfProject } from '../services/projectService'
import { isFuture } from 'date-fns'

interface ProjectEnergyCostStatsProps {
  project: Project | ActiveProject
  dateRange?: DateRange
  loading?: boolean
}

export default function ProjectEnergyCostStats({
  project,
  dateRange,
  loading = false
}: ProjectEnergyCostStatsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  /**
   * The energy cost settings.
   */
  const [settings, setSettings] = useState<EnergyCostSettings>({
    calculationMode: getInitialCalculationMode(),
    startDateMode: getInitialStartDateMode(),
    energyPriceType: getInitialEnergyPriceType(),
    energyPrice: getInitialEnergyPrice()
  })

  /**
   * The energy costs of date range.
   */
  const [energyCosts, setEnergyCosts] = useState<EnergyCost[]>([])

  /**
   * The energy costs before the date range.
   */
  const [energyCostsBeforeDateRange, setEnergyCostsBeforeDateRange] = useState<
    EnergyCost[]
  >([])

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * The start date of the project.
   */
  const startDate = new Date(project.startDate)

  /**
   * The end date of the project.
   */
  const endDate = new Date(project.endDate)

  /**
   * The date range of the project.
   */
  const dateRangeOfProject = {
    from: startDate,
    to: isFuture(endDate) ? new Date() : endDate
  }

  /**
   * The used date range.
   */
  const usedDateRange = dateRange ? dateRange : dateRangeOfProject

  /**
   * The energy cost query.
   */
  const query: EnergyCostQuery = {
    from: usedDateRange.from,
    to: usedDateRange.to
  }

  /**
   * The energy cost query since before the time period.
   */
  const queryBeforeDateRange: EnergyCostQuery = {
    from: getStartDateByMode(
      usedDateRange.from,
      settings.startDateMode,
      project
    ),
    to: usedDateRange.from
  }

  /**
   * The project area as square meters.
   */
  const area: number | undefined = project?.area

  /**
   * Indicates if the start date options should be displayed.
   */
  const showStartDateModeOptions = dateRange !== undefined

  /**
   * Indicates if the selected date range is used.
   */
  const isUsingSelectedDateRange =
    settings.startDateMode === StartDateMode.SELECTED_DATE

  /**
   * Boot the component.
   */
  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (isUsingSelectedDateRange) {
        await loadEnergyCosts(signal)
        setEnergyCostsBeforeDateRange([])
      } else {
        await Promise.all([
          loadEnergyCosts(signal),
          loadEnergyCostsBeforeDateRange(signal)
        ])
      }
    } finally {
      setIsBooting(false)
    }
  }

  /**
   * Load energy costs.
   */
  async function loadEnergyCosts(signal?: AbortSignal): Promise<void> {
    setEnergyCosts(
      await getEnergyCostsOfProject(
        project.id,
        {
          from: query.from,
          to: query.to
        },
        {
          signal
        }
      )
    )
  }

  /**
   * Load energy costs before the date range.
   */
  async function loadEnergyCostsBeforeDateRange(
    signal?: AbortSignal
  ): Promise<void> {
    setEnergyCostsBeforeDateRange(
      await getEnergyCostsOfProject(
        project.id,
        {
          from: queryBeforeDateRange.from,
          to: queryBeforeDateRange.to
        },
        {
          signal
        }
      )
    )
  }

  /**
   * Change the calculation mode.
   */
  function changeCalculationMode(calculationMode: CalculationMode): void {
    const appSettings = useAppSettings()
    const projectStatsSettings = appSettings.projectStatsSettings
    setSettings({
      ...settings,
      calculationMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        projectStatsSettings: {
          ...projectStatsSettings,
          costOfElectricityCalculationMode: calculationMode
        }
      })
    )
  }

  /**
   * Change the start date mode.
   */
  function changeStartDateMode(startDateMode: StartDateMode): void {
    const appSettings = useAppSettings()
    const projectStatsSettings = appSettings.projectStatsSettings
    setSettings({
      ...settings,
      startDateMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        projectStatsSettings: {
          ...projectStatsSettings,
          costOfElectricityStartDateMode: startDateMode
        }
      })
    )
  }

  /**
   * Change the energy price mode.
   */
  function changeEnergyPriceType(energyPriceType: EnergyPriceType): void {
    setSettings({
      ...settings,
      energyPriceType
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        energyPriceType
      })
    )
  }

  /**
   * Change the energy price.
   */
  function changeEnergyPrice(energyPrice: number | null): void {
    // If the value is null, then use the fixed energy price of the project.
    if (energyPrice === null) {
      // energyPrice = project?.fixedEnergyPrice ?? null
    }

    setSettings({ ...settings, energyPrice })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        energyPrice: energyPrice
      })
    )
  }

  /**
   * Get the initial energy price type.
   */
  function getInitialEnergyPriceType(): EnergyPriceType {
    return project?.fixedEnergyPrice
      ? EnergyPriceType.FIXED
      : EnergyPriceType.SPOT
  }

  /**
   * Get the initial energy price.
   */
  function getInitialEnergyPrice(): number | null {
    return project?.fixedEnergyPrice ?? null
  }

  function getInitialCalculationMode(): number {
    return (
      appSettings.projectStatsSettings?.costOfElectricityCalculationMode ??
      CalculationMode.CUMULATIVE
    )
  }

  function getInitialStartDateMode(): StartDateMode {
    return (
      appSettings.projectStatsSettings?.costOfElectricityStartDateMode ??
      StartDateMode.SELECTED_DATE
    )
  }

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => {
      if (project?.id) {
        await boot(controller.signal)
      }
    })()
    return () => controller.abort()
  }, [project, dateRange?.from, dateRange?.to, settings.startDateMode])

  return (
    <EnergyCostStats
      energyCosts={energyCosts}
      energyCostsBeforeDateRange={energyCostsBeforeDateRange}
      area={area}
      dateRange={usedDateRange}
      settings={settings}
      query={query}
      loading={loading || isBooting}
      showStartDateModeOptions={showStartDateModeOptions}
      onChangeCalculationMode={changeCalculationMode}
      onChangeStartDateMode={changeStartDateMode}
      onChangeEnergyPrice={changeEnergyPrice}
      onChangeEnergyPriceType={changeEnergyPriceType}
    />
  )
}
