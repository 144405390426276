import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/system'
import {
  Box,
  LinearProgress as MuiLinearProgress,
  Typography
} from '@mui/material'
import styled, { keyframes } from 'styled-components/macro'

const LinearProgress = styled(MuiLinearProgress)`
  height: 12px;
  width: 100%;
  border-radius: 8px;
  background: ${(props) => props.theme.palette.grey[200]};
`

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `
}

const BlinkingComponent = styled.div`
  animation: ${blinkingEffect} 1s linear infinite;
`

export interface DevicePowerBarProps {
  phase: number
  fuse: number
}

export default function DeviceCurrentBar({ phase, fuse }: DevicePowerBarProps) {
  const [t] = useTranslation('common')
  const theme = useTheme()
  const phasePercentage: number = (phase / fuse) * 100

  const linearProgressColor = getLinearProgressColor()

  function getLinearProgressColor(): 'success' | 'warning' | 'error' {
    if (phasePercentage < 60) {
      return 'success'
    } else if (phasePercentage < 85) {
      return 'warning'
    } else {
      return 'error'
    }
  }

  return (
    <Box>
      {phasePercentage < 100 ? (
        <LinearProgress
          variant="determinate"
          value={phasePercentage}
          color={linearProgressColor}
        />
      ) : (
        <BlinkingComponent>
          <LinearProgress
            variant="determinate"
            value={100}
            color={linearProgressColor}
          />
        </BlinkingComponent>
      )}

      <Typography color={theme.palette.text.secondary} mt={1}>
        {`${t('Device.fromMax')}: ${Math.round(phasePercentage)} %`}
      </Typography>
    </Box>
  )
}
