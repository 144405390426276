import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectType } from '../enums/ProjectType'

export default function ProjectTypeSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField name="type" label={t('Projects.type')} select {...props}>
      <MenuItem value={ProjectType.KERROSTALO as string}>
        {t('Projects.block')}
      </MenuItem>
      <MenuItem value={ProjectType.OMAKOTITALO as string}>
        {t('Projects.detached')}
      </MenuItem>
      <MenuItem value={ProjectType.RIVITALO as string}>
        {t('Projects.terraced')}
      </MenuItem>
      <MenuItem value={ProjectType.LIIKETILA as string}>
        {t('Projects.business')}
      </MenuItem>
      <MenuItem value={ProjectType.INFRA as string}>
        {t('Project.infra')}
      </MenuItem>
      <MenuItem value={ProjectType.SAIRAALA as string}>
        {t('Project.hospital')}
      </MenuItem>
      <MenuItem value={ProjectType.HALLI}>{t('Project.warehouse')}</MenuItem>
      <MenuItem value={ProjectType.MUU}>{t('Project.other')}</MenuItem>
    </TextField>
  )
}
