import { useTranslation } from 'react-i18next'
import {
  Typography,
  Box,
  Card,
  CardContent,
  LinearProgress as MuiLinearProgress
} from '@mui/material'
import styled from 'styled-components/macro'
import {
  calculateKwh,
  calculateMaxKwh,
  calculatePowerUsageAsPercentage
} from '../../../utils/sensor'
import { Device, PowerSupply, Sensor } from '../../../types'

const LinearProgress = styled(MuiLinearProgress)`
  height: 30px;
  width: 100%;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`
//TODO: This causes error. Something to do with powerSupply types.
interface DevicePowerConsumptionBarProps {
  device: Device
  sensor: Sensor
}

export default function PowerConsumptionBar({
  device,
  sensor
}: DevicePowerConsumptionBarProps) {
  const [t] = useTranslation('common')

  const kw: number = sensor ? calculateKwh(sensor) : 0
  const maxKw: number = sensor
    ? //@ts-ignore
      calculateMaxKwh(device?.powerSupply?.fuse)
    : 0

  const usageAsPercentage: number = sensor
    ? calculatePowerUsageAsPercentage(
        sensor,
        device?.powerSupply as PowerSupply
      )
    : 0
  const linearProgressColor: string = getLinearProgressColor()

  function getLinearProgressColor(): string {
    if (usageAsPercentage < 60) {
      return 'success'
    } else if (usageAsPercentage < 85) {
      return 'warning'
    } else {
      return 'error'
    }
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" mb={4}>
          {`${t('Device.power')} ${kw} kW / ${maxKw} kw`}
        </Typography>
        <Typography variant="h3" mb={3}>
          <Box>
            <LinearProgress
              variant="determinate"
              value={usageAsPercentage}
              // @ts-ignore
              color={linearProgressColor}
            />
          </Box>
        </Typography>
        <span> {t('Device.fromMax')}: </span>
        {`${usageAsPercentage} % `}
      </CardContent>
    </Card>
  )
}
