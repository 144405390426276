import { differenceInMinutes } from 'date-fns'
import {
  RuuviTagAcceleration,
  RuuviTagBattery,
  RuuviTagCondition,
  Statistic
} from '../types'
import { lessThanMinutesAgo, moreThanMinutesAgo } from './date'

export function isRuuviTagOnline(date: Date): boolean {
  return Math.abs(differenceInMinutes(new Date(), date)) <= 15
}

export function formatMacAddress(macAddress: string): string {
  const matches = macAddress.match(/.{1,2}/g)

  return matches !== null ? matches.join(':').toUpperCase() : macAddress
}

/**
 * Calculate the temperature.
 *
 * @param {RuuviTagCondition} condition
 * @param {Statistic} statistic
 *
 * @returns {number} The temperature.
 */
export function calculateTemperature(
  condition: RuuviTagCondition,
  statistic: Statistic = 'avg'
): number {
  switch (statistic) {
    case 'min':
      return condition.temperatureMin
    case 'max':
      return condition.temperatureMax
    case 'avg':
    default:
      return condition.temperatureAvg
  }
}

/**
 * Calculate the humidity.
 *
 * @param {RuuviTagCondition} condition
 * @param {Statistic} statistic
 *
 * @returns {number} The humidity.
 */
export function calculateHumidity(
  condition: RuuviTagCondition,
  statistic: Statistic = 'avg'
): number {
  switch (statistic) {
    case 'min':
      return condition.humidityMin
    case 'max':
      return condition.humidityMax
    case 'avg':
    default:
      return condition.humidityAvg
  }
}

/**
 * Calcate the pressure.
 *
 * @param {RuuviTagCondition} condition
 * @param {Statistic} statistic
 *
 * @returns {number} The pressure.
 */
export function calculatePressure(
  condition: RuuviTagCondition,
  statistic: Statistic = 'avg'
): number {
  switch (statistic) {
    case 'min':
      return condition.pressureMin
    case 'max':
      return condition.pressureMax
    case 'avg':
    default:
      return condition.pressureAvg
  }
}

/**
 * Checks if the acceleration is outdated.
 *
 * @param acceleration - The acceleration.
 *
 * @returns True if the acceleration is outdated, false otherwise.
 */
export function isAccelerationOutdated(
  acceleration: RuuviTagAcceleration
): boolean {
  return moreThanMinutesAgo(new Date(acceleration.time), 60)
}

/**
 * Checks if the battery is outdated.
 *
 * @param battery - The battery.
 *
 * @returns True if the battery is outdated, false otherwise.
 */
export function isBatteryOutdated(battery: RuuviTagBattery): boolean {
  return moreThanMinutesAgo(new Date(battery.time), 60)
}

/**
 * Checks if the condition is outdated.
 *
 * @param condition - The condition.
 *
 * @returns True if the condition is outdated, false otherwise.
 */
export function isConditionOutdated(condition: RuuviTagCondition): boolean {
  return moreThanMinutesAgo(new Date(condition.time), 60)
}
