import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { AssetGridViewMode, Statistic } from '../types'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Circle } from '@mui/icons-material'

interface DeviceFiltersProps {
  /**
   * The status.
   */
  status?: boolean | null

  /**
   * The selected view mode.
   */
  viewMode?: AssetGridViewMode

  /**
   * The selected statistic.
   */
  statistic?: Statistic

  /**
   * The handler on status change.
   *
   * @param status - The changed status.
   */
  onStatusChange?: (status: boolean | null) => void

  /**
   * The handler on view mode change.
   *
   * @param viewMode - The changed view mode.
   */
  onViewModeChange?: (viewMode: AssetGridViewMode) => void

  /**
   * The handler on statistic change.
   *
   * @param statistic - The changed statistic.
   */
  onStatisticChange?: (statistic: Statistic) => void
}

export default function DeviceFilters({
  status = null,
  viewMode = 'large',
  statistic = 'avg',
  onStatusChange,
  onViewModeChange,
  onStatisticChange
}: DeviceFiltersProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Handle query status change.
   *
   * @param event - The change event.
   * @param status - The changed status.
   */
  function handleStatusChange(
    event: MouseEvent<HTMLElement>,
    status: boolean | null
  ): void {
    if (onStatusChange) {
      onStatusChange(status)
    }
  }

  /**
   * Change the view mode.
   *
   * @param event - The change event.
   */
  function handleViewModeChange(
    event: SelectChangeEvent<AssetGridViewMode>
  ): void {
    if (onViewModeChange) {
      onViewModeChange(event.target.value as AssetGridViewMode)
    }
  }

  /**
   * Change the selected statistic.
   *
   * @param event - The change event.
   */
  function handleStatisticChange(event: SelectChangeEvent<Statistic>): void {
    if (onStatisticChange) {
      onStatisticChange(event.target.value as Statistic)
    }
  }

  return (
    <Stack direction="row" gap={6}>
      <FormControl sx={{ width: 200 }} size="small">
        <InputLabel>{t('viewMode')}</InputLabel>
        <Select
          value={viewMode}
          label={t('viewMode')}
          onChange={handleViewModeChange}
        >
          <MenuItem value="large">{t('largeGrid')}</MenuItem>
          <MenuItem value="small">{t('smallGrid')}</MenuItem>
          <MenuItem value="data">{t('table')}</MenuItem>
        </Select>
      </FormControl>

      {viewMode !== 'data' && (
        <FormControl sx={{ width: 120 }} size="small">
          <InputLabel>{t('statistic')}</InputLabel>
          <Select
            value={statistic}
            label={t('statistic')}
            onChange={handleStatisticChange}
          >
            <MenuItem key="avg" value="avg">
              {t('average')}
            </MenuItem>
            <MenuItem key="min" value="min">
              {t('lowest')}
            </MenuItem>
            <MenuItem key="max" value="max">
              {t('highest')}
            </MenuItem>
          </Select>
        </FormControl>
      )}

      {viewMode !== 'data' && (
        <ToggleButtonGroup
          size="small"
          value={status}
          exclusive
          onChange={handleStatusChange}
        >
          <ToggleButton size="small" value={true}>
            <Circle color="success" fontSize="inherit" sx={{ mr: 1 }} />
            {t('online')}
          </ToggleButton>
          <ToggleButton size="small" value={false}>
            <Circle color="error" fontSize="inherit" sx={{ mr: 1 }} />
            {t('offline')}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  )
}
