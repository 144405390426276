import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ToggleButton,
  Typography,
  useMediaQuery
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import LoadingState from './LoadingState'
import { useTranslation } from 'react-i18next'
import { Project, User } from '../types'
import { UserAlertRule } from '../types/Alerts'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { useAuth } from '../hooks'
import { AlertErrors } from '../enums/AlertErrors'
import { useTheme } from '@mui/material/styles'
import {
  getUser,
  getUserAlertRulesByProject,
  setShow,
  setShowUserAlertManagementPopUp,
  setUser,
  deleteUserAlertRule
} from '../redux/slices/users'

import {
  deleteUserAlertRule as deleteUserAlertRuleService,
  toggleUserAlertRulesByProject,
  updateUserAlertRulesByProject
} from '../services/userService'

import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useNavigate } from 'react-router-dom'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import UserAlertManagementPopUp from './UserAlertManagementPopUp'
import { getProjectAlertRules } from '../redux/slices/projects'

interface UserAlertManagementTableProps {
  project?: Project
}

export default function UserAlertManagementTable({
  project
}: UserAlertManagementTableProps) {
  /**
   * The translate function
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function
   */
  const dispatch = useDispatch()

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * Is loading user alerts
   * The user's alert rules
   */
  const { loading, userAlertRules } = useSelector(
    (state: RootState) => state.users
  )

  /**
   * The theme
   */
  const theme = useTheme()

  /**
   * The mobile breakpoint
   */
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  /**
   * The user
   * The show user alertmanagement popup state
   */
  const { user, showUserAlertManagementPopUp } = useSelector(
    (state: RootState) => state.users
  )

  /**
   * The project's alertrules
   */
  const { projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  /**
   * The navigate function
   */
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser && project) {
      dispatch(getUserAlertRulesByProject(currentUser?.id, project.id))
      dispatch(getUser(parseInt(currentUser.id)))
      dispatch(getProjectAlertRules(project.id))
    }
  }, [currentUser, project])

  async function handleUpdateAlerts(data: UserAlertRule[]) {
    try {
      await updateUserAlertRulesByProject(
        parseInt(currentUser?.id),
        project?.id as number,
        data
      )
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('alertUpdateSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AlertErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  /**
   * The datagrid columns
   */
  const columns = [
    {
      field: 'alertRuleName',
      headerName: t('name'),
      width: lessThanSmall ? 150 : 350,
      editable: false
    },
    {
      field: 'notificationEnabled',
      headerName: t('emailNotifications'),
      type: 'boolean',
      width: lessThanSmall ? 150 : 350,
      editable: true
    },
    {
      field: 'appNotificationEnabled',
      headerName: t('appNotifications'),
      type: 'boolean',
      width: lessThanSmall ? 150 : 350,
      editable: true
    },
    {
      field: 'delete',
      headerName: t('delete'),
      width: 150,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        async function handleDelete() {
          const currentRow = params.row
          try {
            await deleteUserAlertRuleService(currentRow.id)
            dispatch(deleteUserAlertRule(currentRow.id))
            dispatch(
              createErrorOrSuccessNotification(
                NotificationType.SUCCESS,
                t('alertRuleDeleteSuccess')
              )
            )
          } catch (error) {
            //Create message
            const errorMessage = createUserNotification({
              user: currentUser,
              type: AlertErrors.DELETE,
              error: error
            })

            //Dispatch error message
            dispatch<unknown>(
              createErrorOrSuccessNotification(
                NotificationType.WARNING,
                t(errorMessage.key) + t(errorMessage.message)
              )
            )
          }
        }

        return (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={handleDelete}
          >
            {t('delete')}
          </Button>
        )
      }
    }
  ]

  /**
   * The datagrid rows
   */
  const rows: UserAlertRule[] = []

  /**
   * The custom rows
   */
  const [customRows, setCustomRows] = useState<UserAlertRule[]>([])

  /**
   * The enable notidications state
   */
  const [enableNotifications, setEnableNotifications] = useState<boolean>(false)

  useEffect(() => {
    if (userAlertRules) {
      createRows()
    }
  }, [userAlertRules])

  function createRows() {
    if (userAlertRules) {
      userAlertRules?.map((rule: UserAlertRule) => {
        rows.push({
          id: rule.id,
          userId: rule.id,
          projectId: rule.id,
          ruleGroupName: rule.ruleGroupName,
          alertRuleName: rule.alertRuleName,
          notificationEnabled: rule.notificationEnabled,
          appNotificationEnabled: rule.appNotificationEnabled
        })
      })
      setCustomRows(rows)
      setEnableNotifications(checkRules)
    }
  }

  function handleRowChange(row: any) {
    //Updated row
    const target = customRows.find((obj: UserAlertRule) => obj.id === row.id)
    Object.assign(target as UserAlertRule, {
      id: row.id,
      userId: target?.userId,
      projectId: target?.projectId,
      ruleGroupName: target?.ruleGroupName,
      alertRuleName: target?.alertRuleName,
      notificationEnabled: row.notificationEnabled,
      appNotificationEnabled: row.appNotificationEnabled
    })
    setRowIsNotModified(false)
  }

  function handleRowDataChange(params: any) {
    const alertId = Object.keys(params)
    const alertValues: any = Object.values(params)

    if (alertId[0] && alertValues[0]) {
      handleRowChange({
        id: parseInt(alertId[0]),
        notificationEnabled: alertValues[0].notificationEnabled.value,
        appNotificationEnabled: alertValues[0].appNotificationEnabled.value
      })
    }
  }

  /**
   * Indicatets is row modified
   */
  const [rowIsNotModiefied, setRowIsNotModified] = useState(true)

  /**
   * Indicates if there is any rules enabled for user
   */
  let enabled = false

  /**
   * The function that checks if there is any rules enabled
   * @returns
   */
  function checkRules() {
    for (let i = 0; i < userAlertRules.length; i++) {
      if (
        userAlertRules[i].notificationEnabled === true ||
        userAlertRules[i].appNotificationEnabled === true
      ) {
        enabled = true
        break
      }
    }
    return enabled
  }

  /**
   * Indicates is user toggling
   */
  const [isToggling, setIsToggling] = useState<boolean>(false)

  /**
   * Handle mute/unmute all the rules
   * @param enabled
   */
  async function handleToggleAllNotifications(enabled: boolean) {
    try {
      if (currentUser && project) {
        setIsToggling(true)

        await toggleUserAlertRulesByProject(
          parseInt(currentUser.id),
          project.id,
          {
            notificationEnabled: enabled,
            appNotificationEnabled: enabled
          }
        )

        setEnableNotifications(enabled)
        await updateAllRows(enabled)
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            enabled ? t('alertUnMuteSuccess') : t('alertMuteSuccess')
          )
        )
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AlertErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsToggling(false)
    }
  }

  function handleNavigation(id: number) {
    dispatch(setShow(true))
    navigate(`/users/${id}`)
  }

  //Alert management
  function handleClickOpenUserAlertManagement(user: User) {
    dispatch(getUserAlertRulesByProject(user.id, project?.id as number))
    dispatch(setUser(user))
    dispatch(setShowUserAlertManagementPopUp(true))
  }

  function handleCloseUserAlertManagement() {
    dispatch(setShowUserAlertManagementPopUp(false))
  }

  async function updateAllRows(enabled: boolean) {
    customRows.map(
      (rule) => (
        (rule.appNotificationEnabled = enabled),
        (rule.notificationEnabled = enabled)
      )
    )

    setCustomRows(customRows)
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid xs={6}>
          <Typography sx={{ mt: 10, mb: 4, ml: 6 }} variant="h4">
            {t('yourAlertsForThisProject')}
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Typography align="right" sx={{ mt: 10, mb: 4, mr: 6 }}>
            <NotificationAddIcon
              style={{ cursor: 'pointer' }}
              onClick={() => handleClickOpenUserAlertManagement(user as User)}
            />
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        open={showUserAlertManagementPopUp}
        onClose={handleCloseUserAlertManagement}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogContent>
          <UserAlertManagementPopUp
            user={user as any}
            projectId={project?.id as number}
            projectAlertRules={projectAlertRules}
            loading={loading}
            userAlertRules={userAlertRules}
            updatingOwnSettings={true}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseUserAlertManagement}
            autoFocus
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        {loading || isToggling ? (
          <LoadingState />
        ) : (
          <React.Fragment>
            {user?.notificationsByEmail === false && (
              <Alert severity="warning">
                {t('emailsDisabledWarning')}{' '}
                <Button
                  variant="outlined"
                  onClick={() => handleNavigation(user.id)}
                >
                  {t('accountSettings')}
                </Button>
              </Alert>
            )}
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Typography align="left">
                  <ToggleButton
                    sx={{ mt: 4, mb: 4, ml: 4 }}
                    value="mute"
                    selected={enableNotifications}
                    onChange={() => {
                      handleToggleAllNotifications(!enableNotifications)
                    }}
                  >
                    {enableNotifications ? (
                      <NotificationsIcon />
                    ) : (
                      <NotificationsOffIcon />
                    )}
                  </ToggleButton>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right">
                  <Button
                    disabled={rowIsNotModiefied}
                    sx={{ mt: 4, mb: 4, mr: 4 }}
                    variant="contained"
                    onClick={() => handleUpdateAlerts(customRows)}
                  >
                    {t('save')}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <DataGrid
              rows={customRows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              editMode="row"
              onEditRowsModelChange={(params: any) =>
                handleRowDataChange(params)
              }
              disableColumnMenu
              autoHeight
            />
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  )
}
