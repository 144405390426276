import * as React from 'react'
import styled from 'styled-components/macro'
import { Power } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { Tooltip, IconButton as MuiIconButton } from '@mui/material'

import useAuth from '../../hooks/useAuth'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

// eslint-disable-next-line
function NavbarSignOutButton() {
  // eslint-disable-next-line
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null)
  const navigate = useNavigate()
  const { signOut } = useAuth()

  const handleSignOut = async () => {
    localStorage.removeItem('closedAnnouncements')
    await signOut()
    navigate('/auth/sign-in')
  }

  return (
    <React.Fragment>
      <Tooltip title="Sign Out">
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={() => handleSignOut()}
          color="inherit"
          size="large"
        >
          <Power data-cy="power" />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export default NavbarSignOutButton
