import { ReactElement } from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import {
  RuuviTag,
  RuuviTagBattery,
  RuuviTagCondition,
  Statistic
} from '../types'
import { humanizeTimestamp } from '../utils/date'
import RuuviTagBatteryIndicator from './RuuviTagBatteryIndicator'
import RuuviTagSignalIndicator from './RuuviTagSignalIndicator'

interface RuuviTagStatsTooltipProps {
  /**
   * The ruuvi tag.
   */
  ruuviTag: RuuviTag

  /**
   * The condition.
   */
  condition: RuuviTagCondition

  /**
   * The battery.
   */
  battery?: RuuviTagBattery | null

  /**
   * The statistic.
   */
  statistic?: Statistic

  /**
   * The child nodes.
   */
  children: ReactElement
}

export default function RuuviTagStatsTooltip({
  ruuviTag,
  condition,
  battery,
  statistic = 'avg',
  children
}: RuuviTagStatsTooltipProps): JSX.Element {
  /**
   * Calculate the temperature.
   *
   * @returns The temperature.
   */
  function calculateTemperature(): number {
    switch (statistic) {
      case 'min':
        return condition.temperatureMin
      case 'max':
        return condition.temperatureMax
      case 'avg':
      default:
        return condition.temperatureAvg
    }
  }

  /**
   * Calculate the humidity.
   *
   * @returns The humidity.
   */
  function calculateHumidity(): number {
    switch (statistic) {
      case 'min':
        return condition.humidityMin
      case 'max':
        return condition.humidityMax
      case 'avg':
      default:
        return condition.humidityAvg
    }
  }

  /**
   * Calculate the pressure.
   *
   * @returns {number} The pressure.
   */
  function calculatePressure(): number {
    switch (statistic) {
      case 'min':
        return condition.pressureMin
      case 'max':
        return condition.pressureMax
      case 'avg':
      default:
        return condition.pressureAvg
    }
  }

  return (
    <Tooltip
      title={
        <Stack direction="column" gap={1}>
          <Box>{humanizeTimestamp(condition.time)}</Box>
          <Stack direction="row" alignItems="center" gap={2}>
            {ruuviTag.has_temperature_sensor && (
              <Box>{Math.round(calculateTemperature())}°C</Box>
            )}

            {ruuviTag.has_humidity_sensor && (
              <Box>{Math.round(calculateHumidity())}%</Box>
            )}

            {ruuviTag.has_pressure_sensor && (
              <Box>{Math.round(calculatePressure())}hPa</Box>
            )}

            {battery && (
              <RuuviTagBatteryIndicator
                battery={battery}
                condition={condition}
                rotationAngle={90}
              />
            )}

            <RuuviTagSignalIndicator condition={condition} />
          </Stack>
        </Stack>
      }
    >
      {children}
    </Tooltip>
  )
}
