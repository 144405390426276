import styled from 'styled-components/macro'

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string
  href?: string
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`

// eslint-disable-next-line
function Footer() {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          sx={{ display: { xs: 'none', md: 'block' } }}
          container
          item
          xs={12}
          md={6}
        >
          <List>
            <ListItemButton component={NavLink} to="/user-manual">
              <ListItemText primary={t('userManual')} />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/privacy-policy">
              <ListItemText primary={t('privacyPolicy')} />
            </ListItemButton>
            <ListItemButton component={NavLink} to="/terms-of-service">
              <ListItemText primary={t('termsOfService')} />
            </ListItemButton>
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemButton>
              <ListItemText primary={`© ${new Date().getFullYear()} - Spine`} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default Footer
