import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { UserRole } from '../enums'
import ConfirmRoleChanges from '../pages/pages/ConfirmRoleChanges'

export default [
  {
    path: '/confirm-role/:token',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.GUEST
        ]}
      >
        <DashboardLayout>
          <ConfirmRoleChanges />
        </DashboardLayout>
      </AuthGuard>
    )
  }
]
