import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLevels } from '../hooks'

export default function LevelSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')
  const levels = useLevels()

  return (
    <TextField
      select
      name="level"
      date-testid="level"
      label={t('level')}
      {...props}
    >
      {levels.map((level) => (
        <MenuItem key={level.id} value={level.id}>
          {level.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
