import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import useAuth from '../../hooks/useAuth'
import {
  Card as MuiCard,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CardContent,
  Paper,
  Stack,
  IconButton,
  Chip,
  Link
} from '@mui/material'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {
  deleteAnnouncement,
  getAnnouncements,
  hideAnnouncement,
  showAnnouncement
} from '../../redux/slices/announcements'
import { Announcement, AnnouncementCustomData } from '../../types'
import { RootState } from '../../redux/store'
import announcementService from '../../services/announcementService'
import AnnouncementForm from '../../components/AnnouncementForm'
import LoadingState from '../../components/LoadingState'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { AnnouncementErrors } from '../../enums/AnnouncementErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import MessageIcon from '@mui/icons-material/Message'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'
import LinkIcon from '@mui/icons-material/Link'

const Divider = styled(MuiDivider)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Card = styled(MuiCard)(spacing)

// eslint-disable-next-line
export default function Announcements() {
  /**
   * The dispatch function
   */
  const dispatch = useDispatch()

  /**
   * The translaton function
   */
  const [t] = useTranslation('common')

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * The redux states
   */
  const { announcements, announcement, show, loading } = useSelector(
    (state: RootState) => state.announcements
  )

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  /**
   * Open announcement message
   */
  const [openMessage, setOpenMessage] = useState(false)

  useEffect(() => {
    if (currentUser) {
      dispatch(getAnnouncements())
    }
  }, [currentUser])

  const title = !show
    ? t('announcements')
    : announcement === undefined
    ? t('newAnnouncement')
    : t('editAnnouncement')

  function handleAdd() {
    dispatch(showAnnouncement(undefined))
  }

  function handleEdit(announcement: Announcement) {
    dispatch(showAnnouncement(announcement))
  }

  function handleCancel(): void {
    dispatch(hideAnnouncement())
  }

  async function handleDelete(announcement: Announcement) {
    try {
      await announcementService.deleteAnnouncement(announcement.id)
      dispatch(deleteAnnouncement(announcement.id))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('announcementDeleteSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AnnouncementErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setOpenInfo(false)
    }
  }

  /**
   * Open delete info
   */
  const [openInfo, setOpenInfo] = useState<boolean>(false)

  function handleCloseInfo() {
    setOpenInfo(false)
  }

  /**
   * The selected announcement message
   */
  const [selectedMessage, setSelectedMessage] = useState<string>('')

  /**
   * The selected announcement
   */
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<
    Announcement | undefined
  >(undefined)

  function handleOpenMessage(message: string) {
    setSelectedMessage(message)
    setOpenMessage(true)
  }

  function handleCloseMessage() {
    setSelectedMessage('')
    setOpenMessage(false)
  }

  function handleOpenDeleteInfo(announcement: Announcement) {
    setSelectedAnnouncement(announcement)
    setOpenInfo(true)
  }

  /**
   * The selected announcement url info
   */
  const [selectedUrlData, setSelectedUrlData] = useState<
    AnnouncementCustomData | undefined
  >(undefined)

  /**
   * Open delete info
   */
  const [openUrlInfo, setOpenUrlInfo] = useState<boolean>(false)

  function handleOpenUrlInfo(customData: AnnouncementCustomData) {
    setSelectedUrlData(customData)
    setOpenUrlInfo(true)
  }

  function handleCloseUrlInfo() {
    setSelectedUrlData(undefined)
    setOpenUrlInfo(false)
  }

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      minWidth: 100,
      editable: false
    },
    {
      field: 'message',
      headerName: t('message'),
      flex: 1,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              size="small"
              onClick={() => handleOpenMessage(params.row.message)}
            >
              <MessageIcon />
            </IconButton>

            <Dialog
              open={openMessage}
              onClose={handleCloseMessage}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{t('message')}:</DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedMessage}
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleCloseMessage}
                  autoFocus
                >
                  {t('close')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
    },
    {
      field: 'customData',
      headerName: 'URL',
      flex: 1,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <>
            {Object.keys(params.row.customData).length > 0 &&
            params.row.customData.url !== '' &&
            params.row.customData.urlName !== '' ? (
              <>
                <IconButton
                  size="small"
                  onClick={() => handleOpenUrlInfo(params.row.customData)}
                >
                  <LinkIcon />
                </IconButton>

                <Dialog
                  open={openUrlInfo}
                  onClose={handleCloseUrlInfo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {selectedUrlData?.urlName}
                  </DialogTitle>

                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {selectedUrlData?.url}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={handleCloseUrlInfo}
                      autoFocus
                    >
                      {t('close')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : null}
          </>
        )
      }
    },
    {
      field: 'severity',
      headerName: t('severity'),
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Chip
            label={params.row?.severity.replace(
              /^./,
              params.row?.severity[0].toUpperCase()
            )}
            size="small"
            color={params.row?.severity}
          />
        )
      }
    },
    {
      field: 'showAt',
      headerName: t('showAt'),
      flex: 1,
      minWidth: 100,
      editable: false,
      valueGetter: (params: any) =>
        new Date(params.row?.showAt).toLocaleString()
    },
    {
      field: 'hideAt',
      headerName: t('hideAt'),
      flex: 1,
      minWidth: 100,
      editable: false,
      valueGetter: (params: any) =>
        new Date(params.row?.hideAt).toLocaleString()
    },

    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 250,
      renderCell: (params: any) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexGrow={0}
              spacing={2}
            >
              <IconButton
                size="small"
                data-testid="edit"
                onClick={() => handleEdit(params.row)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
                data-testid="delete"
                onClick={() => handleOpenDeleteInfo(params.row)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Stack>

            <Dialog
              open={openInfo}
              onClose={handleCloseInfo}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t('confirmDeleteAnnouncement')}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('confirmDeleteInfo')}
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() =>
                    handleDelete(selectedAnnouncement as Announcement)
                  }
                  autoFocus
                >
                  {t('yes')}
                </Button>
                <Button variant="contained" onClick={handleCloseInfo} autoFocus>
                  {t('no')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <Helmet title="Announcements" />
      <Typography variant="h3" gutterBottom display="inline">
        {t('announcements')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('frontpage')}
        </Link>
        <Typography>{t('announcements')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Card mb={6}>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="h6" my={4}>
                    {title}
                  </Typography>
                </Grid>
                {!show ? (
                  <Grid item xs={6}>
                    <Typography align="right" my={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAdd()}
                        endIcon={<AddIcon />}
                      >
                        {t('newAnnouncement')}
                      </Button>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Typography align="right" my={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCancel()}
                      >
                        {t('cancel')}
                      </Button>
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Paper>
                {show ? (
                  <AnnouncementForm />
                ) : (
                  <React.Fragment>
                    <DataGrid
                      rows={announcements}
                      columns={columns}
                      autoHeight
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      disableSelectionOnClick
                      components={{
                        Toolbar: GridToolbar
                      }}
                      componentsProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: true },
                          printOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 250 }
                        }
                      }}
                    />
                  </React.Fragment>
                )}
              </Paper>
            </CardContent>
          </>
        )}
      </Card>
    </React.Fragment>
  )
}
