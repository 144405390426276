import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  Paper,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link,
  Divider as MuiDivider
} from '@mui/material'
import SelectProjectForm from '../../components/installation/SelectProjectForm'
import InstallDeviceToProject from '../../components/installation/InstallDeviceToProject'
import { spacing } from '@mui/system'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

const Divider = styled(MuiDivider)(spacing)

export default function Installation() {
  const [t] = useTranslation('common')

  /**
   * Selected project
   */
  const [selectedProject, setSelectedProject] = useState<number | undefined>(
    undefined
  )
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom display="inline">
        {t('installation')}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t('frontpage')}
        </Link>
        <Typography> {t('installation')}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {selectedProject ? (
        <InstallDeviceToProject
          projectId={selectedProject}
          handleBackStep={() => {
            setSelectedProject(undefined)
          }}
        />
      ) : (
        <SelectProjectForm
          projectSelected={(project) => {
            setSelectedProject(project)
          }}
        />
      )}
    </React.Fragment>
  )
}
