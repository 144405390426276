export const scrollbarForLight = {
  '&::-webkit-scrollbar': {
    width: 10,
    height: 15
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D3D3D3'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#376fd0',
    borderRadius: 2
  }
}

export const scrollbarForDark = {
  '&::-webkit-scrollbar': {
    width: 20,
    height: 15
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D3D3D3'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#A9A9A9',
    borderRadius: 2
  }
}
