import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  CardHeader,
  IconButton,
  Paper,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  DialogContentText
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import useAuth from '../hooks/useAuth'
import { ActionRequest } from '../types'
import LoadingState from './LoadingState'
import { deleteRequest, updateRequestState } from '../services/requestService'
import { RequestStatus } from '../enums/RequestStatus'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { RequestAccessErrors } from '../enums/RequestAccessErrors'
import {
  deleteCompanyRequest,
  deleteProjectRequest,
  deleteSystemRequest
} from '../redux/slices/requests'
import { ActionRequestType } from '../enums/RequestAccessType'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { RequesterType } from '../enums/RequesterType'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

const Card = styled(MuiCard)(spacing)

interface RequestTableProps {
  requests: ActionRequest[]
  loading: boolean
}

function RequestTable({ requests, loading }: RequestTableProps) {
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [isDeleteInfoOpen, setIsDeleteInfoOpen] = useState(false)
  const [isApproveInfoOpen, setIsApproveInfoOpen] = useState(false)

  const [newEndDate, setNewEndDate] = useState<Date | null>(null)

  async function approveSystemRequest(request: ActionRequest) {
    try {
      const updatedRequest = await updateRequestState(request.id, {
        status: RequestStatus.APPROVED,
        approver: request.user?.id.toString() ?? ''
      })

      dispatch(deleteSystemRequest(updatedRequest.id))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('requestApproveSuccess')
        )
      )
    } catch (error) {
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.UPDATE,
        error: error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsApproveInfoOpen(false)
    }
  }

  async function rejectSystemRequest(
    request: ActionRequest,
    newEndDate: Date | null
  ) {
    if (newEndDate == null) {
      return
    }
    try {
      const updated = await updateRequestState(request.id, {
        status: RequestStatus.REJECTED,
        approver: request.user?.id.toString() ?? '',

        newEndDate: newEndDate.toISOString()
      })

      dispatch(deleteSystemRequest(updated.id))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('requestRejectSuccess')
        )
      )
    } catch (error) {
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.DELETE,
        error: error
      })

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsDeleteInfoOpen(false)
    }
  }

  const requestValues = {
    approver: currentUser?.firstName + ' ' + currentUser?.lastName,
    status: RequestStatus.APPROVED,
    userId: ''
  }

  async function handleReject(request: ActionRequest) {
    try {
      if (request.requesterType === RequesterType.SYSTEM) {
        setIsDeleteInfoOpen(true)
        return
      }
      await deleteRequest(request.id)
      request.requestableType === ActionRequestType.COMPANY
        ? dispatch(deleteCompanyRequest(request.id))
        : dispatch(deleteProjectRequest(request.id))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('requestRejectSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  async function handleApprove(request: ActionRequest) {
    try {
      if (request.requesterType === RequesterType.SYSTEM) {
        setIsApproveInfoOpen(true)
        return
      }
      setIsSubmitting(true)
      request.user ? (requestValues.userId = request.user.id.toString()) : ''
      const updatedRequest = await updateRequestState(request.id, requestValues)

      updatedRequest.requestableType === ActionRequestType.COMPANY
        ? dispatch(deleteCompanyRequest(updatedRequest.id))
        : dispatch(deleteProjectRequest(updatedRequest.id))

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('requestApproveSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.UPDATE,
        error: error
      })

      //Dispatch error message
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const columns = [
    {
      field: 'message',
      headerName: t('message'),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200
    },
    {
      field: 'user.firstName',
      headerName: t('firstName'),
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params: any) => params.row.user?.firstName
    },
    {
      field: 'user.lastName',
      headerName: t('lastName'),
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params: any) => params.row.user?.lastName
    },
    {
      field: 'user.phone',
      headerName: t('phone'),
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params: any) => params.row.user?.phone
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Dialog
              open={isApproveInfoOpen}
              onClose={() => setIsApproveInfoOpen(false)}
              maxWidth="sm"
            >
              <DialogTitle>{t('systemRequest.endProject')}</DialogTitle>

              <DialogActions>
                <Button onClick={() => setIsApproveInfoOpen(false)}>
                  {t('cancel')}
                </Button>
                <Button onClick={() => approveSystemRequest(params.row)}>
                  {t('yes')}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={isDeleteInfoOpen}
              onClose={() => setIsDeleteInfoOpen(false)}
              maxWidth="sm"
            >
              <DialogTitle>{t('systemRequest.extendProject')}</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  {t('systemRequest.selectEndDate')}
                </DialogContentText>
                <LocalizationProvider
                  // @ts-expect-error
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={newEndDate}
                    onChange={(newValue) => setNewEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setIsDeleteInfoOpen(false)}>
                  {t('cancel')}
                </Button>
                <Button
                  onClick={() => rejectSystemRequest(params.row, newEndDate)}
                >
                  {t('yes')}
                </Button>
              </DialogActions>
            </Dialog>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexGrow={0}
              spacing={2}
            >
              <IconButton
                aria-label="Accept"
                onClick={() => {
                  handleApprove(params.row)
                }}
              >
                <CheckBoxIcon color="success" />
              </IconButton>
              /
              <IconButton
                onClick={() => {
                  handleReject(params.row)
                }}
              >
                <DisabledByDefaultIcon color="error" />
              </IconButton>
            </Stack>
          </>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Card mb={6}>
            <CardHeader title={t('Request.requests')} />
            {loading || isSubmitting ? (
              <LoadingState />
            ) : (
              <Paper>
                <DataGrid
                  rows={requests}
                  columns={columns}
                  autoHeight
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  disableSelectionOnClick
                  components={{
                    Toolbar: GridToolbar
                  }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 }
                    }
                  }}
                />
              </Paper>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default RequestTable
