import React, { useState } from 'react'
import {
  Button,
  Grid,
  Typography,
  CardHeader,
  Card as MuiCard,
  Alert,
  AlertColor
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import UploadType from '../../enums/UploadType'
import { RootState } from '../../redux/store'
import uploadService from '../../services/uploadService'
import { deleteError, setError } from '../../redux/slices/errors'
import { UploadErrors } from '../../enums/UploadErrors'
import { useAuth } from '../../hooks'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { createUpload } from '../../redux/slices/uploads'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import UploadTable from './UploadTable'

const Card = styled(MuiCard)(spacing)

export default function UploadPictureForm({ device }: any) {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const shortUUID = device.shortUUID
  const [uploadNumber, setUploadNumber] = useState(1)
  const { currentUser } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleUpload = async (event: any) => {
    try {
      setIsSubmitting(true)
      event.preventDefault()
      const file = event.currentTarget['fileInput'].files[0]
      const name = `${UploadType.QA}/${shortUUID as string}-${uploadNumber}`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', name)
      setUploadNumber(uploadNumber + 1)

      //POST
      const uploadedFile = await uploadService.postUpload(formData)
      dispatch(createUpload(uploadedFile))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('uploadCreateSuccess')
        )
      )
      // @ts-ignore
      document.getElementById('fileInput').value = ''
    } catch (error: unknown) {
      dispatch(
        setError({
          type: UploadErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UploadErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(UploadErrors.CREATE))
    }
  }
  const { notification } = useSelector(
    (state: RootState) => state.notifications
  )
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardHeader
              title={t('Manufacturing.savePictureofPreparedProduct')}
              align="center"
            />
            {notification?.show === true && isSubmitting === true ? (
              <Grid style={{ padding: '1rem' }}>
                <Alert severity={notification.type as AlertColor}>
                  {notification.message}
                </Alert>
              </Grid>
            ) : null}
            <form
              onSubmit={handleUpload}
              encType="multipart/form-data"
              id="upload"
            >
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <input id="fileInput" type="file" required />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="right">
                    <Button type="submit" variant="contained">
                      {t('Manufacturing.save')}
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </form>
            <UploadTable uploadType={UploadType.QA} />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
