import { useEffect, memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControlLabel,
  Switch,
  useMediaQuery
} from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { Add } from '@mui/icons-material'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useNavigate } from 'react-router-dom'
import { Blueprint } from '../types/Blueprint'
import { getBlueprintService } from '../services/uploadService'
import LoadingState from '../components/LoadingState'
import React from 'react'
import { ProjectTab } from '../pages/pages/Project'
import { ActiveProject, Project, RuuviTag, Sector } from '../types'
import {
  getLevelsByBlueprints,
  getProjectAlertRules
} from '../services/projectService'
import PowerSupplyHierarchy from './PowerSupplyHierarchy'
import RuuviTagHierarchy from './RuuviTagHierarchy'
import Carousel from 'react-material-ui-carousel'
import { BlueprintAssetType } from './BlueprintCanvas'
import useAppSettings from '../hooks/useAppSettings'
import { AlertGroup } from '../types/Alerts'
import { useTheme } from '@mui/system'

interface PowerSupplyHierarchyProps {
  height?: string
  project: Project | ActiveProject
  loading?: boolean
  userCanManageProject: boolean
  ruuviTags: RuuviTag[]
}

const AssetHierarchyWrapper = memo(function ({
  height = '60vh',
  project,
  userCanManageProject,
  ruuviTags,
  loading = false
}: PowerSupplyHierarchyProps) {
  const [t] = useTranslation('common')

  const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(false)
  const navigate = useNavigate()

  function TableWrapper({ children }: { children: any }) {
    const Wrapper = styled.div`
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow-x: hidden;
      oveflow-y: auto;
      width: 100%;
      height: ${isFullScreenMode ? 'calc(100vh - 80px)' : height};
      padding: 0;
      position: relative;
    `

    const Container = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border: 1px solid rgba(81, 81, 81, 1);
      border-radius: 2px;
    `

    return (
      <Wrapper>
        <Container>{children}</Container>
      </Wrapper>
    )
  }

  /**
   * The styles of the card component.
   */
  const cardStyle = isFullScreenMode
    ? {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        borderRadius: 0,
        zIndex: 2147483647
      }
    : {}

  const hash: string = location.hash.substr(1)
  const [filter, setFilter] = useState<string[]>([])
  const handleChange = (event: SelectChangeEvent<typeof filter>) => {
    const {
      target: { value }
    } = event
    setFilter(typeof value === 'string' ? value.split(',') : value)
  }

  const sectors: Sector[] = useSelector(
    (state: RootState) => state.sectors.sectors
  )

  const [assetType, setAssetType] = useState<BlueprintAssetType>(
    BlueprintAssetType.POWERSUPPLY
  )

  const [listOfLevelIds, setListOfLevelIds] = useState<string[]>([])
  const [areaUpload, setAreaUpload] = useState<Blueprint | undefined>(undefined)
  const [isLoadingListOfLevels, setIsLoadingListOfLevels] =
    useState<boolean>(false)
  const [isLoadingAreaUpload, setIsLoadingAreaUpload] = useState<boolean>(false)
  const [alertRules, setAlertRules] = useState<AlertGroup[]>([])
  const [isLoadingAlertRules, setIsLoadingAlertRules] = useState<boolean>(false)

  //Get level ids from the project blueprints
  useEffect(() => {
    loadAlertRules()
    if (project) {
      if (
        project.customData &&
        project.customData.blueprintAdminOnly === true &&
        userCanManageProject
      ) {
        loadLevelBlueprints(project.id)
        loadAreaBlueprint(project.id)
      } else if (
        project.customData.blueprintAdminOnly === false ||
        Object.keys(project.customData).length === 0
      ) {
        loadLevelBlueprints(project.id)
        loadAreaBlueprint(project.id)
      }
    }
  }, [project])

  async function loadLevelBlueprints(project: number): Promise<void> {
    try {
      setIsLoadingListOfLevels(true)
      setListOfLevelIds(await getLevelsByBlueprints(project))
    } finally {
      setIsLoadingListOfLevels(false)
    }
  }

  async function loadAreaBlueprint(project: number): Promise<void> {
    try {
      setIsLoadingAreaUpload(true)
      setAreaUpload(await getBlueprintService({ project: project }))
    } finally {
      setIsLoadingAreaUpload(false)
    }
  }

  async function loadAlertRules(): Promise<void> {
    try {
      if (project) {
        setIsLoadingAlertRules(true)
        const alertRuleResponse = await getProjectAlertRules(project.id)
        setAlertRules(alertRuleResponse)
      }
    } finally {
      setIsLoadingAlertRules(false)
    }
  }

  function handleHierarchyChange(slideNumber: number) {
    switch (slideNumber) {
      case 0:
        return BlueprintAssetType.POWERSUPPLY
      case 1:
        return BlueprintAssetType.RUUVITAG
      default:
        return BlueprintAssetType.POWERSUPPLY
    }
  }

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  const [autoPlayCarousel, setAutoPlayCarousel] = useState<boolean>(false)

  function handleChangeZoom(zoom: number) {
    const hierarchySettings = appSettings.hierarchySettings
    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        hierarchySettings: {
          ...hierarchySettings,
          hierarchyZoom: zoom
        }
      })
    )
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Typography variant="h6">{t(assetType) as string}</Typography>

          <Typography>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => setAutoPlayCarousel(e.target.checked)}
                  size="small"
                />
              }
              label={t('autoChangeView') as string}
            />
          </Typography>

          <FormControl sx={{ m: 1, width: isMobile ? 200 : 300 }} size="small">
            <InputLabel>{t('sectors')}</InputLabel>
            <Select
              multiple
              value={filter}
              onChange={handleChange}
              input={<OutlinedInput label={t('sectors')} />}
              renderValue={(selected) => selected.join(', ')}
            >
              {sectors.map((sector) => (
                <MenuItem key={sector.id} value={sector.name}>
                  <Checkbox checked={filter.indexOf(sector.name) > -1} />
                  <ListItemText primary={sector.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isFullScreenMode ? (
            <IconButton
              size="small"
              onClick={(event) => setIsFullScreenMode(false)}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={(event) => setIsFullScreenMode(true)}
            >
              <FullscreenIcon />
            </IconButton>
          )}
        </Stack>
        {loading ||
        isLoadingAreaUpload ||
        isLoadingListOfLevels ||
        isLoadingAlertRules ? (
          <LoadingState />
        ) : (
          <Carousel
            autoPlay={autoPlayCarousel}
            stopAutoPlayOnHover={false}
            interval={30000}
            swipe={false}
            IndicatorIcon={[
              <Button
                size="small"
                variant="outlined"
                onClick={() => setAssetType(BlueprintAssetType.POWERSUPPLY)}
              >
                {t('powerSupplies')}
              </Button>,
              <Button
                size="small"
                variant="outlined"
                onClick={() => setAssetType(BlueprintAssetType.RUUVITAG)}
              >
                {t('ruuviTags')}
              </Button>
            ]}
            next={(next, active) =>
              //@ts-ignore
              setAssetType(handleHierarchyChange(next))
            }
            prev={(prev, active) =>
              //@ts-ignore
              setAssetType(handleHierarchyChange(prev))
            }
            height={isFullScreenMode ? 'calc(100vh - 80px)' : '60vh'}
          >
            {/**PowerSupply hierarchy */}
            <TableWrapper>
              <TransformWrapper
                initialScale={appSettings.hierarchySettings?.hierarchyZoom ?? 1}
                minScale={0.25}
                centerZoomedOut={true}
                disablePadding={true}
                limitToBounds={true}
                onWheelStop={(e) => handleChangeZoom(e.state.scale)}
              >
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute'
                  }}
                >
                  <PowerSupplyHierarchy
                    project={project}
                    userCanManageProject={userCanManageProject}
                    listOfLevelIds={listOfLevelIds}
                    areaUpload={areaUpload}
                    filter={filter}
                    alertRules={alertRules}
                  />
                </TransformComponent>
              </TransformWrapper>
            </TableWrapper>
            {/**RuuviTag hierarchy */}
            <TableWrapper>
              <TransformWrapper
                initialScale={appSettings.hierarchySettings?.hierarchyZoom ?? 1}
                minScale={0.25}
                centerZoomedOut={true}
                disablePadding={true}
                limitToBounds={true}
                onWheelStop={(e) => handleChangeZoom(e.state.scale)}
              >
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute'
                  }}
                >
                  <RuuviTagHierarchy
                    project={project}
                    userCanManageProject={userCanManageProject}
                    ruuviTags={ruuviTags}
                    listOfLevelIds={listOfLevelIds}
                    areaUpload={areaUpload}
                    filter={filter}
                  />
                </TransformComponent>
              </TransformWrapper>
            </TableWrapper>
          </Carousel>
        )}
      </CardContent>
      {userCanManageProject && hash !== ProjectTab.POWER_SUPPLIES && (
        <React.Fragment>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Typography ml={4} variant="h6" align="right">
              <Button
                size="small"
                variant="contained"
                onClick={() => navigate(`/projects/${project.id}/#levels`)}
              >
                {t('addFloorPlan')}
                <Add />
              </Button>
            </Typography>
          </Stack>
        </React.Fragment>
      )}
    </Card>
  )
})

export default AssetHierarchyWrapper
