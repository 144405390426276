import React from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  Typography,
  MenuItem
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { sendEmailWithCompanyRole } from '../redux/slices/companies'
import { UserRole } from '../enums/UserRole'
import { Company } from '../types'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  role: Yup.string().required('Required')
})

export interface UserRoleManagementProps {
  company?: Company
}
// eslint-disable-next-line
export default function UserRoleManagement({
  company
}: UserRoleManagementProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const initialValues = {
    email: '',
    companyId: company?.id,
    role: ''
  }
  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      dispatch(sendEmailWithCompanyRole(values))

      resetForm()
      //navigate('/auth/sign-in')
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <Card mb={6}>
            <CardContent>
              <Grid justifyContent="center" alignItems={'center'} container>
                <Typography variant="h6" gutterBottom>
                  Welcome to the users management page. On this page you can
                  manage the roles of your company's users.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Start by giving user's email and select the role you want to
                  give to the user.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  If user's role is upgraded, email is sent to user that
                  contains link to the page where user confirms this change.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  If role is downgraded user is only notified with email but
                  cofirmation is not needed.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  If there is not yet an account created with the email you
                  gave, email contais link to the sign up page.
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl>
                    <TextField
                      name="email"
                      label={t('Users.email')}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl>
                    <TextField
                      name="role"
                      label={t('Users.role')}
                      select
                      value={values.role}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    >
                      <MenuItem value={UserRole.ADMIN}>Company Admin</MenuItem>
                      <MenuItem value={UserRole.USER}>User</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  {t('Users.send')}
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </React.Fragment>
  )
}
