import { createSlice } from '@reduxjs/toolkit'
import projectService from '../../services/projectService'
import { Dispatch } from 'redux'
import {
  createNotification,
  createNotificationForManufacturingEnvironment
} from './notifications'
import { AlertGroup } from '../../types/Alerts'
import { ActiveProject, Project } from '../../types'
import { ProjectReducerType } from '../../enums/ProjectReducerType'
import ProjectQuery from '../../types/ProjectQuery'

interface ProjectState {
  activeProject: ActiveProject | undefined
  activeProjects: ActiveProject[]
  projects: Project[]
  project: Project | undefined
  projectAlertRules: AlertGroup[]
  projectSensors: Record<string, never>
  show: boolean
  status: 'idle' | 'loading' | 'failed'
  loading: boolean
}

export interface LatLong {
  latitude: string
  longitude: string
}

export interface AddressAndCity {
  address: string
  city: string
}

const initialState: ProjectState = {
  activeProject: undefined,
  activeProjects: [],
  projects: [],
  project: undefined,
  projectAlertRules: [],
  projectSensors: {},
  show: false,
  status: 'idle',
  loading: false
}

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setActiveProject(state, action) {
      state.activeProject = action.payload
    },
    setActiveProjects(state, action) {
      state.activeProjects = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setProjects(state, action) {
      state.projects = action.payload
    },
    setProject(state, action) {
      state.project = action.payload
    },
    setSensors(state, action) {
      state.projectSensors = action.payload
    },
    createProject(state, action) {
      state.projects = [...state.projects, action.payload]
    },
    updateProject(state, action) {
      state.projects = state.projects
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
      state.project = { ...state.project, ...action.payload } as Project
    },
    deleteProject(state, action) {
      state.projects = state.projects.filter((u) => u.id !== action.payload)
    },
    setShow(state, action) {
      state.show = action.payload
    },
    setProjectAlertRules(state, action) {
      state.projectAlertRules = action.payload
    }
  }
})

export const {
  setProjects,
  setProject,
  setSensors,
  setLoading,
  setShow,
  createProject,
  updateProject,
  setProjectAlertRules,
  deleteProject
} = projectSlice.actions

export function getProjects(query?: ProjectQuery) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectSlice.actions.setLoading(true))
      const response = await projectService.getProjects(query)
      dispatch(projectSlice.actions.setProjects(response))
    } finally {
      dispatch(projectSlice.actions.setLoading(false))
    }
  }
}

export function getActiveProjects() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectSlice.actions.setLoading(true))
      const projects = await projectService.getActiveProjects()
      dispatch(projectSlice.actions.setActiveProjects(projects))
    } finally {
      dispatch(projectSlice.actions.setLoading(false))
    }
  }
}

export function getMyProjects(projectReducerType: ProjectReducerType) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectSlice.actions.setLoading(true))
      const response = await projectService.getMyProjects()
      projectReducerType === ProjectReducerType.ACTIVE_PROJECTS
        ? dispatch(projectSlice.actions.setActiveProjects(response))
        : dispatch(projectSlice.actions.setProjects(response))
    } finally {
      dispatch(projectSlice.actions.setLoading(false))
    }
  }
}

export function setActiveProjectMembership(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await projectService.getProject(id)
      dispatch(projectSlice.actions.setActiveProject(response))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export function clearActiveProjectMembership() {
  return async (dispatch: Dispatch) => {
    dispatch(projectSlice.actions.setActiveProject(undefined))
  }
}

export function setActiveProject(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await projectService.getProject(id)
      dispatch(projectSlice.actions.setActiveProject(response))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export function getProjectsByCompanyId(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectSlice.actions.setLoading(true))
      const response = await projectService.getProjectsByCompanyId(id)

      dispatch(projectSlice.actions.setProjects(response))
      dispatch(projectSlice.actions.setLoading(false))
    } finally {
      dispatch(projectSlice.actions.setLoading(false))
    }
  }
}

export function getProject(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectSlice.actions.setLoading(true))
      const response = await projectService.getProject(id)
      dispatch(projectSlice.actions.setProject(response))
    } finally {
      dispatch(projectSlice.actions.setLoading(false))
    }
  }
}

export function updateActiveMembership(id: string) {
  return async (dispatch: Dispatch) => {
    const response = await projectService.updateActiveMembership(id)

    if (response.status === 200) {
      // By reloading the page we guarrantee that the state is kept up-to-date.
      window.location.replace('/')
      //window.location.reload()
      // TODO: We could patch the state by ourselves so we would need to reload
      // the page. We should start by updating the currentUser in AuthContext.
    }
  }
}

export function getProjectAlertRules(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(projectSlice.actions.setLoading(true))
      const response = await projectService.getProjectAlertRules(id)
      dispatch(projectSlice.actions.setProjectAlertRules(response))
    } finally {
      dispatch(projectSlice.actions.setLoading(false))
    }
  }
}

export default projectSlice.reducer
