import { useTranslation } from 'react-i18next'
import {
  Typography,
  Box,
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
  Divider as MuiDivider,
  Stack
} from '@mui/material'
import { styled as muiStyled, useTheme } from '@mui/system'
import { spacing } from '@mui/system'
import styled, { keyframes } from 'styled-components/macro'
import {
  calculateKwh,
  calculateMaxKwh,
  calculatePowerUsageAsPercentage
} from '../utils/sensor'
import Sensor from '../types/Sensor'
import { Device } from '../types'
import BlinkingComponent from './BlinkingComponent'

interface LinearProgressProps extends MuiLinearProgressProps {
  size?: 'small' | 'large'
}

const LinearProgress = muiStyled(MuiLinearProgress, {
  shouldForwardProp: (prop) => prop !== 'size'
})<LinearProgressProps>(
  ({ size = 'large', theme }) => `
  height: ${size === 'large' ? 12 : 8}px;
  width: 100%;
  border-radius: 8px;
  background: ${theme.palette.grey[200]};
`
)

export interface DevicePowerBarProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The sensor.
   */
  sensor?: Sensor

  /**
   * The size.
   */
  size?: 'small' | 'large'
}

export default function DevicePowerBar({
  device,
  sensor,
  size = 'large'
}: DevicePowerBarProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The theme.
   */
  const theme = useTheme()

  /**
   * The power usage in kilowatts.
   */
  const kw = sensor?.kw ?? 0

  /**
   * The maximum power usage in kilowatts.
   */
  const maxKw = sensor
    ? // @ts-ignore
      calculateMaxKwh(device?.powerSupply?.fuse)
    : 0

  /**
   * The power usage as a percentage.
   */
  const usageAsPercentage = sensor
    ? // @ts-ignore
      calculatePowerUsageAsPercentage(sensor, device?.powerSupply)
    : 0

  /**
   * The color of the linear progress bar.
   */
  const linearProgressColor = makeLinearProgressColor()

  /**
   * Make the color of the linear progress bar.
   */
  function makeLinearProgressColor(): 'success' | 'warning' | 'error' {
    if (usageAsPercentage < 60) {
      return 'success'
    } else if (usageAsPercentage < 85) {
      return 'warning'
    } else {
      return 'error'
    }
  }

  return (
    <Box>
      {size === 'large' && (
        <Typography variant="h4" mb={2}>
          {`${Math.round(kw)} kW / ${Math.round(maxKw)} kW`}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {usageAsPercentage < 100 ? (
          <LinearProgress
            variant="determinate"
            value={usageAsPercentage}
            color={linearProgressColor}
            size={size}
            sx={{ flexGrow: 1 }}
          />
        ) : (
          <BlinkingComponent>
            <LinearProgress
              variant="determinate"
              value={usageAsPercentage}
              color={linearProgressColor}
              size={size}
              sx={{ flexGrow: 1 }}
            />
          </BlinkingComponent>
        )}

        {size === 'small' && (
          <Typography fontSize="small" sx={{ flexShrink: 0, ml: 2 }}>
            {usageAsPercentage} %
          </Typography>
        )}
      </Box>
      {size === 'large' && (
        <Typography color={theme.palette.text.secondary} sx={{ mt: 2 }}>
          {`${t('Device.fromMax')}: ${usageAsPercentage} %`}
        </Typography>
      )}
    </Box>
  )
}
