import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Popover, Typography, useMediaQuery } from '@mui/material'
import { green, amber, red, blue } from '@mui/material/colors'
import styled from 'styled-components/macro'
import { PowerSupply, RuuviTag, RuuviTagCondition, Sensor } from '../types'
import { getSensorNow } from '../services/deviceService'
import { calculatePowerUsageAsPercentage } from '../utils/sensor'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getRuuviTagConditionNow } from '../services/ruuviTagService'
import { isRuuviTagOnline } from '../utils/ruuviTag'
import LoadingState from './LoadingState'
import { BlueprintAssetType } from './BlueprintCanvas'

export interface BlueprintAssetContainerProps {
  powerSupply?: PowerSupply
  containerWidth: string
  containerHeight: string
  containerFontSize: string
  containerBorderRadius: string
  sizeSetting: number
  ruuviTag?: RuuviTag
}

export default function BlueprintAssetContainer({
  powerSupply,
  containerWidth,
  containerHeight,
  containerFontSize,
  containerBorderRadius,
  sizeSetting,
  ruuviTag
}: BlueprintAssetContainerProps) {
  const [t] = useTranslation('common')
  const [sensor, setSensor] = useState<Sensor>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const theme = useTheme()
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [condition, setCondition] = useState<RuuviTagCondition>()

  useEffect(() => {
    const controller: AbortController = new AbortController()
    if (powerSupply) {
      ;(async () => await loadSensor(controller.signal))()
    } else if (ruuviTag) {
      ;(async () =>
        await loadRuuviTagConditionAndBatteryNow(controller.signal))()
    }
    return () => controller.abort()
  }, [])

  async function loadSensor(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      setSensor(
        await getSensorNow(powerSupply?.device?.name as string, { signal })
      )
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Loads the current condition and battery of RuuviTag.
   */
  async function loadRuuviTagConditionAndBatteryNow(
    signal: AbortSignal
  ): Promise<void> {
    try {
      setIsLoading(true)
      setCondition(
        await getRuuviTagConditionNow(ruuviTag?.id as string, {
          signal
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  const powerUsageAsPercentage: number = sensor
    ? calculatePowerUsageAsPercentage(sensor, powerSupply as PowerSupply)
    : 0

  const powerUsageString = `${powerUsageAsPercentage} %`

  const powerUsageColor: string = getPowerUsageColor()

  function getPowerUsageColor(): string {
    if (powerUsageAsPercentage === 0) {
      return blue[500]
    } else if (powerUsageAsPercentage < 60) {
      return green[500]
    } else if (powerUsageAsPercentage < 85) {
      return amber[500]
    } else {
      return red[500]
    }
  }

  /**
   * Indicates if the ruuvi tag is online.
   */
  const isOnline = condition && isRuuviTagOnline(new Date(condition?.time))

  /**
   * The status color.
   */
  const statusColor = isOnline ? 'green' : 'red'

  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
    background: ${powerSupply ? powerUsageColor : statusColor};
    flex-direction: row;
    border-radius: ${containerBorderRadius};
  `

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    asset: PowerSupply | RuuviTag,
    type: BlueprintAssetType
  ) => {
    setAnchorEl(event.currentTarget)

    type === BlueprintAssetType.POWERSUPPLY
      ? setSelectedPowerSupply(asset as PowerSupply)
      : setSelectedRuuviTtag(asset as RuuviTag)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const popoverTop = anchorEl?.getBoundingClientRect().top || 0
  const popoverLeft = anchorEl?.getBoundingClientRect().left || 0
  const [selectedPowerSupply, setSelectedPowerSupply] = useState<
    PowerSupply | undefined
  >(undefined)
  const [selectedRuuviTag, setSelectedRuuviTtag] = useState<
    RuuviTag | undefined
  >(undefined)
  const navigate = useNavigate()

  return (
    <React.Fragment>
      {powerSupply && (
        <React.Fragment>
          <Container
            onClick={(e) =>
              handlePopoverOpen(
                e,
                powerSupply as PowerSupply,
                BlueprintAssetType.POWERSUPPLY
              )
            }
            style={{
              width: containerWidth,
              height: containerHeight,
              fontSize: containerFontSize,
              borderRadius: containerBorderRadius
            }}
          >
            {isLoading ? (
              <LoadingState size="xs" />
            ) : (
              <React.Fragment>
                {lessThanSmall && sizeSetting === 10 ? <br /> : null}
                {powerSupply?.fuse}(A)
                <br />
                {powerUsageString}
              </React.Fragment>
            )}
          </Container>
          <Popover
            id={id}
            anchorReference="anchorPosition"
            anchorPosition={{
              top: popoverTop - 100,
              left: popoverLeft - 100
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
          >
            <Typography align="right" mr={-2}>
              <Button size="small" onClick={handlePopoverClose}>
                X
              </Button>
            </Typography>
            <Typography ml={2} mr={2}>
              {t('name')}: {selectedPowerSupply?.name} (
              {selectedPowerSupply?.device?.shortUUID})
            </Typography>
            <Typography ml={2} mr={2}>
              {t('Device.power')}: {powerUsageString}
            </Typography>
            <Typography ml={2} mr={2}>
              {t('Device.phase1')}:{' '}
              {sensor?.current_1 ? sensor?.current_1 + 'A' : '-'}
            </Typography>
            <Typography ml={2} mr={2}>
              {t('Device.phase2')}:{' '}
              {sensor?.current_2 ? sensor?.current_2 + 'A' : '-'}
            </Typography>
            <Typography ml={2} mr={2}>
              {t('Device.phase3')}:{' '}
              {sensor?.current_3 ? sensor?.current_3 + 'A' : '-'}
            </Typography>
            <Typography>
              <Button
                onClick={() =>
                  navigate(`/devices/${selectedPowerSupply?.device?.name}`)
                }
              >
                {t('Device.moreInfo')}
              </Button>
            </Typography>
          </Popover>
        </React.Fragment>
      )}

      {ruuviTag && (
        <React.Fragment>
          <Container
            onClick={(e) =>
              handlePopoverOpen(
                e,
                ruuviTag as RuuviTag,
                BlueprintAssetType.RUUVITAG
              )
            }
            style={{
              width: containerWidth,
              height: containerHeight,
              fontSize: containerFontSize,
              borderRadius: containerBorderRadius
            }}
          >
            {isLoading ? (
              <LoadingState size="xs" />
            ) : (
              <React.Fragment>
                {isOnline ? (
                  <>
                    {lessThanSmall && sizeSetting === 10 ? <br /> : null}
                    {condition?.temperatureAvg}°C
                    <br />
                    RH: {condition?.humidityAvg}%
                  </>
                ) : (
                  t('offline')
                )}
              </React.Fragment>
            )}
          </Container>
          <Popover
            id={id}
            anchorReference="anchorPosition"
            anchorPosition={{
              top: popoverTop - 100,
              left: popoverLeft - 100
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
          >
            <Typography align="right" mr={-2}>
              <Button size="small" onClick={handlePopoverClose}>
                X
              </Button>
            </Typography>
            <Typography ml={2} mr={2}>
              {t('name')}: {selectedRuuviTag?.asset?.name}
            </Typography>
            <Typography ml={2} mr={2}>
              {t('device')}: {selectedRuuviTag?.deviceName}
            </Typography>
            <Typography ml={2} mr={2}>
              {t('latestMeasurement')}:{' '}
              {condition?.time
                ? new Date(condition.time).toLocaleString()
                : '-'}
            </Typography>
            <Typography ml={2} mr={2}>
              {t('temperature')}: {condition?.temperatureAvg ?? '-'}°C
            </Typography>
            <Typography ml={2} mr={2}>
              {t('humidity')}: {condition?.humidityAvg ?? '-'}%
            </Typography>
            <Typography ml={2} mr={2}>
              {t('pressure')}: {condition?.pressureAvg ?? '-'}hPa
            </Typography>
            <Typography>
              <Button
                onClick={() => navigate(`/ruuvi-tags/${selectedRuuviTag?.id}`)}
              >
                {t('Device.moreInfo')}
              </Button>
            </Typography>
          </Popover>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
