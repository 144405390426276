import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Alert,
  Collapse,
  AlertColor
} from '@mui/material'
import { spacing } from '@mui/system'
import { ReactComponent as Logo } from '../../vendor/spine-white-logo-text.svg'
import { createTheme, ThemeProvider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { createNotificationForManufacturingEnvironment } from '../../redux/slices/notifications'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

const Divider = styled(MuiDivider)(spacing)

function ManufacturingHeader() {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { manufacturingNotification } = useSelector(
    (state: RootState) => state.notifications
  )
  const sev = manufacturingNotification?.type as AlertColor
  const handleClear = () => {
    dispatch(
      createNotificationForManufacturingEnvironment({
        show: false,
        type: '',
        message: '',
        timeout: 0
      })
    )
  }

  useEffect(() => {
    if (
      manufacturingNotification?.type == 'success' ||
      manufacturingNotification?.type == 'warning'
    ) {
      setTimeout(() => {
        handleClear()
      }, manufacturingNotification.timeout)
    } else {
      return
    }
  }, [manufacturingNotification?.show])

  return (
    <React.Fragment>
      <Helmet title={t('Manufacturing.manufacturing')} />
      <Collapse in={manufacturingNotification?.show}>
        <Alert variant={'filled'} severity={sev} onClose={() => handleClear()}>
          {manufacturingNotification?.message}
        </Alert>
        <Divider my={6} />
      </Collapse>
    </React.Fragment>
  )
}

export default ManufacturingHeader
