import {
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { OrderItemType } from '../enums/OrderItemType'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function OrderItemTypeSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')

  return (
    <TextField
      name="type"
      label={t('Manufacturing.type')}
      select
      {...props}
      my={2}
    >
      <MenuItem value={OrderItemType.INSTALLATION_KIT as string}>
        {t('Manufacturing.kit')}
      </MenuItem>
      <MenuItem value={OrderItemType.PLUG_AND_PLAY as string}>
        {t('Manufacturing.plug')}
      </MenuItem>
      <MenuItem value={OrderItemType.SMART_ELECTRICAL_CABINET as string}>
        {t('Manufacturing.cabinet')}
      </MenuItem>
      <MenuItem value={OrderItemType.SPINE_MODULE as string}>
        {t('spineModule')}
      </MenuItem>
    </TextField>
  )
}
