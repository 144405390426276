import announcements from './announcements'
import auth from './auth'
import blueprint from './blueprint'
import companies from './companies'
import confirmRole from './confirm-role'
import devices from './devices'
import inspectDevice from './inspect-device'
import installation from './installation'
import manufacturing from './manufacturing'
import mfa from './mfa'
import powersupplies from './powersupplies'
import projects from './projects'
import requestAccess from './request-access'
import ruuviTags from './ruuvi-tags'
import scan from './scan'
import users from './users'

import AuthGuard from '../components/guards/AuthGuard'
import { UserRole } from '../enums/UserRole'
import { UserManualProvider } from '../contexts/userManual'
import AuthLayout from '../layouts/Auth'
import DashboardLayout from '../layouts/Dashboard'
import Dashboard from '../pages/dashboards/Default'
import UserManual from '../pages/UserManual'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import TermsOfService from '../pages/TermsOfService'
import Page404 from '../pages/auth/Page404'

// Deprecated routes.
import DeviceGroups from '../pages/pages/DeviceGroups'
import Memberships from '../pages/pages/Memberships'
import Conditions from '../pages/pages/Conditions'
import Condition from '../pages/pages/Condition'
import Levels from '../pages/pages/Levels'
import Lifts from '../pages/pages/Lifts'
import Sectors from '../pages/pages/Sectors'
import { ConfluencePages } from '../enums'

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.GUEST,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.DASHBOARD}>
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/user-manual',
    element: (
      <DashboardLayout>
        <UserManual />
      </DashboardLayout>
    )
  },
  {
    path: '/privacy-policy',
    element: (
      <DashboardLayout>
        <PrivacyPolicy />
      </DashboardLayout>
    )
  },
  {
    path: '/terms-of-service',
    element: (
      <DashboardLayout>
        <TermsOfService />
      </DashboardLayout>
    )
  },

  ...announcements,
  ...auth,
  ...blueprint,
  ...companies,
  ...confirmRole,
  ...devices,
  ...inspectDevice,
  ...installation,
  ...manufacturing,
  ...mfa,
  ...powersupplies,
  ...projects,
  ...requestAccess,
  ...ruuviTags,
  ...scan,
  ...users,

  /**
   * @deprecated
   */
  {
    path: '/device_groups',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <DeviceGroups />
        </DashboardLayout>
      </AuthGuard>
    )
  },

  /**
   * @deprecated
   */
  {
    path: '/memberships',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <Memberships />
        </DashboardLayout>
      </AuthGuard>
    )
  },

  /**
   * @deprecated
   */
  {
    path: '/conditions',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Conditions />
      },
      {
        path: '*',
        element: <Condition />
      }
    ]
  },

  /**
   * @deprecated
   */
  {
    path: '/sectors',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <Sectors />
        </DashboardLayout>
      </AuthGuard>
    )
  },

  /**
   * @deprecated
   */
  {
    path: '/lifts',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <Lifts />
        </DashboardLayout>
      </AuthGuard>
    )
  },

  /**
   * @deprecated
   */
  {
    path: '/levels',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <Levels />
        </DashboardLayout>
      </AuthGuard>
    )
  },

  {
    path: '*',
    element: (
      <AuthLayout>
        <Page404 />
      </AuthLayout>
    )
  }
]

export default routes
