import { ReactElement } from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import { Device, Sensor, Statistic } from '../types'
import { humanizeTimestamp } from '../utils/date'
import DeviceSignalIndicator from './DeviceSignalIndicator'
import { SensorAggregateStatistic } from '../enums'
import {
  calculateAverageCurrent,
  calculateAverageVoltage,
  calculateMaximumCurrent,
  calculateMaximumVoltage,
  calculateMinimumCurrent,
  calculateMinimumVoltage
} from '../utils/sensor'

interface DeviceStatsTooltipProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The sensor.
   */
  sensor: Sensor

  /**
   * The statistic.
   */
  statistic?: Statistic

  /**
   * The child nodes.
   */
  children: ReactElement
}

export default function DeviceStatsTooltip({
  device,
  sensor,
  statistic,
  children
}: DeviceStatsTooltipProps): JSX.Element {
  /**
   * Calculate the power.
   *
   * @returns The power.
   */
  function calculatePower(): number {
    switch (statistic) {
      case SensorAggregateStatistic.MAX:
        return sensor?.max_kw ?? sensor.kw
      case SensorAggregateStatistic.MIN:
        return sensor?.min_kw ?? sensor.kw
      case SensorAggregateStatistic.AVG:
      default:
        return sensor.kw
    }
  }

  /**
   * Calculate the voltage.
   *
   * @returns The voltage.
   */
  function calculateVoltage(): number {
    switch (statistic) {
      case SensorAggregateStatistic.MAX:
        return calculateMaximumVoltage(sensor)
      case SensorAggregateStatistic.MIN:
        return calculateMinimumVoltage(sensor)
      case SensorAggregateStatistic.AVG:
      default:
        return calculateAverageVoltage(sensor)
    }
  }

  /**
   * Calculate the current.
   *
   * @returns The current.
   */
  function calculateCurrent(): number {
    switch (statistic) {
      case SensorAggregateStatistic.MAX:
        return calculateMaximumCurrent(sensor)
      case SensorAggregateStatistic.MIN:
        return calculateMinimumCurrent(sensor)
      case SensorAggregateStatistic.AVG:
      default:
        return calculateAverageCurrent(sensor)
    }
  }
  return (
    <Tooltip
      title={
        <Stack direction="column" gap={1}>
          <Box>{humanizeTimestamp(sensor.time)}</Box>
          <Stack direction="row" gap={2}>
            <Box>{`${Math.round(calculatePower())}kW`}</Box>
            <Box>{Math.round(calculateVoltage())}V</Box>
            <Box>{`${Math.round(calculateCurrent())}A/${
              device?.powerSupply?.fuse ?? '-'
            }A`}</Box>
            <Box>
              <DeviceSignalIndicator device={device} sensor={sensor} />
            </Box>
          </Stack>
        </Stack>
      }
    >
      {children}
    </Tooltip>
  )
}
