import { Circle } from '@mui/icons-material'
import BlinkingComponent from './BlinkingComponent'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * The props of the status indicator.
 */
interface StatusIndicatorProps {
  /**
   * The status.
   */
  status: boolean

  /**
   * The font size.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
}

/**
 * A status indicator.
 */
export default function StatusIndicator({
  status,
  fontSize = 'inherit'
}: StatusIndicatorProps): JSX.Element {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <>
      {status ? (
        <Tooltip title={t('online') as string}>
          <Circle color="success" fontSize={fontSize} />
        </Tooltip>
      ) : (
        <Tooltip title={t('offline') as string}>
          <BlinkingComponent>
            <Circle color="error" fontSize={fontSize} />
          </BlinkingComponent>
        </Tooltip>
      )}
    </>
  )
}
