import { useState } from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from '@mui/material'

interface Props {
  label: string
  items: string[]
  setItem: (value: string) => void
  value: string
}

const TroubleshootDropdown = ({ label, items, setItem, value }: Props) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value
    setItem(newValue)
  }

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel id="troubleshot-dropdown-label">{label}</InputLabel>
      <Select
        value={value}
        labelId="troubleshot-dropdown-label"
        label={label}
        onChange={handleChange}
      >
        {items.map((menuItem, index) => (
          <MenuItem key={index} value={menuItem}>
            {menuItem}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default TroubleshootDropdown
