import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import { Announcement } from '../../types'
import announcementService from '../../services/announcementService'

interface AnnouncementState {
  announcements: Announcement[]
  announcement: Announcement | undefined
  show: boolean
  loading: boolean
}

const initialState: AnnouncementState = {
  announcements: [],
  announcement: undefined,
  show: false,
  loading: false
}

const announcementSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setAnnouncements(state, action) {
      state.announcements = action.payload
    },
    setAnnouncement(state, action) {
      state.announcement = action.payload
    },
    createAnnouncement(state, action) {
      state.announcements = [...state.announcements, action.payload]
    },
    updateAnnouncement(state, action) {
      state.announcements = state.announcements.map((announcement) =>
        announcement.id === action.payload.id ? action.payload : announcement
      )
    },
    deleteAnnouncement(state, action) {
      state.announcements = state.announcements.filter(
        (announcement) => announcement.id !== action.payload
      )
    },
    showAnnouncement(state, action) {
      state.announcement = action.payload
      state.show = true
    },
    hideAnnouncement(state) {
      state.show = false
      state.announcement = undefined
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const { createAnnouncement, updateAnnouncement, deleteAnnouncement } =
  announcementSlice.actions

export function getAnnouncements() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(announcementSlice.actions.setLoading(true))
      const announcements: Announcement[] =
        await announcementService.getAnnouncements()
      dispatch(announcementSlice.actions.setAnnouncements(announcements))
    } finally {
      dispatch(announcementSlice.actions.setLoading(false))
    }
  }
}

export function getAnnouncement(id: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(announcementSlice.actions.setLoading(true))
      const announcement = await announcementService.getAnnouncement(id)
      dispatch(announcementSlice.actions.setAnnouncement(announcement))
    } finally {
      dispatch(announcementSlice.actions.setLoading(false))
    }
  }
}

export function showAnnouncement(announcement?: Announcement) {
  return async (dispatch: Dispatch) => {
    dispatch(announcementSlice.actions.showAnnouncement(announcement))
  }
}

export function hideAnnouncement() {
  return async (dispatch: Dispatch) => {
    dispatch(announcementSlice.actions.hideAnnouncement())
  }
}

export default announcementSlice.reducer
