import { Sector, SectorInput } from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getSectors(): Promise<Sector[]> {
  const response = await api.get('/sectors')

  return response.data
}

export async function getSectorsByProjectId(
  projectId: number
): Promise<Sector[]> {
  const response = await api.get(`/projects/${projectId}/sectors`)

  return response.data
}

export async function getSector(id: number): Promise<Sector> {
  const response = await api.get(`/sectors/${id}`)

  return response.data
}

export async function createSector(input: SectorInput): Promise<Sector> {
  const response = await api.post('/sectors', input)

  return response.data
}

export async function updateSector(
  id: number,
  input: SectorInput
): Promise<Sector> {
  const response = await api.put(`/sectors/${id}`, input)

  return response.data
}

export async function deleteSector(id: number): Promise<void> {
  await api.delete(`/sectors/${id}`)
}

export default {
  getSectors,
  getSectorsByProjectId,
  getSector,
  createSector,
  updateSector,
  deleteSector
}
