import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import fi from 'date-fns/locale/fi'
import Chart from 'react-chartjs-2'
import { ChartData, ChartOptions } from 'chart.js'
import 'chartjs-adapter-date-fns'
import _ from 'lodash'
import { useTheme } from '@mui/system'
import { useRef } from 'react'

export interface LineChartProps {
  ref?: any
  data: ChartData
  options: ChartOptions
  wrapperHeight?: string
}

export default function LineChart({
  ref,
  data,
  options,
  wrapperHeight = '280px'
}: LineChartProps) {
  const [t] = useTranslation('common')

  const chart = useRef('chart')

  const theme = useTheme()

  const LineChartWrapper = styled.div`
    height: ${wrapperHeight};
  `

  options = _.merge(
    {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      normalized: true,
      locale: 'fi-FI',
      font: {
        family: "'Inter', sans-serif"
      },
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false
      },
      plugins: {
        title: {
          display: true,
          position: 'bottom',
          padding: {
            top: 24
          },
          font: {
            weight: '600',
            size: 14
          },
          color:
            theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 1.0)'
              : 'rgba(255, 255, 255, 1.0)'
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 24,
            boxHeight: 1,
            padding: 24,
            font: {
              weight: '600',
              size: 12
            }
          }
        },
        decimation: {
          enabled: false,
          algorithm: 'min-max'
        }
      },
      elements: {
        point: {
          pointStyle: 'point',
          radius: 0
        },
        line: {
          borderWidth: 1
        }
      },
      scales: {
        x: {
          display: true,
          adapters: {
            date: {
              locale: fi
            }
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0
          },
          grid: {
            display: true,
            // @ts-ignore
            color:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.0375)'
                : 'rgba(255, 255, 255, 0.0375)',
            // @ts-ignore
            fontColor: '#fff',
            // @ts-ignore
            drawBorder: false
          },
          color: '#fff'
        },
        y: {
          display: true,
          grid: {
            display: true,
            // @ts-ignore
            color:
              theme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.0375)'
                : 'rgba(255, 255, 255, 0.0375)',
            // @ts-ignore
            fontColor: '#fff',
            // @ts-ignore
            drawBorder: false
          }
        }
      }
    },
    options
  )

  return (
    <LineChartWrapper>
      <Chart redraw type="line" data={data} options={options} />
    </LineChartWrapper>
  )
}
