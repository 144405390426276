import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  SelectChangeEvent,
  Stack,
  MenuItem,
  Chip,
  Box
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { NavigateNext, QrCode } from '@mui/icons-material'
import { Device, RuuviTag, RuuviTagInput, RuuviTagSignal } from '../types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { humanizeTimestamp } from '../utils/date'
import { useEffect, useState } from 'react'
import { useAuth } from '../hooks'
import {
  userIsAdminOfProject,
  userIsSuperadmin,
  userIsUserOfProject
} from '../utils/auth'
import AssetDetails from './AssetDetails'
import { updateRuuviTag } from '../services/ruuviTagService'
import { getPowerSuppliesByProjectId } from '../services/projectService'
import { formatMacAddress } from '../utils/ruuviTag'
import { subHours, subMinutes } from 'date-fns'
import SignalIndicator from './SignalIndicator'

interface RuuviTagDetailsProps {
  ruuviTag: RuuviTag
  onUpdate(ruuviTag: RuuviTag): void
}

export default function RuuviTagDetails({
  ruuviTag,
  onUpdate
}: RuuviTagDetailsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The input.
   */
  const [input, setInput] = useState<RuuviTagInput>({
    device: ruuviTag.deviceName
  })

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * Indicates if the component is saving.
   */
  const [isSaving, setIsSaving] = useState<boolean>(false)

  /**
   * The devices of project.
   */
  const [devices, setDevices] = useState<Device[]>([])

  /**
   * The devices by signal strength.
   */
  const devicesBySignalStrength = devices.sort((a, b) => {
    return getAverageSignal(b.name) - getAverageSignal(a.name)
  })

  /**
   * Indicates if the device dialog is open.
   */
  const [isDeviceDialogOpen, setIsDeviceDialogOpen] = useState<boolean>(false)

  /**
   * Check if the current user can view the device.
   */
  function canViewDevice(device: Device): boolean {
    return (
      userIsSuperadmin(currentUser) ||
      (device?.asset?.project !== undefined &&
        (userIsAdminOfProject(currentUser, device.asset.project) ||
          userIsUserOfProject(currentUser, device.asset.project)))
    )
  }

  /**
   * Check if the current user can edit ruuvi tag.
   */
  function canEditRuuviTag(ruuviTag: RuuviTag): boolean {
    return (
      userIsSuperadmin(currentUser) ||
      (ruuviTag?.asset?.project !== undefined &&
        userIsAdminOfProject(currentUser, ruuviTag.asset.project))
    )
  }

  /**
   * Open the device dialog.
   */
  function openDeviceDialog(): void {
    setIsDeviceDialogOpen(true)
  }

  /**
   * Close the device dialog.
   */
  function closeDeviceDialog(): void {
    setIsDeviceDialogOpen(false)
  }

  /**
   * Cancel the device dialog.
   */
  function cancelDeviceDialog(): void {
    closeDeviceDialog()
    setInput({
      device: ruuviTag.deviceName
    })
  }

  /**
   * Get the signals of a device.
   *
   * @param deviceName The device name.
   *
   * @returns The signals of the device.
   */
  function getSignals(deviceName: string): RuuviTagSignal[] {
    let signals: RuuviTagSignal[] = []

    if (deviceName in ruuviTag.signalHistory) {
      signals = ruuviTag.signalHistory[deviceName]
    }

    // Keep only signals of the last 15 minutes.
    signals = signals.filter((signal) => {
      return new Date(signal.time) > subMinutes(new Date(), 15)
    })

    return signals
  }

  /**
   * Check if a device has signals.
   *
   * @param deviceName The device name.
   *
   * @returns True if the device has signals, false otherwise.
   */
  function hasSignals(deviceName: string): boolean {
    return getSignals(deviceName).length > 0
  }

  /**
   * Get count of signals of a device.
   *
   * @param deviceName The device name.
   *
   * @return The count of signals of the device.
   */
  function getSignalCount(deviceName: string): number {
    return getSignals(deviceName).length
  }

  /**
   * Get the average signal of a device.
   *
   * @param deviceName The device name.
   *
   * @returns The average signal of the device.
   */
  function getAverageSignal(deviceName: string): number {
    const signals = getSignals(deviceName)

    if (signals.length === 0) {
      return -255
    }

    const sum = signals.reduce((sum, signal) => sum + signal.rssi, 0)

    return sum / signals.length
  }

  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (ruuviTag?.asset?.projectId) {
        await loadDevices(ruuviTag.asset.projectId, signal)
      }
    } finally {
      setIsBooting(false)
    }
  }

  async function loadDevices(
    projectId: number,
    signal: AbortSignal
  ): Promise<void> {
    const powerSupplies = await getPowerSuppliesByProjectId(projectId)
    const devices = powerSupplies
      .filter(
        (powerSupply) =>
          powerSupply.device !== null && powerSupply.device !== undefined
      )
      .map(
        (powerSupply): Device =>
          // @ts-ignore
          powerSupply.device
      )

    setDevices(devices)
  }

  /**
   * Save the ruuvi tag.
   */
  async function save(): Promise<void> {
    try {
      setIsSaving(true)

      onUpdate(await updateRuuviTag(ruuviTag.id, input))

      setIsSaving(false)

      // Close the device dialog.
      setIsDeviceDialogOpen(false)
    } catch (error: any) {
      // TODO: Handle error.
      console.error(error)
      setIsSaving(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      await boot(controller.signal)
    })()

    return () => controller.abort()
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      // TODO: Reset the device of ruuvi tag

      if (ruuviTag?.asset?.projectId) {
        await loadDevices(ruuviTag.asset.projectId, controller.signal)
      } else {
        setDevices([])
      }
    })()

    return () => controller.abort()
  }, [ruuviTag.asset?.projectId])

  return (
    <AssetDetails
      asset={ruuviTag?.asset ?? null}
      onUpdate={(asset) => {
        onUpdate({ ...ruuviTag, asset })
      }}
    >
      <Divider sx={{ my: 3 }} />

      <List>
        <ListItemButton>
          <ListItemText
            primary={
              <Stack direction="row" gap={2} alignItems="center">
                {ruuviTag.device?.asset?.name ?? ruuviTag.deviceName}
                {ruuviTag?.device && (
                  <Chip
                    icon={<QrCode fontSize="inherit" />}
                    label={ruuviTag.device.shortUUID}
                    size="small"
                    variant="outlined"
                    sx={{ ml: 2 }}
                  />
                )}
                {ruuviTag?.deviceName && (
                  <SignalIndicator
                    rssi={getAverageSignal(ruuviTag.deviceName)}
                    fontSize="small"
                  />
                )}
              </Stack>
            }
            secondary={t('hostDevice')}
          />
          <ListItemSecondaryAction>
            <Stack direction="row" gap={2}>
              {canEditRuuviTag(ruuviTag) && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ alignSelf: 'center' }}
                  onClick={openDeviceDialog}
                >
                  {t('edit')}
                </Button>
              )}

              {ruuviTag.device && canViewDevice(ruuviTag.device) && (
                <IconButton
                  onClick={() => navigate(`/devices/${ruuviTag?.device?.name}`)}
                >
                  <NavigateNext />
                </IconButton>
              )}
            </Stack>
          </ListItemSecondaryAction>
        </ListItemButton>

        <Dialog
          open={isDeviceDialogOpen}
          onClose={cancelDeviceDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{t('selectDevice')}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="device-select-label">{t('device')}</InputLabel>
              <Select
                id="device-select"
                labelId="device-select-label"
                value={input.device?.toString() ?? undefined}
                label={t('device')}
                autoFocus
                onChange={(event: SelectChangeEvent) => {
                  setInput({
                    ...input,
                    device: event.target.value ?? null
                  })
                }}
              >
                {devicesBySignalStrength.map((device) => (
                  <MenuItem value={device.name}>
                    <Stack
                      direction="row"
                      gap={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <SignalIndicator
                        rssi={getAverageSignal(device.name)}
                        fontSize="small"
                      />
                      {device?.asset?.name ?? device.name}
                      <Chip
                        icon={<QrCode fontSize="inherit" />}
                        label={device.shortUUID}
                        size="small"
                        variant="outlined"
                        sx={{ ml: 2 }}
                      />
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button disabled={isSaving} onClick={cancelDeviceDialog}>
              {t('cancel')}
            </Button>

            {input.device !== null && (
              <Button
                disabled={isSaving}
                onClick={() => {
                  setInput({
                    ...input,
                    device: null
                  })
                }}
              >
                {t('clear')}
              </Button>
            )}

            <LoadingButton
              variant="contained"
              disabled={isSaving}
              loading={isSaving}
              onClick={save}
            >
              <span>{t('save')}</span>
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <ListItem>
          <ListItemText
            primary={formatMacAddress(ruuviTag.id)}
            secondary={t('macAddress')}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={humanizeTimestamp(ruuviTag.createdAt)}
            secondary={t('createdAt')}
          />
        </ListItem>
      </List>
    </AssetDetails>
  )
}
