import { ReactElement, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { CalculationMode } from '../enums'

interface CalculationModeToggleProps {
  value: CalculationMode
  options?: CalculationMode[]
  onChange(event: MouseEvent<HTMLElement>, value: CalculationMode): void
}

export default function CalculationModeToggle({
  value,
  options = [CalculationMode.CUMULATIVE, CalculationMode.INCREMENTAL],
  onChange
}: CalculationModeToggleProps): ReactElement {
  const [t] = useTranslation('common')

  function handleChange(
    event: MouseEvent<HTMLElement>,
    value: CalculationMode
  ) {
    if (value && value !== null) {
      onChange(event, value)
    }
  }

  function hasOption(option: CalculationMode): boolean {
    return options.includes(option)
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography fontSize={12}>{t('calculationMode')}</Typography>

      <ToggleButtonGroup
        color="primary"
        size="small"
        exclusive
        value={value}
        onChange={handleChange}
      >
        {hasOption(CalculationMode.CUMULATIVE) && (
          <ToggleButton value={CalculationMode.CUMULATIVE}>
            {t('CalculationMode.CUMULATIVE')}
          </ToggleButton>
        )}

        {hasOption(CalculationMode.INCREMENTAL) && (
          <ToggleButton value={CalculationMode.INCREMENTAL}>
            {t('CalculationMode.INCREMENTAL')}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Stack>
  )
}
