import { createRef, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Box, Grid } from '@mui/material'
import useAuth from '../hooks/useAuth'
import { getRuuviTagsUsingPagination } from '../services/ruuviTagService'
import RuuviTagGrid from './RuuviTagGrid'
import RuuviTagGridSkeleton from './AssetGridSkeleton'
import { RuuviTag, RuuviTagQuery } from '../types'
import { useTranslation } from 'react-i18next'

interface RuuviTagScrollerProps {
  query?: RuuviTagQuery
  empty?: JSX.Element
  pageSize?: number
  autoFill?: boolean
}

export default function RuuviTagScroller({
  query = {},
  empty,
  pageSize = 24,
  autoFill = true
}: RuuviTagScrollerProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The ruuvi tag sensors.
   */
  const [ruuviTags, setRuuviTags] = useState<RuuviTag[]>([])

  /**
   * The current page.
   */
  const [currentPage, setCurrentPage] = useState<number>(1)

  /**
   * The page count.
   */
  const [pageCount, setPageCount] = useState<number>(1)

  /**
   * The total count.
   */
  const [totalCount, setTotalCount] = useState<number>(0)

  /**
   * Indicates if there are more pages.
   */
  const [hasMorePages, setHasMorePages] = useState<boolean>(true)

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The height of the scroller.
   */
  const [scrollerHeight, setScrollerHeight] = useState<number>(0)

  /**
   * The scroller element.
   */
  const scroller = createRef<HTMLElement>()

  /**
   * Indicates if the ruuvi tags are empty.
   */
  const isEmpty = !isBooting && ruuviTags.length === 0

  /**
   * Boots the component.
   */
  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      await loadRuuviTags(signal)
    } catch (error: any) {
      // TODO: Handle boot errors.
      console.error(error)
    } finally {
      setIsBooting(false)
    }
  }

  /**
   * Loads more ruuvi tags if needed.
   */
  async function loadMoreRuuviTagsIfNeeded(
    signal?: AbortSignal
  ): Promise<void> {
    const pageHeight = document.getElementById('root')?.clientHeight ?? 0

    const threshold = 150
    if (currentPage <= pageCount && scrollerHeight - threshold < pageHeight) {
      await loadRuuviTags(signal)
    }
  }

  /**
   * Load more ruuvi tags.
   */
  async function loadMoreRuuviTags(page: number): Promise<void> {
    if (currentPage <= pageCount) {
      await loadRuuviTags()
    }
  }

  /**
   * Loads the ruuvi tag.
   */
  async function loadRuuviTags(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)

      const { pageCount, totalCount, data } = await getRuuviTagsUsingPagination(
        query,
        {
          page: currentPage,
          pageSize
        },
        {
          signal
        }
      )

      setPageCount(pageCount)
      setTotalCount(totalCount)
      setRuuviTags([...ruuviTags, ...data])

      setCurrentPage(currentPage + 1)
      if (currentPage >= pageCount) {
        setHasMorePages(false)
      }
    } catch (error: any) {
      // TODO: Handle errors.
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser) {
      const controller = new AbortController()

      ;(async () => {
        await boot(controller.signal)
      })()

      return () => controller.abort()
    }
  }, [currentUser])

  useEffect(() => {
    const controller = new AbortController()

    if (autoFill && currentPage > 1) {
      ;(async () => {
        await loadMoreRuuviTagsIfNeeded(controller.signal)
      })()
    }

    return () => controller.abort()
  }, [currentPage])

  useEffect(() => {
    setScrollerHeight(scroller?.current?.clientHeight ?? 0)
  })

  return (
    <Box ref={scroller}>
      {isBooting ? (
        <RuuviTagGridSkeleton count={pageSize} />
      ) : isEmpty ? (
        <>{empty}</>
      ) : (
        <InfiniteScroll
          pageStart={currentPage}
          loadMore={loadMoreRuuviTags}
          hasMore={hasMorePages}
          useWindow={false}
          getScrollParent={() => document.getElementById('root')}
          initialLoad={false}
          loader={
            <>
              {isLoading && (
                <Grid item mt={6}>
                  <RuuviTagGridSkeleton count={pageSize} />
                </Grid>
              )}
            </>
          }
        >
          <RuuviTagGrid ruuviTags={ruuviTags} />
        </InfiniteScroll>
      )}
    </Box>
  )
}
