import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Stack,
  Typography,
  Skeleton,
  Button
} from '@mui/material'
import { BarChart } from '@mui/icons-material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import useAuth from '../../hooks/useAuth'
import {
  getRuuviTag,
  getRuuviTagAccelerationAggregates,
  getRuuviTagAccelerationNow,
  getRuuviTagAccelerations,
  getRuuviTagBatteries,
  getRuuviTagBatteryAggregates,
  getRuuviTagBatteryNow,
  getRuuviTagConditionAggregates,
  getRuuviTagConditionNow,
  getRuuviTagConditions,
  getRuuviTagMovementNow,
  getRuuviTagMovements
} from '../../services/ruuviTagService'
import {
  RuuviTag,
  RuuviTagAcceleration,
  RuuviTagAccelerationQuery,
  RuuviTagAccelerationAggregate,
  RuuviTagAccelerationAggregateQuery,
  RuuviTagBattery,
  RuuviTagBatteryQuery,
  RuuviTagBatteryAggregate,
  RuuviTagBatteryAggregateQuery,
  RuuviTagCondition,
  RuuviTagConditionQuery,
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateQuery,
  RuuviTagMovement,
  RuuviTagMovementQuery
} from '../../types'
import {
  Hours,
  RuuviTagAccelerationAggregateInterval,
  RuuviTagBatteryAggregateInterval,
  RuuviTagConditionAggregateInterval
} from '../../enums'
import RuuviTagAccelerationAxisChart from '../../components/RuuviTagAccelerationAxisChart'
import RuuviTagBatteryVoltageStats from '../../components/RuuviTagBatteryVoltageStats'
import RuuviTagHumidityChart from '../../components/RuuviTagHumidityChart'
import RuuviTagHumidityStats from '../../components/RuuviTagHumidityStats'
import RuuviTagPressureChart from '../../components/RuuviTagPressureChart'
import RuuviTagPressureStats from '../../components/RuuviTagPressureStats'
import RuuviTagSignalChart from '../../components/RuuviTagSignalChart'
import RuuviTagSignalStats from '../../components/RuuviTagSignalStats'
import RuuviTagTemperatureChart from '../../components/RuuviTagTemperatureChart'
import RuuviTagTemperatureStats from '../../components/RuuviTagTemperatureStats'
import { differenceInHours } from 'date-fns'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import RuuviTagDetails from '../../components/RuuviTagDetails'
import RuuviTagMovementCountStats from '../../components/RuuviTagMovementCountStats'
import DateRangeBar from '../../components/DateRangeBar'
import { userIsSuperadmin } from '../../utils/auth'
import { formatMacAddress } from '../../utils/string'
import RuuviTagStatusIndicator from '../../components/RuuviTagStatusIndicator'

const Divider = styled(MuiDivider)(spacing)

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

export default function RuuviTagPage() {
  /**
   * The route params.
   */
  const { id } = useParams()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The current date range.
   */
  const { dateRange } = useSelector((state: RootState) => state.query)

  /**
   * The RuuviTag.
   */
  const [ruuviTag, setRuuviTag] = useState<RuuviTag | null>(null)

  /**
   * The latest acceleration of the RuuviTag.
   */
  const [accelerationNow, setAccelerationNow] =
    useState<RuuviTagAcceleration | null>(null)

  /**
   * The latest battery of the RuuviTag.
   */
  const [batteryNow, setBatteryNow] = useState<RuuviTagBattery | null>(null)

  /**
   * The latest condition of the RuuviTag.
   */
  const [conditionNow, setConditionNow] = useState<RuuviTagCondition | null>(
    null
  )

  /**
   * The latest movement of the RuuviTag.
   */
  const [movementNow, setMovementNow] = useState<RuuviTagMovement | null>(null)

  /**
   * The accelerations of the RuuviTag.
   */
  const [accelerations, setAccelerations] = useState<RuuviTagAcceleration[]>([])

  /**
   * The batteries of the RuuviTag.
   */
  const [batteries, setBatteries] = useState<RuuviTagBattery[]>([])

  /**
   * The conditions of the RuuviTag.
   */
  const [conditions, setConditions] = useState<RuuviTagCondition[]>([])

  /**
   * The movements of the RuuviTag.
   */
  const [movements, setMovements] = useState<RuuviTagMovement[]>([])

  /**
   * The accceleration aggregates of the RuuviTag.
   */
  const [accelerationAggregates, setAccelerationAggregates] = useState<
    RuuviTagAccelerationAggregate[]
  >([])

  /**
   * The battery aggregates of the RuuviTag.
   */
  const [batteryAggregates, setBatteryAggregates] = useState<
    RuuviTagBatteryAggregate[]
  >([])

  /**
   * The condition aggregates of the RuuviTag sensor.
   */
  const [conditionAggregates, setConditionAggregates] = useState<
    RuuviTagConditionAggregate[]
  >([])

  /**
   * Indicates if the component is loading.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The differencec of date range in hours.
   */
  const differenceOfDateRangeInHours = Math.abs(
    differenceInHours(dateRange.from, dateRange.to)
  )

  /**
   * Indicates if aggregates should be used.
   */
  const isUsingAggregates = differenceOfDateRangeInHours >= 24

  /**
   * Indicates if the current user is superadmin.
   */
  const isSuperadmin = userIsSuperadmin(currentUser)

  /**
   * The query for the accelerations of the RuuviTag.
   */
  const accelerationQuery: RuuviTagAccelerationQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query for the accelerations of the RuuviTag.
   */
  const accelerationAggregateQuery: RuuviTagAccelerationAggregateQuery = {
    from: dateRange.from,
    to: dateRange.to,
    interval: getRuuviTagAccelerationAggregateInterval()
  }

  /**
   * The query for the batteries of the RuuviTag.
   */
  const batteryQuery: RuuviTagBatteryQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query for the battery aggregates of the RuuviTag.
   */
  const batteryAggregateQuery: RuuviTagBatteryAggregateQuery = {
    from: dateRange.from,
    to: dateRange.to,
    interval: getRuuviTagBatteryAggregateInterval()
  }

  /**
   * The query for the conditions of the RuuviTag.
   */
  const conditionQuery: RuuviTagConditionQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The query for the conditions of the RuuviTag.
   */
  const conditionAggregateQuery: RuuviTagConditionAggregateQuery = {
    from: dateRange.from,
    to: dateRange.to,
    interval: getRuuviTagConditionAggregateInterval()
  }

  /**
   * The query for the movements of the RuuviTag.
   */
  const movementQuery: RuuviTagMovementQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * Boots the component.
   */
  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)

      if (ruuviTag === null) {
        await loadRuuviTag(signal)
      }

      await Promise.all([
        loadAccelerationNow(signal),
        loadConditionNow(signal),
        loadBatteryNow(signal),
        loadMovementNow(signal)
      ])

      if (isUsingAggregates) {
        await Promise.all([
          loadAccelerationAggregates(signal),
          loadConditionAggregates(signal)
        ])
      } else {
        await Promise.all([loadAccelerations(signal), loadConditions(signal)])
      }
    } catch (error: unknown) {
      // TODO: Handle errors.
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Loads the RuuviTag.
   */
  async function loadRuuviTag(signal: AbortSignal): Promise<void> {
    if (id) {
      setRuuviTag(await getRuuviTag(id, { signal }))
    }
  }

  /**
   * Loads the accelerations of the RuuviTag.
   */
  async function loadAccelerations(signal: AbortSignal): Promise<void> {
    if (id) {
      setAccelerations(
        await getRuuviTagAccelerations(id, accelerationQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the batteries of the RuuviTag.
   */
  async function loadBatteries(signal: AbortSignal): Promise<void> {
    if (id) {
      setBatteries(
        await getRuuviTagBatteries(id, batteryQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the conditions of the RuuviTag.
   */
  async function loadConditions(signal: AbortSignal): Promise<void> {
    if (id) {
      setConditions(await getRuuviTagConditions(id, conditionQuery, { signal }))
    }
  }

  /**
   * Loads the movements of the RuuviTag.
   */
  async function loadMovements(signal: AbortSignal): Promise<void> {
    if (id) {
      setMovements(
        await getRuuviTagMovements(id, movementQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the acceleration agggregates of the RuuviTag.
   */
  async function loadAccelerationAggregates(
    signal: AbortSignal
  ): Promise<void> {
    if (id) {
      setAccelerationAggregates(
        await getRuuviTagAccelerationAggregates(
          id,
          accelerationAggregateQuery,
          { signal }
        )
      )
    }
  }

  /**
   * Loads the battery agggregates of the RuuviTag.
   */
  async function loadBatteryAggregates(signal: AbortSignal): Promise<void> {
    if (id) {
      setBatteryAggregates(
        await getRuuviTagBatteryAggregates(id, batteryAggregateQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the condition aggregates of the RuuviTag.
   */
  async function loadConditionAggregates(signal: AbortSignal): Promise<void> {
    if (id) {
      setConditionAggregates(
        await getRuuviTagConditionAggregates(id, conditionAggregateQuery, {
          signal
        })
      )
    }
  }

  /**
   * Loads the current acceleration of the RuuviTag.
   */
  async function loadAccelerationNow(signal: AbortSignal): Promise<void> {
    if (id) {
      setAccelerationNow(
        await getRuuviTagAccelerationNow(id, {
          signal
        })
      )
    }
  }

  /**
   * Loads the current battery of the RuuviTag.
   */
  async function loadBatteryNow(signal: AbortSignal): Promise<void> {
    if (id) {
      setBatteryNow(
        await getRuuviTagBatteryNow(id, {
          signal
        })
      )
    }
  }

  /**
   * Loads the current condition of the RuuviTag.
   */
  async function loadConditionNow(signal: AbortSignal): Promise<void> {
    if (id) {
      setConditionNow(
        await getRuuviTagConditionNow(id, {
          signal
        })
      )
    }
  }

  /**
   * Loads the current movement of the RuuviTag.
   */
  async function loadMovementNow(signal: AbortSignal): Promise<void> {
    if (id) {
      setMovementNow(
        await getRuuviTagMovementNow(id, {
          signal
        })
      )
    }
  }

  /**
   * Get the aggregate interval for the accelerations of the RuuviTag.
   */
  function getRuuviTagAccelerationAggregateInterval(): RuuviTagAccelerationAggregateInterval {
    if (differenceOfDateRangeInHours < Hours.THREE_DAYS) {
      return RuuviTagAccelerationAggregateInterval.FIVE_MINUTES
    } else if (differenceOfDateRangeInHours < Hours.SEVEN_DAYS) {
      return RuuviTagAccelerationAggregateInterval.FIFTEEN_MINUTES
    } else if (differenceOfDateRangeInHours <= Hours.THIRTY_ONE_DAYS) {
      return RuuviTagAccelerationAggregateInterval.ONE_HOUR
    } else {
      return RuuviTagAccelerationAggregateInterval.ONE_DAY
    }
  }

  /**
   * Get the aggregate interval for the batteries of the RuuviTag.
   */
  function getRuuviTagBatteryAggregateInterval(): RuuviTagBatteryAggregateInterval {
    if (differenceOfDateRangeInHours < Hours.THREE_DAYS) {
      return RuuviTagBatteryAggregateInterval.FIVE_MINUTES
    } else if (differenceOfDateRangeInHours < Hours.SEVEN_DAYS) {
      return RuuviTagBatteryAggregateInterval.FIFTEEN_MINUTES
    } else if (differenceOfDateRangeInHours <= Hours.THIRTY_ONE_DAYS) {
      return RuuviTagBatteryAggregateInterval.ONE_HOUR
    } else {
      return RuuviTagBatteryAggregateInterval.ONE_DAY
    }
  }

  /**
   * Get the aggregate interval for the conditions of the RuuviTag.
   */
  function getRuuviTagConditionAggregateInterval(): RuuviTagConditionAggregateInterval {
    if (differenceOfDateRangeInHours < Hours.THREE_DAYS) {
      return RuuviTagConditionAggregateInterval.FIVE_MINUTES
    } else if (differenceOfDateRangeInHours < Hours.SEVEN_DAYS) {
      return RuuviTagConditionAggregateInterval.FIFTEEN_MINUTES
    } else if (differenceOfDateRangeInHours <= Hours.THIRTY_ONE_DAYS) {
      return RuuviTagConditionAggregateInterval.ONE_HOUR
    } else {
      return RuuviTagConditionAggregateInterval.ONE_DAY
    }
  }

  /**
   * Boot the component when id or date range changes.
   */
  useEffect(() => {
    const controller = new AbortController()

    if (currentUser && id) {
      boot(controller.signal)
    }

    return () => controller.abort()
  }, [currentUser, id, dateRange])

  return (
    <>
      <Helmet title={t('conditionMonitoring')} />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column" gap={0}>
          {ruuviTag === null ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="120px"
              sx={{ fontSize: '1.5rem' }}
            />
          ) : (
            <Typography variant="h3" gutterBottom display="inline">
              <Stack direction="row" alignItems="center" gap={3}>
                <RuuviTagStatusIndicator ruuviTag={ruuviTag} />
                {ruuviTag?.asset?.name ?? formatMacAddress(ruuviTag.id)}
              </Stack>
            </Typography>
          )}

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {ruuviTag && ruuviTag?.asset?.project?.name && (
              <Link
                component={NavLink}
                to={`/projects/${ruuviTag.asset.projectId}`}
              >
                {ruuviTag.asset?.project?.name}
              </Link>
            )}

            {ruuviTag && ruuviTag?.asset?.project?.name ? (
              <Link
                component={NavLink}
                to={`/projects/${ruuviTag.asset.projectId}#monitoring `}
              >
                {t('deviceMonitoring')}
              </Link>
            ) : ruuviTag ? (
              <Link component={NavLink} to="/ruuvi-tags">
                {t('conditionMonitoring')}
              </Link>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                width="40px"
                height="1em"
              />
            )}

            {ruuviTag ? (
              <Typography>{formatMacAddress(ruuviTag.id)}</Typography>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                width="40px"
                height="1em"
              />
            )}
          </Breadcrumbs>
        </Stack>
        <Stack direction="row" gap={3}>
          <DateRangeBar showAutoRefreshToggle={true} />

          {ruuviTag ? (
            <>
              {isSuperadmin && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  endIcon={<BarChart />}
                  component={NavLink}
                  to={`/ruuvi-tags/${ruuviTag.id}/stats`}
                  sx={{ alignSelf: 'center' }}
                >
                  {t('stats')}
                </Button>
              )}
            </>
          ) : (
            <Skeleton
              animation="wave"
              variant="text"
              width="80px"
              sx={{ fontSize: '1.5rem' }}
            />
          )}
        </Stack>
      </Stack>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {ruuviTag && (
            <>
              <RuuviTagDetails
                ruuviTag={ruuviTag}
                onUpdate={(ruuviTag) => setRuuviTag(ruuviTag)}
              />
              <Divider sx={{ my: 6 }} />
            </>
          )}

          <Grid container spacing={6} mb={6}>
            {ruuviTag?.has_temperature_sensor && (
              <Grid item xs={12} lg={4}>
                <RuuviTagTemperatureStats
                  ruuviTagCondition={conditionNow}
                  loading={isLoading}
                />
              </Grid>
            )}

            {ruuviTag?.has_humidity_sensor && (
              <Grid item xs={12} lg={4}>
                <RuuviTagHumidityStats
                  ruuviTagCondition={conditionNow}
                  loading={isLoading}
                />
              </Grid>
            )}

            {ruuviTag?.has_pressure_sensor && (
              <Grid item xs={12} lg={4}>
                <RuuviTagPressureStats
                  ruuviTagCondition={conditionNow}
                  loading={isLoading}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={6} mb={6}>
            <Grid item xs={12} lg={4}>
              <RuuviTagMovementCountStats
                ruuviTagMovement={movementNow}
                loading={isLoading}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <RuuviTagBatteryVoltageStats
                ruuviTagBattery={batteryNow}
                ruuviTagCondition={conditionNow}
                loading={isLoading}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <RuuviTagSignalStats
                ruuviTagCondition={conditionNow}
                loading={isLoading}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 6 }} />

          <Grid container spacing={6}>
            {ruuviTag?.has_temperature_sensor && (
              <Grid item xs={12}>
                <RuuviTagTemperatureChart
                  conditions={
                    isUsingAggregates ? conditionAggregates : conditions
                  }
                  query={
                    isUsingAggregates ? conditionAggregateQuery : conditionQuery
                  }
                  loading={isLoading}
                />
              </Grid>
            )}

            {ruuviTag?.has_humidity_sensor && (
              <Grid item xs={12}>
                <RuuviTagHumidityChart
                  conditions={
                    isUsingAggregates ? conditionAggregates : conditions
                  }
                  query={
                    isUsingAggregates ? conditionAggregateQuery : conditionQuery
                  }
                  loading={isLoading}
                />
              </Grid>
            )}

            {ruuviTag?.has_pressure_sensor && (
              <Grid item xs={12}>
                <RuuviTagPressureChart
                  conditions={
                    isUsingAggregates ? conditionAggregates : conditions
                  }
                  query={
                    isUsingAggregates ? conditionAggregateQuery : conditionQuery
                  }
                  loading={isLoading}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <RuuviTagAccelerationAxisChart
                accelerations={
                  isUsingAggregates ? accelerationAggregates : accelerations
                }
                query={
                  isUsingAggregates
                    ? accelerationAggregateQuery
                    : accelerationQuery
                }
                loading={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <RuuviTagSignalChart
                conditions={
                  isUsingAggregates ? conditionAggregates : conditions
                }
                query={
                  isUsingAggregates ? conditionAggregateQuery : conditionQuery
                }
                loading={isLoading}
              />
            </Grid>

            {/*<Grid item xs={12}>
              <RuuviTagBatteryVoltageChart
                batteries={
                  isUsingAggregates ? batteryAggregates : batteries
                }
                query={
                  isUsingAggregates
                    ? batteryAggregateQuery
                    : batteryQuery
                }
              />
            </Grid>*/}

            {/*<Grid item xs={12}>
              <RuuviTagMovementCountChart
                movements={movements}
                query={movementQuery}
              />
            </Grid>*/}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
