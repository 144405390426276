import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { PowerSupply } from '../../types'
import powerSupplyService from '../../services/powerSupplyService'
import projectService from '../../services/projectService'

interface PowerSupplyState {
  powerSupplies: PowerSupply[]
  powerSupply: PowerSupply | undefined
  powerSupplyName: string
  show: boolean
  loading: boolean
}

const initialState: PowerSupplyState = {
  powerSupplies: [],
  powerSupply: undefined,
  powerSupplyName: '',
  show: false,
  loading: false
}

const powerSupplySlice = createSlice({
  name: 'powerSupplies',
  initialState,
  reducers: {
    setPowerSupplies(state, action) {
      state.powerSupplies = action.payload
    },
    setPowerSupply(state, action) {
      state.powerSupply = action.payload
    },
    setPowerSupplyName(state, action) {
      state.powerSupplyName = action.payload
    },
    createPowerSupply(state, action) {
      state.powerSupplies = [...state.powerSupplies, action.payload]
    },
    updatePowerSupply(state, action) {
      state.powerSupplies = state.powerSupplies.map((powerSupply) =>
        powerSupply.id === action.payload.id ? action.payload : powerSupply
      )
    },
    deletePowerSupply(state, action) {
      state.powerSupplies = state.powerSupplies.filter(
        (powerSupply) => powerSupply.id !== action.payload
      )
    },
    setShow(state, action) {
      state.show = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const {
  setShow,
  setPowerSupplies,
  setPowerSupply,
  setPowerSupplyName,
  createPowerSupply,
  updatePowerSupply,
  deletePowerSupply
} = powerSupplySlice.actions

export function getPowerSupplies() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(powerSupplySlice.actions.setLoading(true))
      const powerSupplies = await powerSupplyService.getPowerSupplies()
      dispatch(powerSupplySlice.actions.setPowerSupplies(powerSupplies))
    } finally {
      dispatch(powerSupplySlice.actions.setLoading(false))
    }
  }
}

export function getPowerSuppliesByProjectId(projectId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(powerSupplySlice.actions.setLoading(true))
      const powerSupplies = await projectService.getPowerSuppliesByProjectId(
        projectId
      )
      dispatch(powerSupplySlice.actions.setPowerSupplies(powerSupplies))
    } finally {
      dispatch(powerSupplySlice.actions.setLoading(false))
    }
  }
}

export function getPowerSupply(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(powerSupplySlice.actions.setLoading(true))
      const powerSupply = await powerSupplyService.getPowerSupply(id)
      dispatch(powerSupplySlice.actions.setPowerSupply(powerSupply))
    } finally {
      dispatch(powerSupplySlice.actions.setLoading(false))
    }
  }
}

export default powerSupplySlice.reducer
