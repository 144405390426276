import React from 'react'
import styled from 'styled-components/macro'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  Grid,
  CardHeader,
  Divider as MuiDivider
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { createLift } from '../../redux/slices/lifts'
import { RootState } from '../../redux/store'
import { useTranslation } from 'react-i18next'
import { setStep } from '../../redux/slices/steps'
import liftService from '../../services/liftService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { LiftErrors } from '../../enums/LiftErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { Lift, LiftInput, Project } from '../../types'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Divider = styled(MuiDivider)(spacing)

interface LiftFormProps {
  project: Project
}

// eslint-disable-next-line
export default function LiftForm({ project }: LiftFormProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const { step } = useSelector((state: RootState) => state.steps)

  const initialValues: LiftInput = {
    name: '',
    project: project.id
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required')
  })

  const { currentUser } = useAuth()

  const handleSubmit = async (
    values: LiftInput,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const createdLift: Lift = await liftService.createLift(values)

      dispatch(createLift(createdLift))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('liftCreateSuccess')
        )
      )
      dispatch(setStep(step - 2))
      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: LiftErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: LiftErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(LiftErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardHeader
            title={t('Manufacturing.instructionsForLift')}
            align="center"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider my={6} />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl>
                  <TextField
                    name="name"
                    label={t('Manufacturing.lift')}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={4}
                  fullWidth
                >
                  {t('Manufacturing.save')}
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
