import { useTranslation } from 'react-i18next'
import { ChartOptions, Chart } from 'chart.js'

import SensorAggregateChart from './SensorAggregateChart'

import DateRange from '../types/DateRange'
import Sensor from '../types/Sensor'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import { Device } from '../types'
import SensorAggregateQuery from '../types/SensorAggregateQuery'
import annotationPlugin from 'chartjs-plugin-annotation'
import { calculateMaxKwh, calculateMaxPhaseKwH } from '../utils/sensor'
import { orange, red } from '@mui/material/colors'
import { ForcedYAxis, ScalingYAxis } from '../types/YAxisOptions'
Chart.register(annotationPlugin)

interface DevicePowerChartProps {
  device: Device
  dateRange: DateRange
  sensors: Sensor[]
  sensorAggregates: SensorAggregate[]
  sensorAggregateOptions: SensorAggregateOptions
  sensor?: Sensor
  showTotalPowerAnnotation?: boolean
  showPhasePowerAnnotation?: boolean
  yAxisOption: ScalingYAxis | ForcedYAxis
}

export default function DevicePowerChart({
  device,
  dateRange,
  sensors = [],
  sensorAggregates,
  sensorAggregateOptions,
  sensor,
  showTotalPowerAnnotation,
  showPhasePowerAnnotation,
  yAxisOption
}: DevicePowerChartProps) {
  const totalPowerAnnotationY = device?.powerSupply
    ? //@ts-ignore
      calculateMaxKwh(parseInt(device?.powerSupply?.fuse))
    : 0
  const phasePowerAnnotationY = device?.powerSupply
    ? //@ts-ignore
      calculateMaxPhaseKwH(parseInt(device?.powerSupply?.fuse))
    : 0

  const chartOptions: ChartOptions = {
    plugins: {
      annotation: {
        annotations: {
          line1: {
            display: showTotalPowerAnnotation,
            drawTime: 'afterDatasetsDraw',
            type: 'line',
            yMin: totalPowerAnnotationY,
            yMax: totalPowerAnnotationY,
            borderColor: red[500],
            borderWidth: 2
          },
          line2: {
            display: showPhasePowerAnnotation,
            drawTime: 'afterDatasetsDraw',
            type: 'line',
            yMin: phasePowerAnnotationY,
            yMax: phasePowerAnnotationY,
            borderColor: orange[500],
            borderWidth: 2
          }
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: yAxisOption
    }
  }

  return (
    <SensorAggregateChart
      dateRange={dateRange}
      sensors={sensors}
      sensorAggregates={sensorAggregates}
      options={sensorAggregateOptions}
      chartOptions={chartOptions}
      chartWrapperHeight={'250px'}
    />
  )
}
