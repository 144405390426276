import { useTranslation } from 'react-i18next'
import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Stack,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { Zap as PowerSupplyIcon } from 'react-feather'
import { RuuviTag } from '../types'
import RuuviTagDataCard from './RuuviTagDataCard'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Chip = styled(MuiChip)(spacing)

export interface RuuviTagCardProps {
  ruuviTag: RuuviTag
}

export default function RuuviTagCard({ ruuviTag }: RuuviTagCardProps) {
  return (
    <Card variant="outlined" sx={{ width: '168px', height: '168px' }}>
      <CardContent
        padding={0}
        sx={{ paddingBottom: '0px !important', height: '100%' }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="space-between"
          height="100%"
        >
          <Stack
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center"
            px={3}
            pt={3}
            pb={1}
          >
            <Typography fontWeight={700} fontSize={14}>
              {ruuviTag.asset?.name}
            </Typography>
          </Stack>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingX={3}
          >
            <RuuviTagDataCard key={ruuviTag.id} ruuviTag={ruuviTag} />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={3}
          >
            <Chip
              icon={<PowerSupplyIcon size={10} />}
              label={ruuviTag.deviceName}
              size="small"
              color="secondary"
              sx={{
                fontSize: 10
              }}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
