import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { subDays } from 'date-fns'
import useAxios from '../hooks/useAxios'
import EnergyPrice from '../types/EnergyPrice'
import EnergyPriceQuery from '../types/EnergyPriceQuery'
import GeoCoordinate from '../types/GeoCoordinate'

const axios: AxiosInstance = useAxios()

export async function getEnergyPrices(
  { lat, long, from, to }: EnergyPriceQuery,
  config?: AxiosRequestConfig
): Promise<EnergyPrice[]> {
  const response: AxiosResponse = await axios.get('/energy-prices', {
    ...config,
    params: {
      lat,
      long,
      from: from.toISOString(),
      to: to.toISOString()
    }
  })

  return response.data
}

export async function getEnergyPriceNow(
  { lat, long }: GeoCoordinate,
  config?: AxiosRequestConfig
): Promise<EnergyPrice> {
  const response: AxiosResponse = await axios.get('/energy-prices', {
    ...config,
    params: {
      lat,
      long,
      from: subDays(new Date(), 1).toISOString(),
      to: new Date().toISOString()
    }
  })

  return response.data[response.data.length - 1]
}
