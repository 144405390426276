import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { ConfluencePages, UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import Users from '../pages/pages/Users'
import User from '../pages/pages/Profile'
import Redirect from '../pages/Redirect'

export default [
  {
    path: '/users',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.USERS}>
          <DashboardLayout>
            <Users />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/users/:id',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.USERS}>
          <DashboardLayout>
            <User />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/profile/:id',
    element: <Redirect to="/users/:id" />
  }
]
