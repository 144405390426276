import DashboardLayout from '../layouts/Dashboard'
import RequestAccessIndex from '../pages/pages/RequestAccessIndex'

export default [
  {
    path: '/request-access',
    element: (
      <DashboardLayout>
        <RequestAccessIndex />
      </DashboardLayout>
    )
  }
]
