import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { UserRole } from '../enums'
import Blueprint from '../pages/pages/Blueprint'

export default [
  {
    path: '/blueprint/project/:projectId/level/:levelId',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.INSTALLER
        ]}
      >
        <DashboardLayout>
          <Blueprint />
        </DashboardLayout>
      </AuthGuard>
    )
  }
]
