import { ChangeEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, TextField, Typography } from '@mui/material'
import useAppSettings from '../hooks/useAppSettings'
import { CalculationMode, StartDateMode } from '../enums'
import EnergyPriceType from '../enums/EnergyPriceType'
import {
  DateRange,
  EnergyCostSettings,
  EnergyCostQuery,
  EnergyPrice,
  SensorAggregate,
  StatsFigure,
  EnergyCost
} from '../types'
import {
  calculateEnergyCostsForFixedEnergyPrice,
  calculateEnergyCostsForSpotEnergyPrices
} from '../utils/energyCost'
import DeviceStats from './DeviceStats'
import CalculationModeToggle from './CalculationModeToggle'
import StartDateModeToggle from './StartDateModeToggle'
import EnergyCostChart from './EnergyCostChart'
import EnergyPriceModeToggle from './EnergyPriceModeToggle'

interface EnergyCostStatsProps {
  energyCosts?: EnergyCost[]
  energyCostsBeforeDateRange?: EnergyCost[]
  area?: number
  dateRange: DateRange
  query: EnergyCostQuery
  settings: EnergyCostSettings
  loading?: boolean
  showCalculationModeOptions?: boolean
  calculationModeOptions?: CalculationMode[]
  showStartDateModeOptions?: boolean
  startDateModeOptions?: StartDateMode[]
  onChangeCalculationMode(calculationMode: CalculationMode): any
  onChangeStartDateMode(startDateMode: StartDateMode): any
  onChangeEnergyPriceType(energyPriceType: EnergyPriceType): any
  onChangeEnergyPrice(energyPrice: number | null): any

  // @deprecated
  sensorAggregates?: SensorAggregate[]
  sensorAggregatesBeforeDateRange?: SensorAggregate[]
  energyPrices?: EnergyPrice[]
  energyPricesBeforeDateRange?: EnergyPrice[]
}

export default function EnergyCostStats({
  energyCosts,
  energyCostsBeforeDateRange,
  area,
  dateRange,
  query,
  settings,
  loading = false,
  showCalculationModeOptions = true,
  showStartDateModeOptions = true,
  calculationModeOptions,
  startDateModeOptions,
  sensorAggregates = [],
  sensorAggregatesBeforeDateRange = [],
  energyPrices = [],
  energyPricesBeforeDateRange = [],
  onChangeCalculationMode,
  onChangeStartDateMode,
  onChangeEnergyPriceType,
  onChangeEnergyPrice
}: EnergyCostStatsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The total energy costs since before the date range.
   */
  const energyCostBeforeDateRange: number =
    settings.startDateMode !== StartDateMode.SELECTED_DATE
      ? energyCostsBeforeDateRange
        ? calculateTotalEnergyCostUsingEnergyCosts(energyCostsBeforeDateRange)
        : calculateTotalEnergyCostUsingSensorAggregates(
            sensorAggregatesBeforeDateRange,
            energyPricesBeforeDateRange
          )
      : 0

  /**
   * The total energy costs of date range.
   */
  const energyCostOfDateRange: number = energyCosts
    ? calculateTotalEnergyCostUsingEnergyCosts(energyCosts)
    : calculateTotalEnergyCostUsingSensorAggregates(
        sensorAggregates,
        energyPrices
      )

  /**
   * The total energy costs as euros.
   */
  const energyCostAsEuros: number =
    energyCostBeforeDateRange + energyCostOfDateRange

  /**
   * The energy costs per square meter as euros.
   */
  const energyCostPerSquareMeterAsEuros: number | undefined = area
    ? energyCostAsEuros / area
    : undefined

  /**
   * The stats figures.
   */
  const statsFigures: StatsFigure[] = makeStatsFigures()

  /**
   * Calculate the energy cost.
   */
  function calculateTotalEnergyCostUsingSensorAggregates(
    sensorAggregates: SensorAggregate[],
    energyPrices: EnergyPrice[]
  ): number {
    switch (settings.energyPriceType) {
      case EnergyPriceType.SPOT:
        return calculateEnergyCostsForSpotEnergyPrices(
          sensorAggregates,
          energyPrices
        )

      case EnergyPriceType.FIXED:
        return calculateEnergyCostsForFixedEnergyPrice(
          sensorAggregates,
          settings.energyPrice ?? 0
        )
    }
  }

  /**
   * Calculate the total energy cost using energy costs.
   */
  function calculateTotalEnergyCostUsingEnergyCosts(
    energyCosts: EnergyCost[]
  ): number {
    if (!energyCosts) {
      return 0
    }

    return energyCosts
      .map((energyCost) => {
        return energyCost.value
      })
      .reduce((sum, value) => {
        return sum + value
      }, 0)
  }

  /**
   * Change the calculation mode.
   */
  function changeCalculationMode(
    event: MouseEvent<HTMLElement>,
    calculationMode: CalculationMode
  ): void {
    onChangeCalculationMode(calculationMode)
  }

  /**
   * Change the start date mode.
   */
  function changeStartDateMode(
    event: MouseEvent<HTMLElement>,
    startDateMode: StartDateMode
  ): void {
    onChangeStartDateMode(startDateMode)
  }

  /**
   * Change the energy price mode.
   */
  function changeEnergyPriceType(
    event: React.MouseEvent<HTMLElement>,
    energyPriceType: EnergyPriceType
  ): void {
    onChangeEnergyPriceType(energyPriceType)
  }

  /**
   * Change the energy price.
   */
  function changeEnergyPrice(event: ChangeEvent<HTMLInputElement>): void {
    const energyPrice =
      event.target.value !== '' ? parseFloat(event.target.value) : null

    onChangeEnergyPrice(energyPrice)
  }

  /**
   * Calculate the energy consumption.
   */
  function calculateEnergyConsumption(
    sensorAggregates: SensorAggregate[]
  ): number {
    return sensorAggregates.reduce(
      (sum, sensorAggregate) => sum + sensorAggregate.avg_kw,
      0
    )
  }

  /**
   * Make the stats figures.
   */
  function makeStatsFigures(): StatsFigure[] {
    const statsFigures: StatsFigure[] = []

    statsFigures.push({
      value: energyCostAsEuros.toFixed(2),
      unit: '€'
    })

    if (energyCostPerSquareMeterAsEuros) {
      statsFigures.push({
        value: energyCostPerSquareMeterAsEuros.toFixed(2),
        unit: '€/m2'
      })
    }

    return statsFigures
  }

  return (
    <DeviceStats
      title={t('energyCost')}
      figures={statsFigures}
      dateRange={
        settings.startDateMode !== StartDateMode.SELECTED_DATE
          ? dateRange
          : undefined
      }
      loading={loading}
      chart={
        <EnergyCostChart
          energyCosts={energyCosts}
          initialEnergyCost={energyCostBeforeDateRange}
          query={query}
          settings={settings}
          /*sensorAggregates={sensorAggregates}
          energyPrices={energyPrices}*/
        />
      }
      menu={
        <Stack direction="column" spacing={3}>
          {showCalculationModeOptions && (
            <CalculationModeToggle
              value={settings.calculationMode}
              options={calculationModeOptions}
              onChange={changeCalculationMode}
            />
          )}

          {showStartDateModeOptions && (
            <StartDateModeToggle
              value={settings.startDateMode}
              options={startDateModeOptions}
              onChange={changeStartDateMode}
            />
          )}

          {/*<EnergyPriceModeToggle
            value={settings.energyPriceType}
            onChange={changeEnergyPriceType}
          />*/}

          {/*settings.energyPriceType === EnergyPriceType.FIXED && (
            <TextField
              type="number"
              value={settings.energyPrice}
              label={t('c/kWh')}
              helperText={t('energyPriceHelperText')}
              onClick={(event) => event.stopPropagation()}
              onChange={changeEnergyPrice}
            />
          )*/}

          {/*<Typography fontSize="0.75rem" mt={3}>
            {t('energyPrice')}
          </Typography>

          <TextField
            type="number"
            value={settings.energyPrice}
            label={t('c/kWh')}
            onClick={(event) => event.stopPropagation()}
            onChange={changeEnergyPrice}
            disabled
          />*/}
        </Stack>
      }
    />
  )
}
