import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'
import { spacing } from '@mui/system'
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Link
} from '@mui/material'

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Divider = styled(MuiDivider)(spacing)

export interface Breadcrumb {
  label: string
  link?: string
}

export interface PageHeaderProps {
  title: string
  heading: string
  breadcrumbs?: Breadcrumb[]
}

export default function PageHeader({
  title,
  heading,
  breadcrumbs = []
}: PageHeaderProps) {
  return (
    <>
      <Helmet title={title} />

      <Typography variant="h3" gutterBottom display="inline">
        {heading}
      </Typography>

      {breadcrumbs.length > 0 && (
        <Breadcrumbs mt={2}>
          {breadcrumbs?.map((breadcrumb, index) =>
            breadcrumb.link ? (
              <Link component={NavLink} to={breadcrumb.link} key={index}>
                {breadcrumb.label}
              </Link>
            ) : (
              <Typography key={index}>{breadcrumb.label}</Typography>
            )
          )}
        </Breadcrumbs>
      )}

      <Divider my={6} />
    </>
  )
}
