import { Circle } from '@mui/icons-material'
import { useTheme } from '@mui/system'
import { AssetType } from '../enums/AssetType'
import { Asset } from '../types'
import DeviceStatusIndicator from './DeviceStatusIndicator'
import RuuviTagStatusIndicator from './RuuviTagStatusIndicator'

/**
 * The props of the asset status indicator.
 */
interface AssetStatusIndicatorProps {
  /**
   * The asset.
   */
  asset: Asset
}

/**
 * An indicator for the asset status.
 */
export default function AssetStatusIndicator({
  asset
}: AssetStatusIndicatorProps): JSX.Element {
  /**
   * The theme.
   */
  const theme = useTheme()

  return (
    <>
      {asset.type === AssetType.DEVICE && asset.device ? (
        <DeviceStatusIndicator device={asset.device} />
      ) : asset.type === AssetType.RUUVI_TAG && asset.ruuviTag ? (
        <RuuviTagStatusIndicator ruuviTag={asset.ruuviTag} />
      ) : (
        <Circle fontSize="inherit" color={theme.palette.text.secondary} />
      )}
    </>
  )
}
