import { blue, green, red } from '@mui/material/colors'
import { Device, GeoCoordinate } from '../types'
import { DeviceSignal } from '../enums/DeviceSignal'

enum Helsinki {
  LAT = 60.169857,
  LONG = 24.938379
}

enum Stockholm {
  LAT = 59.329323,
  LONG = 18.068581
}

enum Oslo {
  LAT = 59.913868,
  LONG = 10.752245
}

export function getDeviceGeoCoordinate(device: Device): GeoCoordinate {
  // FIXME: Throw error when device's geo coordinates cannot be
  // resolved instead of using Helsinki's coordinates as fallback.

  const lat =
    device?.asset?.project?.lat !== undefined &&
    device?.asset?.project?.lat !== 0
      ? device?.asset?.project?.lat
      : Helsinki.LAT

  const long =
    device?.asset?.project?.long !== undefined &&
    device?.asset?.project?.long !== 0
      ? device?.asset?.project?.long
      : Helsinki.LONG

  return {
    lat,
    long
  }
}

export function checkRssiColor(value: number) {
  if (value > -80 || value == -255) {
    return green[500]
  } else if (value == -254) {
    return blue[500]
  } else return red[500]
}

export function checkSignal(value: number) {
  if (value > -60) {
    return DeviceSignal.EXCELLENT as string
  } else if (value > -70) {
    return DeviceSignal.VERY_GOOD as string
  } else if (value > -80) {
    return DeviceSignal.GOOD as string
  } else if (value > -90) {
    return DeviceSignal.LOW as string
  } else if (value < -90 && value > -254) {
    return DeviceSignal.VERY_LOW as string
  } else if (value === -254) {
    return DeviceSignal.BLUETOOTH as string
  } else if (value === -255) {
    return DeviceSignal.SUB_DEVICE as string
  }
}
