import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Link,
  Typography,
  Grid,
  TextField,
  Chip
} from '@mui/material'

import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { getProject } from '../redux/slices/projects'

import translateProjectType from '../utils/translateProjectType'

import useTheme from '../hooks/useTheme'
import { ActiveProject, Project } from '../types'
import translateProjectState from '../utils/translateProjectState'
import checkProjectStatus from '../utils/checkProjectStatus'
import { ProjectStateType } from '../enums/ProjectStateType'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

export interface ProjectTableProps {
  projects?: Project[]
  activeProjects?: ActiveProject[]
}

export default function ProjectTable({
  projects,
  activeProjects
}: ProjectTableProps) {
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [t] = useTranslation('common')

  //TODO: ADD LOADING

  const handleSetProject = (id: number) => {
    dispatch(getProject(id))
  }

  const columns = [
    {
      field: 'state',
      headerName: t('status'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Chip
            label={t(translateProjectState(params.row.state) as string)}
            size="small"
            color={checkProjectStatus(params.row.state as ProjectStateType)}
          />
        )
      }
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Link
            onClick={() => handleSetProject(params.row.id)}
            component={NavLink}
            to={`/projects/${params.row.id}`}
          >
            {params.row.name}
          </Link>
        )
      }
    },
    {
      field: 'address',
      headerName: t('address'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'city',
      headerName: t('city'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'zip',
      headerName: t('zip'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'area',
      headerName: t('area'),
      type: 'number',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'type',
      headerName: t('type'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueGetter: (params: any) =>
        t(translateProjectType(params.row.type) as string)
    }
  ]

  function getRows() {
    if (projects) {
      return projects
    }

    if (activeProjects) {
      return activeProjects
    }

    if (!projects && !activeProjects) {
      return []
    }
  }

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <React.Fragment>
      {projects ? (
        <Card>
          <CardContent>
            <DataGrid
              rows={projects}
              columns={columns}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 }
                }
              }}
            />
          </CardContent>
        </Card>
      ) : (
        <DataGrid
          rows={activeProjects ?? []}
          columns={columns}
          autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar
          }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 }
            }
          }}
        />
      )}
    </React.Fragment>
  )
}
