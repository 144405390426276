import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { OrderItem } from '../../types'
import orderService from '../../services/orderService'

interface OrderItemsState {
  orderItem: OrderItem | undefined
  orderItems: OrderItem[]
  show: boolean
  loading: boolean
}

const initialState: OrderItemsState = {
  orderItem: undefined,
  orderItems: [],
  show: false,
  loading: false
}

const orderItemSlice = createSlice({
  name: 'orderItems',
  initialState,
  reducers: {
    setOrderItems(state, action) {
      state.orderItems = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setOrderItem(state, action) {
      state.orderItem = action.payload
    },
    createOrderItem(state, action) {
      state.orderItems = [...state.orderItems, action.payload]
    },
    updateOrderItem(state, action) {
      state.orderItems = state.orderItems
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteOrderItem(state, action) {
      state.orderItems = state.orderItems.filter((u) => u.id !== action.payload)
    }
  }
})

export const { setOrderItems, setOrderItem, createOrderItem, deleteOrderItem } =
  orderItemSlice.actions

export function getOrderItems(orderId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(orderItemSlice.actions.setLoading(true))
      const orderItems = await orderService.getOrderItems(orderId)
      dispatch(orderItemSlice.actions.setOrderItems(orderItems))
    } finally {
      dispatch(orderItemSlice.actions.setLoading(false))
    }
  }
}

export function getOrderItem(orderId: number, orderItemId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(orderItemSlice.actions.setLoading(true))
      const orderItem: OrderItem = await orderService.getOrderItem(
        orderId,
        orderItemId
      )
      dispatch(orderItemSlice.actions.setOrderItem(orderItem))
    } finally {
      dispatch(orderItemSlice.actions.setLoading(false))
    }
  }
}

export default orderItemSlice.reducer
