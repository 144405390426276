export enum TimePeriod {
  ONE_HOUR = '1h',
  TWENTYFOUR_HOURS = '24h',
  THREE_DAYS = '3d',
  SEVEN_DAYS = '7d',
  THIRTY_DAYS = '30d',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  CUSTOM = 'custom'
}

export default TimePeriod
