export enum ProjectType {
  OMAKOTITALO = 'Omakotitalo',
  RIVITALO = 'Rivitalo',
  KERROSTALO = 'Kerrostalo',
  LIIKETILA = 'Liiketila',
  INFRA = 'Infra',
  SAIRAALA = 'Sairaala',
  HALLI = 'Halli',
  MUU = 'Muu'
}
