import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@mui/material'
import { Device } from '../types'
import RuuviTagScroller from './RuuviTagScroller'

interface DeviceRuuviTagGridProps {
  device: Device
}

export default function DeviceRuuviTagGrid({
  device
}: DeviceRuuviTagGridProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  return (
    <>
      <RuuviTagScroller
        query={{ deviceName: device.name }}
        empty={
          <Alert severity="warning">
            <AlertTitle>{t('noConditionMonitoring')}</AlertTitle>
            {t('deviceDoesNotHaveConditionMonitoring')}
          </Alert>
        }
      />
    </>
  )
}
