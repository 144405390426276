import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableRow,
  Divider as MuiDivider
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import {
  getProjectAlertRuleTemplate,
  updateProjectAlertRules
} from '../services/projectService'
import LoadingState from './LoadingState'
import { useTranslation } from 'react-i18next'
import { Project } from '../types'
import { AlertGroup, AlertRule, AlertTemplate } from '../types/Alerts'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { getProjectAlertRules } from '../redux/slices/projects'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { useAuth } from '../hooks'
import { AlertErrors } from '../enums/AlertErrors'
import { useTheme } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { checkAlertValueUnit, buildAlertStructure } from '../utils/alert'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import NotificationAddIcon from '@mui/icons-material/NotificationAdd'
import UserAlertManagementPopUp from './UserAlertManagementPopUp'
import {
  getUserAlertRulesByProject,
  setShowUserAlertManagementPopUp
} from '../redux/slices/users'

const Divider = styled(MuiDivider)(spacing)

interface ProjectAlertManagementTableProps {
  project?: Project
  userCanManageProject: boolean
}

export type AlertDataGridRow = {
  id: string
  group?: string
  name: string
  value: number
  enabled: boolean
  readonly?: boolean
  unit?: string
}

export default function ProjectAlertManagementTable({
  project,
  userCanManageProject
}: ProjectAlertManagementTableProps) {
  /**
   * The translate function
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function
   */
  const dispatch = useDispatch()

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * The project alert rules
   */
  const { projectAlertRules } = useSelector(
    (state: RootState) => state.projects
  )

  /**
   * The loading state
   */
  const loading = project === undefined

  const { userAlertRules, showUserAlertManagementPopUp } = useSelector(
    (state: RootState) => state.users
  )

  /**
   * The theme
   */
  const theme = useTheme()

  /**
   * Mobile breakpoint
   */
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'))

  /**
   * Indicates is alert info open
   */
  const [openAlertInfo, setOpenAlertInfo] = useState(false)

  /**
   * The alert template
   */
  const [alertTemplate, setAlertTemplate] = useState<AlertTemplate[]>([])

  /**
   * Indicates should data be refreshed
   */
  const [refreshData, setRefreshData] = useState<boolean>(false)

  useEffect(() => {
    if (project) {
      dispatch(getProjectAlertRules(project.id))
      dispatch(getUserAlertRulesByProject(currentUser?.id, project.id))
      loadTemplate()
      setRefreshData(false)
    }
  }, [currentUser, project, refreshData === true])

  async function loadTemplate(): Promise<void> {
    setAlertTemplate(await getProjectAlertRuleTemplate())
  }

  /**
   * Indicates Is user submitting changes
   */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  /**
   * Update alert rules
   * @param data
   */
  async function handleUpdateAlerts(data: AlertDataGridRow[]) {
    try {
      /**
       * Combine existing rules with new rules. Otherwise backend clears existing rules after save
       */
      data = data.concat(showAlertsTurnedOff ? rows : rulesTurnedOff)

      setIsSubmitting(true)
      const alertRules = buildAlertStructure(data, alertTemplate)
      await updateProjectAlertRules(project?.id as number, alertRules)

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('alertUpdateSuccess')
        )
      )
      setRowIsNotModified(true)
      setRefreshData(true)

      /**
       * If user is enabling new rules open user alertmanagement popup
       */
      if (showAlertsTurnedOff) {
        dispatch(setShowUserAlertManagementPopUp(true))
      }
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: AlertErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      if (showAlertsTurnedOff) {
        setShowAlertsTurnedOff(false)
      }
      setIsSubmitting(false)
    }
  }

  /**
   * The datagrid columns
   */
  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      width: lessThanSmall ? 150 : 350,
      editable: false
    },
    {
      field: 'value',
      headerName: t('value'),
      type: 'number',
      width: lessThanSmall ? 150 : 350,
      editable: userCanManageProject,
      valueFormatter: (params: any) => {
        const alertValueUnit = projectAlertRules
          ? checkAlertValueUnit(params.id, projectAlertRules)
          : ''

        if (params.value === null) {
          return ''
        }
        return `${params.value.toLocaleString()} ${alertValueUnit}`
      }
    },
    {
      field: 'enabled',
      headerName: t('enabled'),
      type: 'boolean',
      width: lessThanSmall ? 150 : 350,
      editable: userCanManageProject
    }
  ]

  /**
   * The datagrid rows
   */
  const rows: AlertDataGridRow[] = []

  /**
   * Modify rule rows to more readable form and hide alerts that are turned off
   */
  projectAlertRules?.map((group: AlertGroup) => {
    group.alerts.map((alert: AlertRule) => {
      alert.enabled &&
        rows.push({
          id: alert.name,
          group: group.name,
          name: alert.name,
          value: alert.variables.param1.value,
          enabled: alert.enabled,
          readonly: alert.variables.param1.readonly
        })
    })
  })

  /**
   * The project alert rulest that are turned off
   */
  const rulesTurnedOff: AlertDataGridRow[] = []

  /**
   * Modify rule rows to more readable form and hide alerts that are turned off
   */
  projectAlertRules?.map((group: AlertGroup) => {
    group.alerts.map((alert: AlertRule) => {
      alert.enabled === false &&
        rulesTurnedOff.push({
          id: alert.name,
          group: group.name,
          name: alert.name,
          value: alert.variables.param1.value,
          enabled: alert.enabled,
          readonly: alert.variables.param1.readonly
        })
    })
  })

  /**
   * The popup datagrid columns
   */
  const popUpColumns = [
    {
      field: 'name',
      headerName: t('name'),
      width: 200,
      editable: false
    },
    {
      field: 'value',
      headerName: t('value'),
      type: 'number',
      width: 200,
      editable: userCanManageProject,
      valueFormatter: (params: any) => {
        const alertValueUnit = projectAlertRules
          ? checkAlertValueUnit(params.id, projectAlertRules)
          : ''

        if (params.value === null) {
          return ''
        }
        return `${params.value.toLocaleString()} ${alertValueUnit}`
      }
    },
    {
      field: 'enabled',
      headerName: t('enabled'),
      type: 'boolean',
      width: 100,
      editable: userCanManageProject
    }
  ]

  /**
   * Handle datagrid row change
   * @param row
   */
  function handleRowChange(row: AlertDataGridRow) {
    //Updated row
    const target = showAlertsTurnedOff
      ? rulesTurnedOff.find((obj: AlertDataGridRow) => obj.id === row.id)
      : rows.find((obj: AlertDataGridRow) => obj.id === row.id)

    Object.assign(target as AlertDataGridRow, {
      id: row.name,
      group: target?.group,
      name: row.name,
      value: row.value,
      enabled: row.enabled
    })
    setRowIsNotModified(false)
  }

  function handleRowDataChange(params: any) {
    const alertName = Object.keys(params)
    const alertValues: any = Object.values(params)
    if (alertName[0] && alertValues[0]) {
      handleRowChange({
        id: alertName[0],
        name: alertName[0],
        value: alertValues[0].value.value,
        enabled: alertValues[0].enabled.value
      })
    }
  }

  /**
   * Indicates is row modified
   */
  const [rowIsNotModiefied, setRowIsNotModified] = useState(true)

  /**
   * The template
   */
  const templateRows: AlertDataGridRow[] = []

  /**
   * Modify template to more readable form
   */
  alertTemplate.forEach((template) => {
    Object.entries(template.defaults).forEach(([alertName, alert]) => {
      templateRows.push({
        id: alertName,
        group: template.name,
        name: alertName,
        enabled: alert.enabled,
        value: alert.variables.param1.value,
        unit: alert.variables.param1.unit
      })
    })
  })

  /**
   * Reset alerts to default
   * @param templateRows
   */
  function handleAlertReset(templateRows: AlertDataGridRow[]) {
    handleUpdateAlerts(templateRows)
    setOpenAlertInfo(false)
  }

  /**
   * Show alert that are turned off
   */
  const [showAlertsTurnedOff, setShowAlertsTurnedOff] = useState<boolean>(false)

  /**
   * Handle close alert popup
   */
  function handleCloseAlertPopUp() {
    setShowAlertsTurnedOff(false)
  }

  function handleCloseUserAlertManagement() {
    dispatch(setShowUserAlertManagementPopUp(false))
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid xs={6}>
          <Typography sx={{ mt: 10, mb: 4, ml: 6 }} variant="h4">
            {t('projectAlerts')}
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Typography align="right" sx={{ mt: 10, mb: 4, mr: 6 }}>
            <NotificationAddIcon
              style={{ cursor: 'pointer' }}
              onClick={() => setShowAlertsTurnedOff(true)}
            />
          </Typography>
        </Grid>
      </Grid>

      <Dialog
        open={showAlertsTurnedOff}
        onClose={handleCloseAlertPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Typography align="left" sx={{ mb: 4, mt: 4, ml: 4 }} variant="h6">
              {t('addNewAlertsForProject')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">
              <Button
                disabled={rowIsNotModiefied}
                sx={{ mt: 4, mb: 4, mr: 4 }}
                variant="contained"
                onClick={() => handleUpdateAlerts(rulesTurnedOff)}
              >
                {t('save')}
              </Button>
            </Typography>
          </Grid>
        </Grid>
        <DialogContent>
          {loading || isSubmitting ? (
            <LoadingState />
          ) : (
            <DataGrid
              rows={rulesTurnedOff}
              columns={popUpColumns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              editMode="row"
              onEditRowsModelChange={(params: any) =>
                handleRowDataChange(params)
              }
              disableColumnMenu
              autoHeight
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleCloseAlertPopUp} autoFocus>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showUserAlertManagementPopUp}
        onClose={handleCloseUserAlertManagement}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogContent>
          <UserAlertManagementPopUp
            user={currentUser}
            projectId={project?.id as number}
            projectAlertRules={projectAlertRules}
            loading={loading}
            userAlertRules={userAlertRules}
            updatingOwnSettings={true}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openAlertInfo}
        onClose={() => setOpenAlertInfo(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <DialogActions>
            {userCanManageProject && (
              <Typography align="right">
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => handleAlertReset(templateRows)}
                >
                  {t('restoreToDefault')}
                </Button>
              </Typography>
            )}
          </DialogActions>
          {t('alertInfoTitle')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table>
              {templateRows.map((rule) => (
                <TableRow key={rule.name}>
                  {rule.name}: {rule.value} ({rule.unit})
                </TableRow>
              ))}
            </Table>
          </DialogContentText>
          <Divider my={6} />
          <DialogContentText>{t('alertInfoText')}</DialogContentText>
          <Divider my={6} />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenAlertInfo(false)}
            autoFocus
          >
            {t('understood')}
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        {userCanManageProject && (
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Typography align="left">
                <IconButton
                  size="small"
                  onClick={() => setOpenAlertInfo(true)}
                  sx={{ mt: 4, mb: 4, ml: 4 }}
                >
                  <InfoIcon />
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">
                <Button
                  disabled={rowIsNotModiefied}
                  sx={{ mt: 4, mb: 4, mr: 4 }}
                  variant="contained"
                  onClick={() => handleUpdateAlerts(rows)}
                >
                  {t('save')}
                </Button>
              </Typography>
            </Grid>
          </Grid>
        )}

        {loading || isSubmitting ? (
          <LoadingState />
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            editMode="row"
            onEditRowsModelChange={(params: any) => handleRowDataChange(params)}
            disableColumnMenu
            autoHeight
            // isCellEditable={(params) => params.row.readonly === false}
          />
        )}
      </Card>
    </React.Fragment>
  )
}
