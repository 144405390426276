import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { CatalogProduct } from '../../types'
import catalogProductService from '../../services/catalogProductService'

interface CatalogProductState {
  catalogProducts: CatalogProduct[]
  catalogProduct: CatalogProduct | undefined
  show: boolean
  status: 'idle' | 'loading' | 'failed'
  loading: boolean
}

const initialState: CatalogProductState = {
  catalogProducts: [],
  catalogProduct: undefined,
  show: false,
  status: 'idle',
  loading: false
}

const catalogProductSlice = createSlice({
  name: 'catalogProducts',
  initialState,
  reducers: {
    setCatalogProducts(state, action) {
      state.catalogProducts = action.payload
    },
    setCatalogProduct(state, action) {
      state.catalogProduct = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    showCatalogProduct(state, action) {
      state.catalogProduct = action.payload
      state.show = true
    },
    hideCatalogProduct(state) {
      state.show = false
      state.catalogProduct = undefined
    },
    createCatalogProduct(state, action) {
      state.catalogProducts = [...state.catalogProducts, action.payload]
    },
    updateCatalogProduct(state, action) {
      state.catalogProducts = state.catalogProducts.map((catalogProduct) =>
        catalogProduct.id === action.payload.id
          ? action.payload
          : catalogProduct
      )
    },
    deleteCatalogProductState(state, action) {
      state.catalogProducts = state.catalogProducts.filter(
        (u) => u.id !== action.payload
      )
    }
  }
})

export const {
  setCatalogProducts,
  showCatalogProduct,
  hideCatalogProduct,
  createCatalogProduct,
  setCatalogProduct,
  updateCatalogProduct,
  deleteCatalogProductState
} = catalogProductSlice.actions

//Get orders
export function getCatalogProducts() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(catalogProductSlice.actions.setLoading(true))
      const catalogProducts: CatalogProduct[] =
        await catalogProductService.getCatalogProducts()
      dispatch(catalogProductSlice.actions.setCatalogProducts(catalogProducts))
    } finally {
      dispatch(catalogProductSlice.actions.setLoading(false))
    }
  }
}

export default catalogProductSlice.reducer
