import React from 'react'
import styled from 'styled-components/macro'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import {
  Card,
  Button as MuiButton,
  Typography,
  Divider as MuiDivider,
  Button
} from '@mui/material'
import { spacing, SpacingProps } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import { THEMES } from '../../constants'
import useTheme from '../../hooks/useTheme'

const Divider = styled(MuiDivider)(spacing)

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;

  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`
const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

export default function CompanyArchivedError() {
  const { theme } = useTheme()
  const [t] = useTranslation('common')
  const navigate = useNavigate()

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}

      <Wrapper>
        <Helmet title="Company archived" />

        <Card sx={{ width: '450px', height: '250px' }}>
          <Typography variant="h3" align="center" sx={{ mr: 2, ml: 2, mt: 10 }}>
            {t('companyArchivedTitle')}
          </Typography>
          <Divider my={6} />
          <Typography component="h3" align="center">
            {t('companyArchivedInfo')}
          </Typography>
          <Divider my={6} />
          <Button variant="outlined" onClick={() => navigate('/auth/sign-in')}>
            {t('returnToLogin')}
          </Button>
        </Card>
      </Wrapper>
    </React.Fragment>
  )
}
