interface ClosedAnnouncements {
  announcements: string[]
}

export default function useClosedAnnouncements(): ClosedAnnouncements {
  const closedAnnouncements = JSON.parse(
    localStorage.getItem('closedAnnouncements') ?? '{}'
  )

  return closedAnnouncements
}
