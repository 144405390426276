import { createSlice } from '@reduxjs/toolkit'
import sensorService from '../../services/sensorService'
import { Dispatch } from 'redux'
import { Sensor } from '../../types'

interface SensorState {
  sensors: Sensor[]
  sensor: Sensor | undefined
  show: boolean
  status: 'idle' | 'loading' | 'failed'
}

export interface SensorData {
  latest: Sensor
}

const initialState: SensorState = {
  sensors: [],
  sensor: undefined,
  show: false,
  status: 'idle'
}

const sensorSlice = createSlice({
  name: 'sensors',
  initialState,
  reducers: {
    setSensors(state, action) {
      state.sensors = action.payload
    },
    setSensor(state, action) {
      state.sensor = action.payload
    },
    setShow(state) {
      state.show = !state.show
    }
  }
})

export const { setSensors } = sensorSlice.actions

export function getSensor(id: number) {
  return async (dispatch: Dispatch) => {
    const response = await sensorService.getSensor(id)
    dispatch(sensorSlice.actions.setSensor(response.data))
  }
}

// export function getSensor(sensor: any) {
//   return async (dispatch: any) => {
//     dispatch(sensorSlice.actions.setSensor(sensor))
//   }
// }

export default sensorSlice.reducer
