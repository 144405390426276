import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useWarehouses } from '../hooks'
import { Warehouse } from '../types'

export default function WarehouseSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')
  const warehouses = useWarehouses()

  return (
    <TextField select name="warehouse" label={t('warehouse')} {...props}>
      {warehouses.map((warehouse: Warehouse) => (
        <MenuItem key={warehouse.id} value={warehouse.id}>
          {warehouse.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
