import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { ConfluencePages, UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import Companies from '../pages/pages/Companies'
import Company from '../pages/pages/Company'
import Redirect from '../pages/Redirect'

export default [
  {
    path: '/companies',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.COMPANIES}>
          <DashboardLayout>
            <Companies />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/companies/:id',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.INSTALLER,
          UserRole.USER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.COMPANIES}>
          <DashboardLayout>
            <Company />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: 'company/:id',
    element: <Redirect to="/companies/:id" />
  }
]
