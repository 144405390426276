import React from 'react'
import styled, { css } from 'styled-components/macro'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { List } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { SidebarItemsType } from '../../types/sidebar'
import SidebarNavSection from './SidebarNavSection'

import '../../vendor/perfect-scrollbar.css'
import useAuth from '../../hooks/useAuth'

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  flex-grow: 1;
`

const Scrollbar = styled.div`
  ${baseScrollbar}
`

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`

type SidebarNavProps = {
  items: {
    title: string
    pages: SidebarItemsType[]
  }[]
}

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const { currentUser } = useAuth()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages.filter((pageRoles) =>
                  pageRoles.roles.find((r) => r.includes(currentUser?.role))
                )}
                title={item.title}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  )
}

export default SidebarNav
