import { ReactElement, useEffect, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import {
  DateRange,
  Device,
  EnergyConsumptionSettings,
  EnergyConsumptionQuery,
  Project,
  EnergyConsumption,
  SensorAggregate,
  StatsFigure,
  SensorAggregateQuery,
  ActiveProject
} from '../types'
import { CalculationMode, StartDateMode } from '../enums'
import { getStartDateByMode } from '../utils/date'
import EnergyConsumptionStats from './EnergyConsumptionStats'
import { getEnergyConsumptions } from '../services/deviceService'
import useAppSettings from '../hooks/useAppSettings'

interface DeviceEnergyConsumptionStatsProps {
  device: Device
  project?: Project | ActiveProject
  dateRange: DateRange
  loading?: boolean
}

export default function DeviceEnergyConsumptionStats({
  device,
  project,
  dateRange,
  loading = false
}: DeviceEnergyConsumptionStatsProps): ReactElement {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  const appSettings = useAppSettings()

  /**
   * The settings.
   */
  const [settings, setSettings] = useState<EnergyConsumptionSettings>({
    calculationMode: getInitialCalculationMode(),
    startDateMode: getInitialStartDateMode()
  })

  function getInitialCalculationMode(): number {
    return (
      appSettings.deviceStatsSettings
        ?.consumptionOfElectricityCalculationMode ?? CalculationMode.CUMULATIVE
    )
  }

  function getInitialStartDateMode(): StartDateMode {
    if (
      appSettings.deviceStatsSettings?.consumptionOfElectricityStartDateMode &&
      appSettings.deviceStatsSettings?.consumptionOfElectricityStartDateMode ===
        StartDateMode.START_OF_PROJECT &&
      project === undefined
    ) {
      return StartDateMode.SELECTED_DATE
    } else
      return (
        appSettings.deviceStatsSettings
          ?.consumptionOfElectricityStartDateMode ?? StartDateMode.SELECTED_DATE
      )
  }

  /**
   * The energy consumptions.
   */
  const [energyConsumptions, setEnergyConsumptions] = useState<
    EnergyConsumption[]
  >([])

  /**
   * The energy consumptions before the date range.
   */
  const [
    energyConsumptionsBeforeDateRange,
    setEnergyConsumptionsBeforeDateRange
  ] = useState<EnergyConsumption[]>([])

  /**
   * Indicates if the component loading.
   */
  const [isBooting, setIsBooting] = useState<boolean>(false)

  /**
   * The area as square meters.
   */
  const areaAsSquareMeters: number | null = project?.area ?? null

  /**
   * The energy consumption query.
   */
  const query: EnergyConsumptionQuery = {
    from: dateRange.from,
    to: dateRange.to
  }

  /**
   * The energy consumption query before the date range.
   */
  const queryBeforeDateRange: EnergyConsumptionQuery = {
    from: getStartDateByMode(dateRange.from, settings.startDateMode, project),
    to: dateRange.from
  }

  /**
   * The date range of stats.
   */
  const statsDateRange: DateRange | undefined =
    settings.startDateMode !== StartDateMode.SELECTED_DATE
      ? {
          from: queryBeforeDateRange.from,
          to: query.to
        }
      : undefined

  /**
   * Indicate if the selected date range is used.
   */
  const isUsingSelectedDateRange =
    settings.startDateMode === StartDateMode.SELECTED_DATE

  const startDateModeOptions = project
    ? [
        StartDateMode.START_OF_PROJECT,
        StartDateMode.START_OF_MONTH,
        StartDateMode.SELECTED_DATE
      ]
    : [StartDateMode.START_OF_MONTH, StartDateMode.SELECTED_DATE]

  /**
   * Boot the component.
   */
  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (isUsingSelectedDateRange) {
        await loadEnergyConsumptions(signal)
        setEnergyConsumptionsBeforeDateRange([])
      } else {
        await Promise.all([
          loadEnergyConsumptions(signal),
          loadEnergyConsumptionsBeforeDateRange(signal)
        ])
      }
    } finally {
      setIsBooting(false)
    }
  }

  /**
   * Load the energy consumptions of the device.
   */
  async function loadEnergyConsumptions(signal: AbortSignal): Promise<void> {
    setEnergyConsumptions(
      await getEnergyConsumptions(device.name, query, { signal })
    )
  }

  /**
   * Load the energy consumptions of the device before the date range.
   */
  async function loadEnergyConsumptionsBeforeDateRange(
    signal: AbortSignal
  ): Promise<void> {
    setEnergyConsumptionsBeforeDateRange(
      await getEnergyConsumptions(device.name, queryBeforeDateRange, { signal })
    )
  }

  /**
   * Change the calculation mode.
   */
  function changeCalculationMode(calculationMode: CalculationMode): void {
    const appSettings = useAppSettings()
    const deviceStatsSettings = appSettings.deviceStatsSettings
    setSettings({
      ...settings,
      calculationMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        deviceStatsSettings: {
          ...deviceStatsSettings,
          consumptionOfElectricityCalculationMode: calculationMode
        }
      })
    )
  }

  /**
   * Change the start date mode.
   */
  function changeStartDateMode(startDateMode: StartDateMode): void {
    const appSettings = useAppSettings()
    const deviceStatsSettings = appSettings.deviceStatsSettings
    setSettings({
      ...settings,
      startDateMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        deviceStatsSettings: {
          ...deviceStatsSettings,
          consumptionOfElectricityStartDateMode: startDateMode
        }
      })
    )
  }

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [device, query.from, query.to, settings.startDateMode])

  return (
    <EnergyConsumptionStats
      energyConsumptions={energyConsumptions}
      energyConsumptionsBeforeDateRange={energyConsumptionsBeforeDateRange}
      query={query}
      settings={settings}
      dateRange={statsDateRange}
      area={areaAsSquareMeters}
      loading={loading || isBooting}
      startDateModeOptions={startDateModeOptions}
      onChangeCalculationMode={changeCalculationMode}
      onChangeStartDateMode={changeStartDateMode}
    />
  )
}
