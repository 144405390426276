import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { RuuviTagBatteryAggregateStatistic } from '../types'

export interface RuuviTagBatteryStatisticToggleProps {
  value: RuuviTagBatteryAggregateStatistic[]
  onChange(value: RuuviTagBatteryAggregateStatistic[]): void
}

export default function RuuviTagBatteryStatisticToggle({
  value,
  onChange
}: RuuviTagBatteryStatisticToggleProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  function handleChange(
    event: MouseEvent<HTMLElement>,
    newValue: RuuviTagBatteryAggregateStatistic[]
  ): void {
    onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value="min">{t('lowest')}</ToggleButton>
      <ToggleButton value="avg">{t('average')}</ToggleButton>
      <ToggleButton value="max">{t('highest')}</ToggleButton>
    </ToggleButtonGroup>
  )
}
