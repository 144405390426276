import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Stats from '../pages/dashboards/Default/Stats'
import EnergyPriceChart from './EnergyPriceChart'
import { getEnergyPriceNow } from '../services/energyPriceService'
import { ChartOptions } from 'chart.js'
import EnergyPrice from '../types/EnergyPrice'
import EnergyPriceQuery from '../types/EnergyPriceQuery'
import { DateRange, EnergyPriceSettings } from '../types'
import { EnergyPriceDateRangeMode } from '../enums'
import {
  endOfToday,
  endOfTomorrow,
  startOfToday,
  startOfTomorrow
} from 'date-fns'
import { Paper } from '@mui/material'
import EnergyPriceDateRangeModeToggle from './EnergyPriceDateRangeModeToggle'

export interface EnergyPriceStatsProps {
  query: EnergyPriceQuery
  chartOptions?: ChartOptions
  initialSettings?: EnergyPriceSettings
}

export default function EnergyPriceStats({
  query,
  chartOptions = {},
  initialSettings = {
    dateRangeMode: EnergyPriceDateRangeMode.TODAY
  }
}: EnergyPriceStatsProps) {
  const [t] = useTranslation('common')

  const [settings, setSettings] = useState<EnergyPriceSettings>(initialSettings)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [energyPrice, setEnergyPrice] = useState<EnergyPrice>()

  const dateRange: DateRange =
    settings.dateRangeMode === EnergyPriceDateRangeMode.TODAY
      ? { from: startOfToday(), to: endOfToday() }
      : settings.dateRangeMode === EnergyPriceDateRangeMode.TOMORROW
      ? { from: startOfTomorrow(), to: endOfTomorrow() }
      : { from: query.from, to: query.to }

  const energyPriceNowAsCentsPerKwh: number = energyPrice?.cents_per_kwh ?? 0

  /**
   * The text of chip.
   */
  const textOfChip: string | undefined =
    settings.dateRangeMode === EnergyPriceDateRangeMode.TODAY
      ? t('today')
      : settings.dateRangeMode === EnergyPriceDateRangeMode.TOMORROW
      ? t('tomorrow')
      : undefined

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [])

  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      await loadEnergyPrice(signal)
    } finally {
      setIsLoading(false)
    }
  }

  async function loadEnergyPrice(signal?: AbortSignal): Promise<void> {
    setEnergyPrice(
      await getEnergyPriceNow(
        {
          lat: query.lat,
          long: query.long
        },
        {
          signal
        }
      )
    )
  }

  function changeDateRangeMode(dateRangeMode: EnergyPriceDateRangeMode): void {
    setSettings({
      ...settings,
      dateRangeMode
    })
  }

  return (
    <Stats
      title={t('Device.energyPrice')}
      chip={textOfChip}
      amount={energyPriceNowAsCentsPerKwh.toFixed(2)}
      unit={t('Device.centsPerKwh')}
      loading={isLoading}
      menu={
        <Paper elevation={0} sx={{ py: 1, px: 3, maxWidth: '320px' }}>
          <EnergyPriceDateRangeModeToggle
            value={settings.dateRangeMode}
            onChange={changeDateRangeMode}
          />
        </Paper>
      }
      chart={
        <EnergyPriceChart
          dateRange={dateRange}
          query={query}
          settings={settings}
          options={chartOptions}
        />
      }
    />
  )
}
