import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import {
  Grid,
  Paper,
  Typography,
  Alert,
  AlertColor,
  Collapse
} from '@mui/material'

import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import NewPasswordComponent from '../../components/auth/NewPassword'
import NavbarLanguagesDropdown from '../../components/navbar/NavbarLanguagesDropdown'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { createNotification } from '../../redux/slices/notifications'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

function NewPassword() {
  const [t] = useTranslation('common')
  const { theme } = useTheme()

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}
      <Wrapper>
        <Helmet title="Reset Password" />
        <Grid container spacing={6}>
          <NavbarLanguagesDropdown />
        </Grid>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t('newPassword.new')}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t('newPassword.give')}
        </Typography>

        <NewPasswordComponent />
      </Wrapper>
    </React.Fragment>
  )
}

export default NewPassword
