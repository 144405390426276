import styled from 'styled-components/macro'
import {
  Card as MuiCard,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress as MuiLinearProgress
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Device, Sensor } from '../../../types'

const Card = styled(MuiCard)(spacing)

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
  min-height: 232px;
`

const LinearProgress = styled(MuiLinearProgress)`
  height: 14px;
  width: 100%;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`
export interface ConsumptionTableProps {
  device: Device
  sensor: Sensor
}
function ConsumptionTable({ device, sensor }: ConsumptionTableProps) {
  const [t] = useTranslation('common')

  const changeColor = (value: number) => {
    if (value < 60) {
      return 'success'
    } else if (value >= 60 && value < 85) {
      return 'warning'
    } else {
      return 'error'
    }
  }
  const calculatePercent = (current: number, fuse: number) => {
    const percent = (current / fuse) * 100
    if (percent > 100) {
      return 100
    } else return percent
  }

  return (
    <Card mb={6}>
      <CardHeader title={t('Device.consumption')} />

      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  {t('Device.phase')}
                </TableCell>
                <TableCell>{t('Device.ampere')}</TableCell>
                <TableCell>% {t('Device.ampere')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">{t('Device.phase1')}</TableCell>
                <TableCell>
                  {Math.round(sensor.current_1 as number) +
                    ' / ' +
                    device?.powerSupply?.fuse}
                </TableCell>
                <TableCell>
                  <LinearProgress
                    variant="determinate"
                    value={calculatePercent(
                      sensor.current_1,
                      device?.powerSupply?.fuse as number
                    )}
                    color={changeColor(
                      calculatePercent(
                        sensor.current_1,
                        device?.powerSupply?.fuse as number
                      )
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">{t('Device.phase2')}</TableCell>
                <TableCell>
                  {Math.round(sensor.current_2 as number) +
                    ' / ' +
                    device?.powerSupply?.fuse}
                </TableCell>
                <TableCell>
                  <LinearProgress
                    variant="determinate"
                    value={calculatePercent(
                      sensor.current_2,
                      device?.powerSupply?.fuse as number
                    )}
                    color={changeColor(
                      calculatePercent(
                        sensor.current_2,
                        device?.powerSupply?.fuse as number
                      )
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">{t('Device.phase3')}</TableCell>

                <TableCell>
                  {Math.round(sensor.current_3 as number) +
                    ' / ' +
                    device?.powerSupply?.fuse}
                </TableCell>
                <TableCell>
                  <LinearProgress
                    variant="determinate"
                    value={calculatePercent(
                      sensor.current_3,
                      device?.powerSupply?.fuse as number
                    )}
                    color={changeColor(
                      calculatePercent(
                        sensor.current_3,
                        device?.powerSupply?.fuse as number
                      )
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  )
}

export default ConsumptionTable
