import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { RuuviTagAccelerationStatistic } from '../types'

export interface RuuviTagAccelerationStatisticToggleProps {
  value: RuuviTagAccelerationStatistic[]
  onChange(value: RuuviTagAccelerationStatistic[]): void
}

export default function RuuviTagAccelerationStatisticToggle({
  value,
  onChange
}: RuuviTagAccelerationStatisticToggleProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Handle the change event.
   */
  function handleChange(
    event: MouseEvent<HTMLElement>,
    newValue: RuuviTagAccelerationStatistic[]
  ): void {
    onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value="avg">{t('average')}</ToggleButton>
      <ToggleButton value="max">{t('highest')}</ToggleButton>
    </ToggleButtonGroup>
  )
}
