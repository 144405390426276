import {
  Card as MuiCard,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableContainer,
  Stack
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { THEMES } from '../constants'
import { scrollbarForDark, scrollbarForLight } from '../theme/scrollbar'
import useTheme from '../hooks/useTheme'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import LoadingState from './LoadingState'
import { deleteCompany, updateCompany } from '../redux/slices/companies'
import { CompanyErrors } from '../enums/CompanyErrors'
import { useAuth } from '../hooks'
import { createUserNotification } from '../utils/createUserNotification'
import { recoverCompany } from '../services/companyService'
import { Company } from '../types'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const Card = styled(MuiCard)(spacing)

export interface CompanyArchiveTableProps {
  companies: Company[]
}

export default function CompanyArchiveTable({
  companies
}: CompanyArchiveTableProps) {
  const { currentUser } = useAuth()
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [filter, setFilter] = useState<string>('')
  const filteredCompanies = companies?.filter((company: Company) => {
    const regex = new RegExp(filter, 'i')
    return (
      filter.length === 0 ||
      Object.values(company).some((value) => {
        return regex.test(value as string)
      })
    )
  })
  const [open, setOpen] = useState(false)
  const [companyId, setCompanyId] = useState<number>(0)

  const handleDelete = (id: number) => {
    dispatch(deleteCompany(id, true))
    handleClose()
  }

  const handleClickOpen = (id: number) => {
    setCompanyId(id)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setCompanyId(0)
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function handleCompanyRecovery(company: Company) {
    try {
      setIsSubmitting(true)
      await recoverCompany(company.id)
      //Update company redux state
      company = { ...company }
      company.deletedAt = null
      dispatch(updateCompany(company))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('companyRecoverySuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: CompanyErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: t('name'),
      type: 'string',
      width: 200,
      editable: false
    },
    {
      field: 'address',
      headerName: t('address'),
      flex: 1,
      minWidth: 200,
      editable: false
    },
    {
      field: 'city',
      headerName: t('city'),
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false
    },
    {
      field: 'deletedAt',
      headerName: t('archivedAt'),
      type: 'string',
      flex: 1,
      minWidth: 200,
      editable: false,
      valueGetter: (params: any) =>
        new Date(params.row.deletedAt).toLocaleString()
    },
    {
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params: any) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={3}>
              <AutorenewIcon
                style={{ cursor: 'pointer' }}
                color="success"
                onClick={() => handleCompanyRecovery(params.row)}
              />

              <Delete
                color="error"
                style={{ cursor: 'pointer' }}
                onClick={() => handleClickOpen(params.row.id)}
              />
            </Stack>
          </React.Fragment>
        )
      }
    }
  ]

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Companies.confirm')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Companies.warning')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleDelete(companyId)}>{t('yes')}</Button>
          <Button onClick={handleClose} autoFocus>
            {t('no')}
          </Button>
        </DialogActions>
      </Dialog>
      <Card mb={6}>
        {isSubmitting ? (
          <LoadingState />
        ) : (
          <Paper>
            <DataGrid
              rows={companies}
              columns={columns}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
              components={{
                Toolbar: GridToolbar
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 }
                }
              }}
            />
          </Paper>
        )}
      </Card>
    </React.Fragment>
  )
}
