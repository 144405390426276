import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import Chart from 'react-chartjs-2'
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Divider as MuiDivider,
  useMediaQuery
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { blue, grey, orange } from '@mui/material/colors'
import Sensor from '../types/Sensor'
import { PolarAreaChart } from '../enums/PolarAreaChart'
import { calculateMaxKwh, calculatePhaseKwH } from '../utils/sensor'
import useTheme from '../hooks/useTheme'
import { THEMES } from '../constants'
import LoadingState from './LoadingState'
import { Device, PowerSupply } from '../types'
import { getSensorNow } from '../services/deviceService'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)

const ChartWrapper = styled.div`
  height: 300px;
  position: relative;
`
export interface DeviceConsumptionStatsProps {
  loading: boolean
  powerSupplies: PowerSupply[]
  sensors: Sensor[]
  selectPowerSupply(selectedPowerSupply: PowerSupply | undefined): void
}

export default function DeviceConsumptionStats({
  loading,
  powerSupplies,
  sensors,
  selectPowerSupply
}: DeviceConsumptionStatsProps) {
  const [t] = useTranslation('common')
  const { theme } = useTheme()

  /**
   * Chart action
   * Default value percentage
   */
  const [action, setAction] = useState(PolarAreaChart.PERCENTAGE)

  /**
   * Chart radius
   * Default value 100 (%)
   */
  const [rMax, setRMax] = useState(100)

  /**
   * Selected powerSupplies
   */
  const [selectedPowerSupply, setSelectedPowerSupply] = useState<
    PowerSupply | undefined
  >(undefined)

  /**
   * Data that is displayed on chart
   */
  const [chartData, setChartData] = useState<any>([])

  useEffect(() => {
    handleChartData(action)
  }, [sensors])

  /**
   * Handle chart data
   * @param action
   */
  function handleChartData(action: PolarAreaChart) {
    let fuseSizeCombined = 0
    let current_1_combined = 0
    let current_2_combined = 0
    let current_3_combined = 0
    let voltage_1_combined = 0
    let voltage_2_combined = 0
    let voltage_3_combined = 0

    /**
     * Combine the fuse sizes of the powersupplies or use selected powersupply's fuse
     */

    selectedPowerSupply
      ? (fuseSizeCombined = selectedPowerSupply.fuse)
      : powerSupplies.forEach((powerSupply) => {
          fuseSizeCombined += powerSupply.fuse
        })

    if (sensors.length > 0) {
      sensors.forEach((sensor) => {
        current_1_combined += sensor.current_1
      })

      sensors.forEach((sensor) => {
        current_2_combined += sensor.current_2
      })

      sensors.forEach((sensor) => {
        current_3_combined += sensor.current_3
      })

      sensors.forEach((sensor) => {
        voltage_1_combined += sensor.voltage_1
      })

      sensors.forEach((sensor) => {
        voltage_2_combined += sensor.voltage_2
      })

      sensors.forEach((sensor) => {
        voltage_3_combined += sensor.voltage_3
      })
    }

    if (action === PolarAreaChart.PERCENTAGE) {
      setRMax(100)
      setChartData([
        calculatePercentage(current_1_combined, fuseSizeCombined),
        calculatePercentage(current_2_combined, fuseSizeCombined),
        calculatePercentage(current_3_combined, fuseSizeCombined)
      ])
    } else if (action === PolarAreaChart.CURRENT) {
      setRMax(fuseSizeCombined)
      setChartData([
        Math.round(current_1_combined as number),
        Math.round(current_2_combined as number),
        Math.round(current_3_combined as number)
      ])
    } else {
      setRMax(
        //@ts-ignore
        Math.round(calculateMaxKwh(parseInt(fuseSizeCombined)))
      )
      setChartData([
        calculatePhaseKwH(
          current_1_combined as number,
          voltage_1_combined as number
        ),
        calculatePhaseKwH(
          current_2_combined as number,
          voltage_2_combined as number
        ),
        calculatePhaseKwH(
          current_3_combined as number,
          voltage_3_combined as number
        )
      ])
    }
  }

  /**
   * Calculate consumption percentage
   */
  function calculatePercentage(current: number, fuse: number): number {
    const percent = (current / fuse) * 100
    return percent > 100 ? 100 : Math.round(percent)
  }

  /**
   *  Handle chart action change
   * @param action
   */
  function handlePolarAreaActionChange(action: PolarAreaChart) {
    setAction(action)
    handleChartData(action)
  }

  /**
   * Handle power supply change
   * @param powerSupply
   */
  function handlePowerSupplyChange(powerSupply: PowerSupply | undefined) {
    selectPowerSupply(powerSupply)
    setSelectedPowerSupply(powerSupply)
    handleChartData(action)
  }

  const data = {
    labels: [t('Device.phase1'), t('Device.phase2'), t('Device.phase3')],
    datasets: [
      {
        data: chartData,
        backgroundColor: [blue[500], grey[500], orange[500]],
        borderWidth: 2,
        borderColor: theme === THEMES.DARK ? '#233044' : '#FFFFFFF2'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = ''
            if (context.parsed.r) {
              label = context.parsed.r + action
            }
            return label
          }
        }
      }
    },
    scales: {
      r: {
        beginAtZero: true,
        max: rMax,
        grid: {
          color: grey[500]
        }
      }
    },
    cutout: '80%'
  }

  return (
    <Card mb={6}>
      {loading ? (
        <LoadingState />
      ) : (
        <>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} md={4}>
              <Typography sx={{ ml: 4 }} variant="h5">
                {t('Device.consumption') + ' ' + action}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography align="center" sx={{ ml: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  label={t('powerSupplies')}
                  select
                  autoFocus={false}
                  value={selectedPowerSupply?.id ?? 'all'}
                >
                  <MenuItem
                    value={'all'}
                    onClick={() => handlePowerSupplyChange(undefined)}
                  >
                    {t('allMainDistributions')}
                  </MenuItem>
                  {powerSupplies.map((powerSupply) => (
                    <MenuItem
                      value={powerSupply.id}
                      onClick={() => handlePowerSupplyChange(powerSupply)}
                    >
                      {powerSupply.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography align="center" sx={{ mr: 2 }}>
                <TextField
                  size="small"
                  select
                  autoFocus={false}
                  value={action}
                  fullWidth
                >
                  <MenuItem
                    value={PolarAreaChart.PERCENTAGE}
                    onClick={() =>
                      handlePolarAreaActionChange(PolarAreaChart.PERCENTAGE)
                    }
                  >
                    {t('Device.percentage')}
                  </MenuItem>
                  <MenuItem
                    value={PolarAreaChart.CURRENT}
                    onClick={() =>
                      handlePolarAreaActionChange(PolarAreaChart.CURRENT)
                    }
                  >
                    {t('Device.current')}
                  </MenuItem>
                  <MenuItem
                    value={PolarAreaChart.POWER}
                    onClick={() =>
                      handlePolarAreaActionChange(PolarAreaChart.POWER)
                    }
                  >
                    {t('Device.power')}
                  </MenuItem>
                </TextField>
              </Typography>
            </Grid>
          </Grid>

          <Divider mt={6} />

          <CardContent>
            <ChartWrapper>
              <Chart type="polarArea" data={data} options={options} />
            </ChartWrapper>
          </CardContent>
        </>
      )}
    </Card>
  )
}
