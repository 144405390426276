import { ProjectPhase, ProjectPhaseInput } from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function createProjectPhase(
  input: ProjectPhaseInput
): Promise<ProjectPhase> {
  const response = await api.post('/project-phases', input)

  return response.data
}

export async function updateProjectPhase(
  id: string,
  input: ProjectPhaseInput
): Promise<ProjectPhase> {
  const response = await api.put(`/project-phases/${id}`, input)

  return response.data
}

export async function deleteProjectPhase(id: string): Promise<void> {
  await api.delete(`/project-phases/${id}`)
}
