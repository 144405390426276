import { AxiosResponse } from 'axios'
import { Device_group } from '../redux/slices/device_groups'
import { useApi } from '../hooks'

const api = useApi('v2')

async function getDeviceGroups(): Promise<AxiosResponse> {
  const response = await api.get('/device_groups')
  return response.data
}

async function createDeviceGroup(
  device_group: Device_group
): Promise<AxiosResponse> {
  const response = await api.post('/device_groups', device_group)
  return response.data
}

async function updateDeviceGroup(
  device_group: Device_group
): Promise<AxiosResponse> {
  const response = await api.put(
    `/device_groups/${device_group.id}`,
    device_group
  )
  return response.data
}

async function deleteDeviceGroup(id: number): Promise<AxiosResponse> {
  const response = await api.delete(`/device_groups/${id}`)
  return response.data
}

export default {
  getDeviceGroups,
  createDeviceGroup,
  updateDeviceGroup,
  deleteDeviceGroup
}
