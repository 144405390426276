import { AxiosRequestConfig, AxiosResponse } from 'axios'
import useAxios from '../hooks/useAxios'
import { SensorAggregateInterval } from '../enums/SensorAggregateInterval'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateQuery from '../types/SensorAggregateQuery'
import { Device } from '../types'

const axios = useAxios()

type SensorAggregateParams = {
  interval?: SensorAggregateInterval
  from?: string
  to?: string
}

export async function getDevice(id: number | string): Promise<Device> {
  const response: AxiosResponse = await axios.get(`/devices/${id}`)
  return response.data
}

export async function getSensorAggregates(
  deviceCode: string,
  query: SensorAggregateQuery,
  config?: AxiosRequestConfig
): Promise<SensorAggregate[]> {
  const params: SensorAggregateParams = makeParams(query)
  const response: AxiosResponse = await axios.get(
    `/devices/${deviceCode}/sensor-aggregates`,
    { ...config, params }
  )

  return response.data
}

function makeParams({
  interval,
  from,
  to
}: SensorAggregateQuery): SensorAggregateParams {
  const params: SensorAggregateParams = {}

  if (interval) {
    params.interval = interval
  }

  if (from instanceof Date) {
    params.from = from.toISOString()
  }

  if (to instanceof Date) {
    params.to = to.toISOString()
  }

  return params
}
