import { Divider, List, ListItem, ListItemText } from '@mui/material'
import { Device, Sensor } from '../types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { humanizeTimestamp } from '../utils/date'
import { useEffect, useState } from 'react'
import { useAuth } from '../hooks'
import AssetDetails from './AssetDetails'
import { checkRssiColor, checkSignal } from '../utils/device'
import translateProductOrOrderItemType from '../utils/translateProductOrOrderItemType'
import { UserRole } from '../enums/UserRole'
import { red } from '@mui/material/colors'

interface DeviceDetailsProps {
  device: Device
  sensor?: Sensor
  onUpdate(device: Device): void
}

export default function DeviceDetails({
  device,
  sensor,
  onUpdate
}: DeviceDetailsProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * Indicates if the component is booting.
   */
  const [isBooting, setIsBooting] = useState<boolean>(true)

  async function boot(signal: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      // TODO: Load ruuvi tags of device.
    } finally {
      setIsBooting(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      await boot(controller.signal)
    })()

    return () => controller.abort()
  }, [])

  return (
    <AssetDetails
      asset={device?.asset ?? null}
      onUpdate={(asset) => {
        onUpdate({ ...device, asset })
      }}
    >
      <Divider sx={{ my: 3 }} />

      <List>
        <ListItem>
          <ListItemText primary={device.name} secondary={t('serialNumber')} />
        </ListItem>

        <ListItem>
          <ListItemText primary={device.shortUUID} secondary={t('shortUUID')} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={device?.product?.catalogProduct?.name ?? '-'}
            secondary={t('productName')}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              device?.product
                ? t(
                    translateProductOrOrderItemType(
                      device?.product?.type
                    ) as string
                  )
                : '-'
            }
            secondary={t('productType')}
          />
        </ListItem>

        {currentUser?.role === UserRole.SUPERADMIN && (
          <ListItem>
            <ListItemText
              primary={device.simId ?? '-'}
              secondary={t('simCard')}
            />
          </ListItem>
        )}

        <ListItem>
          <ListItemText
            primary={device?.hardwareVersion ?? '-'}
            secondary={t('hardwareVersion')}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={device?.firmwareVersion ?? '-'}
            secondary={t('firmwareVersion')}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              sensor && sensor?.rssi ? checkSignal(sensor?.rssi) : t('noSignal')
            }
            secondary={t('signal')}
            sx={{
              color:
                sensor && sensor.rssi ? checkRssiColor(sensor.rssi) : red[500]
            }}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={humanizeTimestamp(device.createdAt)}
            secondary={t('createdAt')}
          />
        </ListItem>
      </List>
    </AssetDetails>
  )
}
