import { createContext, ReactNode, useEffect, useReducer } from 'react'

import { JWTContextType, ActionMap, AuthState, AuthUser } from '../types/auth'

import {
  getUserIdFromToken,
  isValidToken,
  setSession,
  setTemporaryToken
} from '../utils/jwt'

import authService, { sendEmailVerificationMail } from '../services/authService'
import userService from '../services/userService'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useQuery from '../hooks/useQuery'
import { UserRole } from '../enums/UserRole'
import { MFAType } from '../enums'
import { CurrentUser, User, Company } from '../types'

const INITIALIZE = 'INITIALIZE'
const SIGN_IN = 'SIGN_IN'
const SIGN_OUT = 'SIGN_OUT'
const SIGN_UP = 'SIGN_UP'

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean
    currentUser: AuthUser
  }
  [SIGN_IN]: {
    currentUser: AuthUser
  }
  [SIGN_OUT]: undefined
  [SIGN_UP]: {
    currentUser: AuthUser
  }
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  currentUser: null
}

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        currentUser: action.payload.currentUser
      }
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.currentUser
      }
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.currentUser
      }

    default:
      return state
  }
}

const AuthContext = createContext<JWTContextType | null>(null)

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState)
  const navigate = useNavigate()
  const query = useQuery()

  const { i18n } = useTranslation()
  const handleDefaultLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
  }

  const handleUserNavigation = (role: string) => {
    const redirect = query.get('redirect')
    if (redirect) {
      navigate(redirect)
    } else if (role === UserRole.MANUFACTURER) {
      navigate('/manufacturing')
    } else navigate('/')
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken')
        const refreshToken = window.localStorage.getItem('refreshToken')

        if (refreshToken && isValidToken(refreshToken)) {
          setSession(accessToken, refreshToken)

          const userId = getUserIdFromToken(refreshToken)

          if (!userId) {
            throw Error('User ID not found in token')
          }

          const response = await userService.getUser(userId)

          const currentUser = makeCurrentUser(response)

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              currentUser
            }
          })
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              currentUser: null
            }
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err)
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            currentUser: null
          }
        })
      }
    }

    initialize()
  }, [])

  const signIn = async (email: string, password: string) => {
    const loginResponse: CurrentUser = await authService.login({
      email: email,
      password: password
    })

    if (!loginResponse) {
      navigate(`auth/500`)
    }

    const isMissingCompany: boolean = checkCompany(
      loginResponse.company,
      loginResponse.role
    )

    if (isMissingCompany) {
      return navigate('/auth/company-archived')
    }

    const accessToken = loginResponse.accessToken
    const refreshToken = loginResponse.refreshToken

    if (loginResponse && loginResponse.emailVerifiedAt === null) {
      setTemporaryToken(accessToken)
      await sendEmailVerificationMail()
      navigate(`auth/verify-email`)
    } else if (loginResponse && loginResponse.mfaType !== MFAType.NONE) {
      const redirect = query.get('redirect')
      if (redirect) {
        setTemporaryToken(accessToken)
        navigate(
          `auth/mfa/user?redirect=${redirect}&type=${loginResponse.mfaType}`
        )
      } else {
        setTemporaryToken(accessToken)
        navigate(`auth/mfa/user?type=${loginResponse.mfaType}`)
      }
    } else if (loginResponse) {
      const currentUser = makeCurrentUser(loginResponse)
      setSession(accessToken, refreshToken)
      dispatch({
        type: SIGN_IN,
        payload: {
          currentUser
        }
      })
      window.localStorage.setItem('language', currentUser.language)
      handleDefaultLanguageChange(currentUser.language)
      handleUserNavigation(currentUser.role)
    }
  }

  const signOut = async () => {
    setSession(null, null)
    dispatch({ type: SIGN_OUT })
  }

  const completeSignIn = async (otpConfirmSuccessResponse: CurrentUser) => {
    const token = otpConfirmSuccessResponse.accessToken
    const refreshToken = otpConfirmSuccessResponse.refreshToken

    const currentUser = makeCurrentUser(otpConfirmSuccessResponse)
    setSession(token, refreshToken)
    dispatch({
      type: SIGN_IN,
      payload: {
        currentUser
      }
    })

    window.localStorage.setItem('language', currentUser.language)
    handleDefaultLanguageChange(currentUser.language)
    handleUserNavigation(currentUser.role)
  }

  function makeCurrentUser(user: CurrentUser | User) {
    return {
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company,
      role: user.role,
      language: user.language,
      projects: user.projects,
      activeProject: user.activeProject,
      activeProjectMembership: user.activeProjectMembership,
      mfaType: user.mfaType
    }
  }

  async function refreshUser() {
    const accessToken = window.localStorage.getItem('accessToken')
    const refreshToken = window.localStorage.getItem('refreshToken')
    if (refreshToken && isValidToken(refreshToken)) {
      setSession(accessToken, refreshToken)

      const userId = getUserIdFromToken(refreshToken)

      if (!userId) {
        throw Error('User ID not found in token')
      }

      const response = await userService.getUser(userId)

      const currentUser = makeCurrentUser(response)

      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: true,
          currentUser
        }
      })
    } else {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          currentUser: null
        }
      })
    }
  }

  function checkCompany(
    company: Company | null | undefined,
    role: UserRole
  ): boolean {
    if (
      company === null &&
      role !== UserRole.GUEST &&
      role !== UserRole.INSTALLER &&
      role !== UserRole.MANUFACTURER &&
      role !== UserRole.SUPERADMIN
    ) {
      return true
    } else return false
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
        completeSignIn,
        refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
