import { RssiSignal } from '../enums/RssiSignal'

export default function checkRssiSignal(value: number) {
  if (value > -60) {
    return RssiSignal.EXCELLENT as string
  } else if (value > -70) {
    return RssiSignal.VERY_GOOD as string
  } else if (value > -80) {
    return RssiSignal.GOOD as string
  } else if (value > -90) {
    return RssiSignal.LOW as string
  } else if (value < -90 && value > -254) {
    return RssiSignal.VERY_LOW as string
  } else if (value === -254) {
    return RssiSignal.BLUETOOTH as string
  } else if (value === -255) {
    return RssiSignal.SUB_DEVICE as string
  }
}
