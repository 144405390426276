import { ProjectNewOrOldType } from '../enums/ProjectNewOrOldType'

export default function translateProjectNewOrOldType(type: string) {
  switch (type) {
    case ProjectNewOrOldType.NEW:
      return 'Project.new'
    case ProjectNewOrOldType.OLD:
      return 'Project.renovation'
  }
}
