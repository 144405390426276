export enum SensorAggregateField {
  CURRENT_1 = 'current_1',
  CURRENT_2 = 'current_2',
  CURRENT_3 = 'current_3',
  VOLTAGE_1 = 'voltage_1',
  VOLTAGE_2 = 'voltage_2',
  VOLTAGE_3 = 'voltage_3',
  KW = 'kw',
  KW_L1 = 'kw_l1',
  KW_L2 = 'kw_l2',
  KW_L3 = 'kw_l3',
  TEMP = 'temp',
  RH = 'rh',
  RATED_CURRENT = 'rated_current',
  RSSI = 'rssi'
}

export default SensorAggregateField
