import React from 'react'
import styled from 'styled-components/macro'
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader
} from '@mui/material'
import * as Yup from 'yup'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import userService from '../../services/userService'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { useAuth } from '../../hooks'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { EmailErrors } from '../../enums/EmailErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import LoadingState from '../LoadingState'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const Button = styled(MuiButton)(spacing)

const Card = styled(MuiCard)(spacing)

const Grid = styled(MuiGrid)(spacing)

const CardContent = styled(MuiCardContent)(spacing)

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required')
})

const initialValues = {
  email: '',
  projectId: ''
}

interface EmailFormProps {
  projectId: string | number
  emailSent(): void
}

export default function EmailForm({ projectId, emailSent }: EmailFormProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [t] = useTranslation('common')

  /**
   * Initialize project id
   */
  if (projectId) {
    initialValues.projectId = projectId.toString()
  }
  /**
   * Current user
   */
  const { currentUser } = useAuth()

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      await userService.sendSendEmailFromInstaller(values)

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('emailSentSuccess')
        )
      )
      emailSent()
      resetForm()
    } catch (error: unknown) {
      dispatch(
        setError({
          type: EmailErrors.SEND,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: EmailErrors.SEND,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(EmailErrors.SEND))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <LoadingState />
            ) : (
              <form onSubmit={handleSubmit}>
                <CardHeader
                  title={t('Manufacturing.giveForemanEmail')}
                  align="center"
                  titleTypographyProps={{ variant: 'h6' }}
                />

                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl>
                    <TextField
                      name="email"
                      label={t('Manufacturing.email')}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Box
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    onClick={() => {
                      navigate('/installation')
                    }}
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    {t('Manufacturing.cancel')}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    {t('Manufacturing.send')}
                  </Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
