import { CatalogProduct, CatalogProductInput } from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getCatalogProducts(): Promise<CatalogProduct[]> {
  const response = await api.get('/catalog-products')

  return response.data
}

export async function createCatalogProduct(
  input: CatalogProductInput
): Promise<CatalogProduct> {
  const response = await api.post('/catalog-products', input)

  return response.data
}

export async function updateCatalogProduct(
  id: string,
  input: CatalogProductInput
): Promise<CatalogProduct> {
  const response = await api.put(`/catalog-products/${id}`, input)

  return response.data
}

export async function deleteCatalogProduct(id: string): Promise<void> {
  await api.delete(`/catalog-products/${id}`)
}

export default {
  getCatalogProducts,
  createCatalogProduct,
  updateCatalogProduct,
  deleteCatalogProduct
}
