import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RuuviTagInput } from '../types'
import { useDispatch } from 'react-redux'
import { getProjects } from '../redux/slices/projects'
import { getCompanies } from '../redux/slices/companies'
import { getLiftsByProjectId } from '../redux/slices/lifts'
import { getLevelsByProjectId } from '../redux/slices/levels'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField
} from '@mui/material'
import CompanySelect from './CompanySelect'
import ProjectSelect from './ProjectSelect'
import LevelSelect from './LevelSelect'
import LiftSelect from './LiftSelect'
import { Delete } from '@mui/icons-material'
import styled from 'styled-components'
import { LoadingButton } from '@mui/lab'
import { setDevice, setSelectedDeviceId } from '../redux/slices/devices'
import * as Yup from 'yup'

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

interface RuuviTagFormProps {
  values: RuuviTagInput
  onSubmit?: (values: RuuviTagInput) => Promise<void>
  onCancel?: () => void
}

export function RuuviTagForm({
  values,
  onSubmit,
  onCancel
}: RuuviTagFormProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The uploaded file.
   */
  const [file, setFile] = useState<File | null>(null)

  /**
   * Indicates whether the photo dialog is open.
   */
  const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState<boolean>(false)

  /**
   * The URL of the uploaded file.
   */
  const fileUrl = file !== null ? URL.createObjectURL(file) : null

  /**
   * The initial values.
   */
  const initialValues: RuuviTagInput = {
    id: values?.id ?? '',
    device: values?.device ?? null,
    asset: {
      name: values?.asset?.name ?? '',
      description: values?.asset?.description,
      photo: values?.asset?.photo ?? null,
      project: values?.asset?.project ?? null,
      company: values?.asset?.company ?? null,
      level: values?.asset?.level ?? null,
      lift: values?.asset?.lift ?? null
    }
  }

  /**
   * Open the photo dialog.
   */
  const openPhotoDialog = () => {
    setIsPhotoDialogOpen(true)
  }

  /**
   * Close the photo dialog.
   */
  const closePhotoDialog = () => {
    setIsPhotoDialogOpen(false)
  }

  /**
   * The validation schema.
   */
  const validationSchema = Yup.object().shape({
    asset: Yup.object().shape({
      name: Yup.string().required('Name is required')
    })
  })

  /**
   * Handle the photo upload.
   */
  const handlePhotoUpload = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement
    const newFile = target.files?.[0]

    if (newFile && newFile.type.startsWith('image/')) {
      setFile(newFile)
      openPhotoDialog()
    }
  }

  /**
   * Handle the photo delete.
   */
  const handlePhotoDelete = () => {
    setFile(null)
    closePhotoDialog()
  }

  /**
   * Handle the cancel event.
   */
  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  /**
   * Handle the submit even.
   */
  const handleSubmit = async (
    values: RuuviTagInput,
    { setSubmitting }: FormikHelpers<RuuviTagInput>
  ) => {
    try {
      setSubmitting(true)

      if (values?.asset) {
        values.asset.photo = file
      }

      if (onSubmit) {
        await onSubmit(values)
      }
    } catch (error: unknown) {
      // TODO: Handle error.
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    // Load companies.
    dispatch(getCompanies())

    // Load projects.
    dispatch(getProjects())

    if (values?.asset?.project) {
      // Load levels of project.
      dispatch(getLevelsByProjectId(values.asset.project))

      // Load lifts of project.
      dispatch(getLiftsByProjectId(values.asset.project))
    }
  }, [null])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <TextField
                name="id"
                label={t('macAddress')}
                value={values?.id}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="device"
                label={t('device')}
                value={values?.device}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Stack
                direction="row"
                gap={3}
                justifyContent="space-between"
                alignItems="center"
              >
                {fileUrl ? (
                  <>
                    <Paper elevation={6} onClick={openPhotoDialog}>
                      <img
                        src={fileUrl}
                        style={{
                          display: 'block',
                          objectFit: 'cover',
                          width: '64px',
                          height: '64px',
                          borderRadius: '4px'
                        }}
                      />
                    </Paper>

                    <Stack direction="row" gap={3}>
                      <IconButton
                        color="error"
                        size="small"
                        onClick={handlePhotoDelete}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </>
                ) : (
                  <Button component="label" variant="contained" tabIndex={-1}>
                    {t('uploadNewPhoto')}
                    <HiddenInput type="file" onChange={handlePhotoUpload} />
                  </Button>
                )}
              </Stack>
              <Dialog
                open={isPhotoDialogOpen}
                onClose={closePhotoDialog}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>{t('previewPhoto')}</DialogTitle>
                <DialogContent>
                  {fileUrl !== null && (
                    <Paper elevation={6}>
                      <img
                        src={fileUrl}
                        style={{
                          display: 'block',
                          objectFit: 'cover',
                          width: '552px',
                          height: '552px',
                          borderRadius: '4px'
                        }}
                      />
                    </Paper>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={closePhotoDialog}>{t('close')}</Button>
                  <Button color="error" onClick={handlePhotoDelete}>
                    {t('delete')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="asset.name"
                label={t('name')}
                value={values?.asset?.name ?? ''}
                /* @ts-ignore */
                error={Boolean(touched?.asset?.name && errors?.asset?.name)}
                /* @ts-ignore */
                helperText={touched?.asset?.name && errors?.asset?.name}
                fullWidth
                disabled={isSubmitting}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                name="asset.description"
                label={t('description')}
                value={values?.asset?.description ?? ''}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <CompanySelect
                name="asset.company"
                label={t('company')}
                value={values?.asset?.company ?? ''}
                fullWidth
                disabled
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <ProjectSelect
                name="asset.project"
                label={t('project')}
                value={values?.asset?.project ?? ''}
                fullWidth
                disabled
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <LiftSelect
                name="asset.lift"
                label={t('lift')}
                value={values?.asset?.lift ?? null}
                disabled={isSubmitting}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <LevelSelect
                name="asset.level"
                label={t('level')}
                value={values?.asset?.level ?? null}
                disabled={isSubmitting}
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
              >
                <Button onClick={handleCancel}>{t('cancel')}</Button>
                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                >
                  {t('save')}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
