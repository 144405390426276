import { createSlice } from '@reduxjs/toolkit'
import companyService from '../../services/companyService'
import { Dispatch } from 'redux'
import { createNotification } from './notifications'
import { Company, CompanyQuery, EmailFromCompany } from '../../types'

interface CompanyState {
  companies: Company[]
  company: Company | undefined
  show: boolean
  status: 'idle' | 'loading' | 'failed'
  loading: boolean
}

const initialState: CompanyState = {
  companies: [],
  company: undefined,
  show: false,
  status: 'idle',
  loading: false
}

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies(state, action) {
      state.companies = action.payload
    },
    setCompany(state, action) {
      state.company = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    createCompany(state, action) {
      state.companies = [...state.companies, action.payload]
    },
    updateCompany(state, action) {
      state.companies = state.companies
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteCompany(state, action) {
      state.companies = state.companies.filter((u) => u.id !== action.payload)
    },
    setShow(state, action) {
      state.show = action.payload
    }
  }
})

export const {
  setCompanies,
  setCompany,
  setLoading,
  createCompany,
  updateCompany
} = companySlice.actions

export function getCompanies(query?: CompanyQuery) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(companySlice.actions.setLoading(true))
      const companies = await companyService.getCompanies(query)
      dispatch(companySlice.actions.setCompanies(companies))
      dispatch(companySlice.actions.setLoading(false))
    } finally {
      setLoading(false)
    }
  }
}

export function getCompany(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(companySlice.actions.setLoading(true))
      const company = await companyService.getCompany(id)
      dispatch(companySlice.actions.setCompany(company))
    } finally {
      dispatch(companySlice.actions.setLoading(false))
    }
  }
}

export function getCompanyById(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(companySlice.actions.setLoading(true))
      const response = await companyService.getCompany(id)
      dispatch(companySlice.actions.setCompanies(response))
    } finally {
      dispatch(companySlice.actions.setLoading(false))
    }
  }
}

export function sendEmailWithCompanyRole(email: EmailFromCompany) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await companyService.sendEmailWithCompanyRole(email)
      if (response.status === 200) {
        dispatch<any>(
          createNotification({
            show: true,
            type: 'success',
            message: 'Email sent succesfully!',
            timeout: 5000
          })
        )
      }
    } catch (error) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong!',
          timeout: 5000
        })
      )
    }
  }
}

export function deleteCompany(id: number, permanently?: boolean) {
  return async (dispatch: Dispatch) => {
    try {
      await companyService.deleteCompany(id, permanently ?? false)
      dispatch(companySlice.actions.deleteCompany(id))
      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Succesfully deleted company!',
          timeout: 5000
        })
      )
    } catch (error) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong!',
          timeout: 5000
        })
      )
    }
  }
}

export function setShowCompany(show: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(companySlice.actions.setShow(show))
  }
}

export default companySlice.reducer
