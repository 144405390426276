import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { green, blue } from '@mui/material/colors'
import { ChartData, ChartOptions, ChartDataset } from 'chart.js'
import LoadingState from './LoadingState'
import BarChart from './BarChart'
import EnergyPrice from '../types/EnergyPrice'
import {
  getDatesByRange,
  getDifferenceOfDateRangeInHours,
  humanizeTimestamp
} from '../utils/date'
import { transformEnergyPricesToMap } from '../utils/energyPrice'
import { getEnergyPrices } from '../services/energyPriceService'
import _ from 'lodash'
import EnergyPriceQuery from '../types/EnergyPriceQuery'
import DateInterval from '../enums/DateInterval'
import {
  startOfDay,
  startOfToday,
  startOfTomorrow,
  endOfDay,
  endOfToday,
  endOfTomorrow,
  isThisHour
} from 'date-fns'
import { DateRange, EnergyPriceSettings } from '../types'
import { EnergyPriceDateRangeMode } from '../enums'
export interface EnergyPriceChartProps {
  dateRange: DateRange
  query: EnergyPriceQuery
  settings?: EnergyPriceSettings
  options?: ChartOptions
}

export default function EnergyPriceChart({
  dateRange,
  query,
  settings,
  options
}: EnergyPriceChartProps) {
  const [t] = useTranslation('common')

  const dates: Date[] = getDatesByRange(
    dateRange.from,
    dateRange.to,
    DateInterval.ONE_HOUR
  )

  const datesAsIsoStrings: string[] = dates.map((date) => date.toISOString())

  const [isLoading, setIsLoading] = useState<boolean>(true)

  /**
   * The energy price of date range.
   */
  const [energyPrices, setEnergyPrices] = useState<EnergyPrice[]>([])

  /**
   * The energy prices of today.
   */
  const [energyPricesOfToday, setEnergyPricesOfToday] = useState<EnergyPrice[]>(
    []
  )

  /**
   * The energy prices of tomorrow.
   */
  const [energyPricesOfTomorrow, setEnergyPricesOfTomorrow] = useState<
    EnergyPrice[]
  >([])

  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  const chartData: ChartData = makeChartData()

  const chartOptions: ChartOptions = _.merge(makeChartOptions(), options)

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [null, settings])

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => {
      if (
        settings?.dateRangeMode === EnergyPriceDateRangeMode.SELECTED_DATE_RANGE
      ) {
        await boot(controller.signal)
      }
    })()
    return () => controller.abort()
  }, [query.from, query.to])

  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsLoading(true)
      await loadEnergyPrices(signal)
    } finally {
      setIsLoading(false)
    }
  }

  async function loadEnergyPrices(signal?: AbortSignal): Promise<void> {
    switch (settings?.dateRangeMode) {
      case EnergyPriceDateRangeMode.TODAY:
        await loadEnergyPricesOfToday(signal)
        break
      case EnergyPriceDateRangeMode.TOMORROW:
        await loadEnergyPricesOfTomorrow(signal)
        break
      case EnergyPriceDateRangeMode.SELECTED_DATE_RANGE:
      default:
        await loadEnergyPricesOfSelectedDateRange(signal)
        break
    }
  }

  async function loadEnergyPricesOfToday(signal?: AbortSignal): Promise<void> {
    setEnergyPricesOfToday(
      await getEnergyPrices(
        {
          ...query,
          from: startOfToday(),
          to: endOfToday()
        },
        {
          signal
        }
      )
    )
  }

  async function loadEnergyPricesOfTomorrow(
    signal?: AbortSignal
  ): Promise<void> {
    setEnergyPricesOfTomorrow(
      await getEnergyPrices(
        {
          ...query,
          from: startOfTomorrow(),
          to: endOfTomorrow()
        },
        {
          signal
        }
      )
    )
  }

  async function loadEnergyPricesOfSelectedDateRange(
    signal?: AbortSignal
  ): Promise<void> {
    setEnergyPrices(
      await getEnergyPrices(query, {
        signal
      })
    )
  }

  function makeChartData(): ChartData {
    return {
      labels: makeChartLabels(),
      datasets: makeChartDatasets()
    }
  }

  function makeChartLabels(): string[] {
    return datesAsIsoStrings
  }

  function makeChartDatasets(): ChartDataset[] {
    const data = makeDatasetData()
    const colors = makeDatasetDatasetColors()

    return [
      {
        label: `${t('Device.centsPerKwh')}`,
        backgroundColor: colors,
        borderColor: colors,
        // @ts-ignore
        data
      }
    ]
  }

  function makeDatasetData(): number[] | Array<{ x: string; y: number }> {
    switch (settings?.dateRangeMode) {
      case EnergyPriceDateRangeMode.TODAY:
        return getEnergyPricesAsTimeseries(energyPricesOfToday)
      case EnergyPriceDateRangeMode.TOMORROW:
        return getEnergyPricesAsTimeseries(energyPricesOfTomorrow)
      case EnergyPriceDateRangeMode.SELECTED_DATE_RANGE:
      default:
        return getEnergyPricesAsTimeseries(energyPrices)
    }
  }

  function makeDatasetDatasetColors(): string[] {
    return dates.map((date) => {
      return isThisHour(date) ? green[500] : blue[500]
    })
  }

  function makeChartOptions(): ChartOptions {
    return {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            // @ts-ignore
            title: (tooltipItems: TooltipItem[]): string => {
              if (tooltipItems.length) {
                return humanizeTimestamp(
                  // @ts-ignore
                  chartData.labels[tooltipItems[0].dataIndex] as string
                )
              }
              return ''
            }
          }
        }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit:
              differenceOfDateRangeInHours <= 3
                ? 'minute'
                : differenceOfDateRangeInHours <= 24
                ? 'hour'
                : 'day',
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm',
              day: 'd.M'
            }
          },
          ticks: {
            source: 'data'
          },
          grid: {
            display: false
          }
        }
      }
    }
  }

  function getEnergyPricesAsCentsPerKwh(energyPrices: EnergyPrice[]): number[] {
    const energyPricesByDate: Map<string, EnergyPrice> =
      transformEnergyPricesToMap(energyPrices)

    return datesAsIsoStrings.map((date) => {
      const energyPrice = energyPricesByDate.get(date)
      return energyPrice ? energyPrice.cents_per_kwh : 0
    })
  }

  function getEnergyPricesAsTimeseries(
    energyPrices: EnergyPrice[]
  ): Array<{ x: string; y: number }> {
    return energyPrices.map((energyPrice) => {
      return {
        x: energyPrice.time,
        y: energyPrice.cents_per_kwh
      }
    })
  }

  return (
    <>
      {isLoading ? (
        <LoadingState />
      ) : (
        <BarChart data={chartData} options={chartOptions} />
      )}
    </>
  )
}
