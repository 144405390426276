enum StartDateMode {
  START_OF_PROJECT = 1,
  START_OF_YEAR = 2,
  START_OF_MONTH = 3,
  START_OF_WEEK = 4,
  START_OF_DAY = 5,
  SELECTED_DATE = 6
}

export default StartDateMode
