import { useEffect, useState } from 'react'

import React from 'react'
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography,
  Divider as MuiDivider,
  CardHeader,
  Card as MuiCard,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Tab
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { createUpload, getUploads } from '../../redux/slices/uploads'
import UploadType from '../../enums/UploadType'
import { Manufacturers } from '../../enums/Manufacturers'
import { TabContext, TabList } from '@mui/lab'
import TabPanel from '@mui/lab/TabPanel'
import { UploadTab } from '../../enums/UploadTab'
import UploadTable from './UploadTable'
import { useAuth } from '../../hooks'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { deleteError, setError } from '../../redux/slices/errors'
import uploadService from '../../services/uploadService'
import { UploadErrors } from '../../enums/UploadErrors'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function UploadInstructions() {
  const [inputName, setInputName] = useState('')
  const [inputModel, setInputModel] = useState('')
  const [inputManufacturer, setInputManufacturer] = useState('')
  const navigate = useNavigate()
  const [t] = useTranslation('common')
  const dispatch = useDispatch()

  const { currentUser } = useAuth()

  useEffect(() => {
    dispatch(
      getUploads({ type: UploadType.INSTRUCTION, name: UploadType.INSTRUCTION })
    )
  }, [])

  const handleUpload = async (event: any) => {
    try {
      event.preventDefault()
      const file = event.currentTarget['fileInput'].files[0]
      const name = `${UploadType.INSTRUCTION}/${inputManufacturer as string}/${
        inputModel as string
      }/${inputName as string}`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', name)

      //POST
      const uploadedFile = await uploadService.postUpload(formData)
      dispatch(createUpload(uploadedFile))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('uploadCreateSuccess')
        )
      )
    } catch (error: unknown) {
      dispatch(
        setError({
          type: UploadErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UploadErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(UploadErrors.CREATE))
    }
  }

  const [checkedAsOther, setCheckedAsOther] = useState(false)
  const [tab, setTab] = useState<UploadTab>(UploadTab.DASHBOARD)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAsOther(event.target.checked)
  }

  const handleTabChange = (event: React.SyntheticEvent, tab: string): void => {
    setTab(tab as UploadTab)
  }

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate('/manufacturing')}
            >
              <ArrowBackIcon />
              {t('Manufacturing.back')}
            </Button>
            <Divider my={6} />

            <CardHeader
              title={t('Manufacturing.saveInstructionsForProduct')}
              align="center"
            />
            <form onSubmit={handleUpload} encType="multipart/form-data">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  {checkedAsOther === false ? (
                    <TextField
                      label={t('Manufacturing.productManufacturer')}
                      fullWidth
                      select
                      required
                    >
                      <MenuItem
                        value={Manufacturers.TSR_ELSITE}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.TSR_ELSITE)
                        }
                      >
                        {Manufacturers.TSR_ELSITE}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.SATEMA}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.SATEMA)
                        }
                      >
                        {Manufacturers.SATEMA}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.GARO}
                        onClick={() => setInputManufacturer(Manufacturers.GARO)}
                      >
                        {Manufacturers.GARO}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.EL_BJÖRN}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.EL_BJÖRN)
                        }
                      >
                        {Manufacturers.EL_BJÖRN}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.VOHEK}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.VOHEK)
                        }
                      >
                        {Manufacturers.VOHEK}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.APUWATTI}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.APUWATTI)
                        }
                      >
                        {Manufacturers.APUWATTI}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.SRS_FENNO}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.SRS_FENNO)
                        }
                      >
                        {Manufacturers.SRS_FENNO}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.GENERIC}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.GENERIC)
                        }
                      >
                        {Manufacturers.GENERIC}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.KK_SÄHKÖTUKKU}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.KK_SÄHKÖTUKKU)
                        }
                      >
                        {Manufacturers.KK_SÄHKÖTUKKU}
                      </MenuItem>
                      <MenuItem
                        value={Manufacturers.SPINE}
                        onClick={() =>
                          setInputManufacturer(Manufacturers.SPINE)
                        }
                      >
                        {Manufacturers.SPINE}
                      </MenuItem>
                    </TextField>
                  ) : (
                    <TextField
                      label={t('Manufacturing.productManufacturer')}
                      fullWidth
                      required
                      type="text"
                      onChange={(e) => setInputManufacturer(e.target.value)}
                    />
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox name="other" onChange={handleCheckboxChange} />
                    }
                    label={t('Manufacturing.other') as string}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Manufacturing.productModel')}
                    fullWidth
                    required
                    type="text"
                    onChange={(e) => setInputModel(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Manufacturing.productNumber')}
                    fullWidth
                    required
                    type="text"
                    onChange={(e) => setInputName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input id="fileInput" type="file" required />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="right">
                    <Button type="submit" variant="contained">
                      {t('Manufacturing.save')}
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Box sx={{ width: '100%' }}>
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange}>
              <Tab
                label={t('Manufacturing.instructions')}
                value={UploadTab.DASHBOARD as string}
                onClick={() =>
                  dispatch(
                    getUploads({
                      type: UploadType.INSTRUCTION,
                      name: UploadType.INSTRUCTION
                    })
                  )
                }
              />

              <Tab
                label={t('Manufacturing.pictures')}
                value={UploadTab.PICTURES as string}
                onClick={() =>
                  dispatch(
                    getUploads({ type: UploadType.QA, name: UploadType.QA })
                  )
                }
              />
            </TabList>
          </Box>

          <Box mt={6} sx={{ width: '100%' }}>
            <TabPanel value={UploadTab.DASHBOARD as string}>
              <UploadTable uploadType={UploadType.INSTRUCTION} />
            </TabPanel>

            <TabPanel value={UploadTab.PICTURES as string}>
              <UploadTable uploadType={UploadType.QA} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </React.Fragment>
  )
}
