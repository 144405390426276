import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { ConfluencePages, UserRole } from '../enums'
import { UserManualProvider } from '../contexts/userManual'
import RuuviTags from '../pages/pages/RuuviTags'
import RuuviTag from '../pages/pages/RuuviTag'
import RuuviTagStats from '../pages/pages/RuuviTagStats'
import RuuviTagInstall from '../pages/pages/RuuviTagInstall'

export default [
  {
    path: '/ruuvi-tags',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.RUUVI_TAGS}>
          <DashboardLayout>
            <RuuviTags />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: 'ruuvi-tags/install',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.INSTALLER
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.RUUVI_TAGS}>
          <DashboardLayout>
            <RuuviTagInstall />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: '/ruuvi-tags/:id',
    element: (
      <AuthGuard
        roles={[
          UserRole.SUPERADMIN,
          UserRole.ADMIN,
          UserRole.USER,
          UserRole.MANUFACTURER,
          UserRole.INSTALLER,
          UserRole.GUEST
        ]}
      >
        <UserManualProvider pageId={ConfluencePages.RUUVI_TAGS}>
          <DashboardLayout>
            <RuuviTag />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  },
  {
    path: 'ruuvi-tags/:id/stats',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <UserManualProvider pageId={ConfluencePages.RUUVI_TAGS}>
          <DashboardLayout>
            <RuuviTagStats />
          </DashboardLayout>
        </UserManualProvider>
      </AuthGuard>
    )
  }
]
