import {
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps
} from '@mui/material'
import { useCompanies } from '../hooks'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { Company } from '../types'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function CompanySelect(props: TextFieldProps) {
  const companies = useCompanies()

  return (
    <TextField select name="company" {...props}>
      {companies.map((company: Company) => (
        <MenuItem key={company.id} value={company.id}>
          {company.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
