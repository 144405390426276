import { createSlice } from '@reduxjs/toolkit'
import productService from '../../services/productService'
import { Dispatch } from 'redux'
import { Product, ProductQuery } from '../../types'

interface ProductsState {
  products: Product[]
  product: Product | undefined
  show: boolean
  isLoadingProducts: boolean
  step: number
}

const initialState: ProductsState = {
  products: [],
  product: undefined,
  show: false,
  isLoadingProducts: false,
  step: 0
}

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload
    },
    setProduct(state, action) {
      state.product = action.payload
    },
    setLoading(state, action) {
      state.isLoadingProducts = action.payload
    },
    updateProductState(state, action) {
      state.products = state.products
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteProductState(state, action) {
      state.products = state.products.filter((u) => u.id !== action.payload)
    },
    setStep(state, action) {
      state.step = action.payload
    }
  }
})

export const {
  setProducts,
  setProduct,
  updateProductState,
  deleteProductState
} = productSlice.actions

export function getProducts(query?: ProductQuery) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(productSlice.actions.setLoading(true))
      const product = await productService.getProducts(query)
      dispatch(productSlice.actions.setProducts(product))
    } finally {
      dispatch(productSlice.actions.setLoading(false))
    }
  }
}

export function getProduct(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(productSlice.actions.setLoading(true))
      const product = await productService.getProduct(id)
      dispatch(productSlice.actions.setProduct(product))
    } finally {
      dispatch(productSlice.actions.setLoading(false))
    }
  }
}

export default productSlice.reducer
