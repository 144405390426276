import axios from 'axios'
import { Company, GeoCoordinate } from '../types'

export async function forwardGeocoding(companies: Company[]) {
  const listOfLocations: GeoCoordinate[] = []
  companies.map(async (company: Company) => {
    const url =
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
      encodeURIComponent(company.address + ',' + company.city) +
      '.json?access_token=' +
      'pk.eyJ1Ijoic3BpbmUtbmV0d29ya3MiLCJhIjoiY2xnYnhlczdpMDg3YjNlbzA4MG54dWpuMiJ9.qSuhLSJhUIXXCRZMTWkyyg' +
      '&limit=1'

    const result = await axios.get(url)

    listOfLocations.push({
      long: result.data.features[0].center[0],
      lat: result.data.features[0].center[1]
    })
  })
  return listOfLocations
}
