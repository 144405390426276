import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import fi from 'date-fns/locale/fi'
import Chart from 'react-chartjs-2'
import { ChartData, ChartOptions, TooltipItem } from 'chart.js'
import 'chartjs-adapter-date-fns'
import _ from 'lodash'

// FIXME: We would like to control the height of the wrapper
// with props.
const BarChartWrapper = styled.div`
  height: 280px;
`

export interface BarChartProps {
  data: ChartData
  options: ChartOptions
}

export default function BarChart({ data, options }: BarChartProps) {
  const [t] = useTranslation('common')

  const chartOptions: ChartOptions = _.merge(
    {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      normalized: true,
      locale: 'fi-FI',
      font: {
        family: "'Inter', sans-serif"
      },
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false
      },
      plugins: {
        title: {
          display: true,
          position: 'bottom',
          padding: {
            top: 24
          },
          font: {
            weight: '600',
            size: 14
          },
          color: 'rgba(255, 255, 255, 1.0)'
        },
        legend: {
          display: true
        },
        decimation: {
          enabled: false,
          algorithm: 'min-max'
        }
      },
      scales: {
        x: {
          adapters: {
            date: {
              locale: fi
            }
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0
          },
          grid: {
            // @ts-ignore
            color: 'rgba(255, 255, 255, 0.0375)',
            // @ts-ignore
            fontColor: '#fff',
            // @ts-ignore
            drawBorder: false
          },
          color: '#fff'
        },
        y: {
          grid: {
            // @ts-ignore
            color: 'rgba(255, 255, 255, 0.0375)',
            // @ts-ignore
            fontColor: '#fff',
            // @ts-ignore
            drawBorder: false
          }
        }
      }
    },
    options
  )

  return (
    <BarChartWrapper>
      <Chart type="bar" data={data} options={chartOptions} />
    </BarChartWrapper>
  )
}
