import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Link,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CardHeader,
  Tooltip
} from '@mui/material'
import { spacing } from '@mui/system'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { deleteSector, showSector } from '../redux/slices/sectors'
import SectorForm from './SectorForm'
import EmptyState from './EmptyState'
import LoadingState from './LoadingState'
import { useSectors, useSector } from '../hooks'
import { useTranslation } from 'react-i18next'
import SectorInput from '../types/SectorInput'
import { Project, Sector } from '../types'
import { ProjectStateType } from '../enums/ProjectStateType'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import { useState } from 'react'

const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
interface SectorTableProps {
  project?: Project
}

export default function SectorTable({ project }: SectorTableProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const sectors: Sector[] = useSectors()

  const sector: Sector | undefined = useSector()

  const isLoading: boolean = useSelector(
    (state: RootState) => state.sectors.loading
  )

  const isShowing: boolean = useSelector(
    (state: RootState) => state.sectors.show
  )

  const isEmpty = sectors.length === 0

  const isUsingProjectScope = project !== undefined

  const formProps = project
    ? {
        value: { project: project?.id },
        hidden: ['project' as keyof SectorInput]
      }
    : {}

  const title = !isShowing
    ? t('sectors')
    : sector === undefined
    ? t('newSector')
    : t('editSector')

  function handleAdd(): void {
    dispatch(showSector(undefined))
  }

  function handleEdit(sector: Sector): void {
    dispatch(showSector(sector))
  }

  function handleDelete(sector: Sector): void {
    // FIXME: Use MUI dialog.
    // TODO: Warn user if sector has lifts.
    if (window.confirm(t('confirmDeleteSector'))) {
      dispatch(deleteSector(sector.id))
    }
  }

  /**
   * The grid columns.
   */
  const columns = makeGridColumns()

  /**
   * Make the grid columns.
   */
  function makeGridColumns(): GridColumns<Sector> {
    const columns: GridColumns<Sector> = []

    columns.push({
      field: 'name',
      headerName: t('name'),
      minWidth: 200,
      editable: false
    })

    if (!isUsingProjectScope) {
      columns.push({
        field: 'project.name',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        minWidth: 200,
        renderCell: (params) => (
          <>
            {params.row.project && (
              <Link
                component={NavLink}
                to={`/projects/${params.row.project.id}`}
              >
                {params.row.project.name}
              </Link>
            )}
          </>
        )
      })
    }

    columns.push({
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      align: 'right',
      renderCell: (params) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexGrow={0}
          spacing={2}
        >
          <Tooltip title={t('editSector') as string}>
            <IconButton
              size="small"
              data-testid="edit"
              onClick={() => handleEdit(params.row)}
              disabled={project?.state === ProjectStateType.ARCHIVED}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('deleteSector') as string}>
            <IconButton
              color="error"
              size="small"
              data-testid="delete"
              onClick={() => handleDelete(params.row)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    })

    return columns
  }

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          !isShowing && (
            <Button
              variant="outlined"
              color="primary"
              disabled={project?.state === ProjectStateType.ARCHIVED}
              onClick={() => handleAdd()}
              endIcon={<AddIcon />}
            >
              {t('newSector')}
            </Button>
          )
        }
      />
      <CardContent>
        {isLoading ? (
          <LoadingState />
        ) : isShowing ? (
          <SectorForm {...formProps} />
        ) : isEmpty ? (
          <EmptyState title={t('noSectors')} />
        ) : (
          <DataGrid
            rows={sectors}
            columns={columns}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 }
              }
            }}
            sx={{ border: 0 }}
          />
        )}
      </CardContent>
    </Card>
  )
}
