import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useProjects } from '../hooks'

export default function ProjectSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')
  const projects = useProjects()

  return (
    <TextField select name="project" label={t('project')} {...props}>
      {projects.map((project) => (
        <MenuItem key={project.id} value={project.id}>
          {project.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
