import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Typography,
  TextField,
  Button as MuiButton,
  Alert
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { RootState } from '../../redux/store'
import { QrReader } from 'react-qr-reader'
import { getProject, setProject } from '../../redux/slices/projects'
import { getDevice, setDevice } from '../../redux/slices/devices'
import PowerSupplyForm from './PowerSupplyForm'
import LevelForm from './LevelForm'
import LiftForm from './LiftForm'
import { setData, setStep } from '../../redux/slices/steps'
import { QRData } from '../../enums/QRData'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Button = styled(MuiButton)(spacing)

interface LinkDeviceWithProjectInstructionsProps {
  projectId: number
  handleBackStep(): void
}

export default function InstallDeviceToProjectInstructions({
  projectId,
  handleBackStep
}: LinkDeviceWithProjectInstructionsProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { step, data } = useSelector((state: RootState) => state.steps)
  const { device } = useSelector((state: RootState) => state.devices)
  const { project } = useSelector((state: RootState) => state.projects)
  const [shortUUID, setShortUUID] = useState('')

  useEffect(() => {
    if (projectId) {
      dispatch(getProject(projectId))
    }
  }, [])

  useEffect(() => {
    if (data !== QRData.NOT_SCANNED) {
      dispatch(getDevice(data.split('/').pop() as string))
    }
  }, [data])

  const handleNavigation = (step: number) => {
    if (step === 0) {
      //Navigate to installation main page
      dispatch(setStep(0))
      dispatch(setProject(undefined))
      handleBackStep()
    } else if (step === 1) {
      dispatch(setData(QRData.NOT_SCANNED))
      dispatch(setDevice(undefined))
      dispatch(setStep(step - 1))
    } else if (step === 4) {
      //if we are at lift form we need to go 2 steps back so we land to powerSupply form
      dispatch(setStep(step - 2))
    } else {
      dispatch(setStep(step - 1))
    }
  }

  const handleShortUUID = (shortUUID: string) => {
    dispatch(getDevice(shortUUID))
  }

  useEffect(() => {
    if (device && device.asset?.project && step === 0) {
      dispatch(setDevice(undefined))
      setShowAlert(true)
    }

    if (device && device.asset?.project === null) {
      dispatch(setStep(step + 1))
    }
  }, [device])

  const [showAlert, setShowAlert] = useState<boolean>(false)

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            {step < 4 && (
              <React.Fragment>
                <Button
                  sx={{ mt: 4, ml: 4 }}
                  size="small"
                  variant="contained"
                  onClick={() => handleNavigation(step)}
                >
                  <ArrowBackIcon />
                  {t('Manufacturing.back')}
                </Button>
                <Divider my={6} />
              </React.Fragment>
            )}

            <Grid justifyContent={'center'} alignItems="center">
              <Paper>
                {step === 0 && (
                  <>
                    <Typography align="center" variant="h6">
                      {t('Manufacturing.scanQR')}
                    </Typography>
                    <QrReader
                      scanDelay={500}
                      onResult={(result) => {
                        if (result) {
                          dispatch(setData(result.toString()))
                        }
                      }}
                      constraints={{ facingMode: 'environment' }}
                    />
                    <Divider my={6} />
                    <Typography align="center" variant="h6">
                      {t('Manufacturing.enterShortCode')}
                    </Typography>
                    <Divider my={6} />
                    <Grid container spacing={6}>
                      {showAlert ? (
                        <Grid item xs={12}>
                          <Alert
                            severity="warning"
                            onClose={() => setShowAlert(false)}
                          >
                            {t('deviceAlreadyInstalled')}
                          </Alert>
                        </Grid>
                      ) : (
                        <React.Fragment>
                          <Grid item xs={9}>
                            <TextField
                              sx={{ mb: 4, ml: 4 }}
                              fullWidth
                              size="small"
                              onChange={(e) => setShortUUID(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              sx={{ mb: 4, ml: 4 }}
                              variant="contained"
                              fullWidth
                              onClick={() => handleShortUUID(shortUUID)}
                            >
                              {t('Manufacturing.find')}
                            </Button>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </>
                )}

                {step === 1 && device && project && (
                  <PowerSupplyForm device={device} project={project} />
                )}

                {step === 2 && project && <LevelForm project={project} />}
                {step === 3 && project && <LiftForm project={project} />}
                {step === 4 && project && (
                  <Grid container spacing={6}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography align="left">
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => dispatch(setStep(0))}
                        >
                          {t('Manufacturing.addNextDevice')}
                          <AddIcon />
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Typography align="right">
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => navigate(`/projects/${project.id}`)}
                        >
                          {t('Manufacturing.goToProject')}
                          <ArrowForwardIcon />
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
