import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import styled from 'styled-components/macro'
import {
  Button,
  TextField as MuiTextField,
  Grid,
  Typography as MuiTypography,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { UserRole } from '../../enums/UserRole'
import userService from '../../services/userService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { InviteErrors } from '../../enums/InviteErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Typography = styled(MuiTypography)(spacing)
const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)

export default function ManufacturerInviteForm() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const navigate = useNavigate()

  const initialValues = {
    email: '',
    role: UserRole.MANUFACTURER
  }

  // TODO: Use translated validation messages.
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
    role: Yup.string().required('Role is required')
  })

  const { currentUser } = useAuth()

  const submit = async (
    // eslint-disable-next-line
    values: any,
    // @ts-ignore
    { setSubmitting, resetForm, setErrors }
  ) => {
    try {
      await userService.inviteManufacturer(values)
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('inviteCreateSuccess')
        )
      )
      resetForm()
    } catch (error: any) {
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: InviteErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: InviteErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(InviteErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl>
            <Card mb={6}>
              <CardContent pb={1}>
                <Typography gutterBottom align="left">
                  <Button variant="contained" onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                    {t('Manufacturing.back')}
                  </Button>
                </Typography>
                <Divider my={6} />
                <Typography variant="h4" gutterBottom align="center">
                  {t('Manufacturing.inviteManufacturers')}
                </Typography>
                <Divider my={6} />
              </CardContent>
              <CardContent pb={1}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      type="email"
                      name="email"
                      size="small"
                      label={t('Users.email')}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t('Project.inviteBtn')}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </form>
      )}
    </Formik>
  )
}
