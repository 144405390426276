import { ProductType } from '../enums/ProductType'
import { OrderItemType } from '../enums/OrderItemType'

export default function translateProductOrOrderItemType(type: string) {
  if (
    type === ProductType.INSTALLATION_KIT ||
    type === OrderItemType.INSTALLATION_KIT
  ) {
    return 'Manufacturing.kit'
  } else if (
    type === ProductType.PLUG_AND_PLAY ||
    type === OrderItemType.PLUG_AND_PLAY
  ) {
    return 'Manufacturing.plug'
  } else if (
    type === ProductType.SMART_ELECTRICAL_CABINET ||
    type === OrderItemType.SMART_ELECTRICAL_CABINET
  ) {
    return 'Manufacturing.cabinet'
  } else if (
    type === ProductType.SPINE_MODULE ||
    type === OrderItemType.SPINE_MODULE
  ) {
    return 'spineModule'
  }
}
