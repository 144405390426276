import {
  MenuItem,
  TextField as MuiTextField,
  TextFieldProps
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { MembershipRole } from '../enums/MembershipRole'
import { checkProjectRole } from '../utils/checkRole'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function MembershipSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')
  const Roles = [MembershipRole.ADMIN, MembershipRole.USER]

  return (
    <TextField
      select
      name="role"
      date-testid="role"
      label={t('role')}
      {...props}
      my={3}
    >
      {Roles.map((role) => (
        <MenuItem key={role} value={role}>
          {checkProjectRole(role)}
        </MenuItem>
      ))}
    </TextField>
  )
}
