import React from 'react'
import styled, { withTheme } from 'styled-components/macro'
import Chart from 'react-chartjs-2'
import { green, red, blue, grey, amber } from '@mui/material/colors'
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import useTheme from '../../../hooks/useTheme'
import { THEMES } from '../../../constants'
const Card = styled(MuiCard)(spacing)

const ChartWrapper = styled.div`
  height: 180px;
  position: relative;
`

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  text-align: center;
  z-index: 0;
`

const TableRow = styled(MuiTableRow)`
  height: 42px;
`

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`
const VoltageChart = ({ sensor }: any) => {
  const [t] = useTranslation('common')
  const { theme } = useTheme()
  const voltageAverage = (p1: number, p2: number, p3: number) => {
    const average = (+p1 + +p2 + +p3) / 3
    return Math.round(average)
  }

  const checkColor = (value: number) => {
    if (value < 220 || value > 240) {
      return red[500]
    } else return green[500]
  }
  const data = {
    labels: ['P1', 'P2', 'P3'],
    datasets: [
      {
        data: [
          Math.round(sensor.voltage_1 as number),
          Math.round(sensor.voltage_2 as number),
          Math.round(sensor.voltage_3 as number)
        ],
        backgroundColor: [blue[500], grey[500], amber[500]],
        borderWidth: 5,
        borderColor: theme === THEMES.DARK ? '#233044' : '#FFFFFFF2'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      }
    },
    cutout: '80%'
  }

  return (
    <Card mb={6}>
      <CardHeader title={t('Device.voltage')} />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography
              variant="h4"
              style={{
                color: checkColor(
                  voltageAverage(
                    sensor.voltage_1 as number,
                    sensor.voltage_2 as number,
                    sensor.voltage_3 as number
                  )
                )
              }}
            >
              {voltageAverage(
                sensor.voltage_1 as number,
                sensor.voltage_2 as number,
                sensor.voltage_3 as number
              )}
              V
            </Typography>
            <Typography variant="caption">
              {t('Device.averageVoltage')}
            </Typography>
          </DoughnutInner>
          <Chart type="doughnut" data={data} options={options} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Device.phase')}</TableCell>
              <TableCell align="right">{t('Device.voltage')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('Device.phase1')}
              </TableCell>
              <TableCell align="right">
                {Math.round(sensor.voltage_1 as number)}V
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('Device.phase2')}
              </TableCell>

              <TableCell align="right">
                {Math.round(sensor.voltage_2 as number)}V
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('Device.phase3')}
              </TableCell>

              <TableCell align="right">
                {Math.round(sensor.voltage_3 as number)}V
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default withTheme(VoltageChart)
