import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Grid,
  Stack,
  TextField as MuiTextField,
  MenuItem
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { useAuth } from '../hooks'
import LoadingState from './LoadingState'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { Announcement } from '../types'
import AnnouncementSeverity from '../enums/AnnouncementSeverity'
import announcementService from '../services/announcementService'
import {
  createAnnouncement,
  hideAnnouncement,
  updateAnnouncement
} from '../redux/slices/announcements'

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import useAnnouncement from '../hooks/useAnnouncement'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { fi } from 'date-fns/locale'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import SquareIcon from '@mui/icons-material/Square'
import { blue, red, orange, green } from '@mui/material/colors'
import { AnnouncementErrors } from '../enums/AnnouncementErrors'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

export default function AnnouncementForm() {
  /**
   * The dispatch function
   */
  const dispatch = useDispatch()

  /**
   * The translation function
   */
  const [t] = useTranslation('common')

  /**
   * The announcement
   */
  const announcement: Announcement | undefined = useAnnouncement()

  /**
   * The initial values
   */
  const initialValues =
    announcement === undefined
      ? {
          name: '',
          message: '',
          severity: AnnouncementSeverity.INFO,
          showAt: new Date(),
          hideAt: new Date(),
          customData: {
            url: '',
            urlName: ''
          }
        }
      : {
          id: announcement.id,
          name: announcement.name,
          message: announcement.message,
          severity: announcement.severity,
          showAt: announcement.showAt,
          hideAt: announcement.hideAt,
          customData:
            Object.keys(announcement.customData).length === 0
              ? {
                  url: '',
                  urlName: ''
                }
              : announcement.customData
        }

  /**
   * The validation schema
   */
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t('isRequired', {
        field: t('name')
      })
    ),
    message: Yup.string().required(
      t('isRequired', {
        field: t('message')
      })
    )
  })

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  async function handleSubmit(
    values: any,
    { resetForm, setSubmitting }: any
  ): Promise<void> {
    try {
      if (announcement === undefined) {
        const createdAnnouncement: Announcement =
          await announcementService.createAnnouncement(values)
        dispatch(createAnnouncement(createdAnnouncement))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('announcementCreateSuccess')
          )
        )
      } else {
        const updatedAnnouncement: Announcement =
          await announcementService.updateAnnouncement(values.id, values)
        dispatch(updateAnnouncement(updatedAnnouncement))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('announcementUpdateSuccess')
          )
        )
      }
      resetForm()
      dispatch(hideAnnouncement())
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: announcement
          ? AnnouncementErrors.EDIT
          : AnnouncementErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    return function cleanup() {
      dispatch(hideAnnouncement())
    }
  }, [dispatch])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        setValues,
        errors,
        isSubmitting,
        touched,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <TextField
                    name="name"
                    label={t('name')}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="severity"
                    label={t('severity')}
                    value={values.severity}
                    error={Boolean(touched.severity && errors.severity)}
                    helperText={touched.severity && errors.severity}
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    select
                    variant="outlined"
                  >
                    <MenuItem value={AnnouncementSeverity.INFO}>
                      Info <SquareIcon sx={{ mb: -2, color: blue[500] }} />
                    </MenuItem>
                    <MenuItem value={AnnouncementSeverity.SUCCESS}>
                      Success
                      <SquareIcon sx={{ mb: -2, color: green[500] }} />
                    </MenuItem>
                    <MenuItem value={AnnouncementSeverity.WARNING}>
                      Warning
                      <SquareIcon sx={{ mb: -2, color: orange[500] }} />
                    </MenuItem>
                    <MenuItem value={AnnouncementSeverity.ERROR}>
                      Error
                      <SquareIcon sx={{ mb: -2, color: red[500] }} />
                    </MenuItem>
                  </TextField>
                </Grid>

                <LocalizationProvider
                  //@ts-ignore
                  dateAdapter={AdapterDateFns}
                  adapterLocale={fi}
                >
                  <Grid item xs={6}>
                    <DateTimePicker
                      label={t('showAt')}
                      value={values.showAt}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          //@ts-ignore
                          showAt: value
                        })
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimePicker
                      label={t('hideAt')}
                      value={values.hideAt}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          //@ts-ignore
                          hideAt: value
                        })
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label={t('message')}
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="customData.url"
                    label={'URL'}
                    value={values.customData.url}
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="customData.urlName"
                    label={t('urlName')}
                    value={values.customData.urlName}
                    disabled={isSubmitting}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {t('save')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  )
}
