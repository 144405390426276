import BugReportIcon from '@mui/icons-material/BugReport'
import { Tooltip, IconButton as MuiIconButton, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BugReport } from '../BugReport'
import { useState } from 'react'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

export const NavbarBugReport: React.FC = () => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Tooltip title={t('Navbar.bugReport').toString()}>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setIsOpen(true)}
        >
          <BugReportIcon />
        </IconButton>
      </Tooltip>
      <BugReport isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}
