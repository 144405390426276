import '../vendor/aui.css'
import parse, { DOMNode, Element, domToReact } from 'html-react-parser'
import { Alert, Chip } from '@mui/material'
import { ConfluencePage } from '../types'

interface ConfluencePageArticleProps {
  page: ConfluencePage
  format?: 'view' | 'export_view'
}

export default function ConfluencePageArticle({
  page,
  format = 'view'
}: ConfluencePageArticleProps): JSX.Element {
  /**
   * Transform the node to an alert or chip.
   */
  function transform(node: DOMNode) {
    if (node.type === 'tag') {
      const element = nodeToElement(node)

      if (shouldTransformToImage(element)) {
        const apiUrl =
          process.env?.NODE_ENV === 'production'
            ? process.env?.REACT_APP_SPINE_API_URL_PRODUCTION
            : process.env?.REACT_APP_SPINE_API_URL_DEVELOPMENT

        if (apiUrl) {
          // Change the source of the image to our download API so that
          // we can stream the image from Confluence using API credentials.
          element.attribs.src = `${apiUrl}/v2/confluence/download?url=${encodeURIComponent(
            element.attribs.src
          )}`
        }

        return element
      } else if (shouldTransformToAlert(element)) {
        // Parse alert severity from node.
        const severity = parseAlertSeverity(element)

        // Transform children to inner HTML.
        const innerHtml = domToReact(element.children, {
          replace: transform
        })

        return (
          <Alert severity={severity} sx={{ mb: 6 }}>
            {innerHtml}
          </Alert>
        )
      } else if (shouldTransformToChip(element)) {
        // Parse chip color from node.
        const color = parseChipColor(element)

        // Parse label from node.
        const label = domToReact(element.children, {
          replace: transform
        })

        return <Chip color={color} label={label} />
      }
    }

    return undefined
  }

  /**
   * Checks if the element has the specified class.
   */
  function hasClass(element: Element, className: string): boolean {
    return element?.attribs.class?.split(' ').includes(className) || false
  }

  /**
   * Checks if the node should be transformed to an alert.
   */
  function shouldTransformToAlert(element: Element): boolean {
    return (
      element?.name === 'div' &&
      (hasClass(element, 'panel') ||
        hasClass(element, 'confluence-information-macro'))
    )
  }

  /**
   * Checks if the node should be transformed to a chip.
   */
  function shouldTransformToChip(element: Element): boolean {
    return element?.name === 'span' && hasClass(element, 'status-macro')
  }

  /**
   * Checks if the node should be transformed to an image.
   */
  function shouldTransformToImage(element: Element): boolean {
    return (
      element?.name === 'img' && hasClass(element, 'confluence-embedded-image')
    )
  }

  /**
   * Parse alert severity from element.
   */
  function parseAlertSeverity(element: Element) {
    if (hasClass(element, 'confluence-information-macro-tip')) {
      return 'success'
    } else if (hasClass(element, 'confluence-information-macro-note')) {
      return 'warning'
    } else if (hasClass(element, 'confluence-information-macro-warning')) {
      return 'error'
    } else {
      return 'info'
    }
  }

  /**
   * Parse the chip color from element.
   */
  function parseChipColor(element: Element) {
    if (hasClass(element, 'aui-lozenge-complete')) {
      return 'info'
    } else if (hasClass(element, 'aui-lozenge-success')) {
      return 'success'
    } else if (hasClass(element, 'aui-lozenge-current')) {
      return 'warning'
    } else if (hasClass(element, 'aui-lozenge-error')) {
      return 'error'
    } else {
      return undefined
    }
  }

  /**
   * Transform a DOM node to an element.
   */
  function nodeToElement(node: DOMNode): Element {
    return node as Element
  }

  return (
    <article>
      {/* @ts-ignore */}
      {parse(page.body[format]?.value ?? '', { replace: transform })}
    </article>
  )
}
