import { SensorAggregateStatistic } from '../enums'
import { PowerSupply, Statistic } from '../types'
import Sensor from '../types/Sensor'
import { lessThanMinutesAgo, moreThanMinutesAgo } from './date'

export function calculateKwh(sensor: Sensor): number {
  return Math.round(
    // @ts-ignore
    (parseFloat(sensor.current_1) * parseFloat(sensor.voltage_1) +
      // @ts-ignore
      parseFloat(sensor.current_2) * parseFloat(sensor.voltage_2) +
      // @ts-ignore
      parseFloat(sensor.current_3) * parseFloat(sensor.voltage_3)) /
      1000
  )
}

export function calculateMaxKwh(fuse: number): number {
  return Math.round((fuse * 400 * Math.sqrt(3)) / 1000)
}

export function calculateMaxPhaseKwH(fuse: number): number {
  return Math.round((fuse * 230) / 1000)
}

export function calculateSumOfCurrents(sensor: Sensor): number {
  return sensor.current_1 + sensor.current_2 + sensor.current_3
}

export function calculatePowerUsageAsPercentage(
  sensor: Sensor,
  powerSupply: PowerSupply
): number {
  const sum: number = calculateSumOfCurrents(sensor)
  const percentage: number = Math.round((sum / (powerSupply.fuse * 3)) * 100)
  return percentage > 100 ? 100 : percentage
}

export function calculatePhaseKwH(current: number, voltage: number) {
  return Math.round(
    // @ts-ignore
    (parseFloat(current) * parseFloat(voltage)) / 1000
  )
}

/**
 * Calculate the power for a sensor.
 *
 * @param {Sensor} sensor
 * @param {Statistic | SensorAggregateStatistic} statistic
 *
 * @returns {number}
 */
export function calculatePower(
  sensor: Sensor,
  statistic: Statistic | SensorAggregateStatistic = 'avg'
): number {
  statistic = statistic as Statistic

  switch (statistic) {
    case 'min':
      return sensor?.min_kw ?? sensor.kw
    case 'max':
      return sensor?.max_kw ?? sensor.kw
    case 'med':
      return sensor?.med_kw ?? sensor.kw
    case 'avg':
    default:
      return sensor?.avg_kw ?? sensor.kw
  }
}

/**
 * Calculate the voltage for a sensor.
 *
 * @param {Sensor} sensor
 * @param {Statistic | SensorAggregateStatistic} statistic
 *
 * @returns {number}
 */
export function calculateVoltage(
  sensor: Sensor,
  statistic: Statistic | SensorAggregateStatistic = 'avg'
): number {
  statistic = statistic as Statistic

  switch (statistic) {
    case 'min':
      return calculateMinimumVoltage(sensor)
    case 'max':
      return calculateMaximumVoltage(sensor)
    case 'med':
      return calculateMedianVoltage(sensor)
    case 'avg':
    default:
      return calculateAverageVoltage(sensor)
  }
}

/**
 * Calculate the average voltage for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The average voltage.
 */
export function calculateAverageVoltage(sensor: Sensor): number {
  return (sensor.voltage_1 + sensor.voltage_2 + sensor.voltage_3) / 3
}

/**
 * Calculate the minimum voltage for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The minimum voltage.
 */
export function calculateMinimumVoltage(sensor: Sensor): number {
  if (
    sensor.min_voltage_1 !== null &&
    sensor.min_voltage_2 !== null &&
    sensor.min_voltage_3 !== null
  ) {
    return (
      (sensor.min_voltage_1 + sensor.min_voltage_2 + sensor.min_voltage_3) / 3
    )
  }

  return calculateAverageVoltage(sensor)
}

/**
 * Calculate the maximum voltage for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The maximum voltage.
 */
export function calculateMaximumVoltage(sensor: Sensor): number {
  if (
    sensor.max_voltage_1 !== null &&
    sensor.max_voltage_2 !== null &&
    sensor.max_voltage_3 !== null
  ) {
    return (
      (sensor.max_voltage_1 + sensor.max_voltage_2 + sensor.max_voltage_3) / 3
    )
  }

  return calculateAverageVoltage(sensor)
}

/**
 * Calculate the median voltage for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The median voltage.
 */
export function calculateMedianVoltage(sensor: Sensor): number {
  if (
    sensor.med_voltage_1 !== null &&
    sensor.med_voltage_2 !== null &&
    sensor.med_voltage_3 !== null
  ) {
    return (
      (sensor.med_voltage_1 + sensor.med_voltage_2 + sensor.med_voltage_3) / 3
    )
  }

  return calculateAverageVoltage(sensor)
}

/**
 * Calculate the current for a sensor.
 *
 * @param {Sensor} sensor
 * @param {Statistic | SensorAggregateStatistic} statistic
 *
 * @returns {number}
 */
export function calculateCurrent(
  sensor: Sensor,
  statistic: Statistic | SensorAggregateStatistic = 'avg'
): number {
  statistic = statistic as Statistic

  switch (statistic) {
    case 'min':
      return calculateMinimumCurrent(sensor)
    case 'max':
      return calculateMaximumCurrent(sensor)
    case 'med':
      return calculateMedianCurrent(sensor)
    case 'avg':
    default:
      return calculateAverageCurrent(sensor)
  }
}

/**
 * Calculate the average current for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The average current.
 */
export function calculateAverageCurrent(sensor: Sensor): number {
  return (sensor.current_1 + sensor.current_2 + sensor.current_3) / 3
}

/**
 * Calculate the minimum current for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The minimum current.
 */
export function calculateMinimumCurrent(sensor: Sensor): number {
  if (
    sensor.min_current_1 !== null &&
    sensor.min_current_2 !== null &&
    sensor.min_current_3 !== null
  ) {
    return (
      (sensor.min_current_1 + sensor.min_current_2 + sensor.min_current_3) / 3
    )
  }

  return calculateAverageCurrent(sensor)
}

/**
 * Calculate the maximum current for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The maximum current.
 */
export function calculateMaximumCurrent(sensor: Sensor): number {
  if (
    sensor.max_current_1 !== null &&
    sensor.max_current_2 !== null &&
    sensor.max_current_3 !== null
  ) {
    return (
      (sensor.max_current_1 + sensor.max_current_2 + sensor.max_current_3) / 3
    )
  }

  return calculateAverageCurrent(sensor)
}

/**
 * Calculate the meidan current for a sensor.
 *
 * @param {Sensor} sensor
 *
 * @returns {number} The median current.
 */
export function calculateMedianCurrent(sensor: Sensor): number {
  if (
    sensor.med_current_1 !== null &&
    sensor.med_current_2 !== null &&
    sensor.med_current_3 !== null
  ) {
    return (
      (sensor.med_current_1 + sensor.med_current_2 + sensor.med_current_3) / 3
    )
  }

  return calculateAverageCurrent(sensor)
}

/**
 * Check if the sensor is recently measured.
 *
 * @param {Sensor} sensor - The sensor.
 *
 * @returns {boolean} True if the sensor is recently measured, false otherwise.
 */
export function isSensorNow(sensor: Sensor): boolean {
  return lessThanMinutesAgo(new Date(sensor.time), 5)
}
