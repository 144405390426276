import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/styles'
import { spacing } from '@mui/system'
import useAppSettings from '../hooks/useAppSettings'
import {
  SensorAggregateInterval,
  CalculationMode,
  StartDateMode
} from '../enums'
import DateInterval from '../enums/DateInterval'
import EmissionFactorMode from '../enums/EmissionFactorMode'
import EmissionFactorAggregateInterval from '../enums/EmissionFactorAggregateInterval'
import {
  ActiveProject,
  DateRange,
  Device,
  Emission,
  EmissionFactorAggregate,
  EmissionQuery,
  EmissionSettings,
  GeoCoordinate,
  Project,
  SensorAggregate,
  SensorAggregateMap
} from '../types'
import { getEmissionFactorAggregates } from '../services/emissionFactorAggregateService'
import { calculateEmission } from '../utils/emission'
import { getDeviceGeoCoordinate } from '../utils/device'
import { getStartDateByMode } from '../utils/date'
import { getSensorAggregates } from '../services/sensorAggregateService'
import { transformSensorAggregatesToMap } from '../utils/sensorAggregate'
import EmissionStats from './EmissionStats'
import { transformEmissionFactorAggregatesToMap } from '../utils/emissionAggregate'
import {
  getSensorAggregatesOfProjectByDevice,
  makeSensorAggregates
} from '../utils/project'
import { usePowerSupplies } from '../hooks'
import { getEnergyEmissionsOfProject } from '../services/projectService'
import { isFuture } from 'date-fns'

const TextField = styled(MuiTextField)(spacing)

interface ProjectEmissionStatsProps {
  project: Project | ActiveProject
  dateRange?: DateRange
  loading?: boolean
}

export default function ProjectEmissionStats({
  project,
  dateRange,
  loading = false
}: ProjectEmissionStatsProps) {
  const [t] = useTranslation('common')

  const appSettings = useAppSettings()

  const [settings, setSettings] = useState<EmissionSettings>({
    calculationMode: getInitialCalculationMode(),
    startDateMode: getInitialStartDateMode(),
    emissionFactorMode: getInitialEmissionFactorMode(),
    emissionFactorValue: getInitialEmissionFactorValue()
  })

  const [emissions, setEmissions] = useState<Emission[]>([])

  const [emissionsBeforeDateRange, setEmissionsBeforeDateRange] = useState<
    Emission[]
  >([])

  const [isBooting, setIsBooting] = useState<boolean>(true)

  /**
   * The start date of the project.
   */
  const startDate = new Date(project.startDate)

  /**
   * The end date of the project.
   */
  const endDate = new Date(project.endDate)

  /**
   * The date range of the project.
   */
  const dateRangeOfProject = {
    from: startDate,
    to: isFuture(endDate) ? new Date() : endDate
  }

  const usedDateRange = dateRange ? dateRange : dateRangeOfProject

  const query: EmissionQuery = {
    from: usedDateRange.from,
    to: usedDateRange.to
  }

  const queryBeforeDateRange: EmissionQuery = {
    from: getStartDateByMode(
      usedDateRange.from,
      settings.startDateMode,
      project
    ),
    to: usedDateRange.from
  }

  const isUsingFixedEmissionFactor: boolean =
    settings.emissionFactorMode === EmissionFactorMode.FIXED

  const isUsingRealtimeEmissionFactor: boolean =
    settings.emissionFactorMode === EmissionFactorMode.REALTIME

  const isUsingSelectedDateRange =
    settings.startDateMode === StartDateMode.SELECTED_DATE

  const area: number | undefined = project?.area

  const showStartDateModeOptions = dateRange !== undefined

  async function boot(signal?: AbortSignal): Promise<void> {
    try {
      setIsBooting(true)

      if (isUsingSelectedDateRange) {
        await loadEmissions(signal)
        setEmissionsBeforeDateRange([])
      } else {
        await Promise.all([
          loadEmissions(signal),
          loadEmissionsBeforeDateRange(signal)
        ])
      }
    } finally {
      setIsBooting(false)
    }
  }

  async function loadEmissions(signal?: AbortSignal): Promise<void> {
    setEmissions(
      await getEnergyEmissionsOfProject(
        project.id,
        {
          from: query.from,
          to: query.to
        },
        {
          signal
        }
      )
    )
  }

  async function loadEmissionsBeforeDateRange(
    signal?: AbortSignal
  ): Promise<void> {
    setEmissionsBeforeDateRange(
      await getEnergyEmissionsOfProject(
        project.id,
        {
          from: queryBeforeDateRange.from,
          to: queryBeforeDateRange.to
        },
        {
          signal
        }
      )
    )
  }

  function getInitialEmissionFactorMode(): EmissionFactorMode {
    return project?.fixedEmissionFactor
      ? EmissionFactorMode.FIXED
      : EmissionFactorMode.REALTIME
  }

  function getInitialEmissionFactorValue(): number | null {
    return project?.fixedEmissionFactor ?? null
  }

  function getInitialCalculationMode(): number {
    return (
      appSettings.projectStatsSettings?.costOfElectricityCalculationMode ??
      CalculationMode.CUMULATIVE
    )
  }

  function getInitialStartDateMode(): StartDateMode {
    return (
      appSettings.projectStatsSettings?.costOfElectricityStartDateMode ??
      StartDateMode.SELECTED_DATE
    )
  }

  function changeCalculationMode(calculationMode: CalculationMode): void {
    const appSettings = useAppSettings()
    const projectStatsSettings = appSettings.projectStatsSettings
    setSettings({
      ...settings,
      calculationMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        projectStatsSettings: {
          ...projectStatsSettings,
          emissionCalculationMode: calculationMode
        }
      })
    )
  }

  function changeStartDateMode(startDateMode: StartDateMode): void {
    const appSettings = useAppSettings()
    const projectStatsSettings = appSettings.projectStatsSettings
    setSettings({
      ...settings,
      startDateMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        projectStatsSettings: {
          ...projectStatsSettings,
          emissionStartDateMode: startDateMode
        }
      })
    )
  }

  function changeEmissionFactorMode(
    emissionFactorMode: EmissionFactorMode
  ): void {
    setSettings({
      ...settings,
      emissionFactorMode
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        emissionFactorMode: emissionFactorMode as string
      })
    )
  }

  function changeEmissionFactorValue(value: number) {
    setSettings({
      ...settings,
      emissionFactorValue: value
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        emissionFactorValue: value
      })
    )
  }

  useEffect(() => {
    const controller: AbortController = new AbortController()
    ;(async () => await boot(controller.signal))()
    return () => controller.abort()
  }, [project, dateRange?.from, dateRange?.to, settings.startDateMode])

  return (
    <EmissionStats
      emissions={emissions}
      emissionsBeforeDateRange={emissionsBeforeDateRange}
      query={query}
      queryBeforeDateRange={queryBeforeDateRange}
      settings={settings}
      area={area}
      loading={loading || isBooting}
      showStartDateModeOptions={showStartDateModeOptions}
      onChangeCalculationMode={changeCalculationMode}
      onChangeStartDateMode={changeStartDateMode}
      onChangeEmissionFactorMode={changeEmissionFactorMode}
      onChangeEmissionFactorValue={changeEmissionFactorValue}
    />
  )
}
