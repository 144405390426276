import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Link,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Button as MuiButton,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress,
  Box,
  IconButton,
  CardHeader,
  Tooltip
} from '@mui/material'
import { spacing } from '@mui/system'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  UploadFile
} from '@mui/icons-material'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { deleteLevel, showLevel } from '../redux/slices/levels'
import { useAuth, useLevels, useLevel } from '../hooks'
import { UserRole } from '../enums/UserRole'
import LevelInput from '../types/LevelInput'
import LevelForm, { LevelFormProps } from './LevelForm'
import EmptyState from './EmptyState'
import LoadingState from './LoadingState'
import { useState } from 'react'
import UploadType from '../enums/UploadType'
import { createUpload, deleteUpload } from '../redux/slices/uploads'
import { Blueprint } from '../types/Blueprint'
import { deleteError, setError } from '../redux/slices/errors'
import { UploadErrors } from '../enums/UploadErrors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import uploadService, { getBlueprintService } from '../services/uploadService'
import { Level, Project } from '../types'
import { ProjectStateType } from '../enums/ProjectStateType'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)

interface LevelTableProps {
  project?: Project
}

export default function LevelTable({ project }: LevelTableProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')

  const { currentUser } = useAuth()

  const levels: Level[] = useLevels()

  const level: Level | undefined = useLevel()

  const isLoading: boolean = useSelector(
    (state: RootState) => state.levels.loading
  )

  const isShowing: boolean = useSelector(
    (state: RootState) => state.levels.show
  )

  const isEmpty: boolean = levels.length === 0

  const isUsingProjectScope = !!project

  const formProps: LevelFormProps = isUsingProjectScope
    ? {
        value: { project: project?.id },
        hidden: ['project' as keyof LevelInput]
      }
    : {}

  const title = !isShowing
    ? t('levels')
    : level === undefined
    ? t('newLevel')
    : t('editLevel')

  function handleAdd() {
    dispatch(showLevel(undefined))
  }

  function handleEdit(level: Level) {
    dispatch(showLevel(level))
  }

  function handleDelete(level: Level) {
    // FIXME: Use MUI dialog.
    // TODO: Warn user if level has power supplies.
    if (window.confirm(t('confirmDeleteLevel'))) {
      dispatch(deleteLevel(level.id))
    }
  }

  const [open, setOpen] = useState(false)
  const [levelId, setLevelId] = useState<string | number>()
  const [upload, setUpload] = useState<Blueprint | undefined>(undefined)
  const [isAreaBlueprint, setIsAreaBlueprint] = useState(false)
  const navigate = useNavigate()

  const handleClickOpenLevelBlueprintForm = (level: Level) => {
    setIsAreaBlueprint(false)
    setLevelId(level.id)
    loadLevelBlueprint(level.id)
    setOpen(true)
  }

  const handleClickOpenProjectBlueprintForm = () => {
    setIsAreaBlueprint(true)
    loadAreaBlueprint(project?.id as number)
    setOpen(true)
  }

  const handleClose = () => {
    setIsAreaBlueprint(false)
    setOpen(false)
    setUpload(undefined)
  }

  async function loadLevelBlueprint(level: number): Promise<void> {
    setUpload(
      await getBlueprintService({
        project: project?.id as number,
        level: level
      })
    )
  }

  async function loadAreaBlueprint(project: number): Promise<void> {
    setUpload(await getBlueprintService({ project: project }))
  }
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleUpload = async (event: any) => {
    try {
      setIsSubmitting(true)
      event.preventDefault()
      const file = event.currentTarget['fileInput'].files[0]
      const name =
        isAreaBlueprint === false
          ? `${UploadType.BLUEPRINT}/project/${project?.id}/level/${levelId}`
          : `${UploadType.BLUEPRINT}/project/${project?.id}/area`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', name)

      //POST
      const uploadedFile = await uploadService.postUpload(formData)
      dispatch(createUpload(uploadedFile))
      setOpen(false)
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('uploadCreateSuccess')
        )
      )
    } catch (error: unknown) {
      dispatch(
        setError({
          type: UploadErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: UploadErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(UploadErrors.CREATE))
    } finally {
      setIsSubmitting(false)
    }
  }
  //Delete upload
  const handleBlueprintDelete = () => {
    dispatch(deleteUpload(upload?.blueprint.id as string))
    setUpload(undefined)
    setOpen(false)
  }

  /**
   * The grid columns.
   */
  const columns = makeColumns()

  /**
   * Make the grid columns.
   */
  function makeColumns(): GridColDef<Level>[] {
    const columns: GridColDef<Level>[] = []

    columns.push({
      field: 'name',
      headerName: t('name'),
      minWidth: 200,
      editable: false
    })

    columns.push({
      field: 'number',
      headerName: t('number'),
      type: 'number',
      minWidth: 200,
      editable: false,
      valueGetter: (params: any) =>
        params.row?.number !== null && params.row?.number > -999
          ? params.row.number
          : '-'
    })

    if (!isUsingProjectScope) {
      columns.push({
        field: 'project.name',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        renderCell: (params: any) => {
          return (
            <>
              {params.row.project && (
                <Link
                  component={NavLink}
                  to={`/projects/${params.row.project.id}`}
                >
                  {params.row.project.name}
                </Link>
              )}
            </>
          )
        }
      })
    }

    columns.push({
      field: t('buttons'),
      headerName: '',
      sortable: false,
      filterable: false,
      align: 'right',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <>
          {(currentUser?.role === UserRole.SUPERADMIN ||
            currentUser?.role === UserRole.ADMIN) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexGrow={0}
              spacing={2}
            >
              <Tooltip title={t('addFloorPlan') as string}>
                <IconButton
                  size="small"
                  disabled={project?.state === ProjectStateType.ARCHIVED}
                  onClick={() => handleClickOpenLevelBlueprintForm(params.row)}
                >
                  <UploadFile fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('editLevel') as string}>
                <IconButton
                  size="small"
                  data-testid="edit"
                  onClick={() => handleEdit(params.row)}
                  disabled={project?.state === ProjectStateType.ARCHIVED}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('deleteLevel') as string}>
                <IconButton
                  size="small"
                  color="error"
                  data-testid="delete"
                  onClick={() => handleDelete(params.row)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </>
      )
    })

    return columns
  }

  /**
   * The selected page size
   */
  const [pageSize, setPageSize] = useState<number>(25)

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <>
            {!isShowing && (
              <Stack direction="row" gap={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<UploadFile fontSize="inherit" />}
                  disabled={project?.state === ProjectStateType.ARCHIVED}
                  onClick={() => handleClickOpenProjectBlueprintForm()}
                >
                  {t('uploadAreaBlueprint')}
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<AddIcon fontSize="inherit" />}
                  disabled={project?.state === ProjectStateType.ARCHIVED}
                  onClick={() => handleAdd()}
                >
                  {t('newLevel')}
                </Button>
              </Stack>
            )}
          </>
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {upload ? (
          <>
            {isAreaBlueprint === false ? (
              <DialogTitle id="alert-dialog-title">
                {t('Project.deleteBlueprint')}
              </DialogTitle>
            ) : (
              <DialogTitle id="alert-dialog-title">
                {t('Project.deleteAreaBlueprint')}
              </DialogTitle>
            )}

            <Grid container spacing={6}>
              <Grid item xs={4}>
                <Typography align="left">
                  <Button
                    color="error"
                    ml={5}
                    mt={5}
                    mb={5}
                    variant="outlined"
                    onClick={() => handleBlueprintDelete()}
                  >
                    {t('Project.delete')}
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="center">
                  <Button
                    ml={5}
                    mt={5}
                    mb={5}
                    variant="outlined"
                    onClick={() =>
                      isAreaBlueprint
                        ? navigate(
                            `/blueprint/project/${project?.id}/level/area`
                          )
                        : navigate(
                            `/blueprint/project/${project?.id}/level/${levelId}`
                          )
                    }
                  >
                    {t('Project.open')}
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right">
                  <Button
                    variant="outlined"
                    mr={5}
                    mt={5}
                    mb={5}
                    onClick={handleClose}
                    autoFocus
                  >
                    {t('Project.close')}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {isAreaBlueprint === false ? (
              <DialogTitle id="alert-dialog-title">
                {t('Project.uploadBlueprint')}
              </DialogTitle>
            ) : (
              <DialogTitle id="alert-dialog-title">
                {t('Project.uploadAreaBlueprint')}
              </DialogTitle>
            )}

            <DialogContent>
              {isSubmitting ? (
                <Box
                  sx={{
                    width: '30vw',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '10vh'
                  }}
                >
                  <CircularProgress size="5vh" />
                </Box>
              ) : (
                <form onSubmit={handleUpload} encType="multipart/form-data">
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <input id="fileInput" type="file" required />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="left">
                        <Button mt={5} variant="outlined" type="submit">
                          {t('Project.upload')}
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography align="right">
                        <Button
                          variant="outlined"
                          mt={5}
                          onClick={handleClose}
                          autoFocus
                        >
                          {t('Project.close')}
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>

      <CardContent>
        {isLoading ? (
          <LoadingState />
        ) : isShowing ? (
          <LevelForm {...formProps} />
        ) : isEmpty ? (
          <EmptyState title={t('noLevels')} />
        ) : (
          <DataGrid
            rows={levels}
            columns={columns}
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar
            }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 }
              }
            }}
            sx={{ border: 0 }}
          />
        )}
      </CardContent>
    </Card>
  )
}
