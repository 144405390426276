import {
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularOff
} from '@mui/icons-material'
import { RuuviTagCondition } from '../types'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BlinkingComponent from './BlinkingComponent'

/**
 * The props of the ruuvi tag signal indicator.
 */
interface RuuviTagSignalIndicatorProps {
  /**
   * The condition.
   */
  condition: RuuviTagCondition

  /**
   * The font size.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
}

/**
 * A signal indicator for ruuvi tag.
 */
export default function RuuviTagSignalIndicator({
  condition,
  fontSize = 'inherit'
}: RuuviTagSignalIndicatorProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Indicates if the signal is excellent.
   */
  const isExcellentSignal = condition.rssi > -60

  /**
   * Indicates if the signal is good.
   */
  const isGoodSignal = condition.rssi > -80

  /**
   * Indicates if the signal is fair.
   */
  const isFairSignal = condition.rssi > -90

  /**
   * Indicates if the signal is poor.
   */
  const isPoorSignal = condition.rssi < -90

  /**
   * Get the message to be displayed in the tooltip.
   */
  const message = isExcellentSignal
    ? t('excellentSignal')
    : isGoodSignal
    ? t('goodSignal')
    : isFairSignal
    ? t('fairSignal')
    : isPoorSignal
    ? t('poorSignal')
    : t('noSignal')

  return (
    <Tooltip title={message} arrow>
      {isExcellentSignal ? (
        <SignalCellular4Bar fontSize={fontSize} color="success" />
      ) : isGoodSignal ? (
        <SignalCellular3Bar fontSize={fontSize} color="success" />
      ) : isFairSignal ? (
        <SignalCellular2Bar fontSize={fontSize} color="warning" />
      ) : isPoorSignal ? (
        <BlinkingComponent>
          <SignalCellular1Bar fontSize={fontSize} color="error" />
        </BlinkingComponent>
      ) : (
        <BlinkingComponent>
          <SignalCellularOff fontSize={fontSize} color="error" />
        </BlinkingComponent>
      )}
    </Tooltip>
  )
}
