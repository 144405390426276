import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { handleRefreshToken } from '../services/authService'

let apiUrl =
  process.env?.NODE_ENV === 'production'
    ? process.env?.REACT_APP_SPINE_API_URL_PRODUCTION
    : process.env?.REACT_APP_SPINE_API_URL_DEVELOPMENT

export default function useApi(path = 'v1'): AxiosInstance {
  if (apiUrl === undefined) {
    throw Error('Missing API URL configuration.')
  }

  // Remove the trailing slash.
  apiUrl = apiUrl.replace(/\/$/, '')

  // Remove the leading slash.
  path = path.replace(/^\//, '')

  // Remove the trailing slash.
  path = path.replace(/\/$/, '')

  const client: AxiosInstance = axios.create({
    baseURL: `${apiUrl}/${path}/`,
    headers: {
      'x-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json;charset=utf-8'
    }
  })

  client.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const accessToken: string | null =
        window.localStorage.getItem('accessToken')

      const temporaryToken: string | null =
        window.localStorage.getItem('temporaryToken')

      if (accessToken || temporaryToken) {
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${
          accessToken ?? temporaryToken
        }`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      const config = error?.config

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true

        await handleRefreshToken()

        return await client(config)
      }
      return Promise.reject(error)
    }
  )

  return client
}
