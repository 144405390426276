enum NominalCurrent {
  OTHER = '0',
  NOMINAL16 = '16',
  NOMINAL32 = '32',
  NOMINAL63 = '63',
  NOMINAL100 = '100',
  NOMINAL125 = '125',
  NOMINAL250 = '250',
  NOMINAL400 = '400',
  NOMINAL630 = '630',
  NOMINAL800 = '800',
  NOMINAL1000 = '1000',
  NOMINAL1200 = '1200',
  NOMINAL1250 = '1250'
}
export default NominalCurrent
