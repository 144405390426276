import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  Grid,
  CardHeader,
  Divider as MuiDivider
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { createLevel } from '../../redux/slices/levels'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { setStep } from '../../redux/slices/steps'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { useAuth } from '../../hooks'
import levelService from '../../services/levelService'
import { LevelErrors } from '../../enums/LevelErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { Level, LevelInput, Project } from '../../types'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Divider = styled(MuiDivider)(spacing)

interface LevelFormProps {
  project: Project
}

export default function LevelForm({ project }: LevelFormProps) {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The trasnlate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The current step.
   */
  const { step } = useSelector((state: RootState) => state.steps)

  const [levelNumber, setLevelNumber] = useState(0)

  const initialValues: LevelInput = {
    name: '',
    number: 0,
    project: project.id
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    number: Yup.string().required('Required')
  })

  const handleSubmit = async (
    values: LevelInput,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const createdLevel: Level = await levelService.createLevel(values)

      dispatch(createLevel(createdLevel))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('levelCreateSuccess')
        )
      )
      dispatch(setStep(step - 1))

      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
    } catch (error: unknown) {
      dispatch(
        setError({
          type: LevelErrors.CREATE,
          error: error
        })
      )

      const errorMessage = createUserNotification({
        user: currentUser,
        type: LevelErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(LevelErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardHeader
            title={t('Manufacturing.instructionsForLevel')}
            align="center"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider my={6} />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl>
                  <TextField
                    name="name"
                    label={t('Manufacturing.name')}
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={3} sm={3} md={3} lg={3} xl>
                  <Button
                    variant="contained"
                    fullWidth
                    mt={4}
                    onClick={() => setLevelNumber(levelNumber - 1)}
                  >
                    <RemoveIcon />
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl>
                  <TextField
                    name="number"
                    label={t('Manufacturing.number')}
                    value={(values.number = levelNumber)}
                    error={Boolean(touched.number && errors.number)}
                    fullWidth
                    InputProps={{
                      readOnly: true
                    }}
                    helperText={touched.number && errors.number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl>
                  <Button
                    variant="contained"
                    fullWidth
                    mt={4}
                    onClick={() => setLevelNumber(levelNumber + 1)}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={4}
                  fullWidth
                >
                  {t('Manufacturing.save')}
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
