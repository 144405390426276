import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Button as MuiButton,
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider as MuiDivider,
  CardHeader,
  Table,
  TableRow,
  TableCell,
  TextField
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getSensorNow } from '../../services/deviceService'
import { updateProductState } from '../../redux/slices/products'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { ProductErrors } from '../../enums/ProductErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { ProductStatus } from '../../enums/ProductStatus'
import { Device, Product, Sensor } from '../../types'
import { updateProduct } from '../../services/productService'
import LoadingState from '../LoadingState'
import { updatePowerSupply } from '../../services/powerSupplyService'
import { getDevice } from '../../services/sensorAggregateService'
import { useNavigate } from 'react-router-dom'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const Divider = styled(MuiDivider)(spacing)

interface DeviceSensorDataTestFormProps {
  product: Product
  showForm(showTestDeviceSensorDataForm: boolean): void
}

// eslint-disable-next-line
export default function DeviceSensorDataTestForm({
  product,
  showForm
}: DeviceSensorDataTestFormProps) {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  /**
   * SensorData now
   */
  const [sensorNow, setSensorNow] = useState<Sensor | undefined>(undefined)

  /**
   * Is loading SensorData
   */
  const [isLoadingSensor, setIsLoadingSensor] = useState<boolean>(false)

  /**
   * The device
   */
  const [device, setDevice] = useState<Device | undefined>(undefined)

  /**
   * Is loading device
   */
  const [isLoadingDevice, setIsLoadingDevice] = useState<boolean>(false)

  /**
   * Is submitting
   */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  /**
   * Custoemr reference
   */
  const [customerReference, setCustomerReference] = useState<
    string | undefined
  >(undefined)

  /**
   * Load sensor now on render once
   * Load device with powersupply relation
   */
  useEffect(() => {
    loadSensor()
    loadDevice()
  }, [])

  /**
   * Load sensor now every 30sec after first load
   */
  useEffect(() => {
    const timer = setInterval(async () => {
      loadSensor()
    }, 30000)
    return () => {
      clearInterval(timer)
    }
  }, [sensorNow])

  async function loadSensor() {
    try {
      setIsLoadingSensor(true)
      if (product?.device) {
        setSensorNow(await getSensorNow(product.device.name))
      }
    } finally {
      setIsLoadingSensor(false)
    }
  }

  async function loadDevice() {
    try {
      setIsLoadingDevice(true)
      if (product?.device) {
        setDevice(await getDevice(product.device.name))
      }
    } finally {
      setIsLoadingDevice(false)
    }
  }

  async function handleSubmit() {
    try {
      setIsSubmitting(true)

      /**
       * Update product
       */
      const updatedProduct = await updateProduct(product?.id as number, {
        status: ProductStatus.TESTED,
        sensor: sensorNow
      })

      /**
       * Update powersupply if customer reference is provided
       */
      if (customerReference && device?.powerSupply) {
        await updatePowerSupply(device?.powerSupply.id, {
          ...device?.powerSupply,
          parent: device?.powerSupply?.parent?.id,
          owner: device?.asset?.companyId,
          project: device?.asset?.projectId,
          level: device?.asset?.levelId,
          lift: device?.asset?.liftId,
          customerReference: customerReference
        })
      }

      dispatch(updateProductState(updatedProduct))
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('productUpdateSuccess')
        )
      )
      showForm(false)
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Card mb={6}>
      <CardContent>
        {isSubmitting ? (
          <LoadingState />
        ) : (
          <React.Fragment>
            <Divider my={6} />

            {product?.device && (
              <React.Fragment>
                <CardHeader
                  title={
                    t('deviceCode') + ': ' + product?.device?.shortUUID ?? '-'
                  }
                  align="center"
                />
                <CardHeader
                  title={
                    t('serialNumber') + ': ' + product?.device?.name ?? '-'
                  }
                  align="center"
                />
                <Divider my={6} />
                <CardHeader
                  title={t('testSensordataInstructions.title')}
                  align="center"
                />
                <Typography align="center">
                  <Table>
                    <TableRow>
                      1. {t('testSensordataInstructions.step1')}
                    </TableRow>
                    <TableRow>
                      2.{t('testSensordataInstructions.step2')}
                    </TableRow>
                    <TableRow>
                      3. {t('testSensordataInstructions.step3')}
                    </TableRow>
                    <TableRow>
                      4. {t('testSensordataInstructions.step4')}
                    </TableRow>
                    <TableRow>
                      5. {t('testSensordataInstructions.step5')}
                    </TableRow>
                  </Table>
                </Typography>
                <TextField
                  fullWidth
                  sx={{ mt: 4 }}
                  label={t('customerReference')}
                  onChange={(e) => setCustomerReference(e.target.value)}
                />
              </React.Fragment>
            )}
            <Divider my={6} />
            {isLoadingSensor || isLoadingDevice ? (
              <LoadingState />
            ) : (
              <Table sx={{ mb: 4 }}>
                <TableRow>
                  <TableCell align="center">
                    {t('Sensor.current_1') + ': '}
                    {sensorNow ? sensorNow.current_1 : '-'} A
                  </TableCell>
                  <TableCell align="center">
                    {t('Sensor.current_2') + ': '}
                    {sensorNow ? sensorNow.current_2 : '-'} A
                  </TableCell>
                  <TableCell align="center">
                    {t('Sensor.current_3') + ': '}
                    {sensorNow ? sensorNow.current_3 : '-'} A
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    {t('Sensor.voltage_1') + ': '}
                    {sensorNow ? sensorNow.voltage_1 : '-'} V
                  </TableCell>
                  <TableCell align="center">
                    {t('Sensor.voltage_2') + ': '}
                    {sensorNow ? sensorNow.voltage_2 : '-'} V
                  </TableCell>
                  <TableCell align="center">
                    {t('Sensor.voltage_3') + ': '}
                    {sensorNow ? sensorNow.voltage_3 : '-'} V
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    {t('firmwareVersion') + ': '}
                    {sensorNow ? sensorNow.firmware_version : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {t('hardwareVersion') + ': '}
                    {sensorNow ? sensorNow.hardware_version : '-'}
                  </TableCell>
                  <TableCell align="center">
                    {t('signal') + ': '}
                    {sensorNow ? sensorNow.rssi : '-'} dBm
                  </TableCell>
                </TableRow>
              </Table>
            )}

            <Grid container justifyContent={'flex-end'} gap={3}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate(`/devices/${device?.id}/troubleshoot`)
                }}
              >
                {t('troubleshoot.troubleshoot')}
              </Button>
              <Button
                disabled={sensorNow ? false : true}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t('markAsTested')}
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  )
}
