import React, { useState } from 'react'
import styled from 'styled-components/macro'

import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider as MuiDivider,
  CardHeader
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateProductState } from '../../redux/slices/products'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { ProductErrors } from '../../enums/ProductErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { ProductStatus } from '../../enums/ProductStatus'
import { OrderItem, Product } from '../../types'
import { updateProduct } from '../../services/productService'
import LoadingState from '../LoadingState'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const Divider = styled(MuiDivider)(spacing)

interface DispatchProductProps {
  products: Product[]
  orderItem?: OrderItem
  showForm(showTestDeviceSensorDataForm: boolean): void
}

// eslint-disable-next-line
export default function DispatchProduct({
  products,
  orderItem,
  showForm
}: DispatchProductProps) {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function handleSubmit() {
    try {
      setIsSubmitting(true)
      for (const product of products) {
        const updatedProduct = await updateProduct(product.id as number, {
          status: ProductStatus.SHIPPED
        })
        dispatch(updateProductState(updatedProduct))
      }

      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('productDispatchSuccess')
        )
      )
      showForm(false)
    } catch (error: unknown) {
      dispatch(
        setError({
          type: ProductErrors.EDIT,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: ProductErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(ProductErrors.EDIT))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Card mb={6}>
      <CardContent>
        {isSubmitting ? (
          <LoadingState />
        ) : (
          <React.Fragment>
            <Typography align="center" variant="h4">
              {t('Manufacturing.markAsShipped')}
            </Typography>
            <Divider my={6} />
            {orderItem && orderItem.order && orderItem.order.company && (
              <>
                <CardHeader
                  title={
                    t('Manufacturing.customer') +
                    ': ' +
                    orderItem.order.company.name
                  }
                  align="center"
                />

                <CardHeader
                  title={
                    t('Manufacturing.orderReference') +
                    ': ' +
                    orderItem.order.name
                  }
                  align="center"
                />

                <CardHeader
                  title={
                    t('Manufacturing.orderDispatchDate') +
                    ': ' +
                    orderItem.order.dueDate.toLocaleString()
                  }
                  align="center"
                />

                <CardHeader
                  title={
                    t('devices') +
                    ': ' +
                    products.map((product: Product) =>
                      ' ' + product?.device ? product.device.shortUUID : '-'
                    )
                  }
                  align="center"
                />
              </>
            )}

            <Divider my={6} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={() => handleSubmit()}
                >
                  {t('send')}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  )
}
