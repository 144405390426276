import { Grid, Card as MuiCard } from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import Project from '../types/Project'
import { useProjects } from '../hooks'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useNavigate } from 'react-router-dom'

const Card = styled(MuiCard)(spacing)

export default function ProjectsMap() {
  const apiKey = process.env.REACT_APP_MAPBOX_API_KEY

  const projects: Project[] | undefined = useProjects()
  const navigate = useNavigate()
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl>
      <Card mb={6}>
        {projects.length > 0 && (
          <Map
            mapboxAccessToken={apiKey}
            initialViewState={{
              latitude: projects[0].lat,
              longitude: projects[0].long,
              zoom: 10
            }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            style={{ width: '100%', height: 560 }}
          >
            {projects.map((project: Project) => (
              <Marker
                onClick={() => navigate(`/projects/${project.id}`)}
                longitude={project.long}
                latitude={project.lat}
              />
            ))}
          </Map>
        )}
      </Card>
    </Grid>
  )
}
