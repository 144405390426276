import liftService from '../../services/liftService'
import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import { createNotification } from './notifications'
import Lift from '../../types/Lift'

interface LiftsState {
  lifts: Lift[]
  lift: Lift | undefined
  show: boolean
  loading: boolean
}

const initialState: LiftsState = {
  lifts: [],
  lift: undefined,
  show: false,
  loading: false
}

const liftSlice = createSlice({
  name: 'lifts',
  initialState,
  reducers: {
    setLifts(state, action) {
      state.lifts = action.payload
    },
    setLift(state, action) {
      state.lift = action.payload
    },
    createLift(state, action) {
      state.lifts = [...state.lifts, action.payload].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    },
    updateLift(state, action) {
      state.lifts = state.lifts
        .map((lift) => (lift.id === action.payload.id ? action.payload : lift))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    deleteLift(state, action) {
      state.lifts = state.lifts.filter((u) => u.id !== action.payload)
    },
    showLift(state, action) {
      state.lift = action.payload
      state.show = true
    },
    hideLift(state) {
      state.show = false
      state.lift = undefined
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const { createLift, updateLift } = liftSlice.actions

export function getLifts() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(liftSlice.actions.setLoading(true))
      const lifts = await liftService.getLifts()
      dispatch(liftSlice.actions.setLifts(lifts))
    } finally {
      dispatch(liftSlice.actions.setLoading(false))
    }
  }
}

export function getLiftsByProjectId(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(liftSlice.actions.setLoading(true))
      const lifts = await liftService.getLiftsByProjectId(id)
      dispatch(liftSlice.actions.setLifts(lifts))
    } finally {
      dispatch(liftSlice.actions.setLoading(false))
    }
  }
}

export function getLift(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(liftSlice.actions.setLoading(true))
      const lift = await liftService.getLift(id)
      dispatch(liftSlice.actions.setLift(lift))
    } finally {
      dispatch(liftSlice.actions.setLoading(false))
    }
  }
}

export function deleteLift(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      await liftService.deleteLift(id)
      dispatch(liftSlice.actions.deleteLift(id))

      // FIXME: Translate notification message.
      dispatch<any>(
        createNotification({
          show: true,
          type: 'success',
          message: 'Lift deleted succesfully',
          timeout: 5000
        })
      )
    } catch (error: any) {
      // FIXME: Translate notification message.
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong',
          timeout: 0
        })
      )
    }
  }
}

export function showLift(lift?: Lift) {
  return async (dispatch: Dispatch) => {
    dispatch(liftSlice.actions.showLift(lift))
  }
}

export function hideLift() {
  return async (dispatch: Dispatch) => {
    dispatch(liftSlice.actions.hideLift())
  }
}

export default liftSlice.reducer
