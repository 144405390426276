import { ProjectStateType } from '../enums/ProjectStateType'

export default function checkProjectStatus(state: ProjectStateType) {
  switch (state) {
    case ProjectStateType.ACTIVE:
      return 'success'
    case ProjectStateType.INACTIVE:
      return 'error'
    case ProjectStateType.PLANNED:
      return 'primary'
    case ProjectStateType.EXTENDED:
      return 'warning'
    case ProjectStateType.ENDED:
      return 'warning'
  }
}
