import { Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

// Components
import SensorAggregateDateSelect from './SensorAggregateDateSelect'
import SensorAggregateFieldSelect from './SensorAggregateFieldSelect'
import SensorAggregateTimePeriodSelect from './SensorAggregateTimePeriodSelect'
import SensorAggregateStatisticSelect from './SensorAggregateStatisticSelect'
import DateRangeSelect from './DateRangeSelect'

// Types
import DateRange from '../types/DateRange'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import SensorAggregateQuery from '../types/SensorAggregateQuery'

// Enums
import SensorAggregateInterval from '../enums/SensorAggregateInterval'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'
import SensorAggregateField from '../enums/SensorAggregateField'

// Utilities
import { resolveDateRange, resolveFromDate } from '../utils/sensorAggregate'
import { getDifferenceOfDateRangeInHours } from '../utils/date'
import SensorAggregateStatisticToggle from './SensorAggregateStatisticToggle'
import useAppSettings from '../hooks/useAppSettings'
import { setDateRange } from '../redux/slices/query'
import { useDispatch } from 'react-redux'
import DateRangeBar from './DateRangeBar'

export interface SensorAggregateFiltersConfig {
  showDateSelect?: boolean
  showTimePeriodSelect?: boolean
  showStatisticSelect?: boolean
  showFieldSelect?: boolean
}
export interface SensorAggregateFiltersProps {
  query: SensorAggregateQuery
  options: SensorAggregateOptions
  config?: SensorAggregateFiltersConfig
  onQueryChange: {
    (query: SensorAggregateQuery): void
  }
  onOptionsChange: {
    (options: SensorAggregateOptions): void
  }
}

export default function SensorAggregateFilters({
  query,
  options,
  config,
  onQueryChange,
  onOptionsChange
}: SensorAggregateFiltersProps) {
  const [t] = useTranslation('common')
  const dispatch = useDispatch()

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()
  const sensorSettings = appSettings.sensorSettings

  /**
   * The date range.
   */
  const dateRange: DateRange = {
    from: query.from,
    to: query.to
  }

  /**
   * The difference of date range in hours.
   */
  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  /**
   * Indicates if sensor aggregates are being used.
   */
  const isUsingSensorAggregates = differenceOfDateRangeInHours > 3

  const selectedFields: SensorAggregateField[] = options.fields
  const selectedStatistics: SensorAggregateStatistic[] = options.statistics

  /**
   * Indicates if the date selection should be displayed.
   */
  const showDateSelect = config?.showDateSelect ?? true

  /**
   * Indicates if the statistics selecte should be displayed.
   */
  const showStatisticSelect = config?.showStatisticSelect ?? true

  /**
   * Indicates if the field selection should be displayed.
   */
  const showFieldSelect = config?.showFieldSelect ?? true

  function handleStatisticsChange(statistics: string[]): void {
    const selectedStatistics = statistics
      ? JSON.stringify(statistics)
      : undefined

    onOptionsChange({
      ...options,
      statistics: statistics.map(
        (statistic: string) => statistic as SensorAggregateStatistic
      )
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          sensorAggregateChartStatistics: statistics
        }
      })
    )

    if (selectedStatistics) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('statistics', selectedStatistics as string)

      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
    }
  }

  function handleFieldsChange(fields: string[]): void {
    const selectedFields = fields
      ? JSON.stringify(fields.filter((field) => field))
      : undefined

    onOptionsChange({
      ...options,
      fields: fields.map((field: string) => field as SensorAggregateField)
    })
    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          sensorAggregateChartFields: fields.filter((field) => field)
        }
      })
    )

    if (selectedFields) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('fields', selectedFields as string)

      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
    }
  }

  function changeDateRange(dateRange: DateRange | null): void {
    dispatch(setDateRange(dateRange as DateRange))
    onQueryChange({
      ...query,
      ...dateRange
    })
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={6}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        {showFieldSelect && (
          <SensorAggregateFieldSelect
            value={selectedFields}
            onValueChange={handleFieldsChange}
          />
        )}

        {showStatisticSelect && (
          <SensorAggregateStatisticToggle
            value={selectedStatistics}
            onChange={handleStatisticsChange}
          />
        )}
      </Stack>

      {showDateSelect && (
        <DateRangeBar
          includeOneHourOption={true}
          showAutoRefreshToggle={false}
        />
      )}
    </Stack>
  )
}
