import { Dispatch } from 'redux'
import { createSlice } from '@reduxjs/toolkit'
import { ProjectPhase } from '../../types'
import projectService from '../../services/projectService'

interface PhaseState {
  phases: ProjectPhase[]
  phase: ProjectPhase | undefined
  show: boolean
  loading: boolean
}

const initialState: PhaseState = {
  phases: [],
  phase: undefined,
  show: false,
  loading: false
}

const phaseSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {
    setPhases(state, action) {
      state.phases = action.payload
    },
    setPhase(state, action) {
      state.phase = action.payload
    },
    createPhaseState(state, action) {
      state.phases = [...state.phases, action.payload]
    },
    updatePhaseState(state, action) {
      state.phases = state.phases.map((phase) =>
        phase.id === action.payload.id ? action.payload : phase
      )
    },
    deletePhase(state, action) {
      state.phases = state.phases.filter((phase) => phase.id !== action.payload)
    },
    showPhase(state, action) {
      state.phase = action.payload
      state.show = true
    },
    hidePhase(state) {
      state.show = false
      state.phase = undefined
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  }
})

export const {
  createPhaseState,
  updatePhaseState,
  deletePhase,
  showPhase,
  hidePhase,
  setPhase
} = phaseSlice.actions

export function getProjectPhases(projectId: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(phaseSlice.actions.setLoading(true))
      const phases = await projectService.getProjectPhases(projectId)
      dispatch(phaseSlice.actions.setPhases(phases))
    } finally {
      dispatch(phaseSlice.actions.setLoading(false))
    }
  }
}

export default phaseSlice.reducer
