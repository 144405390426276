import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Stack,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { Zap as PowerSupplyIcon } from 'react-feather'
import DeviceCard from './DeviceCard'
import { Device, PowerSupply } from '../types'
import { AlertGroup } from '../types/Alerts'

const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Chip = styled(MuiChip)(spacing)

export interface PowerSupplyCardProps {
  powerSupply: PowerSupply
  alertRules: AlertGroup[]
}

export default function PowerSupplyCard({
  powerSupply,
  alertRules
}: PowerSupplyCardProps) {
  const [t] = useTranslation('common')

  const powerSupplySizeString = `${powerSupply.voltage_1_ts}/${powerSupply.fuse}A`

  return (
    <Card variant="outlined" sx={{ width: '168px', height: '168px' }}>
      <CardContent
        padding={0}
        sx={{ paddingBottom: '0px !important', height: '100%' }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="space-between"
          height="100%"
        >
          <Stack
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center"
            px={3}
            pt={3}
            pb={1}
          >
            {/*<Typography fontWeight={700} fontSize={10}>
              {powerSupplySizeString}
            </Typography>*/}
            <Typography fontWeight={700} fontSize={14}>
              {powerSupply.name}
            </Typography>
          </Stack>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingX={3}
          >
            {powerSupply?.device && (
              <DeviceCard
                key={powerSupply.device.id}
                device={powerSupply.device}
                powerSupply={powerSupply}
                alertRules={alertRules}
              />
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={3}
          >
            {powerSupply?.parent && (
              <Chip
                icon={<PowerSupplyIcon size={10} />}
                label={powerSupply.parent.name}
                size="small"
                color="secondary"
                sx={{
                  fontSize: 10
                }}
              />
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
