import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  MenuItem,
  Divider as MuiDivider,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import {
  getMyProjects,
  getProjects,
  setProject
} from '../../redux/slices/projects'
import useAuth from '../../hooks/useAuth'
import { RootState } from '../../redux/store'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import AddIcon from '@mui/icons-material/Add'
import { setData, setStep } from '../../redux/slices/steps'
import { setDevice } from '../../redux/slices/devices'
import { QRData } from '../../enums/QRData'
import { ActiveProject, Project } from '../../types'
import DeviceInstallProjectCreation from './DeviceInstallProjectCreation'
import { NotificationType } from '../../enums/NotificationType'
import { ProjectReducerType } from '../../enums/ProjectReducerType'
import { createRequest } from '../../services/requestService'
import { ActionRequestType } from '../../enums/RequestAccessType'
import ProjectMembershipRole from '../../enums/ProjectMembershipRole'
import { createUserNotification } from '../../utils/createUserNotification'
import { RequestAccessErrors } from '../../enums/RequestAccessErrors'
import { RequesterType } from '../../enums/RequesterType'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const Grid = styled(MuiGrid)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)

/**
 * Initial values
 */
const initialValues = {
  project: ''
}

/**
 * Navigator options
 */
const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
}

interface DeviceInstallProjectFormProps {
  projectSelected(selectedProject: number): void
}

export default function SelectProjectForm({
  projectSelected
}: DeviceInstallProjectFormProps) {
  const dispatch = useDispatch()

  const [t] = useTranslation('common')
  /**
   * Current usuer
   */
  const { currentUser } = useAuth()
  /**
   * User's active projects
   */
  const activeProjects = useSelector(
    (state: RootState) => state.projects.activeProjects
  )
  /**
   * The projects
   */
  const { projects } = useSelector((state: RootState) => state.projects)

  /**
   * Is checkbox checked
   */
  const [checked, setChecked] = useState(false)

  /**
   * User wants to create new project base
   */
  const [createNewProject, setCreateNewProject] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setDevice(undefined))
    dispatch(setData(QRData.NOT_SCANNED))
    dispatch(setProject(undefined))
    dispatch(setStep(0))
    dispatch(getMyProjects(ProjectReducerType.ACTIVE_PROJECTS))
  }, [currentUser])

  /**
   * Handle user's current location
   */
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error, options)
    } else {
      dispatch<any>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t('Manufacturing.notSupported')
        )
      )
    }
  }

  /**
   * Location handling success
   * Fetch projects by user's current location
   * @param position
   */
  function success(position: any) {
    const latitude = position.coords.latitude
    const longitude = position.coords.longitude
    dispatch(getProjects({ showDeleted: false, lat: latitude, lon: longitude }))
  }

  /**
   * Location handling error
   */
  function error() {
    dispatch<any>(
      createErrorOrSuccessNotification(
        NotificationType.WARNING,
        t('Manufacturing.locationNotFound')
      )
    )
  }

  /**
   * Checkbox handler
   * If checked try to fetch user's current location
   * @param event
   */
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    setSelectedProject(undefined)
    if (event.target.checked === true) {
      handleLocationClick()
    }
  }

  const [selectedProject, setSelectedProject] = useState<string | undefined>(
    undefined
  )

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (checked && selectedProject) {
        await createRequest({
          requesterId: currentUser?.id,
          requestableType: ActionRequestType.PROJECT,
          requestableId: parseInt(selectedProject),
          role: ProjectMembershipRole.USER,
          message: null,
          requesterType: RequesterType.USER
        })
        dispatch<any>(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('requestCreateSuccess')
          )
        )
        resetForm()
      } else if (checked === false) {
        if (selectedProject) {
          projectSelected(parseInt(selectedProject))
        } else {
          dispatch<any>(
            createErrorOrSuccessNotification(
              NotificationType.WARNING,
              t('Manufacturing.chooseProjectWarning')
            )
          )
        }

        resetForm()
      }
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.CREATE,
        error: error
      })

      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    }
  }

  return (
    <React.Fragment>
      {createNewProject ? (
        /**
         * Project base creation
         */
        <DeviceInstallProjectCreation
          projectCreatedAndEmailSent={(createdProject) => {
            projectSelected(createdProject)
          }}
          handleCancel={() => {
            setCreateNewProject(false)
          }}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <Card mb={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Typography align="left">
                      <Button
                        size="small"
                        onClick={() => {
                          setCreateNewProject(true)
                        }}
                        variant="contained"
                        color="success"
                        mt={3}
                      >
                        {t('Manufacturing.createNewProject')}
                        <AddIcon />
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider my={6} />
                <form onSubmit={handleSubmit}>
                  {checked === false && (
                    <>
                      <CardHeader
                        title={t('Manufacturing.chooseActiveProject')}
                        align="center"
                        titleTypographyProps={{ variant: 'h6' }}
                      />
                      <Grid container spacing={6}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl>
                          <TextField
                            name="project"
                            label={t('Manufacturing.yourProjects')}
                            select
                            value={selectedProject}
                            error={Boolean(touched.project && errors.project)}
                            fullWidth
                            helperText={touched.project && errors.project}
                            onBlur={handleBlur}
                            onChange={(e) => setSelectedProject(e.target.value)}
                            type="text"
                            variant="outlined"
                            my={2}
                          >
                            {activeProjects.map((project: ActiveProject) => (
                              <MenuItem
                                key={project.membership?.id}
                                value={project.id}
                              >
                                {project.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {checked === true && (
                    <>
                      <CardHeader
                        title={t('Manufacturing.chooseNearbyProject')}
                        align="center"
                        titleTypographyProps={{ variant: 'h6' }}
                      />
                      <Grid container spacing={6}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl>
                          <TextField
                            name="project"
                            label={t('Manufacturing.nearbyProjects')}
                            select
                            value={selectedProject}
                            error={Boolean(touched.project && errors.project)}
                            fullWidth
                            helperText={touched.project && errors.project}
                            onBlur={handleBlur}
                            onChange={(e) => setSelectedProject(e.target.value)}
                            type="text"
                            variant="outlined"
                            my={2}
                          >
                            {projects.map((project: Project) => (
                              <MenuItem key={project.id} value={project.id}>
                                {project.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <FormControlLabel
                    control={<Checkbox onChange={handleCheckboxChange} />}
                    label={t('Manufacturing.wantsNearbyProjects') as string}
                  />

                  <Divider my={6} />

                  <Typography align="right">
                    {checked ? (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                      >
                        {t('requestAccess')}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mt={3}
                      >
                        {t('Manufacturing.next')}
                      </Button>
                    )}
                  </Typography>
                </form>
              </CardContent>
            </Card>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}
