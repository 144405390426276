import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'

export interface RowData {
  id: string
  name: string
  value?: number
  checked: boolean
}

interface TroubleShootTableProps {
  rows: RowData[]
  onCheckBoxChange: (index: number, state: boolean) => void
}

export default function TroubleshootTable({
  rows,
  onCheckBoxChange
}: TroubleShootTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Incorrect</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.value}</TableCell>
              <TableCell>
                <Checkbox
                  onChange={(event) =>
                    onCheckBoxChange(index, event.target.checked)
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
