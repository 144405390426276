import DashboardLayout from '../layouts/Dashboard'
import AuthGuard from '../components/guards/AuthGuard'
import { UserRole } from '../enums'
import PowerSupplies from '../pages/pages/PowerSupplies'
import PowerSupply from '../pages/pages/PowerSupply'

export default [
  {
    path: '/powersupplies',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <PowerSupplies />
        </DashboardLayout>
      </AuthGuard>
    )
  },
  {
    path: '/powersupplies/:id',
    element: (
      <AuthGuard roles={[UserRole.SUPERADMIN]}>
        <DashboardLayout>
          <PowerSupply />
        </DashboardLayout>
      </AuthGuard>
    )
  }
]
