import { useTranslation } from 'react-i18next'
import LineChart from './LineChart'
import BarChart from './BarChart'
import {
  getDatesByRange,
  getDifferenceOfDateRangeInHours,
  humanizeTimestamp
} from '../utils/date'
import {
  calculateAvgKwh,
  transformSensorAggregatesToMap
} from '../utils/sensorAggregate'
import { transformEmissionFactorAggregatesToMap } from '../utils/emissionAggregate'
import { calculateEmission, mapEmissionsByDate } from '../utils/emission'
import { ChartData, ChartDataset, ChartOptions } from 'chart.js'
import { blue } from '@mui/material/colors'
import { CalculationMode, DateInterval, EmissionFactorMode } from '../enums'
import {
  Emission,
  EmissionQuery,
  EmissionSettings,
  EmissionFactorAggregate,
  SensorAggregate
} from '../types'

export interface EmissionChartProps {
  emissions?: Emission[]
  query: EmissionQuery
  settings: EmissionSettings
  initialEmission?: number

  // @deprecated
  sensorAggregates?: SensorAggregate[]
  emissionFactorAggregates?: EmissionFactorAggregate[]
}

export default function EmissionChart({
  emissions,
  query,
  settings,
  initialEmission = 0,
  sensorAggregates = [],
  emissionFactorAggregates = []
}: EmissionChartProps) {
  const [t] = useTranslation('common')

  const dates: Date[] = getDatesByRange(
    query.from,
    query.to,
    DateInterval.ONE_HOUR
  )

  const datesAsIsoStrings: string[] = dates.map((date) => date.toISOString())

  const chartData: ChartData = makeChartData()

  const chartOptions: ChartOptions = makeChartOptions()

  function makeChartData(): ChartData {
    return {
      labels: makeLabels(),
      datasets: makeDatasets()
    }
  }

  function makeChartOptions(): ChartOptions {
    return {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        tooltip: {
          callbacks: {
            // @ts-ignore
            title: (tooltipItems: TooltipItem[]): string => {
              if (tooltipItems.length) {
                return humanizeTimestamp(
                  // @ts-ignore
                  chartData?.labels[tooltipItems[0].dataIndex]
                )
              }
              return ''
            }
          }
        }
      },
      scales: {
        x: {
          type: 'timeseries',
          time: {
            // @ts-ignore
            unit:
              getDifferenceOfDateRangeInHours(
                // @ts-ignore
                new Date(chartData?.labels[0]),
                // @ts-ignore
                new Date(chartData?.labels[chartData?.labels.length - 1])
              ) > 24
                ? 'day'
                : 'hour',
            displayFormats: {
              hour: 'HH:00',
              day: 'd.M'
            }
          },
          grid: {
            display: false
          }
        },
        y: {
          min: 0
          // TODO: Use smarter max scaling.
          // suggestedMax:
          //   settings.calculationMode === CalculationMode.CUMULATIVE ? 48 : 2
        }
      }
    }
  }

  function makeLabels(): string[] {
    return datesAsIsoStrings
  }

  function makeDatasets(): ChartDataset[] {
    return [
      {
        label: `${t('Device.gCo2')}`,
        backgroundColor: blue['500'],
        borderColor: blue['500'],
        data:
          settings.calculationMode === CalculationMode.CUMULATIVE
            ? makeCumulativeEmissionData()
            : makeIncrementalEmissionData(),
        fill: settings.calculationMode === CalculationMode.CUMULATIVE
      }
    ]
  }

  function makeIncrementalEmissionData(): number[] {
    return emissions
      ? makeIncrementalEmissionDataUsingEmissions()
      : makeIncrementalEmissionDataUsingSensorAggregates()
  }

  function makeIncrementalEmissionDataUsingEmissions(): number[] {
    if (!emissions) {
      return []
    }

    const emissionsByDate = mapEmissionsByDate(emissions)
    return datesAsIsoStrings.map((date) => {
      const emission = emissionsByDate.get(date)
      return emission ? emission.value : 0
    })
  }

  function makeIncrementalEmissionDataUsingSensorAggregates(): number[] {
    const emissionsData: number[] = []

    const sensorAggregatesByDate: Map<string, SensorAggregate> =
      transformSensorAggregatesToMap(sensorAggregates)

    const emissionFactorAggregatesByDate: Map<string, EmissionFactorAggregate> =
      transformEmissionFactorAggregatesToMap(emissionFactorAggregates)

    datesAsIsoStrings.forEach((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)
      const emissionFactorAggregate = emissionFactorAggregatesByDate.get(date)

      if (
        sensorAggregate &&
        settings.emissionFactorMode === EmissionFactorMode.FIXED &&
        settings.emissionFactorValue
      ) {
        const emissionAsKg =
          calculateEmission(
            calculateAvgKwh(sensorAggregate),
            settings.emissionFactorValue
          ) / 1000

        emissionsData.push(emissionAsKg)
      } else if (
        sensorAggregate &&
        emissionFactorAggregate &&
        settings.emissionFactorMode === EmissionFactorMode.REALTIME
      ) {
        const emissionAsKg =
          calculateEmission(
            calculateAvgKwh(sensorAggregate),
            emissionFactorAggregate.avg_gco2_per_kwh
          ) / 1000

        emissionsData.push(emissionAsKg)
      } else {
        emissionsData.push(0)
      }
    })

    return emissionsData
  }

  function makeCumulativeEmissionData(): number[] {
    return emissions
      ? makeCumulativeEmissionDataUsingEmissions()
      : makeCumulativeEmissionDateUsingSensorAggregates()
  }

  function makeCumulativeEmissionDataUsingEmissions(): number[] {
    if (!emissions) {
      return []
    }

    let value = initialEmission
    const emissionsByDate = mapEmissionsByDate(emissions)
    return datesAsIsoStrings.map((date) => {
      const emission = emissionsByDate.get(date)
      value += emission ? emission.value : 0
      return value
    })
  }

  function makeCumulativeEmissionDateUsingSensorAggregates(): number[] {
    const emissionsData: number[] = []

    const sensorAggregatesByDate: Map<string, SensorAggregate> =
      transformSensorAggregatesToMap(sensorAggregates)

    const emissionFactorAggregatesByDate: Map<string, EmissionFactorAggregate> =
      transformEmissionFactorAggregatesToMap(emissionFactorAggregates)

    let emissions = initialEmission

    datesAsIsoStrings.forEach((date) => {
      const sensorAggregate = sensorAggregatesByDate.get(date)
      const emissionFactorAggregate = emissionFactorAggregatesByDate.get(date)

      if (
        sensorAggregate &&
        settings.emissionFactorMode === EmissionFactorMode.FIXED &&
        settings.emissionFactorValue
      ) {
        const emissionAsKg =
          calculateEmission(
            calculateAvgKwh(sensorAggregate),
            settings.emissionFactorValue
          ) / 1000

        emissions += emissionAsKg
      } else if (
        sensorAggregate &&
        emissionFactorAggregate &&
        settings.emissionFactorMode === EmissionFactorMode.REALTIME
      ) {
        const emissionAsKg =
          calculateEmission(
            calculateAvgKwh(sensorAggregate),
            emissionFactorAggregate.avg_gco2_per_kwh
          ) / 1000

        emissions += emissionAsKg
      }

      emissionsData.push(emissions)
    })

    return emissionsData
  }

  return (
    <>
      {settings.calculationMode === CalculationMode.CUMULATIVE ? (
        <LineChart data={chartData} options={chartOptions} />
      ) : (
        settings.calculationMode === CalculationMode.INCREMENTAL && (
          <BarChart data={chartData} options={chartOptions} />
        )
      )}
    </>
  )
}
