import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { RuuviTagAccelerationAggregateStatistic } from '../types'

export interface RuuviTagAccelerationAggregateToggleProps {
  value: RuuviTagAccelerationAggregateStatistic[]
  onChange(value: RuuviTagAccelerationAggregateStatistic[]): void
}

export default function RuuviTagAccelerationAggregateToggle({
  value,
  onChange
}: RuuviTagAccelerationAggregateToggleProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * Handle the change event.
   */
  function handleChange(
    event: MouseEvent<HTMLElement>,
    newValue: RuuviTagAccelerationAggregateStatistic[]
  ): void {
    onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value="min">{t('lowest')}</ToggleButton>
      <ToggleButton value="avg">{t('average')}</ToggleButton>
      <ToggleButton value="max">{t('highest')}</ToggleButton>
    </ToggleButtonGroup>
  )
}
