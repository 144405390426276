import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Button, Grid, TextField as MuiTextField } from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { useDispatch } from 'react-redux'
import { NotificationType } from '../../enums/NotificationType'
import { createUserNotification } from '../../utils/createUserNotification'
import { useAuth } from '../../hooks'
import { MFAType } from '../../enums'
import LoadingState from '../LoadingState'
import { sendOtpToEmail } from '../../services/userService'
import { MFAErrors } from '../../enums/MFAErrrors'
import { checkUserOtp } from '../../services/MFAService'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

// eslint-disable-next-line
export default function MFACheckForm() {
  const [t] = useTranslation('common')
  const { completeSignIn } = useAuth()
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(window.location.search)
  const foundType = searchParams.get('type') ?? undefined

  const validationSchema = Yup.object().shape({
    token: Yup.string()
      .required(
        t('isRequired', {
          field: t('6-digitNumber').toLocaleLowerCase()
        })
      )
      .length(6)
  })

  const initialValues = {
    token: ''
  }

  async function handleSendOtpToEmail() {
    try {
      setIsSubmitting(true)

      await sendOtpToEmail()
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('otpSentSuccess')
        )
      )
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: MFAErrors.SEND_OTP,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setIsSubmitting(false)
    }
  }
  async function handleSubmit(
    values: any,
    { resetForm, setSubmitting }: any
  ): Promise<void> {
    try {
      setSubmitting(true)
      const token = await checkUserOtp({ token: values.token })
      await completeSignIn(token)

      // eslint-disable-next-line
    } catch (error: any) {
      //Create message
      const errorMessage = createUserNotification({
        user: null,
        type: MFAErrors.CONFIRM_OTP,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      resetForm()
      setSubmitting(false)
    }
  }

  const { signOut } = useAuth()

  function handleSignOut() {
    signOut()
    navigate('/auth/sign-in')
  }

  return (
    <React.Fragment>
      {isSubmitting ? (
        <LoadingState />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6} my={2}>
                <Grid xs={12} my={2}>
                  <TextField
                    name="token"
                    label={t('sixDigitCode')}
                    value={values.token}
                    error={Boolean(touched.token && errors.token)}
                    fullWidth
                    helperText={touched.token && errors.token}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} my={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t('confirm')}
                  </Button>
                </Grid>
                {foundType === MFAType.AUTHENTICATOR && (
                  <Grid xs={12} my={2}>
                    <Button
                      fullWidth
                      onClick={() => navigate('/auth/reset-mfa')}
                      variant="contained"
                    >
                      {t('lostAuthenticator')}
                    </Button>
                  </Grid>
                )}
                {foundType === MFAType.EMAIL && (
                  <Grid xs={12} my={2}>
                    <Button
                      fullWidth
                      onClick={() => handleSendOtpToEmail()}
                      variant="contained"
                    >
                      {t('sendCodeToMyEmail')}
                    </Button>
                  </Grid>
                )}

                <Grid xs={12}>
                  <Button
                    onClick={() => handleSignOut()}
                    fullWidth
                    color="primary"
                  >
                    {t('returnToLogin')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}
