import React from 'react'
import styled from 'styled-components/macro'
import { Paper } from '@mui/material'
import ManufacturingHeader from '../../components/manufacturing/ManufacturingPageHeader'
import ManufacturerInviteForm from '../../components/manufacturing/ManufacturerInviteForm'
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(12)};
  }
`
function ManufacturerInvite() {
  return (
    <React.Fragment>
      <ManufacturingHeader />
      <Wrapper>
        <ManufacturerInviteForm />
      </Wrapper>
    </React.Fragment>
  )
}

export default ManufacturerInvite
