import { useTranslation } from 'react-i18next'
import SensorAggregateChart from './SensorAggregateChart'
import Sensor from '../types/Sensor'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import { ChartOptions } from 'chart.js'
import DateRange from '../types/DateRange'
import { ForcedYAxis, ScalingYAxis } from '../types/YAxisOptions'
import { Device } from '../types'

interface DeviceVoltageChartProps {
  device: Device
  dateRange: DateRange
  sensors: Sensor[]
  sensorAggregates: SensorAggregate[]
  sensorAggregateOptions: SensorAggregateOptions
  yAxisOption: ScalingYAxis | ForcedYAxis
}

export default function DeviceVoltageChart({
  device,
  dateRange,
  sensors,
  sensorAggregates,
  sensorAggregateOptions,
  yAxisOption
}: DeviceVoltageChartProps) {
  const [t] = useTranslation('common')

  const chartOptions: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: yAxisOption
    }
  }

  return (
    <SensorAggregateChart
      dateRange={dateRange}
      sensors={sensors}
      sensorAggregates={sensorAggregates}
      options={sensorAggregateOptions}
      chartOptions={chartOptions}
      chartWrapperHeight={'250px'}
    />
  )
}
