import { SensorAggregate, EmissionFactorAggregate } from '../types'
import { transformSensorAggregatesToMap } from './sensorAggregate'
import { calculateEmission } from './emission'

export function transformEmissionFactorAggregatesToMap(
  emissionFactorAggregates: EmissionFactorAggregate[]
): Map<string, EmissionFactorAggregate> {
  const emissionFactorAggregatesByDate: Map<string, EmissionFactorAggregate> =
    new Map()

  emissionFactorAggregates.forEach((emissionFactorAggregate) => {
    emissionFactorAggregatesByDate.set(
      emissionFactorAggregate.time_bucket,
      emissionFactorAggregate
    )
  })

  return emissionFactorAggregatesByDate
}

export function calculateTotalEmission(
  sensorAggregates: SensorAggregate[],
  emissionFactorAggregates: EmissionFactorAggregate[]
): number {
  let emissions = 0

  const sensorAggregatesByDate: Map<string, SensorAggregate> =
    transformSensorAggregatesToMap(sensorAggregates)

  const emissionFactorAggregatesByDate: Map<string, EmissionFactorAggregate> =
    transformEmissionFactorAggregatesToMap(emissionFactorAggregates)

  sensorAggregatesByDate.forEach((sensorAggregate, date) => {
    const emissionFactorAggregate = emissionFactorAggregatesByDate.get(date)
    if (emissionFactorAggregate) {
      emissions += calculateEmission(
        sensorAggregate.avg_kw as number,
        emissionFactorAggregate.avg_gco2_per_kwh
      )
    }
  })

  return emissions
}
