import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { handleRefreshToken } from '../services/authService'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL

const client: AxiosInstance = axios.create({
  baseURL,
  headers: {
    'x-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=utf-8'
  }
})

client.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const accessToken: string | null =
      window.localStorage.getItem('accessToken')

    const temporaryToken: string | null =
      window.localStorage.getItem('temporaryToken')

    if (accessToken || temporaryToken) {
      // @ts-ignore
      config.headers['Authorization'] = `Bearer ${
        accessToken ?? temporaryToken
      }`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

client.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true

      await handleRefreshToken()

      return await client(config)
    }
    return Promise.reject(error)
  }
)

export default function useAxios(): AxiosInstance {
  return client
}
