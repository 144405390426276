import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLifts } from '../hooks'

export default function LiftSelect(props: TextFieldProps) {
  const [t] = useTranslation('common')
  const lifts = useLifts()

  return (
    <TextField
      select
      name="lift"
      date-testid="lift"
      label={t('lift')}
      {...props}
    >
      {lifts.map((lift) => (
        <MenuItem key={lift.id} value={lift.id}>
          {lift.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
