import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button as MuiButton,
  Grid,
  Stack,
  TextField as MuiTextField
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import { RootState } from '../redux/store'
import { createLevel, updateLevel, hideLevel } from '../redux/slices/levels'
import { getProjects } from '../redux/slices/projects'
import useAuth from '../hooks/useAuth'
import LoadingState from './LoadingState'
import { useTranslation } from 'react-i18next'
import { useLevel } from '../hooks'
import ProjectSelect from './ProjectSelect'
import levelService from '../services/levelService'
import { deleteError, setError } from '../redux/slices/errors'
import { LevelErrors } from '../enums/LevelErrors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { Level, LevelInput, PowerSupply } from '../types'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
export interface LevelFormProps {
  value?: LevelInput
  hidden?: Array<keyof LevelInput>
  disabled?: Array<keyof LevelInput>
}

export default function LevelForm({
  value,
  hidden = [],
  disabled = []
}: LevelFormProps) {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The level.
   */
  const level = useLevel()

  /**
   * Indicates if the projects are being loaded.
   */
  const isLoadingProjects = useSelector(
    (state: RootState) => state.projects.loading
  )

  const initialValues: LevelInput =
    level === undefined
      ? {
          name: '',
          number: null,
          project: currentUser?.activeProjectMembership?.projectId ?? null,
          ...value
        }
      : {
          name: level.name,
          number: level.number,
          project: level.project?.id ?? null,
          ...value
        }

  const validationSchema = Yup.object().shape({
    // FIXME: Add unique condition:
    // - Name should be unique among levels of the selected project
    name: Yup.string().required(
      t('isRequired', {
        field: t('Level.name').toLocaleLowerCase()
      })
    ),
    // FIXME: Add unique condition:
    // - Number should be unique among levels of the selected project
    number: Yup.number().required(
      t('isRequired', {
        field: t('Level.number').toLocaleLowerCase()
      })
    ),
    project: Yup.number().required(
      t('isRequired', {
        field: t('Level.project').toLocaleLowerCase()
      })
    )
  })

  function isHidden(field: string): boolean {
    // @ts-ignore
    return hidden.includes(field)
  }

  function isDisabled(field: string): boolean {
    // @ts-ignore
    return disabled.includes(field)
  }

  useEffect(() => {
    dispatch(getProjects())
  }, [])

  async function handleSubmit(
    values: LevelInput,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) {
    try {
      setSubmitting(true)

      if (level === undefined) {
        const createdLevel: Level = await levelService.createLevel(values)

        dispatch(createLevel(createdLevel))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('levelCreateSuccess')
          )
        )
      } else {
        const updatedLevel: Level = await levelService.updateLevel(
          level.id,
          values
        )

        dispatch(updateLevel(updatedLevel))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('levelUpdateSuccess')
          )
        )
      }
      resetForm()
      setStatus({ sent: true })
      dispatch(hideLevel())
    } catch (error: any) {
      setStatus({ sent: false })
      // FIXME: Set validation errors.
      setErrors({ submit: 'Error' })
      dispatch(
        setError({
          type: level ? LevelErrors.EDIT : LevelErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: level ? LevelErrors.EDIT : LevelErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(level ? LevelErrors.EDIT : LevelErrors.CREATE))
    } finally {
      setSubmitting(false)
    }
  }

  function handleCancel() {
    dispatch(hideLevel())
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <>
          {isSubmitting ? (
            <LoadingState />
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                {!isHidden('project') && (
                  <Grid item xs={12}>
                    <ProjectSelect
                      data-testid="project"
                      name="project"
                      label={t('Level.project')}
                      value={values.project}
                      error={Boolean(touched.project && errors.project)}
                      helperText={touched.project && errors.project}
                      disabled={
                        isDisabled('project') ||
                        isLoadingProjects ||
                        isSubmitting
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  {!isHidden('number') && (
                    <TextField
                      type="number"
                      name="number"
                      label={t('Level.number')}
                      value={values.number}
                      error={Boolean(touched.number && errors.number)}
                      helperText={touched.number && errors.number}
                      disabled={isDisabled('number') || isSubmitting}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  {!isHidden('number') && (
                    <TextField
                      name="name"
                      label={t('Level.name')}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      disabled={isDisabled('name') || isSubmitting}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || isLoadingProjects}
                    >
                      {t('save')}
                    </Button>

                    <Button
                      type="button"
                      disabled={isSubmitting}
                      onClick={() => handleCancel()}
                    >
                      {t('cancel')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  )
}
