import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import IncorrectTable, { RowData } from './TroubleshootTable'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import TroubleshootProps from './TroubleshootProps'
import { TroubleshootEvent } from '../../pages/pages/DeviceTroubleshoot'
import { useTranslation } from 'react-i18next'
import TroubleshootDropdown from './TroubleshootDropdown'
import { getStatus, invokeAction } from '../../services/deviceService'
import { delay } from '../../utils/delay'
import { StatusOutput } from '../../types'

export interface IncorrectValues {
  freeText: string
  incorrectValues: string[]
  currentsAndVoltages: CurrentsAndVoltages[]
}

interface CurrentsAndVoltages {
  name: string
  type: string
  value: string
}

interface Props extends TroubleshootProps {
  setIncorrect: (data: IncorrectValues) => void
}

const TEN_SECONDS_IN_MS = 10 * 1000

const whatType = ['All Phases', 'L1', 'L2', 'L3', "Don't know"]
const whatValue = ['All Values', 'Min', 'Max', 'Avg', "Don't know"]

export default function SelectIncorrect({
  stateUpdate,
  setIncorrect,
  device
}: Props) {
  const [t] = useTranslation('common')
  /**
   * State used to stop running requests if the component is unmounted.
   */
  const isCancelled = useRef(false)
  /**
   * State that stores how many retries it took to fetch device status.
   */
  const [retries, setRetries] = useState(0)
  /**
   * State used to store gotten status from device.
   */
  const [status, setStatus] = useState<StatusOutput>()
  /**
   * State for free text field text.
   */
  const [freeText, setFreeText] = useState('')
  /**
   * State for incorrect Current and Voltage selection.
   */
  const [rows, setRows] = useState<CurrentsAndVoltages[]>([])

  /**
   * State for table values.
   */
  const [tableValues, setTableValues] = useState<RowData[]>([
    {
      id: 'clampRatio',
      name: t('troubleshoot.clampRatio'),
      checked: false
    },
    {
      id: 'inputCables',
      name: t('troubleshoot.inputCables'),
      checked: false
    },
    {
      id: 'relay',
      name: t('troubleshoot.relay'),
      checked: false
    },
    {
      id: 'ratedCurrent',
      name: t('troubleshoot.ratedCurrent'),
      checked: false
    },
    {
      id: 'energyCounter',
      name: t('troubleshoot.energyCounter'),
      checked: false
    }
  ])

  /**
   * Function that runs on textField change.
   * @param event
   */
  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value
    setFreeText(newText)
  }

  /**
   * Function that runs on checkBox Change.
   * @param index index of the changed Checkbox.
   * @param state
   */
  const onCheckBoxChange = (index: number, state: boolean) => {
    const newTableValues = [...tableValues]
    newTableValues[index].checked = state
    setTableValues(newTableValues)
  }

  /**
   * Function that adds new row to to the currents and voltages table.
   */
  const addRow = () => {
    const emptyRow = { name: '-', type: "Don't know", value: "Don't know" }
    const newRows = [...rows, emptyRow]
    setRows(newRows)
  }

  /**
   * Function that removes a given row from the currents and voltages table.
   * @param index the index of the row thats to be deleted.
   */
  const removeRow = (index: number) => {
    const newRows = [...rows]
    newRows.splice(index, 1)
    setRows(newRows)
  }

  /**
   * Function run on currents and voltages table change.
   * @param index index of the table row.
   * @param key key of the value to be changed.
   * @param newValue new value to be inserted.
   */
  const onRowChange = (
    index: number,
    key: 'name' | 'value' | 'type',
    newValue: string
  ) => {
    const newRows: CurrentsAndVoltages[] = [...rows]
    newRows[index][key] = newValue
    setRows(newRows)
  }

  /**
   * Function that handles submit.
   */
  const handleSubmit = () => {
    const data: IncorrectValues = {
      freeText,
      incorrectValues: tableValues
        .filter((row) => row.checked)
        .map((row) => row.name),
      currentsAndVoltages: rows.filter((row) => row.name !== '-')
    }
    setIncorrect(data)
    if (!status) {
      stateUpdate(
        TroubleshootEvent.Send,
        `Selected incorrect values. Device didn't return any status after ${retries} retries.`
      )
      return
    }
    stateUpdate(
      TroubleshootEvent.Send,
      `Selected incorrect values. Gotten status from device: ${status}.`
    )
  }

  /**
   * Send get_status action to the device.
   */
  const sendGetStatus = async () => {
    await invokeAction(device.name, { get_status: 1 })
  }

  /**
   * Polls backend API every 10s until a status message is returned.
   */
  const getDeviceStatus = async () => {
    const maxRetries = 200

    let attempts = 0
    while (attempts < maxRetries && !isCancelled.current) {
      try {
        const status = await getStatus(device.name)
        setStatus(status)
        return status
      } catch (error) {
        await delay(TEN_SECONDS_IN_MS)
        attempts += 1
        setRetries(attempts)
      }
    }
    if (attempts === maxRetries) {
      throw new Error(`Failed to fetch status after ${maxRetries} attempts`)
    }
  }

  /**
   * Updates a table row value searched by id.
   * @param tableValues
   * @param searchedId
   * @param newValue
   */
  const updateValue = (
    tableValues: RowData[],
    searchedId: string,
    newValue?: number
  ) => {
    const found = tableValues.find(({ id }) => id === searchedId)
    if (found) {
      found.value = newValue
    }
  }

  const initializeTable = async () => {
    const newValues = [...tableValues]
    await sendGetStatus()
    // Clamp ratio, input cables and relay from Device status
    const status = await getDeviceStatus()
    if (status) {
      updateValue(newValues, 'clampRatio', status.clamp_ratio)
      updateValue(newValues, 'inputCables', status.input_cables)
      updateValue(newValues, 'relay', status.relay)
    }

    // Rated current and energy counter from latest sensor data.
    const latestSensorData = device.sensorData?.latest
    updateValue(newValues, 'ratedCurrent', latestSensorData?.rated_current)
    updateValue(newValues, 'energy_counter', latestSensorData?.energy_counter)

    setTableValues(newValues)
  }

  useEffect(() => {
    initializeTable()
    addRow()
  }, [])
  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      <Form>
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
          {t('troubleshoot.selectIncorrect')}
        </Typography>
        <IncorrectTable
          rows={tableValues}
          onCheckBoxChange={onCheckBoxChange}
        />

        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table aria-label="custom table">
            <TableHead>
              <TableRow>
                <TableCell>Currents / Voltages</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Delete row</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <TroubleshootDropdown
                        items={['Current', 'Voltage', '-']}
                        label={'Name'}
                        value={row.name}
                        setItem={(selected) =>
                          onRowChange(index, 'name', selected)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TroubleshootDropdown
                        items={whatType}
                        label={'Type'}
                        value={row.type}
                        setItem={(selected) =>
                          onRowChange(index, 'type', selected)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TroubleshootDropdown
                        items={whatValue}
                        label={'Value'}
                        value={row.value}
                        setItem={(selected) =>
                          onRowChange(index, 'value', selected)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => removeRow(index)}>X</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Button onClick={() => addRow()} variant="contained" sx={{ mt: 2 }}>
            Add new row
          </Button>
        </TableContainer>

        <Typography
          sx={{ fontSize: 14, mt: 4 }}
          color="text.primary"
          gutterBottom
        >
          {t('troubleshoot.giveDescription')}
        </Typography>

        <TextField
          id="freeTextField"
          multiline
          fullWidth
          onChange={onTextFieldChange}
        />

        <Typography align="left" sx={{ mt: 4 }}>
          <Button type="submit" variant="contained">
            {t('troubleshoot.send')}
          </Button>
        </Typography>
      </Form>
    </Formik>
  )
}
