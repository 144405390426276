import { UserRole } from '../enums/UserRole'
import { AuthUser } from '../types/auth'
import {
  createSimpleErrorMessage,
  createSuperAdminErrorMessage
} from './createError'

export function createUserNotification({
  user,
  type,
  error
}: {
  user: AuthUser
  type: string
  error: any
}) {
  const message =
    user?.role === UserRole.SUPERADMIN
      ? createSuperAdminErrorMessage({
          type: type,
          error: error
        })
      : createSimpleErrorMessage({
          type: type,
          error: error
        })

  return message
}
