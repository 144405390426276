import { Device } from '../../types'
import { TroubleshootEvent } from '../../pages/pages/DeviceTroubleshoot'
import { Button, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface DeviceReadingProps {
  device: Device
  stateUpdate: (event: TroubleshootEvent, message: string) => void
}

export default function DeviceReadings({
  device,
  stateUpdate
}: DeviceReadingProps) {
  const deviceHasSensorReadings = !!device.sensorData?.latest
  const [t] = useTranslation('common')
  useEffect(() => {
    if (!deviceHasSensorReadings) {
      stateUpdate(
        TroubleshootEvent.NoSensorReadings,
        'No sensor readings found from device.'
      )
    }
  })

  return (
    <>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {t('troubleshoot.deviceReadings')}
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography align="right" sx={{ mr: 2, mt: 2 }}>
          <Button
            onClick={() => {
              stateUpdate(
                TroubleshootEvent.NoIncorrectReadings,
                'User selected: Device has no incorrect readings.'
              )
            }}
            variant="contained"
          >
            {t('no')}
          </Button>
        </Typography>
        <Typography align="right" sx={{ mr: 2, mt: 2 }}>
          <Button
            onClick={() => {
              stateUpdate(
                TroubleshootEvent.SomeIncorrectReadings,
                'User selected: Device has incorrect readings.'
              )
            }}
            variant="contained"
          >
            {t('yes')}
          </Button>
        </Typography>
      </Grid>
    </>
  )
}
