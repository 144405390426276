import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'

import {
  Grid,
  Paper,
  Alert,
  Button,
  Divider as MuiDivider,
  AlertTitle
} from '@mui/material'

import { ReactComponent as WhiteLogo } from '../../vendor/spine-white-logo-text.svg'
import NavbarLanguagesDropdown from '../../components/navbar/NavbarLanguagesDropdown'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BlueLogo } from '../../vendor/spine-blue-logo-text.svg'
import useTheme from '../../hooks/useTheme'
import { THEMES } from '../../constants'
import { Link, useParams } from 'react-router-dom'
import { spacing } from '@mui/system'
import { isValidToken } from '../../utils/jwt'
import LoadingState from '../../components/LoadingState'
import {
  resetUserMfa,
  verifyUserEmailService
} from '../../services/authService'
import { VerificationType } from '../../enums/VerificationType'

const WhiteBrand = styled(WhiteLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const BlueBrand = styled(BlueLogo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  transform: scale(3.5);
`

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 500px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

const Divider = styled(MuiDivider)(spacing)

export default function EmailVerificationLandingIndex() {
  const [t] = useTranslation('common')
  const { theme } = useTheme()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true)
  const [submitStatus, setSubmitStatus] = useState<string>('success')
  const { token } = useParams()

  const tokenDecodablePart = token ? token.split('.')[1] : ''
  const decoded = Buffer.from(tokenDecodablePart, 'base64').toString()
  const json = JSON.parse(decoded)
  const type = json.type

  useEffect(() => {
    if (token && isValidToken(token)) {
      handleVerification(type as string, token)
    } else {
      setIsSubmitting(false)
      setSubmitStatus('failed')
    }
  }, [token])

  async function handleVerification(type: string, token: string) {
    try {
      if (type === VerificationType.MFA) {
        await resetUserMfa(token)
      } else if (type === VerificationType.EMAIL) {
        await verifyUserEmailService(token)
      }
      setSubmitStatus('success')
    } catch (error) {
      setSubmitStatus('failed')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      {theme === THEMES.DARK ? <WhiteBrand /> : <BlueBrand />}
      <Wrapper>
        <Helmet title="Verification" />
        <Grid container spacing={6}>
          <NavbarLanguagesDropdown />
        </Grid>
        <Divider my={6} />
        {isSubmitting ? (
          <LoadingState />
        ) : (
          <>
            {type === 'email-verification' && submitStatus === 'success' && (
              <Alert severity="success">
                <AlertTitle>{t('emailVerificationSuccessTitle')}</AlertTitle>
                {t('emailVerificationSuccess')}
              </Alert>
            )}

            {type === 'email-verification' && submitStatus === 'failed' && (
              <Alert severity="error">
                <AlertTitle>{t('emailVerificationErrorTitle')}</AlertTitle>
                {t('emailVerificationError')}
              </Alert>
            )}

            {type === 'mfa-reset' && submitStatus === 'success' && (
              /**TODO: Translations */
              <Alert severity="success">
                <AlertTitle>{t('mfaResetSuccessTitle')}</AlertTitle>
                {t('mfaResetSuccess')}
              </Alert>
            )}

            {type === 'mfa-reset' && submitStatus === 'failed' && (
              /**TODO: Translations */
              <Alert severity="error">
                <AlertTitle>{t('mfaResetErrorTitle')}</AlertTitle>
                {t('mfaResetError')}
              </Alert>
            )}

            <Divider my={6} />
            <Button
              component={Link}
              to="/auth/sign-in"
              fullWidth
              color="primary"
              variant="contained"
            >
              {t('login')}
            </Button>
          </>
        )}
      </Wrapper>
    </React.Fragment>
  )
}
