import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import {
  Grid,
  Card as MuiCard,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  TableHead,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider as MuiDivider,
  CardHeader,
  TextField,
  Typography,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import { spacing } from '@mui/system'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { deleteOrder, getOrders } from '../../redux/slices/orders'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Delete, Edit } from '@mui/icons-material'
import { OrderStatus } from '../../enums/OrderStatus'
import { Order } from '../../types'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import orderService from '../../services/orderService'
import { createUserNotification } from '../../utils/createUserNotification'
import { useAuth } from '../../hooks'
import { OrderErrors } from '../../enums/OrderErrors'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

export interface OrderTableProps {
  orders?: Order[]
}

function OrderTable({ orders }: OrderTableProps) {
  const [t] = useTranslation('common')
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { currentUser } = useAuth()

  async function handleDelete(id: number) {
    try {
      await orderService.deleteOrder(id)
      dispatch(deleteOrder(id))
    } catch (error) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: OrderErrors.DELETE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      handleClose()
    }
  }

  const [open, setOpen] = useState(false)
  const [orderId, setOrderId] = useState(0)

  const handleClickOpen = (id: any) => {
    setOrderId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOrderId(0)
  }

  const TranslateOrderStatus = (status: string) => {
    switch (status) {
      case OrderStatus.PENDING:
        return t('Manufacturing.pending')
      case OrderStatus.ON_HOLD:
        return t('Manufacturing.onHold')
      case OrderStatus.IN_PROGRESS:
        return t('Manufacturing.inProgress')
    }
  }

  const [filter, setFilter] = useState<string>('')
  const [hasCompany, setHasCompany] = useState<boolean | string>('')
  const companyFilteredOrders =
    hasCompany !== ''
      ? hasCompany === false
        ? orders?.filter((item) => item.company === null)
        : orders?.filter((item) => item.company !== null)
      : orders

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    filter: string | boolean
  ) => {
    setHasCompany(filter)
  }
  const filteredOrders = companyFilteredOrders?.filter((order: Order) => {
    const regex = new RegExp(filter, 'i')
    return (
      filter.length === 0 ||
      Object.values(order).some((value) => {
        return regex.test(value as string)
      })
    )
  })

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl>
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate('/manufacturing')}
          >
            <ArrowBackIcon />
            {t('Manufacturing.back')}
          </Button>
          <Divider my={6} />
          <Card mb={6}>
            <CardHeader title={t('Manufacturing.orders')} />

            <Paper>
              {orders && (
                <>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={t('search')}
                        fullWidth
                        name="search"
                        size="small"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setFilter(event.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography align="right">
                        <ToggleButtonGroup
                          color="primary"
                          value={hasCompany}
                          exclusive
                          onChange={handleChange}
                          aria-label="Platform"
                        >
                          <ToggleButton value={false} size="small">
                            {t('storage')}
                          </ToggleButton>
                          <ToggleButton value={true} size="small">
                            {t('customers')}
                          </ToggleButton>
                          <ToggleButton value={''} size="small">
                            {t('all')}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Typography>
                    </Grid>
                  </Grid>
                  <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredOrders?.map((row: Order) => (
                          <TableRow key={row.id}>
                            <TableCell>
                              {' '}
                              {row.company === null ? (
                                <WarehouseIcon />
                              ) : (
                                ''
                              )}{' '}
                            </TableCell>
                            <TableCell>
                              {row.company?.name ?? t('Manufacturing.storage')},{' '}
                              {row.name}, {TranslateOrderStatus(row.status)},
                              DD: {row.dueDate}
                            </TableCell>
                            <TableCell>
                              <Edit
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    `/manufacturing/orders/${row.id}/edit`
                                  )
                                }
                              >
                                {t('Manufacturing.edit')}
                              </Edit>
                            </TableCell>

                            {row.status === OrderStatus.ON_HOLD ? (
                              <TableCell align="right">
                                <Button>
                                  <Delete
                                    color="error"
                                    onClick={() => handleClickOpen(row.id)}
                                  >
                                    {t('Manufacturing.delete')}
                                  </Delete>
                                </Button>
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {t('Manufacturing.confirmOrder')}
                                  </DialogTitle>

                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      {t('Manufacturing.warning')}
                                    </DialogContentText>
                                  </DialogContent>

                                  <DialogActions>
                                    <Button
                                      onClick={() => handleDelete(orderId)}
                                    >
                                      {t('Manufacturing.yes')}
                                    </Button>
                                    <Button onClick={handleClose} autoFocus>
                                      {t('Manufacturing.no')}
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                <Button
                                  size="small"
                                  onClick={() =>
                                    navigate(`/manufacturing/orders/${row.id}`)
                                  }
                                >
                                  <ArrowForwardIosIcon />
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableWrapper>
                </>
              )}
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

function OrderList() {
  const { orders, loading } = useSelector((state: RootState) => state.orders)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getOrders({
        status: [
          OrderStatus.PENDING,
          OrderStatus.IN_PROGRESS,
          OrderStatus.ON_HOLD
        ]
      })
    )
  }, [])

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '60vh'
              }}
            >
              <CircularProgress size="5vh" />
            </Box>
          ) : (
            <OrderTable orders={orders} />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default OrderList
