import { AxiosResponse } from 'axios'
import useAxios from '../hooks/useAxios'
import { Filter } from '../redux/slices/uploads'
import { BlueprintInput } from '../types/BlueprintInput'
import { BlueprintCoordinatesInput } from '../types'

const axios = useAxios()

//POST
export async function postUpload(upload: any): Promise<any> {
  const response: AxiosResponse = await axios.post('/uploads', upload)
  return response.data
}

//UPDATE COORDINATES
export async function updateCoordinates(
  id: string,
  input: BlueprintCoordinatesInput
): Promise<any> {
  const response: AxiosResponse = await axios.put(`/uploads/${id}`, input)
  return response.data
}

//GET /:id
export async function getUpload(id: string): Promise<any> {
  const response: AxiosResponse = await axios.get(`/uploads/${id}`)
  return response.data
}

//GET by type and name
export async function getUploads(input: Filter): Promise<any> {
  const response: AxiosResponse = await axios.get(`/uploads`, {
    params: {
      type: input.type,
      name: input.name
    }
  })
  return response.data
}

//DELETE
export async function deleteUpload(id: string): Promise<void> {
  await axios.delete(`/uploads/${id}`)
}

// GET blueprint
export async function getBlueprintService(input: BlueprintInput): Promise<any> {
  const response: AxiosResponse = await axios.get(`/uploads/level-blueprint`, {
    params: {
      project: input.project,
      level: input.level
    }
  })
  return response.data
}

export default {
  postUpload,
  getUpload,
  getUploads,
  deleteUpload,
  getBlueprintService
}
