import jwtDecode from 'jwt-decode'
import { verify, sign } from 'jsonwebtoken'
import axios from 'axios'

// eslint-disable-next-line
const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const isTemporaryToken = (accessToken: string) => {
  const decoded = jwtDecode<{ temporary: boolean }>(accessToken)
  if (decoded.temporary && decoded.temporary === true) {
    return true
  }

  return false
}

const getUserIdFromToken = (accessToken: string) => {
  if (!accessToken) {
    return undefined
  }

  const decoded = jwtDecode<{ userId: number }>(accessToken)
  return decoded.userId
}

// eslint-disable-next-line

const setSession = (
  accessToken: string | null,
  refreshToken: string | null
) => {
  if (accessToken && refreshToken) {
    //Remove temporary token and set real accessToken and refreshToken
    localStorage.removeItem('temporaryToken')
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('temporaryToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('persist:root')
    localStorage.removeItem('language')
    delete axios.defaults.headers.common.Authorization
  }
}

const setTemporaryToken = (temporaryToken: string | null) => {
  if (temporaryToken) {
    localStorage.setItem('temporaryToken', temporaryToken)
    axios.defaults.headers.common.Authorization = `Bearer ${temporaryToken}`
  } else {
    localStorage.removeItem('temporaryToken')
    localStorage.removeItem('persist:root')
    localStorage.removeItem('language')
    delete axios.defaults.headers.common.Authorization
  }
}

export {
  verify,
  sign,
  isValidToken,
  getUserIdFromToken,
  setSession,
  setTemporaryToken,
  isTemporaryToken
}
