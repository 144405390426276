import { AxiosResponse } from 'axios'
import Device from '../types/Device'
import {
  Company,
  CompanyInput,
  CompanyQuery,
  CompanyVatResponse,
  EmailFromCompany,
  ActionRequest
} from '../types'
import { useApi } from '../hooks'

const api = useApi('/v2')

export async function getCompanies(query?: CompanyQuery): Promise<Company[]> {
  const response = await api.get(`/companies`, {
    params: {
      showDeleted: query?.showDeleted ?? false
    }
  })

  return response.data
}

async function getCompany(id: number): Promise<AxiosResponse> {
  const response = await api.get(`/companies/${id}`)

  return response.data
}

async function createCompany(input: CompanyInput): Promise<AxiosResponse> {
  const response = await api.post('/companies', input)

  return response.data
}

async function updateCompany(
  id: number,
  input: CompanyInput
): Promise<AxiosResponse> {
  const response = await api.put(`/companies/${id}`, input)

  return response.data
}

export async function deleteCompany(
  id: number,
  permanently = false
): Promise<void> {
  await api.delete(`/companies/${id}`, {
    params: {
      permanently
    }
  })
}

export async function recoverCompany(id: number): Promise<void> {
  await api.patch(`/companies/recover/${id}`)
}

async function sendEmailWithCompanyRole(
  email: EmailFromCompany
): Promise<AxiosResponse> {
  const response = await api.post(`/companies/email/`, email)
  return response.data
}

export async function getActionRequestsByCompany(
  id: number
): Promise<ActionRequest[]> {
  const response = await api.get(`/companies/${id}/action-requests`)
  return response.data
}

export async function getCompanyDevices(id: number): Promise<Device[]> {
  const response = await api.get(`/companies/${id}/devices`)
  return response.data
}

export async function getCompanyByVat(
  vat: string
): Promise<CompanyVatResponse> {
  const response = await api.get(`/companies/vat/${vat}`)
  return response.data
}

export default {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
  sendEmailWithCompanyRole,
  getActionRequestsByCompany,
  getCompanyDevices,
  getCompanyByVat
}
