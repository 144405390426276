import {
  Typography,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableBody,
  TableCell
} from '@mui/material'
import TroubleshootProps from './TroubleshootProps'
import { useEffect, useRef, useState } from 'react'
import { TroubleshootEvent } from '../../pages/pages/DeviceTroubleshoot'
import { useTranslation } from 'react-i18next'
import { Sensor } from '../../types'
import { getLastSensor } from '../../services/deviceService'
import { delay } from '../../utils/delay'

const TEN_SECONDS_IN_MS = 10 * 1000

interface Props extends TroubleshootProps {
  endTroubleshoot: () => void
}

export default function DidWeSucceed({
  stateUpdate,
  endTroubleshoot,
  device
}: Props) {
  /**
   * State which describes if the ending text is shown.
   */
  const [isText, setIsText] = useState(false)
  const [sensorMessage, setSensorMessage] = useState<Sensor>()
  const [retries, setRetries] = useState(-1)
  const isCancelled = useRef(false)
  const [t] = useTranslation('common')

  /**
   * Get the latest sensor message.
   * @returns Latest sensor message.
   */
  const getLatestSensorMessage = async () => {
    return await getLastSensor(device.name)
  }

  /**
   * Polls backend API every 10s until a sensor message is returned.
   */
  const getDeviceStatus = async () => {
    const maxRetries = 200

    let attempts = 0
    while (attempts < maxRetries && !isCancelled.current) {
      try {
        const sensor = await getLatestSensorMessage()
        setSensorMessage(sensor)
      } catch (error) {
        await delay(TEN_SECONDS_IN_MS)
        attempts += 1
        setRetries(attempts)
      }
    }
    if (attempts === maxRetries) {
      throw new Error(`Failed to fetch status after ${maxRetries} attempts`)
    }
  }

  useEffect(() => {
    getDeviceStatus().catch((error) => {
      stateUpdate(
        TroubleshootEvent.ItDidNotHelp,
        'Failed to get device status automatically: ${error}.'
      )
    })
    return () => {
      isCancelled.current = true
    }
  }, [])

  return (
    <>
      {!isText ? (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            {t('troubleshoot.didWeSucceed')}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="custom table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('device')}</TableCell>
                  <TableCell>{t('troubleshoot.latestSensorMessage')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{sensorMessage?.device_code}</TableCell>
                  <TableCell>{sensorMessage?.time}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsText(true)
                }}
              >
                {t('troubleshoot.itWorked')}
              </Button>
            </Typography>
            <Typography align="right" sx={{ mr: 2, mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => {
                  if (!sensorMessage) {
                    stateUpdate(
                      TroubleshootEvent.ItDidNotHelp,
                      `User selected: Resending configurations didn't help. Sensor message wasn't received after ${retries} retries.`
                    )
                    return
                  }
                  stateUpdate(
                    TroubleshootEvent.ItDidNotHelp,
                    `User selected: Resending configurations didn't help. Sensor message retries: ${retries}. Latest sensor message sent at: ${sensorMessage?.time}`
                  )
                }}
              >
                {t('troubleshoot.didNotHelp')}
              </Button>
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            {t('troubleshoot.relief')}
          </Typography>
          <Typography align="left" sx={{ mr: 2, mt: 2 }}>
            <Button variant="contained" onClick={endTroubleshoot}>
              {t('troubleshoot.endTroubleshoot')}
            </Button>
          </Typography>
        </>
      )}
    </>
  )
}
