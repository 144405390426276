import React from 'react'
import styled from 'styled-components/macro'
import {
  Button as MuiButton,
  Card as MuiCard,
  Grid as MuiGrid,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  CardHeader,
  MenuItem,
  Typography
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { MembershipRole } from '../enums/MembershipRole'
import { createRequest, updateRequest } from '../services/requestService'
import { RequestAccessErrors } from '../enums/RequestAccessErrors'
import { deleteError, setError } from '../redux/slices/errors'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { RequestInput, UpdateRequestInput } from '../types'
import useRequest from '../hooks/useRequest'
import { hideRequest, updateUserRequest } from '../redux/slices/requests'
import { ActionRequestType } from '../enums/RequestAccessType'
import { RequestAccessProject } from '../types/RequestAccessProject'
import axios from 'axios'
import { RequesterType } from '../enums/RequesterType'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const Grid = styled(MuiGrid)(spacing)
const CardContent = styled(MuiCardContent)(spacing)

const validationSchema = Yup.object().shape({
  requestableId: Yup.string().required('Required')
})

interface RequestAccessFormProps {
  projects?: RequestAccessProject[]
}

export default function RequestAccessForm({
  projects
}: RequestAccessFormProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const request = useRequest()
  const updatingActionRequest = request === undefined
  const initialValues: RequestInput = updatingActionRequest
    ? {
        requesterId: (currentUser?.id as number).toString(),
        requestableType: ActionRequestType.PROJECT,
        requestableId: 0,
        role: MembershipRole.USER,
        message: null,
        requesterType: RequesterType.USER
      }
    : {
        requesterId: (currentUser?.id as number).toString(),
        requestableType: request.requestableType,
        requestableId: request.requestableId,
        role: null,
        message: request.message,
        requesterType: request.requesterType
      }

  const handleSubmit = async (
    values: RequestInput | UpdateRequestInput,
    {
      resetForm,
      setErrors,
      setStatus,
      setSubmitting
    }: {
      resetForm: () => void
      setErrors: (values: { submit: string }) => void
      setStatus: (values: { sent: boolean }) => void
      setSubmitting: (value: boolean) => void
    }
  ) => {
    try {
      if (request === undefined) {
        await createRequest(values as RequestInput)
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('requestCreateSuccess')
          )
        )
        navigate('/')
      } else {
        const udpatedRequest = await updateRequest(
          request.id,
          values as UpdateRequestInput
        )
        dispatch(updateUserRequest(udpatedRequest))
        dispatch(hideRequest())
        //TODO: translations
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('requestUpdateSuccess')
          )
        )
      }
      resetForm()
      setStatus({ sent: true })
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })

      if (axios.isAxiosError(error) && error.response?.status === 409) {
        dispatch<unknown>(
          createErrorOrSuccessNotification(
            NotificationType.WARNING,
            t('requestAlreadyExists')
          )
        )
      } else {
        dispatch(
          setError({
            type: RequestAccessErrors.CREATE,
            error: error
          })
        )
        //Create message
        const errorMessage = createUserNotification({
          user: currentUser,
          type: RequestAccessErrors.CREATE,
          error: error
        })

        //Dispatch error message
        dispatch<unknown>(
          createErrorOrSuccessNotification(
            NotificationType.WARNING,
            t(errorMessage.key) + t(errorMessage.message)
          )
        )
        //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
        dispatch(deleteError(RequestAccessErrors.CREATE))
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              {request ? (
                <CardHeader
                  title={t('modifyMessageForRequest')}
                  align="center"
                  titleTypographyProps={{ variant: 'h6' }}
                />
              ) : (
                <CardHeader
                  title={t('Project.projectsFound')}
                  align="center"
                  titleTypographyProps={{ variant: 'h6' }}
                />
              )}

              <Grid container spacing={6}>
                {projects && (
                  <Grid item xs={12}>
                    <TextField
                      name="requestableId"
                      label={t('Manufacturing.nearbyProjects')}
                      select
                      value={values.requestableId}
                      error={Boolean(
                        touched.requestableId && errors.requestableId
                      )}
                      fullWidth
                      helperText={touched.requestableId && errors.requestableId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    >
                      {projects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label={t('Request.optionalMessage')}
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    fullWidth
                    helperText={touched.message && errors.message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl>
                {request ? (
                  <React.Fragment>
                    <Grid container spacing={6}>
                      <Grid item xs={6}>
                        <Typography align="left">
                          <Button
                            onClick={() => dispatch(hideRequest())}
                            variant="contained"
                            mt={3}
                          >
                            {t('Project.cancel')}
                          </Button>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography align="right">
                          <Button type="submit" variant="contained" mt={3}>
                            {t('save')}
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Button fullWidth variant="contained" type="submit" mt={4}>
                    {t('Request.requestAccess')}
                  </Button>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
