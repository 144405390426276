import { useApi } from '../hooks'
import { AlertQuery } from '../types'

const api = useApi('/v2')

export async function getAlerts(query: AlertQuery = {}) {
  const response = await api.get('/alerts', {
    params: query
  })

  return response.data
}
