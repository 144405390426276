import { ProjectStateType } from '../enums/ProjectStateType'

export default function translateProjectState(state: ProjectStateType) {
  switch (state) {
    case ProjectStateType.ENDED:
      return 'ended'
    case ProjectStateType.ACTIVE:
      return 'active'
    case ProjectStateType.INACTIVE:
      return 'inactive'
    case ProjectStateType.PLANNED:
      return 'planned'
    case ProjectStateType.ARCHIVED:
      return 'archived'
    case ProjectStateType.EXTENDED:
      return 'extended'
  }
}
