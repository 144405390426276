import { Device } from '../types'
import StatusIndicator from './StatusIndicator'

/**
 * The props of the device status indicator.
 */
interface DeviceStatusIndicatorProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The font size.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
}

/**
 * An indicator for the device status.
 */
export default function DeviceStatusIndicator({
  device,
  fontSize
}: DeviceStatusIndicatorProps): JSX.Element {
  return <StatusIndicator status={device.status} fontSize={fontSize} />
}
