/**
 * Format MAC address.
 *
 * @param macAddress The MAC address.
 *
 * @returns The formatted MAC address.
 */
export function formatMacAddress(macAddress: string): string {
  const matches = macAddress.match(/.{1,2}/g)

  return matches !== null ? matches.join(':').toUpperCase() : macAddress
}
