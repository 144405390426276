import React, { SyntheticEvent, useState } from 'react'
import styled from 'styled-components/macro'
import { NavLink, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
  Box,
  Button as MuiButton,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CardContent,
  Skeleton,
  Alert,
  Stack
} from '@mui/material'
import useAuth from '../../hooks/useAuth'
import { spacing } from '@mui/system'
import { RootState } from '../../redux/store'
import { getCompany, setShowCompany } from '../../redux/slices/companies'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { AddModerator, ArrowForward, Edit } from '@mui/icons-material'
import CompanyForm from '../../components/CompanyForm'
import { TabContext, TabList } from '@mui/lab'
import TabPanel from '@mui/lab/TabPanel'
import { UserRole } from '../../enums/UserRole'
import UserTable from '../../components/UserDataGrid'
import UserRoleManagement from '../../components/UserRoleManagement'
import RequestTable from '../../components/RequestTable'
import { getDevicesByCompanyId } from '../../redux/slices/devices'
import { getProjectsByCompanyId } from '../../redux/slices/projects'
import requestService from '../../services/requestService'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { deleteError, setError } from '../../redux/slices/errors'
import { RequestAccessErrors } from '../../enums/RequestAccessErrors'
import { createUserNotification } from '../../utils/createUserNotification'
import { Company, User } from '../../types'
import { ActionRequestType } from '../../enums/RequestAccessType'
import { getActionRequestsByCompany } from '../../redux/slices/requests'
import LoadingState from '../../components/LoadingState'
import ProjectDataGrid from '../../components/ProjectDataGrid'
import AssetScroller from '../../components/AssetScroller'
import { setShow } from '../../redux/slices/users'
import DeviceManager from '../../components/DeviceManager'
import { RequesterType } from '../../enums/RequesterType'

const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const Button = styled(MuiButton)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)

type CompanyTab = 'dashboard' | 'users' | 'devices' | 'monitoring' | 'projects'

export default function CompanyPage() {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The navigate function.
   */
  const navigate = useNavigate()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The route parameters.
   */
  const { id } = useParams()

  /**
   * The current user.
   */
  const { currentUser } = useAuth()

  /**
   * The companies state from redux.
   */
  const { company, show } = useSelector((state: RootState) => state.companies)

  /**
   * The devices state from redux.
   */
  const { devices } = useSelector((state: RootState) => state.devices)

  /**
   * Indicates if devices are being loaded.
   */
  const isLoadingDevices = useSelector(
    (state: RootState) => state.devices.loading
  )

  /**
   * The requests state from redux.
   */
  const { companyRequests } = useSelector((state: RootState) => state.requests)

  /**
   * Indicates if requests are being loaded.
   */
  const isLoadingRequests = useSelector(
    (state: RootState) => state.requests.loading
  )

  const [openRoleChangeInfo, setOpenRoleChangeInfo] = useState(false)

  const [optionalMessage, setOptionalMessage] = useState<string | null>(null)

  /**
   * The current tab.
   */
  const [tab, setTab] = useState<CompanyTab>(getInitialTab())

  /**
   * The users of the company.
   */
  const [companyUsers, setCompanyUsers] = useState<User[]>([])

  /**
   * Indicates if the current user can manage company.
   */
  const userCanManageCompany: boolean =
    currentUser?.role === UserRole.SUPERADMIN ||
    (currentUser?.role === UserRole.ADMIN &&
      currentUser?.company.id === company?.id)

  /**
   * Get the intial tab.
   *
   * @returns The initial tab.
   */
  function getInitialTab(): CompanyTab {
    const hash = location.hash.substring(1).split('?')[0] ?? 'dashboard'

    switch (hash) {
      case 'devices':
        return 'devices'
      case 'users':
        return 'users'
      case 'projects':
        return 'projects'
      case 'monitoring':
        return 'monitoring'
      case 'dashboard':
      default:
        return 'dashboard'
    }
  }

  const handleEdit = (company: Company) => {
    dispatch(getCompany(company.id))
    dispatch(setShowCompany(true))
  }

  /**
   * Handle the tab change.
   *
   * @param event - The change event.
   * @param tab - The new tab.
   */
  const handleTabChange = (event: SyntheticEvent, tab: string): void => {
    setTab(tab as CompanyTab)
    window.history.replaceState({}, '', `#${tab}`)
  }

  const handleClickOpenRoleChangeInfo = () => {
    setOpenRoleChangeInfo(true)
  }

  const handleCloseRoleChangeInfo = () => {
    setOpenRoleChangeInfo(false)
  }

  const handleSubmitRoleChangeRequest = async () => {
    try {
      await requestService.createRequest({
        requesterId: currentUser?.id,
        requestableId: currentUser?.company.id,
        requestableType: ActionRequestType.COMPANY,
        role: UserRole.ADMIN,
        message: optionalMessage,
        requesterType: RequesterType.USER
      })
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('requestCreateSuccess')
        )
      )
    } catch (error: unknown) {
      dispatch(
        setError({
          type: RequestAccessErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: RequestAccessErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
      //TODO: Do not delete errors until submitting form has passed if error is validation error from the backend
      dispatch(deleteError(RequestAccessErrors.CREATE))
    }

    setOpenRoleChangeInfo(false)
  }

  useEffect(() => {
    if (currentUser && id) {
      dispatch(getCompany(parseInt(id as string)))
    }
  }, [currentUser, id])

  useEffect(() => {
    if (currentUser && userCanManageCompany) {
      dispatch(getActionRequestsByCompany(currentUser.company.id))
      dispatch(getDevicesByCompanyId(parseInt(id as string)))
      dispatch(getProjectsByCompanyId(parseInt(id as string)))
    }
  }, [currentUser])

  useEffect(() => {
    setCompanyUsers(company?.users ?? [])
  }, [company])

  return (
    <React.Fragment>
      <Helmet title={t('Company.company')} />

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        gap={6}
      >
        <Stack direction="column" gap={2}>
          {company ? (
            <Typography variant="h3">{company.name}</Typography>
          ) : (
            <Skeleton variant="text" width={200} sx={{ fontSize: '1.5rem' }} />
          )}

          {currentUser?.role === UserRole.SUPERADMIN && (
            <Breadcrumbs>
              <Link component={NavLink} to="/companies">
                {t('companies')}
              </Link>

              <Typography>{company?.name}</Typography>
            </Breadcrumbs>
          )}
        </Stack>

        {(currentUser?.role === UserRole.SUPERADMIN ||
          currentUser?.role === UserRole.USER ||
          currentUser?.role === UserRole.INSTALLER) && (
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddModerator fontSize="inherit" />}
            onClick={handleClickOpenRoleChangeInfo}
          >
            {t('Company.requestRoleChange')}
          </Button>
        )}

        <Dialog
          open={openRoleChangeInfo}
          onClose={handleCloseRoleChangeInfo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {t('Company.roleChangeInfoTitle')}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Company.roleChangeInfo')}
            </DialogContentText>

            <TextField
              fullWidth
              label={t('Request.optionalMessage')}
              multiline
              onChange={(e) => setOptionalMessage(e.target.value)}
              sx={{ mt: 6 }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseRoleChangeInfo}>{t('cancel')}</Button>

            <Button
              variant="contained"
              onClick={handleSubmitRoleChangeRequest}
              autoFocus
            >
              {t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>

      <Divider mt={6} />

      <Box sx={{ width: '100%' }}>
        <TabContext value={tab as string}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={handleTabChange}
            >
              <Tab label={t('Company.companyInfo')} value="dashboard" />

              <Tab
                label={t('devices')}
                value="devices"
                disabled={!userCanManageCompany}
              />

              <Tab
                label={t('monitoring')}
                value="monitoring"
                disabled={!userCanManageCompany}
              />

              <Tab
                label={t('projects')}
                value="projects"
                disabled={!userCanManageCompany}
              />

              <Tab
                label={t('users')}
                value="users"
                disabled={!userCanManageCompany}
              />
            </TabList>
          </Box>

          {company === undefined ? (
            <LoadingState />
          ) : (
            <Box mt={3}>
              <TabPanel value="dashboard">
                {company && (
                  <Card>
                    <CardHeader
                      title={t('Company.companyInfo')}
                      action={
                        <>
                          {userCanManageCompany && (
                            <>
                              <Button
                                variant="outlined"
                                color="primary"
                                endIcon={<Edit fontSize="inherit" />}
                                onClick={() => handleEdit(company)}
                              >
                                {t('editCompany')}
                              </Button>

                              <Dialog
                                open={show}
                                onClose={() => setShow(false)}
                                maxWidth="lg"
                                fullWidth
                              >
                                <DialogTitle>
                                  {currentUser?.role === UserRole.GUEST
                                    ? t('createCompany')
                                    : t('editCompany')}
                                </DialogTitle>
                                <DialogContent>
                                  <CompanyForm />
                                </DialogContent>
                              </Dialog>
                            </>
                          )}
                        </>
                      }
                    />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell> {t('Company.name')}</TableCell>
                            <TableCell align="right">
                              {t('Company.address')}
                            </TableCell>
                            <TableCell align="right">
                              {t('Company.city')}
                            </TableCell>
                            <TableCell align="right">
                              {t('Company.zip')}
                            </TableCell>
                            <TableCell align="right">
                              {t('countryCode')}
                            </TableCell>
                            <TableCell align="right">
                              {t('vatNumber')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={company.id}>
                            <TableCell component="th" scope="row">
                              {company.name}
                            </TableCell>
                            <TableCell align="right">
                              {company.address}
                            </TableCell>
                            <TableCell align="right">{company.city}</TableCell>
                            <TableCell align="right">{company.zip}</TableCell>
                            <TableCell align="right">
                              {company.countryCode ?? '-'}
                            </TableCell>
                            <TableCell align="right">
                              {company.vatNumber ?? '-'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                )}
              </TabPanel>

              <TabPanel value="projects">
                <Card>
                  <CardHeader
                    title={t('projects')}
                    action={
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<ArrowForward fontSize="inherit" />}
                        onClick={() => navigate('/projects#archive')}
                      >
                        {t('projectArchive')}
                        <ArrowForward fontSize="inherit" />
                      </Button>
                    }
                  />
                  <CardContent>
                    {/*<ProjectTable projects={company?.projects as Project[]} />*/}
                    {company?.projects && (
                      <ProjectDataGrid projects={company?.projects} />
                    )}
                  </CardContent>
                </Card>
              </TabPanel>

              <TabPanel value="users">
                <UserRoleManagement company={company} />
                {companyRequests.length > 0 && (
                  <RequestTable
                    requests={companyRequests}
                    loading={isLoadingRequests}
                  />
                )}

                {companyUsers !== undefined && (
                  <Card>
                    <CardHeader title={t('users')} />
                    <CardContent>
                      <UserTable
                        users={companyUsers}
                        hiddenFields={['company', 'deletedAt']}
                        onDelete={(user) => {
                          setCompanyUsers(
                            companyUsers.filter((u) => u.id !== user.id)
                          )
                        }}
                      />
                    </CardContent>
                  </Card>
                )}
              </TabPanel>

              <TabPanel value="devices">
                {!userCanManageCompany ? (
                  <Alert severity="warning">{t('accessDenied')}</Alert>
                ) : (
                  company && (
                    <DeviceManager
                      devices={devices}
                      loading={isLoadingDevices}
                    />
                  )
                )}
              </TabPanel>

              <TabPanel value="monitoring">
                {!userCanManageCompany ? (
                  <Alert severity="warning">{t('accessDenied')}</Alert>
                ) : (
                  company && (
                    <AssetScroller
                      initialQuery={{ companyId: company.id }}
                      initialViewMode="small"
                    />
                  )
                )}
              </TabPanel>
            </Box>
          )}
        </TabContext>
      </Box>
    </React.Fragment>
  )
}
