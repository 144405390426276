import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button as MuiButton,
  Grid,
  Stack,
  TextField as MuiTextField,
  Typography,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
  Box
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components/macro'
import useAuth from '../hooks/useAuth'
import LoadingState from './LoadingState'
import { useTranslation } from 'react-i18next'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import {
  getAuthKeyAndQrCode,
  updateUserOtpSecret
} from '../services/MFAService'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { MFAErrors } from '../enums/MFAErrrors'
import { useNavigate } from 'react-router-dom'
import { MFAType } from '../enums'
import { AuthResponse } from '../types'
import { sendOtpToEmail } from '../services/userService'

const Button = styled(MuiButton)(spacing)
const Card = styled(MuiCard)(spacing)
const TextField = styled(MuiTextField)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Divider = styled(MuiDivider)(spacing)

export default function MFASetupForm() {
  const dispatch = useDispatch()
  const [t] = useTranslation('common')
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [mfaType, setMfaType] = useState(MFAType.AUTHENTICATOR)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const { refreshUser } = useAuth()
  const validationSchema = Yup.object().shape({
    token: Yup.string().required(
      t('isRequired', {
        field: t('sixDigitCode').toLocaleLowerCase()
      })
    )
  })

  const [authResponse, setAuthResponse] = useState<AuthResponse | undefined>(
    undefined
  )

  const [isLoadingQrCode, setIsLoadingKeyAndQrCode] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser) {
      loadAuthKeyAndQrCode()
    }
  }, [currentUser])

  async function loadAuthKeyAndQrCode() {
    try {
      setIsLoadingKeyAndQrCode(true)
      setAuthResponse(await getAuthKeyAndQrCode({ email: currentUser?.email }))
    } finally {
      setIsLoadingKeyAndQrCode(false)
    }
  }

  const initialValues = {
    token: ''
  }

  async function handleSubmit(values: any, { resetForm, setSubmitting }: any) {
    try {
      setSubmitting(true)

      await updateUserOtpSecret({
        key: authResponse?.key,
        token: values.token,
        mfaType: mfaType
      })
      await refreshUser()
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('mfaEnableSuccess')
        )
      )
      navigate(`/users/${currentUser?.id}`)
    } catch (error: any) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: MFAErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      resetForm()
      setSubmitting(false)
    }
  }

  async function handleSendOtpToEmail() {
    try {
      setEmailError(false)
      if (currentUser && authResponse) {
        await sendOtpToEmail({ key: authResponse.key })
        setEmailSent(true)
      }
    } catch (error) {
      setEmailError(true)
    } finally {
      setTimeout(() => setEmailSent(false), 5000)
    }
  }

  return (
    <React.Fragment>
      <Card mb={6}>
        <CardContent>
          <ToggleButtonGroup
            color="primary"
            size="small"
            value={mfaType}
            aria-label={t('SensorAggregate.statistics')}
          >
            <ToggleButton
              value={MFAType.EMAIL}
              onClick={() => setMfaType(MFAType.EMAIL)}
            >
              {t('email')}
            </ToggleButton>
            <ToggleButton
              value={MFAType.AUTHENTICATOR}
              onClick={() => setMfaType(MFAType.AUTHENTICATOR)}
            >
              {t('authenticator')}
            </ToggleButton>
          </ToggleButtonGroup>
          <Divider my={6} />
          {mfaType === MFAType.AUTHENTICATOR && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                {isLoadingQrCode ? (
                  <LoadingState />
                ) : (
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Typography align="center" variant="h6">
                        {t('scanQrWithYourDevice')}
                      </Typography>
                    </Grid>

                    <Divider my={6} />
                    <Grid item xs={12}>
                      <Typography align="center">
                        <QRCodeSVG
                          value={authResponse ? authResponse.uri : ''}
                          size={300}
                          bgColor={'#ffffff'}
                          fgColor={'#000000'}
                          level={'L'}
                          includeMargin={true}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align="center" variant="h6">
                        {t('orEnterCode')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ backgroundColor: 'gray' }}>
                        <Typography variant="h3" align="center" color={'white'}>
                          {authResponse?.key}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {mfaType === MFAType.EMAIL && (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography align="center" variant="h6">
                  {t('sendEmailOtp')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {emailSent && (
                  <Typography align="center" variant="h6">
                    <Alert severity="success">{t('otpSentSuccess')}</Alert>
                  </Typography>
                )}

                {emailError && (
                  <Typography align="center" variant="h6">
                    <Alert severity="error">{t('otpSentError')}</Alert>
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography align="center">
                  <Button
                    onClick={() => handleSendOtpToEmail()}
                    variant="contained"
                  >
                    {t('sendCodeToMyEmail')}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          )}
          <Divider my={6} />
          <Typography align="center" variant="h6">
            {t('verifyCode')}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <React.Fragment>
                {isSubmitting ? (
                  <LoadingState />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} mt={4}>
                        <TextField
                          name="token"
                          label={t('sixDigitCode')}
                          value={values.token}
                          error={Boolean(touched.token && errors.token)}
                          helperText={touched.token && errors.token}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          fullWidth
                          variant="outlined"
                        ></TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack direction="row" spacing={3}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || isLoadingQrCode}
                          >
                            {t('apply')}
                          </Button>

                          <Button
                            type="button"
                            disabled={isSubmitting}
                            onClick={() =>
                              navigate(`/users/${currentUser?.id}`)
                            }
                          >
                            {t('cancel')}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </React.Fragment>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}
