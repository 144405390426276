import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button as MuiButton,
  Grid,
  TextField as MuiTextField,
  CardContent as MuiCardContent,
  Card as MuiCard,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Typography,
  CardHeader,
  Autocomplete,
  FormGroup,
  Stack
} from '@mui/material'
import { Box, spacing } from '@mui/system'
import styled from 'styled-components/macro'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'

import {
  createCompany,
  setCompany,
  setShowCompany,
  updateCompany
} from '../redux/slices/companies'
import useCompany from '../hooks/useCompany'
import useAuth from '../hooks/useAuth'
import { UserRole } from '../enums/UserRole'
import { createErrorOrSuccessNotification } from '../redux/slices/notifications'
import { deleteError, setError } from '../redux/slices/errors'
import companyService, { getCompanyByVat } from '../services/companyService'
import { CompanyErrors } from '../enums/CompanyErrors'
import { NotificationType } from '../enums/NotificationType'
import { createUserNotification } from '../utils/createUserNotification'
import { Company, CompanyInput, CompanyVatResponse } from '../types'
import { countryList } from '../lists/countryList'
import LoadingState from './LoadingState'
import { VatType } from '../enums/VatType'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Card = styled(MuiCard)(spacing)

interface CompanyFormProps {
  /**
   * The handler for the cancel event.
   */
  onCancel?: () => void
}

export default function CompanyForm({ onCancel }: CompanyFormProps) {
  /**
   * The dispatch function.
   */
  const dispatch = useDispatch()

  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The auth context.
   */
  const { currentUser, refreshUser, signOut } = useAuth()

  /**
   * The company from state.
   */
  const company: Company | undefined = useCompany()

  const domainFromUser = currentUser?.email.split('@')
  const [openDomainInfo, setOpenDomainInfo] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [domain, setDomain] = useState<string | null>(domainFromUser[1])
  const [checked, setChecked] = useState(false)
  const [hasEnteredVat, setHasEnteredVat] = useState<boolean>(
    company ? true : false
  )
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false)
  const [companyByVat, setCompanyByVat] = useState<
    CompanyVatResponse | undefined
  >(undefined)
  const [countryCode, setCountryCode] = useState<string>(
    company?.countryCode ?? ''
  )

  const handleClickOpenDomainInfo = () => {
    setOpenDomainInfo(true)
  }

  const handleCloseDomainInfo = () => {
    setOpenDomainInfo(false)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      setDomain(domainFromUser[1])
      setChecked(false)
    } else if (event.target.checked === true) {
      setDomain(null)
      setChecked(true)
    }
  }

  const handleClickOpenConfirmation = () => {
    setOpenConfirmation(true)
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }

  const handleConfirmation = (confirmation: boolean) => {
    if (confirmation === true) {
      setConfirmed(true)
      handleCloseConfirmation()
    } else if (confirmation === false) {
      setConfirmed(false)
      handleCloseConfirmation()
    }
  }

  const initialValues: CompanyInput =
    company === undefined
      ? {
          name: '',
          address: '',
          zip: '',
          city: '',
          domain: null,
          countryCode: null,
          vatNumber: '',
          vatType: VatType.EU
        }
      : {
          name: company.name,
          address: company.address,
          zip: company.zip,
          city: company.city,
          domain: company.domain,
          countryCode: company.countryCode,
          vatNumber: company.vatNumber ?? '',
          vatType: company.vatType ?? VatType.EU
        }

  const [isEuCountry, setIsEuCountry] = useState<boolean>(true)

  const validationSchema = isEuCountry
    ? Yup.object().shape({
        name: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        zip: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        countryCode: Yup.string().required('Required'),
        vatNumber: Yup.string()
          .required('Required')
          .matches(/^[A-Z]+[0-9]+$/, t('vatValidationMessage'))
      })
    : Yup.object().shape({
        name: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        zip: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        countryCode: Yup.string().required('Required'),
        vatNumber: Yup.string().required('Required')
      })

  useEffect(() => {
    return function cleanup() {
      dispatch(setShowCompany(false))
    }
  }, [dispatch])

  async function handleSubmit(
    values: CompanyInput,
    { resetForm, setSubmitting }: any
  ): Promise<void> {
    try {
      setSubmitting(true)
      if (company === undefined) {
        const createdCompany = await companyService.createCompany(values)

        dispatch(createCompany(createdCompany))
        currentUser?.role === UserRole.GUEST
          ? dispatch(
              createErrorOrSuccessNotification(
                NotificationType.SUCCESS,
                t('companyCreateForGuestSuccess')
              )
            )
          : dispatch(
              createErrorOrSuccessNotification(
                NotificationType.SUCCESS,
                t('companyCreateSuccess')
              )
            )
      } else {
        const updatedCompany = await companyService.updateCompany(
          company.id,
          values
        )

        dispatch(updateCompany(updatedCompany))
        dispatch(setCompany(updatedCompany))
        dispatch(
          createErrorOrSuccessNotification(
            NotificationType.SUCCESS,
            t('companyUpdateSuccess')
          )
        )
      }
      resetForm()
      dispatch(setShowCompany(false))

      //We have to force signout for now since scopes doesn't update until next login
      if (currentUser?.role === UserRole.GUEST) {
        await signOut()
      }
    } catch (error: unknown) {
      dispatch(
        setError({
          type: company
            ? CompanyErrors.EDIT
            : currentUser?.role === UserRole.GUEST
            ? CompanyErrors.CREATECOMPANYFORGUEST
            : CompanyErrors.CREATE,
          error: error
        })
      )

      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: company
          ? CompanyErrors.EDIT
          : currentUser?.role === UserRole.GUEST
          ? CompanyErrors.CREATECOMPANYFORGUEST
          : CompanyErrors.CREATE,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )

      dispatch(
        deleteError(
          company
            ? CompanyErrors.EDIT
            : currentUser?.role === UserRole.GUEST
            ? CompanyErrors.CREATECOMPANYFORGUEST
            : CompanyErrors.CREATE
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  async function handleFetchCompany(vatNumber: string, vatType: VatType) {
    try {
      setIsLoadingCompany(true)
      if (vatType === VatType.EU) {
        setCompanyByVat(await getCompanyByVat(vatNumber))
        setHasEnteredVat(true)
      } else setHasEnteredVat(vatNumber !== '')
    } catch (error) {
      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t('invalidVatNumber')
        )
      )
    } finally {
      setIsLoadingCompany(false)
    }
  }

  async function handleCancelCompanyCreation() {
    setCompanyByVat(undefined)
    setHasEnteredVat(false)

    if (onCancel) {
      onCancel()
    }
  }

  /**
   * Handle the cancel event.
   */
  function handleCancel(): void {
    dispatch(setShowCompany(false))

    if (onCancel) {
      onCancel()
    }
  }

  function handleIsCountrycodeFieldDisabled() {
    if (
      (companyByVat !== undefined && currentUser?.role !== UserRole.GUEST) ||
      company !== undefined
    ) {
      return true
    } else if (currentUser?.role === UserRole.SUPERADMIN) {
      return false
    } else return false
  }

  const isCountryCodeDisabled = handleIsCountrycodeFieldDisabled()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
        setValues,
        resetForm
      }) => (
        <>
          <Dialog
            open={openDomainInfo}
            onClose={handleCloseDomainInfo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t('FrontPage.domainInfoTitle')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('FrontPage.domainInfo')}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseDomainInfo}
                autoFocus
              >
                {t('FrontPage.understood')}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openConfirmation}
            onClose={handleCloseConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t('FrontPage.confirmDomain')}
              <IconButton
                size="small"
                onClick={() => handleClickOpenDomainInfo()}
              >
                <InfoIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('FrontPage.confirmDomainInfo')}
                {domain !== null ? (
                  <DialogContentText id="alert-dialog-description">
                    {t('Companies.domain')}: {domain}
                  </DialogContentText>
                ) : (
                  <DialogContentText id="alert-dialog-description">
                    {t('Companies.domain')}: {t('FrontPage.noDomain')}
                  </DialogContentText>
                )}
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                color="success"
                variant="contained"
                onClick={() => handleConfirmation(true)}
                autoFocus
              >
                {t('FrontPage.confirm')}
              </Button>

              <Button
                color="error"
                variant="contained"
                onClick={() => handleConfirmation(false)}
                autoFocus
              >
                {t('FrontPage.cancel')}
              </Button>
            </DialogActions>
          </Dialog>

          {isSubmitting || isLoadingCompany ? (
            <LoadingState />
          ) : (
            <React.Fragment>
              {hasEnteredVat === false ? (
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      id="vatNumber"
                      name="vatNumber"
                      label={t('vatNumber')}
                      value={values.vatNumber}
                      error={Boolean(touched.vatNumber && errors.vatNumber)}
                      fullWidth
                      helperText={touched.vatNumber && errors.vatNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Grid item xs={12} md={10}>
                    <FormGroup>
                      <FormControlLabel
                        /* @ts-ignore */
                        label={t('partOfEU')}
                        control={
                          <Checkbox
                            checked={isEuCountry}
                            onChange={(event: ChangeEvent) => {
                              //@ts-ignore
                              setIsEuCountry(event.target.checked),
                                //@ts-ignore
                                event.target.checked
                                  ? setValues({
                                      ...values,
                                      vatType: VatType.EU
                                    })
                                  : setValues({
                                      ...values,
                                      vatType: VatType.OTHER
                                    })
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Typography align="right">
                      <Button
                        fullWidth
                        disabled={!values.vatNumber}
                        variant="contained"
                        onClick={() => {
                          if (values.vatNumber) {
                            handleFetchCompany(values.vatNumber, values.vatType)
                          }
                        }}
                      >
                        {t('submit')}
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="name"
                        name="name"
                        label={t('name')}
                        disabled={
                          companyByVat !== undefined || company !== undefined
                        }
                        value={
                          (values.name = companyByVat?.name ?? values.name)
                        }
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="address"
                        label={t('Companies.address')}
                        value={
                          (values.address =
                            companyByVat?.address ?? values.address)
                        }
                        error={Boolean(touched.address && errors.address)}
                        fullWidth
                        helperText={touched.address && errors.address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        sx={{ my: 2 }}
                        disabled={isCountryCodeDisabled}
                        key={companyByVat?.countryCode ?? values.countryCode}
                        options={countryList.map((option) => option.code)}
                        autoHighlight
                        getOptionLabel={(option) => option}
                        defaultValue={companyByVat?.countryCode ?? countryCode}
                        value={
                          (values.countryCode =
                            companyByVat?.countryCode ?? countryCode)
                        }
                        onChange={(event: any, newValue: any) => {
                          setCountryCode(newValue)
                          // values.countryCode = newValue
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                              alt=""
                            />
                            ({option})
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            error={Boolean(
                              touched.countryCode && errors.countryCode
                            )}
                            {...params}
                            label={t('countryCode')}
                            inputProps={{
                              ...params.inputProps
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="zip"
                        name="zip"
                        label={t('Companies.zip')}
                        value={(values.zip = companyByVat?.zip ?? values.zip)}
                        error={Boolean(touched.zip && errors.zip)}
                        fullWidth
                        helperText={touched.zip && errors.zip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="city"
                        label={t('Companies.city')}
                        value={
                          (values.city = companyByVat?.city ?? values.city)
                        }
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        helperText={touched.city && errors.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    {checked === false && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Stack direction="row" gap={2} alignItems="center">
                            <TextField
                              name="domain"
                              label={t('Companies.domain')}
                              value={(values.domain = domain)}
                              error={Boolean(touched.domain && errors.domain)}
                              fullWidth
                              disabled={
                                currentUser?.role === UserRole.SUPERADMIN
                                  ? false
                                  : true
                              }
                              helperText={touched.domain && errors.domain}
                              onBlur={handleBlur}
                              onChange={(e) => setDomain(e.target.value)}
                              variant="outlined"
                              my={2}
                            />

                            <IconButton
                              size="small"
                              onClick={() => handleClickOpenDomainInfo()}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {(currentUser?.role === UserRole.SUPERADMIN ||
                    (currentUser?.role === UserRole.ADMIN &&
                      company?.vatNumber === null)) && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        name="vatNumber"
                        label={t('vatNumber')}
                        value={values.vatNumber}
                        error={Boolean(touched.vatNumber && errors.vatNumber)}
                        fullWidth
                        helperText={touched.vatNumber && errors.vatNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  )}

                  <FormControlLabel
                    control={<Checkbox onChange={handleCheckboxChange} />}
                    label={t('FrontPage.noBusinessEmail') as string}
                    value={(values.domain = domain)}
                  />

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={6}
                    sx={{ mt: 3 }}
                  >
                    {currentUser?.role === UserRole.GUEST ? (
                      <Button onClick={() => handleCancelCompanyCreation()}>
                        {t('cancel')}
                      </Button>
                    ) : (
                      <Button onClick={handleCancel}>{t('cancel')}</Button>
                    )}

                    {confirmed === false ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickOpenConfirmation()}
                      >
                        {t('FrontPage.next')}
                      </Button>
                    ) : (
                      <Button color="success" type="submit" variant="contained">
                        {t('Companies.save')}
                      </Button>
                    )}
                  </Stack>
                </form>
              )}
            </React.Fragment>
          )}
        </>
      )}
    </Formik>
  )
}
