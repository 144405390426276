import React from 'react'
import styled from 'styled-components/macro'
import { Formik } from 'formik'

import {
  Button as MuiButton,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider as MuiDivider,
  Box
} from '@mui/material'
import { spacing } from '@mui/system'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setStep } from '../../redux/slices/steps'
import { createErrorOrSuccessNotification } from '../../redux/slices/notifications'
import { NotificationType } from '../../enums/NotificationType'
import { PowerSupplyErrors } from '../../enums/PowerSupplyErrors'
import { useAuth } from '../../hooks'
import { createUserNotification } from '../../utils/createUserNotification'
import { updatePowerSupply } from '../../services/powerSupplyService'
import { Device, Product } from '../../types'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)
const Card = styled(MuiCard)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Button = styled(MuiButton)(spacing)
const Divider = styled(MuiDivider)(spacing)

interface UpdateLinkedDeviceProps {
  product: Product
  device: Device
  step: number
}

// eslint-disable-next-line
export default function UpdateLinkedDevice({
  product,
  device,
  step
}: UpdateLinkedDeviceProps) {
  /**
   * The translation function
   */
  const [t] = useTranslation('common')

  /**
   * The dispatch function
   */
  const dispatch = useDispatch()

  /**
   * The current user
   */
  const { currentUser } = useAuth()

  /**
   * Does catalog product have inputCables
   */
  const catalogProductHasInputCables =
    product.catalogProduct &&
    product.catalogProduct.customData &&
    product.catalogProduct.customData.inputCables
      ? true
      : false

  /**
   * The initial values
   */
  const initialValues = {
    name: device.name,
    inputCables: catalogProductHasInputCables
      ? //@ts-ignore
        product.catalogProduct.customData.inputCables
      : 1,
    voltage_1_tp: product.clampRatio,
    voltage_1_ts: 1,
    fuse: product.nominalCurrent,
    catalogProduct: product.catalogProduct ? product.catalogProduct.id : null
  }

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setSubmitting }: any
  ) => {
    try {
      await updatePowerSupply(device?.powerSupply?.id as number, values)
      dispatch(
        createErrorOrSuccessNotification(
          NotificationType.SUCCESS,
          t('powerSupplyUpdateSuccess')
        )
      )
      resetForm()
      dispatch(setStep(step + 1))
    } catch (error: unknown) {
      //Create message
      const errorMessage = createUserNotification({
        user: currentUser,
        type: PowerSupplyErrors.EDIT,
        error: error
      })

      //Dispatch error message
      dispatch<unknown>(
        createErrorOrSuccessNotification(
          NotificationType.WARNING,
          t(errorMessage.key) + t(errorMessage.message)
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography align="center" variant="h4">
              {t('Manufacturing.updateLinkedDeviceInstructions')}
            </Typography>
            <Divider my={6} />
            {product && (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl>
                    <TextField
                      name="name"
                      label={t('Devices.name')}
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      disabled={true}
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl>
                    <TextField
                      data-testid="fuse"
                      name="fuse"
                      label={t('addDevice.size') + ' (A)'}
                      disabled
                      error={Boolean(touched.fuse && errors.fuse)}
                      fullWidth
                      value={values.fuse}
                      helperText={touched.fuse && errors.fuse}
                      my={2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl>
                    <TextField
                      data-testid="inputCables"
                      name="inputCables"
                      label={t('addDevice.cable')}
                      fullWidth
                      select
                      disabled={product.nominalCurrent < 100}
                      value={values.inputCables}
                      helperText={touched.inputCables && errors.inputCables}
                      my={2}
                      onChange={handleChange}
                    >
                      <MenuItem value={'1'}>1</MenuItem>
                      <MenuItem value={'2'}>2</MenuItem>
                      <MenuItem value={'3'}>3</MenuItem>
                      <MenuItem value={'4'}>4</MenuItem>
                      <MenuItem value={'5'}>5</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4} lg={4} xl>
                    <TextField
                      key={(values.voltage_1_tp = product.clampRatio)}
                      name="voltage_1_tp"
                      label={t('addDevice.primary')}
                      fullWidth
                      disabled
                      variant="outlined"
                      my={2}
                      value={product.clampRatio}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl>
                    <TextField
                      name="voltage_1_ts"
                      label={t('addDevice.secondary')}
                      fullWidth
                      disabled
                      variant="outlined"
                      my={2}
                      value={values.voltage_1_ts}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} xl>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      {t('Devices.save')}
                    </Button>
                  </Box>
                </Grid>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}
