import { Device, Sensor } from '../types'
import SignalIndicator from './SignalIndicator'

/**
 * The props of the device signal indicator.
 */
interface DeviceSignalIndicatorProps {
  /**
   * The device.
   */
  device: Device

  /**
   * The sensor.
   */
  sensor: Sensor

  /**
   * The font size of the icon.
   */
  fontSize?: 'inherit' | 'small' | 'medium' | 'large'
}

/**
 * A signal indicator for a device.
 */
export default function DeviceSignalIndicator({
  sensor,
  fontSize = 'inherit'
}: DeviceSignalIndicatorProps) {
  return <SignalIndicator rssi={sensor.rssi} fontSize={fontSize} />
}
