import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  CardContent as MuiCardContent,
  TextField as MuiTextField,
  Button as MuiButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress
} from '@mui/material'
import { spacing } from '@mui/system'
import {
  createDeviceGroup,
  deleteDeviceGroup,
  getDeviceGroup,
  getDeviceGroups,
  updateDeviceGroup,
  setShow,
  Device_group
} from '../../redux/slices/device_groups'
import { RootState } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Add, Delete, Edit } from '@mui/icons-material'
const Card = styled(MuiCard)(spacing)
const Divider = styled(MuiDivider)(spacing)
const CardContent = styled(MuiCardContent)(spacing)
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing)
const Paper = styled(MuiPaper)(spacing)
const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

const initialValues = {
  name: ''
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required')
})

// eslint-disable-next-line
function Form() {
  const dispatch = useDispatch()

  const { device_group } = useSelector(
    (state: RootState) => state.device_groups
  )

  const handleSubmit = async (
    // eslint-disable-next-line
    values: any,
    // eslint-disable-next-line
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (device_group === undefined) {
        dispatch(createDeviceGroup(values))
      } else {
        dispatch(updateDeviceGroup(values))
      }

      resetForm()
      setStatus({ sent: true })
      setSubmitting(false)
      // eslint-disable-next-line
      dispatch(setShow())
    } catch (error: unknown) {
      setStatus({ sent: false })
      setErrors({ submit: 'Error' })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={
        device_group === undefined
          ? initialValues
          : {
              id: device_group.id,
              name: device_group.name
            }
      }
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <Card mb={6}>
          <CardContent>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <TextField
                  name="name"
                  label="Nimi"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Tallenna
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

interface IProps {
  device_group: Device_group | undefined
  device_groups: Device_group[]
  show: boolean
}

function SimpleTableDemo(props: IProps) {
  const dispatch = useDispatch()
  const { device_groups, device_group, show } = props

  const handleShow = () => {
    dispatch(setShow())

    if (device_group !== undefined) {
      dispatch(getDeviceGroup(undefined))
    }
  }

  const handleEdit = (id: number) => {
    dispatch(
      getDeviceGroup(device_groups.find((u: Device_group) => u.id === id))
    )
    dispatch(setShow())
  }

  const handleDelete = (id: number) => {
    dispatch(deleteDeviceGroup(id))
  }

  return (
    <Card mb={6}>
      <CardContent
        pb={1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Laiteryhmät
        </Typography>
        <Button
          mr={2}
          variant="contained"
          color="primary"
          onClick={() => handleShow()}
        >
          {show ? 'Peruuta' : 'Lisää'}
          {!show && <Add />}
        </Button>
      </CardContent>
      <Paper>
        {show ? (
          <Form />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="right">Nimi</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {device_groups.map((row: Device_group) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">
                    <Edit
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleEdit(row.id)}
                    />{' '}
                    <Delete
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete(row.id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </Card>
  )
}

/**
 * @deprecated
 */
export default function DeviceGroups() {
  const dispatch = useDispatch()
  const { device_groups, device_group, show } = useSelector(
    (state: RootState) => state.device_groups
  )

  useEffect(() => {
    dispatch(getDeviceGroups())
  }, [dispatch])

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Laiteryhmät
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Etusivu
        </Link>
        <Typography>Laiteryhmät</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SimpleTableDemo
            device_groups={device_groups}
            device_group={device_group}
            show={show}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
