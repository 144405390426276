import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid
} from '@mui/material'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ZapOff } from 'react-feather'
import React, { useEffect } from 'react'
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport
} from '@mui/x-data-grid'

// Components
import EmptyState from './EmptyState'
import LoadingState from './LoadingState'

// Types
import Sensor from '../types/Sensor'
import SensorAggregateQuery from '../types/SensorAggregateQuery'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import { SensorAggregateField, SensorAggregateStatistic } from '../enums'

// The value of "max-height" is the sum of table header and 6½ rows.
const TableWrapper = styled.div`
  overflow-x: auto;
  max-height: calc(54.78px + (6.5 * 51.58px));
  max-width: calc(100vw - (2 * 24px));
  @media (min-width: 960px) {
    max-width: calc(100vw - 258px - (2 * 48px));
  }
  margin-top: 2rem;
`
interface SensorTableProps {
  sensors: Sensor[]
  query: SensorAggregateQuery
  options: SensorAggregateOptions
  loading?: boolean
}

type FieldLabel = {
  label: string
  key: string
}

export default function SensorTable({
  sensors,
  options,
  loading = false
}: SensorTableProps) {
  /**
   *  The translate function.
   */
  const [t] = useTranslation('common')

  const fieldLabels: FieldLabel[] = makeFieldLabels()

  function makeFieldLabels(): FieldLabel[] {
    const labels: FieldLabel[] = []
    sensors.map((sensor) =>
      isLegacySensor(sensor)
        ? options.fields.map((key) => {
            labels.push({
              label: t(`SensorAggregate.${key as string}`) as string,
              key: key
            })
          })
        : options.fields.forEach((field: SensorAggregateField) => {
            options.statistics.forEach(
              (statistic: SensorAggregateStatistic) => {
                labels.push(makeLabel(field, statistic))
              }
            )
          })
    )

    return labels
  }

  function makeLabel(
    field: SensorAggregateField,
    statistic: SensorAggregateStatistic
  ): FieldLabel {
    const key = makeKey(field, statistic)
    const humanizedField = t(('SensorAggregate.' + field) as string)
    return {
      label: `${humanizedField} (${t(
        ('SensorAggregate.' + statistic) as string
      )})`,
      key: key
    }
  }

  function makeKey(
    field: SensorAggregateField,
    statistic: SensorAggregateStatistic
  ): keyof Sensor {
    return `${statistic as string}_${field as string}` as keyof Sensor
  }

  const timestampLabel = `${t('Sensor.time')} (${getTimezoneString()})`

  function getTimezoneString(): string {
    const offset: number = new Date().getTimezoneOffset() / 60

    const symbol = offset > 0 ? '+' : '-'
    const hours =
      Math.abs(offset) < 10 ? '0' + Math.abs(offset) : Math.abs(offset)
    const minutes = offset % 1 !== 0 ? '30' : '00'

    return `UTC${symbol}${hours}:${minutes}`
  }

  function humanizeTimestamp(timestamp: string): string {
    return format(Date.parse(timestamp), 'd.M.yyyy HH:mm:ss')
  }

  function humanizeValue(value: number): string {
    return value.toFixed(2)
  }

  function isLegacySensor(sensor: Sensor): boolean {
    return sensor.min_current_1 === null
  }
  const columns = [
    {
      field: 'timestamp',
      headerName: timestampLabel,
      width: 200,
      editable: false
    }
  ]

  fieldLabels.forEach((fieldLabels) =>
    columns.push({
      field: fieldLabels.key,
      headerName: fieldLabels.label,
      width: 200,
      editable: false
    })
  )

  const rows: any[] = []

  sensors.forEach((sensor) =>
    isLegacySensor(sensor)
      ? rows.push({
          id: sensor.time,
          timestamp: humanizeTimestamp(sensor.time),
          current_1: humanizeValue(sensor.current_1),
          current_2: humanizeValue(sensor.current_2),
          current_3: humanizeValue(sensor.current_3),
          voltage_1: humanizeValue(sensor.voltage_1),
          voltage_2: humanizeValue(sensor.voltage_2),
          voltage_3: humanizeValue(sensor.voltage_3),
          temp: humanizeValue(sensor.temp),
          rh: humanizeValue(sensor.rh),
          rated_current: humanizeValue(sensor.rated_current),
          rssi: humanizeValue(sensor.rssi),
          kw: humanizeValue(sensor.kw)
        })
      : rows.push({
          id: sensor.time,
          timestamp: humanizeTimestamp(sensor.time as string),
          avg_current_1: humanizeValue(sensor.avg_current_1 as number),
          avg_current_2: humanizeValue(sensor.avg_current_2 as number),
          avg_current_3: humanizeValue(sensor.avg_current_3 as number),
          avg_kw: humanizeValue(sensor.avg_kw as number),
          avg_rated_current: humanizeValue(sensor.avg_rated_current as number),
          avg_rh: humanizeValue(sensor.avg_rh as number),
          avg_rssi: humanizeValue(sensor.avg_rssi as number),
          avg_temp: humanizeValue(sensor.avg_temp as number),
          avg_voltage_1: humanizeValue(sensor.avg_voltage_1 as number),
          avg_voltage_2: humanizeValue(sensor.avg_voltage_2 as number),
          avg_voltage_3: humanizeValue(sensor.avg_voltage_3 as number),
          max_current_1: humanizeValue(sensor.max_current_1 as number),
          max_current_2: humanizeValue(sensor.max_current_2 as number),
          max_current_3: humanizeValue(sensor.max_current_3 as number),
          max_kw: humanizeValue(sensor.max_kw as number),
          max_rated_current: humanizeValue(sensor.max_rated_current as number),
          max_rh: humanizeValue(sensor.max_rh as number),
          max_rssi: humanizeValue(sensor.max_rssi as number),
          max_temp: humanizeValue(sensor.max_temp as number),
          max_voltage_1: humanizeValue(sensor.max_voltage_1 as number),
          max_voltage_2: humanizeValue(sensor.max_voltage_2 as number),
          max_voltage_3: humanizeValue(sensor.max_voltage_3 as number),
          min_current_1: humanizeValue(sensor.min_current_1 as number),
          min_current_2: humanizeValue(sensor.min_current_2 as number),
          min_current_3: humanizeValue(sensor.min_current_3 as number),
          min_kw: humanizeValue(sensor.min_kw as number),
          min_rated_current: humanizeValue(sensor.min_rated_current as number),
          min_rh: humanizeValue(sensor.min_rh as number),
          min_rssi: humanizeValue(sensor.min_rssi as number),
          min_temp: humanizeValue(sensor.min_temp as number),
          min_voltage_1: humanizeValue(sensor.min_voltage_1 as number),
          min_voltage_2: humanizeValue(sensor.min_voltage_2 as number),
          min_voltage_3: humanizeValue(sensor.min_voltage_3 as number)
        })
  )

  if (loading) {
    return <LoadingState />
  } else if (!sensors.length) {
    return (
      <EmptyState icon={<ZapOff fontSize="large" />} title={t('noSensors')} />
    )
  } else {
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnSelector
        sx={{ height: 500 }}
        components={{
          Toolbar: GridToolbar
        }}
      />
    )
  }
}
