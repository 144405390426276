import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider as MuiDivider,
  Stack,
  Typography,
  IconButton,
  Menu,
  MenuProps,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  MenuList,
  ListItem,
  ListItemText
} from '@mui/material'
import { styled } from '@mui/styles'
import DevicePowerBar from './DevicePowerBar'
import DevicePowerChart from './DevicePowerChart'
import LoadingState from './LoadingState'
import SensorAggregateStatisticToggle from './SensorAggregateStatisticToggle'
import SensorAggregateStatistic from '../enums/SensorAggregateStatistic'
import SensorAggregateField from '../enums/SensorAggregateField'
import DateRange from '../types/DateRange'
import Sensor from '../types/Sensor'
import SensorQuery from '../types/SensorQuery'
import SensorAggregate from '../types/SensorAggregate'
import SensorAggregateQuery from '../types/SensorAggregateQuery'
import SensorAggregateOptions from '../types/SensorAggregateOptions'
import { differenceInHours } from 'date-fns'
import { getDifferenceOfDateRangeInHours } from '../utils/date'
import { Device } from '../types'
import { MoreVertical } from 'react-feather'
import { useTheme } from '@mui/system'
import { spacing } from '@mui/system'
import useAppSettings from '../hooks/useAppSettings'
import SquareIcon from '@mui/icons-material/Square'
import { orange, red } from '@mui/material/colors'
import { ForcedYAxis, ScalingYAxis } from '../types/YAxisOptions'
import { calculateMaxKwh, isSensorNow } from '../utils/sensor'

const Divider = styled(MuiDivider)(spacing)

export interface DevicePowerStatsProps {
  device: Device
  dateRange: DateRange
  sensor: Sensor | undefined
  sensors: Sensor[]
  sensorAggregates: SensorAggregate[]
  loading?: boolean
}

export default function DevicePowerStats({
  device,
  dateRange,
  sensor,
  sensors,
  sensorAggregates,
  loading = false
}: DevicePowerStatsProps) {
  const [t] = useTranslation('common')

  /**
   * The application settings.
   */
  const appSettings = useAppSettings()

  const [sensorAggregateOptions, setSensorAggregateOptions] =
    useState<SensorAggregateOptions>({
      statistics: getInitialStatistics(),
      fields: appSettings.sensorSettings?.powerChartFields
        ? appSettings.sensorSettings.powerChartFields
        : [SensorAggregateField.KW]
    })

  /**
   * The difference of date range as hours.
   */
  const differenceOfDateRangeInHours =
    getDifferenceOfDateRangeInHours(dateRange)

  /**
   * Indicates if sensor aggregates are being used.
   */
  const isUsingSensorAggregates = differenceOfDateRangeInHours > 3

  function getInitialStatistics(): SensorAggregateStatistic[] {
    const searchParams = new URLSearchParams(window.location.search)
    const foundStatics = searchParams.get('power-statistics') ?? undefined
    if (foundStatics) {
      return JSON.parse(foundStatics)
    } else {
      searchParams.set(
        'power-statistics',
        JSON.stringify(
          appSettings.sensorSettings?.powerChartStatistics
            ? appSettings.sensorSettings.powerChartStatistics
            : [SensorAggregateStatistic.AVG]
        )
      )
      const newRelativePathQuery =
        window.location.pathname + '?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
      return appSettings.sensorSettings?.powerChartStatistics
        ? appSettings.sensorSettings.powerChartStatistics
        : [SensorAggregateStatistic.AVG]
    }
  }

  function changeStatistics(statistics: SensorAggregateStatistic[]): void {
    const appSettings = useAppSettings()
    const sensorSettings = appSettings.sensorSettings

    setSensorAggregateOptions({
      ...sensorAggregateOptions,
      statistics
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          powerChartStatistics: statistics
        }
      })
    )
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('power-statistics', JSON.stringify(statistics) as string)

    const newRelativePathQuery =
      window.location.pathname + '?' + searchParams.toString()
    window.history.replaceState(null, '', newRelativePathQuery)
  }

  function changeFields(fields: SensorAggregateField[]): void {
    const appSettings = useAppSettings()
    const sensorSettings = appSettings.sensorSettings
    setSensorAggregateOptions({
      ...sensorAggregateOptions,
      fields
    })

    window.localStorage.setItem(
      'appSettings',
      JSON.stringify({
        ...appSettings,
        sensorSettings: {
          ...sensorSettings,
          powerChartFields: fields
        }
      })
    )
  }

  /**
   * The anchor element for the menu.
   */
  const [menuAnchor, setAnchorEl] = useState<null | HTMLElement>(null)

  /**
   * Indicates if the menu is open.
   */
  const isMenuOpen = Boolean(menuAnchor)

  /**
   * Open the menu.
   */
  function openMenu(event: MouseEvent<HTMLElement>): void {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu.
   */
  function closeMenu(): void {
    setAnchorEl(null)
  }

  function handleChange(
    event: MouseEvent<HTMLElement>,
    newValue: SensorAggregateField[]
  ): void {
    changeFields(newValue)
  }

  const [showTotalPowerChartAnnotation, setShowTotalPowerChartAnnotation] =
    useState<boolean>(
      appSettings.sensorSettings?.showTotalMaxPowerAnnotation
        ? appSettings.sensorSettings.showTotalMaxPowerAnnotation
        : false
    )

  const [showPhasePowerChartAnnotation, setShowPhasePowerChartAnnotation] =
    useState<boolean>(
      appSettings.sensorSettings?.showPhaseMaxPowerAnnotation
        ? appSettings.sensorSettings.showPhaseMaxPowerAnnotation
        : false
    )

  const handleTotalPowerSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const appSettings = useAppSettings()
    const sensorSettings = appSettings.sensorSettings
    if (event.target.checked === false) {
      setShowTotalPowerChartAnnotation(false)
      window.localStorage.setItem(
        'appSettings',
        JSON.stringify({
          ...appSettings,
          sensorSettings: {
            ...sensorSettings,
            showTotalMaxPowerAnnotation: false
          }
        })
      )
    } else if (event.target.checked === true) {
      setShowTotalPowerChartAnnotation(true)
      window.localStorage.setItem(
        'appSettings',
        JSON.stringify({
          ...appSettings,
          sensorSettings: {
            ...sensorSettings,
            showTotalMaxPowerAnnotation: true
          }
        })
      )
    }
  }

  const handlePhasePowerSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const appSettings = useAppSettings()
    const sensorSettings = appSettings.sensorSettings
    if (event.target.checked === false) {
      setShowPhasePowerChartAnnotation(false)
      window.localStorage.setItem(
        'appSettings',
        JSON.stringify({
          ...appSettings,
          sensorSettings: {
            ...sensorSettings,
            showPhaseMaxPowerAnnotation: false
          }
        })
      )
    } else if (event.target.checked === true) {
      setShowPhasePowerChartAnnotation(true)
      window.localStorage.setItem(
        'appSettings',
        JSON.stringify({
          ...appSettings,
          sensorSettings: {
            ...sensorSettings,
            showPhaseMaxPowerAnnotation: true
          }
        })
      )
    }
  }
  //@ts-ignore
  const maxKw: number = calculateMaxKwh(device?.powerSupply?.fuse)

  const [yAxisOption, setYaxisOption] = useState<ScalingYAxis | ForcedYAxis>({
    max: Math.round(maxKw * 1.2),
    min: 0,
    defaultChecked: true
  })

  const handleForceYAxisSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked === false) {
      setYaxisOption({
        suggestedMax: Math.round(maxKw * 1.2),
        suggestedMin: 0,
        defaultChecked: false
      })
    } else if (event.target.checked === true) {
      setYaxisOption({
        max: Math.round(maxKw * 1.2),
        min: 0,
        defaultChecked: true
      })
    }
  }

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        pt={4}
        pb={2}
      >
        <Typography variant="h6">{t('Device.power')}</Typography>

        <Stack direction="row" spacing={3}>
          <SensorAggregateStatisticToggle
            value={sensorAggregateOptions.statistics}
            onChange={changeStatistics}
          />
        </Stack>

        <IconButton
          aria-controls={isMenuOpen ? 'menu' : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={openMenu}
        >
          <MoreVertical />
        </IconButton>

        <Menu
          anchorEl={menuAnchor}
          open={isMenuOpen}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            sx: {
              width: 320
            }
          }}
        >
          <MenuList>
            <ListItem>
              <ListItemText primary={t('phases')} />
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={sensorAggregateOptions.fields}
                aria-label={t('phases')}
                onChange={handleChange}
              >
                <ToggleButton value={SensorAggregateField.KW}>
                  {t('SensorAggregate.kw')}
                </ToggleButton>
                <ToggleButton value={SensorAggregateField.KW_L1}>
                  {t('L1')}
                </ToggleButton>
                <ToggleButton value={SensorAggregateField.KW_L2}>
                  {t('L2')}
                </ToggleButton>
                <ToggleButton value={SensorAggregateField.KW_L3}>
                  {t('L3')}
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Stack direction="row" gap={2} alignItems="center">
                    <SquareIcon fontSize="small" sx={{ color: red[500] }} />
                    <Typography>{t('showTotalMaxPower')}</Typography>
                  </Stack>
                }
              />
              <Switch
                size="small"
                checked={showTotalPowerChartAnnotation}
                onChange={handleTotalPowerSwitchChange}
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={
                  <Stack direction="row" gap={2} alignItems="center">
                    <SquareIcon fontSize="small" sx={{ color: orange[500] }} />
                    <Typography>{t('showPhaseMaxPower')}</Typography>
                  </Stack>
                }
              />
              <Switch
                size="small"
                checked={showPhasePowerChartAnnotation}
                onChange={handlePhasePowerSwitchChange}
              />
            </ListItem>

            <ListItem>
              <ListItemText primary={t('zoomIn')} />
              <Switch
                size="small"
                checked={yAxisOption.defaultChecked}
                onChange={handleForceYAxisSwitchChange}
              />
            </ListItem>
          </MenuList>
        </Menu>
      </Box>

      <CardContent>
        <Stack direction="column">
          {sensor && isSensorNow(sensor) && (
            <>
              <DevicePowerBar device={device} sensor={sensor} />
              <Divider my={6} />
            </>
          )}

          {loading ? (
            <LoadingState />
          ) : (
            <DevicePowerChart
              device={device}
              dateRange={dateRange}
              sensors={sensors}
              sensorAggregates={sensorAggregates}
              sensorAggregateOptions={sensorAggregateOptions}
              sensor={sensor}
              showTotalPowerAnnotation={showTotalPowerChartAnnotation}
              showPhasePowerAnnotation={showPhasePowerChartAnnotation}
              yAxisOption={yAxisOption}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
