import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartOptions } from 'chart.js'
import { Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import {
  RuuviTagCondition,
  RuuviTagConditionQuery,
  RuuviTagConditionAggregate,
  RuuviTagConditionAggregateQuery,
  RuuviTagConditionAggregateStatistic
} from '../types'
import RuuviTagConditionChart from './RuuviTagConditionChart'
import RuuviTagConditionAggregateChart from './RuuviTagConditionAggregateChart'
import RuuviTagConditionStatisticToggle from './RuuviTagConditionStatisticToggle'
import LoadingState from './LoadingState'
import { getDifferenceOfDateRangeInHours } from '../utils/date'

interface RuuviTagSignalChartProps {
  conditions: RuuviTagCondition[] | RuuviTagConditionAggregate[]
  query: RuuviTagConditionQuery | RuuviTagConditionAggregateQuery
  loading?: boolean
}

export default function RuuviTagSignalChart({
  conditions,
  query,
  loading = false
}: RuuviTagSignalChartProps) {
  /**
   * The translate function.
   */
  const [t] = useTranslation('common')

  /**
   * The condition statistics of RuuviTag.
   */
  const [statistics, setStatistics] = useState<
    RuuviTagConditionAggregateStatistic[]
  >(['avg'])

  /**
   * The chart options.
   */
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        suggestedMax: 0,
        suggestedMin: -120,
        grid: {
          display: true
        }
      }
    }
  }

  /**
   * Determines whether condition aggregates are being used.
   */
  const isUsingAggregates =
    getDifferenceOfDateRangeInHours(query.from, query.to) >= 24

  /**
   * The highest pressure.
   */
  const highestSignal = Math.max(
    ...conditions.map((ruuviTagCondition) =>
      isUsingAggregates
        ? (ruuviTagCondition as RuuviTagConditionAggregate).rssiMax
        : (ruuviTagCondition as RuuviTagCondition).rssi
    )
  )

  /**
   * The lowest pressure.
   */
  const lowestSignal = Math.min(
    ...conditions.map((ruuviTagCondition) =>
      isUsingAggregates
        ? (ruuviTagCondition as RuuviTagConditionAggregate).rssiMin
        : (ruuviTagCondition as RuuviTagCondition).rssi
    )
  )

  /**
   * The average pressure.
   */
  const averageSignal =
    conditions
      .map((ruuviTagCondition) =>
        isUsingAggregates
          ? (ruuviTagCondition as RuuviTagConditionAggregate).rssiAvg
          : (ruuviTagCondition as RuuviTagCondition).rssi
      )
      .reduce((a, b) => a + b, 0) / conditions.length

  /**
   * Make the chip label.
   */
  function makeChipLabel(label: string, value: number): string {
    return `${label}: ${value.toFixed(2)} dBm`
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h4">{t('signal')}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'center' }}
            alignItems="center"
          >
            {conditions.length > 0 && (
              <Stack direction="row" gap={3}>
                <Chip
                  label={makeChipLabel(t('min'), lowestSignal)}
                  size="small"
                  variant="outlined"
                />

                <Chip
                  label={makeChipLabel(t('avg'), averageSignal)}
                  size="small"
                  variant="outlined"
                />

                <Chip
                  label={makeChipLabel(t('max'), highestSignal)}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
            alignItems="center"
          >
            {isUsingAggregates && (
              <RuuviTagConditionStatisticToggle
                value={statistics}
                onChange={setStatistics}
              />
            )}
          </Grid>
        </Grid>

        {loading ? (
          <LoadingState />
        ) : (
          <>
            {isUsingAggregates ? (
              <RuuviTagConditionAggregateChart
                field="rssi"
                conditions={conditions as RuuviTagConditionAggregate[]}
                query={query as RuuviTagConditionAggregateQuery}
                statistics={statistics as RuuviTagConditionAggregateStatistic[]}
                options={options}
              />
            ) : (
              <RuuviTagConditionChart
                ruuviTagConditionField="rssi"
                ruuviTagConditions={conditions as RuuviTagCondition[]}
                ruuviTagConditionQuery={query as RuuviTagConditionQuery}
                chartOptions={options}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}
