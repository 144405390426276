import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import SendProjectInvite from '../types/SendProjectInvite'
import {
  ActionRequest,
  DateRange,
  Emission,
  EmissionQuery,
  EnergyConsumption,
  EnergyConsumptionQuery,
  EnergyCost,
  EnergyCostQuery,
  Project,
  ProjectRequestParams,
  ProjectPhase,
  StatisticsAggregate,
  PowerSupply,
  Device,
  ProjectBaseInput
} from '../types'
import ProjectInput from '../types/ProjectInput'
import { AlertGroup, AlertTemplate } from '../types/Alerts'
import { useApi } from '../hooks'
import ProjectQuery from '../types/ProjectQuery'
import { RequestProjectAccessInput } from '../types/RequestProjectAccessInput'
import { RequestAccessProject } from '../types/RequestAccessProject'

const api = useApi('/v2')

export async function getProjects(
  query: ProjectQuery = {}
): Promise<Project[]> {
  const response = await api.get(`/projects`, {
    params: query
  })

  return response.data
}

export async function getProjectsByLocation(
  query: RequestProjectAccessInput
): Promise<RequestAccessProject[]> {
  const response = await api.get(`/projects/by-location`, {
    params: query
  })
  return response.data
}

async function getActiveProjects(): Promise<Project[]> {
  const response = await api.get(`/projects`, {
    params: {
      showActive: true
    }
  })

  return response.data
}

export async function getProjectsByCompanyId(
  companyId: number | string | undefined,
  showDeleted?: boolean
): Promise<Project[]> {
  const response = await api.get(`/projects`, {
    params: {
      companyId: companyId,
      showDeleted: showDeleted ?? false
    }
  })

  return response.data
}

export async function getProject(
  id: number | string | undefined
): Promise<Project> {
  const response = await api.get(`/projects/${id}`)

  return response.data
}

async function createProject(input: ProjectInput): Promise<AxiosResponse> {
  const response = await api.post('/projects', input)

  return response.data
}

export async function createProjectBase(
  input: ProjectBaseInput
): Promise<Project> {
  const response = await api.post('/projects/base', input)

  return response.data
}

export async function updateProject(
  id: number,
  input: ProjectInput,
  config?: AxiosRequestConfig
): Promise<Project> {
  const response = await api.put(`/projects/${id}`, input, config)

  return response.data
}

export async function deleteProject(
  id: number | string,
  deletePermanently?: boolean
): Promise<void> {
  await api.delete(`/projects/${id}`, {
    params: {
      permanently: deletePermanently ?? false
    }
  })
}

export async function recoverProject(id: number): Promise<Project> {
  const response = await api.patch(`/projects/recover/${id}`)

  return response.data
}

// FIXME: Move to meService.
async function getMyProjects(): Promise<AxiosResponse> {
  const response = await api.get(`/me/projects`)

  return response.data
}

// FIXME: Move to meService.
async function updateActiveMembership(id: string): Promise<AxiosResponse> {
  const response = await api.put(`/me`, {
    activeProjectMembershipId: id
  })

  return response
}

export async function sendProjectInvite(id: number, input: SendProjectInvite) {
  return await api.post(`/projects/${id}/invite`, input)
}

export async function getActionRequestsByProject(
  id: string | number,
  params: ProjectRequestParams
): Promise<ActionRequest[]> {
  const response = await api.get(`projects/${id}/action-requests`, {
    params: params
  })

  return response.data
}

export async function getLevelsByBlueprints(id: number): Promise<string[]> {
  const response = await api.get(`/projects/${id}/blueprints/levels`)

  return response.data
}

/**
 * Get the statistics aggregates of a project.
 */
export async function getStatisticsAggregatesOfProject(
  id: number,
  query: DateRange,
  config?: AxiosRequestConfig
): Promise<StatisticsAggregate[]> {
  const response = await api.get(`/projects/${id}/statistics-aggregates`, {
    ...config,
    params: { ...query }
  })

  return response.data
}

/**
 * Get the energy consumptions of a project.
 */
export async function getEnergyConsumptionsOfProject(
  id: number,
  query: EnergyConsumptionQuery,
  config?: AxiosRequestConfig
): Promise<EnergyConsumption[]> {
  const response = await api.get(`/projects/${id}/energy-consumptions`, {
    ...config,
    params: query
  })

  return response.data
}

/**
 * Get the energy costs of a project.
 */
export async function getEnergyCostsOfProject(
  id: number,
  query: EnergyCostQuery,
  config?: AxiosRequestConfig
): Promise<EnergyCost[]> {
  const response = await api.get(`/projects/${id}/energy-costs`, {
    ...config,
    params: query
  })

  return response.data
}

/**
 * Get the energy emissions of a project.
 */
export async function getEnergyEmissionsOfProject(
  id: number,
  query: EmissionQuery,
  config?: AxiosRequestConfig
): Promise<Emission[]> {
  const response = await api.get(`/projects/${id}/energy-emissions`, {
    ...config,
    params: query
  })

  return response.data
}

/**
 * Get the total energy consumption of a project.
 *
 * @deprecated
 */
export async function getTotalEnergyConsumptionOfProject(
  id: number,
  config?: AxiosRequestConfig
): Promise<number> {
  const response = await api.get(`/projects/${id}/energy-consumption`, {
    ...config
  })

  return response.data
}

/**
 * Get the total energy costs of a project.
 *
 * @deprecated
 */
export async function getTotalEnergyCostsOfProject(
  id: number,
  config?: AxiosRequestConfig
): Promise<number> {
  const response = await api.get(`/projects/${id}/energy-cost`, {
    ...config
  })

  return response.data
}

/**
 * Get the total emissions of a project.
 *
 * @deprecated
 */
export async function getTotalEmissionsOfProject(
  id: number,
  config?: AxiosRequestConfig
): Promise<number> {
  const response = await api.get(`/projects/${id}/energy-emission`, {
    ...config
  })

  return response.data
}

export async function getProjectAlertRules(id: number): Promise<AlertGroup[]> {
  const response = await api.get(`/projects/alertrules/${id}`)

  return response.data
}

export async function updateProjectAlertRules(
  id: number,
  input: AlertGroup[]
): Promise<AxiosPromise> {
  const response = await api.put(`/projects/alertrules/${id}`, input)

  return response.data
}

export async function getProjectAlertRuleTemplate(): Promise<AlertTemplate[]> {
  const response = await api.get(`/projects/alertrules/templates`)

  return response.data
}

export async function getProjectPhases(id: number): Promise<ProjectPhase[]> {
  const response = await api.get(`/projects/${id}/project-phases`)

  return response.data
}

export async function getPowerSuppliesByProjectId(
  projectId: number
): Promise<PowerSupply[]> {
  const response = await api.get(`/projects/${projectId}/powersupplies`)

  return response.data
}

export async function getDevicesOfProject(id: number): Promise<Device[]> {
  const response = await api.get(`/projects/${id}/devices`)

  return response.data
}

export default {
  getProjects,
  getActiveProjects,
  getProjectsByCompanyId,
  getMyProjects,
  getProject,
  createProject,
  createProjectBase,
  updateProject,
  deleteProject,
  updateActiveMembership,
  getActionRequestsByProject,
  getLevelsByBlueprints,
  getProjectAlertRules,
  getProjectPhases,
  getPowerSuppliesByProjectId
}
