import {
  Button,
  Grid,
  Typography,
  TextField as MuiTextField
} from '@mui/material'
import styled from 'styled-components/macro'
import { spacing } from '@mui/system'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Device } from '../../types'
import { sendBug } from '../../services/jiraService'
import { IncorrectValues } from './SelectIncorrect'
import JiraProject from '../../enums/JiraProject'
import { EditedValue } from '../../pages/pages/DeviceTroubleshoot'

const TextField = styled(MuiTextField)<{ my?: number }>(spacing)

interface ErrorTicketProps {
  troubleshootSteps: string[]
  device: Device
  incorrectValues?: IncorrectValues
  updatedValues: EditedValue[]
  endTroubleshoot: () => void
}

const ErrorTicket: React.FC<ErrorTicketProps> = ({
  troubleshootSteps,
  device,
  incorrectValues,
  updatedValues,
  endTroubleshoot
}) => {
  const { t } = useTranslation('common')
  const [freeText, setFreeText] = useState('')
  const [isTicketSent, setIsTicketSent] = useState(false)

  /**
   * Function that handles textField onChange event.
   * @param event
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFreeText(event.target.value)
  }

  /**
   * Function that sends an error ticket to jira.
   */
  const sendErrorTicket = () => {
    const allSteps = ['Troubleshoot steps:', ...troubleshootSteps]

    if (updatedValues.length !== 0) {
      allSteps.push('\nAll updated values:')
      for (const updated of updatedValues) {
        allSteps.push(
          `${updated.name}: From ${
            updated.oldValue ? updated.oldValue : 'Not defined'
          } to ${updated.newValue}`
        )
      }
    }

    if (incorrectValues) {
      allSteps.push('\nIncorrect values:')
      for (const value of incorrectValues.incorrectValues) {
        allSteps.push(`${value}`)
      }
      for (const current of incorrectValues.currentsAndVoltages) {
        allSteps.push(`${current.name} : ${current.type} : ${current.value}`)
      }

      if (incorrectValues.freeText?.length !== 0) {
        allSteps.push('Free text regarding incorrect values:')
        allSteps.push(incorrectValues.freeText)
      }
    }

    if (freeText.length !== 0) {
      allSteps.push('\nAnything to add regarding the issue:')
      allSteps.push(freeText)
    }

    const title = `Troubleshoot ticket for device with id: ${device.id}`

    sendBug(
      {
        title,
        description: allSteps.join('\n')
      },
      JiraProject.TROUBLESHOOT
    )
    setIsTicketSent(true)
  }

  return (
    <>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
        {t('troubleshoot.didNotGoAsPlanned')}
      </Typography>

      {isTicketSent ? (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t('troubleshoot.ticketBody')}
          </Typography>
          <Button variant="contained" onClick={endTroubleshoot}>
            {t('troubleshoot.endTroubleshoot')}
          </Button>
        </>
      ) : (
        <>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t('troubleshoot.anythingToAdd')}
          </Typography>

          <TextField
            id="freeTextField"
            multiline
            fullWidth
            onChange={handleChange}
          />

          <Typography align="left" sx={{ mr: 2, mt: 2 }}>
            <Button variant="contained" onClick={sendErrorTicket}>
              {t('troubleshoot.sendErrorTicket')}
            </Button>
          </Typography>
        </>
      )}
    </>
  )
}

export default ErrorTicket
