import EnergyPriceStats from './EnergyPriceStats'
import GeoCoordinate from '../types/GeoCoordinate'
import DateRange from '../types/DateRange'
import { ChartOptions } from 'chart.js'
import { humanizeDateRange } from '../utils/date'
import EnergyPriceQuery from '../types/EnergyPriceQuery'
import { EnergyPriceSettings } from '../types'
import { EnergyPriceDateRangeMode } from '../enums'
import { ActiveProject, Project } from '../types'

export interface ProjectEnergyPriceStatsProps {
  project: Project | ActiveProject
  dateRange: DateRange
  initialSettings?: EnergyPriceSettings
}

export default function ProjectEnergyPriceStats({
  project,
  dateRange,
  initialSettings = {
    dateRangeMode: EnergyPriceDateRangeMode.TODAY
  }
}: ProjectEnergyPriceStatsProps) {
  const geoCoordinate: GeoCoordinate = {
    lat: project.lat,
    long: project.long
  }

  const energyPriceQuery: EnergyPriceQuery = {
    ...dateRange,
    ...geoCoordinate
  }

  const chartOptions: ChartOptions = {
    plugins: {
      title: {
        display: false,
        text: humanizeDateRange(dateRange.from, dateRange.to)
      }
    }
  }

  return (
    <EnergyPriceStats
      query={energyPriceQuery}
      chartOptions={chartOptions}
      initialSettings={initialSettings}
    />
  )
}
