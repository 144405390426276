import { AxiosResponse } from 'axios'
import { useApi } from '../hooks'
import { ProjectMembershipQuery, ProjectMembership } from '../types'

const api = useApi('/v2')

export interface CreateMembership {
  projectId: number
  memberId: number
  role: string
}

export interface UpdateMembership {
  id: string
  projectId: number
  memberId: number
  role: string
}

export async function createProjectMembership(
  membership: CreateMembership
): Promise<AxiosResponse> {
  const response = await api.post('/project-memberships', membership)

  return response.data
}

export async function deleteProjectMembership(
  id: string
): Promise<AxiosResponse> {
  const response = await api.delete(`/project-memberships/${id}`)

  return response.data
}

export async function updateProjectMembership(
  membership: UpdateMembership
): Promise<AxiosResponse> {
  const response = await api.put(
    `/project-memberships/${membership.id}`,
    membership
  )

  return response.data
}

export async function getProjectMembership(
  user: ProjectMembershipQuery
): Promise<ProjectMembership> {
  const response = await api.get(`/project-memberships`, {
    params: {
      memberId: user.memberId,
      projectId: user.projectId
    }
  })

  if (!response.data.length) {
    throw new Error('Membership not found')
  }

  return response.data[0]
}

export default {
  getProjectMembership,
  createProjectMembership,
  updateProjectMembership,
  deleteProjectMembership
}
