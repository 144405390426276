import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import deviceService from '../../services/deviceService'
import { Dispatch } from 'redux'
import { createNotification } from './notifications'
import companyService from '../../services/companyService'
import { Device, Sensor } from '../../types'

interface DeviceState {
  selectedDeviceId: number
  devices: Device[]
  device: Device | undefined
  sensorData: any
  lastSensorData: Sensor | Record<string, never>
  secondLastSensorData: Sensor | Record<string, never>
  show: boolean
  showDevice: boolean
  loading: boolean
  status: 'idle' | 'loading' | 'failed'
}

export interface SensorData {
  latest: Sensor
  secondLatest: Sensor
  day: Sensor[]
  week: Sensor[]
  allDaily: Sensor[]
  allLatest: Sensor[]
  month: Sensor[]
}

const initialState: DeviceState = {
  selectedDeviceId: 0,
  devices: [],
  device: undefined,
  sensorData: [],
  lastSensorData: {},
  secondLastSensorData: {},
  show: false,
  showDevice: false,
  loading: false,
  status: 'idle'
}

export const fetchDevices = createAsyncThunk(
  'devices/fetchDevices',
  async () => {
    const response = await deviceService.getDevices()
    return response
  }
)

export const fetchDevice = createAsyncThunk(
  'devices/fetchDevice',
  async (id: number) => {
    const response = await deviceService.getDevice(id)
    return response
  }
)

const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload
    },
    setDevices(state, action) {
      state.devices = action.payload
    },
    setDevice(state, action) {
      state.device = action.payload
    },
    setDeviceSensorData(state, action) {
      state.sensorData = action.payload
    },
    setLastSensorData(state, action) {
      state.lastSensorData = action.payload
    },
    setSecondLastSensorData(state, action) {
      state.secondLastSensorData = action.payload
    },
    createDevice(state, action) {
      state.devices = [...state.devices, action.payload]
    },
    updateDevice(state, action) {
      state.devices = state.devices
        .filter((u) => u.id !== action.payload.id)
        .concat(action.payload)
    },
    deleteDevice(state, action) {
      state.devices = state.devices.filter((u) => u.name !== action.payload)
    },
    setShow(state, action) {
      state.show = action.payload
    },
    setShowDevice(state) {
      state.showDevice = !state.showDevice
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDevices.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.devices = action.payload
        state.loading = false
      })
      .addCase(fetchDevice.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchDevice.fulfilled, (state, action) => {
        state.device = action.payload
        state.loading = false
      })
  }
})

export const { setSelectedDeviceId, setDevices, setDevice, setLoading } =
  deviceSlice.actions

export function getDevices() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await deviceService.getDevices()
      dispatch(deviceSlice.actions.setDevices(response))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export function getDevicesByCompanyId(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await companyService.getCompanyDevices(id)
      dispatch(deviceSlice.actions.setDevices(response))
    } catch (error) {
      dispatch<any>(
        createNotification({
          show: true,
          type: 'warning',
          message: 'Something went wrong!',
          timeout: 5000
        })
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export function getProjectDevices(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const devices = await deviceService.getProjectDevices(id)

      dispatch(deviceSlice.actions.setDevices(devices))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export function getDevice(id: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await deviceService.getDevice(id)

      dispatch(deviceSlice.actions.setDevice(response))
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export function setShow(show: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(deviceSlice.actions.setShow(show))
  }
}

export function setShowDevice() {
  return async (dispatch: Dispatch) => {
    dispatch(deviceSlice.actions.setShowDevice())
  }
}

export default deviceSlice.reducer
